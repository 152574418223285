import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Div, Text, Icons } from "qdm-component-library";
import close from "../../assets/img/close.svg";
import search from "../../assets/img/search.svg";
import Viewer from "./Viewer";
import ListDiv from "./list";
import moment from "moment";
import {Routes} from "../../router/routes";
import {getImgUrl} from "../../utils"
class Viewreport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appId: this.props?.location?.state?.appNo ?? "",
            report_docs: this.props?.location?.state?.report_docs ?? [],
            fileData: [this.props?.location?.state?.report_docs[0]] ?? [{}],
        };
    }

    componentDidMount() {
    }

    shouldComponentUpdate(props, state) {
        return true;
    }

    DataList = [
        {
            fileid: "10009",
            filetype: "application/pdf",
            objectid: "1",
            fileName: "tc_quote_2021_3_23 (2).pdf",
        },
        {
            status: "200",
            objectid: "11",
            fileid: "10002",
            filetype: "application/jpg",
            fileName: "img 1",
        },
        {
            status: "200",
            objectid: "111",
            fileid: "10011",
            filetype: "application/pdf",
            fileName: "pdf 2",
        },
    ];

    changeUi = (data) => {
        this.setState({
            ...this.state,
            fileData: [data],
        });
    };

    downloadFun = () => {
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            getImgUrl(this.state.fileData[0].fileid),
        );
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
    render() {
        return (
            <Div
                key={"0"}
                name="container"
                fluid={true}
                style={{
                    backgroundColor: "#F9F9F9",
                    padding: 0,
                }}
                id={"Viewreport1"}
            >
                <Row
                    key={"0"}
                    alignItems="stretch"
                    direction="row"
                    justify="flex-start"
                    spacing="2"
                    inLineStyles={{
                        border: "1px solid #F0F0F0",
                        borderRadius: "8px",
                    }}
                    id={"Viewreport2"}
                >
                    <Col
                        key={"0"}
                        name="qdm-col-12"
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        textAlign="left"
                        lg="3"
                        xs="12"
                        md="3"
                        sm="3"
                        style={{
                            height: "calc(100vh - 8px)",
                            padding: 0,
                            backgroundColor: "#fff",
                            borderRight: "2px solid #e0e0e0",
                        }}
                        id={"Viewreport3"}
                    >
                        <Div
                            style={{
                                padding: "22px 24px 12px",
                                borderBottom: "2px solid #e0e0e0",
                            }}
                            id={"Viewreport4"}
                        >
                            <Div>
                                <Text
                                    key={"0"}
                                    name="Today"
                                    style={{
                                        color: "#101010",
                                        fontSize: "20px",
                                    }}
                                    className={"pc_semibold"}
                                    id={"Viewreport5"}
                                >
                                    View Uploaded Report
                                </Text>
                            </Div>
                            <Div>
                                <Text
                                    key={"0"}
                                    name="Today"
                                    style={{
                                        color: "#B6B6B6",
                                        fontSize: "12px",
                                    }}
                                    className={"pc_regular"}
                                    id={"Viewreport6"}
                                >
                                    {this.props?.location?.state?.data?.appno ?? ""}
                                </Text>
                            </Div>
                        </Div>
                        <Div
                            style={{
                                padding: "22px 24px 12px",
                                height: "88vh",
                                overflow: "auto",
                            }}
                            id={"Viewreport7"}
                        >
                            <Div style={{ position: "relative", marginBottom: "8%" }}>
                                <img
                                    alt={"search"}
                                    src={search}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: "12px",
                                        bottom: 0,
                                        margin: "auto",
                                    }}
                                    id={"Viewreport8"}
                                />
                                <input
                                    placeholder={" Search"}
                                    style={{
                                        width: "100%",
                                        border: "1px solid #E0E0E0",
                                        height: "40px",
                                        borderRadius: "8px",
                                        color: "#101010",
                                        paddingLeft: "32px",
                                    }}
                                    id={9}
                                />
                            </Div>
                            {this.state.report_docs.map((data, i) => {
                                return (
                                    <ListDiv
                                        data={data}
                                        created={this.props?.location?.state?.created}
                                        changeUi={this.changeUi}
                                        active={this.state.fileData}
                                    />
                                );
                            })}
                        </Div>
                    </Col>
                    <Col
                        key={"0"}
                        name="qdm-col-12"
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        textAlign="left"
                        lg="9"
                        xs="12"
                        md="9"
                        sm="9"
                        style={{
                            padding: 0,
                            height: "calc(100vh - 8px)",
                        }}
                        id={10}
                    >
                        <div
                            style={{
                                padding: "22px 24px 12px",
                                borderBottom: "2px solid #e0e0e0",
                                backgroundColor: "#FFF",
                                justifyContent: "space-between",
                                display: "flex",
                            }}
                            id={"Viewreport11"}
                        >
                            <Div>
                                <Div>
                                    <Text
                                        key={"0"}
                                        name="Today"
                                        style={{
                                            color: "#101010",
                                            fontSize: "20px",
                                        }}
                                        className={"pc_semibold"}
                                        id={"Viewreport12"}
                                    >
                                        {this.state?.fileData?.[0]?.fileName}
                                    </Text>
                                </Div>
                                <div>
                                    <Text
                                        key={"0"}
                                        name="Today"
                                        style={{
                                            color: "#B6B6B6",
                                            fontSize: "12px",
                                        }}
                                        className={"pc_regular"}
                                        id={"Viewreport13"}
                                    >
                                        {moment.unix(this.props?.location?.state?.data?.createddate).format("DD-MM-YYYY")}
                                    </Text>
                                </div>
                            </Div>
                            <div>
                                <img
                                    alt={"close"}
                                    src={close}
                                    style={{
                                        width: "40px",
                                        height: "40px",
                                        border: "1px solid #E0E0E0",
                                        padding: "12px",
                                        borderRadius: "8px",
                                    }}
                                    id={"Viewreport14"}
                                    onClick={() =>
                                        this.props.history.push(Routes.PatientDashBoard)
                                    }
                                />
                            </div>
                        </div>
                        <Viewer uri={this.state.fileData} />
                    </Col>
                    <Icons
                        key={"0"}
                        fontIcon="download"
                        ariaHidden="true"
                        size="small"
                        className={"downloadViewIcon"}
                        onClick={this.downloadFun}
                        id={"Viewreport15"}
                    ></Icons>
                </Row>
            </Div>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, actions)(withRouter(Viewreport));