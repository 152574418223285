import React from "react";
import { Div } from "qdm-component-library";
import { Button } from "@material-ui/core";
import {
  TopNavbar,
  PatientIdentification,
  PatientNames,
  PatientCommunication,
  PatientCotactPoint,
  PatientAddress,
  NextOfKinDetails,
  PatientCategory,
  PatientBirthDetails,
  TopHeader,
  LoadingComp,
} from "../../../components";
import {
  patientRegister,
  patientRegisterTitle,
} from "../../../utils/routesList";
import { Routes } from "../../../router/routes";
import { withQueue, withAllContexts } from "../../../HOCs";
import { withRouter } from "react-router-dom";
import {
  AlertProps,
  checkError,
  upsertPerson,
  fetchQueueData,
  makeQueueData,
  putOnHold,
  _calculateAge,
  uploadImage,
  fetchUserInfo,
  ValidateEmail,
  getUtcTime,
  UIColor,
  getImgUrl,
  dataUrlToFile,
  mobileNumberValidation,
} from "../../../utils";
import { AlertContext } from "../../../contexts";
import moment from "moment";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { PatientJSON, PatientNextJSON, PatientNEXTDataJSON } from "./genJson";
import { checkWithCasbin } from "../../../utils/permissonHandling";
import { CountryRace } from "./countryDemonomy";

class PatientRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: 1,
      allMasters: [],
      bookerId: null,
      patientbookerId: null,
      statusData: {},
      Patientstate: {},
      Nas: [],
      Sbi: [],
      City: {},
      loading: true,
      btnName: false,
      personInfo: {},
      kadOpen: false,
      isPersonUpdate:false,
    };
    this.successfun = this.successfun.bind(this);
    this.nextofKin = this.nextofKin.bind(this);
    this.setActive = this.setActive.bind(this);
    this.fetchQueueData = fetchQueueData.bind(this);
    this.putOnHold = putOnHold.bind(this);
    this.fetchUserInfo = fetchUserInfo.bind(this);
  }

  handleKad = () => {
    this.setState({
      ...this.state,
      kadOpen: true,
    });
  };

  async successfun(files) {
    debugger
    console.log(files, "successfun")
    if (this.state?.activeId === 2) {
      if (this.props.nextOfKin?.addformdetails?.length === 0) {
        return this.handleaddNextOfKin("nextOfKin", "setNextOfKin");
      }
    }

    let UploadedFiles = [];
    this.setState({
      ...this.state,
      btnName: true,
    });
    UploadedFiles = await uploadImage(files);
    let uploadedImg = [];
    if (this.props.patientData?.details?.image?.oldData) {
      uploadedImg = this.props.patientData?.details?.image?.oldData;
    } else {
      uploadedImg = await uploadImage(
        [
          this.props.patientData?.details?.image?.file
            ? this.props.patientData?.details?.image?.file
            : this.props.patientData?.details?.image?.url,
        ] || []
      );
    }
    // ----------------------------------
    var errBooll;
    var errBooll1;
    this.props.patientData["setPatientData"]({
      ...this.props.patientData,
      uploadFiles: UploadedFiles,
    });
    // eslint-disable-next-line no-unused-expressions
    this.props.patientData.names?.forEach((_, i) => {
      if (this.validateNames(i, "patientData", "setPatientData")) {
      } else {
        errBooll = true;
      }
    });
    // eslint-disable-next-line no-unused-expressions
    this.props.patientData.identification?.forEach((_, i) => {
      if (this.validateIdentifications(i, "patientData", "setPatientData")) {
      } else {
        errBooll1 = true;
      }
    });
    // console.log('*****', data, data1)

    const detsils = this.validateDeatils(null, "patientData", "setPatientData");
    // const contect = this.validateContact(
    //   null,
    //   "patientData",
    //   "setPatientData",
    //   this.props?.patientData
    // );
    if (!errBooll && !errBooll1 && detsils) {
      if (!this.props.location?.state?.newUser) {
        const patientDetails = this.props?.patientData;
        const FilledMobile = () => {
          if (
            patientDetails?.contact[0]?.mode?.value === "Phone" &&
            patientDetails?.contact[0]?.mobile
          ) {
            return this.props.READ_PERSON;
          } else if (
            (patientDetails?.contact[1]?.mode?.value === "E-mail" ||
              patientDetails?.contact[1]?.mode?.value === "Email") &&
            patientDetails?.contact[1]?.mobile
          ) {
            return this.props.READ_PERSON;
          }
        };
        
        console.log(patientDetails);
        let temp_id = patientDetails?.identification.map((x) => {
          return {
            value: x.id_number,
            Type: x?.id_type,
            // assigner: x?.id_source?._id,
            // system: "",
            // files: [],
            // period: [
            //   {
            //     start: getUtcTime(),
            //     end: x.expiry_date ? getUtcTime(v.expiry_date) : 0,
            //     id: 0,
            //   },
            // ],
          };
        });

        let compare_id = patientDetails?.identification.map((x) => {
          return {
            id: x.id_number,
            display: x?.id_type?.value,
          };
        });

        const payload = {
          phone:
            patientDetails?.contact[0]?.mode?.value === "Phone" ||
            !this.props.patientData.contact[0]?.mode?.value
              ? `${patientDetails?.contact[0]?.mobile_code.value}${
                  patientDetails?.contact[0]?.mobile ?? ""
                }`
              : "",
          dob: moment(patientDetails?.details?.dob).format("DD/MM/YYYY"),
          firstName: patientDetails?.names[0]?.first_name ?? "",
          lastName: patientDetails?.names[0]?.last_name ?? "",
          middleName: patientDetails?.names[0]?.middle_name ?? "",
          prefix: patientDetails?.names[0]?.prefix?._id ?? "",
          suffix: patientDetails?.names[0]?.suffix?._id ?? "",
          use: patientDetails?.names[0]?.use?._id ?? "",
          gender: patientDetails?.details?.gender?.value,
          email:
            patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() ===
              "e-mail" ||
            patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() === "email"
              ? patientDetails?.contact[1]?.mobile || ""
              : "",
          photo: uploadedImg || [],
          identifier: temp_id,
          compare_id: compare_id,
        };
        const { isBack, patientId, isModify } =
          this?.props?.location?.state || {};
        if (isBack || isModify) {
          this.handlesubmit(null, uploadedImg);
          return;
        }
        const {
          error,
          data: CheckUser,
          getData,
        } = await upsertPerson(
          FilledMobile(),
          this.props.UPSERT_PERSON,
          payload
        );
        var bookerId = 0;
        console.log(bookerId + getData);
        const isPatient =
          error &&
          CheckUser.startsWith("User Already Exists for") &&
          getData?.payload?.data?.allData?.Patientname?.length === 0;
        if (error && !isPatient) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Patient details already exists!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          this.setState({ activeId: 1 });
          return;
        } else {
          this.handlesubmit(null, uploadedImg);
          return;
        }
      } else {
        const patientDetails = this.props?.patientData;
        const FilledMobile = () => {
          if (
            patientDetails?.contact[0]?.mode?.value === "Phone" &&
            patientDetails?.contact[0]?.mobile
          ) {
            return this.props.READ_PERSON;
          } else if (
            (patientDetails?.contact[1]?.mode?.value === "E-mail" ||
              patientDetails?.contact[1]?.mode?.value === "Email") &&
            patientDetails?.contact[1]?.mobile
          ) {
            return this.props.READ_PERSON;
          }
        };
        // alert("Else")
       
        console.log(patientDetails?.identification);
        let temp_id = patientDetails?.identification.map((x) => {
          return {
            value: x.id_number,
            Type: x?.id_type?._id,
            assigner: x?.id_source?._id,
            id: 0,
            system: "",
            files: [],
            period: [
              {
                start: getUtcTime(),
                end: x.expiry_date ? getUtcTime(x.expiry_date) : 0,
                id: 0,
              },
            ],
          };
        });

        let compare_id = patientDetails?.identification.map((x) => {
          return {
            id: x.id_number,
            display: x?.id_type?.value,
          };
        });
        const payload = {
          phone:
            patientDetails?.contact[0]?.mode?.value === "Phone" ||
              !this.props.patientData.contact[0]?.mode?.value
              ? `${patientDetails?.contact[0]?.mobile_code.value}${patientDetails?.contact[0]?.mobile ?? ""
              }`
              : "",
          dob: moment(patientDetails?.details?.dob).format("DD/MM/YYYY"),
          firstName: patientDetails?.names[0]?.first_name ?? "",
          lastName: patientDetails?.names[0]?.last_name ?? "",
          middleName: patientDetails?.names[0]?.middle_name ?? "",
          prefix: patientDetails?.names[0]?.prefix?._id ?? "",
          suffix: patientDetails?.names[0]?.suffix?._id ?? "",
          use: patientDetails?.names[0]?.use?._id ?? "",
          gender: patientDetails?.details?.gender?.value,
          email:
            patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() ===
              "e-mail" ||
              patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() === "email"
              ? patientDetails?.contact[1]?.mobile || ""
              : "",
          photo: uploadedImg || [],
          identifier: temp_id,
          compare_id: compare_id,
        };
        const {
          error,
          data: CheckUser,
          getData,
        } = await upsertPerson(
          FilledMobile(),
          this.props.UPSERT_PERSON,
          payload
        );
        var bookerId = 0;
        console.log(bookerId + getData);
        if (error) {
          this.setState({
            ...this.state,
            btnName: false,
          });
          // alert(JSON.stringify(CheckUser))
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: CheckUser || "Patient details already exists!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          this.setState({ activeId: 1 });
          return;
        } else {
          bookerId = CheckUser.payload?.data?.id || 0;
          this.handlesubmit(bookerId, uploadedImg);
        }
      }
    } else {
      this.setState({
        ...this.state,
        btnName: false,
      });
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: this.SnackMsg(),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    // ----------------------------------
  }

  SnackMsg = () => {
    if (this.props?.patientData?.contact?.error?.code_err) {
      return "Invalid Country code";
    } else if (this.props?.patientData?.contact?.error?.number_err) {
      return "Please enter valid mobile number";
    } else {
      return "Please fill all the mandatory fields";
    }
  };

  FlterOptions = (type) => {
    const { allMasters } = this.state;
    var lista = [];
    const data = allMasters?.[type];
    for (let index in data) {
      var datatemp = {
        ...data[index],
        value: data[index]?.coding?.display,
        label: data[index]?.coding?.display,
        _id: data[index]?.coding?._id,
        CodeableConcept_id: data[index]?._id,
      };
      lista.push(datatemp);
    }
    return lista;
  };

  getAge = (date) => {
    return Math.floor(
      moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
    );
  };

  async componentDidMount() {
    await this.getallmasters();
    const exitPatient = this.props?.location?.state?.patientData;
    console.log("exitPatient" , exitPatient)
    let patientData = this.props["patientData"];
  console.log("patientData" , patientData)
    const nextOfKinData = this.props.nextOfKin;
    let { isBack, key } = this?.props?.location?.state || {};
    console.log("isBack" , isBack)
    console.log("key" , key)
    this.setState({
      ...this.state,
      loading: false,
    });
    if (!isBack) {
      const phoneOpt = this.FlterOptions("CONTACTSYSTEM").find(
        (v) => v.value?.toLowerCase() === "phone"
      );
      patientData.addformdetails = [{}];
      patientData.identification = [{}];
      patientData.names = [{}];
      patientData.details = {};
      patientData.statusDetails = {};
      patientData.contact = [
        {
          mode: {
            value: phoneOpt?.value,
            _id: phoneOpt?._id,
          }
        }
      ];
      patientData.uploadFiles = [];
      patientData.communication = {};
      patientData.address = [];
      patientData.index = null;
      patientData.details["age"] = "";
      patientData["setPatientData"]({
        ...patientData,
      });
      nextOfKinData.setNextOfKin({
        identification: [{}],
        names: [{}],
        details: {},
        contact: [],
        address: [],
        addformdetails: [],
        index: null,
      });
      this.props.dataPayload["setadditnaldata"]({
        ...this.props.dataPayload,
        additnaldata: {},
        birthdetails: {},
      });
    }
    if (!isBack && exitPatient) {
      debugger
      const persondata = await this.props.READ_PERSON({
        phone: exitPatient,
        allData: true,
      });
      const res = persondata?.payload?.data?.[0]?.[0];
      console.log("res" , res)
      if (res) {
        const ageTypeMaster_ = await this.FlterOptions("VITALSUOM");
        const use =
          (this.FlterOptions("USE") || [])?.find((g) => {
            if (
              g?.value?.toLowerCase?.() ===
              res?.names?.[0]?.use?.toLowerCase?.()
            ) {
              return true;
            }
            return false;
          }) || {};
        const prefix =
          (this.FlterOptions("NAMEPREFIX") || [])?.find((g) => {
            if (
              g?.value?.toLowerCase?.() ===
              res?.names?.[0]?.prefix?.toLowerCase?.()
            ) {
              return true;
            }
            return false;
          }) || {};
        patientData.names[0] = {
          first_name: res?.names?.[0]?.text ?? "",
          middle_name: res?.names?.[0]?.given ?? "",
          last_name: res?.names?.[0]?.family ?? "",
          use,
          prefix: prefix,
          suffix: {},
        };

        patientData.identification["id_number"] =
          res?.Patientname?.[0]?.identifier?.[0]?.value ?? "";
        patientData.identification["id_source"] =
          res?.Patientname?.[0]?.identifier?.[0]?.value ?? "";
        patientData.details["dob"] =
          moment(res?.birthDay, "DD/MM/YYYY").format("YYYY-MM-DD") ?? "";
        patientData.details["gender"] =
          (this.FlterOptions("GENDER") || [])?.find((g) => {
            if (g?.value?.toLowerCase?.() === res?.gender?.toLowerCase?.()) {
              return true;
            }
            return false;
          }) || {};
        patientData.details["age"] = this.getAge(res?.birthDay);
        patientData.details["ageType"] = res?.birthDay
          ? this.calculateAgeType(
            moment(res?.birthDay, "DD/MM/YYYY").format("YYYY-MM-DD"),
            ageTypeMaster_
          )
          : { value: "", label: "" };
        patientData.details["nationality"] = {
          value: res?.Patientname?.[0]?.Patientname?.[0]?.nationality ?? "",
        };
        patientData.contact = []
        let obj = {}

        obj["mobile"] =
          res?.telecom
            ?.find((val) => val.system === "Phone" && val.use === "Mobile")
            ?.value?.slice(3) ?? "";
        const mobileCode =
          res?.telecsom
            ?.find((val) => val.system === "Phone" && val.use === "Mobile")
            ?.value?.slice(0, 3) ?? "";
          obj["mobile_code"] =
          this.state.Sbi.find((val) => val.value === mobileCode) || {};

          obj["mode"] =
          this.FlterOptions("CONTACTSYSTEM").find(
            (d) => d.value?.toLowerCase() === "phone"
          ) || {};
          
          patientData.contact.push(obj)
          
        const personDetails = await this.props.PATIENT_DETAILS_READ({
          key: key,
          // key: '10306'
        });
        const ageTypeMaster = await this.FlterOptions("VITALSUOM");
        const state = this.state;
        this.setState({
          ...state,
          Patientstate: personDetails?.payload?.data?.[0],
          personInfo: res,
        });
        const personDetailsData = personDetails?.payload?.data?.[0];
        console.log("personDetailsData" , personDetailsData)
        if (personDetailsData) {
          const p_details = await PatientJSON(
            personDetailsData,
            this.props.PATIENT_SDI_READ,
            ageTypeMaster
          );
          // p_details.contact.mobile = patientData.contact.mobile;
          // p_details.contact.mobile_code = patientData.contact.mobile_code;
          // p_details.contact.mode = patientData.contact.mode;
          patientData["setPatientData"]({
            ...patientData,
            ...p_details,
          });
        }
        const patient_importance = this.FlterOptions("PATIENTIMPORTANCETYPE");
        const p_n_details = PatientNextJSON(personDetailsData, {
          patient_importance,
        });
        this.props.dataPayload["setadditnaldata"]({
          ...this.props.dataPayload,
          ...p_n_details,
        });
        const nextOfKinArr = (personDetailsData?.link?.[0]?.patient || []).map(
          (e) => {
            const key = e.split("/")[1];
            return this.props.PATIENT_DETAILS_READ({ key });
          }
        );
        const nextOfKinData = await Promise.all(nextOfKinArr);
        const nextOfKinInfo = nextOfKinData.map((d) => {
          const info = d?.payload?.data?.[0] || {};
          return PatientNEXTDataJSON(info, ageTypeMaster);
        });
        this.props.nextOfKin["setNextOfKin"]({
          ...this.props.nextOfKin,
          addformdetails: [...nextOfKinInfo],
        });
      }
    }

    if (
      (this.props.queueManagement?.data || []).length === 0 &&
      (this.props.queueManagement?.previousData || []).length === 0 &&
      (this.props.queueManagement?.onHoldData || []).length === 0
    ) {
      this.fetchQueueData();
    } else {
      const userData = await this.fetchUserInfo();
      const permissionA = await checkWithCasbin(["queueSideNav"]);
      const permissionData = permissionA.write.find(
        (d) =>
          d === "appointmentQueueDataN" ||
          d === "appointmentQueueDataD" ||
          d === "appointmentQueueDataF"
      );
      const [, displayData, onHoldData, completedData] = makeQueueData(
        permissionData,
        this.props.queueManagement.allData,
        userData,
        false
      );
      this.props.queueManagement.setDisplayData(displayData);
      this.props.queueManagement.setOnHoldData(onHoldData);
      this.props.queueManagement.setPreviousData(completedData);
    }
   
    
  }

  calculateAgeType = (value, ageTypeMaster_) => {
    let days = moment().diff(moment(value, "YYYY-MM-DD"), "days");
    if (days <= 90) {
      let option = ageTypeMaster_.filter((val) => val.label === "Days")[0];
      return option ? option : { value: "Years", label: "Years" };
    } else if (days > 90 && days < 365) {
      let option = ageTypeMaster_.filter((val) => val.label === "Months")[0];
      return option ? option : { value: "Years", label: "Years" };
    } else if (days >= 365) {
      let option = ageTypeMaster_.filter((val) => val.label === "Years")[0];
      return option ? option : { value: "Years", label: "Years" };
    }
  };

  validateIdentifications = (i, saveto, setTo) => {
    // ----------------------------------
    const patientData = this.props[saveto];
    var valerrlist;
    if (this.state.activeId === 1) {
      valerrlist = ["id_number", "id_source", "id_type"];
    } else {
      valerrlist = ["id_type", "id_number"];
    }
    const error = {};
    let isValidate = true;
    valerrlist.map((val) => {
      if (val === "expiry_date") {
        if (patientData.identification[i][val]) {
          const isGeaterThan100yr =
            moment(patientData.identification[i][val]).diff(moment(), "years") >
            100;
          const isPastDate =
            moment(patientData.identification[i][val]) <
            moment().startOf("day");
          if (isPastDate && isGeaterThan100yr) {
            isValidate = false;
          } else {
            error[val] = false;
          }
        } else {
          error[val] = true;
        }
        return error;
      } else {
        // if (!patientData.identification[i][val]) {
        if (
          patientData.identification[i][val] === null ||
          patientData.identification[i][val] === undefined
        ) {
          error[val] = true;
          isValidate = false;
        } else {
          if (typeof patientData.identification[i][val] === "string") {
            if (!patientData.identification[i][val]) {
              error[val] = true;
              isValidate = false;
              if (patientData.identification[i][val] === 0) {
                error[val] = false;
                isValidate = true;
              }
            }
          } else if (typeof patientData.identification[i][val] === "object") {
            if (Object.keys(patientData.identification[i][val])?.length === 0) {
              error[val] = true;
              isValidate = false;
            }
          }
        }
        //   } else {
        //     error[val] = false;
        //   }
        return error;
      }
    });
    patientData.identification[i].error = { ...error };
    patientData[setTo]({ ...patientData });
    return isValidate;
    // ----------------------------------
  };

  validateNames = (i, saveto, setTo) => {
    // ----------------------------------
    const patientData = this.props[saveto];
    const valerrlist = [
      //"use",
      //"prefix",
      "first_name",
      // "middle_name",
      // "last_name",
      // "suffix",
    ];
    const error = {};
    let isValidate = true;
    valerrlist.map((val) => {
      const valCondition =
        val === "prefix" || val === "suffix" || val === "use";
      if (
        (valCondition && !patientData.names[i][val]?.value) ||
        !patientData.names[i][val]
      ) {
        error[val] = true;
        isValidate = false;
        if (patientData.names[i][val] === 0) {
          error[val] = false;
          isValidate = true;
        }
      } else {
        error[val] = false;
      }
      return error;
    });
    patientData.names[i].error = { ...error };
    patientData[setTo]({ ...patientData });
    return isValidate;
    // ----------------------------------
  };

  validateDeatils = (i, saveto, setTo) => {
    // ----------------------------------
    const patientData = this.props[saveto];
    const valerrlist = ["gender", "dob", "nationality", "ageType"];
    const error = {};
    let isValidate = true;
    valerrlist.map((val) => {
      const valCondition =
        val === "ageType" || val === "gender" || val === "nationality";
      if (
        (valCondition && !patientData.details?.[val]?.value) ||
        !patientData.details[val]
      ) {
        error[val] = true;
        isValidate = false;
        if (patientData.details[val] === 0) {
          error[val] = false;
          isValidate = true;
        }
      } else {
        if (val === "dob") {
          let years = moment().diff(patientData.details[val], "years");
          let days = moment().diff(patientData.details[val], "days");
          if (!(years < 200 && days > 0)) {
            error[val] = true;
            isValidate = false;
          } else {
            error[val] = false;
          }
        } else {
          error[val] = false;
        }
      }
      return error;
    });
    patientData.details.error = { ...error };
    patientData[setTo]({ ...patientData });
    return isValidate;
    // ----------------------------------
  };

  validateContact = (i, saveto, setTo, modeVal) => {
    // ----------------------------------
    const patientData = this.props[saveto];
    const valerrlist = ["mobile", "mode", "mobile_code"];
    // const valerrlist = ["mobile"];
    const error = {};
    let isValidate = true;
    valerrlist.map((val) => {
      if (!patientData.contact[val]) {
        error[val] = true;
        isValidate = false;
        if (patientData.contact[val] === 0) {
          error[val] = false;
          isValidate = true;
        }
      } else {
        if (val === "mobile") {
          if (modeVal?.contact?.mode?.value === "Phone") {
            let res__ = mobileNumberValidation(
              modeVal?.contact?.mobile,
              modeVal?.contact?.mobile_code?.value
            );
            console.log("res__", res__);
            if (res__ === "Invalid Country code") {
              error["code_err"] = true;
              isValidate = false;
            } else if (res__ === false) {
              error["number_err"] = true;
              isValidate = false;
            } else {
              error[val] = false;
              isValidate = true;
            }
          } else if (
            (modeVal?.contact?.mode?.value === "E-mail" ||
              modeVal?.contact?.mode?.value === "Email") &&
            ValidateEmail(patientData.contact[val])
          ) {
            error[val] = false;
            isValidate = true;
          } else if (!modeVal?.contact?.mobile) {
            error[val] = true;
            isValidate = false;
          }
        } else {
          error[val] = false;
        }
      }
      return error;
    });
    patientData.contact.error = { ...error };
    patientData[setTo]({ ...patientData });
    return isValidate;
    // ----------------------------------
  };

  async nextofKin() {
    // ----------------------------------
    var errBooll;
    var errBooll1;
    const data = this.props.patientData.names?.map((v, i) => {
      if (this.validateNames(i, "patientData", "setPatientData")) {
        return (errBooll = false);
      } else {
        return (errBooll = true);
      }
    });
    const data1 = this.props.patientData.identification?.map((v, i) => {
      if (this.validateIdentifications(i, "patientData", "setPatientData")) {
        return (errBooll1 = false);
      } else {
        return (errBooll1 = true);
      }
    });
    console.log("*****", data, data1);
    const detsils = this.validateDeatils(null, "patientData", "setPatientData");
    // const contect = this.validateContact(
    //   null,
    //   "patientData",
    //   "setPatientData",
    //   this.props?.patientData
    // );
    if (!errBooll && !errBooll1 && detsils) {
      if (!this.props.location?.state?.newUser) {
        this.setState({ activeId: 2 });
      } else {
        const patientDetails = this.props?.patientData;

        const FilledMobile = () => {
          if (
            patientDetails?.contact?.[0]?.mode?.value === "Phone" &&
            patientDetails?.contact?.[0]?.mobile
          ) {
            return this.props.READ_PERSON;
          } else if (
            (patientDetails?.contact[1]?.mode?.value === "E-mail" ||
              patientDetails?.contact[1]?.mode?.value === "Email") &&
            patientDetails?.contact[1]?.mobile
          ) {
            return this.props.READ_PERSON;
          }
          return this.props.READ_PERSON;
        };
        const payload = {
          phone:
            patientDetails?.contact?.[0]?.mode?.value === "Phone" ||
            !this.props.patientData.contact[0]?.mode?.value
              ? `${patientDetails?.contact[0]?.mobile_code.value}${
                  patientDetails?.contact[0]?.mobile ?? ""
                }`
              : "",
          dob: moment(patientDetails?.details?.dob).format("DD/MM/YYYY"),
          firstName: patientDetails?.names[0]?.first_name ?? "",
          lastName: patientDetails?.names[0]?.last_name ?? "",
          gender: patientDetails?.details?.gender?.value,
          email:
          patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() ===
          "e-mail" ||
          patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() === "email"
          ? patientDetails?.contact[1]?.mobile || ""
          : "",
        };
        const {
          error,
          data: CheckUser,
          getData,
        } = await upsertPerson(FilledMobile(), () => { }, payload);
        var bookerId = 0;
        console.log(bookerId + getData);
        if (error) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Patient details already exists!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return;
        } else {
          this.setState({ activeId: 2, patientbookerId: null });
          return;
        }
      }
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: this.SnackMsg(),
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
    // ----------------------------------
  }

  setActive(Id) {
    if (this.state.activeId === 3 && Id === 2) {
      this.setState({ activeId: Id });
    }
    if (this.state.activeId === 2 && Id === 3) {
      this.setState({ activeId: Id });
    }
  }

  styles = {
    outlinedButton: {
      border: `1px solid #01205c`,
      color: UIColor.primaryColor.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
      marginRight: "10px",
    },
    containedButton: {
      color: "#fff",
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 14,
      fontFamily: "pc_medium !important",
    },
  };

  handlesubmit = async (bookerId, UploadedFiles) => {
    const { nextRoute } = this.props.location?.state ?? {};
    const nr = nextRoute ? nextRoute : Routes.PatientDashBoard;
    const patientDetails = this.props?.patientData;
    const dataPayload = this.props?.dataPayload;
    const nextOfKinDetails = this.props?.nextOfKin;
    const UserId = this.props?.location?.state?.patientId;

    // identifier details
    const identifier = patientDetails?.identification?.map((v) => {
      return {
        user: v?.id_type?._id,
        system: "",
        assigner: v?.id_source?._id ?? "",
        value: v.id_number ? v.id_number : "",
        files: v.doc ? [v.doc] : [],
        period: [
          {
            start: getUtcTime(),
            end: v.expiry_date ? getUtcTime(v.expiry_date) : 0,
            id: 0,
          },
        ],
        Type: "",
        id: 0,
      };
    });

    // name details
    const nameData = patientDetails?.names?.map((v) => {
      return {
        use: v?.use?._id || "",
        text: v?.first_name || "",
        family: v?.last_name || "",
        given: v?.middle_name || "",
        prefix: v?.prefix?._id || "",
        suffix: v?.suffix?._id || "",
        period: [
          // {
          //      "start": Math.floor(Date.now()/1000),
          // "end": 0
          //     "id": 0
          // }
        ],
        id: 0,
      };
    });

    const addressData = patientDetails?.address?.map((v) => {
      return {
        use: v?.use?._id ?? "",
        text: v?.addressLine1 ?? "", // line 1
        line: (v?.addressLine2 ?? "") + "/" + (v?.addressLine3 ?? ""), // line 2
        city: v?.city?._id ?? "",
        district: v?.district?._id ?? "",
        state: v?.state?._id ?? "",
        postalCode: v?.pincode?._id ?? "",
        country: v?.country?._id ?? "",
        latitude: "",
        longitude: "",
        Type: v?.type?._id ?? "",
        id: 0,
      };
    });

    const languageData = patientDetails?.communication?.clanguage?.map(
      (v) => v?._id ?? ""
    );

    const nextofkinnameDetails = (v) =>
      v?.names?.map((v) => {
        return {
          use: v?.use?._id || "",
          text: v?.first_name || "",
          family: v?.last_name || "",
          given: v?.middle_name || "",
          prefix: v?.prefix?._id || "",
          suffix: v?.suffix?._id || "",
          period: [
            // {
            //      "start": Math.floor(Date.now()/1000),
            // "end": 0
            //     "id": 0
            // }
          ],
          id: 0,
        };
      });

    const nextofkinaddressData = (v) =>
      v?.address?.map((v) => {
        return {
          use: v?.use?._id ?? "",
          text: v?.addressLine1 ?? "", // line 1
          line: (v?.addressLine2 ?? "") + "/" + (v?.addressLine3 ?? ""), // line 2
          city: v?.city?._id ?? "",
          district: v?.district?._id ?? "",
          state: v?.state?._id ?? "",
          postalCode: v?.pincode?._id ?? "",
          country: v?.country?._id ?? "",
          latitude: "",
          longitude: "",
          Type: v?.type?._id ?? "",
          id: 0,
        };
      });

    const nextofkinidentifier = (v) =>
      v?.identification?.map((v) => {
        return {
          user: v?.id_type?._id,
          system: "",
          assigner: v?.id_source?._id ?? "",
          value: v.id_number ? v.id_number : "",
          files: v.doc ? [v.doc] : [],
          period: [
            {
              start: getUtcTime(),
              end: v.expiry_date ? getUtcTime(v.expiry_date) : 0,
              id: 0,
            },
          ],
          Type: "",
          id: 0,
        };
      });
    const promArr = this.props?.nextOfKin?.addformdetails?.map((v) =>
      uploadImage([v?.details?.image?.file] || [])
    );
    const nextOfKinImg = await Promise.all(promArr);
    const LinknextOfKin = this.props?.nextOfKin?.addformdetails?.map((v, i) => {
      return {
        identifier: [...nextofkinidentifier(v)],

        active:
          this.props.location?.state?.newUser ||
            this.props.location?.state?.fromQueue
            ? true
            : nextOfKinDetails?.statusDetails?.status1 === "Active"
              ? true
              : false,
        activereason:
          v?.statusDetails?.status1 === "Active"
            ? ""
            : v?.statusDetails?.reason1?._id ?? "",
        // isdeceased: v?.statusDetails?.status
        // 	? v?.statusDetails?.status == "Deceased"
        // 		? "CodingMaster/11844"
        // 		: "CodingMaster/11845"
        // 	: "",
        isdeceased: patientDetails?.statusDetails?.status
          ? this.FlterOptions("DECEASEDSTATUS").filter(
            (val) => val?.value === patientDetails?.statusDetails?.status
          )?.[0]?._id ?? ""
          : "",
        deceaseddate: v?.statusDetails?.time
          ? getUtcTime(v?.statusDetails?.time)
          : 0,
        deceasedreason: v?.statusDetails?.reason?._id ?? "",
        telecom: [
          {
            system: v?.contact[0]?.mode?._id ?? "",
            value: v?.contact[0]?.mobile ?? "", // value (+9180121)
            use: v?.contact[0]?.use?._id ?? "", // type (phone, email)
            rank: v?.contact[0]?.priority?._id ?? "",
            id: 0,
          },
          {
            system: v?.contact[1]?.mode?._id ?? "",
            value: v?.contact[1]?.mobile ?? "", // value (+9180121)
            use: v?.contact[1]?.use?._id ?? "", // type (phone, email)
            rank: v?.contact[1]?.priority?._id ?? "",
            id: 0,
          },
        ],
        // "url": nextOfKinDetails?.details?.image?.url ?? '',
        photo: nextOfKinImg[i] || [],
        name: [...nextofkinnameDetails(v)],
        age_type: v?.details?.ageType?._id ?? "",
        age: JSON.stringify(v?.details?.age) ?? "",
        nationality: v?.details?.nationality?._id ?? "",
        gender: v?.details?.gender?._id ?? "",
        birthDate: v.details.dob
          ? moment(v.details.dob).format("DD/MM/YYYY")
          : moment(new Date()).format("DD/MM/YYYY"),
        address: [...nextofkinaddressData(v)],
        approxAgeEntered: v?.details?.entered_age ?? false,
        maritalstatus: v?.details?.marital_status?._id ?? "",
        personID:
          this.props?.location?.state?.personId || this.state.bookerId || 0,
        OrgID: "",
        PractitionerID: [],
        alias: "",
        id: 0,
      };
    });

    // payload request
    const data = [
      {
        doc: {
          // "files": UploadedFiles || [],
          identifier: [...identifier],
          preferredLanguage:
            patientDetails?.communication?.planguage?.value ?? "",
          communicationLanguage: [...(languageData || [])],
          interpRequired: patientDetails?.communication?.isinterpreter,
          active:
            this.props.location?.state?.isBack ||
              this.props.location?.state?.newUser ||
              this.props.location?.state?.fromQueue
              ? true
              : patientDetails?.statusDetails?.status1 === "Active"
                ? true
                : false,
          activereason:
            patientDetails?.statusDetails?.status1 === "Active"
              ? ""
              : patientDetails?.statusDetails?.reason1?._id ?? "",
          // isdeceased: patientDetails?.statusDetails?.status
          // 	? patientDetails?.statusDetails?.status == "Deceased"
          // 		? "CodingMaster/11844"
          // 		: "CodingMaster/11845"
          // 	: "",
          isdeceased: patientDetails?.statusDetails?.status
            ? this.FlterOptions("DECEASEDSTATUS").filter(
              (val) => val?.value === patientDetails?.statusDetails?.status
            )?.[0]?._id ?? ""
            : "",
          deceaseddate: patientDetails?.statusDetails?.time
            ? getUtcTime(patientDetails?.statusDetails?.time)
            : 0,
          deceasedreason: patientDetails?.statusDetails?.reason?._id ?? "",

          // "deceaseddate": (patientDetails?.statusDetails?.status === 'Deceased') ? moment(patientDetails?.statusDetails?.time).unix() : 0,
          // "deceasedreason": (patientDetails?.statusDetails?.status === 'Deceased') ? (patientDetails?.statusDetails?.reason?._id ?? '') : "",

          // "url": patientDetails?.details?.image?.url ?? '',
          photo:
            UploadedFiles && UploadedFiles.length > 0
              ? UploadedFiles
              : this.state.personInfo?.photo?.[0]
                ? [
                  {
                    fileName: this.state.personInfo?.photo?.[0]?.fileName,
                    fileid: this.state.personInfo?.photo?.[0]?.fileid,
                    filetype: this.state.personInfo?.photo?.[0]?.filetype,
                    objectid: this.state.personInfo?.photo?.[0]?.objectid,
                  },
                ]
                : [],
          attachment:
            patientDetails?.uploadFiles?.length > 0
              ? patientDetails?.uploadFiles
              : [],
          telecom: [
            {
              system: patientDetails?.contact[0]?.mode?._id ?? "",
              value: patientDetails?.contact[0]?.mobile
                ? `${patientDetails?.contact[0]?.mode?.value?.toLowerCase?.() ===
                  "phone"
                  ? patientDetails?.contact[0]?.mobile_code?.value
                  : ""
                }${patientDetails?.contact[0]?.mobile}`
                : "",
              use: patientDetails?.contact[0]?.use?._id ?? "",
              rank: patientDetails?.contact[0]?.priority?._id ?? "",
              valueprefix:
                patientDetails?.contact[0]?.mode?.value?.toLowerCase?.() ===
                  "phone"
                  ? patientDetails?.contact[0]?.mobile_code?.value
                  : "",
              id: 0,
            },
            {
              system: patientDetails?.contact[1]?.mode?._id ?? "",
              value: patientDetails?.contact[1]?.mobile
                ? patientDetails?.contact[1]?.mobile
                : "",
              use: patientDetails?.contact[1]?.use?._id ?? "",
              rank: patientDetails?.contact[1]?.priority?._id ?? "",
              valueprefix:
                patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() ===
                "email"
                  ? patientDetails?.contact[1]?.mobile_code?.value
                  : "",
              id: 1,
            },
          ],

          name: [...nameData],
          gender: patientDetails?.details?.gender?._id ?? "",
          birthDate: patientDetails.details.dob
            ? moment(patientDetails.details.dob).format("DD/MM/YYYY")
            : 0,

          address: [...addressData],
          maritalstatus: patientDetails?.details?.marital_status?._id ?? "",

          link: [
            {
              patient: [...LinknextOfKin],
              Type: "",
            },
          ],
          age_type: patientDetails?.details?.ageType?._id ?? "",
          age: JSON.stringify(patientDetails?.details?.age) ?? "",
          nationality: patientDetails?.details?.nationality?._id ?? "",
          approxAgeEntered: patientDetails?.details?.entered_age ?? false,
          patientTypecode: dataPayload?.additnaldata?.patient_type?._id ?? "",
          patientImp_Code:
            dataPayload?.additnaldata?.patient_importance?._id ?? "",
          specialCourtesy_code:
            dataPayload?.additnaldata?.special_courtesy?._id ?? "",
          specialArng_code:
            dataPayload?.additnaldata?.special_arrangement?._id ?? "",
          raceCode: dataPayload?.additnaldata?.race?._id ?? "",
          ethinicityCode: dataPayload?.additnaldata?.ethnicity?._id ?? "",
          religionCode: dataPayload?.additnaldata?.religion?._id ?? "",
          bloodGroup: dataPayload?.additnaldata?.blood_group?._id ?? "",
          birthPlace: dataPayload?.birthdetails?.birth_place ?? "",
          birthTime: dataPayload.birthdetails.birth_time
            ? // ? moment(dataPayload.birthdetails.birth_time, "YYYY-MM-DD").unix()
            moment(dataPayload.birthdetails.birth_time).unix()
            : 0,
          birthGendercode: dataPayload?.birthdetails?.birth_gender?._id ?? "",
          genderIdentitycode:
            dataPayload?.birthdetails?.gender_identify?._id ?? "",
          disabilityCode: dataPayload?.birthdetails?.disability_code?._id ?? "",
          cadavericDonor: dataPayload?.birthdetails?.cadaveric_donar ?? false,
          citizenshipCode:
            dataPayload?.birthdetails?.citizenshio_code?._id ?? "",
          residencyStatus: dataPayload?.birthdetails?.residency_status
            ? dataPayload?.birthdetails?.residency_status === "legal"
              ? true
              : false
            : false,
          primarycare_Prac:
            dataPayload?.birthdetails?.primarycare_practitioner?._id ?? "",
          personID:
            this.props?.location?.state?.personId ||
            bookerId ||
            this.state.patientbookerId ||
            0,
          OrgID: "",
          PractitionerID: [],
          id: 0,
          alias: "",
        },
      },
    ];
    const {
      newUser,
      isBack,
      patientId,
      isModify,
      key,
      ids,
      patient_id,
      patient_key,
    } = this?.props?.location?.state;
    if (!(isModify || isBack)) {
      data[0].doc["statushistory"] = [
        {
          oldstatus: "",
          oldstatusreason: "",
          newstatus: "Active",
          fromperiod: moment().unix(),
          toperiod: 0,
        },
      ];
      data[0].doc["conditionhistory"] = [];
    } else {
      try {
        const newDate = moment().unix();
        let conditionHistoryStatus = patientDetails.statusDetails?.status;
        let statusHistoryStaus = patientDetails.statusDetails?.status1;
        const isRevoked = patientDetails.statusDetails?.isRevoked;
        if (isRevoked) {
          conditionHistoryStatus = "Revoked";
        }
        data[0].doc["statushistory"] = JSON.parse(
          JSON.stringify(this.state.Patientstate?.statushistory || [])
        );
        data[0].doc["conditionhistory"] = JSON.parse(
          JSON.stringify(this.state.Patientstate?.conditionhistory || [])
        );
        const newStatusHistory = {
          oldstatus: "",
          oldstatusreason: "",
          newstatus: statusHistoryStaus,
          fromperiod: newDate,
          toperiod: 0,
        };
        if (data[0].doc["statushistory"].length > 0) {
          const lastStatus = data[0].doc["statushistory"].find(
            (x) => x.toperiod === 0
          );
          if (lastStatus && lastStatus.newstatus !== statusHistoryStaus) {
            lastStatus.toperiod = newDate;
            newStatusHistory.oldstatus = lastStatus.newstatus;
            newStatusHistory.oldstatusreason =
              this.state.Patientstate?.activereason?._id || "";
            data[0].doc["statushistory"].push(newStatusHistory);
          }
        } else {
          data[0].doc["statushistory"].push(newStatusHistory);
        }
        const newConditionhistory = {
          olddeceasedstatus: "",
          fromdeceaseddate: newDate,
          olddeceasedreason: "",
          newdeceasedstatus: conditionHistoryStatus,
          todeceaseddate: 0,
        };
        if (conditionHistoryStatus) {
          if (data[0].doc["conditionhistory"].length > 0) {
            const lastStatus = data[0].doc["conditionhistory"].find(
              (x) => x.todeceaseddate === 0
            );
            if (
              lastStatus &&
              lastStatus.newdeceasedstatus !== conditionHistoryStatus
            ) {
              lastStatus.todeceaseddate = newDate;
              newConditionhistory.olddeceasedstatus =
                lastStatus.newdeceasedstatus;
              newConditionhistory.olddeceasedreason =
                this.state.Patientstate?.deceasedreason?._id || "";
              data[0].doc["conditionhistory"].push(newConditionhistory);
            }
          } else {
            if (conditionHistoryStatus) {
              data[0].doc["conditionhistory"].push(newConditionhistory);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    if (!newUser && this.state.personInfo?._id) {
      this.props.UPSERT_PERSON({
        phone:
          patientDetails?.contact[0]?.mode?.value === "Phone" ||
            !this.props.patientData.contact[0]?.mode?.value
            ? `${patientDetails?.contact[0]?.mobile_code.value}${patientDetails?.contact[0]?.mobile ?? ""
            }`
            : "",
        dob: moment(patientDetails?.details?.dob).format("DD/MM/YYYY"),
        firstName: patientDetails?.names[0]?.first_name ?? "",
        lastName: patientDetails?.names[0]?.last_name ?? "",
        middleName: patientDetails?.names[0]?.middle_name ?? "",
        prefix: patientDetails?.names[0]?.prefix?._id ?? "",
        suffix: patientDetails?.names[0]?.suffix?._id ?? "",
        use: patientDetails?.names[0]?.use?._id ?? "",
        gender: patientDetails?.details?.gender?.value,
        email:
          patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() === "e-mail" ||
            patientDetails?.contact[1]?.mode?.value?.toLowerCase?.() === "email"
            ? patientDetails?.contact[1]?.mobile || ""
            : "",
        updatedId: this.state.personInfo?._key,
        // photo: uploadedImg ?? "",
        photo:
          UploadedFiles && UploadedFiles.length > 0
            ? UploadedFiles
            : this.state.personInfo?.photo?.[0]
              ? [
                {
                  fileName: this.state.personInfo?.photo?.[0]?.fileName,
                  fileid: this.state.personInfo?.photo?.[0]?.fileid,
                  filetype: this.state.personInfo?.photo?.[0]?.filetype,
                  objectid: this.state.personInfo?.photo?.[0]?.objectid,
                },
              ]
              : [],
      });
    }
    if (isModify || isBack) {
      delete data[0].doc?.id;
      delete data[0].doc?.personID;
      delete data[0].doc?.alias;
    } else {
      await this.props.REGISTER_USERS({
        firstName: data[0]?.doc?.name?.[0]?.text ?? "",
        lastName: data[0]?.doc?.name?.[0]?.family ?? "",
        emailId:
          patientDetails?.contact[1]?.mode?.value === "E-mail" ||
          patientDetails?.contact[1]?.mode?.value === "Email"
            ? patientDetails?.contact[1]?.mobile || ""
            : "",
        mobileNo: data?.[0]?.doc?.telecom?.[0]?.value ?? "",
      });
    }

    const res = await this.props.ORGANIZATION_REGISTER_PATIENT({
      payloaddata: data[0],
      isUpdate: isModify || isBack,
      patientId: isModify ? ids : patientId,
      _id: this.state.Patientstate?._id || patient_id,
      _key: key || patient_key,
    });
    if (!res?.payload?.data?.Result?.[0]?.properties?.doc?.active) {
      await this.props.UPSERT_CANCEL_APP_PATIENT({
        personId: this.props.location?.state?.ids,
      });
    }
    const status = checkError(this.props.oraganizationRegisterPatient);

    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }

    if (res.payload?.data?.id?.validation_error) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: res.payload.data.id.validation_error[0].Errormsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg:
          isBack || isModify
            ? "Info Updated Successfully"
            : "PID generated successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      this.props.history.push({
        pathname: this.props.location?.state?.newUser
          ? Routes.financialRegistration
          : nr,
        state: {
          nextRoute: Routes.financialRegistration,
          personvisitedDataNames: this.props.patientData?.names,
          walkin: this.props.location?.state?.Ispatient,
          personId:
            this.props.location?.state?.personId ||
            res.payload?.data.Result[0].properties.doc.personID,
          patientId:
            this.props.location?.state?.patientId ||
            res.payload?.data.Result[0].properties.doc.id,
          patient_id:
            this.props.location?.state?.patient_id ||
            res.payload?.data.Result[0].properties.doc._id,
          patient_key:
            this.props.location?.state?.patient_key ||
            res.payload?.data.Result[0].properties.doc._key,
          appointmentId: this.props.location?.state?.appointmentId,
          userId:
            this.props.location?.state?.personId === 0
              ? ""
              : this.props.location?.state?.ids ||
                this.props.location?.state?.personId,
          mobileNumber: patientDetails?.contact[0]?.mobile
            ? `${
                patientDetails?.contact[0]?.mode?.value?.toLowerCase?.() ===
                "phone"
                  ? patientDetails?.contact[0]?.mobile_code?.value
                  : ""
              }${patientDetails?.contact[0]?.mobile}`
            : "",
          guest: !this.props.location?.state?.newUser,
          Ispatient: true,
          patientData: !this.props.location?.state?.Ispatient,
          prevRoute: Routes.assemblePatioentRegister,
          key: this.props.location?.state?.key,
        },
      });

      this.setState({
        patientbookerId: null,
        bookerId: null,
      });
    }
  };

  handleaddNextOfKin = async (saveto, setTo) => {
    if (this.props.nextOfKin.index !== null) {
      let patientData = this.props.nextOfKin;
      
      patientData.addformdetails[patientData.index] = {
        identification: patientData.identification,
        names: patientData.names,
        details: patientData.details,
        contact: patientData.contact,
        address: patientData.address,
      };

      patientData.identification = [{}];
      patientData.names = [{}];
      patientData.details = {};
      patientData.contact = [];
      patientData.address = [];
      patientData.index = null;
      patientData.setNextOfKin({
        ...patientData,
      });
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Updated succsssfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }

    var errBooll;
    var errBooll1;
    const data = this.props.nextOfKin.names?.map((v, i) => {
      if (this.validateNames(i, "nextOfKin", "setNextOfKin")) {
        return (errBooll = false);
      } else {
        return (errBooll = true);
      }
    });
    const data1 = this.props.nextOfKin.identification?.map((v, i) => {
      if (this.validateIdentifications(i, "nextOfKin", "setNextOfKin")) {
        return (errBooll1 = false);
      } else {
        return (errBooll1 = true);
      }
    });
    console.log("*****", data, data1);
    const detsils = this.validateDeatils(null, "nextOfKin", "setNextOfKin");
    // const contect = this.validateContact(
    //   null,
    //   "nextOfKin",
    //   "setNextOfKin",
    //   this.props?.nextOfKin
    // );

    if (!errBooll && !errBooll1 && detsils) {
      // const patientDetails = this.props?.nextOfKin
      // const FilledMobile = () => {
      //     if ((patientDetails?.contact?.mode?.value === "Phone") && (patientDetails?.contact?.mobile)) {
      //         return this.props.READ_PERSON
      //     } else if ((patientDetails?.contact?.mode?.value === "E-mail" ||
      //         patientDetails?.contact?.mode?.value === "Email") && (patientDetails?.contact?.mobile)) {
      //         return this.props.READ_PERSON
      //     }
      // }
      // const payload = {
      //     phone: ((patientDetails?.contact?.mode?.value === "Phone") || (!this.props.patientData.contact?.mode?.value)) ? (`+91${patientDetails?.contact?.mobile ?? ''}`) : '',
      //     dob: moment(patientDetails?.details?.dob ?? new Date()).format("DD/MM/YYYY"),
      //     firstName: patientDetails?.names[0]?.first_name ?? '',
      //     lastName: patientDetails?.names[0]?.last_name ?? '',
      //     gender: patientDetails?.details?.gender?.value,
      //     email: (patientDetails?.contact?.mode?.value === "E-mail" ||
      //         patientDetails?.contact?.mode?.value === "Email") ? (patientDetails?.contact?.mobile || "") : '',
      // };
      // const {
      //     error,
      //     data: CheckUser,
      //     getData,
      // } = await upsertPerson(
      //     FilledMobile(),
      //     this.props.UPSERT_PERSON,
      //     payload
      // );
      // var bookerId = 0;
      // console.log(bookerId + getData)
      // if (error) {
      //     this.context.setSnack({
      //         open: true,
      //         severity: AlertProps.severity.error,
      //         msg: "Next of kin details already exists!",
      //         vertical: AlertProps.vertical.top,
      //         horizontal: AlertProps.horizontal.center,
      //         tone: true,
      //     });
      //     return;
      // } else {
      // bookerId = CheckUser.payload?.data?.id || 0;
      // await this.setState({ bookerId: bookerId })
      let patientData = this.props[saveto];
      const phoneOpt = this.FlterOptions("CONTACTSYSTEM").find(
        (v) => v.value?.toLowerCase() === "phone"
      );
      await patientData.addformdetails.push({
        identification: patientData.identification,
        names: patientData.names,
        details: patientData.details,
        contact: patientData.contact,
        address: patientData.address,
      });
      patientData.identification = [{}];
      patientData.names = [{}];
      patientData.details = {};
      patientData.contact = [{mode:{
        value: phoneOpt?.value,
        _id: phoneOpt?._id,
      }}];
      patientData.address = [];
      patientData.index = null;

      patientData[setTo]({
        ...patientData,
      });
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Added succsssfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      // }
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };

  onedit = async (v, i) => {
    let patientData = this.props.nextOfKin;
    console.log("kitkinpatientdata" , patientData)
    patientData.identification = v.identification;
    patientData.names = v.names;
    patientData.details = v.details;
    patientData.contact = v.contact;
    patientData.address = v.address;
    patientData.index = i;
    await patientData.setNextOfKin({
      ...patientData,
    });
  };

  ondelete = (v, i) => {
    let patientData = this.props.nextOfKin;
    patientData.addformdetails.splice(i, 1);
    patientData.setNextOfKin({
      ...patientData,
    });
    this.context.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Deleted succsssfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };

  Cancel = () => {
    let patientData = this.props.nextOfKin;
    patientData.identification = [{}];
    patientData.names = [{}];
    patientData.details = {};
    patientData.contact = [];
    patientData.address = [];
    // patientData.addformdetails = []
    patientData.index = null;
    patientData.setNextOfKin({
      ...patientData,
    });
  };

  getallmasters = async () => {
    const allCity = await this.props.GET_____CITY();
    const Nas = await this.props.PATIENT_NATIONALITY_READ();
    const Sbi = await this.props.PATIENT_SDI_READ();
    const CityData = await this.props.PATIENT_CITY_READ();
    const allDistrict = await this.props.GET_ALL_DISTRICT();
    const allState = await this.props.GET_ALL_STATE();
    const allCountry = await this.props.GET_ALL_COUNTRY();
    const allPincode = await this.props.GET_ALL_PINCODE();

    const data = await this.props.MASTER_SEARCH({
      type: [
        "Level Of Care",
        "Department",
        "VISITTYPE",
        "Occupation",
        "NAMEPREFIX",
        "NAMESUFFIX",
        "Patient Type",
        "Race",
        "Ethnicity",
        "Religion",
        "RELATIONSHIP",
        "MARITALSTATUS",
        "LANGUAGE",
        "Town",
        "District",
        "State",
        "Country",
        "ALTERNATEIDTYPE",
        "Blood Group",
        "SPECIALTY",
        "Document Type",
        "PRACTITIONER",
        "DISABILITY",
        "VITALSUOM",
        "USE",
        "CONTACTSYSTEM",
        "ADDRESSUSE",
        "PRIORITY",
        "ADDRESSTYPE",
        "GENDER",
        "COUNTRY",
        "Reason",
        "DECEASEDSTATUS",
        "PATIENTIMPORTANCETYPE",
        "SPLARRANGEMENTS",
        "SPLCOURTESY",
        // 'COUNTRY','DISTRICT'
        // 'CITY'
        // "Practitioner","Disability"
      ],
    });
    this.setState({
      allMasters: data?.payload?.data,
      Nas: Nas.payload?.data,
      Sbi: Sbi.payload?.data,
      City: CityData.payload?.data,
      allCity: allCity.payload?.data,
      allDistrict: allDistrict.payload?.data,
      allState: allState.payload?.data,
      allCountry: allCountry.payload?.data,
      allPincode: allPincode.payload?.data,
    });
  };

  alertHold = () => {
    // this.context.setSnack({
    // 	open: true,
    // 	severity: AlertProps.severity.success,
    // 	msg: "Put on holded",
    // 	vertical: AlertProps.vertical.top,
    // 	horizontal: AlertProps.horizontal.right,
    // });
  };

  fetchMyKad = async () => {
    await this.props.FETCH_MY_KAD_DETAILS().then(async (res) => {
      if (res?.error || res?.payload?.error) {
        this.setState({
          ...this.state,
          kadOpen: false,
        });
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Failed to fetch MyKad Details!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        let fetchMyKadData = this.props.fetchMykadDetails?.data;

        let addressUse =
          this.state?.allMasters?.ADDRESSUSE &&
            Array.isArray(this.state?.allMasters?.ADDRESSUSE)
            ? this.state?.allMasters?.ADDRESSUSE?.find(
              (it) => it?.coding?.display?.toLowerCase() === "home"
            )
            : "";

        let addressType =
          this.state?.allMasters?.ADDRESSTYPE &&
            Array.isArray(this.state?.allMasters?.ADDRESSTYPE)
            ? this.state?.allMasters?.ADDRESSTYPE?.find(
              (it) => it?.coding?.display?.toLowerCase() === "home"
            )
            : "";

        if (addressUse && Object.keys(addressUse).length > 0) {
          addressUse = {
            _id: addressUse?.coding?._id,
            label: addressUse?.coding?.display,
            value: addressUse?.coding?.display,
          };
        } else if (
          this.state?.allMasters?.ADDRESSUSE &&
          Array.isArray(this.state?.allMasters?.ADDRESSUSE) &&
          this.state?.allMasters?.ADDRESSUSE?.length > 0
        ) {
          let addresUsage = this.state?.allMasters?.ADDRESSUSE?.[0]?.coding;
          addressUse = {
            _id: addresUsage?._id,
            label: addresUsage?.display,
            value: addresUsage?.display,
          };
        }

        if (addressType && Object.keys(addressType).length > 0) {
          addressType = {
            _id: addressType?.coding?._id,
            label: addressType?.coding?.display,
            value: addressType?.coding?.display,
          };
        } else if (
          this.state?.allMasters?.ADDRESSTYPE &&
          Array.isArray(this.state?.allMasters?.ADDRESSTYPE) &&
          this.state?.allMasters?.ADDRESSTYPE?.length > 0
        ) {
          let addressType = this.state?.allMasters?.ADDRESSTYPE?.[0]?.coding;
          addressUse = {
            _id: addressType?._id,
            label: addressType?.display,
            value: addressType?.display,
          };
        }

        let initialAddress = {
          _id: "",
          code: "",
          label: "",
          parentId: "",
          value: "",
        };

        let addressCity =
          this.state?.allCity && Array.isArray(this.state?.allCity)
            ? this.state?.allCity.find(
              (val) =>
                val?.value?.toLowerCase() ===
                fetchMyKadData?.City.toLowerCase()
            )
            : initialAddress;

        let addressDistrict = "";
        if (addressCity?.parentId) {
          this.props.GET_____DISTRICT(addressCity?.parentId);
          addressDistrict =
            this.state?.allDistrict && Array.isArray(this.state?.allDistrict)
              ? this.state?.allDistrict.find(
                (val) => val.label === addressCity?.parentId
              )
              : initialAddress;
        }
        let addressPincode = "";
        if (addressCity?.label) {
          this.props.GET_____PINCODE(addressCity?.label);
          addressPincode =
            this.state?.allPincode && Array.isArray(this.state?.allPincode)
              ? this.state?.allPincode.find(
                (val) => val.parentId === addressCity?.label
              )
              : initialAddress;
        }

        let addressState = "";
        if (addressDistrict?.parentId) {
          this.props.GET_____STATE(addressDistrict?.parentId);
          addressState =
            this.state?.allState && Array.isArray(this.state?.allState)
              ? this.state?.allState.find(
                (val) => val.label === addressDistrict?.parentId
              )
              : initialAddress;
        }

        let addressCountry = "";
        if (addressState?.parentId) {
          this.props.GET_____COUNTRY(addressState?.parentId);
          addressCountry =
            this.state?.allCountry && Array.isArray(this.state?.allCountry)
              ? this.state?.allCountry.find(
                (val) => val.label === addressState?.parentId
              )
              : initialAddress;
        }

        //1st
        // let MyKadDob = moment(fetchMyKadData?.DOB).format("YYYY-MM-DD");
        //2nd
        // let MyKadDob = moment(fetchMyKadData?.DOB).format("YYYY-DD-MM");
        //3rd
        let MyKadDob = fetchMyKadData?.DOB
          ? moment(fetchMyKadData?.DOB, "DD/MM/yyyy").format("YYYY-MM-DD")
          : "";

        let MyKadAge = moment().diff(moment(MyKadDob, "YYYY-MM-DD"), "years");

        let MyKadImg = "data:image/png;base64," + fetchMyKadData?.Photo;
        const imgFilee = await dataUrlToFile(
          MyKadImg,
          "myKad.png",
          "image/png"
        );

        let MyKadAgeType = {};
        let ageType =
          this.state?.allMasters?.VITALSUOM &&
          Array.isArray(this.state?.allMasters?.VITALSUOM) &&
          this.state?.allMasters?.VITALSUOM?.length > 0 &&
          this.state?.allMasters?.VITALSUOM.filter(
            (val) => val?.coding?.display?.toLocaleLowerCase() === "years"
          );
        if (Array.isArray(ageType)) {
          MyKadAgeType = {
            value: ageType?.[0]?.coding?.display,
            label: ageType?.[0]?.coding?.display,
            _id: ageType?.[0]?.coding?._id,
          };
        } else {
          MyKadAgeType = { value: "Years", label: "Years" };
        }

        let names = fetchMyKadData?.Name?.split(" ");
        let MykadGender =
          this.state?.allMasters?.GENDER &&
            Array.isArray(this.state?.allMasters?.GENDER)
            ? this.state?.allMasters?.GENDER?.find(
              (it) =>
                it?.coding?.code?.toLowerCase() ===
                fetchMyKadData?.Gender?.toLowerCase()
            )
            : "";
        if (MykadGender && Object.keys(MykadGender).length > 0) {
          MykadGender = {
            _id: MykadGender?.coding?._id,
            label: MykadGender?.coding?.display,
            value: MykadGender?.coding?.display,
          };
        }

        let MyKadReligion =
          this.state?.allMasters?.Religion &&
            Array.isArray(this.state?.allMasters?.Religion)
            ? this.state?.allMasters?.Religion?.find(
              (it) =>
                it?.coding?.display?.toLowerCase() ===
                fetchMyKadData?.Religion?.toLowerCase()
            )
            : "";
        if (MyKadReligion && Object.keys(MyKadReligion).length > 0) {
          MyKadReligion = {
            _id: MyKadReligion?.coding?._id,
            label: MyKadReligion?.coding?.display,
            value: MyKadReligion?.coding?.display,
          };
        }

        let actualRaces = CountryRace.find(
          (val) =>
            val?.Country?.toLowerCase() === fetchMyKadData?.Race?.toLowerCase()
        );
        let MyKadRace =
          this.state?.allMasters?.Race &&
            Array.isArray(this.state?.allMasters?.Race)
            ? this.state?.allMasters?.Race?.find(
              (it) =>
                it?.coding?.display?.toLowerCase() ===
                actualRaces?.Demonyms?.toLowerCase()
            )
            : "";
        if (MyKadRace && Object.keys(MyKadRace).length > 0) {
          MyKadRace = {
            _id: MyKadRace?.coding?._id,
            label: MyKadRace?.coding?.display,
            value: MyKadRace?.coding?.display,
          };
        }

        let MykadNationality =
          this.state?.Nas && Array.isArray(this.state?.Nas)
            ? this.state?.Nas.find(
              (it) =>
                it?.value?.toLowerCase() ===
                fetchMyKadData?.Nationality?.toLowerCase()
            )
            : "";

        if (MykadNationality && Object.keys(MykadNationality).length > 0) {
          MykadNationality = {
            _id: MykadNationality?._id,
            label: MykadNationality?.label,
            value: MykadNationality?.value,
          };
        }

        let MykadICNumberType =
          this.state?.allMasters?.ALTERNATEIDTYPE &&
            Array.isArray(this.state?.allMasters?.ALTERNATEIDTYPE)
            ? this.state?.allMasters?.ALTERNATEIDTYPE.find(
              (it) =>
                it?.coding?.display?.toLowerCase() === "mykad" ||
                it?.coding?.display?.toLowerCase() === "ic number" ||
                it?.coding?.display?.toLowerCase() === "ic no."
            )
            : "";
        if (MykadICNumberType && Object.keys(MykadICNumberType).length > 0) {
          MykadICNumberType = {
            _id: MykadICNumberType?.coding?._id,
            code: MykadICNumberType?.coding?.code,
            dis: "ALTERNATEIDTYPE",
            value: MykadICNumberType?.coding?.display,
          };
        }
        let id_source_ = {};
        if (
          this.state?.allMasters?.RELATIONSHIP &&
          Array.isArray(this.state?.allMasters?.RELATIONSHIP) &&
          this.state?.allMasters?.RELATIONSHIP?.length > 0
        ) {
          this.state.allMasters.RELATIONSHIP.map((data) => {
            if (data?.coding?.display?.toLowerCase() === "self") {
              return (id_source_ = {
                value: data?.coding?.display,
                code: data?.coding?.code,
                dis: data?.Type,
                _id: data?.coding?._id,
              });
            }
          });
        }

        this.props.patientData["setPatientData"]({
          ...this.props.patientData,
          address: [
            ...this.props.patientData?.address,
            {
              addressLine1: fetchMyKadData?.Addr1 ?? "",
              addressLine2: fetchMyKadData?.Addr2 ?? "",
              addressLine3: fetchMyKadData?.Addr3 ?? "",
              use: addressUse ?? "",
              type: addressType ?? "",
              city: addressCity ?? "",
              country: addressCountry ?? "",
              district: addressDistrict ?? "",
              pincode: addressPincode ?? "",
              state: addressState ?? "",
              errorList: {
                city: addressCity ? false : true,
                country: addressCountry ? false : true,
                // district: addressDistrict() ? false : true,
                pincode: addressPincode ? false : true,
                state: addressState ? false : true,
              },
            },
          ],
          details: {
            gender: MykadGender ?? "",
            nationality: MykadNationality ?? "",
            dob: MyKadDob ?? "",
            age: MyKadAge ?? "",
            ageType: MyKadAgeType ?? "",
            image: {
              url: MyKadImg ?? "",
              file: imgFilee ?? "",
            },
          },
          identification: [
            {
              id_number: fetchMyKadData?.ICNo ?? "",
              id_type: fetchMyKadData?.ICNo ? MykadICNumberType : {},
              id_source: id_source_,
            },
          ],
          // names: [
          //   {
          //     first_name: names?.[0] ?? "",
          //     middle_name: names?.[1] ?? "",
          //     last_name: names?.[2] ?? "",
          //   },
          // ],
          names: [
            {
              first_name: names?.[0] ?? "",
              middle_name: names?.[1] ?? "",
              last_name: names?.[2]
                ? names?.[3]
                  ? names?.[4]
                    ? names?.[5]
                      ? names?.[6]
                        ? names?.[2] +
                        " " +
                        names?.[3] +
                        " " +
                        names?.[4] +
                        " " +
                        names?.[5] +
                        " " +
                        names?.[6]
                        : names?.[2] +
                        " " +
                        names?.[3] +
                        " " +
                        names?.[4] +
                        " " +
                        names?.[5]
                      : names?.[2] + " " + names?.[3] + " " + names?.[4]
                    : names?.[2] + " " + names?.[3]
                  : names?.[2]
                : "",
            },
          ],
        });
        this.props.dataPayload["setadditnaldata"]({
          ...this.props.dataPayload,
          additnaldata: {
            religion: MyKadReligion ?? "",
            race: MyKadRace ?? "",
          },
          birthdetails: {
            birth_place: fetchMyKadData?.BirthPlace ?? "",
          },
        });
        this.setState({
          ...this.state,
          kadOpen: false,
        });
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "Successfully fetched MyKad details!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    });
  };

  render() {
    const { activeId } = this.state;
    const { outlinedButton, containedButton } = this.styles;
    //const myKadDetails = this.props.fetchMykadDetails.data;
    // const { parent_id } = this.props
    // debugger;
    return (
      <Div id="patient_details_parent_div" className="light-background-color">
        <TopHeader
          parent_id={"dashboard"}
          noHome={true}
          middleComponent={<></>}
        />
        <TopNavbar
          initialFiles={this.props.patientData?.uploadFiles || []}
          parent_id={
            this.props?.location?.state?.idedit
              ? "edit_patient_details_update"
              : "patient_details"
          }
          alertHold={this.alertHold}
          putOnHold={() =>
            this.putOnHold(
              "undefined",
              this.props.location?.state?.appointmentId
            )
          }
          isRegisterFromMyKad
          fetchMyKad={this.fetchMyKad}
          newUser={this.props.location?.state?.newUser}
          activeId={activeId}
          navClick={this.setActive}
          successfun={this.successfun}
          title={patientRegisterTitle}
          navBarArr={patientRegister}
          parent_edit_id={this.props?.location?.state?.idedit}
          handleEditPatient={this.handleEditPatient}
          btn={this.state.btnName ? "Registering" : "Register & continue"}
          isAVC={this.props?.AVCMeeting?.RoomName}
          kadOpen={this.state.kadOpen}
          handleKad={this.handleKad}
        />

        <div
          style={{
            height: "calc(100vh - 128px)", // 128px = 72px + 56px // 72 - topheader , 56 - topnav
            overflow: "auto",
            display: this.state.loading && activeId === 1 ? "flex" : "block",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {activeId === 1 && (
            <LoadingComp
              loading={this.state.loading && activeId === 1 ? true : false}
            >
              <Div
                id="patient_details_parent_div"
                style={{
                  padding: "5px 15px",
                }}
              >
                <PatientIdentification
                  parent_id={
                    this.props.parent_edit_id
                      ? this.props.parent_edit_id
                      : "Register_Patient_patientdetails"
                  }
                  allMasters={this.state?.allMasters}
                  saveto={"patientData"}
                  setTo={"setPatientData"}
                  parent_edit_id={this.props.parent_edit_id}
                  City={this.state.City}
                />

                <PatientNames
                  parent_id={
                    this.props?.location?.state?.idedit
                      ? "Edit_Patient_patientdetails"
                      : "Register_Patient_patientdetails"
                  }
                  saveto={"patientData"}
                  setTo={"setPatientData"}
                  statusData={() => this.state.statusData}
                  parent_edit_id={this.props?.location?.state?.idedit}
                  mrn={
                    (this.state.Patientstate?.alias ?? "") ||
                    (this.state.Patientstate?._id ?? "")
                  }
                  Nas={this.state.Nas}
                  City={this.state.City}
                  allMasters={this.state?.allMasters}
                  personImg={
                    this.state.personInfo?.photo?.[0]
                      ? getImgUrl(this.state.personInfo?.photo?.[0]?.fileid)
                      : ""
                  }
                />

                <PatientCommunication
                  parent_id={"Register_Patient_patientdetails"}
                  allMasters={this.state?.allMasters}
                />
                <PatientCotactPoint
                  parent_id={"Register_Patient_patientdetails"}
                  allMasters={this.state?.allMasters}
                  saveto={"patientData"}
                  setTo={"setPatientData"}
                  Sbi={this.state.Sbi}
                  City={this.state.City}
                  PersonUpdate={this.state.isPersonUpdate}
                />

                <PatientAddress
                  parent_id={"Register_Patient_patientdetails"}
                  saveto={"patientData"}
                  setTo={"setPatientData"}
                  allMasters={this.state?.allMasters}
                  City={this.state.City}
                />

                <Div
                  id="patient_details_button_div"
                  className="p-continue-action"
                >
                  <Button
                    id="patient_details_contiune_to_next_button"
                    onClick={() => this.nextofKin()}
                    style={containedButton}
                    type="submit"
                    disabled={
                      this.props.patientData.statusDetails?.status1 ===
                      "Inactive" ||
                      this.props.patientData.statusDetails?.status ===
                      "Deceased"
                    }
                  >
                    Continue to Next Of Kin
                  </Button>
                </Div>
              </Div>
            </LoadingComp>
          )}

          {activeId === 2 && (
            <Div
              id="nextofkin_parent_div"
              style={{
                padding: "5px 15px",
              }}
            >
              {this.props.nextOfKin?.addformdetails?.length > 0 && (
                <NextOfKinDetails
                  parent_id={"Register_Patient_nextofkindetails"}
                  onedit={this.onedit}
                  ondelete={this.ondelete}
                />
              )}

              <PatientIdentification
                parent_id={
                  this.props.parent_edit_id
                    ? this.props.parent_edit_id
                    : "Register_Patient_patientdetails"
                }
                allMasters={this.state?.allMasters}
                saveto={"nextOfKin"}
                setTo={"setNextOfKin"}
                parent_edit_id={this.props.parent_edit_id}
                City={this.state.City}
              />

              <PatientNames
                parent_id={
                  this.props?.location?.state?.idedit
                    ? "Edit_Patient_patientdetails"
                    : "Register_Patient_patientdetails"
                }
                allMasters={this.state?.allMasters}
                statusData={() => this.state.statusData}
                parent_edit_id={this.props?.location?.state?.idedit}
                mrn={
                  (this.state.Patientstate?.alias ?? "") ||
                  (this.state.Patientstate?._id ?? "")
                }
                saveto={"nextOfKin"}
                setTo={"setNextOfKin"}
                Nas={this.state.Nas}
                City={this.state.City}
              />

              <PatientCotactPoint
                parent_id={"Register_Patient_nextofkindetails"}
                allMasters={this.state?.allMasters}
                saveto={"nextOfKin"}
                setTo={"setNextOfKin"}
                Sbi={this.state.Sbi}
                City={this.state.City}
              />

              <PatientAddress
                parent_id={"Register_Patient_nextofkindetails"}
                allMasters={this.state?.allMasters}
                saveto={"nextOfKin"}
                setTo={"setNextOfKin"}
              />

              <Div
                id="Register_Patinet_nextofkin_button_div"
                className="p-continue-action"
              >
                <Button
                  id="Register_Patinet_nextofkin_goback_button"
                  className="nextKing-btn"
                  style={outlinedButton}
                  onClick={() => {
                    this.setState({ activeId: 1 });
                  }}
                >
                  Go Back
                </Button>
                <Button
                  id="Register_Patinet_nextofkin_cancel_button"
                  className="nextKing-btn"
                  style={outlinedButton}
                  onClick={() => this.Cancel()}
                >
                  Clear
                </Button>
                <Button
                  id="Register_Patinet_nextofkin_add_button"
                  className="nextKing-btn"
                  style={containedButton}
                  onClick={() =>
                    this.handleaddNextOfKin("nextOfKin", "setNextOfKin")
                  }
                >
                  + Add
                </Button>
              </Div>
            </Div>
          )}

          {activeId === 3 && (
            <Div
              id="Register_Patinet_additionaldetails_parent_div"
              style={{
                padding: "5px 15px",
                height: "calc(100vh - 56px)",
                overflow: "auto",
              }}
            >
              <PatientCategory
                parent_id={"Register_Patinet_additionaldetails"}
                allMasters={this.state?.allMasters}
                saveto={"dataPayload"}
                setTo={"setadditnaldata"}
              />

              <PatientBirthDetails
                parent_id={"Register_Patinet_additionaldetails"}
                allMasters={this.state?.allMasters}
                saveto={"dataPayload"}
                setTo={"setadditnaldata"}
                City={this.state.City}
              />

              <Div
                id="Register_Patinet_additionaldetails_button_div"
                className="p-continue-action"
              >
                <Button
                  id="Register_Patinet_additionaldetails__goback_button"
                  onClick={() => this.setState({ activeId: 2 })}
                  className="nextKing-btn"
                  style={{
                    ...outlinedButton,
                    backgroundColor: "transparent",
                  }}
                >
                  Go Back
                </Button>
              </Div>
            </Div>
          )}
        </div>
      </Div>
    );
  }
}
PatientRegistration.contextType = AlertContext;

// export default withRouter(withQueue(withAllContexts(PatientRegistration)));

const mapStateToProps = (state) => ({
  oraganizationRegisterPatient:
    state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  fetchMykadDetails: state?.personApiSlice?.fetch_mykad,
  getDistrict: state?.geoLocationSlice?.getDistrict,
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withQueue(withAllContexts(PatientRegistration))));
