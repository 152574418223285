import React from "react";
import CommonTable from "./table";
import { Grid, Dialog, Typography, Paper } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';


const tableHeader = [
    { label: "Day End Process", rowSpan: 2 },
    { label: "Total Appointment Status", colSpan: 2, align: "center" },
    { label: "Total Encounters Status", colSpan: 2, align: "center" },
    { label: "status", rowSpan: 2 },
    { label: "Reason of failre", rowSpan: 2 },
    { label: "Action", rowSpan: 2 }
]

const tableHeader1 = [
    { label: "No-show" },
    { label: "process Failed" },
    { label: "Closed" },
    { label: "process Failed" }
]

const tableData = [
    { label: "date", type: "TEXT" }, { label: "tasnoshow", type: "TEXT" }, { label: "tasfailed", type: "TEXT" }, { label: "tesclosed", type: "TEXT" },
    { label: "tesFailed", type: "TEXT" }, { label: "status", type: "TEXT" }, { label: "reasonForFailed", type: "TEXT" }, { label: "action", type: "VIEW" },
]

const Data = [
    {
        "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12,
        "tesFailed": 12, "status": 12, "reasonForFailed": 12
    }
]

function TableModel(props) {

    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        setData(props.data)
    }, [props.data])

    const viewFun = (row) => {
        props.viewLogDaily(row)
        props.closeFun()
    }

    return <Dialog
        open={Boolean(data)}
        // onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
    >
        <Paper>
            <Grid container md="12">
                <Grid md="12" style={{ padding: "22px 20px 0px" }}>
                    <CloseIcon style={{ float: "right" }} onClick={() => props.closeFun()} />
                    <Typography variant="h6" style={{ fontSize: 16 }} >Day End Process</Typography>
                </Grid>
                <Grid md="12" style={{ padding: "32px 20px 74px" }}>
                    <CommonTable
                        Header={tableHeader}
                        Header1={tableHeader1}
                        tableData={tableData}
                        dataList={Data}
                        viewFun={viewFun}
                    />
                </Grid>
            </Grid>
        </Paper>
    </Dialog>
}

export default TableModel;