import React from "react";
import { H6, Div, TapSelect } from "qdm-component-library";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { getUserInfo, getUtcTime } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  tabcss: {
    padding: "0 !important",
    width: "fit-content",
    borderRadius: "13px !important",
    borderColor: "#8484847a !important",
    marginBottom: "1%",
    "& div": {
      "& span": {
        fontSize: 13,
        paddingLeft: "18px !important",
        paddingRight: "18px !important",
      },
    },
  },
  circle: {
    width: 5,
    height: 5,
    backgroundColor: "#646464",
    margin: "0 4px",
    borderRadius: "50px"
  },
}));
const Colors = ["#DAF6F4", "#FFF0DF"];
const Schedule = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const style = {
    card: {
      padding: "12px",
      border: "1px solid #E0E0E0",
      boxShadow: "0px 10px 25px #0000000A",
      backgroundColor: "#fff",
      borderRadius: 8,
      height:"390px"
    },
    titleTime: {
      padding: "0px 10px 0px 0px",
      fontSize: 12,
    },
    mapDiv: {
      display: "flex",
      alignItems: "center",
      padding: 8,
      width: "100%",
    },
    subdiv: {
      display: "flex",
      alignItems: "center",
      color: "#646464",
    },
    internalDiv: {
      borderTop: "1px solid #E0E0E0",
      // height: "calc(100vh - 400px)",
      height: "200px",
      overflow: "auto",
      paddingTop: "3%",
    },
  };
  const DATA = [
    {
      title: "",
      timefrom: new Date(),
      timeto: new Date(),
      type: "",
      status: true
    },
  ];
  const tabs = [
    { value: "all", label: "All" },
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "night", label: "Night" },
  ];
  const [scheduleData, setScheduleData] = React.useState([]);
  const [scheduleTab, setScheduleTab] = React.useState({ value: "all", label: "All" });
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  React.useEffect(() => {
    getData(scheduleTab.value);
  }, []);

  const getData = async (slot_type) => {
    const userData = await fetchUserInfo();

    const list = props?.Permission?.write ?? [];
    let roleId = list.indexOf("TodayScheduleD") > -1 ? 1 : list.indexOf("TodayScheduleN") > -1 ? 2 : 3

    //TO GET START TIME END TIME
    let startTime = "";
    let endTime = "";
    if (slot_type === "morning") {
      startTime =getUtcTime(moment().startOf("day"));
      endTime = getUtcTime(moment(moment().format("DD-MM-YYYY") + ' 11:59 am', 'DD-MM-YYYY hh:mm a'));
    } else if (slot_type === "afternoon") {
      startTime =getUtcTime(moment(moment().format("DD-MM-YYYY") + ' 12:00 pm', 'DD-MM-YYYY hh:mm a'));
      endTime =getUtcTime(moment(moment().format("DD-MM-YYYY") + ' 03:59 pm', 'DD-MM-YYYY hh:mm a'));
    } else if (slot_type === "evening") {
      startTime =getUtcTime(moment(moment().format("DD-MM-YYYY") + ' 04:00 pm', 'DD-MM-YYYY hh:mm a'));
      endTime =getUtcTime(moment(moment().format("DD-MM-YYYY") + ' 06:59 pm', 'DD-MM-YYYY hh:mm a'));
    } else if (slot_type === "night") {
      startTime =getUtcTime(moment(moment().format("DD-MM-YYYY") + ' 07:00 pm', 'DD-MM-YYYY hh:mm a'));
      endTime =getUtcTime(moment().endOf("day"));
    } else {
      startTime =getUtcTime(moment().startOf("day"));
      endTime =getUtcTime(moment().endOf("day"));
    }
    let payload = {
      startDate:getUtcTime(moment().startOf("day")),
      endDate:getUtcTime(moment().endOf("day")),
      roleId: roleId,
      loginId: userData?.practionerId,
      startTime: startTime,
      endTime: endTime,
    };
  
    let data_ = await dispatch(actions.TODAY_SCHEDULE(payload));
      
    setScheduleData(data_?.payload?.data);
  };

  const fetchUserInfo = async (email) => {
    if (!loggedUserInfo?.data?.orgId) {
      if (!email) {
        const info = getUserInfo();
        email = info.email;
      }
      const userData = await dispatch(
        actions.SEARCH_PRACTIONER({
          email,
        })
      );
      const info = {
        orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
        name: {
          given: userData?.payload?.data?.[0]?.name?.[0]?.given,
          family: userData?.payload?.data?.[0]?.name?.[0]?.family,
          text: userData?.payload?.data?.[0]?.name?.[0]?.text,
        },
        personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
        org_id: userData?.payload?.data?.[0]?.org_id,
      };

      await dispatch(actions.LOGGED_USER_INFO(info));
      return info;
    }
    return loggedUserInfo?.data;
  };

  const tabHandle = (val) => {
    setScheduleTab(val);
    getData(val.value);
  };
  const rowLen = DATA.length;
  const { parent_id } = props;

  return (
    <Div
      id={`${parent_id}_schedule_parent_div`}
      style={{ padding: "10px 0px", paddingBottom: 0 }}
    >
      <H6
        id={`${parent_id}_schedule_today_labe_h6`}
        className="pc_medium"
        style={{ marginBottom: 10, fontSize: 14 }}
      >
        Today's Schedule
      </H6>
      <Div id={`${parent_id}_schedule_sub_div`} style={style.card}>
        <TapSelect
          id={`${parent_id}_schedule_tapselect`}
          label={""}
          selectedBorderColor={"#0271F2"}
          selectedBackgroundColor={"#0271F2"}
          selectedTextColor={"#fff"}
          textSize={"small"}
          options={tabs}
          className={classes.tabcss}
          defaultSelected={scheduleTab}
          value={scheduleTab}
          onChange={(value) => tabHandle(value)}
        />
        <Div id={`${parent_id}_schedule_map_div`} style={style.internalDiv}>
          {(scheduleData && scheduleData.length > 0) &&
            scheduleData?.map((task, i) => {
              return (
                <Div
                  id={`${parent_id}_${i}_schedule_submap_div`}
                  style={style.mapDiv}
                >
                  <Div
                    id={`${parent_id}_${i}_schedule_titletime_div`}
                    style={style.titleTime}
                  >
                    {moment.unix(task.timefrom).format("hh:mm A")}
                  </Div>
                  {task.status === false ? (
                    <Div
                      id={`${parent_id}_${i}_schedule_timeform_div`}
                      style={{
                        fontSize: 12,
                        padding: "14px 12px",
                        backgroundColor: Colors[i % 2],
                        width: "80%",
                        borderRadius: 8,
                      }}
                    >
                      <Div
                        id={`${parent_id}_${i}_schedule_task_title_div`}
                        className={"pc_medium"}
                        style={{ marginBottom: "2%" }}
                      >
                        {task.title}
                      </Div>
                      <Div
                        id={`${parent_id}_${i}_schedule_format_hour_div`}
                        style={style.subdiv}
                      >
                        <span
                          id={`${parent_id}_${i}_schedule_format_hour_span`}
                          className={"pc_regular"}
                        >
                          {moment.unix(task.timefrom).format("hh:mm")}
                          &nbsp;-&nbsp;
                        </span>
                        <Div
                          id={`${parent_id}_${i}_schedule_timeto_hour_div`}
                          className={"pc_regular"}
                        >
                          {moment.unix(task.timeto).format("hh:mm")}
                        </Div>
                        <Div
                          id={`${parent_id}_${i}_schedule_circle_div`}
                          className={classes.circle}
                        />
                        <Div>
                          {/* <a
                            id={`${parent_id}_${i}_schedule_google_link_a`}
                            className={"pc_regular"}
                            style={{ color: "#0071F2 !important" }}
                            href="https://wwww.google.com"
                          >
                            {task.workOn}
                          </a>{" "} */}
                          {task.type}
                        </Div>
                      </Div>
                    </Div>
                  ) : (
                    <Div
                      id={`${parent_id}_${i}_schedule_no_schedule_label_div`}
                      style={{
                        fontSize: 12,
                        color: "#B6B6B6",
                        borderBottom:
                          rowLen === i + 1
                            ? "0px solid #E0E0E0"
                            : "1px solid #E0E0E0",
                        borderTop: "1px solid #E0E0E0",
                        padding: "14px 12px",
                        width: "80%",
                      }}
                    >
                      No Schedule
                    </Div>
                  )}
                </Div>
              );
            })}
        </Div>
      </Div>
    </Div>
  );
};

export default Schedule;
