import React from "react";
import { ActionFab, SearchPage } from "../../components";
import { Image, SideMenu, Div } from "qdm-component-library";
import { fetchUserInfo, UIColor } from "../../utils";
import { connect } from "react-redux";
import { actions } from "primarycare-binder";
import BookAppointment from "../bookAppointment";
import { checkWithCasbin } from "../../utils/permissonHandling"
const casbinjs = require("casbin.js");

class WithSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchable: false,
			bookAppointment: false,
			groupedOptions: [],
			hidden: false,
			callcen: false,
			isCashier:false
		};
		this.fetchUserInfo = fetchUserInfo.bind(this);
	}
	handleActionOpen = () => {
		this.setState({
			searchable: true,
		});
	};
	handleDrawerOpen = () => {
		this.setState({
			bookAppointment: true,
		});
	};
	handleDrawerClose = () => {
		this.setState({
			bookAppointment: false,
		});
	};
	handleActionClose = () => {
		this.setState({
			searchable: false,
		});
	};
	async componentDidMount() {
		let permission_ = await checkWithCasbin(["dashboard"])
		const isCashier = await permission_?.write?.indexOf("cashierIcon") > -1;
		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		}
		var unique = [...permission_.read, ...permission_.write].filter(onlyUnique);
		if(unique.length > 1){
		  let isBookAppointmentIcon = Boolean(unique.find((val) =>{return val === "bookAppointmentIcon"}));
		  this.setState({ callcen: isBookAppointmentIcon });
		}
	
		if(isCashier){
			this.setState({...this.state,searchable:true,isCashier:true})
		}
		// const Arr = localStorage.getItem("role_data");
		// const arr1 = JSON.parse(atob(Arr))[localStorage.getItem("role_name")]
		// 	.access;
		// const permission = { callcenter: arr1 };
		// const authorizer = new casbinjs.Authorizer("manual", permission);
		// authorizer.setPermission(permission);
		// const result = await authorizer.can("callcenter", "4");

		// this.setState({ callcen: result });
		const userData_ = await this.fetchUserInfo();
		if (userData_?.internalRole === "doctor" || userData_?.internalRole === "nurse") {
			this.setState({ hidden: true });
		}
	}

	render() {
		const { noNewPatient } = this.props;
		return (
			<React.Fragment>
				{/* <SideMenu
                    id={`${this.props.parent_id}_sidemenu_parent_sidemenu`}
                    open={this.state.searchable}
                    inLineStyles={{ zIndex: 99999999, padding: 0, background: "#f9f9f9" }}
                    direction='right'
                    width={400}
                    color='default'
                    onClose={this.handleActionClose}
                    // open={true}
                > */}
				{this.state.bookAppointment && (
					<SideMenu
						id="patient_dashboard_cancel_sidemenu"
						open={this.state.bookAppointment}
						direction="right"
						width={400}
						color="default"
						onClose={() => this.handleDrawerClose()}
					// inLineStyles={{ padding: "10px 20px 20px 0px" }}
					>
						<BookAppointment
							parent_id={"patient_dashboard"}
							handleDrawerClose={() => this.handleDrawerClose()}
						/>
					</SideMenu>
				)}
				{this.state.searchable && (
					<Div
						inLineStyles={{
							zIndex: 999,
							width: "25%",
							right: 0,
							position: "absolute",
							background: UIColor.differentiationBackground.color,
							height: "calc(100vh - 10.8vh)",
							overflow: "auto",
							borderLeft: localStorage.getItem("role_name") === "biller and cashier" ? "1px solid #DEE5EC" : "none"
						}}
					>
						<SearchPage
							handleActionClose={this.handleActionClose}
							parent_id={`${this.props.parent_id}`}
							noNewPatient={this.props.noNewPatient}
							isCashier = {this.state.isCashier}
						/>
					</Div>
				)}
				{/* </SideMenu> */}
				{!this.state.searchable ? (
					<React.Fragment>
						{!this.state.bookAppointment && (
							<div
								id={`${this.props.parent_id}_div`}
								style={{ display: this.state.hidden ? "none" : "block" }}
							>
								<div>
									{this.state.callcen && (
										<ActionFab
											bottom
											parent_id={"search"}
											icon={
												<Image
													id={`${this.props.parent_id}_image`}
													src="images/calender_clock_1.svg"
													alt="search"
													style={{ width: 22, height: 22 }}
												/>
											}
											handleAction={() => this.handleDrawerOpen()}
										/>
									)}
								</div>
								<ActionFab
									parent_id={"search"}
									icon={
										<Image
											id={`${this.props.parent_id}_image`}
											src="images/icons8-search.svg"
											alt="search"
											style={{ width: 15, height: 15 }}
										/>
									}
									handleAction={() => this.handleActionOpen()}
								/>
							</div>
						)}
					</React.Fragment>
				) : (
					""
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, actions)(WithSearch);
