import React from "react";
import { H6, Row } from "qdm-component-library";
import LineChart from "./lineChart";

function AverageWaitingTime(props) {
  const data = {
    labels: ["9 AM", "11 AM", "01 PM", "03 PM", "05 PM", "07 PM"],
    datasets: [
      {
        label: "Arrival - Checkin",
        data: ["8", "20", "25", "40", "12"],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        time: "15",
        period: "Mins",
      },
      {
        label: "Checkin - Traige",
        data: ["5", "2", "15", "20", "45"],
        fill: false,
        borderColor: "rgb(242, 146, 51)",
        time: "10",
        period: "Mins",
      },

      {
        label: "Traige - Consultation",
        data: ["11", "15", "10", "15", "30"],
        fill: false,
        borderColor: "rgb(54, 162, 235)",
        time: "16",
        period: "Mins",
      },
      {
        label: "Overall Waiting",
        data: ["14", "30", "35", "45", "55"],
        fill: false,
        borderColor: "rgb(255, 99, 132)",
        time: "20",
        period: "Mins",
      },
    ],
  };
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_layout_average_parent_div`}
      style={{ height: "100%", padding: 10 }}
    >
      <Row id={`${parent_id}_layout_average_parent_row`}>
        <LineChart
          parent_id={"dashboard_layout"}
          label="Average Waiting Time"
          data={data}
        />
      </Row>
    </div>
  );
}

export default AverageWaitingTime;
