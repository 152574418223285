import React from "react";
import { Div, Select, Col, Row, Paper, H6, TextArea } from 'qdm-component-library'
import "./style.css";
import { withAllContexts } from "../../HOCs";
import { UIColor } from "../../utils";

function RefNote(props) {

    const styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        errorborderStyle: {
            borderRadius: 8
        },
        borderReffralBottom: {
            borderRadius: "2px 2px 6px 6px"
        },
        borderReffralTop: {
            borderRadius: "6px 6px 2px 2px"
        }
    }

    const { note } = props.referralData;

    const setDetails = (name, value) => {
        let patientData = props.referralData
        patientData.note[name] = value;
        if (patientData.note.error) {
            patientData.note.error[name] = false;
        }
        patientData.setReferralDetails({
            ...patientData,
        })
    }

    const { labelStyle, borderStyle, borderReffralBottom, borderReffralTop, errorborderStyle } = styles;
    const { parent_id } = props

    return <Div
        id={`${parent_id}_refnotes_parent_div`}
        className="pv-root">
        <Paper
            id={`${parent_id}_refnotes_parent_paper`}
            className="pl-root">
            <Row
                id={`${parent_id}_refnotes_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_refnotes_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_refnotes_title_div`}
                    >
                        <H6
                            id={`${parent_id}_refnotes_label_h6`}
                            className="en-title space-padding-bottom">REFERRAL NOTE</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_refnotes_sub_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_refnotes_reason_col`}
                            md={2} className="space15-padding-bottom no-padding-left" lg={4} sm={12} xs={12}>
                            <Select
                                id={`${parent_id}_refnotes_reason_select`}
                                // isRequired
                                options={[
                                    { label: "RS001", value: "For Consultation with Practitioner" },
                                    { label: "RS002", value: "For further Treatment" },
                                    { label: "RS003", value: "For Procedure" },
                                    { label: "RS004", value: "For Dressing" },
                                    { label: "RS005", value: "For Vaccination" },
                                    { label: "RS006", value: "For Lab Test" },
                                    { label: "RS007", value: "For Radiology Examination" },
                                    { label: "RS008", value: "For Appointment" },
                                    { label: "RS009", value: "For Admission" },
                                ]}
                                placeholder='select...'
                                // helperText={(note?.error && note?.error['reason']) ? 'Is required' : ''}
                                error={
                                    // (note?.error && note?.error['reason']) ? true : 
                                    false}
                                value={note.reason?.value} onChangeValue={e => setDetails("reason", e)} label="Reason for Referral" showArrow labelStyle={labelStyle}
                                inLineStyles={{ ...(
                                    // (note?.error && note?.error['reason']) ? errorborderStyle :
                                     borderStyle), ...borderReffralTop }}
                                hoverColor={UIColor.primaryColor.color} />
                            <TextArea
                                id={`${parent_id}_refnotes_reason_textarea`}
                                value={note.multiLine} onChange={e => setDetails("multiLine", e.target.value)} maxLength={500} placeholder="" className="space15-padding-bottom" labelStyle={labelStyle} style={{ ...borderStyle, ...borderReffralBottom, height: "auto" }} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                    <Row
                        id={`${parent_id}_refnotes_disription_row`}
                        className="en-actions-root" style={{ display: "none" }}>
                        <Col
                            id={`${parent_id}_refnotes_Exam_clinical_Finding_col`}
                            md={6} lg={6} sm={12} xs={12} className="no-padding-left">
                            <TextArea
                                id={`${parent_id}_refnotes_Exam_clinical_Finding_textarea`}
                                value={note.exam_clinic} onChange={e => setDetails("exam_clinic", e.target.value)} placeholder="" className="space15-padding-bottom" label="Exam & clinical Findings" labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refnotes_Diagnosis_col`}
                            className="space15-padding-bottom no-padding-left" md={6} lg={6} sm={12} xs={12}>
                            <TextArea
                                id={`${parent_id}_refnotes_Diagnosis_textarea`}
                                value={note.diagnosis} onChange={e => setDetails("diagnosis", e.target.value)} placeholder="" label="Diagnosis" labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refnotes_Treatment_col`}
                            md={6} lg={6} sm={12} xs={12} className="no-padding-left">
                            <TextArea
                                id={`${parent_id}_refnotes_Treatment_textarea`}
                                value={note.treatment} onChange={e => setDetails("treatment", e.target.value)} placeholder="" label="Treatment" labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_refnotes_Referral_Note_col`}
                            md={6} lg={6} sm={12} xs={12} className="no-padding-left">
                            <TextArea
                                id={`${parent_id}_refnotes_Referral_Note_textarea`}
                                value={note.referral_notes} onChange={e => setDetails("referral_notes", e.target.value)} placeholder="" label="Referral Notes" labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Paper>
    </Div>
}

export default withAllContexts(RefNote);