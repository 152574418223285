/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc Collection of all validation function
 */

import moment from "moment";

//Function to validate email
export let ValidateEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(re.test(email));
};

//Function to check wheather the 'e' is number key event or not based on 'isNumber' key 
export let IsNumberKey = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (!(e.which >= 48 && e.which <= 57)) e.preventDefault();
    }
  }
};

//Function to check wheather the 'e' is number key event and is down or not.
export let IsNumberKeyDown = (e, isNumber) => {
  if (isNumber) {
    if (e.target.value.length <= 10) {
      if (e.which === 38 || e.which === 40) e.preventDefault();
    }
  }
};

//Function to check wheather the 'e' is text key event or not.
export let IsTextKeyDown = (event, isOnlyText) => {
  var inputValue = event.which;

  if (isOnlyText) {
    if (!((inputValue >= 65 && inputValue <= 90) || (inputValue >= 97 && inputValue <= 122)) && inputValue !== 32 && inputValue !== 0) {
      event.preventDefault();
    }
  }

};

export const _calculateAge = (birthday) => { // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const getData = (date) => {
  let da = date ? new Date(date) : new Date();
  let d = new Date(da).toDateString().split(" ");
  return `${d[2]} ${d[1]}, ${d[3]}`
}

// export const getUtcTime = (date) => {
//   let a = null
//   if (date) {
//     a = new Date(moment(date))
//   } else {
//     a = new Date(date)
//   }
//   a = a.toUTCString()
//   return new Date(a).getTime()
// }

// export const utcTOLocal = (date, format) => {
//   let Ndate = new Date(date);
//   let fmt = format ? format : "DD-MM-YYYY"
//   if (format) {
//     return moment(Ndate).format(fmt);
//   } else {
//     return moment(Ndate)
//   }
// };

export const getUtcTime = (date) => {
  if (date) {
    return moment.utc(date).unix()
  } else {
    return moment.utc().unix()
  }
}

export const getUnixTime = (date) => {
  if(date){
    const actualDate = date.replace(",", " ");
    return moment(actualDate, 'YYYY-MM-DD hh:mm A').unix();
  } else {
    return moment.utc().unix()
  }
}

export const utcTOLocal = (date, format) => {
  let Ndate = new Date();
  if (typeof date === "number") {
    Ndate = moment.unix(date);
  } else {
    Ndate = moment(date);
  }

  let fmt = format ? format : "DD-MM-YYYY"
  if (format) {
    return moment.utc(Ndate).local().format(fmt);
  } else {
    return moment.utc(Ndate).local();
  }
};
