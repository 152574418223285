import React from "react";
import { Div, H6, ListItem } from "qdm-component-library";

function CardList(props) {
  const { middleComponent = <></>, title,parent_id } = props;
  return (
    <Div
      id={`${parent_id}_cardlist_parent_col`}
    >
      <ListItem
        id={`${parent_id}_cardlist_listitem`}
        title={title}
        inLineStyles={{
          // marginTop: "3%",
          marginBottom: "5px",
          paddingLeft: 17,
          paddingTop: 17,
          fontSize: 12
        }}
        description={false}
        profile={false}
        action={
          <div
            id={`${parent_id}_cardlist_div`}
            style={{
              display: "flex",
              right: "-12px",
              position: "absolute",
              top: "-12px",
              cursor: "pointer",
            }}
          >
            {/* <Div
              style={{
                border: "1px solid #E0E0E0",
                padding: "4px 10px",
                borderRadius: "4px",
                color: "#B6B6B6",
                marginRight: "8px",
                backgroundColor: "#fff",
              }}
            >
              <Icons
                key={"2"}
                fontIcon="search"
                ariaHidden="true"
                className=""
                size="medium"
                inLineStyles={{
                  color: "#B6B6B6",
                  fontSize: "12px",
                }}
              ></Icons>
            </Div>
            <Div
              style={{
                border: "1px solid #E0E0E0",
                padding: "4px 10px",
                borderRadius: "4px",
                color: "#B6B6B6",
                backgroundColor: "#fff",
              }}
            >
              <Icons
                key={"2"}
                fontIcon="filter"
                ariaHidden="true"
                className=""
                size="medium"
                style={{ color: "#B6B6B6", fontSize: "12px" }}
              ></Icons>
            </Div> */}
          </div>
        }
      />
      {/* <H6
        id={`${parent_id}_cardlist_today_span`}
        key={"0"}
        name="Today"
        className="pc_medium"
        style={{
          color: "#6F6F6F",
          fontSize: "14px",
          margin:0,
          textTransform: "uppercase",
          letterSpacing:0,
          lineHeight:1,
          padding:'0px 17px',
          marginBottom:8
        }}
      ></H6> */}
      <div
        id={`${parent_id}_cardlist_middle_div`}
        style={{ padding:'0px 17px' }}
      >
        {middleComponent}
      </div>
    </Div>
  );
}

export default CardList;
