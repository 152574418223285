import React from "react";
import { H6, Div, Row, Col, Text, Divider } from "qdm-component-library";
import { Line } from "react-chartjs-2";
import { Popover, makeStyles } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import moment from "moment";
import { getUtcTime } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 10px 25px #00000029",
    borderRadius: 8,
    border: "px solid #E0E0E0",
    width: 150,
    marginTop: 10,
  },
}));

const LineChart = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    anchorEl: null,
    index: null,
  });

  const [mainDrop, setMainDrop] = React.useState({ label: "Specialty", value: "Specialty" });
  const [subDrop, setSubDrop] = React.useState(null);

  React.useEffect(() => {
    getData();
  }, [])

  const getData = async () => {
    const promArr = [
      dispatch(actions.GET_ALL_PRACTIONER_WATING()),
      dispatch(actions.GET_ALL_ORGANIZATION_WATING()),
      dispatch(actions.GET_ALL_SPECIALITY_WATING()),
    ];
    const [, ,data] = await Promise.all(promArr);
    setSubDrop(data?.payload?.data[0]);
  };

  React.useEffect(() => {
    if (subDrop && subDrop.value) {
      
      let startDate = moment().startOf('day') 
      let StartHr = moment().startOf("hour").get("h")
      let dateArr=[]
      for (let index = 9; (index <= StartHr && index + 2 <= 19 ); index+=2) {
       let startTime = getUtcTime(startDate.clone().add(index, "hours"))
        let endTime = getUtcTime(startDate.clone().add(index + 2, "hours"))
        dateArr.push({
          start: startTime,
          end: endTime,
        });
        
      }
      dispatch(
        actions.UPDATE_WAITING_TIME_CHART({
          id: subDrop.value,
          date: dateArr,
          type: mainDrop.value,
        })
      );
    }
  }, [subDrop])


  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;

  const styles = {
    rowCenter: {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      justifyContent: "flex-end",
    },
    dot: {
      width: 10,
      height: 10,
      borderRadius: "50%",
      marginRight: 15,
    },
    h6: {
      fontSize: 14,
      color: "#101010",
      margin: 0,
    },
    card: {
      display: "flex",
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 12,
    },
    span: {
      fontSize: 12,
      color: "#646464",
      margin: 0,
    },
    dropdown: {
      border: "1px solid #E0E0E0",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      padding: "3px 10px",
      minWidth: 100,
    },
    downAngle: {
      width: 15,
      height: 15,
      fill: "#6F6F6F",
    },
    typography: {
      fontSize: 12,
      color: "#646464",
      margin: 0,
      padding: 7,
      textAlign: "center",
      cursor: "pointer",
    },
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      }
    },
    elements: {
      line: {
        tension: 0.3,
      },
    },
  };

  const onChange = (key, value) => {
  
    setState({
      ...state,
      [key]: value,
    });
  };

  const specialityOptions = [
    { label: "Specialty", value: "Specialty" },
    { label: "Clinic", value: "Clinic" },
    { label: "Practitioner", value: "Practitioner" },
  ];
  const specialityStateMap = {
    "Specialty": "getAllSpecialityWaiting",
    "Clinic": "getAllOrganizationWaiting",
    "Practitioner": "getAllPractionerWaiting"
  };
  const cardiologyOptions = [
    { label: "Cardiology", value: "Cardiology" },
    { label: "Deutromatology", value: "Deutromatology" },
  ];

  const changePopoverData = (index, anchorEl) => {
    setState({
      ...state,
      anchorEl,
      index,
    });
  };

  const onChangeDropdownData = (key, value) => {
    setState({
      ...state,
      anchorEl: null,
    });
    if (key === "mainDrop" && value?.value !== mainDrop.value) {
      setMainDrop(value);
      const subDropvalue = props?.timeDatas?.[specialityStateMap?.[value?.value]]?.data;
      
      setSubDrop(subDropvalue?.[0] || []);
    } else if (value?.value !== subDrop?.value) {
      setSubDrop(value);
    }
  };

  const returnPopover = (options = [], stateName) => {
    return (
      options &&
      options?.map((_, i) => {
        return (
          <Div
            id={`${parent_id}_${i}_linechart_parent_div`}
          >
            <H6
              id={`${parent_id}_${i}_linechart_name_h6`}
              className="pc_medium"
              style={{
                ...styles.typography,
                color:
                  _?.label === state?.[stateName]?.label
                    ? "#0071F2"
                    : "#646464",
              }}
              onClick={() => onChangeDropdownData(stateName, _)}
            >
              {_?.label}
            </H6>
            {i < options?.length - 1 && <Divider
              id={`${parent_id}_${i}_linechart_divider`}
              style={{ margin: 0 }} />}
          </Div>
        );
      })
    );
  };
  const { parent_id } = props;
  return (
    <div id={`${parent_id}_linechart_parent_div`} style={styles.card}>
      <div
        id={`${parent_id}_linechart_label_div`}
        style={{ position: "relative", marginRight: 20 }}
      >
        <H6
          id={`${parent_id}_linechart_label_h6`}
          className="pc_medium"
          style={{ marginBottom: 10, fontSize: 14 }}
        >
          {props?.label}
        </H6>

        <H6
          id={`${parent_id}_linechart_label_h6`}
          className="pc_medium"
          style={{ ...styles.span, marginBottom: 10 }}
        >{`By ${mainDrop?.label} - ${subDrop?.label || "---"}`}</H6>
        <Line
          id={`${parent_id}_linechart_line`}
          width={500}
          height={200}
          data={
            Array.isArray(
              props?.timeDatas?.updateWaitingTimeChart?.data?.datasets
            )
              ? props?.timeDatas?.updateWaitingTimeChart?.data
              : {
                  labels: [
                    "10 AM",
                    "11 AM",
                    "01 PM",
                    "03 PM",
                    "05 PM",
                    "07 PM",
                  ],
                  datasets: [],
                }
          }
          options={options}
        />
      </div>
      <Div id={`${parent_id}_linechart_sub_parent_div`}>
        <Div id={`${parent_id}_linechart_sub_div`} style={styles.rowCenter}>
          <Div
            id={`${parent_id}_linechart_speciality_div`}
            aria-describedby={id}
            style={styles.dropdown}
            onClick={(e) => changePopoverData(0, e.currentTarget)}
          >
            <H6
              id={`${parent_id}_linechart_speciality_label_h6`}
              style={styles.span}
            >
              {mainDrop?.label}
            </H6>
            <svg
              id={`${parent_id}_linechart_downangle_svg`}
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={styles.downAngle}
            >
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
            </svg>
          </Div>
          <Div
            id={`${parent_id}_linechart_cardiology_div`}
            aria-describedby={id}
            style={{ ...styles.dropdown, marginLeft: 10 }}
            onClick={(e) => changePopoverData(1, e.currentTarget)}
          >
            <H6
              id={`${parent_id}_linechart_cardiology_label_h6`}
              style={styles.span}
            >
              {subDrop?.label || "---"}
            </H6>
            <svg
              id={`${parent_id}_linechart_down_svg`}
              viewBox="0 0 24 24"
              aria-hidden="true"
              style={styles.downAngle}
            >
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
            </svg>
          </Div>
        </Div>
        <Popover
          id={id}
          open={open}
          anchorEl={state.anchorEl}
          onClose={() => onChange("anchorEl", null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{ paper: classes.muiPopovers }}
        >
          {state.index === 0
            ? returnPopover(specialityOptions, "mainDrop")
            : returnPopover(
                props?.timeDatas?.[specialityStateMap?.[mainDrop?.value]]
                  ?.data || [],
                "subDrop"
              )}
        </Popover>
        <H6
          id={`${parent_id}_linechart_waiting_label_h6`}
          className="pc_medium"
          style={styles.h6}
        >
          Waiting Time
        </H6>
        <Row id={`${parent_id}_linechart_sub_row`} style={{ marginTop: 10 }}>
          {props?.timeDatas?.updateWaitingTimeChart?.data?.datasets &&
            props?.timeDatas?.updateWaitingTimeChart?.data?.datasets?.map(
              (_, i) => {
                return (
                  <Col
                    id={`${parent_id}_${i}_linechart_sub_col`}
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    inLineStyles={{
                      padding: 10,
                      borderBottom:
                        i <
                        props?.timeDatas?.updateWaitingTimeChart?.data?.datasets
                          ?.length -
                          2
                          ? "1px dashed #E0E0E0"
                          : 0,
                      borderRight: i % 2 == 0 ? "1px dashed #E0E0E0" : 0,
                    }}
                  >
                    <H6
                      id={`${parent_id}_${i}_linechartlabel_h6`}
                      className="pc_medium"
                      style={{ ...styles.span, color: "#232323" }}
                    >
                      <Text
                        id={`${parent_id}_${i}_linechart_label_text`}
                        style={{
                          color: _?.borderColor ?? "#000",
                          marginRight: 5,
                          marginBottom: 10,
                        }}
                      >
                        —
                      </Text>
                      {_?.label}
                    </H6>
                    <H6
                      id={`${parent_id}_${i}_linechart_label_h6`}
                      style={{
                        color: _?.borderColor ?? "#000",
                      }}
                    >
                      <Text
                        id={`${parent_id}_${i}_linechart_time_h6`}
                        className="pc_semibold"
                        style={{ fontSize: 24, marginRight: 5 }}
                      >
                        {_?.time?.toString() || "-"}
                      </Text>
                      <Text
                        id={`${parent_id}_${i}_linechart_period_h6`}
                        className="pc_regular"
                        style={{ fontSize: 14 }}
                      >
                        {_?.period}
                      </Text>
                    </H6>
                  </Col>
                );
              }
            )}
        </Row>
      </Div>
    </div>
  );
};

LineChart.defaultProps = {
  data: {},
  label: " ",
};

const mapStateToProps = (state) => ({
  timeDatas: state?.avgTimeSlice,
});

export default connect(mapStateToProps, actions)(LineChart);
