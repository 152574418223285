import React from "react";
import { UIColor } from "../../utils";

export const ReferUserIcon = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "17";
  let color = props.color ? props.color : UIColor.sidebarIconColor.color;
  return (
    <svg id="referuser_svg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 17.789"><path class="a" fill={color} d="M13.342,4.918A10.3,10.3,0,0,0,5.4,8.654V6.124a.7.7,0,1,0-1.4,0v4.2a.7.7,0,0,0,.7.7H8.9a.7.7,0,1,0,0-1.4H6.411a8.915,8.915,0,0,1,14.308.6.7.7,0,0,0,1.16-.787A10.312,10.312,0,0,0,13.342,4.918Zm0,4.358A3.2,3.2,0,0,0,10.913,10.3a3.419,3.419,0,0,0,0,4.424,3.2,3.2,0,0,0,2.428,1.029,3.241,3.241,0,0,0,0-6.482Zm0,1.4a1.84,1.84,0,0,1,0,3.679A1.714,1.714,0,0,1,11.96,13.8a2.02,2.02,0,0,1,0-2.562A1.714,1.714,0,0,1,13.342,10.677ZM9.8,16.633a1.582,1.582,0,0,0-1.625,1.523V19.6a2.75,2.75,0,0,0,1.592,2.287,7.279,7.279,0,0,0,3.574.824,7.283,7.283,0,0,0,3.575-.824A2.751,2.751,0,0,0,18.509,19.6v-1.44a1.582,1.582,0,0,0-1.625-1.523Zm0,1.4h7.085c.175,0,.224.094.224.121V19.6c0,.3-.233.7-.882,1.068a6.01,6.01,0,0,1-2.885.641,6,6,0,0,1-2.883-.641c-.649-.368-.882-.773-.882-1.068v-1.44C9.576,18.128,9.626,18.035,9.8,18.035Z" transform="translate(-4 -4.918)"/></svg>
  );
};