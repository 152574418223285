import React, { useState } from "react";
import { Div, H6, Icons, Text, Select, Row, Col } from "qdm-component-library";
import { TextField, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { AlertProps, UIColor } from "../../utils";
import { withAllContexts } from "../../HOCs";

const useStyles = makeStyles({
	autocomplete: {
		"& .MuiInputBase-formControl": {
			height: 40,
			backgroundColor: UIColor.lineBorderFill.color,
			borderRadius: 8,
		},
		"& input": {
			fontFamily: "pc_regular",
			fontSize: 14,
			color: UIColor.secondaryText.color,
			"&::placeholder": {
				fontSize: 14,
				fontFamily: "pc_regular !important",
			},
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: 0,
		},
	},
});

const RequestorDetailsForm = (props) => {
	const {
		requestorDetails,
	} = props;
	const classes = useStyles(props);
	const dispatch = useDispatch();
	const LabOrderApiSlice = useSelector((state) => state.LabOrderApiSlice);
	const practioner_role_options = useSelector((state) => state.LabOrderApiSlice?.practioner_role?.data || []);
	let radmaster = useSelector((states) => states?.RadiologySlice);
	const storeData = useSelector((state) => state?.TreatmentSlice);
	const styles = {
		flexCenterJustify: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		title: {
			color: UIColor.primaryText.color,
			fontSize: 12,
			margin: 0,
		},
		wrapper: {
			border: `1px solid ${UIColor.lineBorderFill.color}`,
			borderRadius: 8,
			padding: 10,
			backgroundColor: UIColor.greyBackground.color,
		},
		actionText: {
			fontSize: 12,
			color: UIColor.secondaryButtonColor.color,
			margin: 0,
			cursor: "pointer",
		  },
		  actionText_: {
			fontSize: 12,
			color: UIColor.primaryColor.color,
			margin: 0,
			cursor: "pointer",
			backgroundColor: UIColor.primaryColor.color,
			borderColor: UIColor.primaryColor.color,
			borderRadius: 8,
			color: "#fff",
			padding: "4px 10px"
		  },
		  cancelText:{
			fontSize: 12,
			margin: 0,
			cursor: "pointer",
			marginRight:"15px",
			padding: "8px",
			borderRadius: 8,
			border: `1px solid ${UIColor.primaryColor.color}`,
			color: UIColor.primaryColor.color,
			padding: "4px 10px"
		  },
		  specimenText: {
			color: UIColor.tertiaryText.color,
			fontSize: 10,
		  },
		errorBorder:{
			borderRadius: 8,
			borderColor: UIColor.error.color,
		},
		flexRow: {
			display: "flex",
			alignItems: "center",
		},
		edit: {
			color: UIColor.primaryColor.color,
			cursor: "pointer",
		},
		labelStyle: {
			color: UIColor.tertiaryText.color,
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		borderStyle: {
			borderRadius: 8,
			borderColor: UIColor.lineBorderFill.color,
		},
		colPadding: {
			padding: "7px",
		},
		star: {
			color: UIColor.error.color,
			fontSize: 12,
			marginLeft: "5px",
			fontFamily: `pc_regular !important`,
		},
	};

	const [show, hide] = useState(false);
	const [state, setState] = useState({
		organization: "",
		practitionerRole: "",
		orderBy: "",
		practitionerId: "",
	});
	const [addedState, setAddedState] = useState({
		organization: requestorDetails?.organization?.label ? requestorDetails?.organization : "",
		practitionerRole: requestorDetails?.practitionerRole?.label ? requestorDetails?.practitionerRole : "",
		orderBy: requestorDetails?.orderBy?.label ? requestorDetails?.orderBy : "",
		practitionerId: "",
	});
	console.log("requestorDetails", props.requestorDetails);
	React.useEffect(() => {
		setState({
			organization: requestorDetails?.organization?.label ? requestorDetails?.organization : { value: "", label: "" },
			practitionerRole: requestorDetails?.practitionerRole?.label ? requestorDetails?.practitionerRole : { value: "", label: "" },
			orderBy: requestorDetails?.orderBy?.label ? requestorDetails?.orderBy : { value: "", label: "" },
			practitionerId: "",
		});
		setAddedState({
			organization: requestorDetails?.organization?.label ? requestorDetails?.organization : "",
			practitionerRole: requestorDetails?.practitionerRole?.label ? requestorDetails?.practitionerRole : "",
			orderBy: requestorDetails?.orderBy?.label ? requestorDetails?.orderBy : "",
			practitionerId: "",
		});
	}, [requestorDetails.orderBy, requestorDetails.organization, requestorDetails.practitionerRole]);
	const onChangeState = async (key, value) => {
		if (key === "organization" && value?.id && value?.id !== state?.organization?.id) {
			await dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_ROLE({
					id: value?.id,
				})
			);
		}
		if (key === "practitionerRole") {
			await dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_LAB({
					id: value?.id,
					_id: value?._id,
					orgId: state?.organization?.id,
				})
			);
		}
		setState({ ...state, [key]: value });
	};

	const addRequestorDetails = () => {
		hide(!show);
	};

	const [isEdit,setEdit] = useState(false)
  	const addRequestorEdit = () => {
    	setEdit(true)
    	hide(!show);
 	}

	const cancel = () => {
		if(!isEdit){
		 setState({
			organization:{ value: "", label: "" },
			practitionerRole:{ value: "", label: "" },
			orderBy:{ value: "", label: "" },
			practitionerId: "",
		 })
		}
		hide(!show);
		setIsErr(false)
	}

	const [isErr,setIsErr] = useState(false)
	const validate = () => {
		if(!state.orderBy.label 
		  || !state.organization.label 
		  || !state.practitionerRole.label
		  || !state.orderBy.practitioner_id
		  ){
			return true
		}else{
		  return false
		}
	  }

	const save = () => {
		const checkErr = validate()
		if(checkErr){
			setIsErr(true)
			props.alert.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill all the mandatory fields",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
		}else{
			setAddedState(state);
			hide(!show);
			props?.actionButton && props.actionButton(state);	
		}
	
	};

	console.log("show", show);
	console.log("Requestor",state)

	const { parent_id } = props;
	return (
		<Div id={`${parent_id}_requestordetails_parent_div`} style={styles.wrapper}>
			<Div
				id={`${parent_id}_requestordetails_parent_sub_div`}
				style={styles.flexCenterJustify}
			>
				<Div
					id={`${parent_id}_requestordetails_title_div`}
					style={{ width: "100%" }}
				>
					<Div
						id={`${parent_id}_requestordetails_title_sub_div`}
						style={styles.flexCenterJustify}
					>
						<H6
							id={`${parent_id}_requestordetails_${props?.title?.replaceAll?.(
								" ",
								"_"
							)}_h6`}
							className="pc_regular"
							style={styles.title}
						>
							{props?.title}
						</H6>
						{!show && !state?.organization?.value && (
							<H6
								id={`${parent_id}_requestordetails_${props?.action?.replaceAll?.(
									" ",
									"_"
								)}_h6`}
								className="pc_regular"
								style={styles.actionText}
								onClick={addRequestorDetails}
							>
								{props?.action}
							</H6>
						)}
						{show && (
							<div style={{display:"flex"}}>
								<H6
								id={`${parent_id}_requestordetails_Cancel_h6`}
								className="pc_regular"
								style={styles.cancelText}
								onClick={cancel}
							>
								Cancel
							</H6>
							<H6
								id={`${parent_id}_requestordetails_Save_h6`}
								className="pc_regular"
								style={styles.actionText_}
								onClick={save}
							>
								Save
							</H6>
							</div>
						)}
					</Div>
					{!show && addedState?.organization?.value && (
						<Text id={`${parent_id}_requestordetails_show_organization_text`}>
							{addedState?.organization?.value && (
								<Text
									id={`${parent_id}_requestordetails_${addedState?.organization?.value?.replaceAll?.(
										" ",
										"_"
									)}_text`}
									className="pc_medium"
									style={styles.specimenText}
								>
									{addedState?.organization?.value}
								</Text>
							)}
							{addedState?.practitionerRole?.value && (
								<Text
									id={`${parent_id}_requestordetails_${addedState?.practitionerRole?.value?.replaceAll?.(
										" ",
										"_"
									)}_text`}
									className="pc_medium"
									style={styles.specimenText}
								>
									&nbsp;•&nbsp;{addedState?.practitionerRole?.value}
								</Text>
							)}
							{addedState?.practitionerId && (
								<Text
									id={`${parent_id}_requestordetails_${addedState?.practitionerId?.replaceAll?.(
										" ",
										"_"
									)}_text`}
									className="pc_medium"
									style={styles.specimenText}
								>
									&nbsp;•&nbsp;{addedState?.practitionerId}
								</Text>
							)}
							{addedState?.orderBy?.value && (
								<Text
									id={`${parent_id}_requestordetails_${addedState?.orderBy?.value?.replaceAll?.(
										" ",
										"_"
									)}_text`}
									className="pc_medium"
									style={styles.specimenText}
								>
									&nbsp;•&nbsp;{addedState?.orderBy?.practitioner_id}
								</Text>
							)}
						</Text>
					)}
					{show && (
						<Row id={`${parent_id}_requestordetails_show_parent_div`}>
							<Col
								id={`${parent_id}_requestordetails_Organization_col`}
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								inLineStyles={styles.colPadding}
							>
								<Select
									id={`${parent_id}_requestordetails_Organization_select`}
									showArrow
									label={"Organization"}
									isRequired
									placeholder={"Select Organization"}
									labelStyle={styles.labelStyle}
									inLineStyles={isErr && !state.organization.value ? styles.errorBorder :styles.borderStyle}
									hoverColor={UIColor.lineBorderFill.color}
									value={state?.organization?.value}
									onChangeValue={(e) => onChangeState("organization", e)}
									options={props?.organizationOption?.org ?? []}
								/>
							</Col>
							<Col
								id={`${parent_id}_requestordetails_practitionerRole_col`}
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								inLineStyles={styles.colPadding}
							>
								<Select
									id={`${parent_id}_requestordetails_practitionerRole_select`}
									showArrow
									label={"Practitioner Role"}
									isRequired
									placeholder={"Select Practitioner Role"}
									labelStyle={styles.labelStyle}
									inLineStyles={isErr && !state.practitionerRole.value ? styles.errorBorder :styles.borderStyle}
									hoverColor={UIColor.lineBorderFill.color}
									value={state?.practitionerRole?.value}
									onChangeValue={(e) => onChangeState("practitionerRole", e)}
									options={practioner_role_options ?? []}
								/>
							</Col>
							<Col
								id={`${parent_id}_requestordetails_orderBy_col`}
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								inLineStyles={styles.colPadding}
							>
								<Select
									id={`${parent_id}_requestordetails_orderBy_select`}
									showArrow
									label={"Order By"}
									isRequired
									placeholder={"Select Order By"}
									labelStyle={styles.labelStyle}
									inLineStyles={isErr && !state.orderBy.value ? styles.errorBorder :styles.borderStyle}
									getOptionLabel="label"
									hoverColor={UIColor.lineBorderFill.color}
									value={state?.orderBy?.label}
									onChangeValue={(e) => onChangeState("orderBy", e)}
									options={
										LabOrderApiSlice?.order_type_req_details?.data?.length > 0 ?
											LabOrderApiSlice?.order_type_req_details?.data
											: []
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_requestordetails_Practitioner_ID_col`}
								xs={12}
								sm={12}
								md={3}
								lg={3}
								xl={3}
								inLineStyles={styles.colPadding}
							>
								<Div
									id={`${parent_id}_requestordetails_Practitioner_ID_div`}
									style={styles.labelStyle}
									className="pc_regular"
								>
									Practitioner ID<Text style={styles.star}>*</Text>
								</Div>
								<TextField
									id={`${parent_id}_requestordetails_Practitioner_ID_textfield`}
									className={classes.autocomplete}
									value={state?.orderBy?.practitioner_id}
									onChange={(e) =>
										onChangeState("practitionerId", e.target.value)
									}
									// placeholder={"Start Date"}
									InputProps={{
										classes: { input: classes.input },
									}}
									style={{border: isErr && !state?.orderBy?.practitioner_id ? `1px solid ${UIColor.error.color}` : 0,
											borderRadius: isErr && !state?.orderBy?.practitioner_id ? "8px" : 0}}
									variant="outlined"
									disabled={true}
								/>
							</Col>
						</Row>
					)}
				</Div>
				{!show && addedState?.organization?.value && (
					<Icons
						id={`${parent_id}_requestordetails_Practitioner_ID_edit_icons`}
						fontIcon={"pencil"}
						onClick={addRequestorEdit}
						style={styles.edit}
					/>
				)}
			</Div>
		</Div>
	);
};

RequestorDetailsForm.defaultProps = {
	title: "",
	action: "",
	actionButton: null,
	specimenData: {},
	onChangeState: null,
};

export default withAllContexts(RequestorDetailsForm);