import React from "react";
import { Div, Paper, H6, Text } from 'qdm-component-library'
import "./style.css"
import { ShowList } from "./"
import { withAllContexts } from "../../HOCs";

class NextOfKinDetails extends React.Component {
    render() {
        const { parent_id } = this.props;
        return <Div
            id={`${parent_id}_parent_div`}
            className="pi-root">
            <Paper
                id={`${parent_id}_parent_paper`}
                className="pi-paper">
                <Div
                    id={`${parent_id}_addnew_div`}
                >
                    <H6
                        id={`${parent_id}_nextofkin_h6`}
                        className="pi-title">Next Of Kin</H6>
                    <Text
                        id={`${parent_id}_addnew_text`}
                        onClick={(e) => this.OpenAddress(e)} className="pi-addbtn">+ Add New</Text>
                </Div>
                {this.props.nextOfKin?.addformdetails?.map((v, i) => {
                    console.log(v)
                    return <Div
                        id={`${parent_id}_${i}_showlist_div`}
                        className="pa-showList">
                        <ShowList
                            parent_ids={`Register_Patinet_nextoffkin_${i}`}
                            index={i + 1}
                            title={v?.names[0]?.first_name}
                            // description={v?.address[0]?.state?.value ?? '------'}
                            description={(v?.details?.gender?.label ?? "") + (v?.details?.age ? ", "+ v?.details?.age +" Years" : "") + (v?.details?.nationality?.label ? " | "+ v?.details?.nationality?.label : "")}
                            ondelete={() => this.props.ondelete(v, i)}
                            onedit={() => this.props.onedit(v, i)}
                            letter={v?.names[0]?.first_name}
                        />
                    </Div>
                })}
            </Paper>
        </Div>

    }
}


export default withAllContexts(NextOfKinDetails);