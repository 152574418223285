import React from "react";

export const MedicationIcon1 = (props) => {
  let width = props.width ? props.width : "41.026";
  let height = props.height ? props.height : "40";
  let color = props.color ? props.color : "#252525";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 41.026 40"
    >
      <g id="medication" transform="translate(0)">
        <path
          id="Path_92711"
          data-name="Path 92711"
          d="M36.8,1.335A1.428,1.428,0,0,0,35.289,0h-9.04a1.427,1.427,0,0,0-1.506,1.335v.2H36.8ZM26.282,6.667h8.974A1.45,1.45,0,0,0,36.8,5.256V2.564H24.744V5.256A1.45,1.45,0,0,0,26.282,6.667Zm8.974,4.551V8.333H26.282v2.885l-5.769,3.846v2.714a8.439,8.439,0,0,1,1.6.479V15.705l6.09-3.846V9.775h5.128v2.084l6.09,3.846v18.75a2.5,2.5,0,0,1-2.4,2.4H30.565a6.683,6.683,0,0,1-1.123,1.6H37.5a3.6,3.6,0,0,0,3.525-3.525V15.065Zm-9.9,17.551a6.949,6.949,0,0,0-.427-6.11,6.858,6.858,0,0,0-9.343-2.6L3.365,27.113A6.924,6.924,0,0,0,10.288,39.1l.225-.129H24.7a5.13,5.13,0,0,0,.654-10.206ZM9.5,37.484A5.128,5.128,0,0,1,4.375,28.6l11.991-6.923A5.128,5.128,0,1,1,21.5,30.561Zm14.6-.048H19.231V33.941l3.275-1.891a6.654,6.654,0,0,0,2-1.774,3.586,3.586,0,1,1-.4,7.161Z"
          fill="#001c3c"
        />
        <g id="noun_medication_1091553">
          <path
            id="Path_92711-2"
            data-name="Path 92711"
            d="M27.564,27.854a6.649,6.649,0,0,1,3.662,5.16h6.594V22.757H27.564Zm10.257-8.623H27.564v2.244H37.821ZM21.651,24.57a3.873,3.873,0,0,0-5.346-1.1l-5.889,3.4,3.627,6.281,5.888-3.4A3.874,3.874,0,0,0,21.651,24.57Z"
            transform="translate(0)"
            fill="#ec6a49"
          />
        </g>
      </g>
    </svg>
  );
};
