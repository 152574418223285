import React, { useState } from 'react';
import { Avatar, Select, Card, Row, Text, H6, Div,Image } from "qdm-component-library";
import iconHigh from "../../../assets/img/icons8-high priority red.svg";
import iconHighAlert from "../../../assets/img/icons8-high priority white.svg";
import { Popover } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import HighAlertPopUp from "../../../components/highAlertPopUp";
import { profileFirstName, UIColor } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      width:"290px",
      height:"250px"
    },
  }));
function ProfileCard(props) {
    const styles = {
        card: {
            height: "100%",
            padding: "12px",
            boxShadow: '0px 10px 25px #0000000A',
            border: '1px solid #E0E0E0',
            borderRadius: 8
        },
        title: {
            fontSize: 14,
            color: '#101010',
            cursor:"pointer"
        },
        title1:{
            fontSize: 14,
            color: '#101010',
            cursor:"pointer",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        subTitle: {
            fontSize: 12,
            color: "#646464",
            cursor:"pointer"

        },
        profilePic: {
            margin: 0,
            width: 50,
            height: 50,
            borderRadius: "8px",
            backgroundColor: UIColor.differentiationBackground.color
        },
        emergencyDiv: {
            position: "absolute",
            backgroundColor: "#FF4D4A",
            borderRadius: "0px 0px 8px 8px",
            bottom: 0,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "3px"
        },
        emergencyText: {
            color: "#FFFFFF",
            fontSize: "5px"
        }
    }
    const { parent_id } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleHighAlertPop = (event) => {
     setAnchorEl(event.currentTarget);
     };

    const handleHighAlertPopClose = () => {
     setAnchorEl(null);
     };

     const open = Boolean(anchorEl);
    return (
        <div
            id={`${parent_id}${props.title.replaceAll(" ","_")}_parent_div`}
            style={{ height: "100%" }}>
            <Card
                id={`${parent_id}${props.title.replaceAll(" ","_")}_parent_card`}
                style={styles.card}>
                <Row
                    id={`${parent_id}${props.title.replaceAll(" ","_")}_parent_row`}
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                    onMouseEnter={props.onClick}
                    //onMouseLeave={props.onleave}
                        id={`${parent_id}${props.title.replaceAll(" ","_")}_sub_div`}
                        md={6} lg={6}>
                        <Row
                            id={`${parent_id}${props.title.replaceAll(" ","_")}_sub_row`}
                            alignItems={"center"}>
                            <Div
                                id={`${parent_id}${props.title.replaceAll(" ","_")}_avatar_div`}
                                style={{ position: 'relative', marginRight: 12 }}>
                                <Avatar
                                    id={`${parent_id}${props.title.replaceAll(" ","_")}_avatar`}
                                    variant="square"
                                    letter={props?.title ? profileFirstName(props?.title) : "A"}
                                    src={props?.url}
                                    alt="Image" 
                                    // inLineStyles={styles.profilePic}
                                    style={{
                                        ...styles.profilePic, border: props?.isEmergency ? '1px dashed #FF4D4A' : '0'
                                    }}
                                />
                                {
                                    props?.isEmergency &&
                                    <Div
                                        id={`${parent_id}${props.title.replaceAll(" ","_")}_emergency_div`}
                                        style={styles.emergencyDiv}>
                                        <Text
                                            id={`${parent_id}${props.title.replaceAll(" ","_")}_emergency_text`}
                                            className="pc_medium" style={styles.emergencyText}>EMERGENCY</Text>
                                    </Div>
                                }
                            </Div>
                            <div
                                id={`${parent_id}${props.title.replaceAll(" ","_")}_sub_title_div`}
                                style={{width: "155px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden"}}
                            >
                                <H6
                                    id={`${parent_id}${props.title.replaceAll(" ","_")}_sub_title_h6`}
                                    className="pc_medium" style={styles.title1}>
                                    {props.title}
                                </H6>
                                <Text
                                    id={`${parent_id}${props.title.replaceAll(" ","_")}_sub_title_text`}
                                    className="pc_regular" style={styles.subTitle}>
                                    {props.subTitle}
                                </Text>
                            </div>
                        </Row>
                    </div>
                    {props.userInfo === 2 && 
                    <div
                        id={`${parent_id}${props.title.replaceAll(" ","_")}_attend_button_div`}
                        md={6} lg={6}
                        style={{
                            backgroundColor:props?.priorityTotalData?.highPriority ? "#FF4D4A":"#F9E6E6",
                            padding:"5px 8px 3px",
                            borderRadius:"50%",
                            cursor:"pointer"
                        }}
                        onClick={handleHighAlertPop}
                    >
                        {/* {props.button &&
                            <Button
                                id={`${parent_id}${props.title.replaceAll(" ","_")}_attend_button`}
                                className="pc_medium" backgroundColor={"#0071F2"} size={12} style={{ padding: "7px 15px", borderRadius: 8 }}>{"Attend"}</Button>
                        } */}
                        
                         <Image
                        inLineStyles={{
                          height: "17px",
                          width: "16px",
                        }}
                        id={`${parent_id}${props.title.replaceAll(" ","_")}_high_priority`}
                        src={props?.priorityTotalData?.highPriority ? iconHighAlert : iconHigh}
                      />

                    </div>
                    }
                </Row>
            </Card>

        <Popover
          id="high_priority_pop_over"
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleHighAlertPopClose}
          disableRestoreFocus
        >
            <HighAlertPopUp 
                handleHighAlertPopClose={handleHighAlertPopClose} 
                handlePriority={props.handlePriority} 
                index={props.index}
                priorityTotalData={props.priorityTotalData}
            />
        </Popover>
        </div>
    );
}

ProfileCard.defaultProps = {
    url: null,
    title: "Title",
    subTitle: "Subtext",
    button: "Attend",
    isEmergency: false
}

export default ProfileCard;