import React from 'react';
import { Select, Div, Text } from 'qdm-component-library';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    TextField, Typography, InputBase, makeStyles
} from "@material-ui/core";
import { UIColor } from '../../utils';

const useStyles = makeStyles({
    autocomplete: {
        "& .MuiInputBase-input": {
            color: UIColor.secondaryText.color,
            fontSize: "12px !important",
            fontFamily: "pc_regular!important",
        },
        "& .MuiInputBase-root": {
            height: 40
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: UIColor.lineBorderFill.color,
                borderRadius: 8
            },
            '&:hover fieldset': {
                borderColor: UIColor.lineBorderFill.color,
            },
            '&.Mui-focused fieldset': {
                borderWidth: 1,
                borderColor: UIColor.lineBorderFill.color,
            }
        },
        "& .MuiAutocomplete-popupIndicator": {
            transform: "none !important",
        }
    }
});


export const SingleSelectCard = (props) => {
    const classes = useStyles(props);
    const styles = {
        title: {
            fontSize: 12,
            color: '#646464',
            margin: 0,
            marginBottom: 8
        },
        labelStyle: {
            color: UIColor.tertiaryText.color,
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: '8px 8px 0px 0px',
            borderColor: UIColor.lineBorderFill.color,
            cursor: 'pointer'
        },
        content: {
            border: `1px solid ${UIColor.lineBorderFill.color}`,
            borderRadius: '0px 0px 8px 8px',
            padding: '7.5px 14px',
            minHeight: 100
        },
        contentText: {
            fontSize: 14,
            color: '#323232',
        },
        contentTextPlaceholder: {
            fontSize: 14,
            color: UIColor.tertiaryText.color,
        },
    };
    const { parent_id } = props;
    return (
        <Div
            id={`${parent_id}_singleselect_parent_div`}
            style={{ width: '100%' }}>
            <Text
                id={`${parent_id}_singleselect_${props?.label}_text`}
                className="pc_regular" inLineStyles={{
                    color: UIColor.tertiaryText.color,
                    fontSize: 12,
                }}>
                {props?.label}
            </Text>
            <Autocomplete
                id={`${parent_id}_singleselect_autocomplete`}
                size="small"
                className={classes.autocomplete}
                options={props?.options ?? []}
                disableClearable
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                    <TextField
                        id={`${parent_id}_singleselect_textfield`}
                        {...params}
                        variant='outlined'
                        placeholder={props?.placeholder}
                    />
                )}
                renderOption={(option) => (
                    <Typography
                        id={`${parent_id}_singleselect_${option.label}_typography`}
                        className="pc_regular"
                        style={{ width: "100%", fontSize: 14, color: UIColor.tertiaryText.color }}
                        noWrap
                    >
                        {option.label}
                    </Typography>
                )}
                value={props?.valuedata}
                onChange={(e, newValue) => props?.onChangeValue(newValue)}
            />
            {(props?.valuedata?.label) && <Div
                id={`${parent_id}_singleselect_inputbase_div`}
                style={styles.content}>
                <InputBase
                    id={`${parent_id}_singleselect_inputbase`}
                    className="pc_regular"
                    // defaultValue
                    onChange={(newValue) => props?.onChangeValue(newValue.target.value, 'content')}
                    value={props?.content ? props?.content : props?.contentPlaceHolder}
                    inputProps={{ 'aria-label': 'naked' }}
                    fullWidth
                />
            </Div>}
        </Div>
    )
}

SingleSelectCard.defaultProps = {
    label: '',
    value: '',
    content: '',
    placeholder: '',
    contentPlaceHolder: '',
    options: [],
    onChangeValue: null,
    isRequired: false
}