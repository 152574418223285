import React from "react";
import { Card } from "qdm-component-library";
import DoughnutChart from "./doughnutChart";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import moment from "moment";
import { getUserInfo, getUtcTime, permission } from "../../../utils";
const casbinjs = require("casbin.js");

function CurrentVsitStats(props) {
  const styles = {
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 12,
      height: "304px",
    },
  };

  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );

  const authorizer = new casbinjs.Authorizer("manual", permission);
  authorizer.setPermission(permission);

  const dispatch = useDispatch();

  const OrganizationData = useSelector(
    (state) => state?.personApiSlice?.readPerson?.data
  );

  const [state, setState] = React.useState({
    loading: true,
    data: null,
  });

  const fetchUserInfo = async (email) => {
    if (!loggedUserInfo?.data?.orgId) {
      if (!email) {
        const info = getUserInfo();
        email = info.email;
      }
      const userData = await dispatch(
        actions.SEARCH_PRACTIONER({
          email,
        })
      );

      const info = {
        orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
        name: {
          given: userData?.payload?.data?.[0]?.name?.[0]?.given,
          family: userData?.payload?.data?.[0]?.name?.[0]?.family,
          text: userData?.payload?.data?.[0]?.name?.[0]?.text,
        },
        personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
        practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        org_id: userData?.payload?.data?.[0]?.org_id,
      };
      await dispatch(actions.LOGGED_USER_INFO(info));
      return info;
    }
    return loggedUserInfo?.data;
  };
  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const userData = await fetchUserInfo();
    const list = props?.Permission?.write ?? [];
    let roleId = list.indexOf("currentVisitStatsD") > -1 ? 1 : list.indexOf("currentVisitStatsN") > -1 ? 2 : 3
    let payload = {
      orgId: userData?.orgId || 0,
      startDate: getUtcTime(moment().startOf("day")),
      endDate: getUtcTime(moment().endOf("day")),
      roleId: roleId,
      loginId: userData?.practionerId,
      org_id: userData.org_id,
    };
   
    let _res = await dispatch(actions.GET_VISIT_STATS(payload));
    let _ = _res?.payload?.data ?? {};
   

    const data = {
      labels: [
        // "No. of patients arrived",
        "No. of patients checked-in",
        "No. of patients triage done",
        "No. of patients visit completed",
        "No. of patients no shows",
      ],
      datasets: [
        {
          data: [
            _?.checkedIn ?? 0,
            _?.traigeDone ?? 0,
            _?.visitCompleted ?? 0,
            _?.noShow ?? 0,
          ],
          backgroundColor: [
            "#36A2EB",
            "#4BC0C0",
            "#FF9F40",
            "#FF6384",
            "#2D6B95",
          ],
          cutout: 60,
        },
      ],
    };

    setState({ loading: false, data: data });
  };
  const { parent_id } = props;
   const list = props?.Permission?.write ?? [];
  let roleId = list.indexOf("currentVisitStatsD") > -1 ? 1 : list.indexOf("currentVisitStatsN") > -1 ? 2 : 3
  return (
    <Card
      id={`${parent_id}_layout_currentvisit_parent_card`}
      style={styles.card}
    >
      {state.data && (
        <DoughnutChart
          roleId={roleId}
          parent_id={`dashboard_layout_current_visit`}
          data={state.data}
          label="Current Visit Stats"
        />
      )}
    </Card>
  );
}

export default CurrentVsitStats;
