import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Container,
  Text,
  Divider,
  TextInput,
  MobileNumberWithCode,
  TapSelect,
  Button,
  Div,
} from "qdm-component-library";
import {
  checkError,
  AlertProps,
  makeName,
  findAge,
  UIColor,
  mobileNumberValidation,
} from "../../utils";
import { withAllContexts } from "../../HOCs";
import { AlertContext } from "../../contexts";
import { Grid } from "@material-ui/core";
import moment from "moment";
import { Routes } from "../../router/routes";
import { DobAge } from "../../components";

const errList = [
  "patientName",
  //"age",
  "dob",
  "mobileNumber",
  "gender",
  "emailId",
];
class BookAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patientName: "",
      // age: "",
      age: {
        val: "",
        milli: moment().valueOf(),
      },
      // dob: "",
      dob: {
        val: null,
        milli: null,
        value: "",
      },
      mobileNumber: {},
      gender: { value: "", label: "" },
      emailId: "",
      dropdownData: [],
    };
  }
  styles = {
    clear: {
      marginTop: "10px",
      width: "100%",
      borderRadius: 8,
      color: "#6F6F6F",
      fontSize: 13,
      border: "1px solid #e0e0e0",
      boxShadow: "none",
      background: "#fff",

      height: "38px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 600,
      cursor: "pointer",
    },
    tapSelectStyle: {
      padding: "4px 8px",
      borderColor: "#E0E0E0",
      margin: "4px 10px 6px 0px",
      borderRadius: "8px",
      minWidth: "auto",
      margin: "6px 6px 6px 0px",
    },
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
      background: "#fff",
    },
    pd_0: {
      padding: "4px 8px !important",
    },
    mbl: {
      "& .bcDEQH": {
        background: "#fff",
      },
    },
  };

  changeState = (key, value) => {
    // if (key === "date") {
    //   this.state.dob = value;
    //   this.state.age = this.getAge(value);
    // } else {
    this.state[key] = value;
    //}
    this.setState({
      ...this.state,
    });
  };
  getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + " Yrs"
    );
  };
  tabHandle = (val, name) => {
    this.setState({
      ...this.state,
      [name]: val,
    });
  };
  Clear = () => {
    this.setState({
      patientName: "",
      age: "",
      // dob: "",
      dob: {
        val: null,
        milli: null,
        value: "",
      },
      mobileNumber: {},
      gender: { value: "Male", label: "Male" },
      emailId: "",
    });
  };

  componentDidMount() {
    this.fetchInfo();
    this.getCountryCodelist();
  }

  getCountryCodelist = async () => {
    let code = await this.props.GET_COUNTRY_CODE_LIST();
    // this.setState({
    //   ...this.state,
    //   dropdownData: code?.payload?.data
    // })

    // let value = {
    //   countryData: code?.payload?.data?.filter(val => val?.callingCodes === '91')?.[0],
    //   mobileNumber: "",
    // };

    // this.setState({
    //   ...this.state,
    //   mobileNumber: value,
    // })
  };

  async fetchInfo() {
    const data = await this.props.APPOINTMENT_READ({
      appointmentId: this.props.appointmentId,
    });
    this.changeState("appointmentInfo", data?.payload?.data || {});
  }

  handleOtpChange = (val) => {
    this.setState({
      otp: val,
    });
  };

  shouldComponentUpdate(props, state) {
    return true;
  }
  // resendOTP = async () => {
  //   const res = await this.props.RESEND_OTP({
  //     mobileNo: `+${
  //       this.props.signUpData?.mobileNo?.countryData?.callingCodes ?? "00"
  //     }${this.props.signUpData?.mobileNo.mobileNo}`,
  //   });
  //   const status = checkError(this.props.resendOTP);
  //   if (status.isError) {
  //     this.context.setSnack({
  //       open: true,
  //       severity: AlertProps.severity.error,
  //       msg: status.errMsg,
  //       vertical: AlertProps.vertical.top,
  //       horizontal: AlertProps.horizontal.center,
  //       tone: true,
  //     });
  //   } else {
  //     this.setState({
  //       resend: res?.payload?.data?.message,
  //     });
  //   }
  // };
  getPractionerName(val) {
    if ((val?.practitionerID || [])?.length > 0) {
      return makeName(val?.Practioner?.[0]?.name?.[0] ?? {});
    }
    return `${val?.healthservice?.[0]?.name}`;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleContinue_ = () => {
    const state = this.state;
    let error = false;
    errList.map((val) => {
      if (!this.state[val]) {
        error = true;
      }
    });
    if (
      !state.patientName ||
      !state.emailId ||
      !state.mobileNumber?.mobileNo ||
      !state.gender?.value ||
      !state?.age?.val
    ) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    if (!this.validateEmail(state.emailId)) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please enter valid mail",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    if (!state.mobileNumber?.countryData?.callingCodes) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select country code",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }

    let res__ = mobileNumberValidation(
      state.mobileNumber?.mobileNo,
      state.mobileNumber?.countryData?.callingCodes
    );

    if (res__ === "Invalid Country code") {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: res__,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    } else if (res__ === false) {
      this.props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please enter valid mobile number",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    this.props.history.push({
      pathname: Routes.doctorConsultation,
      state: {
        unregister: {
          is_Unregister: true,
          mobileNo: state?.mobileNumber,
          patientName: state?.patientName,
          dob: state?.age?.val,
          age: this.calculateAge(this.state?.age?.val),
          gender: state?.gender,
          emailId: state?.emailId,
        },
        mobileNo: state?.mobileNumber,
      },
    });
  };

  calculateAge_ = (val) => {
    let years = moment().diff(moment(val, "DD-MM-YYYY"), "years");
    let days = moment().diff(moment(val, "DD-MM-YYYY"), "days");
    return years < 200 && days > 0 ? findAge(this.state?.dob) : "";
  };

  calculateAge = (val) => {
    let years = moment().diff(moment(val, "DD-MM-YYYY"), "years");
    let days = moment().diff(moment(val, "DD-MM-YYYY"), "days");
    return years < 200 && days > 0 ? findAge(this.state?.age?.val) : "";
  };

  // getAge = (date) => {
  //       return (
  //           Math.floor(
  //               moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
  //           )
  //       );
  //   };
  render() {
    const { labelStyle, borderStyle, pd_0, mbl, tapSelectStyle, clear } =
      this.styles;
    const { parent_id } = this.props;
    const gender = [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Others", label: "Others" },
    ];
    return (
      <div id={`${parent_id}_cancelbook_parent_div`}>
        <Container
          id={`${parent_id}_cancelbook_parent_container`}
          key={"0"}
          name="container"
          fluid={false}
        >
          <Text
            id={`${parent_id}_cancelbook_appointment_text`}
            key={"0"}
            className="pc_semibold"
            style={{ fontSize: 16, color: "#101010" }}
            name={"Book Appointment"}
          ></Text>

          <Divider
            id={`${parent_id}_cancelbook_bottom_divider`}
            key={"1"}
            textWidth={"50"}
            textColor={"#000"}
            borderColor={"#f0f0f0"}
            borderWidth={"1"}
            orientation={"horizontal"}
            variant={"middle"}
            style={{ marginLeft: "-20px", marginRight: "-20px" }}
            //   style={qdmstyles.BKv}
          ></Divider>
          <Grid id={`${parent_id}_parent_grid`} spacing={2} container>
            <Grid item xs={12} style={pd_0}>
              <TextInput
                isRequired
                maxLength={40}
                id={`${parent_id}_pid_number_textinput`}
                value={this.state.patientName ?? ""}
                onChange={(e) =>
                  this.changeState("patientName", e.target.value, 20)
                }
                label="Patient Name"
                placeholder={""}
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor={UIColor?.primaryColor?.color}
              />
            </Grid>
            <Grid item xs={12} style={pd_0}>
              {/* <DobAge
                                parentId="guestsignin_myself_select_dob_textinput"
                                showAge
                                dateLabel="DOB"
                                ageValue={this.calculateAge(this.state?.dob)}
                                isRequired
                                dateValue={this.state.dob}
                                onChange={(value) => {
                                    this.changeState(
                                        "dob",
                                        moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
                                    );
                                }}
                                maxDate={moment(new Date()).format("YYYY-MM-DD")}
                                minDate={moment(new Date())
                                    .subtract(200, "years")
                                    .format("YYYY-MM-DD")}
                            // error={this.checkAge(this.state?.age?.val)}
                            /> */}
              <DobAge
                parentId="guestsignin_others_select_dob_textinput"
                showAge
                dateLabel="DOB"
                ageValue={this.calculateAge(this.state?.age?.val)}
                isRequired
                dateValue={this.state.age.val}
                onChange={(value) => {
                  if (value) {
                    const milli = moment(value, "YYYY-MM-DD").valueOf();
                    const date = {
                      val: moment(value, "YYYY-MM-DD").format("DD-MM-YYYY"),
                      milli,
                    };
                    this.changeState("age", date);
                  }
                }}
                maxDate={new Date()}
                minDate={moment(new Date()).subtract(200, "years")._d}
                // maxDate={moment(new Date()).format('YYYY-MM-DD')}
                // minDate={(moment(new Date()).subtract(200, 'years')).format('YYYY-MM-DD')}
                placeholder="dd/mm/yyyy"
                error={
                  this.state.error?.age &&
                  (!this.state.age.val ||
                    this.state.age.val === "Invalid date") &&
                  true
                }
                top={40}
                right={10}
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                isRequired
                id={`${parent_id}_mobilenumberwithcode_title`}
                className="pc_regular"
                name={
                  <span
                    style={{ color: "#6F6F6F", fontSize: 12 }}
                    id={`${parent_id}_mobilenumberwithcode_title_span`}
                  >
                    Gender
                  </span>
                }
              ></Text>{" "}
              <span
                id="patient_dashboard_pid_number_textinput_sub_div"
                style={{ color: "red", marginLeft: "5px" }}
              >
                *
              </span>
              <TapSelect
                //id={`${parent_id}_diagnosis_calenderIconEnabled_tapselect`}
                label={""}
                inLineStyles={tapSelectStyle}
                // selectedBorderColor={"#0271F2"}
                // selectedBackgroundColor={"#0271F2"}
                // selectedTextColor={"#fff"}
                selectedBorderColor={UIColor.secondaryButtonColor.color}
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                selectedTextColor="#fff"
                borderColor={UIColor.differentiationBackground.color}
                backgroundColor={UIColor.differentiationBackground.color}
                textColor={UIColor.secondaryButtonColor.color}
                textSize={"small"}
                options={gender}
                value={this.state.gender}
                defaultSelected={this.state.gender}
                onChange={(value) => this.tabHandle(value, "gender")}
              />
            </Grid>
            <Grid item xs={12} style={pd_0}>
              <TextInput
                isRequired
                // maxLength={30}
                id={`${parent_id}_pid_number_textinput`}
                value={this.state.emailId ?? ""}
                onChange={(e) =>
                  this.changeState("emailId", e.target.value, 40)
                }
                label="Email ID"
                placeholder={""}
                labelStyle={labelStyle}
                style={borderStyle}
                hoverColor={UIColor?.primaryColor?.color}
              />
            </Grid>
            <Grid item xs={12} className={pd_0}>
              <Text
                isRequired
                id={`${parent_id}_mobilenumberwithcode_title`}
                className="pc_regular"
                name={
                  <span
                    style={{ color: "#6F6F6F", fontSize: 12 }}
                    id={`${parent_id}_mobilenumberwithcode_title_span`}
                  >
                    Mobile
                  </span>
                }
              ></Text>
              <span
                id="patient_dashboard_pid_number_textinput_sub_div"
                style={{ color: "red", marginLeft: "5px" }}
              >
                *
              </span>
              <MobileNumberWithCode
                id={`${parent_id}_mobilenumberwithcode`}
                type="number"
                label="Mobile No"
                borderColor="#E0E0E0"
                borderRadius="6"
                backgroundColor="#fff"
                maxLength={30}
                value={""}
                // dropdown={{
                //     name: "India",
                //     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
                //     altSpellings: "IND",
                //     callingCodes: "91",
                // }}
                className={mbl}
                // dropdownData={[
                //     {
                //         name: "India",
                //         flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
                //         altSpellings: "IND",
                //         callingCodes: "91",
                //     },
                // ]}
                dropdown={
                  this.state.dropdownData.length > 0
                    ? this.state.dropdownData?.[0]
                    : this.state.mobileNumber.countryData
                }
                dropdownData={this?.props?.CountryCode?.data}
                disabled={false}
                style={{
                  height: "42px",
                  background: "#fff",
                  marginTop: "5px",
                }}
                placeholder={""}
                onChange={(e) => this.changeState("mobileNumber", e)}
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                id={`${parent_id}_search_button`}
                className="pc_medium"
                type="button"
                name={"Continue"}
                variant="outlined"
                size={14}
                backgroundColor={UIColor?.primaryColor?.color}
                onClick={() => this.handleContinue_()}
                inLineStyles={{
                  marginTop: "10px",
                  width: "100%",
                  borderRadius: 8,
                  fontSize: 13,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Div
                id={`${parent_id}_clearAll_button`}
                onClick={() => this.props.handleDrawerClose()}
                inLineStyles={clear}
              >
                Cancel
              </Div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resendOTP: state?.authSlice?.resendOTP,
  sigin: state?.authSlice?.signIn,
  cancelAppointment: state?.appointmentReducer?.deleteAppointment,
  CountryCode: state?.authSlice?.country_code_list,
});
BookAppointment.contextType = AlertContext;

export default withAllContexts(
  connect(mapStateToProps, actions)(withRouter(BookAppointment))
);
