import React from 'react'
import { actions } from "primarycare-binder";

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Routes } from './../../router/routes'
import {
  Addmember,
  Addmemberform,
  Addmemberotp
  // AppointmentBooked,
} from '../../components'
import {
  Container,
  // Row,
  Col,
  // Avatar,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
  H6,
  Link,
  OtpInput,
  SideMenu,
  Row,
  Avatar
} from "qdm-component-library";
import { checkError } from "../../utils";

class SignInOTP extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mobileNo: this.props?.location?.state?.mobileNo ?? {},
      otp: '',
      otpSentMessage: '',
      sidebar: false,
      searchMenber: true,
      memberForm: false,
      memberOtp: false
    }
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  componentDidMount () {}

  shouldComponentUpdate (props, state) {
    return true
  }

  changeBtnClick = () => {
    this.history.push(Routes.signIn)
  }
  toggleSideBar = boolean => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true
    })
  }
  nextSection = member => {
    if (member === 'addmemberform') {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false
      })
    } else if (member === 'addmemberotp') {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: true
      })
    } else if (member === 'back') {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false
      })
    } else if (member === 'movenextscreen') {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
        chooseSearch:null
      })
      window.location.href = '/appointments_listing_page'
    }
    this.props.history.push(Routes.signIn)
  }
  handelChangeSelect = v => {
    this.setState({
      chooseSearch: v
    })
  }
  sendOTP = () => {
    if (this.props.resendOTP?.loading) {
      return true
    }

    Promise.resolve(
      this.props.GENERATE_OTP({
        mobileNo: `+${this.state.mobileNo?.countryData?.callingCodes ?? '00'}${
          this.state.mobileNo.mobileNo
        }`
      })
    ).then(res => {
      this.setState({
        otpSentMessage: 'We have sent you the OTP again, Please check.'
      })
    })
  }

  onLogIn = () => {
    Promise.resolve(this.props.SIGN_IN({
      mobileNo: `+${this.state.mobileNo?.countryData?.callingCodes ?? "00"}${this.state.mobileNo.mobileNo}`,
      otp: this.state.otp
    })).then(res => {
      const status = checkError(this.props.signIn);
      if (true || !status.isError) {
        this.props.history.push(Routes.appointmentsListingPage)
      } else {
        this.setState({
          otpSentMessage: status.errMsg,
        })
      }
    })
  }

  render () {
    return (
      <div>
        <Container
          key={'0'}
          name='container'
          fluid={true}
          style={{
            backgroundColor: '#e8e8e8',
            height: '100vh',
            padding: '0px'
          }}
        >
          <Row
            key={'0'}
            alignItems='stretch'
            direction='row'
            justify='flex-start'
            spacing='2'
            style={{ backgroundColor: '#ffffff', height: '' }}
          >
            <Col
              key={'0'}
              name='qdm-col-12'
              alignItems='center'
              direction='row'
              justify='space-between'
              textAlign='left'
              lg='12'
              xs='12'
              md='12'
              sm='12'
              style={{
                height: '55px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Avatar
                key={'0'}
                variant='rounded'
                letter='Logo'
                src='https://upload.wikimedia.org/wikipedia/en/thumb/c/c5/Apollo_Hospitals_Logo.svg/800px-Apollo_Hospitals_Logo.svg.png'
                alt='Logo'
                style={{
                  borderColor: '#ffffff',
                  backgroundColor: '#ffffff',
                  padding: '8px'
                }}
              />

              <Div
                key={'1'}
                inLineStyles='[object Object]'
                className='qdm'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <Icons
                  key={'0'}
                  fontIcon='phone'
                  ariaHidden='true'
                  size='small'
                  className=''
                  style={{ color: '#6F6F6F', margin: '10px' }}
                />

                <Text
                  key={'1'}
                  name='+91 9821315141 , +91 9551482612'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '',
                    marginRight: ''
                  }}
                />

                <Divider
                  key={'2'}
                  id=''
                  className=''
                  inLineStyles='[object Object]'
                  label=''
                  borderColor=''
                  textColor=''
                  orientation='vertical'
                  variant='middle'
                  style={{ height: '24px', marginTop: '6px' }}
                />

                <Button
                  key={'3'}
                  type='button'
                  variant='text'
                  name='Support'
                  style={{
                    borderColor: '#0071F2',
                    borderWidth: '',
                    borderRadius: '8px',
                    color: '#0071F2',
                    height: '36px',
                    lineHeight: 0
                  }}
                />
              </Div>
            </Col>
          </Row>

          <Col
            key={'1'}
            name='qdm-col-12'
            alignItems='stretch'
            direction='row'
            justify='flex-start'
            textAlign='left'
            lg='12'
            xs='12'
            md='12'
            sm='12'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 55px)',
              backgroundColor: '#F0F7FF'
            }}
          >
            <BackgroundImage
              key={'0'}
              url='images/login_bg.svg'
              alt='Mask-Group-71-2x'
              backgroundPosition='0% 0%'
              backgroundPositionX='left'
              backgroundPositionY='top'
              backgroundRepeat='no-repeat'
              backgroundRepeatX='no-repeat'
              backgroundRepeatY='no-repeat'
              backgroundAttachment='scroll'
              backgroundSize='contain'
              backgroundOrigin='padding-box'
              inLineStyles='[object Object]'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 55px)'
              }}
            >
              <Paper
                key={'0'}
                children='Paper'
                variant='elevation'
                elevation='1'
                style={{
                  width: '350px',
                  height: '',
                  backgroundColor: '#ffffff',
                  borderRadius: '16px',
                  padding: '24px',
                  display: ''
                }}
              >
                <H6
                  key={'0'}
                  name='Enter OTP'
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '#101010',
                    textAlign: 'center'
                  }}
                  inLineStyles={{ fontFamily: "'pc_medium' !important" }}
                />

                <Div
                  key={'1'}
                  className='qdm'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '24px'
                  }}
                >
                  <H6
                    key={'0'}
                    name='Please enter the OTP sent to  '
                    style={{
                      color: '#6F6F6F',
                      fontSize: '14px',
                      margin: '0px',
                      marginRight: '8px'
                    }}
                  />

                  <Text
                    key={'1'}
                    name={`+${this.state.mobileNo?.countryData?.callingCodes ??
                      '99******66'} ${this.state.mobileNo?.mobileNo ?? ''}`}
                    style={{ fontWeight: '600', textAlign: '' }}
                  />
                </Div>

                <Div
                  key={'2'}
                  className='qdm'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Link
                    key={'0'}
                    underline='hide'
                    color='primary'
                    name='Change'
                    underLine='none'
                    style={{
                      textAlign: 'center',
                      color: '#0071F2',
                      borderWidth: '',
                      fontSize: '14px',
                      borderStyle: '',
                      borderColor: '',
                      fontWeight:"600"
                    }}
                    onClick={this.changeBtnClick}
                  />
                </Div>

                <Div
                  key={'3'}
                  className='qdm'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <OtpInput
                    key={'0'}
                    numberOfInputs={6}
                    variant='standard'
                    type='text'
                    focusTextColor='#1890ff'
                    focusBorderColor='#1890ff'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: ''
                    }}
                    onChange={e => this.changeState('otp', e)}
                  />
                </Div>

                <Div
                  key={'4'}
                  className='qdm'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '24px'
                  }}
                >
                  <H6
                    key={'0'}
                    name="Didn't receive OTP ? "
                    style={{
                      color: '#6F6F6F',
                      fontSize: '12px',
                      margin: '0px'
                    }}
                  />

                  <Link
                    key={'1'}
                    name={
                      this.props.resendOTP.loading
                        ? 'Resending OTP...'
                        : 'Resend OTP'
                    }
                    underline='hide'
                    color='textPrimary'
                    underLine='none'
                    style={{
                      margin: '0px',
                      marginLeft: '8px',
                      color: '',
                      fontSize: '12px',
                      fontWeight:"600"
                    }}
                    onClick={this.sendOTP}
                  />
                </Div>

                {this.state.otpSentMessage && (
                  <Div style={{ textAlign: 'center' }}>
                    <H6
                      key={'0'}
                      name={this.state.otpSentMessage}
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginTop: '8px'
                      }}
                    />
                  </Div>
                )}

                <Button
                  key={'5'}
                  onClick={this.onLogIn}
                  disabled={this.props.signIn?.loading}
                  type='button'
                  variant='outlined'
                  name={
                    this.props.signIn?.loading
                      ? 'Signing In...'
                      : 'Confirm OTP'
                  }
                  style={{
                    margin: '',
                    marginTop: '32px',
                    width: '100%',
                    backgroundColor: '#0071F2',
                    borderColor: '#0071F2',
                    borderRadius: '8px',
                    fontSize:"14px",
                    fontWeight:"normal",
                    height:"40px"
                  }}
                />
                <SideMenu
                  open={this.state.sidebar}
                  direction='right'
                  width={435}
                  color='default'
                  onClose={() => this.toggleSideBar(false)}
                >
                  {this.state.searchMenber ? (
                    <Addmember
                      name={'Sign Up'}
                      nextSection={member => this.nextSection(member)}
                      handelChangeSelect={v => this.handelChangeSelect(v)}
                      chooseSearch={this.state.chooseSearch}
                    />
                  ) : this.state.memberForm ? (
                    <Addmemberform
                      nextSection={member => this.nextSection(member)}
                    />
                  ) : this.state.memberOtp ? (
                    <Addmemberotp
                      nextSection={member => this.nextSection(member)}
                    />
                  ) : null}
                </SideMenu>

                <Div
                  key={'6'}
                  inLineStyles='[object Object]'
                  className='qdm'
                  style={{ marginTop: '24px', display: 'flex', height: '' }}
                >
                  <Divider
                    key={'0'}
                    id=''
                    className=''
                    label=''
                    borderColor=''
                    textColor=''
                    orientation='horizontal'
                    variant='fullWidth'
                    style={{ width: '140px' }}
                  />

                  <Text
                    key={'1'}
                    name='Or'
                    style={{
                      marginTop: '4px',
                      color: '#6F6F6F',
                      width: '28px',
                      paddingLeft: '6px',
                      paddingRight: '6px',
                      backgroundColor: '#ffffff'
                    }}
                  />

                  <Divider
                    key={'2'}
                    id=''
                    className=''
                    label=''
                    borderColor=''
                    textColor=''
                    orientation='horizontal'
                    variant='fullWidth'
                    style={{ width: '140px' }}
                  />
                </Div>

                <Button
                  onClick={() => this.props.history.push('/signup')}
                  key={'7'}
                  type='button'
                  variant='text'
                  name='Proceed as Guest'
                  style={{
                    width: '100%',
                    borderColor: '#0071F2',
                    borderRadius: '8px',
                    color: '#0071F2',
                    marginTop: '24px',
                    fontSize:"14px",
                    fontWeight:"normal",
                    height:"40px"
                  }}
                />

                <Div
                  key={'8'}
                  inLineStyles='[object Object]'
                  className='qdm'
                  style={{
                    marginTop: '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    color: '#0071f2'
                  }}
                >
                  <Text
                    key={'0'}
                    name="Don't have an account?"
                    style={{ color: '#6F6F6F', fontSize: '12px' }}
                  />

                  <Link
                    key={'1'}
                    underline='hide'
                    color='primary'
                    name='Sign Up'
                    onClick={() => this.toggleSideBar(true)}
                    style={{ color: '#0071f2', marginBottom: '0px',fontWeight:"600" }}
                  />
                </Div>
              </Paper>
            </BackgroundImage>
          </Col>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  signIn: state?.authSlice?.signIn,
  resendOTP: state?.authSlice?.resendOTP
})

export default connect(mapStateToProps, actions)(withRouter(SignInOTP))
