import React from "react";
import Logo from "../../assets/img/Image 4.png";
import { useStyles } from "./styles";
import { Button, Grid, Typography } from "@material-ui/core";

const BillingClinicDetails = (props) => {
  const { dataPass } = props;
  console.log("dataPassLabel", dataPass);
  const classes = useStyles();
  return (
    <div className={classes.topSection}>
      <div className={classes.topSectionContents}>
        <Typography className={classes.fontCopy}>Original/Copy</Typography>
        <Typography className={classes.fontCopy}>Detail Bill</Typography>
      </div>
      <Grid container style={{ backgroundColor: "#E6EFFF" }}>
        <Grid item xs={6}>
          <div style={{ display: "flex" }}>
            <div>
              <img src={Logo} alt="Logo" />
            </div>
            <div>
              <Typography className={classes.topSectionHeader}>
                {dataPass?.address?.name}
              </Typography>
              <Typography className={classes.topSectionSub}>
                Post-box-{dataPass?.address?.postBox}
              </Typography>
              <Typography className={classes.topSectionSub}>
                {dataPass?.address?.addressName}
              </Typography>
              <div className={classes.topSectionLowerContents}>
                <Typography className={classes.contactDetailsFont}>
                  {dataPass?.address?.telecom}
                </Typography>
                <div className={classes.dot}></div>
                <Typography className={classes.contactDetailsFont}>
                  terry.smith@mail.com
                </Typography>
              </div>
              <div className={classes.button}>
                <Typography className={classes.buttonText}>
                  Amt Due : {dataPass?.totpatientshare}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          style={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <div>
            <Typography className={classes.topSectionRightContents}>
              Thank you for choosing Alaska Facility,Pollachi for your
              healthcare needs. Please pay the outstanding amount on due date.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BillingClinicDetails;
