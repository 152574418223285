import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Card, Div, H6, Text } from "qdm-component-library";
import { UIColor } from "../../../utils";

const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "unset !important",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    overflow: "visible",
    padding: 6,
    cursor: "pointer",
  },
});

export const DoccList = (props) => {
  const styles = {
    title: {
      fontSize: 13,
      fontFamily: "pc_regular",
      fontWeight: 600,
    },
    count: {
      color: "#9b9b9b",
      fontFamily: "pc_regular",
      fontSize: 13,
      fontWeight: 600,
    },
  };
  const color = ["#f47690", "#d674f4", "#7697f4"];
  const classes = useStyles(props);
  const { arrData, index, handleEdit } = props;

  return (
    <div>
      <Card className={classes.muiPopovers} onClick={() => handleEdit(arrData)}>
        <Div style={{ display: "flex", justifyContent: "space-between" }}>
          <H6 inLineStyles={styles.title}>
            {arrData?.data?.doccategory?.display}
          </H6>
          <H6 inLineStyles={styles.count} style={{ color: color[index] }}>
            {arrData?.count}
          </H6>
        </Div>
      </Card>
    </div>
  );
};
