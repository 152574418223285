import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataPayloadContext } from './contexts/index'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    }
}));

const DataStore = (props) => {
    let [additnaldata, setadditnaldata] = useState({
        additnaldata: {},
        birthdetails: {},
    });
    const classes = useStyles();
    return (
        <DataPayloadContext.Provider value={{ ...additnaldata, setadditnaldata }}>
            <div className={classes.root}> {props.children} </div>
        </DataPayloadContext.Provider>
    )
}

export default DataStore;