import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
    Paper, TableRow, TableHead, TableContainer, TableCell,
    Table, TableBody, Grid, Typography, Avatar, Badge, Divider
} from '@material-ui/core';
import { Eye, Modify, CancelAppointment } from "../../svg";
// import DeleteIcon from '@material-ui/icons/Delete';
// import { EditIcon } from '../../svg/editIcon';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHeader: {
        // fontFamily: "poppin",
        fontSize: "14px",
        color: "#646464",
        padding: 10
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableBody: {
        padding: "12px",
        // fontFamily: "poppinsemibold",
        fontSize: "12px",
        padding: 10,
        borderBottom: "unset"
    },
    parentProfile: {
        display: "grid",
        gridTemplateColumns: "43px 1fr"
    },
    ProfileBadge: {
        '& span': {
            background: "#646464",
            minWidth: 6,
            height: "6px"
        }
    },
    ProfileName: {
        fontWeight: 600,
        fontSize: 14,
        paddingRight: 8
    },
    ProfileHighlight: {
        fontWeight: 600,
        fontSize: 14
    },
    ProfileAge: {

    },
    ProfileGender: {
        borderLeft: "1.5px solid #959393",
        marginLeft: 5,
        paddingLeft: 5,
    },
});



export default function TableComponent(props) {
    const classes = useStyles();
    const { Header = [], dataList = [], tableData = [], handleCheckBox = () => null,
        handleEdit = () => null, rightAlign = [],
        cancelFunc = () => true, viewFunc = () => true, modifyFunc = () => true,
        handleDelete = () => null, condition = () => true, orderBy = "", orderByHeader = "" } = props;
    const [order, setOrder] = React.useState('asc');

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderByHeader === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
    };


    return (
        <TableContainer component={Paper} style={{ border: '1px solid #DEE5EC', boxShadow: "none" }}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ background: "#DEE5EC" }}>
                    <TableRow>
                        {
                            Header.map(hed => {
                                let val = hed.label
                                return orderByHeader ? <TableCell className={classes.tableHeader}
                                    align={val === "S.No" ? "center" : rightAlign.indexOf(val) > -1 ? "right" : "left"}
                                    sortDirection={orderByHeader === val ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderByHeader === val}
                                        direction={orderByHeader === val ? order : 'asc'}
                                        onClick={e => handleRequestSort(e, val)}
                                    >
                                        {val}
                                        {orderBy === val ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell> : <TableCell className={classes.tableHeader} align={val === "S.No" ? "center" : rightAlign.indexOf(val) > -1 ? "right" : "left"}>
                                    {val}
                                </TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(dataList) && (orderBy ? stableSort(dataList, getComparator(order, orderBy)) : dataList).map((row, index) => {
                        return <TableRow key={index + 1}>
                            {tableData.map(val => {
                                return <TableCell align={val.align ? val.align : "left"} className={classes.tableBody} style={{ background: index % 2 !== 0 ? "#F8FAFD" : "" }}>
                                    {
                                        val.type.map(type => {
                                            if (condition(row, type)) {
                                                return getComponentType({
                                                    index: index,
                                                    type: type,
                                                    value: val.optionLabel ? row[val.name][val.optionLabel] : row[val.name],
                                                    handleCheckBox: (e) => { handleCheckBox(e, row, index) },
                                                    handleEdit: (e) => { handleEdit(e, row, index) },
                                                    handleDelete: (e) => { handleDelete(e, row, index) },
                                                    cancelFunc: (e) => { cancelFunc(e, row, index) },
                                                    modifyFunc: (e) => { modifyFunc(e, row, index) },
                                                    viewFunc: (e) => { viewFunc(e, row, index) },
                                                    subData: val.subData,
                                                    classes,
                                                    row
                                                })
                                            } else {
                                                return false
                                            }
                                        })
                                    }
                                </TableCell>

                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            {dataList.length === 0 && <div style={{ textAlign: "center", padding: "20px", color: "#646464" }}> No Data Found!</div>}
        </TableContainer>
    );
}



const getComponentType = (data) => {
    switch (data.type) {
        case "TEXT": {
            return data.value
        }
        case "INCRIMENT": {
            return data.index + 1
        }
        case "MODIFY": {
            // return <DeleteIcon style={{ marginRight: "10px" }} onClick={data.handleDelete} />
            return <span onClick={data.modifyFunc} style={{ marginRight: "10px", cursor: "pointer" }}><Modify id={``} /></span>
        }
        case "CANCEL": {
            // return <DeleteIcon style={{ marginRight: "10px" }} onClick={data.handleDelete} />
            return <span onClick={data.cancelFunc} style={{ marginRight: "10px", cursor: "pointer" }} ><CancelAppointment id={``} /></span>
        }
        case "EYE": {
            // return <DeleteIcon style={{ marginRight: "10px" }} onClick={data.handleDelete} />
            return <span onClick={data.viewFunc} style={{ marginRight: "10px", cursor: "pointer" }} ><Eye id={``} /></span>
        }
        case "TWOLAYER": {
            let subData = data.subData
            let classes = data.classes
            let row = data.row
            return <Grid>
                <Typography className={classes.ProfileName}>{row[subData["top"]]}</Typography>
                <Typography variant='span'>{row[subData["bottom"]]}</Typography>
            </Grid>
        }
        case "TWOLAYERWITHDOTTOP": {
            let subData = data.subData
            let classes = data.classes
            let row = data.row
            return <Grid>
                <Grid>
                    <Typography variant='span' className={classes.ProfileHighlight}>{row[subData["topOne"]]}</Typography>
                    {row[subData["topTwo"]] && <Badge color="secondary" className={classes.ProfileBadge} variant="dot" style={{ margin: "0px 9px" }}></Badge>}
                    <Typography variant='span' className={classes.ProfileHighlight}>{row[subData["topTwo"]]}</Typography>
                </Grid>
                <Grid>
                    <Typography variant='span'>{row[subData["bottom"]]}</Typography>
                </Grid>
            </Grid>
        }
        case "TWOLAYERWITHDOTBOTTOM": {
            let subData = data.subData
            let classes = data.classes
            let row = data.row
            return <Grid>
                <Grid>
                    <Typography variant='span' className={classes.ProfileHighlight}>{row[subData["top"]]}</Typography>
                </Grid>
                <Grid>
                    <Typography variant='span' >{row[subData["bottomOne"]]}</Typography>
                    {row[subData["bottomTwo"]] && <Badge color="secondary" className={classes.ProfileBadge} variant="dot" style={{ margin: "0px 9px" }}></Badge>}
                    <Typography variant='span' >{row[subData["bottomTwo"]]}</Typography>
                </Grid>
            </Grid>
        }
        case "PROFILE": {
            let subData = data.subData
            let classes = data.classes
            let row = data.row
            return <Grid className={classes.parentProfile}>
                <Grid> <Avatar alt={row[subData["name"]]?.[0] ?? ""} src={row[subData["imgUrl"]]} style={{ width: 36, height: 36 }} /></Grid>
                <Grid>
                    <Grid>
                        <Typography variant='span' className={classes.ProfileName}>{row[subData["name"]]}</Typography>
                        <Typography variant='span' className={classes.ProfileAge}>{row[subData["age"]]}</Typography>
                        {row[subData["gender"]] && <Typography variant='span' className={classes.ProfileGender}>{row[subData["gender"]]}</Typography>}
                    </Grid>
                    <Grid>
                        <Typography variant='span'>{row[subData["mrn"]]}</Typography>
                        <Badge color="secondary" className={classes.ProfileBadge} variant="dot" style={{ margin: "0px 9px" }}></Badge>
                        <Typography variant='span' >{row[subData["mobileno"]]}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        }
        default: {
            return data.value
        }
    }
}