import React from 'react';
import { Row, Col, Text, H6, Avatar, Div } from 'qdm-component-library';
import { LeftArrowIcon } from "../../../components";
import { UIColor } from '../../../utils';

const statusColors = {
    "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
    "Discontinue": { color: "#d60000", background: UIColor.lineBorderFill.color },
    "Continue": { color: UIColor.primaryColor.color, background: UIColor.primaryColor.color },
    //"Active": { color: UIColor.primaryColor.color, background: "#D4E7FD" },
    "Active": { color: UIColor.success.color, background: "#fff" },
    // "Cancel": { color: "#FF4D4A", background: "#FFE1E0" }
}

const styles = {
    header_ : {
        background: "#fff",
        borderRadius: "10px"
    },
    headBorder: {
        padding: "6px 0px",
        background:"#fff",
    },
    showCard: {
        // background: "#F0F0F0 0% 0% no-repeat padding-box",
        // border: `1px solid ${UIColor.lineBorderFill.color}`,
        borderRadius: 22,
        padding: '0',
        display: 'flex',
        alignItems: "center",
    },
    titleCard: {
        display: 'flex',
    },
    subText: {
        fontSize: 10,
        color: UIColor.secondaryText.color
    },
    text: {
        fontSize: 12,
        color: UIColor.secondaryText.color
    },
    text1: {
        fontSize: 12,
        color: UIColor.secondaryText.color,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "120px",
        textAlign: "right"
      },
    userPic: {
        width: 34,
        height: 34,
        marginLeft: 12,
        fontSize: 14,
        borderRadius: 4
    },
    flexEnd: {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: 'right',
    },
    flexStart: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: '#055CA7'
    },
    backIcon: {
        background: UIColor.lineBorderFill.color,
        padding: "9px 13px 9px 11px",
        borderRadius: "50px",
        display: "flex",
        cursor: "pointer",
        height: "36px",
        width: "36px",
        marginRight: "5px",
        alignItems:"center"
    },
    headerText: {
        fontSize: 16,
        fontWeight: 500
    },
    active: {
        margin: "0 15px 0 10px",
        // color: "#38C20A",
    },
    bullet: {
        width: 6.5,
        height: 6.5,
        borderRadius: "50%",
        // backgroundColor: "#38C20A",
    },
    activeWrapper: {
        display: "flex",
        alignItems: "center",
        birderRight: `1px solid ${UIColor.secondaryText.color}`
    },
}

const FormHeader = (props) => {
    const { currentStatus, currentStatusText, stateList, parent_id } = props;
    return <Row
        id={`${parent_id}_header_parent_row`}
        inLineStyles={styles.header_}>
        <Col
            id={`${parent_id}_header_parent_col`}
            xs={12} sm={12} md={9} lg={9} xl={9} inLineStyles={styles.titleCard} style={{ paddingRight: 4 }}>
            <Col
                id={`${parent_id}_header_parent_sub_col`}
                xs={12} inLineStyles={{ ...styles.showCard }} style={{ justifyContent: "space-between" }}>
                <div
                    id={`${parent_id}_header_parent_MedicationIcon_div`}
                    style={styles.flexStart}>
                    <Text
                        id={`${parent_id}_header_MedicationIcon_text`}
                        inLineStyles={styles.backIcon} onClick={() => props?.goBack()} >
                        {/* <LeftArrowIcon color={"#055CA7"} /> */}
                        {/* <ComplaintsIcon /> */}
                        <MedicationIcon
                            id={`${parent_id}_header_MedicationIcon`}
                        />
                    </Text>
                    <Text
                        id={`${parent_id}_header_${(props?.title || "").replaceAll(" ", "_")}_text`}
                        inLineStyles={styles.headerText}>{props?.title}</Text>
                </div>
                <div
                    id={`${parent_id}_header_currentstatus_div`}
                    className={"status-selector"} style={{ cursor: "pointer", borderRight: `1px solid ${UIColor.lineBorderFill.color}`, ...styles.activeWrapper }} onClick={(e) => props?.openStausInfo(e)}>
                    <Div
                        id={`${parent_id}_header_currentstatus_sub_div`}
                        className={`bg-color-${currentStatus?.toLowerCase()}`} style={{ background: props.color, ...styles.bullet }} />
                    <Text
                        id={`${parent_id}_header_currentstatus_text`}
                        className={`color-${currentStatus?.toLowerCase()}`} style={{ color: props.color, ...styles.active }}>
                        {currentStatusText}
                    </Text>
                </div>
            </Col>

        </Col>
        <Col
            id={`${parent_id}_header_userpic_col`}
            xs={12} sm={12} md={3} lg={3} xl={3} inLineStyles={styles.titleCard} style={{ paddingLeft: 4 }}>
            <Col
                id={`${parent_id}_header_userpic_sub_col`}
                xs={12} inLineStyles={{ ...styles.flexEnd, ...styles.showCard }}>
                <div
                    id={`${parent_id}_header_description_div`}
                >
                    <Text
                        id={`${parent_id}_header_${(props?.date || "").replaceAll(" ", "_")}_text`}
                        inLineStyles={styles.subText} >{props?.date}</Text>
                    <H6
                        id={`${parent_id}_header_${(props?.name || "").replaceAll(" ", "_")}_h6`}
                        inLineStyles={styles.text1}>{props?.name}</H6>
                </div>
                <div>
                    <Avatar
                        id={`${parent_id}_header_user_avatar`}
                        variant={"square"} inLineStyles={styles.userPic} />
                </div>
            </Col>
        </Col>
    </Row >
}


const ComplaintsIcon = () => {
    return (
        <svg
            id="ComplaintsIcon_svg"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14.824"
            viewBox="0 0 14 14.824"
            fill="#055ca7"
        >
            <path
                d="M5.923 0C4.614 0 0 .529 0 5.824v2.647a2.109 2.109 0 001.2 2 5.222 5.222 0 004.727 3.3 5 5 0 00.843-.074 4.28 4.28 0 01-.8-.987h-.041a3.814 3.814 0 01-1.2-.192.524.524 0 00.124-.337.539.539 0 00-1.062-.121 4.089 4.089 0 01-.681-.6.524.524 0 00.128-.341.534.534 0 00-.538-.529.546.546 0 00-.2.039 4.99 4.99 0 01-.362-.736l-.094-.276-.287-.07a1.018 1.018 0 01-.669-1.074v-.532h.538c0-3.706 5.385-1.059 7-4.765a5.829 5.829 0 011.482 3.2 4.325 4.325 0 011.749.551v-1.1c0-2.118-1.077-4.765-3.231-4.765C8.615 1.059 8.077 0 5.923 0zm3.769 7.412a3.177 3.177 0 101.868 5.761l1.521 1.5a.545.545 0 00.761 0 .523.523 0 000-.749l-1.521-1.5a3.116 3.116 0 00.6-1.836 3.212 3.212 0 00-3.229-3.176zm0 1.059a2.159 2.159 0 011.448.556.528.528 0 00.167 1.032.542.542 0 00.406-.183 2.085 2.085 0 01.133.712A2.154 2.154 0 119.692 8.47zM2.962 9a.265.265 0 10.269.265A.267.267 0 002.962 9zm1.346 1.059a.529.529 0 10.538.529.534.534 0 00-.538-.529zm5.654 0a.794.794 0 10.808.794.8.8 0 00-.808-.794zm-4.308 1.059a.265.265 0 10.269.265.267.267 0 00-.269-.265z"
                className="a"
            ></path>
        </svg>
    );
}
const MedicationIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="#055ca7"
        >
            <path
                d="M9.211 0A3.837 3.837 0 006.5 1.117L1.121 6.474a3.833 3.833 0 005.431 5.409l.956-.953a3.391 3.391 0 104.481-4.469A3.819 3.819 0 009.211 0zm-4.87 4.703l3.27 3.253a3.344 3.344 0 00-.4 1.589c0 .085.007.168.013.251a.509.509 0 00-.08.067l-1.307 1.3a2.816 2.816 0 11-3.99-3.974zm6.262 2.474a2.368 2.368 0 11-2.378 2.368 2.366 2.366 0 012.378-2.368zm.839 1.01a.511.511 0 00-.35.153l-1.7 1.692a.506.506 0 000 .718.511.511 0 00.721 0l1.7-1.692a.507.507 0 00-.37-.871z"
                className="a"
            ></path>
        </svg>
    )
}
export default FormHeader;
