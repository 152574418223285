import React, { PureComponent } from "react";
import { withAllContexts } from "../../../HOCs";

export class VideoCallComponent extends PureComponent {
	// domain = "meeting-ainqa.com";
	// domain = "avcprimarycare.demo.ainqaplatform.in";
	api = {};

	constructor(props) {
		super(props);
		this.state = {
			room: null,
			jwt: "",
			user: {
				name: this.props.userName??"",
			},
			isAudioMuted: false,
			isVideoMuted: true,
		};
	}

	componentWillReceiveProps(props) {
		if (window.JitsiMeetExternalAPI && !this.state.room) {
			this.setState(
				{
					room: props.RoomName,
				},
				() => {
					this.startMeet(props.RoomName, props.jwt, props.userName, props?.domain);
				}
			);
		} else {
			console.log("JitsiMeetExternalAPI not loaded");
		}
	}

	startMeet = (RoomName, jwt, userName, domainName) => {
		
		const options = {
			roomName: RoomName,
			width: "100%",
			configOverwrite: {
				prejoinPageEnabled: false,
				startWithAudioMuted: this.state.isAudioMuted,
				startWithVideoMuted: this.state.isVideoMuted,
				// startSilent:true
			},
			interfaceConfigOverwrite: {
				// overwrite interface properties
			},
			parentNode: document.querySelector("#jitsi-iframe"),
			userInfo: {
				displayName: userName,
			},
			jwt: jwt,
		};
		this.api = new window.JitsiMeetExternalAPI(domainName, options);

		this.api.addEventListeners({
			readyToClose: this.handleClose,
			participantLeft: this.handleParticipantLeft,
			participantJoined: this.handleParticipantJoined,
			videoConferenceJoined: this.handleVideoConferenceJoined,
			// videoConferenceLeft: this.handleVideoConferenceLeft,
			audioMuteStatusChanged: this.handleMuteStatus,
			videoMuteStatusChanged: this.handleVideoStatus,
		});
	};

	handleClose = () => {
		console.log("handleClose");
	};

	handleParticipantLeft = async (participant) => {
		console.log("handleParticipantLeft", participant); // { id: "2baa184e" }
		const data = await this.getParticipants();
	};

	handleParticipantJoined = async (participant) => {
		console.log("handleParticipantJoined", participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }
		const data = await this.getParticipants();
	};

	handleVideoConferenceJoined = async (participant) => {
		console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
		const data = await this.getParticipants();
	};

	handleVideoConferenceLeft = () => {
		console.log("handleVideoConferenceLeft");
		// this.props.closeFun();
		// return this.props.history.push("/avc_login");
	};

	handleMuteStatus = (audio) => {
		console.log("handleMuteStatus", audio); // { muted: true }
	};

	handleVideoStatus = (video) => {
		console.log("handleVideoStatus", video); // { muted: true }
	};

	getParticipants() {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(this.api.getParticipantsInfo()); // get all participants
			}, 500);
		});
	}

	// custom events
	executeCommand(command) {
		this.api.executeCommand(command);
		if (command == "hangup") {
			// return this.props.history.push("/avc_login");
		}

		if (command == "toggleAudio") {
			this.setState({ isAudioMuted: !this.state.isAudioMuted });
		}

		if (command == "toggleVideo") {
			this.setState({ isVideoMuted: !this.state.isVideoMuted });
		}
	}

	// componentDidMount() {

	// }
	render() {
		const { isAudioMuted, isVideoMuted } = this.state;
		return (
			<div style={{zIndex:9999, display: this.props?.screenSize && "none"}}>
				<div id="jitsi-iframe" style={{ height: "100vh", width: "100%" }}></div>
			</div>
		);
	}
}

// export default JitsiComponent;
