import React from "react";
import { Div, Button } from "qdm-component-library";
import { UIColor } from "../../../utils";

function PatientDashboardNavBar(props) {
  const {
    navBarArr,
    btnName,
    btnClick = () => null,
    clickNav = () => null,
    activeId = 1,
    parent_id,
    enablebtn
  } = props;
  return (
    <Div
      id={`${parent_id}_navbar_parent_div`}
      inLineStyles={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 36px",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Div
        id={`${parent_id}_navbar_parent_sub_div`}
      >
        {navBarArr && (
          <ul
            id={`${parent_id}_navbar_ul`}
            style={{
              display: "inline-flex",
              listStyleType: "none",
              padding: 0,
              margin: 0,
              position: "absolute",
              bottom: 0,
            }}
          >
            {navBarArr.map((val, i) => (
              <li
                id={`${parent_id + '_' + val.label}_${i}_navbar_li`}
                className={activeId === val.id ? "pc_medium" : "pc_regular"}
                onClick={() => clickNav(val.id)}
                style={{
                  cursor: "pointer",
                  marginRight: "24px",
                  borderBottom: activeId === val.id ? `2px solid ${UIColor.secondaryColor.color}` : "",
                  textDecoration: "none",
                  padding: "0px 0px 21px",
                  letterSpacing: "-0.13px",
                  color: activeId === val.id ? UIColor.secondaryColor.color : "#6F6F6F",
                  fontSize: activeId === val.id ? "16px" : "14px",
                  lineHeight: 1
                }}
              >
                {val.label}
              </li>
            ))}
          </ul>
        )}
      </Div>
      <Div style={{ height: 38 }}
        id={`${parent_id}_navbar_btn_div`}
      >
        {enablebtn && <Button
          id={`${parent_id}_navbar_btn_`}
          className="pc_medium"
          name={btnName}
          disabled={props.disabled}
          inLineStyles={{
            fontSize: "14px",
            borderRadius: "8px",
            backgroundColor: !props.disabled && UIColor.secondaryColor.color,
            border: "none",
            padding: "10px 16px",
            letterSpacing: 0,
            height: 40
          }}
          onClick={() => btnClick()}
        ></Button>}
      </Div>
    </Div>
  );
}

export default PatientDashboardNavBar;
