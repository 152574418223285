/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, Div } from 'qdm-component-library';
import NoActions from "../../assets/img/noActionsItems.svg";

export const ActionItems = (props) => {

    return (
        <Row inLineStyles={{ height: !props.showTitle ? "calc(100vh - 185px)" : "auto", overflow: "auto" }} >
            {!props.showTitle && <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: "12px" }}>
                <Text inLineStyles={{ fontWeight: 600, fontSize: "16px" }} >{"Action Items"}</Text>
            </Col>}
            <Col xs={12} sm={12} md={12} lg={12} xl={12} inLineStyles={{ mixHeight: "500px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Div>
                    <img src={NoActions} alt={"No Action items"} style={{ width: "180px" }} />
                    <p style={{ textAlign: "center" }} >{props.text ? props.text : "No action items"}</p>
                </Div>
            </Col>
        </Row>
    );
};