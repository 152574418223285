import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CustomDatePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <DatePicker
        minDate={this.props?.minDate}
        maxDate={this.props?.maxDate}
        timeFormat={this.props?.timeFormat }
        showTimeSelectOnly={this.props?.showTimeSelectOnly || false}
        showTimeSelect={this.props?.showTimeSelect || false}
        timeIntervals={this.props?.timeIntervals}
        selected={this.props.selectedDate}
        minTime={this.props?.minTime}
        maxTime={this.props?.maxTime}
        // monthsShown={1}
        inline
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        calendarStartDay={1}

        onChange={(value, e) => { this.props.handleChange(value, e) }}
      />
    );
  }
}

export default CustomDatePicker
