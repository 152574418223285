import React from 'react';
import { H6, Row } from "qdm-component-library";
import DataTable from "./table";

function AppoinmentList(props) {

  const tableHead = ['Name & MRN', 'Date', 'Type', 'Doctor Name', 'App Status'];

  const tableHeadData = [
    'nameMrn', 'date', 'type', 'doctorName', 'appStatus'
  ]

  const tableData = [
    {
      nameMrn: {
        profile_pic: 'https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg',
        name: 'Louis Barrett',
        age: '26',
        gender: 'M',
        mrn: 'MRN080472',
        mobile: '94526 21812'
      },
      date: {
        date: '24 Apr,2021',
        time: '10:30 AM'
      },
      type: {
        type: 'OP',
        vist: 'New Visit',
        encounterNo: 'EN-4095045'
      },
      doctorName: {
        name: 'Ronald Fuller',
        speciality: 'Dermatology',
        ward: 'Ward 1'
      },
      appStatus: {
        status: 'Consultaion',
        in: 'In Queue'
      }
    },
    {
      nameMrn: {
        profile_pic: 'https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg',
        name: 'Louis Barrett',
        age: '26',
        gender: 'M',
        mrn: 'MRN080472',
        mobile: '94526 21812'
      },
      date: {
        date: '24 Apr,2021',
        time: '10:30 AM'
      },
      type: {
        type: 'OP',
        vist: 'New Visit',
        encounterNo: 'EN-4095045'
      },
      doctorName: {
        name: 'Ronald Fuller',
        speciality: 'Dermatology',
        ward: 'Ward 1'
      },
      appStatus: {
        status: 'Consultaion',
        in: 'In Queue'
      }
    }
  ];
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_appoinmentlist_parent_div`}
      style={{ height: "100%", padding: 10 }}>
      <H6
        id={`${parent_id}_appoinmentlist_h6`}
        style={{ marginBottom: 10 }} >Appoinments</H6>
      <Row
        id={`${parent_id}_parent_div`}
      >
        <DataTable
          parent_id={'data_table'}
          tableHead={tableHead}
          tableHeadData={tableHeadData}
          tableData={tableData}
        />
      </Row>
    </div>
  );
}

export default AppoinmentList;