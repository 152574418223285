import React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Typography, FormControlLabel, Checkbox
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { withAllContexts } from "../../../HOCs";
import { AlertProps } from "../../../utils";

const alertType = {
    1: "Stop",
    2: "Informative",
    3: "Warning",
    4: "Overridden",
}

const alertColorMain = {
    1: "#fd2824",
    3: "#F58B00",
    4: "#F58B00",
}
const alertColorSec = {
    1: "#FFEDED",
    3: "#FEF3E5",
    4: "#FEF3E5",
}
const alertText = {
    1: "Close",
    3: "Continue",
    4: "Overridden",
}

const alertIcon = {
    1: <ReportProblemOutlinedIcon style={{ width: 22, height: 32, marginRight: 7, color: "red" }} />,
    3: <ErrorOutlineIcon style={{ width: 22, height: 32, marginRight: 7, color: "#F58B00" }} />
}

function AlertsRestriction(props) {
    const { open, handleClose, data } = props;
    const [checkStatus, setState] = React.useState(false);
    const handleChange = (event) => {
        setState(event.target.checked);
    };
    const handleSubmit = () => {
        if (data.type === 3 && !(checkStatus)) {
            props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please read the message",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            })
        } else {
            handleClose(data)
        }
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                keepMounted
                aria-labelledby="alert-title"
                aria-describedby="alert-description"
            >
                <DialogTitle id="alert-title" >
                    <Typography style={{ display: 'flex' }}>
                        {alertIcon[data?.type ?? 3] ? alertIcon[data?.type ?? 1] : ''}
                        <Typography variant='span' style={{ paddingTop: 5, fontWeight: "bold" }}>{alertType[data?.type ?? 1]}</Typography>
                    </Typography>
                    <CloseIcon onClick={() => handleClose({})} style={{ float: "right", marginTop: "-26px", cursor: "pointer" }} />
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-description" style={{ border: `1.5px solid ${alertColorMain[data?.type ?? 1]}`, borderRadius: "8px" }}>
                        <Typography style={{
                            background: alertColorSec[data?.type ?? 1],
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            padding: "8px 11px", color: alertColorMain[data?.type ?? 1],
                            fontWeight: 800, minWidth: 500
                        }}>Message</Typography>
                        <Typography style={{ padding: "10px 10px 15px 10px" }}>
                            {data?.alertText ?? ""}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: "5px 23px 17px", justifyContent: data.type !== 3 ? "" : "space-between" }}>
                    {data?.type === 3 && <FormControlLabel
                        control={<Checkbox checked={checkStatus} onChange={handleChange} name="checkedA" />}
                        label="Yes, I read the above message"
                    />}
                    <Button variant='contained' onClick={() => handleSubmit()} style={{ color: "white", background: alertColorMain[data?.type ?? 1] }}>
                        {alertText[data?.type ?? 1]}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}


export default withAllContexts(AlertsRestriction);