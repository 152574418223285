import React from 'react';
import headerIcons from "../../../assets/img/supportBoxes.svg";
import { ArrowLeftIcon } from "../../../components";
import Background from "../../../assets/img/supportBackground.svg"
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      background: `url(${Background})`,
      backgroundPosition: "bottom -400px left 120px",
      backgroundRepeat: "no-repeat",
      position: "fixed",
      width: "100%",
      height:"100vh"
    },
    text: {
      fontFamily: "pc_medium !important",
      fontSize: "24px",
      color: "#0071F2",
      width: "100%",
      paddingRight: "50px",
      marginBottom: "20px",
    },
    textBody: {
      fontFamily: "pc_medium !important",
      fontSize: "16px",
      color: "#6F6F6F",
    },
    startTourButton: {
      marginTop: "30px",
      fontSize: "14px",
      textTransform: "none",
      borderRadius: "8px",
      backgroundColor: "#0071F2",
      fontFamily: "pc_medium !important",
      color: "#fff",
      padding: "5px 20px",
      border: "none",
      '&:hover': {
        backgroundColor: '#0551a9',
    },
    },
  }));

function SupportHome(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={5} style={{ marginTop: "150px" }}>
            <div style={{ marginLeft: "130px" }}>
              <img src={headerIcons} />
              <Typography className={classes.text}>
                Welcome to Ainqa Hospital video consultation!
              </Typography>
              <Typography className={classes.textBody}>
                We are here to help and guide you, how our website works and how
                easily you can interact with our video consultation website.{" "}
                <br />
                <br />
                Step by step will explain the functionality, Which helps you with
                easy understanding.
              </Typography>
              <Button className={classes.startTourButton}>
                Start guided tour{" "}
                <div style={{marginLeft:"12px",display:"flex"}}>
                <ArrowLeftIcon
                  color={"#fff"}
                  width={"28px"}
                  height={"15px"}
                />
                </div>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
}

export default SupportHome;