import React from "react";
import { Div, Col, Row, DataTable } from "qdm-component-library";
import {
  TopNavbar,
  PatientInfoCard,
  RefNote,
  RefDetails,
  RefTo,
  RefFrom,
  RefLetter,
  PatientCareTypeCard,
  TopHeader,
  LoadingComp
} from "../../../components";
import {
  patientReferral,
  patientReferralTitle,
} from "../../../utils/routesList";
import { Routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import { withQueue, withAllContexts } from "../../../HOCs";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from "../../../contexts";
import {
  uploadImage,
  _calculateAge,
  AlertProps,
  checkError,
  fetchUserInfo,
  getImgUrl,
  makeName,
} from "../../../utils";
import moment from "moment";

class PatientReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOption: {},
      choosemember: [],
      appointmentData: [],
      allMasters: [],
      facility_type: [],
      fin_data: [],
      loader: true
    };
    this.fetchUserInfo = fetchUserInfo.bind(this);
    this.successfun = this.successfun.bind(this);
  }

  async successfun(files) {
    // ----------------------------------
    const visitform = this.props.referralData;


      let formatedDate = moment().format("yyyy-MM-DD");

      if (visitform?.letter?.referralDate > formatedDate) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "ReferralDate date should not be greater than current date",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return;
      }

    if (visitform?.letter?.recivedDate < visitform?.letter?.referralDate) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Received date should not be less than referral date ",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }

  
    if (!visitform.letter?.letterNo) {
      visitform.letter["error"] = {
        letterNo: true,
        ...visitform.letter["error"],
      };
    }
    if (!visitform.letter?.referralDate) {
      visitform.letter["error"] = {
        referralDate: true,
        ...visitform.letter["error"],
      };
    }
    if (!visitform.letter?.recivedDate) {
      visitform.letter["error"] = {
        recivedDate: true,
        ...visitform.letter["error"],
      };
    }
    if (!visitform.from?.from) {
      visitform.from["error"] = {
        from: true,
        ...visitform.from["error"],
      };
    }
    if (!visitform.from?.facility_type) {
      visitform.from["error"] = {
        facility_type: true,
        ...visitform.from["error"],
      };
    }
    if (!visitform.from?.facility_name) {
      visitform.from["error"] = {
        facility_name: true,
        ...visitform.from["error"],
      };
    }
    // if (!visitform.from?.specialty) {
    //   visitform.from["error"] = {
    //     specialty: true,
    //     ...visitform.from["error"],
    //   };
    // }
    // if (!visitform.from?.practitioner_type) {
    //   visitform.from["error"] = {
    //     practitioner_type: true,
    //     ...visitform.from["error"],
    //   };
    // }
    // if (!visitform.from?.practitioner) {
    //   visitform.from["error"] = {
    //     practitioner: true,
    //     ...visitform.from["error"],
    //   };
    // }
    if (!visitform.to?.specialty) {
      visitform.to["error"] = {
        specialty: true,
        ...visitform.to["error"],
      };
    }

    if (!visitform.details?.intent_type) {
      visitform.details["error"] = {
        intent_type: true,
        ...visitform.details["error"],
      };
    }
    if (!visitform.details?.for) {
      visitform.details["error"] = {
        for: true,
        ...visitform.details["error"],
      };
    }
    if (!visitform.details?.priority) {
      visitform.details["error"] = {
        priority: true,
        ...visitform.details["error"],
      };
    }
    if (!visitform.details?.request_reason) {
      visitform.details["error"] = {
        request_reason: true,
        ...visitform.details["error"],
      };
    }
    if ((visitform.details?.service_requested?.length === 0) || (!visitform.details?.service_requested?.length)) {
      visitform.details["error"] = {
        service_requested: true,
        ...visitform.details["error"],
      };
    }
  
    visitform.setReferralDetails({
      ...visitform,
    });
    if (
      visitform.letter?.letterNo &&
      visitform.letter?.referralDate &&
      // visitform.letter?.recivedDate &&
      visitform.from?.from &&
      visitform.from?.facility_type &&
      visitform.from?.facility_name &&
      // visitform.from?.specialty &&
      // visitform.from?.practitioner_type &&
      visitform.to?.specialty &&
      // visitform.details?.intent_type &&
      // visitform.details?.for &&
      // visitform.details?.priority &&
      // visitform.details?.request_reason
      visitform.details?.service_requested
      // visitform.note?.reason
    ) {
      let UploadedFiles = [];
      if (files && files.length > 0) {
        UploadedFiles = await uploadImage(files);
      }
      this.handlesubmit(UploadedFiles);
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  }

  handlesubmit = async (UploadedFiles) => {
    const { primaryMember = {} } = this?.props?.location?.state ?? {};
    const referralDatas = this.props.referralData;
    const serviceRequested = referralDatas?.details?.service_requested?.map?.(e => e.value)?.join?.(", ") || "";
    const data = [
      {
        doc: {
          RefSourcePatientId: this.props.location?.state?.patientId,
          HealthCareServiceRequested: referralDatas?.from?.facility_name?.value ?? "",
          RefSourceLetDate: referralDatas?.letter?.referralDate
            ? Math.floor(new Date(referralDatas?.letter?.referralDate) / 1000)
            : 0,
          RefSourceLetRef: `${referralDatas.letter.letterNo || ""}` ?? "",
          RefLetReceivedDate: referralDatas?.letter?.recivedDate
            ? Math.floor(new Date(referralDatas?.letter?.recivedDate) / 1000)
            : Math.floor(new Date() / 1000),
          RequestorOrganizationType: referralDatas?.from?.facility_type?.value ?? "",
          RequestorOrganizationEntityType: "",
          RequestorEncounterType: "",
          RequestorEncounter: "",
          RequestorEpisodeofCare: "",
          RequestorSpecialty: referralDatas?.from?.specialty?.value ?? "",
          RequestorPractionerType: referralDatas?.from?.practitioner_type?.value ?? "",
          RequestorPractioner: referralDatas?.from?.practitioner?.value ?? "",
          RequestorPractionerName: "",
          RequestorLocationType: "",
          RequestorLocationCode: "",
          RequestorLocationName: referralDatas?.to?.location?.value ?? "",
          identifier: [],
          basedOn: referralDatas?.from?.from?.value ?? "",
          replaces: "",
          status: "",
          intent: referralDatas?.details?.intent_type?.value ?? "",
          Type: "",
          priority: referralDatas?.details?.priority?.value ?? "",
          serviceRequested: serviceRequested,
          subject: referralDatas?.details?.for?.value ?? "",
          context: "",
          occurrenceDateTime: 1,
          occurrencePeriod: [],
          authoredOn: 1,
          reasonCode: "",
          reasonReference: referralDatas?.details?.request_reason?.value ?? "",
          description: "",
          note: [
            {
              // "author": "",
              authorreference: referralDatas?.note?.reason?.value ?? "",
              time: 1,
              text: referralDatas?.note?.multiLine ?? "",
            },
          ],
          relevantHistory: "",
          patientId: this.props.location?.state?.patientId,
          recipientBookingReqd: true,
          recipientBookingRef: "",
          recipientEncounter: "",
          supportingInfo: "",
          recipientLocationName: referralDatas?.to?.location?.value ?? "",
          recipientLocationCode: "",
          recipientLocType: "",
          recipientPracName: "",
          recipientPract: referralDatas?.to?.practitioner?.value ?? "",
          recipientPractType: referralDatas?.to?.practitioner_type?.value ?? "",
          recipientSpecialty: referralDatas?.to?.specialty?.value ?? "",
          recipientEncType: "",
        },
      },
    ];

    const res = await this.props.ORGANIZATION_REFERRAL_REGISTRATION({
      payloaddata: data[0],
    });
    const status = checkError(res?.payload);
    const { nextRoute } = this.props.location.state;
    const nr = nextRoute ? nextRoute : Routes.PatientDashBoard;
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    if (res.payload?.data?.id?.validation_error) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: res.payload.data.id.validation_error[0].Errormsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Referral id generated successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      const { from, to } = this.props.referralData;
      const propObject = {
        pathname: Routes.doctorListing, //nr,
        state: {
          nextRoute: Routes.confirmBooking,
          cityName: "",
          doctors: [],
          mobileNo: this.props.location?.state?.mobileNo,
          walkin: true,
          referral: true,
          location: {
            address: to?.location?.value || "",
            latitude: 0,
            longitude: 0,
            location: referralDatas?.to?.location?.value,
          },
          hospital: [
            {
              label: from.facility_name.value,
              value: from.facility_name.label,
            },
          ],
          specialities: [
            {
              value: to?.specialty?.label,
              label: to?.specialty?.value,
              type: "Speciality",
            },
          ],
          symptoms: [],
          symptomsSearch: [],
          selectedUser: {
            name: makeName(primaryMember?.allData?.names?.[0]),
              // primaryMember?.allData?.names?.[0]?.prefix +
              // " " +
              // (primaryMember?.allData?.names?.[0]?.text || primaryMember?.allData?.names?.[0]?.given),
            email: primaryMember?.allData?.telecom?.[1]?.value,
            userId: primaryMember?.id,
            birthDay: primaryMember?.birthDay,
            gender: primaryMember?.gender,
            fileid: primaryMember?.imgUrl,
            mobile: this.props.location?.state?.mobileNumber,
          },
          userNo: this.props.location?.state?.mobileNumber,
          userId: primaryMember?.id,
          patientId: this.props.location?.state?.patientId,
          refferalId: res?.payload?.data?.Result?.[0]?.properties?.doc?._id,
          mobileNumber: this.props.location?.state?.mobileNumber,
        },
      };
      this.props.history.push(propObject);
    }
  };
  changeState = (key, val) => {
    this.setState({
      ...this.state,
      [key]: val,
    });
  };
  getallmasters = async () => {
    let orgId = this.props.loggedUserInfo?.data?.org_id;
    if (!orgId) {
      const userInfo = await this.fetchUserInfo();
      orgId = userInfo?.org_id || ""; 
    }
    await this.props.GET_REFERRAL_LOCATION({ orgId });
    const data = await this.props.MASTER_SEARCH({
      type: [
        "Level Of Care",
        "Department",
        "VISITTYPE",
        "Occupation",
        "NAMEPREFIX",
        "NAMESUFFIX",
        "Patient Type",
        "Race",
        "Ethnicity",
        "Religion",
        "RELATIONSHIP",
        "MARITALSTATUS",
        "LANGUAGE",
        "Town",
        "District",
        "State",
        "Country",
        "ALTERNATEIDTYPE",
        "Blood Group",
        "SPECIALTY",
        "Document Type",
        "ENCOUNTERTYPE",
        "ENCOUNTERCLASS",
        "STARTTYPE",
        "LOCATION",
        // "Encounter Type","Encounter Class","Start type",
        "Reason",
      ],
    });
    this.setState({
      allMasters: data?.payload?.data,
    });
  };
  async componentDidMount() {
    const patientID = this.props?.location?.state?.patientId || 0;
    if (this.props.GET_FACILITY_TYPE) {
      const facility_type = await this.props.GET_FACILITY_TYPE({
        type: "ORGTYPE",
      });
      console.log(facility_type);
      const facility_type_Data = facility_type?.payload?.data?.map((v) => {
        return {
          value: v?.coding?.[0]?.display,
          codingId: v?.coding?.[0]?._id,
          codeableId: v?._id,
        };
      });
      this.setState({
        facility_type: facility_type_Data,
      });
    }
    await this.getallmasters();
    const appointmentData = await this.props.APPOINTMENT_READ({
      appointmentId: this.props.location?.state?.appointmentId,
    });
    this.setState({
      appointmentData: appointmentData?.payload?.data,
    });
    const payload = {
      userid: this.props?.location?.state?.personId,
    };
    if (this.props.GET_RELATED_PERSON) {
      const data = await this.props.GET_RELATED_PERSON(payload);
      if (data?.payload?.data) {
        this.setState({
          choosemember: [...data?.payload?.data],
        });
      }
    }
    if (this.props.GET_FINANCIAL) {
      const fin_data = await this.props.GET_FINANCIAL({ patientId: patientID });
      if (fin_data?.payload?.data) {
        const fin_DATA = fin_data?.payload?.data?.map((v) => {
          const setdata = v?.payersprioritycoverage?.[0];
          return {
            pic: "images/Group 90507.svg",
            name: null,
            care_type: setdata?.guarantorid,
						title_name: setdata?.receivertype + ", " + setdata?.policyid,
          };
        });
        this.setState({
          selectOption: fin_DATA[0],
          fin_data: fin_DATA,
        });
      }
    }
    this.setState({
      ...this.state,
      loader: false
    })
  }
  render() {
    const { primaryMember = {} } = this?.props?.location?.state ?? {};
    return (
      <Div
        id="Referral_Registration_referral_parent_div"
        className="light-background-color"
      >
        <TopHeader
          parent_id={'dashboard'}
          noHome={true} middleComponent={<></>} 
        />
        <TopNavbar
          newUser
          parent_id={"Referral_Registration"}
          successfun={this.successfun}
          navBarArr={patientReferral}
          title={patientReferralTitle}
        />
        <Div
          id="Referral_Registration_referral_sub_div"
          style={{ padding: "0px 15px",
            height:'calc(100vh - 128px)', // 128px = 72px + 56px // 72 - topheader , 56 - topnav
            overflow:'auto',
            display: this.state.loader ? "flex" : "block",
            alignItems: "center",
            justifyContent:this.state.loader ? "center" : "unset"
          }}
        >
          <LoadingComp loading={this.state.loader} >
          <Row id="Referral_Registration_referral_div">
            <Col
              id="Referral_Registration_patinetinfo_div"
              lg={6}
              md={6}
              inLineStyles={{ padding: 5 }}
            >
              {/* <PatientListView letter="A" title="Chris Richards Chavez" noright="true" /> */}
              <PatientInfoCard
                parent_id={"Referral_Registration"}
                pic={getImgUrl(primaryMember?.imgUrl)}
                name={primaryMember?.name}
                age={
                  _calculateAge(
                    new Date(moment(primaryMember.birthDay, "DD/MM/YYYY"))
                  ) + " Yrs"
                }
                gender={primaryMember.gender}
                mrn_id={primaryMember.mrn || "---"}
                pi_id={primaryMember.pid || "---"}
                padding
              />
            </Col>
            <Col
              id="Referral_Registration_patientcare_div"
              lg={6}
              md={6}
              inLineStyles={{ padding: 5 }}
            >
              {/* <PatientListView letter="A" title="Chris Richards Chavez" noright="true" /> */}
              <PatientCareTypeCard
                parent_id={"Referral_Registration"}
                careList={[...this.state.fin_data]}
                selected={this.state.selectOption}
                onChange={(data) => this.changeState("selectOption", data)}
                padding
              />
            </Col>
          </Row>
          <RefLetter parent_id={"Referral_Registration_referral"} />
          <RefFrom
            parent_id={"Referral_Registration_referral"}
            facility_type={this.state.facility_type}
            allMasters={this.state?.allMasters}
            choosemember={this.state.choosemember}
          />
          <RefTo
            parent_id={"Referral_Registration_referral"}
            allMasters={this.state?.allMasters}
            appointmentData={this.state.appointmentData}
          />
          <RefDetails
            parent_id={"Referral_Registration_referral"}
            allMasters={this.state?.allMasters}
          />
          <RefNote
            parent_id={"Referral_Registration_referral"}
            allMasters={this.state?.allMasters}
          />
          </LoadingComp>
        </Div>
        {/* <Div className="p-continue-action">
                <Button>Back</Button>
            </Div> */}
      </Div>
    );
  }
}

PatientReferral.contextType = AlertContext;
const mapStateToProps = (state) => ({
  oraganizationRegisterPatient:
    state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  loggedUserInfo: state?.authSlice?.loggedUserInfo
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withQueue(withAllContexts(PatientReferral))));
// export default withRouter(PatientReferral);
