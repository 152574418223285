import React from "react";
import { Button, Div } from 'qdm-component-library'
import {
    TopNavbar, PatientIdentification, PatientNames,
    PatientCotactPoint, PatientAddress, NextOfKinDetails
} from "../../../components"
import "./style.css";
import { patientRegister, patientRegisterTitle } from "../../../utils/routesList";
import { Routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
class NextOfKinForm extends React.Component {
    constructor(props) {
        super(props);
        this.successfun = this.successfun.bind(this);
    }
    successfun() {
        const { nextRoute } = this.props.location.state;
        const nr = nextRoute ? nextRoute : Routes.PatientDashBoard
        this.props.history.push({
            pathname: nr,
            state: { nextRoute: Routes.financialRegistration }
        })
    }
    render() {
        return <Div className="light-background-color">
            <TopNavbar successfun={this.successfun} title={patientRegisterTitle} navBarArr={patientRegister} />
            <NextOfKinDetails />
            <PatientIdentification />
            <PatientNames />
            <PatientCotactPoint />
            <PatientAddress />
            <Div className="p-continue-action">
                <Button className="nextKing-btn">Go Back</Button>
                <Button className="nextKing-btn">Cancel</Button>
                <Button className="nextKing-btn">+Add</Button>
            </Div>
        </Div>

    }
}


export default withRouter(NextOfKinForm);