import { Typography } from "@material-ui/core";
import React from "react";
import { patientInfoJson } from "./dummyJson";
import { useStyles } from "./styles";

const PatientBillingDetails = (props) => {
  const classes = useStyles();
  const {dataPass} = props;
  return (
    <div>
      <div className={classes.alignSpacebetween} style={{paddingBottom:"12px"}}>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Name :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.patient?.name}
          </Typography>
          <div className={classes.dot}></div>
          <Typography className={classes.label}>
            {dataPass?.patient?.age}, {dataPass?.patient?.gender}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>MRN : </Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.patient?.mrn}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Encounter :</Typography>
          <Typography className={classes.labelValue}>
            {props?.encounterNo}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Specialty :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.practitioner?.speciality}
          </Typography>
        </div>
      </div>

      <div className={classes.alignSpacebetween}>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Location :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.practitioner?.location}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Start Date : </Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.encounterStartDate}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>End Date :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.encounterEndDate}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Doctor :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.practitioner?.name}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PatientBillingDetails;
