import React from "react";
import { makeStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import AvcReport from "../../../assets/img/avc_report.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#FFF",
		padding: "8px 12px",
		borderRadius: 50,
		minHeight: "90vh",
	},
	title: {
		margin: 0,
	},
	dis: {
		fontSize: 14,
		color: "#6F6F6F",
	},
	skipbtn: {
		fontSize: 12,
		color: "#101010",
		border: "1px solid #E0E0E0",
		borderRadius: 8,
	},
	accbtn: {
		backgroundColor: "#0071F2",
		fontSize: 12,
		borderRadius: 8,
		"&:hover": {
			backgroundColor: "#0071F2",
		},
	},
	climeDiv: {
		display: "flex",
		backgroundColor: "#ECF9FF",
		border: "1px solid #C1E1F3",
		padding: "14px 12px",
		borderRadius: 8,
		marginBottom: "2%",
	},
	climeTitle: {
		margin: 0,
		color: "#2F9CD8",
		fontSize: 14,
	},
	climeDis: {
		color: "#6DBAE4",
		fontSize: 12,
		padding: 0,
		margin: 0,
	},
	checkStatement: {
		"& .MuiTypography-body1": {
			fontSize: 14,
		},
	},
}));
const GreenCheckbox = withStyles({
	root: {
		color: "#36A2EB",
		"&$checked": {
			color: "#36A2EB",
		},
	},
})((props) => <Checkbox color="default" {...props} />);
export function Acceptance(props) {
	const classes = useStyles();
	const [state, setState] = React.useState({
		checkedB: true,
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};


	const accept = () =>{
		props?.proceed && props.proceed()
	}

	return (
		<div className={classes.root}>
			<div className={classes.climeDiv}>
				<img src={AvcReport} />
				<div style={{ marginLeft: "2%" }}>
					<h5 className={classes.climeTitle}>Consultation Consent Form</h5>
					<p className={classes.climeDis}>
						We request you to read and understand the consent form and its
						purpose. Then acknowledging the consent form only allow you to
						proceed with the further steps for this consultation.
					</p>
				</div>
			</div>
			<h4 className={classes.title}>Consent form of Aniqa Hospital</h4>
			<p className={classes.dis}>
				But I must explain to you how all this mistaken idea of denouncing
				pleasure and praising pain was born and I will give you a complete
				account of the system, and expound the actual teachings of the great
				explorer of the truth, the master-builder of human happiness. No one
				rejects, dislikes, or avoids pleasure itself, because it is pleasure,
				but because those who do not know how to pursue pleasure rationally
				encounter consequences that are extremely painful. Nor again is there
				anyone who loves or pursues or desires to obtain pain of itself.
				<br />
				<br />
				because it is pain, but because occasionally circumstances occur in
				which toil and pain can procure him some great pleasure. To take a
				trivial example, which of us ever undertakes laborious physical
				exercise, except to obtain some advantage from it? <br />
				<br />
				But who has any right to find fault with a man who chooses to enjoy a
				pleasure that has no annoying consequences, or one who avoids a pain
				that produces no resultant pleasure? On the other hand, we denounce with
				righteous indignation and dislike men who are so beguiled and
				demoralized by the charms of pleasure of the moment, so blinded by
				desire, that they cannot foresee the pain and trouble that are bound to
				ensue; and equal blame belongs to those who fail in their duty through
				weakness of will, which is the same as saying through shrinking from
				toil and pain. <br />
				<br />
				But who has any right to find fault with a man who chooses to enjoy a
				pleasure that has no annoying consequences, or one who avoids a pain
				that produces no resultant pleasure? On the other hand, we denounce with
				righteous indignation and dislike men who are so beguiled and
				demoralized by the charms of pleasure of the moment, so blinded by
				desire, that they cannot foresee the pain and trouble that are bound to
				ensue; and equal blame belongs to those who fail in their duty through
				weakness of will, which is the same as saying through shrinking from
				toil and pain.
			</p>
			<div style={{ textAlign: "center", color: "gray" }}>
				<FormControlLabel
					control={
						<GreenCheckbox
							checked={state.checkedG}
							onChange={handleChange}
							name="checkedG"
						/>
					}
					className={classes.checkStatement}
					label="I have read the terms and conditions and hereby give my consent"
				/>
				<div style={{ marginTop: "1%", marginBottom: "1%" }}>
					<Button
						variant="contained"
						color="primary"
						className={classes.accbtn}
						onClick={accept}
					>
						Accept Consent
					</Button>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<Button onClick={accept} variant="outlined" className={classes.skipbtn}>
						Skip For Now
					</Button>
				</div>
			</div>
		</div>
	);
}
