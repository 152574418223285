import React from "react";
import { UIColor } from "../../utils";

export const VitalIcon1 = (props) => {
  let width = props.width ? props.width : "40.8";
  let height = props.height ? props.height : "40.8";
  let color = props.color ? props.color : UIColor.primaryColor.color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40.8 40.8"
    >
      <g
        id="vital_signs"
        data-name="vital signs"
        transform="translate(-33.6 -77.6)"
      >
        <path
          id="Path_92832"
          data-name="Path 92832"
          d="M-244.273,382.648l8.788.079,1.31,1.906a.586.586,0,0,0,.635.238.639.639,0,0,0,.476-.516l1.072-6.869,2.422,14.73a.6.6,0,0,0,.6.516h0a.57.57,0,0,0,.6-.516l2.025-10.72.238.754a.645.645,0,0,0,.6.437h5.717a1.746,1.746,0,0,0,1.628,1.072,1.737,1.737,0,0,0,1.747-1.747,1.737,1.737,0,0,0-1.747-1.747,1.752,1.752,0,0,0-1.667,1.231h-5.2l-.873-2.62a.574.574,0,0,0-.635-.437.593.593,0,0,0-.556.516l-1.826,9.648-2.462-15.087a.6.6,0,0,0-.6-.516h0a.6.6,0,0,0-.6.516l-1.429,9.132-.6-.873a.679.679,0,0,0-.516-.278h-9.592"
          transform="translate(281.289 -284.033)"
          fill="#ec6a49"
        />
        <path
          id="Path_92833"
          data-name="Path 92833"
          d="M-214.224,362.917c0,7.625-4.567,12.692-18.776,24.484-9.833-8.208-15.567-13.651-17.567-18.192a18.085,18.085,0,0,1-1.167-6.292c0-8.042,5.651-12.692,9.942-12.692,4.417,0,7.917,4.292,7.958,4.333a1.173,1.173,0,0,0,.875.417h0a1.173,1.173,0,0,0,.875-.417c.042-.042,3.5-4.333,7.958-4.333,4.25,0,9.9,4.651,9.9,12.692m1.224,0c0-9.125-5.625-13.917-11.167-13.917-4.083,0-7.333,2.708-8.833,4.25-1.5-1.5-4.75-4.25-8.833-4.25-5.542,0-11.167,4.792-11.167,13.917,0,9.083,4.708,13.75,19.292,25.833A1.131,1.131,0,0,0-233,389a1.131,1.131,0,0,0,.708-.25C-217.708,376.667-213,372-213,362.917"
          transform="translate(287 -271)"
          fill="#01205c"
          stroke="#001c3c"
          stroke-width="0.8"
        />
      </g>
    </svg>
  );
};
