import React from "react";
import {
    TableContainer, Table, TableBody, TableHead, Grid,
    TableCell, TablePagination, Paper, TableRow, Typography, Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    tableHeader: {
        fontSize: "14px",
        // color: "#646464",
        border: "1px solid #d9d9d9",
        padding: 7,
        zIndex :0
    },
    tableBody: {
        padding: "12px",
        fontSize: "12px",
        border: "1px solid #d9d9d9",
        padding: 7,
    },
    viewlog: {
        fontSize: 12,
        cursor: "pointer",
        "&:hover": {
            color: 'rgb(0, 113, 242)'
        }
    },
    view: {
        fontSize: 12,
        cursor: "pointer",
        // width: "unset",
        // display: "inline",
        marginTop: -3,
        "&:hover": {
            color: 'rgb(0, 113, 242)'
        }
    },
    viewParent: {
        "&:hover": {
            color: 'rgb(0, 113, 242)'
        }
    }
});


function CommonTable(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        index: [], values: []
    })
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChange = (e, index) => {
        if (e.target.checked) {
            let data = state.index;
            data.push(index)
            setState({
                ...state,
                index: data
            })
        } else {
            let data = state.index;
            data.splice(data.indexOf(index), 1);
            setState({
                ...state,
                index: data
            })
        }
    }
    const selectDeselect = () => {
        if (state.index.length > 0) {
            setState({
                ...state,
                index: []
            })
        } else {
            let data = state.index;
            for (var i = 0; i < dataList.length; i++){
                data.push(i)
            }
            setState({
                ...state,
                index: data
            })
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const { isSelect = false, Header = [], dataList = [], tableData = [], Header1 = null, style = {}, rightAlign = [], pagination= false } = props
    return <React.Fragment>
        <TableContainer
            id={`CommonTable_parent_container`}
            component={Paper}
            style={{
                border: "1px solid #e4e4e4",
                boxShadow: "none",
                // maxHeight: 400,
                ...style,
            }}>
            <Table
                id={`CommonTable_parent_Table`}
                // stickyHeader
                aria-label="simple table"
            >
                <TableHead
                    id={`CommonTable_head`}
                    style={{ background: "#F9F9F9" }}
                >
                    <TableRow>
                        {isSelect && <TableCell className={classes.tableHeader}>
                            <Typography onClick={() => selectDeselect()}
                                style={{ color: "rgb(0, 113, 242)", cursor: "pointer" }}
                            >{state.index.length > 0 ? `De-Select all` : `Select all`}</Typography>
                        </TableCell>}
                        {Header.map((val, i) => {
                            return (
                                <TableCell
                                    colSpan={val.colSpan ? val.colSpan : 1}
                                    rowSpan={val.rowSpan ? val.rowSpan : 1}
                                    id={`CommonTable_${i}_table_TableCell`}
                                    style={{
                                        paddingLeft: (!isSelect && i === 0) ? "25px" : "",
                                        paddingRight: i === Header.length - 1 ? "25px" : "",
                                    }}
                                    className={classes.tableHeader}
                                    align={val.align ? val.align : "left"}
                                >
                                    {val.label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                    {Header1 && <TableRow>
                        {Header1.map((val, i) => {
                            return (
                                <TableCell
                                    colSpan={val.colSpan ? val.colSpan : 1}
                                    rowSpan={val.rowSpan ? val.rowSpan : 1}
                                    id={`CommonTable_${i}_table_TableCell`}
                                    style={{
                                        paddingLeft: (!isSelect && i === 0) ? "25px" : "",
                                        paddingRight: i === Header1.length - 1 ? "25px" : "",
                                    }}
                                    className={classes.tableHeader}
                                    align={val.align ? val.align : "left"}
                                >
                                    {val.label}
                                </TableCell>
                            );
                        })}
                    </TableRow>}
                </TableHead>
                <TableBody id={`CommonTable_table_dataList_tablebody`}>
                    {Array.isArray(dataList) &&
                    <>
                    {pagination ?
                    dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return <TableRow id={`CommonTable_${index}_table_dataList_TableRow`}>
                            {isSelect && <TableCell className={classes.tableHeader}>
                                <Checkbox style={{ padding: "0px 0px 0px 10px" }}
                                    checked={state.index.indexOf(index) > -1}
                                    onChange={(e) => handleChange(e, index)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </TableCell>}
                            {
                                tableData.map((val, i) => {
                                    return <TableCell
                                        id={`CommonTable_${i}${index}_table_dataList_TableCell`}
                                        className={classes.tableBody}
                                        style={{
                                            paddingLeft: (!isSelect && i === 0) ? "25px" : "",
                                            paddingRight: i === Header.length - 1 ? "25px" : "",
                                        }}
                                    >
                                        {getComponentType({
                                            value: row[val.label],
                                            type: val.type,
                                            index: index,
                                            classes: classes,
                                            row: row,
                                            ...props
                                        })}
                                    </TableCell>
                                })
                            }
                        </TableRow>
                    })
                    :
                    dataList.map((row, index) => {
                        return <TableRow id={`CommonTable_${index}_table_dataList_TableRow`}>
                            {isSelect && <TableCell className={classes.tableHeader}>
                                <Checkbox style={{ padding: "0px 0px 0px 10px" }}
                                    checked={state.index.indexOf(index) > -1}
                                    onChange={(e) => handleChange(e, index)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </TableCell>}
                            {
                                tableData.map((val, i) => {
                                    return <TableCell
                                        id={`CommonTable_${i}${index}_table_dataList_TableCell`}
                                        className={classes.tableBody}
                                        style={{
                                            paddingLeft: (!isSelect && i === 0) ? "25px" : "",
                                            paddingRight: i === Header.length - 1 ? "25px" : "",
                                        }}
                                    >
                                        {getComponentType({
                                            value: row[val.label],
                                            type: val.type,
                                            index: index,
                                            classes: classes,
                                            row: row,
                                            ...props
                                        })}
                                    </TableCell>
                                })
                            }
                        </TableRow>
                    })
                    }
                    </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {pagination &&
        <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={dataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />}
    </React.Fragment>
}

const getComponentType = (data) => {
    switch (data.type) {
        case "TEXT": {
            return data.value;
        }
        case "INCRIMENT": {
            return data.index + 1;
        }

        case "VIEWLOG": {
            return <Typography onClick={() => {
                data.viewLog(data.row)
            }} className={data.classes.viewlog}>View Log</Typography>
        }
        case "VIEW": {
            return <Grid onClick={() => { data.viewFun(data.row) }} className={data.classes.viewParent} style={{ display: "grid", gridTemplateColumns: "14px 1fr" }}>
                <VisibilityIcon style={{ width: 12, height: 12 }} />
                <Typography className={data.classes.view}> View</Typography>
            </Grid>
        }
        default: {
            return data.value;
        }
    }
};

export default CommonTable;