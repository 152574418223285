import { Typography } from "@material-ui/core";
import React from "react";
import { billingInfoJson, patientInfoJson } from "./dummyJson";
import { useStyles } from "./styles";

const BillingInfo = (props) => {
  const { dataPass } = props;
  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.alignSpacebetween}
        style={{ paddingBottom: "12px" }}
      >
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Bill No :</Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.billNo}
          </Typography>
        </div>
        <div className={classes.alignItems}>
          <Typography className={classes.label}>Bill Date : </Typography>
          <Typography className={classes.labelValue}>
            {dataPass?.billdate}
          </Typography>
        </div>
        {props?.forInsurance && (
          <>
            <div className={classes.alignItems}>
              <Typography className={classes.label}>GL No :</Typography>
              <Typography className={classes.labelValue}>
                {dataPass?.glNo}
              </Typography>
            </div>
            <div className={classes.alignItems}>
              <Typography className={classes.label}>GL Date :</Typography>
              <Typography className={classes.labelValue}>
                {dataPass?.glDate}
              </Typography>
            </div>{" "}
          </>
        )}
        {!props?.forInsurance && (
          <>
            <div className={classes.alignItems}>
              <Typography className={classes.label}>Payer :</Typography>
              <Typography className={classes.labelValue}>
                {dataPass?.patient?.name}
              </Typography>
            </div>
          </>
        )}
      </div>

      {props?.forInsurance && (
        <div className={classes.alignSpacebetween}>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Payer :</Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.payerid?.shortdesc}
            </Typography>
          </div>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Guarantor : </Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.guarantor}
            </Typography>
          </div>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Plan :</Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.plan}
            </Typography>
          </div>
          <div className={classes.alignItems}>
            <Typography className={classes.label}>Sponsor :</Typography>
            <Typography className={classes.labelValue}>
              {dataPass?.sponsor}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingInfo;
