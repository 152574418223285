import React from 'react';
import { withRouter } from 'react-router-dom';
import { WebDataRocksTable } from '../../../../components';
import { Routes } from "../../../../router/routes";
import '../index.css';
const CurrentVisits = (props) =>{
   
    const REPORT = (data) => {
        return{
            dataSource:{
                dataSourceType:'json',
                data:data
            },
            options:{
                grid:{
                    type:'flat',
                    showTotals: "off",
                    showGrandTotals: "off",
                }, 
            },
            slice: {
                columns: props?.columnToShow
            },
        }
    }

    const [state,setState] = React.useState({
        report:props?.report?.length ? REPORT(props?.report) :  []
    })

    React.useEffect(()=>{
        setState({
            ...state,report:props?.report?.length ? REPORT(props?.report) :  []
        })
    },[props?.report])


    const customizeCellFunction = (cellBuilder, cellData) =>{
        if (cellData?.measure?.uniqueName === "contact") {
            cellBuilder.text=`
            <div class="labelStyle">
                ${(cellData?.label).replace(/"/g, "")}
            </div>`
        } else if(cellData?.measure?.uniqueName === "action"){
            if (cellData?.member?.caption) {
                cellBuilder.text = `<div 
                style='display:flex;align-items:center;justify-content:center;cursor:pointer'
                >
                <svg 
                {...props}
                  xmlns="http://www.w3.org/2000/svg"
                  width=15px
                  height=15px
                  viewBox="0 0 14 10.18"
                >
                   
                  <path class="cls-7" d="M8,4C3.634,4,1.562,7.74,1.06,8.808a.66.66,0,0,0,0,.563C1.562,10.439,3.634,14.18,8,14.18c4.348,0,6.422-3.714,6.933-4.8a.686.686,0,0,0,0-.587C14.419,7.714,12.346,4,8,4ZM8,5.909A3.181,3.181,0,1,1,4.816,9.09,3.182,3.182,0,0,1,8,5.909ZM8,7.181A1.909,1.909,0,1,0,9.907,9.09,1.909,1.909,0,0,0,8,7.181Z" transform="translate(-0.997 -4)"/>
                  </svg>
                </div>`
            } else {
                cellBuilder.text = `Action`
            }
        }
        else if(
            (
                cellData?.measure?.uniqueName === "View Patient" ||
                cellData?.measure?.uniqueName === "Attend" 
                ) && 
                cellData?.label === "true"
            ){
            cellBuilder.text=`
                            <div class='viewDiv'>
                               ${cellData?.measure?.uniqueName}
                            </div>
                        `
        }
        else if(cellData?.measure?.uniqueName === "img" && cellData?.type !== "header"){
                cellBuilder.addClass("imgDiv");
                cellBuilder.text=`
                <div class='centerDiv'>
                    <div class='imgWrapper'>
                        <img src=${cellData?.member?.caption} class='img' />
                    </div>
                </div>
            `
        }
        else if(cellData?.type !== "header"){
            cellBuilder.addClass("imgDiv");
            cellBuilder.text=`<div class='labelStyle'>
                ${cellData?.label}
            </div>`;
        }     
    }

    const cellClick = (data) => {
        if (props?.permission?.write?.indexOf?.("listingScreenF") > -1) {
            if (data.hierarchy.uniqueName === "action") {
                let val = JSON.parse(data.label)
                debugger
                props.history.push({
                    pathname: Routes.patientVisitModify,
                    state: {
                        role: "view",
                        appointmentId: val?.appNo ?? null,
                        patientId: val?.patientId,
                        walkin: val.visitType === "walkin",
                        personId: val?.personId,
                        appointment_Id: val?.appNo ?? null,
                        encounter_id: val.enId,
                        mobileNo: val.contact,
                    },
                });
            }
        }
        // else if (data.columnIndex === 9) {
        //     handleModify(null, val)
        // } else if (data.columnIndex === 10) {
        //     handlesidebarCancelbooking(null, val)
        // }
    }

    return(
        <div>
            <WebDataRocksTable
                report={state?.report}
                customizeCellFunction={customizeCellFunction}
                cellClick={(data)=>cellClick(data)}
            />
        </div>
    )
}

export default withRouter(CurrentVisits);