import React from "react";
import {
    Div, Paper, Select, TextInput, H6,
    Col, Row, Text, Avatar, Divider, Checkbox, Upload, Image, Popover
} from 'qdm-component-library'
import "./style.css";
import { withAllContexts } from "../../HOCs";
import { UIColor, _calculateAge } from "../../utils";
import moment from "moment";
import { AlertProps } from "../../utils";
import ModifyStatus from "./modifyStatus";
import { EditIcon, CameraIcon } from "../../components/svg";
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Typography } from "@material-ui/core";
import RegUploadIcon from "../../assets/img/RegUploadIcon.png";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";

class PatientNames extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            Showfilter: this.props[this.props.saveto].names,
            limited: false,
            editStatus: null,
            statusValue: "Inactive"
        }
    }

    editStatusOpen = (e) => {
        if (this.state.editStatus === null) {
            this.setState({ ...this.state, editStatus: e.currentTarget })
        } else {
            this.handlepopoverclose()
        }
    }

    handlepopoverclose = () => {
        this.setState({ ...this.state, editStatus: null })
    }
    onChangeState = (key, value) => {
        this.setState({
            ...this.state, [key]: value
        })
    }

    styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
         errorborderStyle:{
            borderRadius: 8,
            borderColor: 'red',
        },
        statusBackground: {
            position: "absolute",
            bottom: "0px",
            backgroundColor: "#000",
            width: "100%",
            borderRadius: "0px 0px 8px 8px",
            opacity: "0.4",
            height: "32px"
        },
        status: {
            position: "absolute",
            bottom: "5px",
            color: "white",
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "0px 10px",
           
        },
        dot: {
            width: 6,
            height: 6,
            backgroundColor: "#38C20A",
            borderRadius: '50%',
            margin: '0 5px',
        },
        inactiveDot: {
            width: 6,
            height: 6,
            backgroundColor: "#F58B00",
            borderRadius: '50%',
            margin: '0 5px',
        },
        cameraIcon: {
            cursor: 'pointer',
            width: "20px",
            position: "absolute",
            top: "18px",
            right: "8%",
            textAlign: "center",
            zIndex: 9999999
        },
        idSpace: {
            backgroundColor: UIColor.differentiationBackground.color, textAlign: "center", borderRadius: 8,color: UIColor.secondaryButtonColor.color
        },
        onUpload: {
            position: "absolute",
            top: "19px",
            right: "4%",
            zIndex: 100,
            padding: "10px 0px",
            backgroundColor: "#0000006e",
            borderRadius: "50%",
            height: 26
        }
    }

    setData = (name, value, index, limit) => {

        if (limit) {
            if (limit < value.length) {
                return false
            }
        }

        let patientData = this.props[this.props.saveto]
        patientData.names[index][name] = value;
        if (patientData.names[index].error) {
            patientData.names[index].error[name] = false;
        }
        patientData[this.props.setTo]({
            ...patientData,
        })

    }

    setDetails = (name, value, next, limit) => {
        if (limit) {
            if (limit < value.length) {
                return false
            }
        }
        let patientData = this.props[this.props.saveto]
        patientData.details[name] = value;
        if (patientData.details?.error) {
            patientData.details.error[name] = false;
        }
        if (next === "age") {
            const age = moment().diff(moment(value, "YYYY-MM-DD"), "years");
            if (age > 200 || moment().unix() < moment(value, "YYYY-MM-DD").unix()) {
                if (patientData.details?.error) {
                    patientData.details.error[name] = true;
                } else {
                    patientData.details.error = {};
                    patientData.details.error[name] = true;
                }
            } else {
                debugger
                let days = moment().diff(moment(value, "YYYY-MM-DD"), "days");
                let months = moment().diff(moment(value, "YYYY-MM-DD"), "months");
                let years = moment().diff(moment(value, "YYYY-MM-DD"), "years");
                if (days <= 90) {
                    patientData.details[next] = days ;
                    patientData.details.ageType = { value : 'Days', label : 'Days' }
                } else if (days > 90 && days < 365) {
                    patientData.details[next] =  months;
                    patientData.details.ageType = { value : 'Months', label : 'Months' }
                } else if (days >= 365) {
                    patientData.details[next] =  years ;
                    patientData.details.ageType = { value : 'Years', label : 'Years' }
                }
                // patientData.details[next] = age;
                // let ageTypeOption = this.FlterOptions('VITALSUOM')
                // let option = ageTypeOption.filter(val => val.label.toLocaleLowerCase() === "years")[0];
                // patientData.details.ageType = option ? option : { value: 'Years', label: 'Years' };
                debugger
            }
        }
        if (name === "ageType" || name === "age" || name === "entered_age") {
            const ageType = patientData?.details?.ageType?.value;
            const age = patientData?.details?.age;
            const enteredAge = patientData?.details?.entered_age;
            if (ageType && age && enteredAge) {
                const newDate = moment().subtract(Number(age), ageType.toLowerCase()).format("YYYY-MM-DD");
                patientData.details.dob = newDate;
            }
        }
        patientData[this.props.setTo]({
            ...patientData,
        })
    }

    // setDetails = (name, value, next, limit) => {
    //     if (limit) {
    //         if (limit < value.length) {
    //             return false
    //         }
    //     }
    //     let patientData = this.props[this.props.saveto]
    //     patientData.details[name] = value;
    //     if (next === "age") {
    //         let age = _calculateAge(new Date(value))
    //         patientData.details[next] = age;
    //         patientData.details.ageType = { value: 'Years', label: 'Years' };
    //     }
    //     if (patientData.details?.error) {
    //         patientData.details.error[name] = false;
    //     }
    //     patientData[this.props.setTo]({
    //         ...patientData,
    //     })
    // }

    newRowAdd = () => {
        let patientData = this.props[this.props.saveto]
        patientData.names.push({})
        patientData[this.props.setTo]({
            ...patientData,
        })
    }

    getSize = (size) => {
        let fileSize = size / 1024 / 1024;
        if (fileSize < 1) {
            return `${parseInt(size / 1024) + 1} KB`
        } else {
            return `${parseInt(fileSize)} MB`
        }
    }
    handleDelete = (i) => {
        let patientData = this.props[this.props.saveto]
        if (patientData.names.length <= 1) {
            this.props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Atleast one name should be entered",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            });
        } else {
            patientData.names.splice(i, 1);
            patientData[this.props.setTo]({
                ...patientData,
            })
        }
    }

    handleLimit = () => {
        this.setState({
            limited: !this.state.limited
        })
    }

    FlterOptions = (type) => {
        const { allMasters } = this.props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
                "label": data[index]?.coding?.display,
                "_id": data[index]?.coding?._id,
            };
            lista.push(datatemp);
        }
        return lista
    }

    checkAge = (val) => {
        let years = moment().diff(val, 'years');
        let days = moment().diff(val, 'days');
        if (!(years < 200 && days > 0)) {
            return true
        }
        return false
    }

    checkAgeError = (details) => {
        if (!details?.dob && details?.error && details?.error?.['dob']) {
            return ''
        }
        else if (details?.error?.['dob'] && details?.dob && this.checkAge(details?.dob)) {
            return 'Age should be less than 200 years'
        }
        else {
            return ''
        }
    }

    enterAge = (val) => {
        if (val && parseInt(val) <= 200) {
            this.setDetails("age", val, null, 3)
        }
        else {
            this.setDetails("age", '', null, 3)
        }
    }

    handleSave = (n, d) => {

        let patientData = this.props[this.props.saveto]
        patientData[n] = d;
        patientData[this.props.setTo]({
            ...patientData,
        })
    }
    getUseOptions(value) {
        let option = this.FlterOptions('USE');
        const { names } = this.props[this.props.saveto];
        if(Array.isArray(names) && names.length > 0){
            let useList = names.map(val => val?.use?.value)?.filter(val => val)
            if(useList){
                option = option.filter(val => (useList.indexOf(val.value) === -1 || val.value === value))
            }
        }
        let sortedOption = option?.sort(function(a, b) {
            var textA = a?.value?.toUpperCase();
            var textB = b?.value?.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        return sortedOption;
    }
    render() {
        const { names, details, statusDetails } = this.props[this.props.saveto];
        const { labelStyle, borderStyle, statusBackground, status, dot, cameraIcon, idSpace, onUpload, inactiveDot,errorborderStyle } = this.styles;
        const { parent_id } = this.props;
        return <Div
            id={`${parent_id}_patient_name_parent_div`}
            className="pi-root">
            <Row
                id={`${parent_id}_patient_name_parent_row`}
                className={"pn-row-root"}>
                <Col
                    id={`${parent_id}_patient_name_upload_col`}
                    className="pn-image-root">
                    <Paper
                        id={`${parent_id}_patient_name_upload_paper`}
                        className="pi-paper pn-image-paper-root" style={{padding: 8}}>
                        <Div
                            id={`${parent_id}_patient_name_upload_avatar_div`}
                            style={{ position: "relative" }}
                        >
                            <Avatar
                                id={`${parent_id}_patient_name_upload_avatar`}
                                variant="square" src={details?.image?.url || this.props.personImg} className={"pn-image"} />
                            {true &&
                                <>
                                    <div ref={this.myRef} id={`${parent_id}_edit_patient_name_upload_position`} style={onUpload}>
                                        <Upload
                                            id={`${parent_id}_edit_patient_name_upload`}
                                            handleChange={(e, value) => this.setDetails("image", { url: e, file: value })}
                                            label=""
                                            accept="image/*"
                                            labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important', }}
                                            className={"pn-upload-actions-btn"}
                                            // list={}
                                            showIcon={false}
                                        />
                                    </div>
                                    <Div id={`${parent_id}_image_camera_image`} style={cameraIcon}>
                                        <img
                                            src={RegUploadIcon}
                                            style={{width: 22, height: 22, zIndex: 99}}
                                            alt="camera"
                                            onClick={() => {
                                                const current = this.myRef?.current;
                                                if (current) {
                                                    const btn = current?.querySelectorAll?.("input[type='file']")?.[0];
                                                    if (btn) {
                                                        btn.click();
                                                    }
                                                }
                                            }}
                                        />
                                    </Div>
                                    {this.props.parent_edit_id && <> 
                                    
                                    <Div id={`${parent_id}_background_status`} style={statusBackground}></Div>
                                    <div id={`${parent_id}_status_image`} style={status}>
                                        <Div id={`${parent_id}_status_dot`}
                                            style={statusDetails?.status === 'Deceased' ||
                                                statusDetails?.status === 'Revoke' ? inactiveDot : statusDetails?.status1 === 'Active' ? dot : inactiveDot}></Div>
                                        {/* {JSON.stringify(statusDetails?.reason1)} */}
                                        <Tooltip title={(statusDetails?.reason?.value) ? statusDetails?.reason?.value :
                                            (statusDetails?.reason1?.value) ? statusDetails?.reason1?.value : ''}>
                                            <Typography id={`${parent_id}_status_text`} className="pc_regular">
                                                {(statusDetails?.status || statusDetails?.status1 || 'Active')}</Typography>
                                        </Tooltip>
                                        <div style={{ flexGrow: 1 }}></div>
                                        <div id={`${parent_id}_status_text`} style={{ cursor: 'pointer', width: "20px", textAlign: "end" }} onClick={e => this.editStatusOpen(e)}>
                                            <EditIcon color="#fff" width="18px" height="13px" />
                                        </div>
                                    </div>
                                    
                                    </>}
                                    
                                    <Popover
                                        id={`${parent_id}_status_popOver`}
                                        open={this.state.editStatus}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        style={{ zIndex: "100", width: "670px", top: "200px" }}
                                    >
                                        <ModifyStatus
                                            parent_id={parent_id}
                                            handlepopoverclose={() => this.handlepopoverclose()}
                                            handleSave={this.handleSave}
                                            statusDetails={statusDetails}
                                            allMasters={this.props?.allMasters}
                                        />
                                    </Popover>
                                </>
                            }
                        </Div>

                        <Div
                            id={`${parent_id}_patient_name_upload_sub_div`}
                            className="pn-upload-actions" style={{padding: "9px 0px"}}>
                            {/* <Button className="pn-upload-actions-btn" name="Upload Image" /> */}
                            {this.props.parent_edit_id ?
                                <Div id={`${parent_id}_mrn_id_div`} style={idSpace}>
                                    <H6 id={`${parent_id}_mrn_id_div`} style={{ paddingBottom: "4px", color: UIColor.secondaryButtonColor.color }} className="pi-title">
                                        {this.props?.mrn}</H6>
                                </Div> :
                                <Div style={{padding: 4,lineHeight: 1, textAlign:"center", color: UIColor.secondaryButtonColor.color, backgroundColor: UIColor.differentiationBackground.color}}>
                                    MRN
                                </Div>
                                // <Upload
                                //     id={`${parent_id}_patient_name_upload`}
                                //     handleChange={(e, value) => {
                                //         if (value.type === "image/png" || value.type === "image/jpg" || value.type === "image/jpeg") {
                                //             this.setDetails("image", { url: e, file: value })
                                //         } else {
                                //             this.props.alert.setSnack({
                                //                 open: true,
                                //                 severity: AlertProps.severity.error,
                                //                 msg: "Please Select an Image file.",
                                //                 vertical: AlertProps.vertical.top,
                                //                 horizontal: AlertProps.horizontal.center,
                                //                 tone: true,
                                //             });
                                //         }
                                //     }}
                                //     label="Upload Image"
                                //     labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                                //     className={"pn-upload-actions-btn"}
                                //     showIcon={false}
                                //     accept="image/*"
                                // />
                            }
                        </Div>
                    </Paper>
                </Col>
                <Col
                    id={`${parent_id}_patient_name_sub_col`}
                    className="pn-names-root">
                    <Paper
                        id={`${parent_id}_patient_name_sub_paper`}
                        className="pi-paper" inLineStyles={{    height: "96% !important"}}>
                        <Div
                            id={`${parent_id}_patient_name_label_div`}
                        >
                            <H6
                                id={`${parent_id}_patient_name_label_paitient_h6`}
                                className="pi-title">
                                {this.props.saveto === 'nextOfKin' ? 'NEXT OF KIN NAME' : 'PATIENT NAMES'}</H6>
                            {
                                this.props.saveto !== 'nextOfKin' &&
                                <Text
                                    id={`${parent_id}_patient_name_label_addnew_text`}
                                    onClick={() => this.newRowAdd()} className="pi-addbtn">+ Add New
                                </Text>
                            }
                        </Div>

                        {names?.slice(0, (this.state.limited ? (names.length) : 1))?.map((val, i) => <Row
                            id={`${parent_id}_${i}_patient_name_map_row`}
                            className="pi-actions-root pi-names-root">
                            <Col
                                id={`${parent_id}_${i}_patient_name_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_${i}_patient_name_use_label_select`}
                                    value={val?.use?.value ?? null}
                                    // helperText={(val?.error && val?.error['use']) ? 'Is required' : ''}
                                    isRequired={false}
                                    options={this.getUseOptions(val?.use?.value ?? null)}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    // options={USE}
                                    // options={[
                                    //     { value: 'usual', label: 'US001' },
                                    //     { value: 'official', label: 'US002' },
                                    //     { value: 'temp', label: 'US003' },
                                    //     { value: 'nickname', label: 'US004' },
                                    //     { value: 'anonymous', label: 'US005' },
                                    //     { value: 'old', label: 'US006' },
                                    //     { value: 'maidan', label: 'US007' },
                                    // ]}
                                    error={(val?.error && val?.error['use']) ? true : false}
                                    onChangeValue={(e) => this.setData("use", e, i)} label="Use" showArrow placeholder="use" labelStyle={labelStyle} inLineStyles={(val?.error && val?.error['use']) ? errorborderStyle :borderStyle} hoverColor={UIColor.primaryColor.color}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_${i}_patient_name_prefix_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_${i}_patient_name_prefix_label_select`}
                                    value={val?.prefix?.value ?? null} onChangeValue={(e) => this.setData("prefix", e, i)}
                                    options={this.FlterOptions('NAMEPREFIX')}
                                    // helperText={(val?.error && val?.error['prefix']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    isRequired={false}
                                    error={(val?.error && val?.error['prefix']) ? true : false}
                                    label="Prefix" showArrow placeholder="prefix" labelStyle={labelStyle}
                                    inLineStyles={(val?.error && val?.error['prefix']) ? errorborderStyle : borderStyle} hoverColor={UIColor.primaryColor.color}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_${i}_patient_name_firstname_col`}
                                className="no-padding-left">
                                <TextInput
                                    autoFocus={true}
                                    id={`${parent_id}_${i}_patient_name_firstname_label_textinput`}
                                    // helperText={(val?.error && val?.error['first_name']) ? 'Is required' : ''}
                                    value={val.first_name ? val.first_name : ""}
                                    onChange={(e) => this.setData("first_name", e.target.value, i, 60)}
                                    isRequired={true}
                                    error={(val?.error && val?.error['first_name']) ? true : false}
                                    label="First Name" labelStyle={labelStyle} style={(val?.error && val?.error['first_name']) ? errorborderStyle : borderStyle}
                                    placeholder='first name'
                                    // onKeyPress={function (e) {
                                    //     var key = e.which;
                                    //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                                    //         return
                                    //     } else {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    hoverColor={UIColor.primaryColor.color}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_${i}_patient_name_middlename_col`}
                                className="no-padding-left">
                                <TextInput
                                    id={`${parent_id}_${i}_patient_name_middlename_textinput`}
                                    value={val.middle_name ? val.middle_name : ""}
                                    onChange={(e) => this.setData("middle_name", e.target.value, i, 60)}
                                    // helperText={(val?.error && val?.error['middle_name']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    // isRequired={true}
                                    error={(val?.error && val?.error['middle_name']) ? true : false}
                                    placeholder='middle name'
                                    // onKeyPress={function (e) {
                                    //     var key = e.which;
                                    //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                                    //         return
                                    //     } else {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    label="Middle Name" labelStyle={labelStyle} style={(val?.error && val?.error['middle_name']) ? errorborderStyle : borderStyle} hoverColor={UIColor.primaryColor.color} />
                            </Col>
                            <Col
                                id={`${parent_id}_${i}_patient_name_lastname_col`}
                                className="no-padding-left">
                                <TextInput
                                    id={`${parent_id}_${i}_patient_name_lastname_label_textinput`}
                                    value={val.last_name ? val.last_name : ""}
                                    onChange={(e) => this.setData("last_name", e.target.value, i, 60)}
                                    // helperText={(val?.error && val?.error['last_name']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    isRequired={false}
                                    // onKeyPress={function (e) {
                                    //     var key = e.which;
                                    //     if ((key >= 65 && key <= 90) || (key >= 95 && key <= 122)) {
                                    //         return
                                    //     } else {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    error={(val?.error && val?.error['last_name']) ? true : false}
                                    placeholder='last name'
                                    label="Last Name" labelStyle={labelStyle} style={(val?.error && val?.error['last_name']) ? errorborderStyle : borderStyle} hoverColor={UIColor.primaryColor.color}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_${i}_patient_name_suffix_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_${i}_patient_name_suffix_label_select`}
                                    value={val?.suffix?.value ?? null}
                                    onChangeValue={(e) => this.setData("suffix", e, i)}
                                    options={this.FlterOptions('NAMESUFFIX')}
                                    // helperText={(val?.error && val?.error['suffix']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    // isRequired={true}
                                    error={(val?.error && val?.error['suffix']) ? true : false}
                                    label="Suffix" showArrow placeholder="suffix" labelStyle={labelStyle}
                                    inLineStyles={(val?.error && val?.error['suffix']) ? errorborderStyle :  borderStyle} hoverColor={UIColor.primaryColor.color} />

                            </Col>
                            {this.props.saveto !== 'nextOfKin' && <Col
                                id={`${parent_id}_${i}_patient_name_show_col`}
                                style={{
                                    margin: 'auto', padding: 0
                                }}>
                                <Div
                                    id={`${parent_id}_${i}_patient_name_show_div`}
                                    style={{ display: 'flex', alignItems: 'center', marginTop: 18, width: "max-content" }}>
                                    {/* <i className="fa fa-trash-o" style={{color:'#FF4D4A',cursor:'pointer'}} aria-hidden="true"></i> */}
                                    <Div
                                        id={`${parent_id}_${i}_patient_name_delete_div`}
                                        style={{
                                            width: 15,
                                            height: 18,
                                            cursor: 'pointer'
                                        }}>
                                        <Image
                                            id={`${parent_id}_${i}_patient_name_delete_trash_image`}
                                            src="images/icons8-trash1.svg" alt="delete" onClick={() => this.handleDelete(i)} />
                                    </Div>
                                    {(names?.length > 1 && i === 0) && <Text
                                        id={`${parent_id}_${i}_patient_name_show_showless_label_text`}
                                        className="pc_medium" style={{
                                            color: '#F58B00',
                                            marginLeft: 15,
                                            cursor: 'pointer',
                                            fontSize: '14px'
                                        }}
                                        onClick={() => this.handleLimit()}>{this.state.limited ? 'Show Less' : `Show +${names?.length}`}
                                    </Text>}
                                </Div>
                            </Col>}
                        </Row>)}
                        <Divider
                            id={`${parent_id}_patient_name_bottom_divider`}
                            className="p-dashed-divider " inLineStyles={{ margin: '5px 0px' }} />
                        <Row
                            id={`${parent_id}_patient_name_parent_row`}
                            className="pi-basic-details pi-actions-root">
                            <Col
                                id={`${parent_id}_patient_name_gender_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_patient_name_gender_select`}
                                    isRequired={true}
                                    showArrow
                                    label="Gender"
                                    options={this.FlterOptions('GENDER')}
                                    // options={[
                                    //     { value: 'Male', label: 'Male' },
                                    //     { value: 'Female', label: 'Female' },
                                    //     { value: 'Unknown', label: 'Unknown' },
                                    // ]}
                                    value={details?.gender?.value ?? null}
                                    onChangeValue={(e) => this.setDetails("gender", e)}
                                    placeholder="select..."
                                    labelStyle={labelStyle}
                                    inLineStyles={(details?.error && details?.error['gender']) ? errorborderStyle : borderStyle}
                                    hoverColor={UIColor.primaryColor.color}
                                    // helperText={(details?.error && details?.error['gender']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    error={(details?.error && details?.error['gender']) ? true : false}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_patient_name_dateformat_col`}
                                className="no-padding-left">
                                {/* <TextInput
                                    id={`${parent_id}_patient_name_dateformat_textinput`}
                                    value={details.dob ? details.dob : ''}
                                    helperText={this.checkAgeError(details)}
                                    helperTextType="error"
                                    error={(details?.error && details?.error['dob']) ? true : false}
                                    max={moment(new Date()).format('YYYY-MM-DD')}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    // onKeyPress={function (event) {
                                    //     event.preventDefault();
                                    //     return
                                    // }}
                                    onChange={(e) => this.setDetails("dob", e.target.value, "age")}
                                    minDate={(moment().subtract(200, 'years')).format('YYYY-MM-DD')}
                                    isRequired={true}
                                    dateIcon="images/icons8-calendar-grey.svg"
                                    label="Date of Birth"
                                    type="date"
                                    labelStyle={labelStyle}
                                    style={(details?.error && details?.error['dob']) ? errorborderStyle : borderStyle}
                                    hoverColor={UIColor.primaryColor.color}
                                    className="dateInput"
                                /> */}
                                <CustomInputDatePicker
                                    disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    placeholderText = "dd/mm/yy"
                                    // minDate = {(moment().subtract(200, 'years')).format('YYYY-MM-DD')}
                                    // maxDate = {moment(new Date()).format('YYYY-MM-DD')}
                                    maxDate = { new Date()}
                                    minDate = { new Date(new Date().setFullYear(new Date().getFullYear() - 200)) }
                                    handleChange = {(value) => this.setDetails("dob", value ? moment(value).format("YYYY-MM-DD") : null, "age")}
                                    selectedDate = {details.dob ? new Date(details.dob) : null}
                                    inputField = {
                                        <TextInput 
                                            id={`${parent_id}_patient_name_dateformat_textinput`}
                                            label="Date of Birth"
                                            placeholder="dd/mm/yyyy"
                                            dateIcon="images/icons8-calendar-grey.svg"
                                            labelStyle={labelStyle} 
                                            isRequired={true}
                                            // onChange={() => alert("Hi")}
                                            value={details.dob ? moment(new Date(details.dob)).format("DD/MM/YYYY") : ""}
                                            style={ (details?.error && details?.error['dob']) ? errorborderStyle : borderStyle }
                                            hoverColor={UIColor.primaryColor.color}
                                            helperText={'Invalid Date of Birth'}
                                            disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                        />
                                    }
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_patient_name_age_col`}
                                className="no-padding-left">
                                <TextInput
                                    id={`${parent_id}_patient_name_age_textinput`}
                                    value={details?.age ?? ""} 
                                    onChange={(e) => this.enterAge(e.target.value)} 
                                    label="Age" 
                                    labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color}
                                    placeholder='age' type='number'
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_patient_name_age_type_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_patient_name_age_type_select`}
                                    isRequired={true}
                                    showArrow label="Age Type"
                                    placeholder="select..."
                                    labelStyle={labelStyle}
                                    inLineStyles={(details?.error && details?.error['ageType']) ? errorborderStyle : borderStyle}
                                    hoverColor={UIColor.primaryColor.color}
                                    // style={{width:"400px"}}
                                    options={this.FlterOptions('VITALSUOM')}
                                    // options={[
                                    //     { value: 'Years', label: 'Years' },
                                    //     { value: 'Months', label: 'Months' },
                                    //     { value: 'Days', label: 'Days' },
                                    // ]}
                                    value={details?.ageType?.value ?? null}
                                    onChangeValue={(e) => this.setDetails("ageType", e)}
                                    // helperText={(details?.error && details?.error['ageType']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    error={(details?.error && details?.error['ageType']) ? true : false}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_patient_name_aged_check_col`}
                                className="no-padding-left">
                                <H6
                                    id={`${parent_id}_patient_name_entered_age_h6`}
                                    className="default-size pc_regular">Entered Age</H6>
                                <Checkbox
                                    id={`${parent_id}_patient_name_entered_age_checkbox`}
                                    checked={details?.entered_age} onChange={(e) => this.setDetails("entered_age", !(details?.entered_age))} name="" color={UIColor.secondaryButtonColor.color}
                                    style={{marginTop: "8%"}}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_patient_name_marital_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_patient_name_maritalstatus_label_select`}
                                    options={this.FlterOptions('MARITALSTATUS')}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    label="Marital Status" value={details?.marital_status?.value ?? null} onChangeValue={(e) => this.setDetails("marital_status", e)} showArrow placeholder="select..." labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor={UIColor.primaryColor.color} />
                            </Col>
                            <Col
                                id={`${parent_id}_patient_name_nationality_col`}
                                className="no-padding-left">
                                <Select
                                    id={`${parent_id}_patient_name_nationality_select`}
                                    isRequired={true} 
                                    showArrow label="Nationality"
                                    // options={this.FlterOptions('COUNTRY')}
                                    options={this.props.Nas}
                                    // options={[
                                    //     { value: "Indian" },
                                    //     { value: "Russian" },
                                    //     { value: "German" },
                                    // ]}
                                    // helperText={(details?.error && details?.error['nationality']) ? 'Is required' : ''}
                                    disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                                    error={(details?.error && details?.error['nationality']) ? true : false}
                                    value={details?.nationality?.value ?? null} onChangeValue={(e) => this.setDetails("nationality", e)} placeholder="select..." labelStyle={labelStyle} inLineStyles={(details?.error && details?.error['nationality']) ? errorborderStyle :  borderStyle} hoverColor={UIColor.primaryColor.color} />
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>
        </Div >

    }
}


export default withAllContexts(PatientNames);