import React, { useState } from "react";
import { WorkList } from "./workList";
import { makeStyles, Typography } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../router/routes";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { TopHeader,LoadingComp } from "../../../../components";
import moment from "moment";
import AssignReassign from "../../assignReassign";
import { Modal } from "qdm-component-library";
import { getUtcTime } from "../../../../utils";
import { checkWithCasbin } from "../../../../utils/permissonHandling";
import { CircularProgress } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 20,
    height: "calc(100vh - 72.4px)",
    overflow: "auto",
  },
  header: {
    fontSize: "16px",
    color: "#101010",
    fontFamily: "pc_semibold",
  },
  rowCenter: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: {
    backgroundColor: "#F0F0F0",
    width: 26,
    height: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

export const WorkListParent = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    report: [],
    appDetails: {},
  });

  const [modal, setModel] = React.useState(false);

  React.useEffect(() => {
    if (props?.location?.state) {
      fetchInfo(props?.location?.state);
    }
  }, [props?.location?.state]);

  const [permission, setPermission] = React.useState({});
  React.useEffect(() => {
    const initFun = async () => {
      let permission = await checkWithCasbin(["queueSideNav"]);
      setPermission(permission);
    };
    initFun();
  }, []);
  const assignData = useSelector((state) =>
    props?.location?.state === "Assign Practitioner"
      ? state?.dashboardApiSlice?.assignList
      : props?.location?.state === "Re-Assign Practitioner"
      ? state?.dashboardApiSlice?.assignList
      : state?.dashboardApiSlice?.closeConsultation
  );
  const datas = useSelector((state) => state?.dashboardApiSlice);

  const [loading,setLoading] = useState(true)
  const fetchInfo = async (value) => {
    setLoading(true)
    await dispatch(actions.ASSIGN_PRACTITIONER_ROLE());
    await dispatch(actions.ASSIGN_PRACTITIONER_TYPE());
    await dispatch(actions.DISPOSITION_TYPE());
    await dispatch(actions.FOLLOWUPUOM());
    await dispatch(actions.REASON_MASTER());
    let data;
    let payload = {
      startDate: getUtcTime(moment().startOf("day")), //'1636263146', moment().startOf("day").unix(),
      endDate: getUtcTime(moment().endOf("day")), //moment().endOf("day").unix() '1636349546',
    };
    if (value === "Assign Practitioner") {
      data = await dispatch(actions.GET_ASSIGN_PRACTITIONER_LIST(payload));
    } else if (value === "Re-Assign Practitioner") {
      data = await dispatch(actions.GET_REASSIGN_PRACTITIONER_LIST(payload));
    } else if (value === "Close Consultation") {
      data = await dispatch(actions.GET_CLOSE_PRACTITIONER_LIST(payload));
      
    }
    onChangeState("report", data?.payload?.data);
    setLoading(false)
  };

  const columnToShow = () => {
    let data = [
      { uniqueName: "name", caption: "NAME" },
      { uniqueName: "mrn", caption: "MRN" },
      { uniqueName: "age", caption: "AGE" },
      { uniqueName: "mobileNo", caption: "MOBILE NO" },
      { uniqueName: "apptRefNo", caption: "APPT REF NO" },
      { uniqueName: "apptDate&Time", caption: "APPT DATE AND TIME" },
      { uniqueName: "encounterNo", caption: "ENCOUNTER NO" },
      { uniqueName: "visitType", caption: "VISIT TYPE" },
      { uniqueName: "status", caption: "STATUS" },
      { uniqueName: "action", caption: "ACTION" },
    ];
    if (props?.location?.state === "Assign Practitioner") {
      return data;
    } else if (props?.location?.state === "Re-Assign Practitioner") {
      data.splice(1, 0, { uniqueName: "assignedPractitioner", caption: "ASSIGNED PRACTITIONER" });
      return data;
    } else if (props?.location?.state === "Close Consultation") {
      data.splice(1, 0, { uniqueName: "assignedPractitioner", caption: "ASSIGNED PRACTITIONER" });
      return data;
    }
  };

  const navigateBack = () => {
    history.push({
      pathname: Routes.dashboard,
    });
  };

  const onChangeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const returnType = () => {
    return props?.location?.state === "Assign Practitioner"
      ? "assign"
      : props?.location?.state === "Re-Assign Practitioner"
      ? "reassign"
      : props?.location?.state === "Close Consultation"
      ? "close"
      : "";
  };

  const iconClick = async (data) => {
    let payload = {
      start: data?.appointmentDetails?.Appointment?.start ?? "",
      end: data?.appointmentDetails?.Appointment?.end,
      encounter_key: data?.appointmentDetails?.Encounter?._key ?? "",
      type: returnType(),
      appID: data?.appointmentDetails?.Appointment?._key ?? ""
    };
    if (props?.location?.state === "Assign Practitioner") {
      await dispatch(actions.ASSIGN_LIST(payload));
    } else if (props?.location?.state === "Re-Assign Practitioner") {
      await dispatch(actions.ASSIGN_LIST(payload));
    } else if (props?.location?.state === "Close Consultation") {
      await dispatch(actions.CLOSE_CONSULTATION(payload));
    }

    onChangeState("appDetails", payload);
    setModel(true);
  };

  const onModalClose = async () => {
    fetchInfo(props?.location?.state);
    onChangeState("appDetails", {});
    setModel(false);
  };

  return (
    <div className={classes.root}>
      <TopHeader
        parent_id={"appointment_listing"}
        noHome={true}
        middleComponent={<></>}
      />
      {loading &&  <div style={{ display: "flex", justifyContent: "center",alignItems:"center"}}>
          <CircularProgress />
        </div>}
      {!loading && 
      <div className={classes.content}>
        <div className={classes.rowCenter}>
          <div className={classes.iconWrapper} onClick={navigateBack}>
            <NavigateBeforeIcon />
          </div>
          <Typography variant="body1" className={classes.header}>
            {props?.location?.state}
          </Typography>
        </div>
        <div style={{ paddingTop: 12 }}>
          <WorkList
            report={state.report}
            iconClick={iconClick}
            columnToShow={columnToShow()}
          />
        </div>
        <Modal
          id={`assign_modal_popup`}
          open={modal}
          onClose={() => onModalClose(false)}
          width={1000}
          inLineStyles={{
            borderRadius: 16,
          }}
        >
          <AssignReassign
            parent_id={"worklist_modal"}
            title={
              returnType() === "assign"
                ? "Assign Practitioner"
                : returnType() === "reassign"
                ? "Re-Assign Practitioner"
                : "Close Consultation"
            }
            type={state?.appDetails?.type}
            encounter_id={state?.appDetails?.encounter_key}
            assignData={assignData?.data}
            practitionerType={datas?.assignPractitionerType?.data ?? []}
            practitionerRole={datas?.assignPractitionerRole?.data ?? []}
            dispositionData={datas?.dispositionType?.data ?? []}
            followupUOMData={datas?.followupUOM?.data ?? []}
            reasonData={datas?.reasonMaster?.data ?? []}
            onModalClose={() => onModalClose(false)}
            appointmentStart={state?.appDetails?.start}
            appointmentEnd={state?.appDetails?.end}
            appID={state?.appDetails?.appID}
            showPractitioner={returnType() === "close" ? true : false}
            Role={
              permission?.write?.indexOf("closeConsultationN") > -1
                ? 2
                : permission?.write?.indexOf("closeConsultationD") > -1
                ? 1
                : null
            }
          />
        </Modal>
      </div>}
    </div>
  );
};
