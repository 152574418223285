export const Header = [
  "S.No",
  "Service",
  "Service Description",
  "Order Ref",
  "Order Date & Time",
  "Ord Catg/Drug Class",
  "Qty",
  "UoM",
  "Rate",
  "Charge Amt",
  "Status",
  "Billed?",
  "All",
];

export const billingHeader = [
  "S.No",
  "Charge Code",
  "Service Description",
  "Order Ref",
  "Order Date & Time",
  "Qty",
  "UoM",
  "Rate",
];

export const calculationHeader = [
  "Gross Amt",
  "Disc Amt",
  "Net Amt",
  "Patient Share",
  "Credit Share",
  "Action",
];
