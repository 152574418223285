import React from 'react';
import { Div, H6, Text, Divider, Icons, Avatar } from 'qdm-component-library';
import { UIColor } from "../../utils";

const DetailsView = (val, props) => {
    const { parent_id } = props;
    return (
        <Div
            id={`${parent_id}_requsetordetails_View_parent_div`}
            style={{ padding: 10 }}>
            <Div
                id={`${parent_id}_requsetordetails_View_sub_div`}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Div
                    id={`${parent_id}_requsetordetails_View_title_div`}
                >
                    {val?.name && <H6
                        id={`${parent_id}_requsetordetails_View_${val?.name.replaceAll(" ", "_")}_h6`}
                        className="pc_semibold" style={styles.label}>{val?.name}</H6>}
                    {val?.pidNumber && <Text
                        id={`${parent_id}_requsetordetails_View_${val?.pidNumber}_text`}
                        className="className" style={styles.span}>{val?.pidNumber}</Text>}
                    {val?.role && <Text
                        id={`${parent_id}_requsetordetails_View_${val?.role.replaceAll(" ", "_")}_text`}
                        className="className" style={styles.span}>&nbsp;{'•'}&nbsp;{val?.role}</Text>}
                </Div>
                {
                    val?.profile_pic &&
                    <Avatar
                        id={`${parent_id}_requsetordetails_View_profile_avatar`}
                        variant="square"
                        letter="Avatar"
                        src={val?.profile_pic}
                        alt="Image"
                        style={styles.avatar}
                    />
                }
            </Div>
            <Divider
                id={`${parent_id}_requsetordetails_View_bottom_divider`}
                style={{ margin: '5px 0px' }} />
            {val?.hospital && <Text
                id={`${parent_id}_requsetordetails_View_${val?.hospital.replaceAll(" ","_")}_text`}
                className="className" style={styles.span}><Icons fontIcon="map-marker" style={{ marginRight: 5 }} />{val?.hospital}</Text>}
        </Div>
    )
}

const styles = {
    label: {
        fontSize: 14,
        color: UIColor.primaryText.color,
    },
    span: {
        fontSize: 12,
        color: UIColor.secondaryText.color,
    },
    divider: {
        margin: 0,
        height: 0.5
    },
    avatar: {
        width: 40,
        height: 40
    },
    collapse: {
        backgroundColor: '#EFEFEF',
        padding: 10,
        display: 'flex',
        alignItems: 'center',
    },
}

export const RequestorDetails = (props) => {

    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_requsetordetails_parent_div`}
        >
            <Div
                id={`${parent_id}_requsetordetails_title_div`}
                style={{ padding: 10 }}>
                <H6
                    id={`${parent_id}_requsetordetails_${props?.title.replaceAll(" ", "_")}_div`}
                    className="pc_semibold" style={styles.label}>{props?.title}</H6>
            </Div>
            <Divider style={styles.divider} />
            <DetailsView
                parent_id={'requestor_details'}
                name={props?.requestorDetails?.name}
                pidNumber={props?.requestorDetails?.pidNumber}
                role={props?.requestorDetails?.role}
                profile_pic={props?.requestorDetails?.profile_pic}
                hospital={props?.requestorDetails?.hospital}
            />
            {
                props?.performerDetails &&
                <div>
                    <Div
                        id={`${parent_id}_requsetordetails_Performer_details_div`}
                        style={styles.collapse}>
                        <Text
                            id={`${parent_id}_requsetordetails_Performer_details_text`}
                            className="pc_medium" style={styles.statusHis}>Performer details</Text>
                    </Div>
                    <DetailsView
                        parent_id={'requestor_details'}
                        name={props?.performerDetailsData?.name}
                        pidNumber={props?.performerDetailsData?.pidNumber}
                        role={props?.performerDetailsData?.role}
                        profile_pic={props?.performerDetailsData?.profile_pic}
                        hospital={props?.performerDetailsData?.hospital}
                    />
                </div>
            }
        </div>
    )
}

RequestorDetails.defaultProps = {
    title: 'Requestor details',
    performerDetails: false,
    performerDetailsData: {},
    requestorDetails: {}
}