import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import { Row, Text, Div, Button, SideMenu } from "qdm-component-library";
import {
  Stats, StatsCard, ProfileList, AppoinmentList, PendingTask, PatientStatus, PatientStatusCard, CurrentVsitStats, BillingStatus,
  FinancialClass, AverageWaitingTime, Schedule, PendingTaskCard, PatientWaitingTime,
} from "./components";
import { Queue, LoadingComp } from "../../components";
import { withRouter } from "react-router-dom";
import { Routes } from "../../router/routes";
import { Checkbox } from "@material-ui/core";
import { withAllContexts } from "../../HOCs";
import { checkWithCasbin } from "../../utils/permissonHandling";
import { getWidgetComponetList } from "./func";
import { UIColor } from "../../utils";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//import PatientStatus from "./components/pateintStatus";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
const makeid = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const CustomCheckbox = withStyles({
  root: {
    color: UIColor.secondaryButtonColor.color,
    '&$checked': {
      color: UIColor.secondaryButtonColor.color,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const originalLayouts = getFromLS("layouts") || {};

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}
class Layout extends React.PureComponent {
  static defaultProps = {
    className: "layout",
    cols: { lg: 8, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 30,
  };

  constructor(props) {
    super(props);

    this.state = {
      drawer: false,
      items: props.initialLayout,
      newCounter: props.newCounter,
      cols: { lg: 8, md: 10, sm: 6, xs: 4, xxs: 2 },
      widgetComponetList: null,
      componetsIndexList: {
        0: {
          widgetName: "stats",
        },
        1: {
          widgetName: "profileList",
        },
        2: {
          widgetName: "patientStatus",
        },
        3: {
          widgetName: "schedule",
        },
        4: {
          widgetName: "pendingTask",
        },
        5: {
          widgetName: "patientwaitingtime",
        },
        6: {
          widgetName: "currentVsitStats",
        },
        7: {
          widgetName: "averageWaitingTime",
        },
        8: {
          widgetName: "financialClass",
        },
        9: {
          widgetName: "billingStatus",
        },
      },
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      loading:true,
      isCashier:null
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.getWidget = this.getWidget.bind(this);
    this.registerPatient = this.registerPatient.bind(this);
  }

  async componentDidMount() {
    let dataList = await checkWithCasbin(["dashboard"])
    const isCashier = await dataList?.write?.indexOf("cashierIcon") > -1;
    let layouts = getWidgetComponetList(dataList)
    this.setState({
      widgetComponetList: layouts,
      Permission: dataList,
      loading:false,
      isCashier:isCashier
    })
  }

  shouldComponentUpdate(props, state) {
    if (JSON.stringify(state.items) !== JSON.stringify(props.initialLayout)) {
     
      this.setState({
        items: props.initialLayout,
        newCounter: state.newCounter + 1
      })
      this.forceUpdate()
    }
  }
  registerPatient = (
    personId,
    patientId,
    appointmentId,
    isPatient,
    displayData
  ) => {

    if (isPatient) {
      this.props.history.push({
        pathname: Routes.patientVisitDetails,
        state: {
          personId,
          from: 1,
          patientId,
          appointmentId,
          Ispatient: true,
          patientData: displayData?.[0]?.mobileNo,
          idedit: true
        },
      });
    } else {
      this.props.history.push({
        pathname: Routes.assemblePatioentRegister,
        state: {
          nextRoute: Routes.patientVisitDetails,
          personId,
          patientId,
          appointmentId,
          patientData: displayData?.[0]?.mobileNo,
          Ispatient: false,
          idedit: false
        },
      });
    }
  };

  previous = [
    {
      title: "Previous",
      token: "06",
      profile_pic:
        "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
      name: "Mahalakshmi",
      role: "Consultation",
    },
  ];
  attend = [
    {
      title: "To Attend",
      token: "07",
      profile_pic:
        "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
      name: "Baskar Radhakrishnan",
      role: "Appoinment Booking",
      primaryButtonName: "Book Appoinment",
      isRegister: "Unregistered",
      primaryButtonAction: (a) => this.registerPatient(a),
      secondaryButtonName: "Put on Hold",
      secondaryButtonAction: () => console.log("called"),
      secondaryButtonIcon: "images/icons8-pause-button.svg",
      isEmergency: true,
    },
  ];
  next = [
    {
      title: "Up Next",
      token: "08",
      profile_pic:
        "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
      name: "Ashwin Ravi",
      role: "Appoinment Booking",
      isEmergency: true,
    },
  ];

  getWidget = (type) => {
    // let widgetType = this.state.componetsIndexList?.[i]?.widgetName??null;
    switch (type) {
      case "queue":
        return (
          <div
            parent_id={this.props.parent_id + "prent_div"}
            style={{ padding: "20px 15px", backgroundColor: "#F9F9F9" }}
          >
            <Queue
              parent_id={this.props.parent_id + "layout"}
              previous={this.previous}
              attend={this.attend}
              next={this.next}
            />
          </div>
        );
      case "stats":
        return <Stats Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "statsCard":
        return <StatsCard Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "profileList":
        return <ProfileList Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "appoinmentList":
        return <AppoinmentList parent_id={this.props.parent_id} />;

      case "pendingTask":
        return <PendingTask Permission={this.state.Permission}
          parent_id={this.props.parent_id}
        />;

      case "patientStatus":
        return <PatientStatus Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "currentVsitStats":
        return <CurrentVsitStats Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "billingStatus":
        return <BillingStatus Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "financialClass":
        return <FinancialClass Permission={this.state.Permission} parent_id={this.props.parent_id} />;

      case "averageWaitingTime":
        return <AverageWaitingTime parent_id={this.props.parent_id} />;

      case "schedule":
        return <Schedule Permission={this.state.Permission} parent_id={"dashboard_layout"} />;

      case "pendingTaskCard":
        return <PendingTaskCard Permission={this.state.Permission} parent_id={"dashboard"} parent_id={""} />;

      case "patientStatusCard":
        return <PatientStatusCard Permission={this.state.Permission} parent_id={"dashboard"} parent_id={""} />;

      case "patientwaitingtime":
        return <PatientWaitingTime Permission={this.state.Permission} />;
      default:
        return null;
    }
  };

  createElement(el) {
    const i = el.i;
    let widgetType = this.state.componetsIndexList?.[i]?.widgetName ?? null;
    // console.log(widgetType,el)
    return (
      <div
        key={i}
        data-grid={el}
        style={{ overflow: "auto", borderRadius: "6px" }}
      >
        {this.getWidget(widgetType) ? (
          this.getWidget(widgetType)
        ) : (
          <span className="text">{i}</span>
        )}
        {!el.static && (
          <span
            className={"remove closeIcon"}
            onClick={this.onRemoveItem.bind(this, i)}
          >
            x
          </span>
        )}
      </div>
    );
  }

  onAddItem() {
    /*eslint no-console: 0*/
    console.log("adding", "n" + this.state.newCounter);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: "n" + this.state.newCounter,
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 2,
        h: 4,
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1,
    });
    this.forceUpdate()
  }

  addWidgets = (val) => {
    let type = Object.keys(this.state.componetsIndexList).filter(
      (valW) => this.state.componetsIndexList[valW].widgetName === val.name
    )?.["0"]
      ? "remove"
      : "add";
    if (type === "add") {
      if (val.position) {
        let id = makeid().toString();
        // eslint-disable-next-line
        this.state.componetsIndexList[id] = { widgetName: val.name };
        val.position.i = id;
        val.position.y = Infinity;
        val.position.x = 0;
        this.setState({
          // Add a new item. It must have a unique key!
          items: this.state.items.concat(val.position),
          // Increment the counter to ensure key is always unique.
          newCounter: this.state.newCounter + 1,
          // widget list json
          componetsIndexList: this.state.componetsIndexList,
        });
      } else {
        let id = makeid().toString();
        // eslint-disable-next-line
        this.state.componetsIndexList[id] = { widgetName: val.name };
        this.setState({
          // Add a new item. It must have a unique key!
          items: this.state.items.concat({
            i: id,
            x: 0,
            y: Infinity, // puts it at the bottom
            w: 2,
            h: 4,
          }),
          // Increment the counter to ensure key is always unique.
          newCounter: this.state.newCounter + 1,
          // widget list json
          componetsIndexList: this.state.componetsIndexList,
        });
      }
    } else {
      let index_i = Object.keys(this.state.componetsIndexList).filter(
        (valW) => this.state.componetsIndexList[valW].widgetName === val.name
      )?.[0];
      this.onRemoveItem(index_i);
    }
    this.forceUpdate()
  };

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols,
    });
    this.forceUpdate()
  }

  onLayoutChange = (layout) => {
    this.props.onLayoutChange(layout);
    this.setState({ layout: layout });
  };

  onRemoveItem(i) {
    console.log("removing", i);
    delete this.state.componetsIndexList[i];
    this.setState({
      items: _.reject(this.state.items, { i: i }),
      componetsIndexList: this.state.componetsIndexList,
    });
  }

  openDrawer = (val) => {
    this.setState({
      ...this.state,
      drawer: val,
    });
    this.forceUpdate()
  };

  render() {
    const { parent_id } = this.props;
    console.log("test", this.props.initialLayout)
    console.log("test", this.state.items)
    const role_name = localStorage.getItem("role_name") === "biller and cashier" ? true : false;
    // alert(JSON.stringify(this.props.location.state.roleName))
    return (
      <>
        <LoadingComp style={{alignItems: "center",height:"100vh"}} loading={!this.state.Permission}>
          <Row
            id={`${parent_id}_layout_parent_row`}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 22px",
              paddingTop: "2%",
            }}
          >
            <Text
              id={`${parent_id}_layout_label_dashboard`}
              key={"0"}
              name={role_name ? "Bill Cum Settlement":"Dashboard"}
              style={{
                fontSize: "16px",
                fontWeight: "normal",
              }}
              inLineStyles={{ fontFamily: "'pc_semibold' !important" }}
            ></Text>
            <Div id={`${parent_id}_layout_button_div`}>
              {/* <Button onClick={() => this.openDrawer(true)} variant={"text"} size={"small"} inLineStyles={{ fontWeight: 600, border: "1px solid #0071f2", color: "#0071f2", marginRight: "10px", fontSize: 12, padding: "4px 8px", borderRadius: "8px" }} >
            Add Widget +
            </Button>
          <Button variant={"text"} size={"small"} inLineStyles={{ fontWeight: 600, border: "1px solid #0071f2", color: "#0071f2", marginRight: "10px", fontSize: 12, padding: "4px 8px", borderRadius: "8px" }} >
            Layout
                <Icons
              key={'2'}
              fontIcon='angle-down'
              ariaHidden='true'
              size={"large"}
              style={{ color: '#0071f2', marginLeft: "5px" }}
            ></Icons>
          </Button> */}
            {!role_name && 
              <Button
                id={`${parent_id}_layout_add_widget_button`}
                onClick={() => this.openDrawer(true)}
                inLineStyles={{
                  fontSize: 13,
                  padding: "6px 12px",
                  backgroundColor: UIColor.secondaryColor.color,
                  borderColor: UIColor.secondaryColor.color,
                  borderRadius: "8px",
                  fontFamily: "'pc_medium' !important",
                }}
              >
                + Add Widget
              </Button>}
            </Div>
          </Row>

          <div
            id={`${parent_id}_layout_responsive_div`}
            style={{
              height: "calc(100vh - 131px)",
              overflowY: "auto",
              overflowX: "hidden",
              display: this.state.isCashier ? "flex" : "block",
              justifyContent:"center",
              alignItems:"center"
            }}
          >
            {/* <button onClick={this.onAddItem}>Add Item</button> */}
            {this.state.isCashier && 
              <div style={{padding:"0px 16px",backgroundColor:"#fff",borderRadius:"8px"}}>
                {/* <h1>HEllo</h1> */}
                <img src="images/Group 96503.svg" alt="billImage" />
              </div>
            }
            {this.props.initialLayout && this.state.items && (this.state.isCashier === false) && (
              <ResponsiveReactGridLayout
                {...this.props}
                onLayoutChange={this.onLayoutChange}
                onBreakpointChange={this.onBreakpointChange}
                cols={{ lg: 10, md: 10, sm: 10, xs: 10, xxs: 2 }}
              >
                {_.map(this.state.items, (el) => this.createElement(el))}
              </ResponsiveReactGridLayout>
            )}
          </div>
          {/* Drower */}
          <SideMenu
            id={`${parent_id}_layout_parent_sidemenu`}
            open={this.state.drawer}
            onClose={() => this.openDrawer(false)}
            direction={"right"}
            width={400}
            inLineStyles={{padding: 0}}
          >
            <Div
              id={`${parent_id}_layout_sidemenu_add_widget_div`}
              style={{
                padding: "10px 18px",
                background: "#fff",
                borderBottom: "1px solid #ddd",
              }}
            >
              <Text
                id={`${parent_id}_layout_sidemenu_add_widget_text`}
                name="Add Widget"
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",

                }}
                inLineStyles={{ fontFamily: "'pc_semibold' !important" }}
              />
            </Div>
            <Div
              id={`${parent_id}_layout_sidemenu_add_widget_sub_div`}
              style={{
                padding: 20,
                backgroundColor: UIColor.differentiationBackground.color,
                height: "calc(100vh - 46px)",
              }}
            >
              {this?.state?.widgetComponetList?.map((val, i) => {
                return (
                  <div
                    id={`${parent_id}${i}_layout_sidemenu_widgetlist_div`}
                    onClick={() => this.addWidgets(val)}
                    key={i}
                    style={{
                      cursor: "pointer",
                      borderRadius: 6,
                      overflow: "auto",
                      padding: "2px",
                      marginBottom: "20px",
                      background: "#fff",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      id={`${parent_id}${i}_layout_sidemenu_widgetlist_text`}
                      inLineStyles={{
                        padding: 14,
                        fontSize: 16,
                        textTransform: "capitalize",
                        fontWeight: 500,
                      }}
                    >
                      {val.label}
                    </Text>
                    {
                    // RadioButtonUncheckedIcon
                    // CheckCircleIcon
                    Object.keys(this.state.componetsIndexList).filter(
                      (valW, i) =>
                        this.state.componetsIndexList[valW].widgetName ===
                        val.name
                    )?.["0"] ? (
                      <FormControlLabel
                        control={<CustomCheckbox checked={true} icon={<CheckCircleIcon />} checkedIcon={<CheckCircleIcon checked={true} />} id={`${parent_id}${i}_layout_sidemenu_checkbox`} />}
                      />
                    ) : (
                      <FormControlLabel
                        control={<CustomCheckbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonUncheckedIcon />} id={`${parent_id}${i}_layout_sidemenu_checkbox`} />}
                      />
                    )
                    // <p style={styles.activeColorCheck} ></p>
                  }
                  {/* {this.getWidget(val.name)} */}
                  </div>
                );
              })}
            </Div>
          </SideMenu>
        </LoadingComp>
      </>
    );
  }
}

export default withRouter(withAllContexts(Layout));
