import React from 'react';
import { Avatar, Div, Text, H6 } from "qdm-component-library";
import { Table, TableContainer, TableHead, TableBody, TableCell, TableRow, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        boxShadow: '0px 10px 25px #0000000A',
        border: '1px solid #E0E0E0',
        borderRadius: 8,
    },
    table: {
        //   minWidth: 650,   
        '& thead tr': {
            backgroundColor: '#F0F0F0',
            '& th': {
                fontSize: '14px',
                color: '#646464',
                fontFamily: 'pc_regular',
                padding: 8
            }
        },
        '& tbody tr': {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#F9F9F9'
            },
            '& td': {
                padding: 8,
            },
            '&:last-child': {
                '& td': {
                    borderBottom: 0
                },
            }
        }
    },
});
function DataTable(props) {

    const classes = useStyles();

    const styles = {
        card: {
            height: "100%",
            padding: "20px 10px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        title: {
            marginBottom: "10px"
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        avatar: {
            width: 35,
            height: 35,
        },
        h6: {
            color: '#101010',
            fontSize: 14,
            margin: 0
        },
        span: {
            color: '#646464',
            fontSize: 12
        }
    }
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}+"appoinment"_table_parent_div`}
            style={{ height: "100%", width: "100%" }}>
            <TableContainer
                id={`${parent_id}+"appoinment"_table_parent_table_container`}
                className={classes.container}>
                <Table
                    id={`${parent_id}+"appoinment"_table_parent_table`}
                    className={classes.table}>
                    {
                        props?.tableHead && props?.tableHead?.length > 0 &&
                        <TableHead
                            id={`${parent_id}+"appoinment"_table_tablehead`}
                        >
                            <TableRow
                                id={`${parent_id}+"appoinment"_table_tablerow`}
                            >
                                {
                                    props?.tableHead?.map((l, i) => {
                                        return (
                                            <TableCell
                                                id={`${parent_id}+"appoinment"_${i}_table_tablecell`}
                                            >{l}</TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody
                        id={`${parent_id}+"appoinment"_table_tablebody`}
                    >
                        {
                            props?.tableData && props?.tableData?.length > 0 && props?.tableData?.map((m, j) => {
                                return (
                                    <TableRow
                                        id={`${parent_id}+"appoinment"_${j}_table_parent_tablerow`}
                                    >
                                        {
                                            props?.tableHeadData && props?.tableHeadData?.map((n, k) => {
                                                switch (n) {
                                                    case 'nameMrn':
                                                        return (
                                                            <TableCell
                                                                id={`${parent_id}+"appoinment"_${k}_table_head_tablecell`}
                                                                key={k}>
                                                                <Div
                                                                    id={`${parent_id}+"appoinment"_${k}_table_head_div`}
                                                                    style={styles.flexRow}>
                                                                    <Avatar
                                                                        id={`${parent_id}+"appoinment"_${k}_table_avatar`}
                                                                        variant="circle"
                                                                        letter="Avatar"
                                                                        src={m?.nameMrn?.profile_pic}
                                                                        alt="Image"
                                                                        style={styles.avatar}
                                                                    />
                                                                    <Div>
                                                                        <Div
                                                                            id={`${parent_id}+"appoinment"_${k}_table_sub_div`}
                                                                            style={styles.flexRow}>
                                                                            <H6
                                                                                id={`${parent_id}+"appoinment"_${k}_table_name_h6`}
                                                                                style={styles.h6} className="pc_medium">{m?.nameMrn?.name}</H6>
                                                                            <Text
                                                                                id={`${parent_id}+"appoinment"_${k}_table_age_text`}
                                                                                style={styles.span} className="pc_regular">&nbsp;{`${m?.nameMrn?.age} | ${m?.nameMrn?.gender}`}</Text>
                                                                        </Div>
                                                                        <Div
                                                                            id={`${parent_id}+"appoinment"_${k}_table_mobile_div`}
                                                                            style={styles.flexRow}>
                                                                            <Text
                                                                                id={`${parent_id}+"appoinment"_${k}_table_mobile_text`}
                                                                                style={styles.span} className="pc_regular">{`${m?.nameMrn?.mrn} • ${m?.nameMrn?.mobile}`}</Text>
                                                                        </Div>
                                                                    </Div>
                                                                </Div>
                                                            </TableCell>
                                                        )
                                                    case 'date':
                                                        return (
                                                            <TableCell
                                                                id={`${parent_id}+"appoinment"_${k}_table_date_tablecell`}
                                                                key={k}>
                                                                <Div
                                                                    id={`${parent_id}+"appoinment"_${k}_table_date_div`}
                                                                >
                                                                    <H6
                                                                        id={`${parent_id}+"appoinment"_${k}_table_date_h6`}
                                                                        style={styles.h6} className="pc_medium">{m?.date?.date}</H6>
                                                                    <Text
                                                                        id={`${parent_id}+"appoinment"_${k}_table_time_text`}
                                                                        style={styles.span} className="pc_regular">{m?.date?.time}</Text>
                                                                </Div>
                                                            </TableCell>
                                                        )
                                                    case 'type':
                                                        return (
                                                            <TableCell
                                                                id={`${parent_id}+"appoinment"_${k}_table_vist_tablecell`}
                                                                key={k}>
                                                                <H6
                                                                    id={`${parent_id}+"appoinment"_${k}_table_vist_h6`}
                                                                    style={styles.h6} className="pc_medium">{`${m?.type?.type} • ${m?.type?.vist}`}</H6>
                                                                <Text
                                                                    id={`${parent_id}+"appoinment"_${k}_table_encounterNo_text`}
                                                                    style={styles.span} className="pc_regular">{m?.type?.encounterNo}</Text>
                                                            </TableCell>
                                                        )
                                                    case 'doctorName':
                                                        return (
                                                            <TableCell
                                                                id={`${parent_id}+"appoinment"_${k}_table_name_table`}
                                                                key={k}>
                                                                <H6
                                                                    id={`${parent_id}+"appoinment"_${k}_table_name_h6`}
                                                                    style={styles.h6} className="pc_medium">{m?.doctorName?.name}</H6>
                                                                <Text
                                                                    id={`${parent_id}+"appoinment"_${k}_table_name_text`}
                                                                    style={styles.span} className="pc_regular">{`${m?.doctorName?.speciality} • ${m?.doctorName?.ward}`}</Text>
                                                            </TableCell>
                                                        )
                                                    case 'appStatus':
                                                        return (
                                                            <TableCell
                                                                id={`${parent_id}+"appoinment"_${k}_table_status_tablecell`}
                                                                key={k}>
                                                                <H6
                                                                    id={`${parent_id}+"appoinment"_${k}_table_status_h6`}
                                                                    style={styles.h6} className="pc_medium">{m?.appStatus?.status}</H6>
                                                                <Text
                                                                    id={`${parent_id}+"appoinment"_${k}_table_status_text`}
                                                                    style={styles.span} className="pc_regular">{m?.appStatus?.in}</Text>
                                                            </TableCell>
                                                        )
                                                    default:
                                                        return (
                                                            <TableCell
                                                                id={`${parent_id}+"appoinment"_${k}_table_tablecell`}
                                                                key={k}>
                                                                <Text
                                                                    id={`${parent_id}+"appoinment"_${k}_table_text`}
                                                                    tyle={styles.span} className="pc_regular">{''}</Text>
                                                            </TableCell>
                                                        )
                                                }
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

DataTable.defaultProps = {
    tableHead: [],
    tableHeadData: [],
    tableData: []
}

export default DataTable;