import React from "react";

export const ArrowLeftIcon = (props) => {
  let width = props.width ? props.width : "8";
  let height = props.height ? props.height : "14";
  let color = props.color ? props.color : "#000";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.558 16"
    >
      <path
        id="icons8-left-arrow"
        d="M9.966,12.986a1.042,1.042,0,0,0-.716.315L2.305,20.246a1.042,1.042,0,0,0,0,1.473L9.25,28.664a1.042,1.042,0,1,0,1.473-1.473L5.557,22.024h15.96a1.042,1.042,0,1,0,0-2.084H5.557l5.167-5.167a1.042,1.042,0,0,0-.757-1.788Z"
        transform="translate(22.558 28.986) rotate(180)"
        fill={color}
      />
    </svg>
  );
};
