import React from "react";
import noData from "../../../assets/img/noData.svg";
import { Text } from "qdm-component-library";
import { UIColor } from "../../../utils";

function EmptyCard(props) {
    const { parent_id } = props;

    return <div
        id={`${parent_id}_emptycard_parent_div`}
        style={{
            // marginTop: "10px",
            boxShadow: "none",
            border: "1px solid #F0F0F0",
            backgroundColor: "white",
            padding: "15px 17px 18px 15px",
            borderRadius: 8,
            minHeight: 133,
        }}
    >
        <div
            id={`${parent_id}_emptycard_parent_sub_div`}
            style={{ display: "flex" }}>
            <img
                id={`${parent_id}_emptycard_no_appointment_img`}
                alt={"No Appoinment data"} src={noData} style={{ marginRight: "20px" }} />
            <div
                id={`${parent_id}_emptycard_no_appointment_div`}
                style={{ marginTop: "14px" }}>
                <Text
                    id={`${parent_id}_emptycard_no_appointment_text`}
                    className="pc_medium"
                    key={"0"}
                    name="No appointment"
                    style={{
                        color: UIColor.secondaryText.color,
                        fontSize: "14px",
                        letterSpacing:0
                    }}
                ></Text>
                <div
                    id={`${parent_id}_emptycard_fit_and_healthy_div`}
                >
                    <span
                        id={`${parent_id}_emptycard_fit_and_healthy_span`}
                        className="pc_medium" style={{ color: UIColor.secondaryText.color, fontSize: "12px",letterSpacing:0 }}>
                        Hope,your are fit and healthy
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export default EmptyCard;