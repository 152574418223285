import { Routes } from "../router/routes";

const patientRegisterTitle = "Register Patient";

const patientRegister = [
  {
    id: 1,
    link: Routes.assemblePatioentRegister,
    label: "Patient Details",
  },
  {
    id: 2,
    link: Routes.assembleNextOfKin,
    label: "Next of Kin Details",
  },
  {
    id: 3,
    link: Routes.assemblePatioentRegister,
    label: "Additional Details",
  },
];

const patientVisitTitle = "Register Visit";
const patientModifyTitle = "Revise Visit";
const patientCancelTitle = "Cancel Visit";
const patientReferralTitle = "Referral Registration";

const patientVisit = [
  {
    id: 1,
    link: Routes.patientVisitDetails,
    label: "Visit Details",
  },
];

const patientReferral = [
  {
    id: 2,
    link: Routes.patientReferral,
    label: "Referral",
  },
];

const financialTitle = "Financial Registration";

const collectionTitle = "Collection";

export {
  patientRegister,
  patientVisit,
  patientRegisterTitle,
  patientVisitTitle,
  patientModifyTitle,
  patientCancelTitle,
  financialTitle,
  collectionTitle,
  patientReferralTitle,
  patientReferral,
};
