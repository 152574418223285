import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./sidemenu1.json";
import { withRouter } from "react-router-dom";

import {
  Container,
  H6,
  Divider,
  Row,
  Col,
  Div,
  Text,
  DateTimepicker,
  Dropdown,
  Icons,
  TapSelect,
  Button,
} from "qdm-component-library";

class BookMultipleAppointmentsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { parent_id } = this.props;
    return (
      <div
        id={`${parent_id}_sidemenu1_parent_div`}
      >
        <Container
          id={`${parent_id}_sidemenu1_parent_container`}
          key={"0"}
          name={"container"}
          fluid={true}
          style={qdmstyles.thEz}
        >
          <H6
            id={`${parent_id}_sidemenu1_Book_Multiple_h6`}
            key={"0"}
            name={"Book Multiple Appointments"}
            style={qdmstyles.rgo}
          ></H6>

          <Divider
            id={`${parent_id}_sidemenu1_bottom_divider`}
            key={"1"}></Divider>

          <Row
            id={`${parent_id}_sidemenu1_parent_row`}
            key={"2"}
            alignItems={"stretch"}
            direction={"row"}
            justify={"flex-start"}
            spacing={"2"}
            style={qdmstyles.iCwhZ}
          >
            <Col
              id={`${parent_id}_sidemenu1_parent_col`}
              key={"0"}
              name={"qdm-col-6"}
              alignItems={"stretch"}
              direction={"row"}
              justify={"flex-start"}
              textAlign={"left"}
              lg={"6"}
              xs={"6"}
              md={"6"}
              sm={"6"}
              inLineStyles={{
                display: "flex",
                alignItems: "center",
                paddingRight: 0,
              }}
            >
              <Text
                id={`${parent_id}_sidemenu1_start_text`}
                key={"0"} name={"Starts on"} style={qdmstyles.AhyN}></Text>

              <DateTimepicker
                id={`${parent_id}_sidemenu1_datetimepicker`}
                key={"1"}
                type={"date"}
                placeholder={"please select your date"}
                style={qdmstyles.gXU}
              ></DateTimepicker>
            </Col>

            <Col
              id={`${parent_id}_sidemenu1_dropdown_col`}
              key={"0"}
              className={"qdm"}
              name={"qdm-col-6"}
              lg={"6"}
              xs={"6"}
              md={"6"}
              sm={"6"}
              style={qdmstyles.wqL}
            >
              <Dropdown
                id={`${parent_id}_sidemenu1_dropdown`}
                key={"0"}
                elevation={"1"}
                fullWidth={true}
                dropDownTextSize={"14"}
                dropDownTextColor={"#000"}
                activeTextColor={"#000"}
                dropdownListMargin={"0"}
                dropdownListPadding={"8"}
                options={[
                  { label: "Option", value: "url" },
                  { label: "Option 1", value: "url" },
                ]}
                style={qdmstyles.ASH}
              ></Dropdown>

              <Div
                id={`${parent_id}_sidemenu1_occurrence_div`}
                key={"1"} className={"qdm"} style={qdmstyles.Daj}>
                <Text
                  id={`${parent_id}_sidemenu1_occurrence_text`}
                  key={"0"}
                  name={"05"}
                  style={qdmstyles.bfbce_d_a_bb_ddb}
                ></Text>

                <Text
                  id={`${parent_id}_sidemenu1_occurrence_text`}
                  key={"1"}
                  name={"occurrence"}
                  style={qdmstyles.YhEqT}
                ></Text>
              </Div>
            </Col>
          </Row>

          <Row
            id={`${parent_id}_sidemenu1_every_row`}
            key={"0"}
            alignItems={"stretch"}
            direction={"row"}
            justify={"flex-start"}
            spacing={"2"}
            style={qdmstyles.HzqA}
          >
            <Col
              id={`${parent_id}_sidemenu1_every_col`}
              key={"0"}
              name={"qdm-col-1"}
              alignItems={"stretch"}
              direction={"row"}
              justify={"flex-start"}
              textAlign={"left"}
              lg={"1"}
              xs={"1"}
              md={"1"}
              sm={"1"}
            >
              <Text
                id={`${parent_id}_sidemenu1_every_text`}
                key={"0"}
                name={"Every"}
                style={qdmstyles.cbde_ebc_f_ac_adeafb}
              ></Text>
            </Col>

            <Col
              id={`${parent_id}_sidemenu1_sub_col`}
              key={"1"}
              name={"qdm-col-1"}
              alignItems={"stretch"}
              direction={"row"}
              justify={"flex-start"}
              textAlign={"left"}
              lg={"1"}
              xs={"1"}
              md={"1"}
              sm={"1"}
            >
              <Text
                id={`${parent_id}_sidemenu1_sub_text`}
                key={"0"} name={"02"} style={qdmstyles.dfd__b_a_feb}></Text>
            </Col>

            <Col
              id={`${parent_id}_sidemenu1_dropdown_col`}
              key={"2"}
              name={"qdm-col-12"}
              alignItems={"stretch"}
              direction={"row"}
              justify={"flex-start"}
              textAlign={"left"}
              lg={"3"}
              xs={"12"}
              md={"12"}
              sm={"12"}
            >
              <Dropdown
                id={`${parent_id}_sidemenu1_option_dropdown`}
                key={"0"}
                elevation={"1"}
                fullWidth={true}
                dropDownTextSize={"14"}
                dropDownTextColor={"#000"}
                activeTextColor={"#000"}
                dropdownListMargin={"0"}
                dropdownListPadding={"8"}
                options={[
                  { label: "Option", value: "url" },
                  { label: "Option 1", value: "url" },
                ]}
                style={qdmstyles.aJ}
              ></Dropdown>
            </Col>

            <Col
              id={`${parent_id}_sidemenu1_calendar_col`}
              key={"3"}
              name={"qdm-col-12"}
              alignItems={"stretch"}
              direction={"row"}
              justify={"flex-start"}
              textAlign={"left"}
              lg={"3"}
              xs={"12"}
              md={"12"}
              sm={"12"}
              style={qdmstyles.BzQh}
            >
              <Div
                id={`${parent_id}_sidemenu1_calendar_div`}
                key={"0"} className={"qdm"} style={qdmstyles.SxMul}>
                <Icons
                  id={`${parent_id}_sidemenu1_calendar_icons`}
                  key={"0"}
                  fontIcon={"calendar"}
                  ariaHidden={"true"}
                  size={"medium"}
                ></Icons>
              </Div>
            </Col>
          </Row>

          <Div
            id={`${parent_id}_sidemenu1_PREFERENCE_div`}
            key={"4"} className={"qdm"} style={qdmstyles.sQC}>
            <Div
              id={`${parent_id}_sidemenu1_PREFERENCE_sub_div`}
              key={"0"} className={"qdm"} style={qdmstyles.aaf_c__aba_bdc}>
              <Div
                id={`${parent_id}_sidemenu1_PREFERENCE_label_div`}
                key={"0"}>
                <Text
                  id={`${parent_id}_sidemenu1_PREFERENCE_text`}
                  key={"0"}
                  name={"PREFERENCE"}
                  style={qdmstyles.PTki}
                ></Text>
              </Div>

              <Div
                id={`${parent_id}_sidemenu1_CLEAR_PREFERENCE_div`}
                key={"1"}>
                <Text
                  id={`${parent_id}_sidemenu1_CLEAR_PREFERENCE_text`}
                  key={"0"}
                  name={"CLEAR PREFERENCE"}
                  style={qdmstyles.yJsEe}
                ></Text>
              </Div>
            </Div>

            <div
              id={`${parent_id}_sidemenu1_calendar_div`}
              style={qdmstyles.RBbw}>
              <Text
                id={`${parent_id}_sidemenu1_PREFERRED_DAYS_text`}
                key={"1"} name={"CHOOSE YOUR PREFERRED DAYS"}></Text>
              <TapSelect
                id={`${parent_id}_sidemenu1_DAYcalendar_tapselect`}
                label={""}
                key={"2"}
                type={"single"}
                fullWidth={false}
                selectedBorderColor={"#1890ff"}
                selectedBackgroundColor={"#fff"}
                selectedTextColor={"#1890ff"}
                textSize={"small"}
                iconHeight={"20"}
                iconWidth={"20"}
                textColor={"#000"}
                options={[
                  { value: "MON", label: "MON" },
                  { value: "TUE", label: "TUE" },
                  { value: "WED", label: "WED" },
                  { value: "Thu", label: "Thu" },
                  { value: "Fri", label: "Fri" },
                  { value: "Sat", label: "Sat" },
                  { value: "Sun", label: "Sun" },
                ]}
                iconPosition={"left"}
                style={qdmstyles.ApObc}
              ></TapSelect>
            </div>

            <div
              id={`${parent_id}_sidemenu1_WEEKS_div`}
              style={qdmstyles.RBbw}>
              <Text
                id={`${parent_id}_sidemenu1_WEEKS_text`}
                key={"3"} name={"CHOOSE YOUR PREFERRED WEEKS"}></Text>

              <TapSelect
                id={`${parent_id}_sidemenu1_WEEKScalendar_text`}
                label={""}
                key={"4"}
                type={"single"}
                fullWidth={false}
                selectedBorderColor={"#1890ff"}
                selectedBackgroundColor={"#fff"}
                selectedTextColor={"#1890ff"}
                textSize={"small"}
                iconHeight={"20"}
                iconWidth={"20"}
                textColor={"#000"}
                options={[
                  { value: "WEEK 1", label: "WEEK 1" },
                  { value: "WEEK 2", label: "WEEK 2" },
                  { value: "WEEK 3", label: "WEEK 3" },
                  { value: "WEEK 4", label: "WEEK 4" },
                  { value: "WEEK 5", label: "WEEK 5" },
                ]}
                iconPosition={"left"}
                style={qdmstyles.rBZmA}
              ></TapSelect>
            </div>
          </Div>

          <H6
            id={`${parent_id}_sidemenu1_Every_2_days_h6`}
            key={"5"}
            name={
              "Every 2 days, starting on 26th Apr '21 and ends after 5 occurrences."
            }
            style={qdmstyles.b_f_ce_abd_ce}
          ></H6>

          <Button
            id={`${parent_id}_sidemenu1_Preview_Schedules_button`}
            key={"6"}
            type={"button"}
            variant={"outlined"}
            name={"Preview Schedules"}
            style={qdmstyles.fecdc_cc_f_f_fedaed}
          ></Button>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(withRouter(BookMultipleAppointmentsDrawer));
