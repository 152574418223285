import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import {
  Div,
  H6,
  Image,
  Divider,
  Text
} from "qdm-component-library";

class CollectionSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  styles={
    rowFlex:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    columnFlex:{
        display:'flex',
        flexDirection:'column'
    },
    h6:{
        fontSize:14,
        color:'#000000',
        margin:0
    },
    span:{
      fontSize:12,
      color:'#6F6F6F',
      margin:0
    },
    divider:{
        margin:'5px 0px',
        border:'0.5px dashed #EEEEEE',
        borderRadius:10
    },
    empty: {
        margin: 0,
        color: '#B6B6B6',
        fontSize: 14,
        textAlign:'center',
    },
    emptyDiv:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        justifyContent:'center',
        height:'100%',
    }
}

  componentDidMount() {}



  render() {
    return (   
        <Div style={{...this.styles.columnFlex,height:'100%'}}>
            <Div style={{display:'flex',alignItems:'center',padding:10}}>
                <Image src="images/collectionSummary.svg" style={{marginRight:10,width:16,height:16}} alt="cashHand" />
                <H6 className="pc_semibold" style={this.styles.h6}>Collection Summary</H6>
            </Div>
            <Div style={{...this.styles.rowFlex,backgroundColor:'#E2EFFE',padding:'5px 10px'}}>
                <Text className="pc_semibold" style={this.styles.span}>Amount Due</Text>
                <Text className="pc_semibold" style={this.styles.span}>488.00</Text>
            </Div>
            {this.props.selected === null &&
                <Div style={this.styles.emptyDiv}>
                    <Image style={{width:90,height:71,marginBottom:15}} src="images/icons8-empty-box.svg" alt="empty"/>
                    <H6 className="pc_regular" style={this.styles.empty}>You haven't added any <br /> payment yet!</H6>
                </Div>
            }
            {this.props.selected &&
            <Div style={{...this.styles.columnFlex,padding:'5px 10px',marginTop:5,flex:1}}>
                <Div style={{flex:1}}> 
                    <Div style={this.styles.rowFlex}>
                        <Text className="pc_medium" style={this.styles.span}>Cash</Text>
                        <Text className="pc_medium" style={this.styles.span}>371.00</Text>
                    </Div>
                    <Divider style={this.styles.divider}/>
                    <Div style={this.styles.rowFlex}>
                        <Text className="pc_medium" style={this.styles.span}>Card</Text>
                        <Text className="pc_medium" style={this.styles.span}>80.00</Text>
                    </Div>
                    <Divider style={this.styles.divider}/>
                    <Div style={this.styles.rowFlex}>
                        <Text className="pc_medium" style={this.styles.span}>Cheque</Text>
                        <Text className="pc_medium" style={this.styles.span}>100.00</Text>
                    </Div>
                </Div>
                <Div style={{marginTop:20}}>
                    <Divider style={this.styles.divider}/>
                    <Div style={this.styles.rowFlex}>
                        <Text className="pc_semibold" style={{...this.styles.span,color:'#54CA2E'}}>Total Paid</Text>
                        <Text className="pc_semibold" style={{...this.styles.span,color:'#54CA2E'}}>450.00</Text>
                    </Div>
                    <Divider style={this.styles.divider}/>
                    <Div style={this.styles.rowFlex}>
                        <Text className="pc_semibold" style={{...this.styles.span,color:'#FE5855'}}>Balance Due</Text>
                        <Text className="pc_semibold" style={{...this.styles.span,color:'#FE5855'}}>80.00</Text>
                    </Div>
                </Div>
            </Div>
            }
        </Div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(CollectionSummary);
