import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import {
  Div,
  Row,
  Col,
  H6,
  TextInput,
  Select
} from "qdm-component-library";


class CashPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        currency:'',
        amountReceived:'',
        rate:'',
        total:''
    };
  }

  styles={
      h6:{
          fontSize:16,
          color:'#000000',
          margin:0
      },
      labelStyle:{
        color:'#6F6F6F',
        fontSize:'12px',
        marginBottom:'6px',
        fontFamily:`pc_regular !important`
    },
    selectBorderStyle:{
        borderRadius:8,
        borderColor:'#E0E0E0'
    },
    inputBorderStyle:{
        borderRadius:8,
        border:'1px solid #E0E0E0',
    },
  }

  componentDidMount() {}

   changeState = (key,val) =>{
      this.setState({
          ...this.state,[key]:val
      })
  }


  render() {

    const { labelStyle,inputBorderStyle,selectBorderStyle,h6 } = this.styles;

    return (   
        <Div style={{flex:1}}>
            <H6 className="pc_semibold" style={h6}>Cash Payment</H6>
            <Row>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <Select label="Currency" placeholder="select..." showArrow  labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor="#0071F2" />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                     <TextInput 
                        label="Amount Received"
                        placeholder=""
                        value={this.state.amountReceived}
                        onChange={evt =>this.changeState('amountReceived',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                     <TextInput 
                        label="Rate"
                        placeholder=""
                        value={this.state.rate}
                        onChange={evt =>this.changeState('rate',evt.target.value)}
                        labelStyle={labelStyle}
                        style={{...inputBorderStyle,background:'#F0F0F0'}}
                        hoverColor="#0071F2"
                        disabled
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Total"
                        placeholder=""
                        value={this.state.total}
                        onChange={evt =>this.changeState('total',evt.target.value)}
                        labelStyle={labelStyle}
                        style={{...inputBorderStyle,background:'#F0F0F0'}}
                        hoverColor="#0071F2"
                        disabled
                        />
                </Col>
            </Row>
        </Div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(CashPayment);
