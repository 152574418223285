import React from 'react';
import { Div, Divider } from 'qdm-component-library';
import { convertTime, UIColor } from "../../utils";

export const AppointmentTable = ({
    tableHead = [],
    tableData = [],
    parent_id
}) => {

    const styles = {
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        tableWrapper: {
            border: '1px solid #E0E0E0',
            borderRadius: 8,
        },
        tableHeadCell: {
            color: UIColor.tertiaryText.color,
            textTransform: 'uppercase',
            flex: 1,
            fontSize: 12,
            padding: 7,
            fontWeight: 600,
        },
        tableRowCell: {
            display: 'flex',
            alignItems: 'center',
            padding: '0px 7px'
        },
        rowData: {
            fontSize: 12,
            color: '#101010',
            flex: 1,
            padding: 7
        },
        divider: {
            margin: 0
        }
    }

    const {
        tableWrapper,
        flexRow,
        tableHeadCell,
        tableRowCell,
        rowData,
        divider
    } = styles;
    return (
        <Div
            id={`${parent_id}_appointmenttable_parent_div`}
            style={tableWrapper}>
            {
                tableHead && tableHead?.length > 0 &&
                <Div
                    id={`${parent_id}_appointmenttable_div`}
                >
                    <Div
                        id={`${parent_id}_appointmenttable_sub_div`}
                        style={{ ...flexRow, padding: '0px 7px' }}>
                        {
                            tableHead?.map((_, i) => {
                                return <Div
                                    id={`${parent_id}_${i}_appointmenttable_tablecell_div`}
                                    style={{ ...tableHeadCell, flex: i === 0 ? 0.5 : 1 }}>{_}</Div>
                            })
                        }
                    </Div>
                    <Divider
                        id={`${parent_id}_appointmenttable_divider`}
                        style={divider} />
                </Div>
            }
            {
                tableData && tableData?.length > 0 &&
                <Div
                    id={`${parent_id}_appointmenttable_datetime_div`}
                >
                    {
                        tableData?.map((_, i) => {
                            return (
                                <Div
                                    id={`${parent_id}_${i}_appointmenttable_convert_div`}
                                >
                                    <Div
                                        id={`${parent_id}_${i}_appointmenttable_tablerow_div`}
                                        style={tableRowCell}>
                                        <Div
                                            id={`${parent_id}_${i}_appointmenttable_number_div`}
                                            style={{ ...rowData, flex: 0.5 }}>{i + 1}</Div>
                                        <Div
                                            id={`${parent_id}_${i}_appointmenttable_rowdata_div`}
                                            style={rowData}>{_?.appointmentNumber}</Div>
                                        <Div
                                            id={`${parent_id}_${i}_appointmenttable_rowdata_time_div`}
                                            style={rowData}>{convertTime(_?.dateTime, "unix", "DD MMM YYYY | hh:mm a")}</Div>
                                    </Div>
                                    {i < tableData?.length - 1 && <Divider
                                        id={`${parent_id}_${i}_appointmenttable_divider`}
                                        style={divider} />}
                                </Div>
                            )
                        })
                    }
                </Div>
            }
        </Div>
    )
}
