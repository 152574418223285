/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/12/202
 * @modify 12/12/202
 * @desc Providing the consultant from /src/context which is used in /src/App.js
 */

import React from "react";

import {
  LabOrderContext,
  CheifComplaintsContext,
  VitalsContext,
  AllergyContext,
  DiagnosisContext,
  MedicationContext,
  TreatmentContext,
  RadiologyContext,
  DoccumentContext,
} from "./";
const PatientDetailsContext = (props) => {
  let [CheifComplaints, setCheifComplaints] = React.useState({
    list: [],
    editIndex: null,
  });

  let [diagnosis, setDiagnosis] = React.useState({
    list: [],
    editIndex: null,
  });
  let [Allergys, setAllergys] = React.useState({
    list: [],
    editIndex: null,
  });
  let [vitals, setVitalsContext] = React.useState({
    list: [],
    editIndex: null,
  });
  let [medication, setMedication] = React.useState({
    list: [],
    editIndex: null,
  });
  let [treatment, setTreatment] = React.useState({
    list: [],
    child: {},
    editIndex: null,
  });
  let [LabOrder, setLabOrder] = React.useState({
    list: [],
    child: {},
    editIndex: null,
  });
  let [radiology, setRadiology] = React.useState({
    list: [],
    editIndex: null,
  });

  let [Doccument, setDoccument] = React.useState({
    list: [],
    child: {},
    editIndex: null,
  });
  return (
    <AllergyContext.Provider value={{ ...Allergys, setAllergys }}>
      <DiagnosisContext.Provider value={{ ...diagnosis, setDiagnosis }}>
        <CheifComplaintsContext.Provider
          value={{ ...CheifComplaints, setCheifComplaints }}
        >
          <MedicationContext.Provider value={{ ...medication, setMedication }}>
            <VitalsContext.Provider value={{ ...vitals, setVitalsContext }}>
              <TreatmentContext.Provider value={{ ...treatment, setTreatment }}>
                <LabOrderContext.Provider value={{ ...LabOrder, setLabOrder }}>
                  <RadiologyContext.Provider
                    value={{ ...radiology, setRadiology }}
                  >
                    <DoccumentContext.Provider
                      value={{ Doccument, setDoccument }}
                    >
                      {props.children}
                    </DoccumentContext.Provider>
                  </RadiologyContext.Provider>
                </LabOrderContext.Provider>
              </TreatmentContext.Provider>
            </VitalsContext.Provider>
          </MedicationContext.Provider>
        </CheifComplaintsContext.Provider>
      </DiagnosisContext.Provider>
    </AllergyContext.Provider>
  );
};

export default PatientDetailsContext;
