import React, { useState, useLayoutEffect } from "react";
import { Div } from "qdm-component-library";
// import { Document, Page } from "react-pdf";
import { createFile,getImgUrl } from "../../utils";
import attachment from "../../assets/img/attachment.svg";
import { withRouter } from "react-router-dom";
import moment from "moment";
const ListDiv = (props) => {
  const [openFile, setopenFile] = useState({
    datauri: null,
    datatype: null,
  });
  useLayoutEffect(() => {
    var filedata = {
      datauri: null,
      datatype: null,
    };
    setopenFile(filedata);
    async function funcall() {
      let data = await createFile(
        getImgUrl(props.data.fileid),
        props.data.filetype,
        props.data.fileName
      );
      var filedata = {
        datauri: data,
        datatype: props.data.filetype.split("/")[1],
      };
      setopenFile(filedata);
    }
    funcall();
  }, [props.data]);
  return (
    <Div
      style={{
        padding: "12px",
        border:
          props.data.fileid === props.active[0].fileid
            ? "1px solid #0071F2"
            : "1px solid #dcdcdc",
        marginBottom: "6%",
        borderRadius: "8px",
        height: "auto",
        fontSize: 12,
        color: "#101010",
        fontWeight: 500,
        cursor: "pointer",
        display: "flex",
      }}
      className={"pc_medium"}
      onClick={() => props.changeUi(props.data)}
      id={13}
    >
      <img src={attachment} alt={"attachment"} />
      <Div style={{ display: "inline", marginLeft: "6%" }}>
        <p id={14}>{props.data.fileName}</p>

        <p id={15} style={{ color: "#B6B6B6", fontSize: 10 }}>
        {moment.unix(props?.location?.state?.data?.createddate).format("DD-MM-YYYY")}
        </p>
      </Div>
      {/* {props.data.filetype === "application/pdf" ? (
        <Div>
          <Document
            file={
              "https://cors-anywhere.herokuapp.com/http://africau.edu/images/default/sample.pdf"
            }
          >
            <Page pageNumber={1} />
          </Document>
          {props.data.fileName}
        </Div>
      ) : props.data.filetype === "application/jpg" ? (
        <Div>
          <img
            src={"http://164.52.210.54:5000/primarycare/" + props.data.fileid}
          />
          {props.data.fileName}
        </Div>
      ) : (
        props.data.fileName
      )} */}
    </Div>
  );
};
export default withRouter(ListDiv);
