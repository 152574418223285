import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withAllContexts } from "../../HOCs";
import { Div, Card, Divider, H6 } from "qdm-component-library";
import { Header, Body } from "./components";
import { getUserInfo } from "../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";

class Allergies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      statusCheck: "All",
      loading: true,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    if (this.props.isCC) {
      this.getAllMasters();
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async getAllMasters() {
    const info = getUserInfo();
    this.props.SEARCH_PRACTIONER({ email: info.email });
    this.props.READ_PERSON({ phone: info.mobile });
    await this.props.RELATIONSHIP_MASTERS();
    await this.props.SYMPTOMS_MASTERS();
    await this.props.SEVERITY_MASTERS();
    await this.props.BODYSITE_MASTERS();
    await this.props.VERIFICATIONS_MASTERS();
    await this.props.CLINIC_STATUS_MASTERS();
    await this.props.CC_MASTERS();
    const { patientId, pId } = this.props?.location?.state;

    let data = await this.props.PATIENTID_BASED_CC({ patientId: pId });
    let cheifComplaints = this.props.cheifComplaints;
    cheifComplaints.setCheifComplaints({
      ...cheifComplaints,
      list: JSON.parse(JSON.stringify(data.payload.data)),
    });
    //Diagnosis integration
    // await this.props.DIAGNOSIS_MASTERS();
    await this.props.USE_MASTERS();
    await this.props.VERIFICATIONS_MASTERS_DIAGNOSIS();
    await this.props.DIAGNOSIS_RANK_MASTERS();
    await this.props.CLINICAL_MASTERS();
    await this.props.MANIFESTATION_MASTERS();
    await this.props.GRADE_TYPE_MASTERS();
    await this.props.ASSESMENT_MASTERS();
    await this.props.GRADE_CODE_MASTERS();

    let Diagnosisdata = await this.props.DIAGNOSIS_PATIENT_READ({
      patient_id: pId,
    });
    let diagnosis = this.props.Diagnosis;
    diagnosis.setDiagnosis({
      ...diagnosis,
      list: JSON.parse(JSON.stringify(Diagnosisdata.payload.data)),
    });

    this.setState({
      loading: false,
    });
  }

  shouldComponentUpdate(props, state) {
    return true;
  }

  styles = {
    card: {
      boxShadow: "none",
    },
    divider: {
      margin: 0,
      height: "0.1px",
    },
  };

  editList = (data, index) => {
    // let cheifComplaints = this.props.cheifComplaints
    // cheifComplaints.editIndex = index;
    // cheifComplaints.setCheifComplaints({
    //   ...cheifComplaints
    // })

    this.props.editWidget(data, this.props.componentId, index);
  };

  handleStatusActive = (e) => {
    this.setState({
      statusCheck: "Active",
    });
  };

  handleStatusInActive = (e) => {
    this.setState({
      statusCheck: "Inactive",
    });
  };

  handleStatusResolved = (e) => {
    this.setState({
      statusCheck: "Resolved",
    });
  };

  handleStatusAll = (e) => {
    this.setState({
      statusCheck: "All",
    });
  };

  render() {
    const { styles } = this;
    const {
      title = "",
      data = [],
      icon = "",
      iconBackground = "",
      parent_id,
    } = this.props;
    const { statusCheck, loading } = this.state;
    const filteredData = this?.props?.data?.filter(
      (item) => item.status === this.state.statusCheck
    );
    // console.log("filteredData", filteredData?.length);
    // console.log("data recived", this.props.data);

    return (
      <div id={`${parent_id}_parent_div`}>
        <Card id={`${parent_id}_parent_card`} style={styles.card}>
          <Header
            parent_id={"allergies"}
            icon={icon}
            iconBackground={iconBackground}
            title={title}
            open={this.state.open}
            toggle={() => this.changeState("open", !this.state.open)}
            handleStatusActive={this.handleStatusActive}
            handleStatusInActive={this.handleStatusInActive}
            handleStatusResolved={this.handleStatusResolved}
            handleStatusAll={this.handleStatusAll}
          />
          <Divider id={`${parent_id}_bottom_divider`} style={styles.divider} />
          {loading && (
            <Div
              id={`${parent_id}_CircularProgress_div`}
              style={{ display: "flex", height: "100px" }}
            >
              <CircularProgress
                id={`${parent_id}_CircularProgress`}
                style={{ margin: "auto" }}
                size={23}
              />
            </Div>
          )}
          {!loading && (
            <Div id={`${parent_id}_filter_parent_div`} style={{ padding: 10 }}>
              {filteredData && filteredData?.length < 1
                ? data &&
                  data?.length > 0 &&
                  data
                    .filter(
                      (val) =>
                        statusCheck === "All" || val.new_status === statusCheck
                    )
                    .map((l, i) => {
                      return (
                        <Body
                          parent_id={"allergies" + i}
                          editList={this.editList}
                          index={i}
                          id={l?.id}
                          bulletColor={l?.bulletColor}
                          hoverColor={l?.hoverColor}
                          value={l?.value}
                          isNew={l?.isNew}
                          isModified={l?.isModified}
                          showDivider={i < data?.length - 1 ? true : false}
                          subData={l?.subData}
                          showSubData={this.state.open}
                          status={l?.status}
                          handledStatus={this.state.statusCheck}
                          old_status={l?.old_status}
                          new_status={l?.new_status}
                        />
                      );
                    })
                : filteredData &&
                  filteredData?.length > 0 &&
                  filteredData.map((l, i) => {
                    return (
                      <Body
                        parent_id={"allergies" + i}
                        editList={this.editList}
                        id={l?.id}
                        index={i}
                        bulletColor={l?.bulletColor}
                        hoverColor={l?.hoverColor}
                        value={l?.value}
                        isNew={l?.isNew}
                        isModified={l?.isModified}
                        showDivider={
                          i < filteredData?.length - 1 ? true : false
                        }
                        subData={l?.subData}
                        showSubData={this.state.open}
                        status={l?.status}
                        handledStatus={this.state.statusCheck}
                        old_status={l?.old_status}
                        new_status={l?.new_status}
                      />
                    );
                  })}
              {(data?.filter(
                (val) => statusCheck === "All" || val.new_status === statusCheck
              )?.length === 0 ||
                data?.length === 0) && (
                <H6
                  id={`${parent_id}_No_data_h6`}
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  No data Found
                </H6>
              )}
            </Div>
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  searchPractioner: state?.personApiSlice?.searchPractioner,
  readPerson: state?.personApiSlice?.readPerson,
  encounterData: state?.chiefCompilenceMastersSlice?.encounter_Details,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withAllContexts(Allergies)));
