/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc The first component of the application, all the required context and routes are used here
 * to setup the application.
 */

import React from "react";

import AppAuth from "./App.auth";
// import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
import { store as ReduxStore } from "./redux";
import { Provider } from "react-redux";
import AssembleReg from "./contexts/assembleReg";
import PatientDetailsContext from "./contexts/consultant";
import AVCContext from "./contexts/avc";
import Datastore from "./App.data";
import "./App.css";
import AppDialog from "./App.dialog";
// import { CssBaseline } from "@material-ui/core";
import AppBackdrop from "./App.backdrop";
import AppMeeting from "./App.meeting";
import AppFirebase from "./App.firebase"; //For Push Notification thing

function App() {
	return (
		<Provider store={ReduxStore}>
			<AppAuth>
				<AppErrorBoundary>
					{/* <AppTheme> */}
					{/* <CssBaseline /> */}
					<AppMeeting>
						<AppDialog>
							<AppAlert>
								<AssembleReg>
									<PatientDetailsContext>
										<AVCContext>
											{/* <AppDialog> */}
											<AppBackdrop>
												<AppFirebase>
													<Datastore>
														<RouterApp />
													</Datastore>
												</AppFirebase>
											</AppBackdrop>
											{/* </AppDialog> */}
										</AVCContext>
									</PatientDetailsContext>
								</AssembleReg>
							</AppAlert>
						</AppDialog>
					</AppMeeting>
					{/* </AppTheme> */}
				</AppErrorBoundary>
			</AppAuth>
		</Provider>
	);
}

export default App;
