import React, { useState } from "react";
import {
  Div,
  Icons,
  Text,
  H6,
  Divider,
  TextInput,
} from "qdm-component-library";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { DocumentIcon } from "../../components";
import moment from 'moment';
import { UIColor } from "../../utils";
const statusTypeColor = {
  active: UIColor.success.color,
  inactive: UIColor.secondaryColor.color,
  resolved: UIColor.secondaryText.color,
  default: UIColor.primaryText.color,
}
function InfoStatus(props) {
  const styles = {
    h6: {
      fontSize: 14,
      margin: "16px 16px 5px 16px",
      fontWeight: 600,
    },
    labelStyle: {
      color: UIColor.secondaryText.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    subSection: {
      margin: "8px 16px 8px 16px",
    },
    subHeader: {
      fontSize: 12,
    },
    active: {
      margin: "0 0px 0 5px",
      fontSize: "12px",
    },
    flexStart: {
      display: "flex",
      alignItems: "center",
      borderRadius: "8px",
      marginRight: "6px",
      padding: "4px 10px",
      cursor: "pointer",
    },
    bullet: {
      width: 6.5,
      height: 6.5,
      borderRadius: "50%",
      //   backgroundColor: "#f58b00",
    },
    historyHeader: {
      backgroundColor: UIColor.lineBorderFill.color,
      padding: "12px 16px 6px 16px",
      display: "flex",
      cursor: "pointer",
    },
    iconSize: {
      width: 15,
      height: 15,
      cursor: "pointer",
    },
    historySectionText: {
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
    },
    lastSection: {
      display: "flex",
      marginTop: "4px",
    },
    forwardIcon: {
      fontSize: "14px",
      color: UIColor.secondaryText.color,
    },
    lastSectionText: {
      fontSize: "12px",
      color: UIColor.secondaryText.color,
      marginLeft: "8px",
      paddingRight: "32px",
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
    },
  };
  const [open, setOpen] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const openStausInfo = (e) => {
    props.openStausInfo(e?.currentTarget ?? null);
  };

  const handleInactiveFeild = () => {
    setOpen(true);
    props.handleStatusInactive();
  };

  const handleActiveFeild = () => {
    setOpen(false);
    props.handleStatusActive();
  };

  const handleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  const handleChangeStatus = (val) => {
    props.handleStatusChange("clinicalStatus", val)
  }

  const reasonStatusChange = (val) => {
    props.handleStatusChange("statusreason", val)
  }
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_infostatus_parent_div`}
    >
      <Div
        id={`${parent_id}_infostatus_title_div`}

        style={{
          borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
        }}
      >
        <H6
          id={`${parent_id}_infostatus_Status_info_h6`}
          inLineStyles={styles.h6}>Status info</H6>
      </Div>

      <div
        id={`${parent_id}_infostatus_Change_status_div`}
        style={styles.subSection}>
        <H6
          id={`${parent_id}_infostatus_Change_status_h6`}
          inLineStyles={styles.subHeader}>Change status to</H6>
        <div
          id={`${parent_id}_infostatus_Change_status_case_div`}
          style={{ display: "flex", padding: "6px 0px" }}>

          {props.statusList?.map((val, i) => {
            return (
              <div
                id={`${parent_id}_infostatus_${i}_${val?.label.replaceAll(" ", "_")}_div`}
                style={{ backgroundColor: statusTypeColor[val?.label?.toLocaleLowerCase()] + "20" ?? "#fff", ...styles.flexStart }}
                onClick={() => handleChangeStatus(val?.label)}
              >
                <Div
                  id={`${parent_id}_infostatus_${i}_${val?.label.replaceAll(" ", "_")}_sub_div`}
                  style={{ backgroundColor: statusTypeColor[val?.label?.toLocaleLowerCase()] ?? UIColor.secondaryColor.color , ...styles.bullet }} />
                <Text
                  id={`${parent_id}_infostatus_${i}_${val?.label.replaceAll(" ", "_")}_text`}
                  style={{ color: statusTypeColor[val?.label?.toLocaleLowerCase()] ?? "#000", ...styles.active }}>
                  {val?.label}
                </Text>
              </div>
            )
          })}
        </div>


        {props?.statushistory?.[props?.statushistory?.length - 1 ?? 0]?.new_status !== props?.currentStatus ? (
          <div
            id={`${parent_id}_infostatus_Reason_div`}
          >
            <TextInput
              id={`${parent_id}_infostatus_Reason_textinput`}
              label={"Reason"}
              placeholder="Add Reason"
              labelStyle={styles.labelStyle}
              style={styles.borderStyle}
              hoverColor={UIColor.primaryColor.color}
              onChange={(e) => reasonStatusChange(e.target.value)}
            />
          </div>
        ) : null}
      </div>

      <div
        id={`${parent_id}_infostatus_Show_History_div`}
        style={styles.historyHeader} onClick={handleShowHistory}>
        <Text
          id={`${parent_id}_infostatus_Show_History_text`}
          style={{ fontSize: "12px", fontWeight: 500 }}>Show History</Text>
        <div
          id={`${parent_id}_infostatus_Icons_div`}
          style={{ flexGrow: 1 }}></div>
        <Icons
          id={`${parent_id}_infostatus_chevron-up-down_Icons`}
          fontIcon={showHistory ? "chevron-up" : "chevron-down"}
          style={styles.iconSize}
        />
      </div>
      <div
        id={`${parent_id}_infostatus_person_name_div`}
        style={{ width: "300px", marginTop: "15px", maxHeight: 300, overflow: "auto" }}>
        {showHistory ? (
          <>{props.statushistory.length > 0 && props.statushistory.map((val, i) => {
            return (
              <div
                id={`${parent_id}_infostatus_${i}_person_name_div`}
                style={{ padding: "6px 16px 0px" }}>
                <div
                  id={`${parent_id}_infostatus_${i}_person_name_sub_div`}
                  style={{ display: "flex" }}>
                  <Text
                    id={`${parent_id}_infostatus_${i}_${val?.PersonID?.[0]?.name?.text ?? " ".replaceAll(" ", "_")}_text`}
                    style={{
                      paddingRight: "35px",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    {val?.PersonID?.[0]?.name?.text ?? " "}
                  </Text>
                  <div
                    id={`${parent_id}_infostatus_${i}_text_div`}
                    style={{ flexGrow: 1 }}></div>
                  <Text
                    id={`${parent_id}_infostatus_${i}_${val?.PersonID?.[0]?.name?.use ?? " ".replaceAll(" ", "_")}_text`}
                    inLineStyles={styles.historySectionText}>{val?.PersonID?.[0]?.name?.use ?? " "}</Text>
                  {/* <Text inLineStyles={styles.historySectionText}>Practitioner</Text> */}
                </div>

                <div
                  id={`${parent_id}_infostatus_${i}_calender_div`}
                  style={styles.lastSection}>
                  <Text
                    id={`${parent_id}_infostatus_${i}_${val?.old_status?.length > 0 ? val?.old_status : " ".replaceAll(" ", "_")}_text`}
                    inLineStyles={{
                      color: statusTypeColor[val?.old_status?.toLocaleLowerCase()] ?? UIColor.secondaryColor.color,
                      ...styles.historySectionText,
                    }}
                  >
                    {val?.old_status?.length > 0 ? val?.old_status : " "}
                  </Text>
                  {val?.new_status !== val?.old_status && <>
                    <ArrowForwardIcon
                      id={`${parent_id}_infostatus_${i}_arrowicon`}
                      style={styles.forwardIcon} />
                    <Text
                      id={`${parent_id}_infostatus_${i}_${val?.new_status}_text`}
                      inLineStyles={{
                        color: statusTypeColor[val?.new_status?.toLocaleLowerCase()] ?? UIColor.secondaryColor.color,
                        ...styles.historySectionText,
                      }}
                    >
                      {val?.new_status}
                    </Text></>
                  }
                  <div
                    id={`${parent_id}_infostatus_${i}_text_div`}
                    style={{ flexGrow: 1 }}></div>
                  <Text
                    id={`${parent_id}_infostatus_${i}_unix_format_text`}
                    inLineStyles={{
                      color: "#323232",
                      fontWeight: 500,
                      ...styles.historySectionText,
                    }}
                  >
                    {val?.period?.[0]?.start ? moment.unix(val.period[0].start).format("DD MMM h:mm a") : new Date()}
                  </Text>
                </div>
                <Divider
                  id={`${parent_id}_infostatus_${i}_bottom_divider`}
                  style={{ margin: "14px 0px" }}></Divider>
              </div>
            )
          })}</>
        ) : null}

        <div
          id={`${parent_id}_infostatus_DocumentIcon_div`}
          style={{ padding: "0px 16px 16px", ...styles.lastSection }}>
          <DocumentIcon
            id={`${parent_id}_infostatus_DocumentIcon`}
            width="12px" height="12px" />
          <Text
            id={`${parent_id}_infostatus_Allergy_symptoms_text`}
            inLineStyles={styles.lastSectionText}>
            Allergy symptoms found for itching
          </Text>
        </div>
      </div>
    </div>
  );
}

export default InfoStatus;
