import React from "react";
import { H6, Div } from "qdm-component-library";
import { PolarArea } from "react-chartjs-2";

const PolarChart = (props) => {
  const options = {
    plugins: {
      legend: {
        display: false,
        //   position: 'right',
        //   labels:{
        //       boxWidth:7,
        //       boxHeight:7,
        //       usePointStyle: true,
        //       color:'#101010',
        //       padding:30,
        //       font: {
        //         size: 12,
        //         family:'pc_regular'
        //     },
        //     }
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
  };

  const styles = {
    rowCenter: {
      display: "flex",
      alignItems: "center",
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: 15,
    },
    h6: {
      fontSize: 12,
      color: "#101010",
      margin: 0,
    },
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 12,
      width: "100%",
    },
  };
  const { parent_id } = props;
  return (
    <Div id={`${parent_id}_doughnuchart_parent_div`}>
      {props?.label ? (
        <H6
          id={`${parent_id}_doughnuchart_label_h6`}
          className="pc_medium"
          style={{ marginBottom: 35, fontSize: 14 }}
        >
          {props?.label}
        </H6>
      ) : null}

      <Div id={`${parent_id}_doughnuchart_sub_div`} style={styles.rowCenter}>
        <div
          id={`${parent_id}_doughnuchart_date_options_div`}
          style={{ position: "relative" }}
        >
          <PolarArea width={200} data={props?.data} options={options} />
        </div>
        <Div
          id={`${parent_id}_doughnuchart_map_div`}
          style={{ padding: "0px 20px", width: "100%" }}
        >
          {props?.data &&
            props?.data?.labels?.map((l, i) => {
              return (
                <Div
                  id={`${parent_id}_${i}_doughnuchart_label_div`}
                  style={{
                    ...styles.rowCenter,
                    marginBottom: i < props?.data?.labels?.length - 1 ? 10 : 0,
                  }}
                >
                  <Div
                    id={`${parent_id}_${i}_doughnuchart_sub_div`}
                    style={{ ...styles.rowCenter, flex: 1, marginRight: 15 }}
                  >
                    <Div
                      id={`${parent_id}_${i}_doughnuchart_sublevel_div`}
                      style={{
                        ...styles.dot,
                        backgroundColor:
                          props?.data?.datasets[0]?.backgroundColor?.[i] ??
                          "#000",
                      }}
                    />
                    <H6
                      id={`${parent_id}_${i}_doughnuchart_label_h6`}
                      className="pc_regular"
                      style={{ ...styles.h6, flex: 1 }}
                    >
                      {l}
                    </H6>
                  </Div>
                  {/* <H6
                    id={`${parent_id}_${i}_doughnuchart_label_data_h6`}
                    className="pc_semibold"
                    style={styles.h6}
                  >
                    {props?.data?.datasets[0]?.data?.[i] + "" ?? " "}
                  </H6> */}
                </Div>
              );
            })}
        </Div>
      </Div>
    </Div>
  );
};

PolarChart.defaultProps = {
  data: {},
  label: " ",
};

export default PolarChart;
