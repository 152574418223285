import React from "react";
import {
	Div,
	Select,
	Col,
	Row,
	Paper,
	H6,
	TextInput,
} from "qdm-component-library";
import "./style.css";
import { withAllContexts } from "../../HOCs";

function DeviceDetails(props) {
	const styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		selectBorderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
		},
		inputBorderStyle: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
			background: "#F0F0F0",
		},
		errorborderStyle: {
			borderRadius: 8,
			borderColor: 'red',
		},
	};

	const { device, helthcare, accompained } = props.visitData;

	const setDetails = (name, value, saveto, limit) => {
		console.log(helthcare, "setDetails123")
		debugger;
		if (limit) {
			if (value?.length > limit) {
				return false;
			}
		}
		//RESTRICT space, decimal and special characters
		if (name === "id") {
			const expression = /^[a-zA-Z0-9]*$/;
			const regex = new RegExp(expression);
			if (!value.match(regex)) {
				return false;
			}
		}
		let patientData = props.visitData;
		patientData[saveto][name] = value;
		if (patientData[saveto].error) {
			patientData[saveto].error[name] = false;
		}
		patientData.setVisitDetails({
			...patientData,
		});
	};
	const FlterOptions = (type) => {
		const { allMasters } = props;
		var lista = [];
		const data = allMasters?.[type];
		for (let index in data) {
			if (type === "ALTERNATEIDTYPE") {
				var datatemp = {
					value: data[index]?.coding?.display,
					id: data[index]?.coding?._id,
				};
			} else {
				var datatemp = {
					value: data[index]?.coding?.display
				};
			}
			lista.push(datatemp);
		}
		return lista;
	};
	const { labelStyle, selectBorderStyle, inputBorderStyle, errorborderStyle } = styles;
	const { walkin } = props;

	React.useEffect(() => {
		const { appointmentData, walkin } = props;
		if (walkin) {
			if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				// patientData.device['category'] = { value: appointmentData?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.category?.[0]?.text };
				// patientData.device["category"] = { value: "computed tomography" };
				patientData.device["category"] = { value: "" };
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}

		if (walkin) {
			if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				// patientData.device['type'] = { value: appointmentData?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.type?.[0]?.text ?? '' };
				// patientData.device["type"] = { value: "Scanner" };
				patientData.device["type"] = { value: "" };
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}

		if (walkin) {
			if (appointmentData?.Practioner) {
				let patientData = props.visitData;
				// patientData.device["name"] = { value: "CT" };
				patientData.device["name"] = { value: "" };
				patientData.setVisitDetails({
					...patientData,
				});
			}
		}
	}, [props.appointmentData]);
	const { parent_id } = props;
	console.log("helthcare?.category", helthcare?.category)
	return (
		<Div id={`${parent_id}_device_details_parent_div`} className="pv-root">
			{/* <Paper
				id={`${parent_id}_device_details_parent_paper`}
				className="pl-root"
			>
				<Div id={`${parent_id}_device_details_title_div`}>
					<H6
						id={`${parent_id}_device_details_label_h6`}
						className="en-title space-padding-bottom"
					>
						DEVICE DETAILS
					</H6>
				</Div>
				<Row
					id={`${parent_id}_device_details_parent_row`}
					className="en-actions-root"
				>
					<Col
						id={`${parent_id}_device_details_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Row
							id={`${parent_id}_device_details_sub_row`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_device_details_category_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_category_select`}
									search
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={[{ value: "computed tomography" }]}
									value={device?.category?.value ?? null}
									onChangeValue={(e) => setDetails("category", e, "device")}
									placeholder="select..."
									label="Category"
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_type_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_type_select`}
									search
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={[{ value: "Scanner" }]}
									value={device?.type?.value ?? null}
									onChangeValue={(e) => setDetails("type", e, "device")}
									placeholder="select..."
									label="Type"
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_name_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_name_select`}
									search
									disabled={
										props.view
											? true
											: walkin
											? false
											: props.type === "cancel" || props.type === "modify"
											? true
											: false
									}
									options={[{ value: "CT" }, { value: "MRI" }]}
									value={device?.name?.value ?? null}
									onChangeValue={(e) => setDetails("name", e, "device")}
									placeholder="select..."
									label="Name"
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper> */}
			<Paper
				id={`${parent_id}_device_details_healthcare_parent_div`}
				className="pl-root vr-device-paper"
			>
				<Div id={`${parent_id}_device_details_healthcare_title_div`}>
					<H6
						id={`${parent_id}_device_details_healthcare_label_h6`}
						className="en-title space-padding-bottom"
					>
						HEALTHCARE SERVICE
					</H6>
				</Div>
				<Row
					id={`${parent_id}_device_details_healthcare_parent_div`}
					className="en-actions-root"
				>
					<Col
						id={`${parent_id}_device_details_healthcare_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Row
							id={`${parent_id}_device_details_healthcare_sub_div`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_device_details_healthcare_category_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_healthcare_category_select`}
									options={FlterOptions("SERVICECATEGORY")}
									// helperText={
									// 	helthcare?.error && helthcare?.error["category"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										helthcare?.error && helthcare?.error["category"]
											? true
											: false
									}
									value={helthcare?.category?.value ?? null}
									onChangeValue={(e) => setDetails("category", e, "helthcare")}
									// isRequired={true}
									label="Category"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={helthcare?.error && helthcare?.error["category"] ? errorborderStyle : selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel" || props.type === "modify"
													? true
													: false
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_healthcare_type_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_healthcare_type_select`}
									options={FlterOptions("SERVICETYPE")}
									// helperText={
									// 	helthcare?.error && helthcare?.error["type"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										helthcare?.error && helthcare?.error["type"] ? true : false
									}
									value={helthcare?.type?.value ?? null}
									onChangeValue={(e) => setDetails("type", e, "helthcare")}
									// isRequired={true}
									label="Type"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={helthcare?.error && helthcare?.error["type"] ? errorborderStyle : selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel" || props.type === "modify"
													? true
													: false
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_healthcare_name_col`}
								md={4}
								lg={4}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_healthcare_name_select`}
									// helperText={
									// 	helthcare?.error && helthcare?.error["name"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										helthcare?.error && helthcare?.error["name"] ? true : false
									}
									options={FlterOptions("Service")}
									value={helthcare?.name?.value ?? null}
									onChangeValue={(e) => setDetails("name", e, "helthcare")}
									// isRequired={true}
									label="Name"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={helthcare?.error && helthcare?.error["name"] ? errorborderStyle : selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel" || props.type === "modify"
													? true
													: false
									}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper>
			<Paper
				id={`${parent_id}_device_details_accompanied_parent_paper`}
				className="pl-root vr-device-paper"
			>
				<Div id={`${parent_id}_device_details_accompanied_title_div`}>
					<H6
						id={`${parent_id}_device_details_accompanied_label_h6`}
						className="en-title space-padding-bottom"
					>
						ACCOMPANIED PERSON
					</H6>
				</Div>
				<Row className="en-actions-root">
					<Col
						id={`${parent_id}_device_details_accompanied_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Row
							id={`${parent_id}_device_details_accompanied_sub_row`}
							className="en-actions-root"
						>
							<Col
								id={`${parent_id}_device_details_accompanied_idtype_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_accompanied_idtype_select`}
									options={FlterOptions("ALTERNATEIDTYPE")}
									value={accompained?.id_type?.value ?? null}
									onChangeValue={(e) => setDetails("id_type", e, "accompained")}
									label="Id Type"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_accompanied_id_col`}
								md={3}
								lg={3}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<TextInput
									id={`${parent_id}_device_details_accompanied_id_textinput`}
									value={accompained?.id ?? null}
									onChange={(e) =>
										setDetails("id", e.target.value, "accompained", 30)
									}
									label="ID"
									placeholder="ID"
									labelStyle={labelStyle}
									style={{ ...inputBorderStyle, background: "transparent" }}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
							</Col>
							<Col
								id={`${parent_id}_device_details_accompanied_name_col`}
								md={4}
								lg={4}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<TextInput
									id={`${parent_id}_device_details_accompanied_name_textinput`}
									value={accompained?.name ?? null}
									onChange={(e) =>
										setDetails("name", e.target.value, "accompained", 40)
									}
									label="Name"
									placeholder="Name"
									labelStyle={labelStyle}
									style={{ ...inputBorderStyle, background: "transparent" }}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
								{/* <Select value={accompained?.name?.value ?? null} onChangeValue={(e) => setDetails("name", e, "accompained")} label="Name" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor="#0071F2" /> */}
							</Col>
							<Col
								id={`${parent_id}_device_details_accompanied_relationship_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_device_details_accompanied_relationship_select`}
									options={FlterOptions("RELATIONSHIP")}
									value={accompained?.relationship?.value ?? null}
									onChangeValue={(e) =>
										setDetails("relationship", e, "accompained")
									}
									label="Relationship"
									placeholder="select..."
									showArrow
									labelStyle={labelStyle}
									inLineStyles={selectBorderStyle}
									hoverColor="#0071F2"
									disabled={
										props.view
											? true
											: walkin
												? false
												: props.type === "cancel"
													? true
													: false
									}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper>
		</Div>
	);
}

export default withAllContexts(DeviceDetails);
