import React from "react";
import { Div, Select, Col, Row, TextInput } from 'qdm-component-library'
import { Button } from '@material-ui/core';
import "./style.css";
import { withAllContexts } from "../../HOCs";
import { AlertProps, UIColor } from "../../utils";
import { connect } from "react-redux";
import { actions } from "primarycare-binder";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class PatientAddAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            use: null,
            type: null,
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: null,
            district: null,
            state: null,
            country: null,
            pincode: null,
            errorList: {}
        }
    }

    autoFillLocations = async (val) => {

        let stateVal = { ...this.state };

        if (val?.parentId) {
            var getDistrict = await this.props.GET_____DISTRICT(val?.parentId);
        };
        if (val?.label) {
            var getPincode = await this.props.GET_____PINCODE(val?.label);
        };
        if (getDistrict?.payload?.data[0]?.parentId) {
            var getState = await this.props.GET_____STATE(getDistrict?.payload?.data[0]?.parentId);
        };
        if (getState.payload.data[0].parentId) {
            var getCountry = await this.props.GET_____COUNTRY(getState.payload.data[0].parentId);
        };

        this.setState({
            ...stateVal,
            city: val,
            district: getDistrict?.payload?.data[0] ? getDistrict?.payload?.data[0] : null,
            pincode: getPincode?.payload?.data[0] ? getPincode?.payload?.data[0] : null,
            state: getState.payload.data[0] ? getState.payload.data[0] : null,
            country: getCountry.payload.data[0] ? getCountry.payload.data[0] : null
        });
    };

    onChangeState = (key, value, limit) => {
                                                         
        const stateValue = { ...this.state };
        if (key === "state" && stateValue.state?.label !== value?.label) {
            stateValue["country"] = null;
            if (value?.parentId) {
                this.props.GET_____COUNTRY(value?.parentId)
            }
        }
        if (key === "district" && stateValue.district?.label !== value?.label) {
            stateValue["state"] = null;
            stateValue["country"] = null;
            if (value?.parentId) {
                this.props.GET_____STATE(value?.parentId)
            }
        }
        if (key === "city" && stateValue.city?.label !== value?.label) {
            stateValue["district"] = null;
            stateValue["state"] = null;
            stateValue["country"] = null;
            stateValue["pincode"] = null;

            this.autoFillLocations(value);
        }

        // if (key === "city" && stateValue.city?.label !== value?.label) {
        //     stateValue["district"] = null;
        //     stateValue["state"] = null;
        //     stateValue["country"] = null;
        //     stateValue["pincode"] = null;
        //     const promArr = [];
        //     if (value?.parentId) {
        //         promArr.push(this.props.GET_____DISTRICT(value.parentId));
        //     }
        //     if (value?.label) {
        //         promArr.push(this.props.GET_____PINCODE(value.label));
        //     }
        //     Promise.all(promArr);
        // }

        if (limit) {
            if (limit < value.length) {
                return false
            }
        }
        stateValue.errorList[key] = false
        this.setState({
            ...stateValue,
            [key]: value
        })
        // console.log(stateValue, "stateValue stateValue stateValue")
    }

    componentDidMount() {
        const { editData } = this.props;
        if (editData) {
            this.setState({ ...editData })
        } else {
            this.Clear()
        }
    }

    checkError = () => {
        let error = ["type", "addressLine1", "city", "state", "country", "pincode"]
        let iserror = false;
        let errorList = {};
        error.map(val => {
            if (!this.state[val]) {
                errorList[val] = true;
                iserror = true;
            }
        })
        if (iserror) {
            this.setState({ errorList })
        }
        return iserror
    }

    saveAddress = () => {
        const { onclose, index, handleClear } = this.props;
        let patientData = this.props[this.props.saveto];
        // let iserror = this.checkError()
        // if (iserror) {

        //     this.props.alert.setSnack({
        //         open: true,
        //         severity: AlertProps.severity.error,
        //         msg: "Please fill all the mandatory fields",
        //         vertical: AlertProps.vertical.top,
        //         horizontal: AlertProps.horizontal.center,
        //         tone: true,
        //     });
        //     return false;
        // }
        if (index !== null) {
            patientData.address[index] = this.state
        } else {
            patientData.address.push(this.state)
        }
        patientData[this.props.setTo]({
            ...patientData,
        })
        onclose()
        handleClear()
        this.Clear()
    }

    styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        errorBorderStyle: {
            borderRadius: 8
        },
        outlinedButton: {
            borderColor: '#0071F2',
            color: '#0071F2',
            backgroundColor: '#fff',
            borderRadius: 8,
            padding: '8px 15px',
            minWidth: 100,
            fontWeight: '600',
            fontSize: 12,
            marginRight: 10
        },
        containedButton: {
            backgroundColor: UIColor.secondaryButtonColor.color,
            color: "#fff",
            borderColor: UIColor.secondaryButtonColor.color,
            borderRadius: 8,
            padding: '8px 15px',
            minWidth: 100,
            fontWeight: '600',
            fontSize: 12
        },
    }

    Clear = () => {
        this.setState({
            use: null,
            type: null,
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: null,
            district: null,
            state: null,
            country: null,
            pincode: null,
        })
    }

    FlterOptions = (type) => {
        const { allMasters } = this.props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "label": data[index]?.coding?.display,
                "value": data[index]?.coding?.display,
                "_id": data[index]?.coding?._id,
            };
            lista.push(datatemp);
        }
        return lista
    }

    render() {
        const { onclose, parent_id } = this.props;
        const { errorList } = this.state;
        const { labelStyle, borderStyle, errorBorderStyle, outlinedButton, containedButton } = this.styles;

        return <Div
            id={`${parent_id}_addaddress_parent_div`}
            style={{
                padding: 14
            }}>
            {/* <Paper className="pi-paper"> */}
            <div className="titleDiv">
                <span className="titleAddress">ADD NEW ADDRESS</span>
                <IconButton aria-label="delete"
                    onClick={() => {
                        onclose()
                        this.Clear()
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <Row
                id={`${parent_id}_addaddress_parent_row`}
                className="pi-actions-root" style={{ padding: 0 }}>
                <Col
                    id={`${parent_id}_addaddress_use_label_col`}
                    md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    <Select
                        id={`${parent_id}_addaddress_use_label_select`}
                        showArrow
                        label="Use"
                        placeholder="use"
                        labelStyle={labelStyle}
                        inLineStyles={errorList.use ? errorBorderStyle : borderStyle}
                        // isRequired={true}
                        hoverColor={UIColor.primaryColor.color}
                        options={this.FlterOptions('ADDRESSUSE')}
                        onChangeValue={value => this.onChangeState('use', value)}
                        value={this.state.use?.value}
                        error={errorList.use ? true : false}
                    // helperText={(errorList.use && errorList.use) ? 'Is required' : ''}
                    />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            Use <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_use_label_select`}
                            list={this.FlterOptions('ADDRESSUSE') ?? []}
                            placeholder={"select..."}
                            value={this.state.use ?? ""}
                            onchange={(e,value) => this.onChangeState('use', value)}
                        /> */}
                </Col>
                <Col
                    id={`${parent_id}_addaddress_type_label_col`}
                    md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    <Select
                        id={`${parent_id}_addaddress_type_label_select`}
                        showArrow
                        label="Type"
                        placeholder="type"
                        labelStyle={labelStyle}
                        inLineStyles={errorList.type ? errorBorderStyle : borderStyle}
                        error={errorList.type ? true : false}
                        // helperText={(errorList.type && errorList.type) ? 'Is required' : ''}
                        hoverColor={UIColor.primaryColor.color}
                        isRequired={false}
                        options={this.FlterOptions('ADDRESSTYPE')}
                        onChangeValue={value => this.onChangeState('type', value)}
                        value={this.state.type?.value}
                    />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            Type <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_type_label_select`}
                            list={this.FlterOptions('ADDRESSTYPE') ?? []}
                            placeholder={"select..."}
                            value={this.state.type ?? ""}
                            onchange={(e,value) => this.onChangeState('type', value)}
                        /> */}
                </Col>
                <Col
                    id={`${parent_id}_addaddress_addressline1_label_col`}
                    md={4} lg={4} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    <TextInput isRequired={false}
                        id={`${parent_id}_addaddress_addressline1_label_textinput`}
                        placeholder="addressLine1"
                        onChange={(e) => this.onChangeState('addressLine1', e.target.value, 30)}
                        value={this.state.addressLine1 ? this.state.addressLine1 : ""}
                        label="Address Line 1"
                        error={errorList.addressLine1 ? true : false}
                        // helperText={(errorList.addressLine1 && errorList.addressLine1) ? 'Is required' : ''}
                        style={errorList.addressLine1 ? errorBorderStyle : borderStyle}
                        labelStyle={labelStyle}
                        // style={borderStyle}
                        hoverColor={UIColor.primaryColor.color}
                    />
                </Col>
                <Col
                    id={`${parent_id}_addaddress_addressline2_label_col`}
                    md={4} lg={4} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    <TextInput
                        id={`${parent_id}_addaddress_addressline2_label_textinput`}
                        placeholder="addressLine2"
                        onChange={(e) => this.onChangeState('addressLine2', e.target.value, 30)}
                        value={this.state.addressLine2}
                        label="Address Line 2"
                        labelStyle={labelStyle}
                        error={errorList.addressLine2 ? true : false}
                        // helperText={(errorList.addressLine2 && errorList.addressLine2) ? 'Is required' : ''}
                        style={errorList.addressLine2 ? errorBorderStyle : borderStyle}
                        hoverColor={UIColor.primaryColor.color} />
                </Col>
                {/* </Row> */}
                {/* <Row className="pi-actions-root"> */}
                <Col
                    id={`${parent_id}_addaddress_addressline3_label_col`}
                    md={6} lg={6} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    <TextInput
                        id={`${parent_id}_addaddress_addressline3_label_textinput`}
                        placeholder="addressLine3"
                        onChange={(e) => this.onChangeState('addressLine3', e.target.value, 30)}
                        value={this.state.addressLine3}
                        label="Address Line 3"
                        labelStyle={labelStyle}
                        error={errorList.addressLine3 ? true : false}
                        // helperText={(errorList.addressLine3 && errorList.addressLine3) ? 'Is required' : ''}
                        style={errorList.addressLine3 ? errorBorderStyle : borderStyle}
                        hoverColor={UIColor.primaryColor.color} />
                </Col>
                <Col
                    id={`${parent_id}_addaddress_city_label_col`}
                    md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
                    <Select
                        isRequired={false}
                        id={`${parent_id}_addaddress_city_label_select`}
                        options={this.props.city?.data || []}
                        // options={this.FlterOptions('CITY')}
                        onChangeValue={value => this.onChangeState('city', value)}
                        value={this.state.city?.value} showArrow label="City"
                        placeholder="select..." labelStyle={labelStyle}
                        inLineStyles={errorList.city ? errorBorderStyle : borderStyle}
                        error={errorList.city ? true : false}
                        // helperText={(errorList.city && errorList.city) ? 'Is required' : ''}
                        hoverColor={UIColor.primaryColor.color} />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            City <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_city_label_select`}
                            list={this.props.City?.city ?? []}
                            placeholder={"select..."}
                            value={this.state.city ?? ""}
                            onchange={(e,value) => this.onChangeState('city', value)}
                        /> */}
                </Col>
                <Col
                    id={`${parent_id}_addaddress_district_label_col`}
                    md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
                    <Select
                        // isRequired={true}
                        id={`${parent_id}_addaddress_district_label_select`}
                        options={this.state.city ? (this.props.district?.data || []) : []}
                        // options={this.FlterOptions('DISTRICT')}
                        onChangeValue={value => this.onChangeState('district', value)}
                        value={this.state.district?.value} showArrow label="District" placeholder="select..."
                        labelStyle={labelStyle}
                        inLineStyles={errorList.district ? errorBorderStyle : borderStyle}
                        error={errorList.district ? true : false}
                        // helperText={(errorList.district && errorList.district) ? 'Is required' : ''}
                        hoverColor={UIColor.primaryColor.color} />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            District <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_district_label_select`}
                            list={this.props.City?.disVal ?? []}
                            placeholder={"select..."}
                            value={this.state.district ?? ""}
                            onchange={(e,value) => this.onChangeState('district', value)}
                        /> */}
                </Col>
                {/* {JSON.stringify(this.props?.City?.disVal)} */}
                <Col
                    id={`${parent_id}_addaddress_state_label_col`}
                    md={6} lg={2} sm={2} xs={12} inLineStyles={{ padding: 5 }}>
                    <Select
                        isRequired={false}
                        id={`${parent_id}_addaddress_state_label_select`}
                        // options={this.FlterOptions('State')}
                        options={this.state.district ? (this.props.state?.data || []) : []}
                        onChangeValue={value => this.onChangeState('state', value)}
                        value={this.state.state?.value}
                        showArrow label="State"
                        placeholder="select..."
                        labelStyle={labelStyle}
                        inLineStyles={errorList.state ? errorBorderStyle : borderStyle}
                        error={errorList.state ? true : false}
                        // helperText={(errorList.state && errorList.state) ? 'Is required' : ''}
                        hoverColor={UIColor.primaryColor.color} />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            State <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_state_label_select`}
                            list={this.props.City?.state ?? []}
                            placeholder={"select..."}
                            value={this.state.state ?? ""}
                            onchange={(e,value) => this.onChangeState('state', value)}
                        /> */}
                </Col>
                {/* </Row>
            <Row className="pi-actions-root"> */}
                <Col
                    id={`${parent_id}_addaddress_country_label_col`}
                    md={4} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    <Select
                        isRequired={false}
                        id={`${parent_id}_addaddress_country_label_select`}
                        options={this.state.state ? (this.props.country?.data || []) : []}
                        // options={this.FlterOptions('Country')}
                        onChangeValue={value => this.onChangeState('country', value)} value={this.state.country?.value}
                        showArrow label="Country" placeholder="select..." labelStyle={labelStyle}
                        inLineStyles={errorList.country ? errorBorderStyle : borderStyle}
                        error={errorList.country ? true : false}
                        // helperText={(errorList.country && errorList.country) ? 'Is required' : ''}
                        hoverColor={UIColor.primaryColor.color} />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            Country <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_country_label_select`}
                            list={this.props.City?.country ?? []}
                            placeholder={"select..."}
                            value={this.state.country ?? ""}
                            onchange={(e,value) => this.onChangeState('country', value)}
                        /> */}
                </Col>
                {/* {JSON.stringify(this.props.City)} */}
                <Col
                    id={`${parent_id}_addaddress_pincode_label_col`}
                    md={4} lg={2} sm={6} xs={12} inLineStyles={{ padding: 5 }}>
                    {/* <Select showArrow label="pincode" placeholder="select..." labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor={UIColor.primaryColor.color} /> */}
                    {/* <TextInput
                        id={`${parent_id}_addaddress_pincode_label_textinput`}
                        placeholder="pincode"
                        maxLength={6}
                        type="number" onChange={(e) => this.onChangeState('pincode', e.target.value)}
                        value={this.state.pincode} label="pincode" labelStyle={labelStyle} style={borderStyle} hoverColor={UIColor.primaryColor.color} /> */}
                    <Select
                        id={`${parent_id}_addaddress_pincode_label_select`}
                        isRequired={false}
                        options={this.state.city ? (this.props.pincode?.data || []) : []}
                        onChangeValue={value => this.onChangeState('pincode', value)} value={this.state.pincode?.value}
                        showArrow label="Pincode" placeholder="select..." labelStyle={labelStyle}
                        inLineStyles={errorList.pincode ? errorBorderStyle : borderStyle}
                        error={errorList.pincode ? true : false}
                        // helperText={(errorList.pincode && errorList.pincode) ? 'Is required' : ''}
                        hoverColor={UIColor.primaryColor.color} />
                    {/* <Typography
                            style={{
                            fontSize: "12px",
                            paddingBottom: 4,
                            Fontfamily: "pc_regular",
                            color: "#6F6F6F",
                            }}
                            variant="body1"
                        >
                            Pincode <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectBox
                            id={`${parent_id}_addaddress_pincode_label_select`}
                            list={this.props.City?.pin ?? []}
                            placeholder={"select..."}
                            value={this.state.pincode ?? ""}
                            onchange={(e,value) => this.onChangeState('pincode', value)}
                        /> */}

                </Col>
            </Row>
            <Row
                id={`${parent_id}_addaddress_button_row`}
            >
                <Col
                    id={`${parent_id}_addaddress_button_col`} className="actionDiv" md={12} lg={12} sm={12} xs={12}>
                    {/* <Button
                        id={`${parent_id}_addaddress_cancel_button`}
                        className="aa-actions-btn" variant="outlined" style={outlinedButton} onClick={() => {
                            onclose()
                            this.Clear()
                        }}>Cancel</Button> */}
                    <Button
                        id={`${parent_id}_addaddress_save_button`}
                        className="aa-actions-btn" variant="outlined" backgroundColor={UIColor.secondaryButtonColor.color} style={containedButton} onClick={() => this.saveAddress()} >Save</Button>
                </Col>
            </Row>
            {/* </Paper> */}
        </Div>

    }
}


const mapStateToProps = (state) => ({
    city: state?.geoLocationSlice?.getCity,
    district: state?.geoLocationSlice?.getDistrict,
    pincode: state?.geoLocationSlice?.getPincode,
    state: state?.geoLocationSlice?.getState,
    country: state?.geoLocationSlice?.getCountry,
});

export default connect(
    mapStateToProps,
    actions
)(withAllContexts(PatientAddAddress));