import React from "react";
import { Card, Text, H6, Div } from "qdm-component-library";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";

function PatientStatusCard(props) {
  const styles = {
    card: {
      height: "100%",
      padding: "12px",
      border: "1px solid #E0E0E0",
      boxShadow: "0px 10px 25px #0000000A",
      backgroundColor: "#fff",
      borderRadius: 8,
    },
    title: {
      fontSize: 12,
      color: "#323232",
    },
    subTitle: {
      fontSize: 10,
      color: "#646464",
    },
    rowCenter: {
      display: "flex",
      alignItems: "center",
    },
    countStyle: {
      border: "1px dashed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      width: 30,
      height: 30,
    },
  };
  const { parent_id } = props;

//   React.useEffect(() => {
//     console.log(props)
//     ;
// }, []);

  return (
    <div
      id={`pendingtask_parent_div`}
      style={{ height: "100%", padding: "0px 10px 10px 0px" }}
    >
      <Card id={`${parent_id}_pendingtask_parent_card`} style={styles.card}>
        <Div id={`${parent_id}_pendingtask_sub_div`} style={styles.rowCenter}>
          <Div id={`${parent_id}_pendingtask_title_div`} style={{ flex: 1 }}>
            <H6
              id={`${parent_id}_pendingtask_title_h6`}
              className="pc_medium"
              style={styles.title}
            >
              {props?.title}
            </H6>
            <Text
              id={`${parent_id}_pendingtask_title_text`}
              className="pc_regular"
              style={styles.subTitle}
            >
              {props?.subTitle}
            </Text>
          </Div>
          <Div
            id={`${parent_id}_pendingtask_count_div`}
            className="pc_medium"
            style={{
              ...styles.countStyle,
              color: props?.color,
              borderColor: props?.color,
              backgroundColor: props?.backgroundColor,
            }}
          >
            {
              props?.selectorName
                ? (
                  props?.orgCount?.[props.selectorName]?.data?.count
                )
                : props?.count
            }
          </Div>
        </Div>
      </Card>
    </div>
  );
}

PatientStatusCard.defaultProps = {
  title: "",
  subTitle: "",
  count: "",
  color: "#000",
  backgroundColor: "transparent",
};

const mapStateToProps = (state) => ({
  orgCount: state?.dashboardApiSlice,
});

export default connect(mapStateToProps, actions)(PatientStatusCard);
