import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import React from "react";
import doctorAVC from "../../../../src/assets/img/Doc.svg";
import frontdeskAVC from "../../../../src/assets/img/AVCfrontdesk.svg";
import nurseAVC from "../../../../src/assets/img/AVCnurse.svg";
import mri from "../../../assets/img/MRI Illustration.svg";
import checkUp from "../../../assets/img/checkUp.svg";
import cardio from "../../../assets/img/cardio.svg";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
  },
  textBody: {
    fontFamily: "pc_medium !important",
    fontSize: "14px",
    color: "#2AAD88",
    paddingBottom: "16px",
  },
  alignButton: {
    border: "1px solid #4CBF9D",
    backgroundColor: "#fff",
    color: "#4CBF9D",
    textTransform: "none",
    borderRadius: "8px",
  },
  topDescription: {
    border: "1px solid #eee",
    padding: "20px 20px 10px",
    borderRadius: "8px",
    margin: "6px",
  },
  headerText: {
    fontFamily: "pc_medium !important",
    fontSize: "24px",
    fontWeight: 600,
  },
  headerFollowText: {
    fontFamily: "pc_medium !important",
    fontSize: "12px",
    color: "#6F6F6F",
    padding:"16px 0px"
  },
}));

const carousalData = [
  {
    backPic: mri,
    header: "World's Most Advance MRI Scan available at Ainqa Hospital",
    subHeader:
      "With our advance MRI scan diagnosis your full body and save your health from future disease.",
  },
  {
    backPic: checkUp,
    header: "India's Best Full Body Health Checkup",
    subHeader:
      "Get your scan report with well detailed information. And prevent or get proper treatment",
  },
  {
    backPic: cardio,
    header: "Indian's No : 1 hospital for cardiology and heart care",
    subHeader:
      "Get your scan report with well detailed information. And prevent or get proper treatment",
  },
];

export function WaitingLobbyAVC(props) {
  const classes = useStyles();

  const proceed = () =>{
    props?.moveNextRole && props.moveNextRole()
  }

  return (
    <div className="rootWaiting" style={{padding:"10px"}}>
      <div className={classes.topDescription}>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.textBody}>
              {props?.name}
            </Typography>
            <Typography
              className={classes.textBody}
              style={{ color: "#101010" }}
            >
              {props?.desc}
            </Typography>
            {props?.tokenNumber && <Button className={classes.alignButton} onClick={proceed}>{`YourToken No: ${props?.tokenNumber}`}</Button>}
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <img src={doctorAVC} />
            </div>
          </Grid>
        </Grid>
      </div>

      <Carousel
        showStatus={false}
        animationHandler="fade"
        swipeable={false}
        renderThumbs={() => null}
        dynamicHeight={false}
        showArrows={false}
      >
        {carousalData.map((val) => (
          <div>
            <img alt="" src={val.backPic} />
            <div className="legend" style={{ marginBottom: "25px" }}>
              <Typography className={classes.headerText}>
                {val.header}
              </Typography>
              <Typography className={classes.headerFollowText}>
                {val.subHeader}
              </Typography>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
