import {
	makeStyles,
	Button,
	Typography,
} from "@material-ui/core";
import React from "react";
import landingPic from "../../../assets/img/Group 94316.svg";

const useStyles = makeStyles((theme) => ({
	textBody: {
		fontFamily: "pc_medium !important",
		fontSize: "16px",
		color: "#6F6F6F",
		padding: "0px 10px 10px",
	},
	buttonAlign: {
		fontSize: "14px",
		textTransform: "none",
		borderRadius: "8px",
		padding:"10px 16px",
		backgroundColor: "#0071F2",
		fontFamily: "pc_medium !important",
		color: "#fff",
		marginRight: "10px",
		border: "none",
		"&:hover": {
			backgroundColor: "#0551a9",
		},
	},
	rootCircle: {
		position: "relative",
	},
	bottom: {
		color:"#f6f6f6"
	},
	top: {
		color: "#FF6384",
		animationDuration: "550ms",
		position: "absolute",
		left: 0,
	},
	circle: {
		strokeLinecap: "round",
	},
	btnDiv: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		margin: "30px 0",
	},
}));

const landingData = {
	name: "Sudharsan Narasimhan",
	description:
		" will join the call soon, In the meantime We request you to complete the following basic formalities",
};

const userInfoData = localStorage.getItem("role_name");

export function EndConsult(props) {
	const classes = useStyles();

	return (
		<div style={{width:"70%", margin:"auto",padding:"32px"}}>
            <div style={{display:"flex",justifyContent:'center'}}>
			<img alt="" src={landingPic} />
            </div>
			<div style={{ textAlign: "center", width: "70%", margin: "auto" }}>
				<Typography className={classes.textBody} style={{paddingTop:"10px"}}>
					Hi {landingData.name}
				</Typography>
                <Typography className={classes.textBody} style={{color:"#36A2EB"}}>
                Your Doctor consultation completed 
				</Typography>
				<Typography
					className={classes.textBody}
					style={{ color: "#B6B6B6", fontSize: "12px" }}
				>
					Dr Priyadharshini completed your consultation, Please download your e- prescription below
				</Typography>
				<Typography className={classes.textBody} style={{ color: "#101010" }}>
					{userInfoData === "frontdesk"
						? "Front Desk Staff"
						: userInfoData === "nurse"
						? "Nurse"
						: "Doctor"}
					{landingData?.description}
				</Typography>
				<div className={classes.btnDiv}>
					<Button className={classes.buttonAlign} >
						Exit consultation
					</Button>
				</div>
			</div>
		</div>
	);
}
