import React from "react";
import {
    ActionFab,
    SearchPage
} from '../components'
import CloseIcon from '@material-ui/icons/Close';
import { Image } from 'qdm-component-library'
import { fetchUserInfo } from "../utils";

const WithSearch = (WrappedComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                searchable: false,
                groupedOptions: [],
                hidden: false
            }
            this.fetchUserInfo = fetchUserInfo.bind(this);
        }
        handleActionOpen = () => {
            this.setState({
                searchable: true
            })
        }
        handleActionClose = () => {
            this.setState({
                searchable: false
            })
        }
        async componentDidMount() {
            const userData = await this.fetchUserInfo();
            if ((localStorage.getItem('role_name') === 'doctor') || (localStorage.getItem('role_name') === 'nurse')) {
                this.setState({ hidden: true })
            }
        }
        render() {
            return (
                <React.Fragment>
                    {
                        !this.state.searchable ?
                            <React.Fragment>
                                <div
                                    id="search_parent_div"
                                    style={{ display: this.state.hidden ? "none" : "block" }}>
                                    <ActionFab
                                        parent_id={'search'}
                                        icon={<Image
                                            id="search_imgae"
                                            src="images/icons8-search.svg" alt="search" style={{ width: 15, height: 15 }} />}
                                        // icon={SearchIcon}
                                        handleAction={this.handleActionOpen}
                                    />
                                </div>
                                <WrappedComponent {...this.props} />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <ActionFab
                                    parent_id={'search'}
                                    icon={<CloseIcon />}
                                    handleAction={this.handleActionClose}
                                />
                                <SearchPage
                                    parent_id={'search_page'}
                                    groupedOptions={this.state.groupedOptions}
                                />
                            </React.Fragment>
                    }
                </React.Fragment>
            )
        }
    }
}

export default WithSearch;
