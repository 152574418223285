import React from "react";
import { CircularProgress } from "@material-ui/core";

const LoadingComp = ({ loading, showChildOnLoading = false, children,style ={}, loaderStyle={} }) => {
  if (loading) {
    return (
      <>
        {/* {showChildOnLoading && children} */}
        <div style={{ display: "flex", justifyContent: "center", ...style }}>
          <CircularProgress style={{...loaderStyle}} />
        </div>
      </>
    );
  }
  return <>{children}</>;
};

export default LoadingComp;
