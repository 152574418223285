export const patientInfoJson = {
  name: "Prasanna Rani",
  mrn: 28882873,
  encounter: "EN-5905943",
  specialty: "Cardilogy",
  location: "OPD Room 1",
  startDate: "20/07/2022 10:00",
  endDate: "20/07/2022 16:00",
  doctor: "Dr. Andrew John",
  years: "46 Yrs",
  gender: "Female",
};

export const billingInfoJson = {
  billNo: "0215483",
  billdate: "20/07/2022 10:00",
  glNo: "RC1029923",
  glDate: "RC1029923",
  payer: "Oman Insurance Co",
  guarantor: "Oman Insurance Co",
  plan: "Silver Plus",
  sponsor: "TCS Plc",
};

export const dataType = [
  { name: "itemCode", type: "BILLTEXT", header: "scroll",width:"210px" },
  { name: "rate", type: "BILLTEXT", header: "scroll" },
  { name: "qty", type: "BILLTEXT", header: "scroll" },
  { name: "grossAmt", type: "BILLTEXT", header: "scroll" },
  { name: "discount", type: "BILLTEXT", header: "scroll" },
  { name: "netAmt", type: "BILLTEXT", header: "scroll" },
  { name: "patientShare", type: "BILLTEXT", header: "scroll" },
  { name: "creditShare", type: "BILLTEXT", header: "scroll" },
];

export const Header = [
  "Item Code & Description",
  "Rate",
  "Qty",
  "Gross Amt",
  "Discount",
  "Net Amt",
  "Patient Share",
  "Credit Share",
];

export const tableData = [
  {
    itemCode: "Private - AC 1 (100.01)",
    rate: "4,000.00",
    oty: "5 Days",
    grossAmt: "20,000.00",
    discount: "2,000.00",
    netAmt: "18,000.00",
    patientShare: "3,600.00",
    creditShare: "14,400.00",
  },
  {
    itemCode: "Room & Board NICU excluding Supplies (28.01)",
    rate: "8,000.00",
    oty: "1 Days",
    grossAmt: "8,000.00",
    discount: "800.00",
    netAmt: "7,200.00",
    patientShare: "-",
    creditShare: "7,200.00",
  },
  {
    itemCode: "X-Ray Left Hand (53123)",
    rate: "500.00",
    oty: "1 No",
    grossAmt: "500.00",
    discount: "-",
    netAmt: "500.00",
    patientShare: "-",
    creditShare: "500.00",
  },
  {
    itemCode: "Toxin or antitoxin assay, tissue culture (eg, Clostridium difficile toxin) (87230)",
    rate: "700.00",
    oty: "1 No",
    grossAmt: "700.00",
    discount: "-",
    netAmt: "700.00",
    patientShare: "-",
    creditShare: "700.00",
  },
  {
    itemCode: "(MEMANTINE : 20 MG) FILM COATED TABLETS - BTCH1425 (45785)",
    rate: "35.00",
    oty: "60 Tab",
    grossAmt: "2,100.00",
    discount: "105.00",
    netAmt: "1,995.00",
    patientShare: "2,205.00",
    creditShare: "-",
  },
  {
    itemCode: "",
    rate: "",
    oty: "Total",
    grossAmt: "31,300.00",
    discount: "2,905.00",
    netAmt: "28,395.00",
    patientShare: "5,705.00",
    creditShare: "22,800.00",
  },
];
