import React from "react";
import { UIColor } from "../../utils";

export const OrderIcon = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "20";
  let color = props.color ? props.color : UIColor.sidebarIconColor.color;
  return (
    <svg id="Order_svg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 20"><path class="a" fill={color} d="M8.857,5A2.354,2.354,0,0,0,6.543,7.368V19.737H5.771A.781.781,0,0,0,5,20.526v1.053A3.4,3.4,0,0,0,8.343,25H19.657A3.4,3.4,0,0,0,23,21.579V7.368A2.354,2.354,0,0,0,20.686,5Zm0,1.579H20.686a.769.769,0,0,1,.771.789V21.579a1.8,1.8,0,1,1-3.6,0V20.526a.781.781,0,0,0-.771-.789h-9V7.368A.769.769,0,0,1,8.857,6.579Zm2.057,2.632a.79.79,0,1,0,.771.789A.781.781,0,0,0,10.914,9.211Zm2.571,0a.79.79,0,0,0,0,1.579h5.143a.79.79,0,0,0,0-1.579Zm-2.571,3.158a.79.79,0,1,0,.771.789A.781.781,0,0,0,10.914,12.368Zm2.571,0a.79.79,0,0,0,0,1.579h5.143a.79.79,0,0,0,0-1.579Zm-2.571,3.158a.79.79,0,1,0,.771.789A.781.781,0,0,0,10.914,15.526Zm2.571,0a.79.79,0,0,0,0,1.579h5.143a.79.79,0,0,0,0-1.579ZM6.543,21.316h9.771v.263a3.371,3.371,0,0,0,.6,1.842H8.343a1.81,1.81,0,0,1-1.8-1.842Z" transform="translate(-5 -5)"/></svg>
    );
};