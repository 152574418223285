import React from "react";
import { TopHeader,LoadingComp } from "../../components";
import DayEndProcessDashboard from "./depDashboard";
import { Button, Grid, Typography } from "@material-ui/core";
import DailyDashboard from "./dailyDashboard";
import withAllContexts from "../../HOCs/withAllContexts";
import { AlertProps } from "../../utils";
import { withRouter } from "react-router-dom";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { actions } from "primarycare-binder";
import { connect } from 'react-redux'
class DayEndProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isdaily: false,
            dayEndData: null,
            loader:true,
            buttonChange: false
        }
        this.viewLogDaily = this.viewLogDaily.bind(this)
    }
    async viewLogDaily(data) {
        this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: true,
            message: "Loading..."
        });
        // await this.props.DAY_BASED_PRACTITIONER_LIST({date : 1612846360})
        await this.props.DAY_BASED_PRACTITIONER_LIST({date : data?.dayend?.docdate})
        this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: ""
        });
        this.setState({
            isdaily: true,
            dayEndData : data
        })
    }
    async componentDidMount() {
        // this.props.backDrop.setBackDrop({
        //     ...this.props.backDrop,
        //     open: true,
        //     message: "Loading..."
        // });
        await this.props.GET_DAY_END_PROCESS()
        // this.props.backDrop.setBackDrop({
        //     ...this.props.backDrop,
        //     open: false,
        //     message: ""
        // });
        this.setState({
            ...this.state,
            loader: false
        })
    }
    goBack = () => {
        if (this.state.isdaily) {
            this.setState({
                isdaily: false
            })
        } else {
            this.props.history.goBack();
        }
    }
    runDayEndProcess = async () => {
        // this.props.backDrop.setBackDrop({
        //     ...this.props.backDrop,
        //     open: true,
        //     message: "Loading..."
        // });
        this.setState({
            ...this.state,
            buttonChange: true
        })
        await this.props.RUN_DAY_END_PROCESS()
        // this.props.backDrop.setBackDrop({
        //     ...this.props.backDrop,
        //     open: false,
        //     message: ""
        // });
        this.props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Day-end process has run Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
        });
        this.setState({
            ...this.state,
            buttonChange: false
        })
    }
    render() {
        let { isdaily } = this.state;
        return (
            <React.Fragment>
                <TopHeader
                    parent_id={"dashboard"}
                    noHome={true}
                    middleComponent={<></>}
                />
                <div style={{
                    display: this.state.loader ? "flex" : "block",
                    height: this.state.loader ? "100vh" : "auto",
                    alignItems: "center",
                    justifyContent:this.state.loader ?"center" : "unset"
                }}>
                <LoadingComp loading={this.state.loader}>
                <Grid style={{ padding: "22px 20px 0px" }}>
                    {(!isdaily) && <Button onClick={() => this.runDayEndProcess()} style={{ float: "right" }} disabled={this.state.buttonChange} color="primary" variant="contained">{this.state.buttonChange ? "Processing Day End" : "Process Day End"} </Button>}
                    <Grid style={{ display: "grid", gridTemplateColumns: "30px 1fr" }}>
                        <div onClick={() => this.goBack()}
                            style={{
                                display: "flex", background: "#dddcdc", borderRadius: "50%",
                                width: 23, height: 23, paddingLeft: 5, cursor: "pointer"
                            }}><ArrowBackIosIcon style={{ width: 11, margin: "auto", height: 11 }} /></div>
                        <Typography variant="h6" style={{ fontSize: 16 }} >Day End Process</Typography>
                    </Grid>
                </Grid>
             
                {!isdaily && <DayEndProcessDashboard viewLogDaily={this.viewLogDaily} />}

                {isdaily && <DailyDashboard />}
                </LoadingComp>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    dayEndProcess: state?.dayEndProcessSlice
})

export default connect(
    mapStateToProps,
    actions
)(withRouter(withAllContexts(DayEndProcess)));