import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import PrivateRoute from "./privateRouter";
import AvcPrivateRoute from "./avcPrivateRoute";
import {
	NotFound,
	SignIn,
	SignInOTP,
	SignUp,
	DoctorConsultation,
	DoctorListing,
	ConfirmationPage,
	AppointmentsListingPage,
	YourAppointmentHasBeenBooked,
	PatientRegistration,
	NextOfKinForm,
	PatientVisitDetails,
	PatientVisitModify,
	PatientVisitCancel,
	PatientReferral,
	FinancialRegistration,
	Collection,
	Dashboard,
	PatientDashBoard,
	PatientDetail,
	ViewReport,
	ForgotPassword,
	ResetPassword,
	ChangePassword,
	ExpiryPassword,
	DayEndProcess,
	CashierBillingDetails,
	CashierBillingSummary,
} from "./../screens";
import { VideoCall } from "../screens/AVC";

import {
	AppointmentStats,
	CurrentVisitStats,
	PendingTasks,
	WorkListParent,
} from "../screens/dashboard";

const RouterApp = (props) => {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={SignIn} />
				<Route exact path={Routes.forgotPassword} component={ForgotPassword} />
				<Route exact path={Routes.resetPassword} component={ResetPassword} />
				<Route exact path={Routes.changePassword} component={ChangePassword} />
				<Route exact path={Routes.expired} component={ExpiryPassword} />

				{/*Sign In Route */}
				<Route path={Routes.signIn} component={SignIn} />

				{/* Day End Process */}
				<PrivateRoute path={Routes.dayEndProcess} component={DayEndProcess} />

				{/*Sign In OTP Route */}
				<Route path={Routes.signInOTP} component={SignInOTP} />

				{/*Sign Up Route */}
				<Route path={Routes.signUp} component={SignUp} />

				{/*Doctor Consultation Route */}
				<PrivateRoute
					path={Routes.doctorConsultation}
					component={DoctorConsultation}
				/>

				{/*DoctorListing Route */}
				<PrivateRoute
					exact
					path={Routes.doctorListing}
					component={DoctorListing}
				/>

				<PrivateRoute
					exact
					path={Routes.doctorListingWithId}
					component={DoctorListing}
				/>

				<PrivateRoute exact path={Routes.viewreport} component={ViewReport} />
				{/*ConfirmationPage Route */}
				<PrivateRoute
					exact
					path={Routes.confirmBooking}
					component={ConfirmationPage}
				/>
				<PrivateRoute
					exact
					path={Routes.appointmentsListingPage}
					component={AppointmentsListingPage}
				/>
				<PrivateRoute
					exact
					path={Routes.yourAppointmentHasBeenBooked}
					component={YourAppointmentHasBeenBooked}
				/>
				{/* patient dashboard */}
				<PrivateRoute
					exact
					path={Routes.PatientDashBoard}
					component={PatientDashBoard}
				/>
				{/* assemble registration */}
				<PrivateRoute
					exact
					path={Routes.assemblePatioentRegister}
					component={PatientRegistration}
				/>
				<PrivateRoute
					exact
					path={Routes.assembleNextOfKin}
					component={NextOfKinForm}
				/>
				<PrivateRoute
					exact
					path={Routes.patientReferral}
					component={PatientReferral}
				/>
				<PrivateRoute
					exact
					path={Routes.patientVisitDetails}
					component={PatientVisitDetails}
				/>
				<PrivateRoute
					exact
					path={Routes.patientVisitModify}
					component={PatientVisitModify}
				/>
				<PrivateRoute
					exact
					path={Routes.patientVisitCancel}
					component={PatientVisitCancel}
				/>

				<PrivateRoute
					exact
					path={Routes.financialRegistration}
					component={FinancialRegistration}
				/>

				{/* assemble registration */}
				<PrivateRoute path={Routes.collection} component={Collection} />

				{/* Dashboard  */}
				<PrivateRoute path={Routes.dashboard} component={Dashboard} />

				{/* patientDetail */}
				<PrivateRoute path={Routes.patientDetail} component={PatientDetail} />

				{/* AppointmentStats */}
				<PrivateRoute
					path={Routes.appointmentStats}
					component={AppointmentStats}
				/>

				{/* CurrentVisitStats */}
				<PrivateRoute
					path={Routes.currentVisitStats}
					component={CurrentVisitStats}
				/>

				{/* pendingTask */}
				<PrivateRoute path={Routes.pendingTasks} component={PendingTasks} />

				{/* AVCLoginPage */}
				{/* <Route path={Routes.avcLogin} component={AVCLoginPage} /> */}

				{/* AVCSupportPage */}
				{/* <Route path={Routes.avcSupport} component={SupportPages} /> */}

				{/* AVCLandingPage */}
				{/* <AvcPrivateRoute path={Routes.avcLanding} component={MainViewAVC} /> */}

				{/* VideoCall */}
				<Route path={Routes.videoCall} component={VideoCall} />

				{/* WorkListParent */}
				<PrivateRoute path={Routes.workList} component={WorkListParent} />

				{/* Billing Details */}
				<PrivateRoute path={Routes.billingDetails} component={CashierBillingDetails} />

				{/* Billing Summary */}
				<PrivateRoute path={Routes.billingSummary} component={CashierBillingSummary} />

				{/* For unknow/non-defined path */}
				<Route path="*" component={NotFound} />
			</Switch>
		</Router>
	);
};

export default RouterApp;
