import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Div, Row, Text, Divider, TapSelect } from "qdm-component-library";
import BillSummary from "../../assets/img/bill_summary.svg";
import { UIColor } from "../../utils";
const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding:16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  TapSelect: {
    "& div": {
      "& span": {
        fontSize: 12,
        fontFamily: "pc_medium !important",
      },
    },
  },
});

class DoctorAndAppointmentSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { classes, parent_id } = this.props;
    return (
      <Card
        id={`${parent_id}_billsummary_parent_card`}
        style={{
          boxShadow: "none",
          border: "1.5px solid #E0E0E0",
          borderRadius: 8,
        }}
      >
        <CardActions
          id={`${parent_id}_billsummary_parent_cardactions`}
          onClick={this.handleExpandClick}
          disableSpacing
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingBottom: 0,
            cursor: "pointer",
          }}
        >
          <img
            id={`${parent_id}_billsummary_img`}
            alt="BillSummary"
            src={BillSummary}
            style={{ marginRight: 12 }}
          />
          <Text
            id={`${parent_id}_billsummary_text`}
            key={"0"}
            name="Bill Summary"
            style={{ fontSize: "16px", color: UIColor.primaryText.color,letterSpacing:0 }}
            className={"pc_semibold"}
          />

          <IconButton
            id={`${parent_id}_billsummary_showmore_iconbutton`}
            className={clsx(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            aria-expanded={this.state.expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon
              id={`${parent_id}_billsummary_expandmoreicon`}
            />
          </IconButton>
        </CardActions>
        <Collapse
          id={`${parent_id}_billsummary_collapse`}
          className={classes.body}
          in={this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <Div
            id={`${parent_id}_billsummary_billing_div`}
            key={"0"}
            className="qdm"
            style={{
              backgroundColor: UIColor.differentiationBackground.color,
              borderColor: UIColor.lineBorderFill.color,
              borderWidth: "1px",
              borderRadius: "8px",
              padding: "16px",
            }}
          >
            <Row
              id={`${parent_id}_billsummary_billing_row`}
              key={"0"}
              alignItems="normal"
              direction="row"
              justify="space-between"
              spacing="2"
            >
              <Text
                id={`${parent_id}_billsummary_billing_details_text`}
                key={"0"}
                name="Billing Detail"
                style={{
                  textTransform: "uppercase",
                  color: "#6F6F6F",
                  fontSize: "12px",
                  letterSpacing: "0px",
                }}
                className={"pc_medium"}
              />

              <Text
                id={`${parent_id}_billsummary_price_text`}
                key={"1"}
                name="Price"
                style={{
                  color: "#6F6F6F",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "0px",
                }}
                className={"pc_medium"}
              />
            </Row>

            <Row
              id={`${parent_id}_billsummary_total_row`}
              key={"1"}
              alignItems="stretch"
              direction="row"
              justify="space-between"
              spacing="0"
            >
              <Divider
                id={`${parent_id}_billsummary_bottom_divider`}
                key={"0"}
                textWidth="50"
                textColor="#000"
                borderColor="lightgray"
                borderWidth="1"
                orientation="horizontal"
                variant="middle"
                style={{ padding: "0px", margin:'8px 0px' }}
              />

              <Row
                id={`${parent_id}_billsummary_total_content_row`}
                key={"1"}
                alignItems="normal"
                direction="row"
                justify="space-between"
                spacing="2"
                style={{ width: "100%" }}
              >
                <Text
                  id={`${parent_id}_billsummary_Total_Charges_text`}
                  key={"0"}
                  name="Total Charges"
                  style={{ letterSpacing:0, fontSize: "14px" }}
                  className={"pc_medium"}
                />

                <Text
                  id={`${parent_id}_billsummary_amount_text`}
                  key={"1"}
                  name="₹780.00"
                  style={{ letterSpacing:0, fontSize: "14px" }}
                  className={"pc_medium"}
                />
              </Row>
            </Row>
          </Div>

          <Row
            id={`${parent_id}_billsummary_button_row`}
            key={"1"}
            alignItems="stretch"
            direction="row"
            justify="space-between"
            spacing="2"
            style={{
              padding:'12px 0px 16px'
            }}
          >
            <Div
              id={`${parent_id}_billsummary_+Add_div`}
              key={"0"}>
              <Text
                id={`${parent_id}_billsummary_+Add_Insurance_text`}
                key={"0"}
                name="+ Add Insurance"
                style={{
                  color: "#38C20A",
                  fontSize: "14px",
                  letterSpacing:0
                }}
                className={"pc_medium"}
              />
            </Div>

            <Div
              id={`${parent_id}_billsummary_View_Split_div`}
              key={"1"}>
              <Text
                id={`${parent_id}_billsummary_View_Split_text`}
                key={"0"}
                name="View Split Up"
                style={{
                  color: UIColor.secondaryButtonColor.color,
                  fontSize: "14px",
                  marginRight: "",
                  letterSpacing:0
                }}
                className={"pc_medium"}
              />
            </Div>
          </Row>

          <Div
            id={`${parent_id}_billsummary_View_Split_bottom_div`}
            key={"2"}
            className="qdm"
            style={{ padding: "0px", margin: "0px", marginBottom: "0" }}
          >
            <Divider
              id={`${parent_id}_billsummary_View_Split_divider`}
              key={"0"}
              textWidth="100"
              textColor="#000"
              borderColor="lightgray"
              borderWidth="1"
              orientation="horizontal"
              variant="fullWidth"
              style={{ padding: "0px",margin:0,marginBottom:16 }}
              className={classes.hrtag}
            />

            <Div
              id={`${parent_id}_billsummary_payment_div`}
              key={"1"}>
              <Text
                id={`${parent_id}_billsummary_payment_text`}
                key={"0"}
                name="Payment Mode"
                style={{ fontSize: "14px", letterSpacing:0 }}
                className={"pc_regular"}
              />
            </Div>

            <TapSelect
              id={`${parent_id}_billsummary_mode_tapselect`}
              key={"1"}
              label=""
              type="single"
              fullWidth={true}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor="#ffffff"
              textSize="medium"
              iconHeight="20"
              iconWidth="20"
              textColor={UIColor.primaryText.color}
              options={[
                {
                  label: "Pay At Hospital",
                  value: "pay_at_hospital",
                },
                {
                  label: "Pay Online",
                  value: "pay_online",
                },
              ]}
              inLineStyles={{
                color: UIColor.primaryText.color,
                borderColor: "#e0e0e0",
                padding: "6px",
                borderRadius: 12,
                margin:'8px 8px 0px 0px'
              }}
              className={classes.TapSelect}
              defaultSelected={{
                  label: "Pay At Hospital",
                  value: "pay_at_hospital",
                }}
            />
          </Div>
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(DoctorAndAppointmentSlot))
);
