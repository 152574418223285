/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { UIColor } from "../../../utils/color";

const useStyles = (theme) => ({
  selectBox: {
    width: "100%",
    height: "35px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      '& fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&:hover fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&.Mui-focused fieldset': {
        // borderColor: UIColor.lineBorderFill.color,
        border: `1px solid ${UIColor.lineBorderFill.color}`
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 9px) scale(1) !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "35px",
      "& input": {
        padding: "0.5px 4px !important",
        color: UIColor.secondaryText.color
      },
    },
  },
  textColor: {
    color: UIColor.secondaryText.color,
    padding: "10px" 
  }
});

class SelectBoxOnSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      serachIcon,
      Optionlabel = "label",
      placeholder,
      classes,
      onchange,
      classname,
      list,
      value,
      title,
      top_title,
      disableClearable,
      requireStart,
      error
    } = this.props;
    return (
      <React.Fragment>
        {top_title && <Typography variant="caption" className="pc_regular" style={{color: UIColor.tertiaryText.color}}>{top_title}{requireStart && <span
            style={{
              color: UIColor.error.color,
              marginLeft: 5
            }}>*</span>}</Typography>}
        {serachIcon ? (
          <Autocomplete
            options={list}
            className={`${classes.selectBox} ${value ? "Mui-focused" : ""} ${
              classname ? classname : ""
            }`}
            onChange={onchange}
            getOptionLabel={(option) => option[Optionlabel]}
            style={{ width: "100%", height: "35px", marginTop: "4px" }}
            value={value}
            popupIcon={
              <InputAdornment position="end">
                <SearchIcon style={{ color: UIColor.secondaryText.color }} />
              </InputAdornment>
            }
            renderInput={(params) => (
              <TextField
                style={{ height: "35px" }}
                placeholder={placeholder}
                {...params}
                label={top_title ? "" : title}
                variant="outlined"
                error={error}
                // helperText={error ? 'Is required' : ''}
              />
            )}
          />
        ) : (
          <Autocomplete
            disableClearable={disableClearable}
            options={list}
            className={`${classes.selectBox} ${value ? "Mui-focused" : ""} ${
              classname ? classname : ""
            }`}
            onChange={onchange}
            onOpen={this.props.onOpen}
            onClose={this.props.onOpen}
            loading={this.props.loading}
            loadingText={"Loading Diagnosis...."}
            getOptionLabel={(option) => option[Optionlabel]}
            style={{ width: "100%", height: "35px", marginTop: "4px" }}
            value={value}
            renderInput={(params) => (
              <TextField
                style={{ height: "35px" }}
                onChange={(e) => this.props.onTextfieldChange(e.target.value)}
                placeholder={placeholder}
                {...params}
                label={top_title ? "" : title}
                variant="outlined"
                error={error}
                // helperText={error ? 'Is required' : ''}
              />
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(SelectBoxOnSearch);
