import React from "react";
// div,
// import { LocationIcon } from "../../../assets";
// NextArrow
import { TextFieldAdornment } from "./textFieldAdornment";
import { loadScript } from "./loadScriptFile";
import "./index.css";

let autoComplete;

class SearchLocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.autoCompleteRef = React.createRef(null);
  }
  componentDidMount() {

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBqeACJgp12OLU6EkHeUtFgya2naH1LhrU&libraries=places`,
      () => this.handleScriptLoad(this.autoCompleteRef)
    );
  }

  render() {
    const {
      handleChange,
      value,
      label = "Location",
      error = false,
      errorText = "error",
      isReadonly,
      isrequired
    } = this.props;
    // openDialogMap
    // const { classes } = this.props;
    const { parent_id } = this.props

    this.handleScriptLoad = (autoCompleteRef) => {

      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
      );
      // REFER THIS FOR PLACES FIELDS
      // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
      autoComplete.setFields([
        "address_components",
        "formatted_address",
        "geometry"
      ]);
      autoComplete.addListener("place_changed", () => {

        const addressObject = autoComplete?.getPlace();

        if (addressObject) {
          let _obj = {};
          _obj["address"] = addressObject?.formatted_address;
          _obj["latitude"] = addressObject?.geometry?.location?.lat();
          _obj["longitude"] = addressObject?.geometry?.location?.lng();
          _obj["city"] = (addressObject?.address_components?.find(comp => comp?.types?.includes("locality"))?.long_name || addressObject?.address_components?.[1]?.long_name || "");
          _obj["location"] = addressObject?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_2") || comp?.types?.includes("administrative_area_level_1")).long_name || addressObject?.address_components?.[0]?.long_name || "";
          _obj["locality"] = addressObject?.address_components?.find(comp => !!comp?.types?.find(c => c?.includes('sublocality')))?.long_name
          this.props.handleChange(_obj);
          // console.log(autoComplete, "addressObject");
        }

      }
      );
    }
    this.giveMeLabel = () => {

      return <div variant="body1">{label} {isrequired && <div variant="caption" style={{ color: "red" }}>*</div>}</div>
    }

    return (
      <div
        id={`${parent_id}_search_parent_div`}
      >
        <TextFieldAdornment
          parent_id={this.props.parent_id}
          inputRef={this.autoCompleteRef}
          handleChange={(e) => handleChange(e.target.value)}
          value={value}
          inputLabel={this.giveMeLabel()}
          // adornment={<LocationIcon />}
          error={error}
          errorText={errorText}
          active={this.props.active}
          isReadonly={isReadonly}
        />

      </div>
    );
  }
}

export default SearchLocationInput;
