/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the Context used in the application
 */

import React from "react";
import { Themes, AlertProps, DrawerProps } from "../utils";

/**
 * ThemeContext store the current theme of the app,which is provided
 * at the /src/App.js using the /src/App.theme.js.
 */
export let ThemeContext = React.createContext({
	name: Themes.default,
	setTheme: () => null,
});

/**
 * AuthContext store some basic detail of the user when the user logged
 * into the application, which is provided at the /src/App.js using
 * the /src/App.auth.js.
 */
export let AuthContext = React.createContext({
	user: {},
	setAuth: () => null,
});

/**
 * AlertContext store the props which are neccessary to show the Alert component,
 * which is provided at the /src/App.js using the /src/App.alert.js.
 */
export let AlertContext = React.createContext({
	open: false,
	severity: AlertProps.severity.success,
	msg: "",
	vertical: AlertProps.vertical.top,
	horizontal: AlertProps.horizontal.center,
	onclose: () => null,
	setSnack: () => null,
});

/**
 * DialogContext store the props of a Dialog, which is provided at the /src/App.js using
 * the /src/App.dialog.js
 */
export let DialogContext = React.createContext({
	open: true,
	title: "",
	body: "",
	positiveBtn: "Ok",
	negativeBtn: "Cancel",
	onOk: () => null,
	setDialog: () => null,
});

/**
 * BackdropContext store the props of the Backdrop Component, which is provided at the /src/App.js using
 * the /src/App.backdrop.js
 */
export let BackdropContext = React.createContext({
	open: true,
	message: "",
	setBackDrop: () => null,
});

/**
 * DrawerContext store the props of the Drawer component, which is provided at the /src/App.js using
 * the /src/App.drawer.js
 */
export const DrawerContext = React.createContext({
	open: true,
	direction: DrawerProps.direction.right,
	variant: DrawerProps.variant.temporary,
	onClose: () => null,
	setDrawer: () => null,
});

/**
 * patient registration  form values | patient registration
 */
export const PatientRegContext = React.createContext({
	identification: [],
	names: [],
	details: {},
	communication: {},
	contact: {},
	address: [],
	setPatientData: () => null,
});

/**
 * next of kin form values | patient registration
 */
export const NextOfKinContext = React.createContext({
	identification: [],
	names: [],
	details: {},
	contact: {},
	address: [],
	addformdetails: [],
	index: null,
	setNextOfKin: () => null,
});

/**
 * visit details form values | visit registration
 */
export const VisitDetails = React.createContext({
	encounter: {},
	practitioner: {},
	device: {},
	helthcare: {},
	accompained: {},
	setVisitDetails: () => null,
});

/**
 * referral  details form values | referral registration
 */
export const ReferralDetails = React.createContext({
	letter: {},
	from: {},
	to: {},
	details: {},
	note: {},
	setReferralDetails: () => null,
});

/**
 * referral  details form values | referral registration
 */
export const FinancialFormContext = React.createContext({
	payer: {},
	validity: {},
	membership: {},
	financial: {},
	applicable: {},
	savedata: [],
	index: null,
	setFinancialData: () => null,
});

export const DataPayloadContext = React.createContext({
	additnaldata: {},
	birthdetails: {},
	setadditnaldata: () => null,
});

export const QueueManagementContext = React.createContext({
	allData: [],
	data: [],
	onHoldData: [],
	previousData: [],
	displayData: [],
	displayPreviousData: [],
	setAllData: () => {},
	setData: () => {},
	setOnHoldData: () => {},
	setPreviousData: () => {},
	setDisplayData: () => {},
	setDisplayPreviousData: () => {},
});

export const VisitFormContext = React.createContext({
	encounterInfo: {},
	bookingdetails: {},
	pactitioner: {},
	deviceDetails: {},
	reasonDetails: {},
	setvisitform: () => null,
});

export const CheifComplaintsContext = React.createContext({
	list: [],
	editIndex: null,
	setCheifComplaints: () => null,
});

export const DiagnosisContext = React.createContext({
	list: [],
	editIndex: null,
	setDiagnosis: () => null,
});

export const VitalsContext = React.createContext({
	list: [],
	editIndex: null,
	isUpdate: null,
	setVitalsContext: () => null,
});
export const AllergyContext = React.createContext({
	list: [],
	editIndex: null,
	setAllergy: () => null,
});

export const MedicationContext = React.createContext({
	list: [],
	editIndex: null,
	setMedication: () => null,
});
export const TreatmentContext = React.createContext({
	list: [],
	editIndex: null,
	child: {},
	setTreatment: () => null,
});
export const LabOrderContext = React.createContext({
	list: [],
	editIndex: null,
	child: {},
	setLabOrder: () => null,
});

export const RadiologyContext = React.createContext({
	list: [],
	editIndex: null,
	setRadiology: () => null,
});

export const RoleContext = React.createContext({
	role: null,
	setData: () => null,
});

export const DoccumentContext = React.createContext({
	list: [],
	editIndex: null,
	setDoccument: () => null,
});

export const AVCShowContext = React.createContext({
	avcList: {},
	setAVCList: () => null,
});

// Meeting Drawer Context
export const DrawerMeetingContext = React.createContext({
	open: false,
	direction: DrawerProps.direction.right,
	variant: DrawerProps.variant.temporary,
	RoomName: "",
	jwt: "",
	widthAdjState: "side",
	user_name: "You",
	onClose: () => null,
	setMeetingDrawer: () => null,
});

/* 
Firebase context is for getting a token,deleting a token and requesting permission.
**/
export let FirebaseContext = React.createContext({
	token: "",
	isTokenFound: false,
	getToken: () => false,
	requestPermission: () => false,
	deleteToken: () => false
  })
  