import React from "react";
import { Div, Select, Col, Row, Paper, H6, TextInput } from 'qdm-component-library'
import "./style.css"
import { withAllContexts } from "../../HOCs";
import { UIColor } from "../../utils";

function ApplicableCriteria(props) {

    const styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        selectBorderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
    }

    const { applicable } = props.financialData

    const setDetails = (name, value) => {
        let patientData = props.financialData
        patientData.applicable[name] = value;
        patientData.setFinancialData({
            ...patientData,
        })
    }
    const FlterOptions = (type) => {
        const { allMasters } = props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
            };
            lista.push(datatemp);
        }
        return lista
    }
    const { labelStyle, selectBorderStyle } = styles;
    const { parent_id } = props

    return <Div
        id={`${parent_id}_parent_div`}
        className="pv-root" style={{
            margin: '3px 15px'
        }}>
        <Paper
            id={`${parent_id}_parent_paper`}
            className="pl-root">
            <Row
                id={`${parent_id}_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_APPLICABLE_title_div`}
                    >
                        <H6
                            id={`${parent_id}_APPLICABLE_label_h6`}
                            className="en-title space-padding-bottom">APPLICABLE CRITERIA</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_APPLICABLE_parent_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_APPLICABLE_parent_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_APPLICABLE_Encounter_select`}
                                value={applicable?.encounter?.value}
                                options={[
                                    { value: "This Encounter Only" },
                                    { value: "This Episode Of Care Only" },
                                    { value: "Any" },
                                ]}
                                placeholder='Encounter'
                                onChangeValue={e => setDetails("encounter", e)} label="Encounter" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_APPLICABLE_Encounter_type_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <TextInput
                                id={`${parent_id}_APPLICABLE_Encounter_type_textinput`}
                                value={applicable.encounter_type}
                                placeholder='Encounter type'
                                onChange={e => setDetails("encounter_type", e.target.value)} label="Encounter Type" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_APPLICABLE_Speciality_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_APPLICABLE_Speciality_select`}
                                value={applicable.speciality?.value}
                                options={FlterOptions('SPECIALTY')} onChangeValue={e => setDetails("speciality", e)} label="Specialty" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Paper>
    </Div>
}

export default withAllContexts(ApplicableCriteria);