import React from 'react';
// webDataRocksTable
import 'webdatarocks/webdatarocks.css';
import * as WebDataRocksReact from 'react-webdatarocks';

export class WebDataRocksTable extends React.Component {

    myRef = null;
  
    constructor(props) {
      super(props);
      this.myRef = React.createRef();
    }
  
    reportComplete = () => {
      console.log(">>>>>", this.myRef.webdatarocks.getReport());
    }
    
    customizeToolbar(toolbar) {
      var tabs = toolbar.getTabs(); // get all tabs from the toolbar
      toolbar.getTabs = function() {
        [0,1,2].map(_=>delete tabs[_])
          return tabs;
      }
  }

    render() {
    
    (this?.myRef?.webdatarocks) && this.myRef.webdatarocks.setReport(this.props.report); //setreport
    
      return (
      <div>
        <WebDataRocksReact.Pivot 
          ref={(elem) => {
            this.myRef = elem
          }} 
          toolbar={true} 
          report={this.props?.report} 
          customizeCell={this.props?.customizeCellFunction}
        //   report="https://cdn.webdatarocks.com/reports/report.json" 
          reportcomplete={() => {
            this.reportComplete();
          }}
          cellclick={(cell)=>{
            this.props?.cellClick && this.props.cellClick(cell)
          }}
          beforetoolbarcreated={this.customizeToolbar}
        />
      </div>
      );
    }
  }

