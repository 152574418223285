/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import {
  Row,
  Col,
  Text,
  H6,
  Avatar,
  MonthCalendar,
  Div,
  Select,
  TapSelect,
  TextInput,
  TextArea,
  Button,
  Card,
  Checkbox,
} from "qdm-component-library";
import {
  LeftArrowIcon,
  CalendarIcon,
  SelectBox,
  SelectBoxOnSearch,
} from "../../../components";
import { Popover, Typography } from "@material-ui/core";
import iconimg from "../../../assets/img/icons8-health-checkup.svg";
import userimg from "../../../assets/img/user.png";
import { classes } from "istanbul-lib-coverage";
import { getEvalValue } from "casbin/lib/cjs/util";
import CloseIcon from "@material-ui/icons/Close";
import Calendar from "react-calendar";
import moment from "moment";
import InfoStatus from "../cheifComplaintsFlow/infoStatus";
import { withAllContexts } from "../../../HOCs";
import { useSelector } from "react-redux";
import diagnosis from "./diagnosis";
import { AlertProps, makeName, UIColor, isIntegerValue } from "../../../utils";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import CustomInputDatePicker from "../../../components/customDatePicker/inputDatePicker";
import CustomDatePicker from "../../../components/customDatePicker";
const onserOption = [
  { value: "Day", label: "Day" },
  { value: "Month", label: "Month" },
  { value: "Year", label: "Year" },
];

const verificationOption = [
  { value: "Confirmed", label: "Confirmed" },
  { value: "Unconfirmed", label: "Unconfirmed" },
];

const clinicalStatusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Resolved", label: "Resolved" },
];

const DiagnonsisForm = (props) => {
  const dispatch = useDispatch();
  const { list, setDiagnosis, editIndex } = props.Diagnosis;
  const storeData = useSelector((state) => state?.DiagnosisMasterSlice);
  const [openStatus, setOpenstatus] = React.useState(null);
  const [statusType, setStatusType] = React.useState(true);
  const [Selectopen, setSelectOpen] = React.useState(false);
  const loading =
    Selectopen && storeData?.Diagnosis_masters?.data?.length === 0;
  const handleStatusInactive = () => {
    setStatusType(false);
  };

  const handleStatusActive = () => {
    setStatusType(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event, type) => {
    setAnchorEl1(event.currentTarget);
  };

  // const [state, setState] = React.useState({
  //   onserOption: {},

  //   onsetcalenderShow: true,
  //   abatementcalenderShow: true,

  //   onset: {
  //     day: "",
  //     freq: "",
  //     currentDate: "",
  //     isCalenderEnabled: false,
  //     calenderIconEnabled: true,
  //   },
  //   abatement: {
  //     day: "",
  //     freq: "",
  //     currentDate: "",
  //     isCalenderEnabled: false,
  //     calenderIconEnabled: true,
  //   },
  // });
  // console.log(list[editIndex]);

  const [state, setState] = React.useState(
    editIndex !== undefined && editIndex !== null
      ? list[editIndex]
      : props?.formComponet?.id ?? {
        onset: {
          day: "",
          freq: "",
          currentDate: "",
          isCalenderEnabled: false,
          calenderIconEnabled: true,
        },
        abatement: {
          day: "",
          freq: "",
          currentDate: "",
          isCalenderEnabled: false,
          calenderIconEnabled: true,
        },
      }
  );

  const handleClose = (get) => {
    if (get) {
      setAnchorEl(null);
      setAnchorEl1(null);
    }
  };

  const calenderDataClear = () => {
    state.onset["isCalenderEnabled"] = false;
    setState({
      ...state,
    });
  };

  const calenderDataClear1 = () => {
    state.abatement["isCalenderEnabled"] = false;
    setState({
      ...state,
    });
  };

  const calenderChange = (val) => {
    state.onset["currentDate"] = moment(val).format("DD MMM, YYYY");
    state.onset["isCalenderEnabled"] = true;
    setState({
      ...state,
    });
    handleClose(true);
  };

  const calenderChange1 = (val) => {
    state.abatement["currentDate"] = moment(val).format("DD MMM, YYYY");
    state.abatement["isCalenderEnabled"] = true;
    setState({
      ...state,
    });
    handleClose(true);
  };

  const openStausInfo = (e) => {
    setOpenstatus(e?.currentTarget ?? null);
    console.log("e", e.target);
  };
  const handleStausClose = () => {
    setOpenstatus(null);
  };

  const styles = {
    subText: {
      fontSize: 10,
      color: UIColor.secondaryText.color
  },
  text: {
      fontSize: 12,
      color: UIColor.secondaryText.color
  },
    headerText: {
      fontSize: 14,
      color: "#055CA7",
      fontWeight: 600,
      paddingLeft: "8px",
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexDirection: "row",
    },
    flexStart: {
      display: "flex",
      //   justifyContent: "flex-start",
      alignItems: "center",
    },
    backIcon: {
      background: UIColor.lineBorderFill.color,
      padding: "9px 13px 9px 11px",
      borderRadius: "50px",
      display: "flex",
      marginRight: 14,
      cursor: "pointer",
    },
    errorborderStyle:{
      borderRadius: 8,
      borderColor: 'red',
    },
    headBorder: {
      //borderBottom: "1px solid #E0E0E0",
      padding: "6px 0px",
      background:"#fff",
      borderRadius:"10px"
    },
    labelStyle: {
      color: UIColor.tertiaryText.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
    },
    text_border_Style: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
      color: UIColor.secondaryText.color
    },
    colPadding: {
      padding: "7px",
    },
    tapSelectStyle: {
      padding: "4px 8px",
      borderColor: UIColor.lineBorderFill.color,
      margin: "4px 10px 6px 0px",
      borderRadius: "8px",
      minWidth: "auto",
      margin: "0px 6px 6px 0px",
      fontSize: "14px !important",
    },
    buttonTextStyle: {
      color: UIColor.primaryColor.color,
      fontWeight: 600,
      padding: "4px",
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cancelBtn: {
      padding: "8px",
      fontSize: 14,
      marginRight: 10,
      borderColor: UIColor.lineBorderFill.color,
      color: UIColor.primaryColor.color,
      borderRadius: 8,
    },
    filledBtn: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      padding: "8px",
      fontSize: 14,
      borderRadius: 8,
    },
    gradeCard: {
      background: UIColor.greyBackground.color,
      padding: "12px",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      boxShadow: "none",
    },
    calenderIconstyles: {
      padding: "2px 7px",
      margin: "3px 0px 5px 0px",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      cursor: "pointer",
    },
    dot: {
      height: "25px",
      width: "25px",
      backgroundColor: "#bbb",
      borderRadius: "50%",
      display: "inline-block",
    },
    headercolumn1: {
      display: "flex",
      borderRadius: "50px",
      background: UIColor.lineBorderFill.color,
      justifyContent: "space-between",
    },
    active: {
      margin: "0 15px 0 10px",
      // color: "#38C20A",
    },
    header: {
      borderRadius: "23px",
      // backgroundColor: "#f0f0f0",
      // border: "1px solid #e0e0e0",
      display: "flex",
      textAlign: "right",
      justifyContent: "flex-end",
    },
    header1: {
      borderRadius: "23px",
      // backgroundColor: "#f0f0f0",
      // border: "1px solid #e0e0e0",
      display: "flex",
      width: "100%",
    },
    iconWrapper: {
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      marginLeft: "8px",
    },
    text: {
      fontSize: 14,
    },
    text_: {
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "140px",
    },
    userPic: {
      width: 34,
      height: 34,
      marginLeft: 12,
      fontSize: 14,
      // borderRadius: 4,
    },
    bullet: {
      width: 6.5,
      height: 6.5,
      borderRadius: "50%",
      // backgroundColor: "#38C20A",
    },
    textColor: {
      color: UIColor.secondaryText.color,
      padding: "10px"
    }
  };

  const goBack = () => {
    props.onCancel();
  };

  const handleInputChange = (selector, val) => {
    setState({
      ...state,
      [selector]: val,
    });
  };
  const onTextfieldChange = async (v) => {
    if (v.length > 3) {
      await dispatch(actions.DIAGNOSIS_MASTERS({ input_text: v }));
    }
  };
  const [isErr,setIsErr] = React.useState(false)
  const handleUpdate = async () => {
    // let newList = list;

    // newList[editIndex] = state;

    // setDiagnosis({
    //   ...props.Diagnosis,
    //   list: newList,
    //   editIndex: null,
    // });
    if (state.rank
       && state.codeSet
       && state.diagnosis !== null 
       && state.clinicalStatus !== null 
       && state.use !== null 
       && Object.keys(state.diagnosis).length !== 0
       && Object.keys(state.clinicalStatus).length !== 0
       && Object.keys(state.use).length !== 0) {
      await props.saveDiagnosis([state], editIndex);
      goBack();
    } else {
      setIsErr(true)
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }

    let updatedDiagnosis = {
      updatedDiagnosis: state,
    };

  };
  console.log("diagnosNAme", state);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <Div id={`${parent_id}_diagnosis_parent_div`}>
      {/* Header */}

      <Row
        id={`${parent_id}_diagnosis_parent_row`}
        inLineStyles={styles.headBorder}
      >
        <Col
          id={`${parent_id}_diagnosis_parent_col`}
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={10}
          style={{ display: "flex", paddingRight: "0px", borderRight: `2px solid ${UIColor.lineBorderFill.color}`, margin: "4px 0px" }}
        >
          <div id={`${parent_id}_diagnosis_header_div`} style={styles.header1}>
            <div
              id={`${parent_id}_diagnosis_header_image_div`}
              style={styles.flexStart}
            >
              {/* <Text inLineStyles={styles.backIcon} onClick={() => goBack()}>
                <LeftArrowIcon />
              </Text> */}
              <Div
                id={`${parent_id}_diagnosis_header_image_div`}
                style={{ ...styles.iconWrapper, backgroundColor: "#d2e4f3" }}
              >
                <img
                  id={`${parent_id}_diagnosis_header_image`}
                  src={iconimg}
                  style={{ width: 14, height: 16 }}
                />
              </Div>
              <Text
                id={`${parent_id}_diagnosis_${
                  state?.diagnosis?.label ?? " ".replaceAll(" ", "_")
                }_text`}
                inLineStyles={styles.headerText}
              >
                {state?.diagnosis?.label}
              </Text>
            </div>
            <div
              id={`${parent_id}_diagnosis_statusinfo_div`}
              style={{ flexGrow: 1 }}
            ></div>
            <div
              id={`${parent_id}_diagnosis_statusinfo_sub_div`}
              style={{ cursor: "pointer", ...styles.flexStart }}
              onClick={(e) => openStausInfo(e)}
            >
              <Div
                id={`${parent_id}_diagnosis_statusinfo_active_div`}
                style={{
                  backgroundColor: statusType ? UIColor.success.color : UIColor.secondaryColor.color,
                  ...styles.bullet,
                }}
              />
              <Text
                id={`${parent_id}_diagnosis_${
                  state?.clinicalStatus?.label.replaceAll(" ", "_") ?? "Active"
                }_text`}
                style={{
                  color: statusType ? UIColor.success.color : UIColor.secondaryColor.color,
                  ...styles.active,
                }}
              >
                {state?.clinicalStatus?.label ?? "Active"}
                {/* {statusType ? "Active" : "Inactive"} */}
              </Text>
            </div>
          </div>
        </Col>
        <Col
          id={`${parent_id}_diagnosis_parent_div`}
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={2}
          style={{ textAlign: "right", justifyContent: "flex-end" }}
        >
          <div
            id={`${parent_id}_diagnosis_${state?.createdAt}_div`}
            style={styles.header}
          >
            <div>
              <Text
                id={`${parent_id}_diagnosis_${state?.createdAt}_text`}
                inLineStyles={styles.subText}
              >
                {state?.createdAt} - Created
              </Text>
              <H6
                id={`${parent_id}_diagnosis_${state?.createdName?.text}_h6`}
                inLineStyles={styles.text_}
              >
                {makeName(state?.createdName || {}) + " (You)"}
              </H6>
            </div>
            <div
              id={`${parent_id}_diagnosis_user_image_div`}
              style={{ marginTop: "5px" }}
            >
              <Avatar
                id={`${parent_id}_diagnosis_user_avatar`}
                variant={"circle"}
                inLineStyles={styles.userPic}
                style={{ margin: "0px 8px" }}
              >
                <img id={`${parent_id}_diagnosis_user_image`} src={userimg} />
              </Avatar>
            </div>
          </div>
        </Col>
      </Row>

      {/* Main content */}
      <div
        id={`${parent_id}_diagnosis_content_div`}
        style={{ height: "calc(100vh - 296px)", overflow: "auto", background: "#fff", borderRadius: "10px", margin: "10px 0px"}}
      >
        <Row id={`${parent_id}_diagnosis_content_row`} style={{ padding: 7 }}>
          <Col
            id={`${parent_id}_diagnosis_content_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_diagnosis_${state?.use}_selectbox`}
              disableClearable
              requireStart
              top_title={"Use"}
              list={storeData?.Use_masters?.data ?? []}
              placeholder={"Select..."}
              value={state?.use}
              onchange={(e, value) => handleInputChange("use", value)}
              error={(isErr && !state?.use) ? true : false}
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_rank_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_diagnosis_${state?.rank}_selectbox`}
              disableClearable
              top_title={"Rank"}
              requireStart
              list={storeData?.Diagnosis_rank_masters?.data ?? []}
              placeholder={"Select..."}
              value={state?.rank}
              onchange={(e, value) => handleInputChange("rank", value)}
              error={(isErr && !state?.rank) ? true : false}
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_codeSet_col`}
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            inLineStyles={styles.colPadding}
          >
            <Typography
              id={`${parent_id}_select_Codeseta_typography`}
              variant="caption"
              className="pc_regular"
              style={{color: UIColor.tertiaryText.color}}
            >
              Codeset
              <span
                id={`${parent_id}_diagnosis_Codeset_textinput`}
                style={{
                  color: UIColor.error.color,
                  marginLeft: 5,
                }}
              >
                *
              </span>
            </Typography>
            <TextInput
              id={`${parent_id}_diagnosis_${state?.codeSet}_textinput`}
              name={"codeSet"}
              placeholder=""
              labelStyle={styles.labelStyle}
              hoverColor={UIColor.lineBorderFill.color}
              style={(isErr && !state?.codeSet) ? styles.errorborderStyle : styles.text_border_Style}
              value={state?.codeSet}
              onChange={(event) =>
                handleInputChange(event.target.name, event.target.value)
              }
              error={(isErr && !state?.codeSet) ? true : false}
              // helperText={(isErr && !state?.codeSet) ? 'Is required' : ''}
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_Description_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={styles.colPadding}
          >
            <SelectBoxOnSearch
              id={`${parent_id}_diagnosis_${state?.diagnosis}_selectbox`}
              onOpen={() => {
                setSelectOpen(true);
              }}
              onClose={() => {
                setSelectOpen(false);
              }}
              loading={loading}
              requireStart
              disableClearable
              top_title={"Diagnosis Code & Description"}
              list={storeData?.Diagnosis_masters?.data ?? []}
              placeholder={"Select..."}
              value={state?.diagnosis}
              onchange={(e, value) => handleInputChange("diagnosis", value)}
              onTextfieldChange={onTextfieldChange}
              error={(isErr && ( Object.keys(state.diagnosis).length === 0)) ? true : false}
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_Work_related_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <Row id={`${parent_id}_diagnosis_Work_related_row`}>
              <Col
                id={`${parent_id}_diagnosis_Work_related_div`}
                xs={12}
                sm={12}
                md={5}
                lg={5}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_diagnosis_Work_related_text`}
                  inLineStyles={styles.labelStyle}
                >
                  {"Work related"}
                </Text>
                <Checkbox
                  id={`${parent_id}_diagnosis_Work_related_checkbox`}
                  color={UIColor.secondaryButtonColor.color}
                  inLineStyles={{ marginTop: 10 }}
                  checked={state?.workRelated}
                  label={""}
                  name={""}
                  value={state?.workRelated}
                  onChange={(value) =>
                    setState({ ...state, workRelated: !state.workRelated })
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_diagnosis_confidential_col`}
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_diagnosis_confidential_text`}
                  inLineStyles={styles.labelStyle}
                >
                  {"Confidential"}
                </Text>
                <Checkbox
                  id={`${parent_id}_diagnosis_confidential_checkbox`}
                  color={UIColor.secondaryButtonColor.color}
                  inLineStyles={{ marginTop: 10 }}
                  checked={state?.confidential}
                  label={""}
                  name={""}
                  value={state?.confidential}
                  onChange={(value) =>
                    setState({ ...state, confidential: !state.confidential })
                  }
                />
              </Col>
              <Col
                id={`${parent_id}_diagnosis_Notifiable_col`}
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={4}
                inLineStyles={styles.colPadding}
              >
                <Text
                  id={`${parent_id}_diagnosis_Notifiable_text`}
                  inLineStyles={styles.labelStyle}
                >
                  {"Notifiable"}
                </Text>
                <Checkbox
                  id={`${parent_id}_diagnosis_Notifiable_checkbox`}
                  color={UIColor.secondaryButtonColor.color}
                  inLineStyles={{ marginTop: 10 }}
                  checked={state?.notifiable}
                  label={""}
                  name={""}
                  value={state?.notifiable}
                  onChange={(value) =>
                    setState({ ...state, notifiable: !state.notifiable })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col
            id={`${parent_id}_diagnosis_verification_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_diagnosis_verification_selectbox`}
              disableClearable
              top_title={"Verification Status"}
              list={storeData?.Verification_masters_dianosis?.data ?? []}
              placeholder={"Select..."}
              value={state?.verification}
              onchange={(e, value) => handleInputChange("verification", value)}
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_clinicalStatus_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_diagnosis_clinicalStatus_selectbox`}
              disableClearable
              requireStart
              top_title={"Clinical Status"}
              list={storeData?.Clinical_masters?.data ?? []}
              placeholder={"Select..."}
              value={state?.clinicalStatus}
              onchange={(e, value) =>
                handleInputChange("clinicalStatus", value)
              }
            />
          </Col>
          {state.onset?.isCalenderEnabled === true ? (
            <Col
              id={`${parent_id}_diagnosis_Onset_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Row id={`${parent_id}_diagnosis_Onset_row`}>
                <Col
                  id={`${parent_id}_diagnosis_Onset_col`}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  inLineStyles={styles.colPadding}
                >
                  <Text inLineStyles={styles.labelStyle}>{"Onset"}</Text>
                  <TextInput
                    id={`${parent_id}_diagnosis_Onset_currentDate_textinput`}
                    label={""}
                    variant={"outlined"}
                    style={styles.text_border_Style}
                    hoverColor={UIColor.lineBorderFill.color}
                    placeholder=""
                    value={state?.onset?.currentDate}
                    disabled
                  />
                </Col>
                <Col
                  id={`${parent_id}_diagnosis_Onset_currentDate_col`}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  inLineStyles={styles.colPadding}
                >
                  <div
                    id={`${parent_id}_diagnosis_calender_div`}
                    style={{ margin: "22px 0px 0px 0px" }}
                  >
                    <Avatar
                      id={`${parent_id}_diagnosis_calender_avatar`}
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => calenderDataClear()}
                    >
                      <CloseIcon
                        id={`${parent_id}_diagnosis_calender_closeicon`}
                      />
                    </Avatar>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              id={`${parent_id}_diagnosis_calenderIconEnabled_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Text
                id={`${parent_id}_diagnosis_Onset_text`}
                inLineStyles={styles.labelStyle}
              >
                {"Onset"}
              </Text>
              <div
                id={`${parent_id}_diagnosis_Onset_day_div`}
                style={{ display: "flex" }}
              >
                <div
                  id={`${parent_id}_diagnosis_Onset_day_textinput_div`}
                  style={{ width: 40, marginRight: 8 }}
                >
                  <TextInput
                    id={`${parent_id}_diagnosis_Onset_day_textinput`}
                    label={""}
                    variant={"standard"}
                    placeholder="05"
                    style={styles.text_border_Style}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.onset?.day}
                    maxLength={2}
                    onChange={(event) => {
                      if (!isIntegerValue(event.target.value)) return;
                      state.onset["day"] = event.target.value;
                      setState({
                        ...state,
                      });
                    }}
                  />
                </div>
                <TapSelect
                  id={`${parent_id}_diagnosis_calenderIconEnabled_tapselect`}
                  label={""}
                  inLineStyles={styles.tapSelectStyle}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor={"#fff"}
                  textSize={"small"}
                  options={onserOption}
                  value={state?.onset?.freq}
                  defaultSelected={state?.onset?.freq}
                  onChange={(value) => {
                    state.onset["freq"] = value;
                    state.onset["calenderIconEnabled"] = value?.value
                      ? false
                      : true;
                    console.log(state);
                    setState({
                      ...state,
                    });
                  }}
                />
                {state.onset["calenderIconEnabled"] && (
                  <div
                    id={`${parent_id}_diagnosis_calenderIcon_div`}
                    style={styles.calenderIconstyles}
                    onClick={handleClick}
                  >
                    <Text id={`${parent_id}_diagnosis_calenderIcon_text`}>
                      <CalendarIcon
                        id={`${parent_id}_diagnosis_calenderIcon`}
                        style={{ marginTop: 4 }}
                      />
                    </Text>
                  </div>
                )}
              </div>
            </Col>
          )}
          {state.abatement?.isCalenderEnabled === true ? (
            <Col
              id={`${parent_id}_diagnosis_Abatement_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Row id={`${parent_id}_diagnosis_Abatement_row`}>
                <Col
                  id={`${parent_id}_diagnosis_Abatement_sub_col`}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  inLineStyles={styles.colPadding}
                >
                  <Text
                    id={`${parent_id}_diagnosis_Abatement_text`}
                    inLineStyles={styles.labelStyle}
                  >
                    {"Abatement"}
                  </Text>
                  <TextInput
                    id={`${parent_id}_diagnosis_Abatement_textinput`}
                    label={""}
                    variant={"outlined"}
                    placeholder=""
                    value={state?.abatement?.currentDate}
                    disabled
                    style={styles.text_border_Style}
                    hoverColor={UIColor.lineBorderFill.color}
                  />
                </Col>
                <Col
                  id={`${parent_id}_diagnosis_Abatement_calender_col`}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  inLineStyles={styles.colPadding}
                >
                  <div
                    id={`${parent_id}_diagnosis_Abatement_calender_div`}
                    style={{ margin: "22px 0px 0px 0px" }}
                  >
                    <Avatar
                      id={`${parent_id}_diagnosis_Abatement_calender_avaatar`}
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => calenderDataClear1()}
                    >
                      <CloseIcon
                        id={`${parent_id}_diagnosis_Abatement_closeicon`}
                      />
                    </Avatar>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              id={`${parent_id}_diagnosis_Abatement_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Text
                id={`${parent_id}_diagnosis_Abatement_text`}
                inLineStyles={styles.labelStyle}
              >
                {"Abatement"}
              </Text>
              <div
                id={`${parent_id}_diagnosis_Abatement_day_div`}
                style={{ display: "flex" }}
              >
                <div
                  id={`${parent_id}_diagnosis_Abatement_day_sub_div`}
                  style={{ width: 40, marginRight: 8 }}
                >
                  <TextInput
                    id={`${parent_id}_diagnosis_Abatement_day_textinput`}
                    label={""}
                    variant={"standard"}
                    placeholder="05"
                    style={styles.text_border_Style}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.abatement?.day}
                    maxLength={2}
                    onChange={(event) => {
                      if (!isIntegerValue(event.target.value)) return;
                      state.abatement["day"] = event.target.value;
                      setState({
                        ...state,
                      });
                    }}
                  />
                </div>
                <TapSelect
                  id={`${parent_id}_diagnosis_Abatement_calenderIconEnabled_tapselect`}
                  label={""}
                  inLineStyles={styles.tapSelectStyle}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor={"#fff"}
                  textSize={"small"}
                  options={onserOption}
                  value={state?.abatement?.freq}
                  defaultSelected={state?.abatement?.freq}
                  onChange={(value) => {
                    state.abatement["freq"] = value;
                    state.abatement["calenderIconEnabled"] = value?.value
                      ? false
                      : true;
                    setState({
                      ...state,
                    });
                  }}
                />
                {state.abatement["calenderIconEnabled"] && (
                  <div
                    id={`${parent_id}_diagnosis_Abatement_calenderIcon_div`}
                    style={styles.calenderIconstyles}
                    onClick={handleClick1}
                  >
                    <Text
                      id={`${parent_id}_diagnosis_Abatement_calenderIcon_text`}
                    >
                      <CalendarIcon
                        id={`${parent_id}_diagnosis_Abatement_calenderIcon`}
                        style={{ marginTop: 4 }}
                      />
                    </Text>
                  </div>
                )}
              </div>
            </Col>
          )}

          <Col
            id={`${parent_id}_diagnosis_Manifestation_col`}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_diagnosis_Manifestation_selectbox`}
              disableClearable
              top_title={"Manifestation"}
              list={storeData?.Manifestation_masters?.data ?? []}
              placeholder={"Select..."}
              value={state?.manifestation}
              onchange={(e, value) => handleInputChange("manifestation", value)}
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_evidenceReference_col`}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            inLineStyles={styles.colPadding}
          >
            <TextInput
              id={`${parent_id}_diagnosis_evidenceReference_textinput`}
              label={"Evidence Reference"}
              name={"evidenceReference"}
              placeholder=""
              labelStyle={styles.labelStyle}
              style={styles.text_border_Style}
              hoverColor={UIColor.lineBorderFill.color}
              value={state?.evidenceReference}
              onChange={(event) =>
                handleInputChange(event.target.name, event.target.value)
              }
            />
          </Col>
          <Col
            id={`${parent_id}_diagnosis_Grade_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <Card
              id={`${parent_id}_diagnosis_Grade_parent_card`}
              inLineStyles={styles.gradeCard}
            >
              <Div id={`${parent_id}_diagnosis_Grade_div`}>
                <H6 id={`${parent_id}_diagnosis_Grade_h6`} className="pi-title">
                  {"Grade"}
                </H6>
              </Div>
              <Row id={`${parent_id}_diagnosis_gradeCode_row`}>
                <Col
                  id={`${parent_id}_diagnosis_gradeCode_col`}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  inLineStyles={styles.colPadding}
                >
                  <SelectBox
                    id={`${parent_id}_diagnosis_gradeCode_selectbox`}
                    disableClearable
                    top_title={"Grade Code"}
                    list={storeData?.Grade_code?.data ?? []}
                    placeholder={"Select..."}
                    value={state?.gradeCode}
                    onchange={(e, value) =>
                      handleInputChange("gradeCode", value)
                    }
                  />
                </Col>
                <Col
                  id={`${parent_id}_diagnosis_gradeType_col`}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  inLineStyles={styles.colPadding}
                >
                  <SelectBox
                    id={`${parent_id}_diagnosis_gradeType_selectbox`}
                    disableClearable
                    top_title={"Grade Type"}
                    list={storeData?.Grade_type?.data ?? []}
                    placeholder={"Select..."}
                    value={state?.gradeType}
                    onchange={(e, value) =>
                      handleInputChange("gradeType", value)
                    }
                  />
                </Col>
                <Col
                  id={`${parent_id}_diagnosis_assesmentReference_col`}
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  inLineStyles={styles.colPadding}
                >
                  <SelectBox
                    id={`${parent_id}_diagnosis_assesmentReference_selectbox`}
                    disableClearable
                    top_title={"Assesment Reference"}
                    list={storeData?.Assesment_masters?.data ?? []}
                    placeholder={"Select..."}
                    value={state?.assesmentReference}
                    onchange={(e, value) =>
                      handleInputChange("assesmentReference", value)
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            id={`${parent_id}_diagnosis_notes_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <TextArea
              id={`${parent_id}_diagnosis_notes_textarea`}
              label={"Notes"}
              name={"notes"}
              placeholder={""}
              labelStyle={styles.labelStyle}
              style={styles.borderStyle}
              hoverColor={UIColor.lineBorderFill.color}
              maxLength=""
              value={state?.notes}
              onChange={(event) =>
                handleInputChange(event.target.name, event.target.value)
              }
            />
          </Col>
        </Row>
      </div>

      {/* footer  */}
      <Row
        id={`${parent_id}_diagnosis_button_row`}
        inLineStyles={{ background: "#fff", borderRadius: "10px" }}
      >
        <Col
          id={`${parent_id}_diagnosis_button_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margin: "10px 0px" }}
        >
          <Div
            id={`${parent_id}_diagnosis_button_div`}
            inLineStyles={styles.flexEnd}
          >
            <Button
              id={`${parent_id}_diagnosis_Cancel_button`}
              onClick={() => goBack()}
              variant={"text"}
              inLineStyles={styles.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}_diagnosis_Update_button`}
              variant={"outlined"}
              inLineStyles={styles.filledBtn}
              onClick={handleUpdate}
            >
              {" "}
              Update
            </Button>
          </Div>
        </Col>
      </Row>

      {/* for status info */}
      <Popover
        open={Boolean(openStatus)}
        anchorEl={openStatus}
        onClose={handleStausClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        <InfoStatus
          parent_id={"diagnosis_form"}
          infoStatusprops={openStatus}
          openStausInfo={openStausInfo}
          handleStatusInactive={handleStatusInactive}
          handleStatusActive={handleStatusActive}
          statusList={storeData?.Clinical_masters?.data ?? []}
          currentStatus={state?.clinicalStatus?.label}
          state={state}
          handleChange={handleInputChange}
        />
      </Popover>

      {/* calender UI */}
      <Popover
        id={Boolean(open) ? "simple-popover" : undefined}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Calendar
          id={`${parent_id}_diagnosis_calender`}
          maxDate={new Date()}
          allowPartialRange
          onClickMonth={(value, event) => {
            handleClose(false);
          }}
          onClickYear={(value, event) => {
            handleClose(false);
          }}
          onClickWeekNumber={(value, event) => {
            handleClose(false);
          }}
          onChange={(value, event) => {
            calenderChange(value);
          }}
          className={"pc_regular"}
        /> */}
        <CustomDatePicker maxDate={new Date()} handleChange={(value) => calenderChange(value)}/>
      </Popover>

      <Popover
        id={Boolean(open1) ? "simple-popover" : undefined}
        open={Boolean(open1)}
        anchorEl={anchorEl1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Calendar
          id={`${parent_id}_diagnosis_calender`}
          maxDate={new Date()}
          allowPartialRange
          onClickMonth={(value, event) => {
            handleClose(false);
          }}
          onClickYear={(value, event) => {
            handleClose(false);
          }}
          onClickWeekNumber={(value, event) => {
            handleClose(false);
          }}
          onChange={(value, event) => {
            calenderChange1(value);
          }}
          className={"pc_regular"}
        /> */}
        <CustomDatePicker maxDate={new Date()} handleChange={(value) => calenderChange1(value)}/>
      </Popover>
    </Div>
  );
};

export default withAllContexts(DiagnonsisForm);
