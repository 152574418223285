/**
 * @author PRAKASH P
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-08-17
 * @desc LAB ORDER COMPONENT
 */
import React, { useState } from "react"; 
import { Row, Col, Text, Div } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
	TextField,
	Typography,
	Button,
	Tooltip,
	makeStyles,
} from "@material-ui/core";
import { InsightsIcon } from "../../../components";
import { DoccList } from "./list";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { getData, getUserInfo, UIColor } from "../../../utils";
import { DialogContext } from "../../../contexts";
import { withAllContexts } from "../../../HOCs";
import Tick from "../../../assets/img/icons8-double-tick.svg";
import Addicon from "../../../assets/img/icons8-add.svg";
import Cancel from "../../../assets/img/icons8-multiply.svg";

const useStyles = makeStyles({
	autocomplete: {
		width: 120,
		"& input": {
			fontFamily: "pc_regular",
			fontSize: 14,
			color: UIColor.primaryText.color,
			"&::placeholder": {
				fontSize: 14,
				fontFamily: "pc_regular !important",
			},
		},
		"& .MuiInputBase-input": {
			color: UIColor.secondaryText.color,
			fontSize: "14px !important",
			fontFamily: "pc_regular!important",
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: UIColor.lineBorderFill.color,
				borderRadius: 8
			},
			'&:hover fieldset': {
				borderColor: UIColor.lineBorderFill.color,
			},
			'&.Mui-focused fieldset': {
				borderWidth: 1,
				borderColor: UIColor.lineBorderFill.color,
			}
		},
		"& .MuiAutocomplete-popupIndicator": {
			transform: "none !important",
		}
	},
	input: {
		fontFamily: "pc_regular",
		color: UIColor.secondaryText.color,
		fontSize: 14,
		"&::placeholder": {
			fontSize: 14,
			fontFamily: "pc_regular !important",
		},
	},
	muiPopovers: {
		boxShadow: "0px 4px 24px #00000029",
		border: `1px solid ${UIColor.lineBorderFill.color}`,
		borderRadius: 8,
		backgroundColor: "#fff",
		minWidth: 240,
		overflow: "visible",
	},
	switch: {
		"& .Mui-checked": {
			color: "#fff",
		},
		"& .Mui-checked + .MuiSwitch-track": {
			backgroundColor: UIColor.primaryColor.color,
			opacity: 1,
		},
	},
});

const styles = {
	vitalsHead: {
		padding: "0px 10px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		paddind: 10,
		background: "#fff",
		borderRadius: 8,
		borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
		padding: "10px 10px 10px 20px",
	},
	vitalsfieldHead: {
		padding: "6px 10px",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		background: "#fff",
		borderRadius: 8,
	},
	vitailsCard: {
		border: `1px solid ${UIColor.lineBorderFill.color}`,
		borderRadius: 10,
		width: "100%",
		marginBottom: "24px !important",
		backgroundColor: "#fff"
	},
	vitailsCarderr: {
		fontSize: "12px",
		textAlign: "right",
		justifyContent: "right",
		float: "right",
		color: UIColor.error.color,
		marginBottom: "24px !important",
	},
	list: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	infoBell: {
		width: "50px",
		display: "flex",
		padding: "12px",
		borderLeft: `1px solid ${UIColor.lineBorderFill.color}`,
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 10,
	},
	icons: {
		cursor: "pointer",
	},
	flexFlow: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	floatingLable: {
		backgroundImage: `linear-gradient(#fff, ${UIColor.greyBackground.color})`,
		position: "absolute",
		top: "-8px",
		fontSize: 10,
	},
};

const initialState = {
	selectCatagory: {},
	selectDoccument: {},
	headerShow: false,
	editId: null,
	formData: { mobileStatus: "" },
	selectReason: null,
	error: false,
	status: false,
	saveMode: "Draft",
};

const DoccumentationList = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const dialog = React.useContext(DialogContext);
	const { Doccument, setDoccument } = props.Doccument;
	const { list } = Doccument;
	const info = getUserInfo();

	const storeData = useSelector((state) => state?.DoccSlice);
	const [stateDoc, setDocc] = useState({
		...initialState
		//  headerShow: true,
	});
	React.useEffect(() => {
		dispatch(actions.DOCC_CATE_READ());
	}, []);

	const handleInputChange = (e, name) => {
		if (name === "selectCatagory") {
			dispatch(actions.DOCC_DOCC_READ({ id: e?.__id }));
		}
		setDocc({
			...stateDoc,
			[name]: e,
			error: false,
		});
	};

	const AddTreatmentProcedure = async () => {
		if (!stateDoc.selectCatagory?.label && !stateDoc.selectDoccument?.label) {
			setDocc({
				...stateDoc,
				headerShow: true,
				error: true,
			});
			return;
		} else {
			setDocc({
				...stateDoc,
				headerShow: false,
				error: false,
				selectCatagory: {},
				selectDoccument: {},
			});
			await props.saveDoccumentation([stateDoc], "Add");
			handleEdit();
			// dispatch(actions.DOCC_SINGLE_READ({ id: props.Doccument.editIndex }))
		}
	};

	const handleEdit = (data) => {
		setDoccument({ ...props.Doccument.Doccument, editIndex: data?._key });
		props.onEditChart(data, "docc_form");
	};

	   const handleClose = () => {
       if (storeData.docc_read.data?.length > 0) {
         setDocc({
           ...stateDoc,
           headerShow: false,
           error: false,
           selectCatagory: {},
           selectDoccument: {},
         });
       } else {
         props.handleClose("docc_ui");
       }
     };

	const { parent_id } = props;
	return (
		<div id={`${parent_id}_docc_parent_div`} style={{ width: "100%" }}>
			<Row
				id={`${parent_id}_docc_parent_row`}
				inLineStyles={styles.vitailsCard}
			>
				{/* Add a new docc data's */}
				<Col
					id={`${parent_id}_docc_parent_col`}
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					inLineStyles={styles.vitalsHead}
				>
					{stateDoc.headerShow ? (
						<div
							id={`${parent_id}_docc_title_div`}
							style={styles.vitalsfieldHead}
						>
							<div
								id={`${parent_id}_docc_title_sub_div`}
								style={{ marginRight: 16, width: "100%" }}
							>
								{/* <Text
									id={`${parent_id}_docc_title_text`}
									className="pc_regular"
									inLineStyles={styles.floatingLable}
								>
									Documentation &nbsp;
								</Text> */}
								<Autocomplete
									id={`${parent_id}_docc_autocomplete`}
									className={classes.autocomplete}
									style={{ width: 220 }}
									size="small"
									options={storeData?.docc_cat_read?.data ?? []}
									disableClearable
									getOptionLabel={(option) => option.label}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}_docc_Search_textfield`}
											{...params}
											variant="standard"
											placeholder="Select Category"
											style={{ width: "100%", fontSize: 14 }}
										/>
									)}
									renderOption={(option) => (
										<Typography
											id={`${parent_id}_docc_Search_${option.label.replaceAll(
												" ",
												"_"
											)}_typography`}
											className="pc_regular"
											style={{
												width: "100%",
												fontSize: 14,
												color: UIColor.primaryText.color,
											}}
											noWrap
										>
											{option.label}
										</Typography>
									)}
									classes={{ popper: "muiPopperDropdown" }}
									value={stateDoc.selectCatagory}
									onChange={(e, newValue) =>
										handleInputChange(newValue, "selectCatagory")
									}
								/>
							</div>
							<div
								id={`${parent_id}_docc_Priority_div`}
								style={{ marginRight: 16, width: "100%" }}
							>
								<Autocomplete
									disabled={!stateDoc.selectCatagory?._id}
									id={`${parent_id}_docc_Priority_autocomplete`}
									className={classes.autocomplete}
									style={{ width: 220 }}
									size="small"
									options={storeData?.docc_docc_read?.data ?? []}
									disableClearable
									getOptionLabel={(option) => option.label}
									renderInput={(params) => (
										<TextField
											id={`${parent_id}_docc_Priority_textfield`}
											{...params}
											variant="standard"
											placeholder="Select Document"
											style={{ width: "100%", fontSize: 14 }}
										/>
									)}
									renderOption={(option) => (
										<Typography
											id={`${parent_id}_docc_Priority_${option.label.replaceAll(
												" ",
												"_"
											)}_typography`}
											className="pc_regular"
											style={{
												width: "100%",
												fontSize: 14,
												color: UIColor.primaryText.color,
											}}
											noWrap
										>
											{option.label}
										</Typography>
									)}
									// classes={{popper: "muiPopperDropdown"}}
									value={stateDoc.selectDoccument}
									onChange={(e, newValue) =>
										handleInputChange(newValue, "selectDoccument")
									}
								/>
							</div>
						</div>
					) : (
						<div
							id={`${parent_id}_docc_headerShow_div`}
							style={{ display: "flex",width: "99%",justifyContent:"space-between"  }}
						>
							<Text
								id={`${parent_id}_docc_headerShow_text`}
								onClick={() =>
									handleInputChange(!stateDoc.headerShow, "headerShow")
								}
								inLineStyles={{
									fontWeight: 600,
									fontSize: "14px",
									cursor: "pointer",
								}}
							>
								{"Documentation"}
							</Text>
							 <div
								onClick={() =>
									handleInputChange(!stateDoc.headerShow, "headerShow")
								}
								style={{cursor: "pointer",display: "flex"}}
								>
								<img src={Addicon} alt="Add" />
              				</div>
						</div>
					)}
					<div id={`${parent_id}_docc_add_button_div`} style={styles.flexFlow}>
						
						{/* {stateDoc.selectCatagory?.value ? ( */}
						{stateDoc.headerShow &&
							<div
								id={`${parent_id}_docc_add_button_sub_div`}
								style={{ width: "100%",display: "flex" }}
							>
								<Button
									id={`${parent_id}_docc_add_button`}
									onClick={() => { 
										if(stateDoc.selectCatagory?.value ){
											AddTreatmentProcedure();
										} else{
											return false;
										}
										
									}}
									variant={"text"}
									style={{
										color: UIColor.primaryColor.color,
										fontFamily: "pc_medium",
										fontSize: 14,
										fontWeight: "800",
									}}
									size={"small"}
								>
									 <img src={Tick} alt="Add" />
								</Button>
                                 <Button
                                            id={`${parent_id}_allergy_add_button`}
                                            onClick={() => handleClose()}
                                            variant={"text"}
                                            style={{
                                                color: UIColor.primaryColor.color,
                                                fontWeight: 600,
                                            }}
                                            size={"small"}
                                            >
                                            <img src={Cancel} alt="Cancel" />
                               </Button>
							</div>
						}
						{/* // ) : ( */}
							{/* // <div id={`${parent_id}_docc_add_div`} style={{ width: "100%" }}> */}
							{/* // 	<Text */}
							{/* // 		id={`${parent_id}_docc_add_text`}
							// 		className="pc_medium"
							// 		inLineStyles={{ color: UIColor.tertiaryText.color, fontSize: 14 }}
							// 	>
							// 		{"+ ADD"}
							// 	</Text>
							// </div> */}
						{/* // )} */}
						{/* <Tooltip
							id={`${parent_id}_docc_Insights_icon_tooltip`}
							title={"Insights"}
						>
							<div style={{ ...styles.infoBell, ...styles.icons }}>
								<InsightsIcon
									id={`${parent_id}_docc_Insights_icon`}
									style={{ marginLeft: 5 }}
									// color={UIColor.primaryColor.color}
								/>
							</div>
						</Tooltip> */}
					</div>
				</Col>

				{storeData.docc_read.data?.length > 0 &&
					storeData.docc_read.data.map((val, i) => {
						if (val?.data?.doccategory?.display) {
							/* Docc list */
							return (
								<Col
									id={`${parent_id}_docc_${i}_list_col`}
									key={i}
									xs={12}
									sm={6}
									md={3}
									lg={3}
									xl={3}
									inLineStyles={{
										padding: "14px",
										overflow: "hidden",
									}}
								>
									<DoccList arrData={val} index={i} handleEdit={handleEdit} />
								</Col>
							);
						}
					})}
			</Row>

			{stateDoc.error && (
				<span
					id={`${parent_id}_docc_filled_Recurrent_span`}
					style={styles.vitailsCarderr}
				>
					Please select category and doccument !
				</span>
			)}
		</div>
	);
};
export default withAllContexts(DoccumentationList);
