import React from "react";

export const LabIcon1 = (props) => {
  let width = props.width ? props.width : "27.799";
  let height = props.height ? props.height : "40";
  let color = props.color ? props.color : "#252525";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.799 40"
    >
      <g id="lab_order" data-name="lab order" transform="translate(-5.49)">
        <path
          id="Path_92709"
          data-name="Path 92709"
          d="M32.657,31.478,24.946,17.611,25,11.222h1.056a1.111,1.111,0,0,0,0-2.222H12.723a1.111,1.111,0,0,0,0,2.222h1.056l.056,6.389L6.123,31.478A5.07,5.07,0,0,0,10.557,39H28.223a5.07,5.07,0,0,0,4.433-7.522ZM15.912,18.433a1.121,1.121,0,0,0,.144-.556L16,11.222h6.778l-.056,6.656a1.121,1.121,0,0,0,.144.556l3.967,7.144c-.267-.007,4.826,6.455,4.188,9.063a2.648,2.648,0,0,1-2.8,2.33s-17.648-.019-17.667,0c-.8-.045-1.654-.277-2.585-2.1S13.157,23.4,13.157,23.4Z"
          transform="translate(0 1)"
          fill="#001c3c"
        />
        <g id="noun_lab_3020026" transform="translate(1.39 2)">
          <path
            id="Path_92710"
            data-name="Path 92710"
            d="M14.87,17.49a1.009,1.009,0,0,0,.13-.5L14.95,11h6.1L21,16.99a1.009,1.009,0,0,0,.13.5l3.57,6.43a22.99,22.99,0,0,1-5.94-.95,25.587,25.587,0,0,0-6.37-1.01Z"
            fill="#ec6a49"
          />
          <circle
            id="Ellipse_128812"
            data-name="Ellipse 128812"
            cx="2"
            cy="2"
            r="2"
            transform="translate(13)"
            fill="#ec6a49"
          />
          <circle
            id="Ellipse_128813"
            data-name="Ellipse 128813"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(20 2)"
            fill="#ec6a49"
          />
          <circle
            id="Ellipse_128814"
            data-name="Ellipse 128814"
            cx="1"
            cy="1"
            r="1"
            transform="translate(19 -2)"
            fill="#ec6a49"
          />
        </g>
      </g>
    </svg>
  );
};
