import React from "react";
import { Card } from "qdm-component-library";
import DoughnutChart from "./doughnutChart";

function BillingStatus(props) {
  const styles = {
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 12,
      height: "280px"
    },
  };
  const data = {
    labels: ["Pending bills", "Collected", "Pending Collection", "Cancelled"],
    datasets: [
      {
        data: [50, 20, 15, 15],
        backgroundColor: ["#36A2EB", "#4BC0C0", "#FF9F40", "#FF6384"],
        cutout: 60,
      },
    ],
  };
  const { parent_id } = props;
  return (
    <Card
      id={`${parent_id}_layout_billingstatus_parent_card`}
      style={styles.card}>
      <DoughnutChart
        parent_id={`dashboard_layout_billingstatus`}
        data={data} label="Billing Status" />
    </Card>
  );
}
export default BillingStatus;
