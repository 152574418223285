const Routes = {
	// home: "/",
	signIn: "/signin",
	signInOTP: "/otp_verification",
	signUp: "/signup",
	doctorConsultation: "/doctor_consultation",
	doctorListing: "/appointment_booking",
	doctorListingWithId: "/appointment_booking/:id",
	doctorListingWithIdCall: "/appointment_booking/",
	confirmBooking: "/appointment_confirmation",
	appointmentsListingPage: "/appointments_listing_page",
	yourAppointmentHasBeenBooked: "/your_appointment_has_been_booked",
	collection: "/collection",
	assemblePatioentRegister: "/assemble-patient-form",
	assembleNextOfKin: "/assemble-nextofkin-form",
	patientReferral: "/referral_registration_form",
	patientVisitDetails: "/visit_registration_form",
	patientVisitModify: "/modify_visit",
	patientVisitCancel: "/cancel_visit",
	financialRegistration: "/financial_registration_form",
	dashboard: "/dashboard",
	PatientDashBoard: "/patient_dashboard",
	patientDetail: "/patient_detail",
	viewreport: "/view_report",
	forgotPassword: "/forgot-password",
	resetPassword: "/reset-password",
	appointmentStats: "/appointmentStats",
	currentVisitStats: "/currentVisitStats",
	pendingTasks: "/pendingTasks",
	changePassword: "/change-password",
	expired: "/expired",
	workList: "/work_list",
	dayEndProcess: "/day-end-process",
	avcLogin: "/avc_login/:id",
	avcLoginRedirect: "/avc_login",
	// avcLogin: "/avc_login",
	avcSupport: "/avc_support",
	avcLanding: "/avcLanding",
	avcLanding1: "/avcLanding",
	videoCall: "/video_call",
	billingDetails:"/billing_details",
	billingSummary:"/billing_summary",
};
export { Routes };
