/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc The private route check wheather the user is logged in or not and also check
 * wheather the request route from the user is accessible to them or not using the Access(role,path)
 * function then allow to the particular route or else it will redirect to login page.
 */

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";

const PrivateRoute = (routeprops) => {
  const dispatch = useDispatch();

  let { component: Component, path, ...rest } = routeprops;

  const checkAccess = () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        dispatch(actions.REFRESH_TOKEN({ token, isOrg: true })).then(userInfo => {
          localStorage.setItem("token", userInfo.payload?.data || token);
        }).catch(err => {
          console.log(err);
        })
      }
      let data = localStorage.getItem('role_data');
      const accessObj = JSON.parse(atob(data))?.[localStorage.getItem('role_name')]?.access || [];
      if (accessObj?.length === 0) {
        throw new Error("Invalid Permissions")
      }
      return localStorage.getItem('role_name') && accessObj.indexOf(path) > -1 && localStorage.token;
    } catch (err) {
      localStorage.removeItem("token")
      localStorage.removeItem("role_name")
      localStorage.removeItem("role_data")
      return false;
    }
  };

  return (
    <Route
      {...rest} render={(props) => checkAccess() ? (
        <Component {...props} />
      ) : (
        <Redirect to={"/"} />
      )
      }
    />
    // <Route
    //   {...rest} render={(props) =>  (
    //     <Component {...props} />
    //   ) 
    //   }
    // />
  );
};

export default PrivateRoute;
