import React from "react";
import { Div, Paper, H6, Text, Image } from 'qdm-component-library'
import "./style.css"
import { PatientAddAddress, ShowList } from "./"
import { Popover, withStyles } from "@material-ui/core"
import { withAllContexts } from "../../HOCs";
import { UIColor } from "../../utils";

const styles = (theme) => ({
    muiPopovers: {
        boxShadow: '0px 10px 75px #0000003D',
        borderRadius: 8
    }
});

class PatientAddress extends React.Component {
    constructor(props) {
        super(props)
        this.state = { addAddress: null, addCollapse: false, editadd: {}, editindex: null }
    }

    OpenAddress = async (e,statusDetails) => {
        if(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"){

        }else{
            this.setState({
                addAddress: e?.currentTarget ?? null,
            })
        }
    }
    handleClear = () => {
        this.setState({
            editadd: {},
            editindex: null
        })
    }

    adCollapse(e) {
        this.setState({
            addCollapse: !this.state.addCollapse
        })
    }

    onedit = async (v, i, e,statusDetails) => {
        if(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"){

        }else{
        this.setState({
            editadd: { ...v },
            editindex: i
        })
        this.OpenAddress(e)
        }
    }

    ondelete = (v, i,statusDetails) => {
        if(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"){

        }else{
        let patientData = this.props[this.props.saveto]
        patientData.address.splice(i, 1);
        patientData[this.props.setTo]({
            ...patientData,
        })
        }
    }

    render() {
        let { addAddress, addCollapse } = this.state;
        const { classes, parent_id } = this.props;
        const { address,statusDetails } = this.props[this.props.saveto];

        return <Div
            id={`${parent_id}_address_parent_div`}
            className="pi-root">
            <Paper
                id={`${parent_id}_address_parent_paper`}
                className="pi-paper" style={{padding: "12px 10px 12px 16px"}}>
                <Div
                    id={`${parent_id}_address_sub_parent_div`}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <H6
                        id={`${parent_id}_address_title_address_label_h6`}
                        className="pi-title" style={{ display: 'flex', alignItems: 'center', margin: 0, flex: 1 }}>ADDRESS {(address?.length > 0) && <Image style={{ width: 16, height: 16, marginLeft: 5 }} src="images/icons8-ok.svg" alt="tick" />}
                    </H6>
                    <Text
                        id={`${parent_id}_address_title_addnew_text`}
                        inLineStyles={{
                            color: '#0071F2',
                            fontSize: 12,
                            display: 'flex',
                            alignItems: 'center'
                        }} className="pi-addbtn"><Text className="pi-addbtn1" style={{ marginRight: 10 }} onClick={(e) => {
                            this.handleClear()
                            this.OpenAddress(e,statusDetails)
                        }}>+ Add New</Text>
                        {
                            (address?.length > 0) &&
                            <React.Fragment>
                                {addCollapse ?
                                    //  <i onClick={(e) => this.adCollapse(e)} className="fa fa-angle-down ad-arrow" aria-hidden="true" /> : <i className="fa fa-angle-up ad-arrow" aria-hidden="true" onClick={(e) => this.adCollapse(e)} />}
                                    <Image
                                        id={`${parent_id}_address_group_downarrow_image`}
                                        src="images/Group 3873.svg" style={{ width: 20, height: 20, }} alt="downArrow" onClick={(e) => this.adCollapse(e)} /> : <Image
                                        id={`${parent_id}_address_group_uparrow_image`}
                                        style={{ width: 20, height: 20, }} src="images/Group -1.svg" alt="upArrow" onClick={(e) => this.adCollapse(e)} />}
                            </React.Fragment>
                        }

                    </Text>
                </Div>
                {
                    addCollapse &&
                    <Div
                        id={`${parent_id}_address_showlist_div`}
                        className="pa-showList">
                        {
                            address.map((val, i) => {
                                return <div
                                    id={`${parent_id}_${i}_address_showlist_div`}
                                    style={{ marginBottom: 12 }}>
                                    <ShowList
                                        id={`${parent_id}_${i}_address_showlist`}
                                        index={i + 1}
                                        title={val?.use?.value ?? ''}
                                        description={(val?.addressLine1 ?? "") + (val.city ? ", " + val.city.value : '') + (val.district ? ", "
                                            + val.district.value : "") + (val.state ? ", " + val.state.value : "") + (val.country ? ", " + val.country.value : "") + (val.pincode ? ", " + val.pincode?.value : "")}
                                        ondelete={() => this.ondelete(val, i,statusDetails)}
                                        onedit={(e) => this.onedit(val, i, e,statusDetails)}
                                    />
                                </div>
                            })
                        }
                    </Div>
                }
            </Paper>
            <Popover
                anchorEl={addAddress}
                open={Boolean(addAddress)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                // className={classes.muiPopovers}
                classes={{ paper: classes.muiPopovers }}
            >
                <PatientAddAddress
                    parent_id={'Register_Patinet_patientdetails_patientaddress'}
                    allMasters={this.props?.allMasters}
                    handleClear={this.handleClear} index={this.state.editindex} editData={this.state.editadd} setTo={this.props.setTo} saveto={this.props.saveto} onclose={() => this.OpenAddress(null)} />
            </Popover>
        </Div>

    }
}


export default withStyles(styles)(withAllContexts(PatientAddress));