import React from "react";
import { useStyles } from "./styles";
import { Select, TextInput } from "qdm-component-library";
import { AlertProps, UIColor } from "../../utils";
import QuantityRangePicker from "../patientDetail/medicationFlows/components/quantity-range-picker";
import Cancel from "../../assets/img/icons8-multiply.svg";
import Tick from "../../assets/img/icons8-double-tick.svg";
import { Grid } from "@material-ui/core";
import {
  chargeCodeDropDown,
  newOrder,
  optionsuom,
  optionsvolumuom,
} from "./dummyData";
import { useSelector } from "react-redux";
import { constructNewOrder } from "./constructJson";
import { withAllContexts } from "../../HOCs";

const AddNewBillingOrder = (props) => {
  const classes = useStyles();
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
  };

  const chargeCodeDropDowns = useSelector(
    (state) => state?.billSettlementSlice?.charge_code_dropdown
  );
  const uomDropDowns = useSelector(
    (state) => state?.billSettlementSlice?.uom_dropdown
  );
  console.log("props?.newOrderSelected", props?.newOrderSelected);

  const handleRemoveAdd = () => {
    props.handleRemoveAddnew();
  };

  const addValidate = () => {
    if(props?.newOrderSelected?.chargeCode?.value && props?.newOrderSelected?.qty && props?.newOrderSelected?.uom?.value){
      return true
    }else{
      return false
    }
  }

  const handleAddNewOrder = () => {
    const additionValidate = addValidate()
    if(additionValidate){
      const constructNewOrders = constructNewOrder(props?.newOrderSelected);
      const calculatedNewOrder = generateCalculations(constructNewOrders);
      props.setNewOrderSelected({
        ...props.newOrderSelected,
        chargeCode: {},
        uom: {},
        qty: "",
      });
      props.handleAddNewOrder(calculatedNewOrder);
    }else{
      props.alert.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill all the fields",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
    }  
  };

  const handleChargeCode = (e, type) => {
    props.handleChargeCode(e, type);
  };

  const handleInputChange = (selector, value) => {
    debugger;
  };

  const generateCalculations = (newOrder) => {
    debugger;
    if (
      typeof newOrder === "object" &&
      !Array.isArray(newOrder) &&
      newOrder !== null
    ) {
      const gross = newOrder?.qty * newOrder?.rate;
      const discount = (gross * props?.deductedAmnt?.discountAmnt) / 100;
      const net = gross - discount;
      const patientShare = props?.deductedAmnt?.patientShare;
      const patientShareDiscount = (patientShare * net) / 100;
      const append = {
        gross: gross.toFixed(2),
        dicount: discount,
        net: net,
        patientShare: patientShareDiscount,
        creditShare: (net - patientShareDiscount).toFixed(2),
        action: "delete",
      };
      return { ...newOrder, ...append };
    } else {
      return newOrder;
    }
  };

  return (
    <div>
      <Grid
        container
        style={{
          padding: "16px 16px 12px",
          borderBottom: "1px solid #DFE5EB",
        }}
        spacing={2}
      >
        <Grid item xs={6}>
          <Select
            showArrow
            label="Charge Code & Description"
            options={chargeCodeDropDowns?.data ?? []}
            value={props?.newOrderSelected?.chargeCode?.value ?? ""}
            onChangeValue={(e) => handleChargeCode(e, "chargeCode")}
            placeholder="select..."
            labelStyle={styles.labelStyle}
            inLineStyles={styles.borderStyle}
            hoverColor={UIColor.primaryColor.color}
          />
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={4} style={{ display: "flex" }}>
              <div style={{ width: "30%" }}>
                <TextInput
                  className={"textInput"}
                  id={"patient-share"}
                  label={"Qty"}
                  placeholder=""
                  // isRequired
                  labelStyle={styles.labelStyle}
                  style={{
                    ...styles.borderStyle,
                    ...styles.inputStyle,
                    borderRadius: "8px 0px 0px 8px",
                  }}
                  type="number"
                  value={props?.newOrderSelected?.qty}
                  onChange={(e) => handleChargeCode(e?.target?.value, "qty")}
                  hoverColor={UIColor.lineBorderFill.color}
                  // disabled={true}
                />
              </div>
              <div style={{ width: "70%" }}>
                <Select
                  showArrow
                  label=""
                  options={uomDropDowns?.data ?? []}
                  value={props?.newOrderSelected?.uom?.value ?? ""}
                  onChangeValue={(e) => handleChargeCode(e, "uom")}
                  placeholder=""
                  labelStyle={styles.labelStyle}
                  inLineStyles={{
                    ...styles.borderStyle,
                    marginTop: "24px",
                    borderRadius: "0px 8px 8px 0px",
                  }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </div>
            </Grid>
            <Grid item xs={1} className={classes.addNewIconsAlign}>
              <div
                className={classes.onAddButtons}
                onClick={handleRemoveAdd}
                style={{ backgroundColor: "#ffeeed" }}
              >
                <img src={Cancel} alt="Cancel" width="12px" height="10px" />
              </div>
            </Grid>
            <Grid item xs={1} className={classes.addNewIconsAlign}>
              <div className={classes.onAddButtons} onClick={handleAddNewOrder}>
                <img src={Tick} alt="Tick" width="12px" height="10px" />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withAllContexts(AddNewBillingOrder);
