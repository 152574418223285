import React from "react";
import { Div, Paper, Select, TextInput, H6, Col, Row, Checkbox } from 'qdm-component-library';
import {
    RadioGroup,
    FormControlLabel,
    Radio
  } from "@material-ui/core";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";
import "./style.css"
import { withAllContexts } from "../../HOCs";
import moment from 'moment';
import { UIColor } from "../../utils";


class PatientBirthDetails extends React.Component {

    styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        rodiolabel: {
           fontSize: "10px",
        },
    }

    setData = (name, value) => {

        let patientData = this.props[this.props.saveto]
        patientData.birthdetails[name] = value;
        patientData[this.props.setTo]({
            ...patientData,
        })
    }

    handleRadioChange = (e, name) => {
        let patientData = this.props[this.props.saveto]
        patientData.birthdetails[name] = e.target.value;
        patientData[this.props.setTo]({
            ...patientData,
        })
    };

    FlterOptions = (type) => {
        const { allMasters } = this.props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
                "_id": data[index]?.coding?._id,
            };
            lista.push(datatemp);
        }
        return lista
    }

    render() {

        const { labelStyle, borderStyle, rodiolabel } = this.styles;
        let patientData = this.props[this.props.saveto]?.birthdetails
        const { parent_id } = this.props;

        return <Div
            id={`${parent_id}_birthdetails_parent_div`}
            className="pi-root">
            <Paper
                id={`${parent_id}_birthdetails_parent_paper`}
                className="pi-paper">
                <Div
                    id={`${parent_id}_birthdetails_label_div`}
                >
                    <H6
                        id={`${parent_id}_birthdetails_label_h6`}
                        className="pi-title">BIRTH DETAILS</H6>
                </Div>
                <Row
                    id={`${parent_id}_birthdetails_parent_row`}
                >
                    <Col
                        id={`${parent_id}_birthdetails_birthplace_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <TextInput
                            autoFocus={true}
                            id={`${parent_id}_birthdetails_birthplace__label_textinput`}
                            onChange={(e) => this.setData('birth_place', e.target.value)}
                            value={patientData?.birth_place}
                            label="Birth Place"
                            placeholder="Birth place"
                            labelStyle={labelStyle} style={borderStyle} hoverColor="#0071F2" />
                        {/* <Select
                            onChangeValue={(e) => this.setData('birth_place', e)}
                            value={patientData?.birth_place?.value}
                            options={Options} label="Birth Place" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" /> */}
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_birthtime_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <CustomInputDatePicker 
                            handleChange={(date) => this.setData('birth_time', date)}
                            selectedDate={patientData?.birth_time ? new Date(patientData?.birth_time) : ""}
                            showTimeSelectOnly
                            showTimeSelect
                            timeIntervals={1}
                            timeFormat="HH:mm"
                            inputField={
                                <TextInput 
                                id={`${parent_id}_birthdetails_birthtime_label_textinput`}
                                type="text"
                                label="Birth Time"
                                placeholder="hh:mm"
                                dateIcon="images/icons8-clock-black.svg"
                                labelStyle={labelStyle} 
                                value={patientData?.birth_time ? moment(patientData?.birth_time).format("HH:mm") : ""}
                                style={borderStyle}
                                hoverColor={"#0071F2"}
                            />}
                        />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_birthgender_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <Select
                            id={`${parent_id}_birthdetails_birthgender_select`}
                            onChangeValue={(e) => this.setData('birth_gender', e)}
                            options={this.FlterOptions('GENDER')}
                            value={patientData?.birth_gender?.value}
                            // options={genderOptions} 
                            label="Birth Gender" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_genderidentify_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <Select
                            id={`${parent_id}_birthdetails_genderidentify_select`}
                            onChangeValue={(e) => this.setData('gender_identify', e)}
                            value={patientData?.gender_identify?.value}
                            options={this.FlterOptions('GENDER')}
                            label="Gender Identity" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_primary_practitioner_col`}
                        md={4} lg={4} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <Select
                            id={`${parent_id}_birthdetails_primary_practitioner_select`}
                            onChangeValue={(e) => this.setData('primarycare_practitioner', e)}
                            // value={patientData?.primarycare_practitioner?.value}
                            value={(this.props.City?.add_prac?.find?.(d => d?._id === patientData?.primarycare_practitioner?._id)?.value || "")}
                            options={this.props.City?.add_prac}
                            // options={this.FlterOptions("PRACTITIONER")}
                            label="PrimaryCare Practitioner" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_disability_code_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        {/* <Radio style={{ padding: 0 }} checked={patientData?.disability_code ?? false}
                            name="radio-button-demo" color='default'
                            onChange={(e) => this.setData("disability_code", !(patientData?.disability_code))} /> */}
                        <Select
                            id={`${parent_id}_birthdetails_disability_code_select`}
                            options={this.FlterOptions("DISABILITY")}
                            onChangeValue={(e) => this.setData('disability_code', e)}
                            value={patientData?.disability_code?.value}
                            label="Disability Code" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_cadaveric_donar_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <H6
                            id={`${parent_id}_birthdetails_cadaveric_donar_label_h6`}
                            className="default-size pc_regular"
                            style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}>Cadaveric Donar</H6>
                        <Checkbox
                            id={`${parent_id}_birthdetails_cadaveric_checkbox`}
                            style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                            }}
                            name=''
                            onChange={(e) => this.setData('cadaveric_donar', !(patientData?.cadaveric_donar))}
                            checked={patientData?.cadaveric_donar}
                            color={UIColor.secondaryButtonColor.color} />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_citizenship_code_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <Select
                            id={`${parent_id}_birthdetails_citizenship_code_label_select`}
                            onChangeValue={(e) => this.setData('citizenshio_code', e)}
                            value={patientData?.citizenshio_code?.value}
                            options={this.props.City?.add_cizn_chip}
                            label="Citizenship Code" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" />
                    </Col>
                    <Col
                        id={`${parent_id}_birthdetails_residency_col`}
                        md={4} lg={4} sm={6} xs={12} className="no-padding-left" inLineStyles={{
                            padding: 10,

                        }}>
                        <H6
                            id={`${parent_id}_birthdetails_residency_label_h6`}
                            className="default-size pc_regular"
                            style={{
                                // textAlign: 'center',
                                // justifyContent: 'center',
                                paddingLeft: "40px"
                            }}>Residency Status</H6>
                        {/* <Radio
                            id={`${parent_id}_birthdetails_residency_status_radio`}
                            style={{
                                padding: 0,
                                textAlign: 'center',
                                justifyContent: 'center'
                            }}
                            checked={patientData?.residency_status ?? false}
                            label=''
                            name="radio-button-demo"
                            color={"primary"}
                            onChange={(e) => this.setData("residency_status", !(patientData?.residency_status))}
                        /> */}

                <RadioGroup
                  id={`${parent_id}-overRideType-radiogroup`}
                  aria-label="position"
                  name="Residency Status"
                  value={patientData?.residency_status}
                  onChange={(e) => this.handleRadioChange(e, "residency_status")}
                  row
                  style={{ paddingLeft: "40px" }}
                >
                  <FormControlLabel
                    id={`${parent_id}-overRideType-formcontrolabel`}
                    //style={{ color: "#6F6F6F" }}
                    value="legal"
                    control={
                      <Radio
                        id={`${parent_id}-overRideType-radio`}
                        size="small" style={{ color: "#0071F2" }} />
                    }
                    style={rodiolabel}
                    inLineStyles={{ fontSize: "5px" }}
                    classes={{ label: rodiolabel }}
                    label="Legal"
                  />
                  <FormControlLabel
                    id={`${parent_id}-dateRange-formcontrollabel`}
                    style={{ color: "#6F6F6F" }}
                    value="illegal"
                    control={
                      <Radio
                        id={`${parent_id}-dateRange-radio`}
                        size="small" style={{ color: "#0071F2" }} />
                    }
                    label="Illegal"
                    classes={{ label: rodiolabel }}
                  />
                </RadioGroup>
                        {/* <Select
                            onChangeValue={(e) => this.setData('residency_status', e)}
                            value={patientData?.residency_status?.value}
                            options={Options} label="Residency Status" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor="#0071F2" /> */}
                    </Col>
                </Row>
            </Paper>
        </Div>

    }
}


export default withAllContexts(PatientBirthDetails);