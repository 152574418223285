import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import clsx from "clsx";
import {
  Div,
  Text,
  TapSelect,
  Row,
  Divider,
  Button,
  SideMenu,
} from "qdm-component-library";
import {
  BookMultipleAppointmentsDrawer,
  BookMultipleAppointmentsLists,
} from "../";
import doctorandappointmentslot from "../../assets/img/doctorandappointmentslot.svg";
import { convertTime, utcTOLocal,UIColor } from "../../utils";
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteIcons from "../../assets/img/deleteIcons.svg"
import { Direct } from "../../assets/img/directconsultation";
import { Videoconsultation } from "../../assets/img/videoconsultation";

const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    padding:16,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  TapSelect: {
    "& div": {
      justifyContent: "center !important",
      "& span": {
        fontSize: 12,
        fontWeight: "600",
        letterSpacing: "0.9px",
        fontFamily: "pc_regular !important",
      },
    },
  },
  imgage: {
    width: 40,
    height: 40,
    borderRadius: "50%",
  },
});

class DoctorAndAppointmentSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      bookAppoinment: false,
      showHide: 0,
      info: JSON.parse(JSON.stringify(this.props.multiApppointmentInfo.info)),
    };
  }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  changeState = (key, val) => {
    if (key === "bookAppoinment" && !val) {
      this.setState({
        ...this.state,
        [key]: val,
        showHide: 0,
      });
    } else {
      this.setState({
        ...this.state,
        [key]: val,
      });
    }
  };

  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true;
  }

  render() {
    const { classes, parent_id } = this.props;
    const oldDate = utcTOLocal(
      this.props.oldSlotInfo?.info?.dateStart,
      "hh:mm a, Do MMM YYYY"
    );
    return (
      <Card
        id={`${parent_id}_appointmentslot_parent_card`}
        style={{
          boxShadow: "none",
          border: "1.5px solid #E0E0E0",
          borderRadius: 8,
        }}
      >
        <CardActions
          id={`${parent_id}_appointmentslot_cardactions`}
          onClick={this.handleExpandClick}
          disableSpacing
          style={{
            padding: 0,
            paddingLeft: 16,
            paddingBottom: 0,
            cursor: "pointer",
          }}
        >
          <img
            id={`${parent_id}_appointmentslot_img`}
            alt="doctorandappointmentslot"
            src={doctorandappointmentslot}
            style={{ marginRight: 12 }}
          />
          <Text
            id={`${parent_id}_appointmentslot_Doctor_Slot_text`}
            key={"0"}
            name="Doctor and Appointment Slot"
            style={{ fontSize: "16px", color: "#101010", letterSpacing: 0 }}
            className={"pc_semibold"}
          />

          <IconButton
            id={`${parent_id}_appointmentslot_showmore_iconsbutton`}
            className={clsx(classes.expand, {
              [classes.expandOpen]: this.props.DoctorAndAppointmentSlotCard,
            })}
            aria-expanded={this.props.DoctorAndAppointmentSlotCard}
            aria-label="show more"
          >
            <ExpandMoreIcon
              id={`${parent_id}_appointmentslot_expandmoreicon`}
            />
          </IconButton>
        </CardActions>
        <Collapse
          id={`${parent_id}_appointmentslot_parent_collapse`}
          className={classes.body}
          in={this.props.DoctorAndAppointmentSlotCard}
          timeout="auto"
          unmountOnExit
        >
          <Div
            id={`${parent_id}_appointmentslot_collapse_div`}
            key={"0"}>
            <Text
              id={`${parent_id}_appointmentslot_consultationmode_text`}
              key={"0"}
              name="Consultation Mode"
              style={{
                fontSize: "14px",
                letterSpacing: "0px",
                color: "#101010",
              }}
              className={"pc_regular"}
            />

            <TapSelect
              id={`${parent_id}_appointmentslot_consultationmode_tapselect`}
              key={"1"}
              label=""
              type="single"
              fullWidth={true}
              textSize="medium"
              iconHeight="14"
              iconWidth="14"
              selectedBorderColor={UIColor.secondaryButtonColor.color} 
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor="#fff"
              borderColor={UIColor.differentiationBackground.color}
              backgroundColor={UIColor.differentiationBackground.color}
              textColor={UIColor.secondaryButtonColor.color}
              options={[
                {
                  value: "Direct Consultation",
                  label: "Direct Consultation",
                  disabled: !this.props?.info?.isdirect,
                  icon:<Direct color={UIColor.tertiaryText.color} />
                },
                {
                  value: "Video Consultation",
                  label: "Video Consultation",
                  disabled: !this.props?.info?.isvideo,
                  icon: <Videoconsultation color={UIColor.tertiaryText.color} />
                },
              ]}
              inLineStyles={{
                // color: "#001C3C",
                // borderColor: "#e0e0e0",
                padding: "6px",
                borderRadius: 8,
                marginTop: 8,
                marginRight: 8,
                // backgroundColor: ""
              }}
              className={classes.TapSelect}
              defaultSelected={this.props.consultationMode}
              onChange={(val) => {
                this.props.changeState("consultationMode", val);
              }}
            />

            <Row
              id={`${parent_id}_appointmentslot_guest_row`}
              key={"2"}>
              {this.props.loginType !== "guest" ? (
                <Divider
                  id={`${parent_id}_appointmentslot_guest_divider`}
                  key={"0"}
                  textWidth="50"
                  textColor="#000"
                  borderColor="#E0E0E0"
                  borderWidth="1"
                  orientation="horizontal"
                  variant="middle"
                  style={{ margin: "0px", marginTop: "6px" }}
                />
              ) : (
                ""
              )}

              <Div
                id={`${parent_id}_appointmentslot_guest_div`}
                key={"1"}
                className="qdm"
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "16px",
                  flexDirection: "column",
                }}
              >
                <Row
                  id={`${parent_id}_appointmentslot_newbook_row`}
                  key={"0"}
                  alignItems="stretch"
                  direction="row"
                  justify="space-between"
                  spacing="2"
                  style={{ display: this.props.name ? "flex" : "none", flex: 1 }}
                >
                  <Div
                    id={`${parent_id}_appointmentslot_userprofile_div`}
                    key={"0"} className="qdm" style={{ display: "flex", flex: 1 }}>
                    <Div
                      id={`${parent_id}_appointmentslot_userprofile_sub_div`}
                      key={"0"}
                      style={{ display: 'flex' }}
                    >
                      {/* <img
                        id={`${parent_id}_appointmentslot_userprofile_img`}
                        key={"0"}
                        src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                        alt="user profile"
                        className={classes.imgage}
                      /> */}
                      <Avatar src={this.props.hospitalInfo?.img || undefined}>{this.props.name?.replaceAll("Mrs", "")?.replaceAll("Mr", "")?.replaceAll(".", "")?.[0] ?? ""}</Avatar>
                    </Div>

                    <Div
                      id={`${parent_id}_appointmentslot_name_div`}
                      key={"1"}
                      className="qdm"
                      style={{ display: "flex", marginLeft: "16px", flex: 1, flexDirection: 'column' }}
                    >
                      <Div
                        id={`${parent_id}_appointmentslot_name_sub_div`}
                        style={{ lineHeight: 1 }}
                        key={"0"}>
                        <Text
                          id={`${parent_id}_appointmentslot_name_text`}
                          key={"0"}
                          name={this.props.name}
                          style={{
                            fontSize: "14px",
                            color: "#101010",
                            lineHeight: 1,
                            letterSpacing: 0
                          }}
                          className={"pc_medium"}
                        />
                      </Div>

                      <Div
                        id={`${parent_id}_appointmentslot_newbook_div`}
                        key={"1"}>
                        <Text
                          id={`${parent_id}_appointmentslot_newbook_text`}
                          key={"0"}
                          name={
                            this.props.IsModifyUser && !this.props.newBook
                              ? oldDate
                              : `${this.props.date}, ${this.props.time}`
                          }
                          style={{
                            fontSize: 12,
                            textDecoration:
                              (this.props.IsModifyUser && !this.props.newBook) && "line-through",
                            color: "#6F6F6F",
                            letterSpacing: 0
                          }}
                          className={"pc_regular"}
                        />
                      </Div>
                      {(this.props.IsModifyUser && !this.props.newBook) && (
                        <Div
                          id={`${parent_id}_appointmentslot_time_div`}
                          key={"1"}>
                          <Text
                            id={`${parent_id}_appointmentslot_time_text`}
                            key={"0"}
                            name={this.props.date + ", " + this.props.time}
                            style={{
                              fontSize: 12,
                              color: "#6F6F6F",
                            }}
                            className={"pc_regular"}
                          />
                        </Div>
                      )}
                    </Div>
                  </Div>

                  <Div
                    id={`${parent_id}_appointmentslot_remove_div`}
                    key={"1"}>
                    <Button
                      id={`${parent_id}_doctor_appointment_slot_remove_button`}
                      key={"0"}
                      type="button"
                      variant="text"
                      style={{
                        color: UIColor.error.color,
                        fontSize: "10px",
                        fontWeight: 600,
                      }}
                      onClick={() => this.props.rightSideAllCardfun(false)}
                      className={"pc_regular"}
                    >
                      <img src={DeleteIcons} />
                      {/* //  <deleteIcons style={{fontSize:19}}  /> */}
                    </Button>
                  </Div>
                </Row>

                <Div
                  id={`${parent_id}_appointmentslot_+Book_Multiple_div`}
                  key={"1"}
                  className="qdm"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8px",
                  }}
                >
                  {!this.props.is_unregister && <>
                    {(this.props.loginType !== "guest" && !this.props.IsModifyUser) ? (
                      <Button
                        id={`${parent_id}_appointmentslot_+Book_Multiple_button`}
                        variant="text"
                        key={"0"}
                        name={
                          this.props.isMultipleAppointment
                            ? `${this.props.multiApppointmentInfo.data.length} appointments added.`
                            : "+ Book Multiple Appointments"
                        }
                        style={{
                          color: "#2A60BC",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          if (this.props.isMultipleAppointment) {
                            this.props.changeState("fromListing", true);
                            this.setState({
                              ...this.state,
                              showHide: 1,
                              bookAppoinment: true,
                            });
                            return;
                          }
                          this.props.handleMultiAppointmentChange({
                            date: convertTime(this.props.time, "Do MMM YYYY", "DD-MM-YYYY"),
                            slotSelect: this.props.info,
                            milli: moment(this.props.time, "Do MMM YYYY").toDate().getTime(),
                          }, "appointments", "startsOn");
                          this.changeState("bookAppoinment", true)
                        }}
                        className={"pc_medium"}
                      />
                    ) : (
                      ""
                    )}</>}

                  <SideMenu
                    id={`${parent_id}_appointmentslot_Bookappointment_sidemenu`}
                    open={this.state.bookAppoinment}
                    direction="right"
                    width={650}
                    color="default"
                    onClose={() => this.changeState("bookAppoinment", false)}
                    style={{ zIndex: 2,padding:0 }}
                  >
                    {this.state.showHide === 0 && (
                      <BookMultipleAppointmentsDrawer
                        parent_id={'doctor_listing'}
                        multiApppointmentInfo={this.props.multiApppointmentInfo}
                        handleMultiAppointmentChange={this.props.handleMultiAppointmentChange}
                        changeState={this.changeState}
                        previewSchedule={(info) => {
                          if (info) {
                            this.setState({
                              ...this.state,
                              info,
                              showHide: 1
                            });
                          } else {
                            this.changeState("showHide", 1)
                          }
                        }}
                        date={this.props.time}
                        time={{ label: this.props.date }}
                        info={this.props.info}
                        hospitalInfo={this.props.hospitalInfo}
                      />
                    )}
                    {this.state.showHide === 1 && (
                      <BookMultipleAppointmentsLists
                        parent_id={'doctor_listing'}
                        info={this.state.info}
                        fromListing={this.props.fromListing}
                        changeState={this.props.changeState}
                        multiApppointmentInfo={this.props.multiApppointmentInfo}
                        handleMultiAppointmentChange={this.props.handleMultiAppointmentChange}
                        onChange={() => this.changeState("showHide", 0)}
                        confirmAppoinment={() => {
                          this.props.updateMultiSlots(true);
                          this.changeState("bookAppoinment", false)
                        }}
                      />
                    )}
                  </SideMenu>
                </Div>
              </Div>
            </Row>
          </Div>
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(DoctorAndAppointmentSlot))
);
