import React from "react";
import { UIColor } from "../../utils";

export const NotificationIcon = (props) => {
  let width = props.width ? props.width : "16";
  let height = props.height ? props.height : "17";
  let color = props.color ? props.color : UIColor.sidebarIconColor.color;
  return (
         <svg id="Notification_svg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 17"><path class="a" fill={color} d="M13.693,4.008a6.708,6.708,0,0,0-6.351,6.7v3.168L6.164,16.2l-.006.013a1.547,1.547,0,0,0,1.408,2.175h3.78a2.669,2.669,0,0,0,5.338,0h3.779a1.547,1.547,0,0,0,1.409-2.175l-.006-.013-1.179-2.324V10.539A6.626,6.626,0,0,0,13.693,4.008Zm.063,1.306a5.28,5.28,0,0,1,5.6,5.225v3.487a.643.643,0,0,0,.07.291l1.242,2.45a.2.2,0,0,1-.2.31H7.566a.2.2,0,0,1-.2-.31h0l1.242-2.449a.643.643,0,0,0,.07-.291V10.7A5.383,5.383,0,0,1,13.756,5.314ZM12.68,18.385h2.669a1.335,1.335,0,0,1-2.669,0Z" transform="translate(-6.015 -4.001)"/></svg>
     );
};