import moment from "moment";
import { getImgUrl, makeName } from "../../utils";

const getAge = (date) => {
  return Math.floor(
    moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
  );
};

export const searchCall = (data) => {
  let lista = [];
  const valuedata = data?.map((v) => {
    return lista.push({
      idnumber: "",
      idsourcecode: "",
      email: v.telecom?.find?.((tel) => tel.system === "Email")?.value ?? "",
      name: makeName(v?.name[0] || {}) || "",
      id: v?.alias ?? "",
      dis: `${
        (v?.gender?.display + ", " ?? "") +
        (v?.birthDate ? getAge(v?.birthDate, true) + "Yrs" : "")
      }${v.telecom?.[0].value ? " | " + v.telecom?.[0].value : ""}`,
      gender: v?.gender?.display.slice(0, 1) ?? "",
      age: getAge(v?.birthDate) ?? "",
      userId: v?.personId ?? 1111,
      appointmentName: "",
      mobileNumber:
        // v?.telecom?.find?.((tel) => tel.system === "Phone")?.value || v.telecom?.find?.((tel) => tel.system === "Email")?.value || "",
        v?.telecom?.[0]?.value,
      patientId: v?.id ?? "",
      img:
        getImgUrl(v?.photo?.[0]?.fileid) ||
        getImgUrl(v?.patientDetail?.[0]?.photo?.[0]?.fileid) ||
        "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
      encounter: v?.encounter,
      identifier: v?.identifier?.[0]?.value ?? "",
      _id: v?._id ?? "",
    });
  });
  return lista;
};
