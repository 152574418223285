import React from 'react';
import { makeStyles,Collapse } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CustomListing } from './customList';
import clsx from "clsx";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme)=>({
    rowCenterBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        padding:'8px 16px',
        paddingBottom: 4,
        cursor:'pointer'
    },
    flexRow:{
        display:'flex',
        alignItems:'center'
    },
    root:{
        '& .border':{
            // margin:'8px 16px',
            borderBottom:'1px solid #E0E0E0',
            '&:last-child':{
                borderBottom:0
            }
        }
    },
    children:{
        paddingLeft:35,
        paddingRight:16,
        '& .child':{
            marginBottom:8,
        }
    },
    expand: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(0deg)",
      },
}))

export const CustomDrawer = (props) =>{

    const { list } = props;

    const classes = useStyles(props);

    const history = useHistory();

    const [state,setState] = React.useState({
        index:null
    })

    const onChangeState = (key,value) =>{
        setState({
            ...state,[key]:value
        })
    }

    const handleCollapse = (index) =>{
        if(index === state.index){
            index = null;
        }
        onChangeState('index',index)
    }

    const listClick = (value,index) =>{
        if(value?.children?.length){
            handleCollapse(index)
        }
        else{
            console.log(value,index)
        }
    }

    const onClickListing = (data,index,parentIndex) =>{
        // console.log(data,index,parentIndex);
        // 
        if(data?.link){
            history.push({
                pathname:data?.link,
                state:data.label
            })
        }
        props?.onClose && props.onClose()
    }

    return(
        <div className={classes.root}>
            {list?.length && list?.map((_,index)=>{
                return(
                    <div className="border">
                        <div 
                            key={index}
                            className={classes.rowCenterBtn}
                            onClick={()=>listClick(_,index)}
                        >
                            <CustomListing 
                                //icon={_?.icon}
                                label={_?.label}
                            />
                            { _?.children?.length ? (
                            <div  className={clsx(classes.expand, {
                                [classes.expandOpen]: state.index === index ? true : false,
                              })}>
                                <ExpandLessIcon />
                            </div>) : null 
                            }
                        </div>

                        {_?.children &&
                        <Collapse
                            id={`${props?.parent_id}_collapse_parent_collapse`}
                            // className={classes.body}
                            in={state.index === index ? true : false}
                            timeout="auto"
                            unmountOnExit
                        >
                            <div className={classes.children}>
                                {_?.children?.map((child,i)=>{
                                    return(
                                        <div className="child">
                                            <CustomListing 
                                                icon={child?.icon}
                                                label={child?.label}
                                                color={child?.color}
                                                backgroundColor={child?.backgroundColor}
                                                onClick={()=>onClickListing(child,i,index)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </Collapse>
                        }
                    </div>
                )
            })}
        </div>
    )
}

CustomDrawer.defaultProps={
    list:[]
}