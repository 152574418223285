import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Modal, Div, Icons } from 'qdm-component-library'

class ModelComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {
      open,
      childrens,
      onClose,
      hiddencloseIcon,
      parent_id,
      backgroundColorNone,
    } = this.props
    return (
      <div
        id={`${parent_id}_modal_parent_div`}
      >
        <Modal
          id={`${parent_id}_modal_parent_modal`}
          open={open}
          {...this.props}
          onClose={onClose}
          inLineStyles={{
            borderRadius: 16,
            background: backgroundColorNone ? "none" : "#fff",
            marginTop: 30,
          }}
        >
          {!hiddencloseIcon && (
            <Div
              id={`${parent_id}_modal_close_div`}
              style={{
                position: 'absolute',
                right: '-30px',
                background: '#fff',
                padding: '0px 4px',
                borderRadius: '3px',
                cursor: 'pointer'
              }}
              onClick={() => onClose()}
            >
              <Icons
                id={`${parent_id}_modal_close_icons`}
                fontIcon='close'
                style={{ color: '#a2a2a2' }}
              >
              </Icons>
            </Div>
          )}
          {childrens && childrens}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, actions)(withRouter(ModelComponent))
