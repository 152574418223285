/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc withAllContexts HOC will send all the necessary Context such as AlertContext,
 * DialogContext, BackdropContext and DrawerContext as a prop.
 */

import React from "react";
import {
	NextOfKinContext,
	AlertContext,
	DialogContext,
	BackdropContext,
	CheifComplaintsContext,
	DrawerContext,
	PatientRegContext,
	FinancialFormContext,
	ReferralDetails,
	VitalsContext,
	VisitDetails,
	DataPayloadContext,
	QueueManagementContext,
	VisitFormContext,
	AllergyContext,
	DiagnosisContext,
	MedicationContext,
	TreatmentContext,
	LabOrderContext,
	RadiologyContext,
	RoleContext,
	DoccumentContext,
	AVCShowContext,
	DrawerMeetingContext,
	FirebaseContext
} from "../contexts";

const withAllContexts = (Component) => (props) => {
	const alert = React.useContext(AlertContext);
	const dialog = React.useContext(DialogContext);
	const backDrop = React.useContext(BackdropContext);
	const drawer = React.useContext(DrawerContext);
	const patientData = React.useContext(PatientRegContext);
	const nextOfKin = React.useContext(NextOfKinContext);
	const visitData = React.useContext(VisitDetails);
	const referralData = React.useContext(ReferralDetails);
	const financialData = React.useContext(FinancialFormContext);
	const dataPayload = React.useContext(DataPayloadContext);
	const visitform = React.useContext(VisitFormContext);
	const queueManagement = React.useContext(QueueManagementContext);
	const cheifComplaints = React.useContext(CheifComplaintsContext);
	const Diagnosis = React.useContext(DiagnosisContext);
	const viatalsContext = React.useContext(VitalsContext);
	const allergy = React.useContext(AllergyContext);
	const medicationsData = React.useContext(MedicationContext);
	const treatment = React.useContext(TreatmentContext);
	const LabOrder = React.useContext(LabOrderContext);
	const radiology = React.useContext(RadiologyContext);
	const role = React.useContext(RoleContext);
	const Doccument = React.useContext(DoccumentContext);
	const AVCShowList = React.useContext(AVCShowContext);
	const AVCMeeting = React.useContext(DrawerMeetingContext);
	const PushNotification = React.useContext(FirebaseContext);
	return (
		<Component
			{...props}
			allergy={allergy}
			Diagnosis={Diagnosis}
			cheifComplaints={cheifComplaints}
			financialData={financialData}
			referralData={referralData}
			medicationsData={medicationsData}
			visitData={visitData}
			nextOfKin={nextOfKin}
			patientData={patientData}
			alert={alert}
			dialog={dialog}
			backDrop={backDrop}
			drawer={drawer}
			dataPayload={dataPayload}
			visitform={visitform}
			queueManagement={queueManagement}
			viatalsContext={viatalsContext}
			treatment={treatment}
			LabOrder={LabOrder}
			radiology={radiology}
			role={role}
			Doccument={Doccument}
			AVCShowList={AVCShowList}
			AVCMeeting={AVCMeeting}
			PushNotification={PushNotification}
		>
			{props.children}
		</Component>
	);
};

export default withAllContexts;
