import React from "react";
import { Div, H6, Col, Row, Text, Avatar, Image } from 'qdm-component-library'
import "./style.css"
import { UIColor } from "../../utils";
class ShowList extends React.Component {
    render() {
        const { imgUrl, letter, title, description, ondelete, onedit, parent_ids } = this.props;
        return <Row
            id={`${parent_ids}_parent_row`}
            // className="sl-root"
            style={{border: `1px solid ${UIColor.lineBorderFill.color}`, borderRadius: "8px", background: UIColor.greyBackground.color}}>
            <Col
                id={`${parent_ids}_parent_col`}
                md={11} lg={11} sm={6} xs={12} className="sl-detail-root" inLineStyles={{paddingLeft: "0px"}}>
                <Div
                    id={`${parent_ids}_sub_div`}
                    className="sl-count">
                    <Text
                        id={`${parent_ids}_text`}
                        className="pc_medium" style={{
                            color: UIColor.secondaryText.color, margin: "auto", marginLeft: 0,  padding: "6px 10px 6px 7px", background: UIColor.lineBorderFill.color, borderTopRightRadius: "8px", borderBottomRightRadius: "8px"
                        }}>{this.props?.index ? this.props?.index : 1}</Text>
                </Div>
                <Div
                    id={`${parent_ids}_image_div`}
                    className={(imgUrl || letter) ? "sl-details" : "sl-detail"}>
                    {(imgUrl || letter) && <Avatar
                        id={`${parent_ids}_image_avatar`}
                        className="sl-details-avatar" src={imgUrl} letter={letter} />}
                    <Div
                        id={`${parent_ids}_description_div`}
                        className="sl-details-names">
                        <H6
                            id={`${parent_ids}_description_h6`}
                            style={{color: UIColor.secondaryText.color ,fontSize: "14px", margin: 0, marginBottom: "3px", lineHeight: 1, fontFamily: "pc_medium !important"}}
                        >{title ? title : "-------"}</H6>
                        <Text
                            id={`${parent_ids}_description_text`}
                            style={{
                                color: UIColor.tertiaryText.color,
                                fontSize: 12,
                            }}>{description ? description : "--------"}</Text>
                    </Div>
                </Div>
            </Col>
            <Col
                id={`${parent_ids}_image_col`}
                md={1} lg={1} sm={6} xs={12} className="sl-action-root">
                <Div
                    id={`${parent_ids}_image_div`}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {onedit &&
                        <Image
                            id={`${parent_ids}_image_edit_image`}
                            onClick={(e) => onedit(e)} style={{ cursor: 'pointer' }} src="images/icons8-edit1.svg" alt="edit" />
                    }
                    {ondelete &&
                        <Image
                            id={`${parent_ids}_image_trash_image`}
                            onClick={() => ondelete()} style={{ cursor: 'pointer', marginLeft: 15 }} src="images/icons8-trash1.svg" alt="delete" />
                    }
                </Div>
            </Col>
        </Row>

    }
}


export default ShowList;