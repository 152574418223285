import {
  makeStyles,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React from "react";
import { UIColor } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    color: UIColor.primaryText.color,
    fontFamily: "pc_semibold",
    fontSize: "14px",
    marginBottom: "12px",
  },
  icon: {
    borderRadius: 10,
    width: 17,
    height: 17,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: UIColor.greyBackground.color,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: `2px auto ${UIColor.lineBorderFill.color}`,
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: UIColor.differentiationBackground.color,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: UIColor.primaryColor.color,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    boxShadow: "none",
    "&:before": {
      display: "block",
      width: 17,
      height: 17,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: UIColor.primaryColor.color,
    },
  },
  slabHeader: {
    color: UIColor.secondaryText.color,
    fontFamily: "pc_regular",
    fontSize: "14px",
  },
  lowerHeader: {
    color: UIColor.secondaryText.color,
    fontFamily: "pc_medium",
    fontSize: "10px",
    padding: "3px 6px",
  },
  contentBox: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: "8px",
    padding: "8px 16px",
    width: "720px",
  },
  contentName: {
    maxWidth: "300px",
    marginRight: "15px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

function OrderListsSide(props) {
  const classes = useStyles();
  const handleChange = (e, value, i, index) => {
    props.handleCheckChange(e, value, i, index);
  };

  const [expanded, setExpanded] = React.useState();
  const handleExpandClick = (value, i) => {
    if (expanded === i) {
      setExpanded(null);
    } else {
      setExpanded(i);
    }
  };

  return (
    <div>
      {props?.initalData?.map((val, index) => (
        <div
          style={{
            borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
            padding: "0px 10px 12px",
            marginTop: "16px",
          }}
        >
          <Typography className={classes.rootHeader}>
            {val?.header === "Pharmacy"
              ? "Medication"
              : val?.header === "Radiology"
              ? "Rad"
              : val?.header === "Laboratory"
              ? "Lab"
              : val?.header}{" "}
            orders
          </Typography>
          <div>
            <FormGroup>
              {val?.order.map(
                (value, i) =>
                  value?.name
                    ?.toLowerCase()
                    ?.includes(props.orderListSearch?.toLowerCase()) && (
                    <FormControlLabel
                      style={{
                        paddingBottom: "10px",
                        display: props.selectedState
                          ? value.checked
                            ? "flex"
                            : "none"
                          : "flex",
                      }}
                      control={
                        <Checkbox
                          checked={value.checked}
                          onChange={(e) => handleChange(e, value, i, index)}
                          name="checked"
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      }
                      label={
                        <div className={classes.contentBox}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingBottom: "5px",
                            }}
                          >
                            <div className={classes.contentName}>
                              <Typography className={classes.slabHeader}>
                                {value?.name}
                              </Typography>
                            </div>
                            {val?.header === "Pharmacy" && value?.dose ? (
                              <div
                                style={{
                                  backgroundColor: UIColor.greyBackground.color,
                                  borderRadius: "8px",
                                  margin: "0px 8px",
                                }}
                              >
                                <Typography className={classes.lowerHeader}>
                                  {value?.dose}
                                </Typography>
                              </div>
                            ) : null}
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: UIColor.secondaryText.color,
                                fontFamily: "pc_regular",
                              }}
                            >
                              {/* -{" "} */}
                              {value?.orderQty && value?.orderQtyUOM
                                ? "- Qty " +
                                  value?.orderQty +
                                  " " +
                                  value?.orderQtyUOM?.shortdesc +
                                  " "
                                : null}
                              {value?.startDay
                                ? "- Day " + value?.startDay + " (Start day) "
                                : null}
                              {!value?.IVInfusRateUOM && value?.freqValue && value?.freqValueUnit
                                ? "- " +
                                  value?.freqValue +
                                  " " +
                                  value?.freqValueUnit?.shortdesc +
                                  " "
                                : null}
                              {!value?.IVInfusRateUOM && value?.freqRate && value?.freqRateUnit
                                ? value?.freqRate +
                                  " " +
                                  value?.freqRateUnit?.shortdesc +
                                  " "
                                : null}

                              {/* For IV alone */}
                              {value?.IVInfusRateVol && value?.IVInfusRateVolUOM ? 
                                "- " + value?.IVInfusRateVol + " " + value?.IVInfusRateVolUOM?.shortdesc + " "
                                : null}  

                              {value?.IVInfusRateUOM && value?.IVInfusRateValue ? 
                                 value?.IVInfusRateValue + " " + value?.IVInfusRateUOM?.shortdesc + " "
                                : null}
                              {/* ------------- */}
                              {value?.duration && value?.durationType
                                ? "- " +
                                  value?.duration +
                                  " " +
                                  value?.durationType?.shortdesc +
                                  " "
                                : null}
                            </Typography>
                            {value?.child?.length > 0 && (
                              <IconButton
                                // className={clsx(classes.expand, {
                                //         [classes.expandOpen]: expanded,
                                //  })}
                                className={classes.expand}
                                onClick={() => handleExpandClick(value, i)}
                                aria-expanded={expanded}
                                aria-label="show more"
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            )}
                          </div>
                          {value?.child?.length > 0 &&
                            value?.child?.map((item) => (
                              <Collapse
                                in={expanded === i ? true : false}
                                timeout="auto"
                                unmountOnExit
                              >
                                <div
                                  style={{
                                    borderTop: `1px solid ${UIColor.lineBorderFill.color}`,
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 0px",
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: UIColor.greyBackground.color,
                                      borderRadius: "8px",
                                      margin: "0px 8px",
                                    }}
                                  >
                                    <Typography
                                      className={classes.lowerHeader}
                                      style={{ color: "#5F985B" }}
                                    >
                                      Additive
                                    </Typography>
                                  </div>
                                  <Typography
                                    className={classes.slabHeader}
                                    style={{ maxWidth: "200px" }}
                                  >
                                    {item?.name}
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      color: UIColor.secondaryText.color,
                                      fontFamily: "pc_regular",
                                    }}
                                  >
                                    -{" "}
                                    {item?.orderQty && item?.orderQtyUOM
                                      ? "Qty " +
                                        item?.orderQty +
                                        " " +
                                        item?.orderQtyUOM?.shortdesc +
                                        " "
                                      : null}
                                    {item?.startDay
                                      ? "- Day " +
                                        item?.startDay +
                                        " (Start day) "
                                      : null}
                                    {item?.freqValue && item?.freqValueUnit
                                      ? "- " +
                                        item?.freqValue +
                                        " " +
                                        item?.freqValueUnit?.shortdesc +
                                        " "
                                      : null}
                                    {item?.freqRate && item?.freqRateUnit
                                      ? item?.freqRate +
                                        " " +
                                        item?.freqRateUnit?.shortdesc +
                                        " "
                                      : null}
                                    {item?.duration && item?.durationType
                                      ? "- " +
                                        item?.duration +
                                        " " +
                                        item?.durationType?.shortdesc +
                                        " "
                                      : null}
                                  </Typography>
                                </div>
                              </Collapse>
                            ))}
                        </div>
                      }
                    />
                  )
              )}
            </FormGroup>
          </div>
        </div>
      ))}
    </div>
  );
}

export default OrderListsSide;
