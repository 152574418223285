import React from 'react';
import { Col, TextInput, Select, Div, Avatar, Text, H6, Image } from 'qdm-component-library';
import { CalendarIcon, MaterialAutoComplete } from "../../../../components";
import Icon from '@material-ui/core/Icon';
import { makeStyles, Switch, Popover } from '@material-ui/core';
import { Button } from 'qdm-component-library';
import { QuantityRangePicker } from '../components'
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { useSelector, useDispatch } from "react-redux";
import { UIColor } from '../../../../utils';
import { actions } from "primarycare-binder";

const useStyles = makeStyles((theme) => ({
    switch: {
        '& .Mui-checked': {
            color: '#fff'
        },
        '& .Mui-checked + .MuiSwitch-track': {
            backgroundColor: UIColor.primaryColor.color,
            opacity: 1
        }
    },
}))

const styles_ = {
    cancelBtn: {
        padding: "4px",
        fontSize: 12,
        marginRight: 5,
        borderColor: UIColor.lineBorderFill.color,
        backgroundColor: "#fff",
        color: UIColor.primaryColor.color,
        borderRadius: 8,
        float: "right"
    },
    filledBtn: {
        backgroundColor: UIColor.primaryColor.color,
        borderColor: UIColor.primaryColor.color,
        padding: "4px",
        fontSize: 12,
        borderRadius: 8,
        float: "right"
    }
}
const UnitDoseForm = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [masters, setMasters] = React.useState([]);
    const { styles, data, optionsList,isErr } = props;
    const state = useSelector(state => state?.MedicationMastersSlice);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [reason, setReason] = React.useState("");
    const handleInputChange = (selector, value) => {
       
        if(selector === "dosage"){
            if(!(parseInt(value.min.quantity) < 0)  )
            {
                props.handleInputChange(selector, value, "unitDose")
            }
            else{}
        }
        else{
            props.handleInputChange(selector, value, "unitDose")
        }
        
    }
    console.log("unitErr",isErr);
    console.log("unitData",data );

    const handleMedication = async (v) => {
		if (v?.length > 3) {
			debugger
				let ccMaster = await dispatch(
					actions.DRUGMASTER_MASTER({ input_text: v })
				);
				debugger
				if(Array.isArray(ccMaster?.payload?.data) && ccMaster?.payload?.data.length > 0){
					setMasters(ccMaster?.payload?.data);
				} else {
					setMasters([]);
				}
		} 
		// else {
		// 	setMasters([]);
		// }
	};

    return (
        <>
            {/* drug code and description */}
            <Col
                xs={12}
                sm={12}
                md={5}
                lg={6}
                xl={6}
                inLineStyles={{ paddingTop: 5 }}
            >
                <MaterialAutoComplete
                    handleMedication={handleMedication}
                    // optionsList={state?.drugMaster_masters?.data}
                    optionsList={masters}
                    handleInputChange={handleInputChange}
                    value={data?.name}
                    placeholder={"search.."}
                    title={"Sign and Symptoms Code & Description"}
                    keyCode={"description"}
                    isRequired
                    error= {isErr && !data?.name ? true : false}
                />
            </Col>
            {/* dosage Range Picker START */}
            <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
            >
                <QuantityRangePicker
                    title="Dosage"
                    isRequired={true}
                    optionsuom={state.DosageUOM_masters.data}
                    optionsvolumuom={state.DosageVolumeUom_masters.data}
                    id="dosage"
                    handleChange={handleInputChange}
                    data={data?.dosage}
                    disabled={false}
                    error={ isErr && !data?.dosage?.min?.quantity && !data?.dosage?.min?.unit  ? true : false}
                />
            </Col>
            {/* dosage Range Picker END */}
            {/* route type START */}
            <Col
                xs={12}
                sm={12}
                md={3}
                lg={2}
                xl={2}
                inLineStyles={styles.colPadding}
            >
                <Select
                    getOptionLabel="label"
                    showArrow
                    id={"mf-route"}
                    label={"Route"}
                    isRequired
                    placeholder={""}
                    options={state?.DrugRoutes_masters?.data}
                    labelStyle={styles.labelStyle}
                    inLineStyles={isErr && !data?.route?.label ? styles.errorborderStyle : styles.borderStyle}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={data?.route?.label}
                    onChangeValue={(value) => handleInputChange("route", value)}
                />
            </Col>
            {/* route type END */}
            {/* sliding scale START */}
            <Col
                xs={4}
                sm={4}
                md={1}
                lg={1}
                xl={1}
                inLineStyles={{ ...styles.colPadding, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
            >
                <Text inLineStyles={styles.label}>
                    &nbsp;
                </Text>
                {(data?.route && false) && <Avatar style={styles.iconWrapper} onClick={() => props.handleActionClick('sliding-scale')}>
                    <AspectRatioIcon />
                </Avatar>}
            </Col>
            {/* sliding scale END */}
            {/* dosage form START */}
            <Col
                xs={12}
                sm={12}
                md={3}
                lg={2}
                xl={2}
                inLineStyles={styles.colPadding}
            >
                <TextInput
                    className={"textInput"}
                    id={"mf-dosageFrom"}
                    label={"Dosage Form"}
                    placeholder=""
                    // isRequired
                    labelStyle={styles.labelStyle}
                    style={{ ...styles.borderStyle, ...styles.inputStyle }}
                    value={data?.name?.FormData}
                    onChange={(value) => handleInputChange("dosageForm", value?.value)}
                    hoverColor={UIColor.lineBorderFill.color}
                    disabled={true}
                />
            </Col>
            {/* dosage form END */}
            {/* Strength Range Picker START */}
            <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                inLineStyles={styles.colPadding}
            >
                <QuantityRangePicker
                    title="Strength"
                    isRequired={false}
                    options={optionsList?.quantityUnitsList}
                    id="strength"
                    handleChange={handleInputChange}
                    data={data?.strength}
                    disabled={true}
                />
            </Col>
            {/* Strength Range Picker END */}
            <Col
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={3}
                inLineStyles={styles.colPadding}
            >
                <div>
                    <Text inLineStyles={styles.label} style={{ marginLeft: 10 }}>
                        PRN
                        {/* {<span style={styles.required}>*</span>} */}
                    </Text>
                </div>
                <div>
                    <Text onClick={(e) => {
                        if (!data?.prn) {
                            setAnchorEl(e?.currentTarget)
                        }
                    }}><Switch
                            checked={data?.prn}
                            onChange={(event) => {
                                handleInputChange("prn", !data?.prn)
                                setReason(data?.prnCommnts)
                            }}
                            className={classes.switch}
                        /></Text>
                    {
                        (data?.prnCommnts && data?.prn) && <Image onClick={(e) => {
                            setReason(data.prnCommnts)
                            setAnchorEl(e?.currentTarget)
                        }} style={{ width: "20px", height: "20px", marginLeft: "33px" }} src={'images/icons8-file.svg'} alt="file" />
                    }
                    <Popover
                        id={"calender-popover"}
                        open={anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                        classes={{ paper: styles.muiPopovers }}
                    >
                        <div style={{ width: "305px", padding: "15px 8px" }}>
                            <div>
                                <H6 style={{ float: "left", fontSize: "16px", margin: "0px 0px 0px 4px" }} >Add Remarks</H6>
                                <Button inLineStyles={styles_.filledBtn} onClick={() => {
                                    handleInputChange("prnCommnts", reason)
                                    setReason("")
                                    setAnchorEl(null)
                                }}>Save</Button>
                                <Button inLineStyles={styles_.cancelBtn} onClick={() => setAnchorEl(null)}>Cancel</Button>
                            </div>
                            <div>
                                <TextInput
                                    label={"Reason"}
                                    placeholder="Add Reason"
                                    labelStyle={styles.labelStyle}
                                    style={{ ...styles.borderStyle, height: "86px", overflow: "auto" }}
                                    hoverColor={UIColor.lineBorderFill.color}
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </div>
                        </div>
                    </Popover>
                </div>
            </Col>
        </>
    )
}

export default UnitDoseForm;