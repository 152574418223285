import React from "react";
import CarousalSupport from "./carousalSupport";
import {TopNavBarAvc} from "../../../components";
import SupportHome from "./supportHome";

function SupportPages(props) {
  return (
    <div>
      <TopNavBarAvc  /> 
      <SupportHome />
      {/* <CarousalSupport /> */}
    </div>
  );
}

export default SupportPages;
