import moment from "moment";
import { getUtcTime, makeName, utcTOLocal } from "../../../utils";

export const generateJson = (data, stateList) => {
  let list = data.map((value) => {
    return {
      ...value,
      editId: value._id,
      createdAt: parseInt(value.updatedate)
        ? utcTOLocal(value.updatedate, "DD MMM, YYYY")
        : utcTOLocal(value.updatedate?.split(" ")[0], "DD MMM, YYYY"),
      createdName: makeName(value.createdby?.[0]?.name || {}) ?? "You",
      // name1: stateList.drugMaster_masters?.data.filter(val => val.value === value.drugCode?._id)[0],
      name: {
        value: value.drugCode?._id,
        name: value.drugCode?.LongDesc,
        label: value.drugCode?.LongDesc,
        id: value.drugCode?.id,
        drugroutegenid: value.drugCode?.drugroutegenid,
        drugTypeId: value.drugCode?.drugTypeId,
        drugType: value?.drugType?.shortdesc,
      },
      status: stateList.status_masters?.data.filter(
        (val) => val.value === value.status
      )[0],
      unitValue: stateList.strengthUom_masters?.data.filter(
        (val) => val.value === value.dosageUOM?._id
      )[0],
      doseMeasurementValue: value.dosageValue,
      unitsTypes: stateList.FrequencyMaster_masters?.data.filter(
        (val) => val.value === value.dosageFreqCode?._id
      )[0],
      dosetime: value.dosageDurationValue,
      doseOption: stateList.DurationType_masters?.data.filter(
        (val) => val.value === value.dosageDurationUOM?._id
      )[0],
      //   doseOption: value.drugCode?._id,
    };
  });

  return list;
};

export const CreateJson = (state, editData) => {
  let json = {};
  if (state.formType === "IV") {
    json = {
      name: state.ivForm.baseSolution,
      formCode: state.ivForm.baseSolution?.FormCode ?? "",
      unitValue: state.ivForm.volume.unit,
      doseMeasurementValue: state.ivForm.volume.quantity,
      routeCode: state.ivForm.route,
      ivInfusionRateUOM: state.ivForm.infusionRate.unit,
      ivInfusionRateValue: state.ivForm.infusionRate.quantity,
      totPresQty: state.ivForm.totalVolume.quantity,
      totPresQtyUOM: state.ivForm.totalVolume.unit,
      IVDosageDurationValue: state.ivForm.duration.quantity,
      IVDosageDurationUOM: state.ivForm.duration.unit,
      additiveDetails: state.ivForm.additiveDetails,
      PRNYN: state.ivForm.prn,
      prnComment: state.ivForm.prnCommnts,
    };
  } else {
    json = {
      name: state.unitDose.name,
      formCode: state.unitDose?.name?.FormCode ?? "",
      unitValue: state.unitDose.dosage.min.unit,
      doseMeasurementValue: state.unitDose.dosage.min.quantity,
      routeCode: state.unitDose.route,
      PRNYN: state.unitDose.prn,
      prnComment: state.unitDose.prnCommnts,
    };
  }
  if (json["name"]) {
    json = JSON.parse(JSON.stringify(json));
    json.name.drugTypeId = state?.drugType?.value;
  }
  let UseAsFreqDose = [];
  if (state.frequency?.label === "EVERY THURSDAY AND FRIDAY") {
    UseAsFreqDose =
      state.frequency?.label === "EVERY THURSDAY AND FRIDAY"
        ? state.frequencyList?.["EVERY THURSDAY AND FRIDAY"].column
        : [];
  }
  if (state.frequency?.label === "Twice Daily") {
    UseAsFreqDose =
      state.frequency?.label === "Twice Daily"
        ? state.frequencyList?.["Twice Daily"].column
        : [];
  }
  return {
    ...json,
    editId: editData.editId,
    status: state.statusData?.value ?? "",
    statusreason: state?.statusReason ?? "",
    statusperiod: state?.statusperiod
      ? moment(state.statusperiod, "DD-MM-YYYY,h:mm a").unix()
      : 0,
    // workFlowStatus: state.statusData?.code ?? "",
    unitsTypes: state.frequency,
    dosetime: state.frequencyDuration.quantity,
    doseOption: state.frequencyDuration.unit,
    occurStartDateTime:
      moment(state.startDate, "DD-MM-YYYY,h:mm a").unix() ?? 0,
    occurEndDateTime: moment(state.endDate, "DD-MM-YYYY,h:mm a").unix() ?? 0,
    priority: state.priority,
    requestor: state.requestorDetails,
    presNature: state.nature,
    presMode: state.mode,
    diagnosis: state.selectDiagnosis?.map((val) => val.value) ?? [],
    indication: state.indication,
    notes: state.notes,
    drug_category: state.drugCategory,
    dispensing_pharmacy: state.dispensingPharmacy,
    patientInstruction: state.patientInstruction,
    patientInstructiondesc: state.patientInstructionDes,
    useAsTaperedDose:
      state.frequency?.label === "TAPERING"
        ? state.frequencyList.TAPERING.column
        : [],
    UseAsFreqDose,
  };
};

export const getStatusHistory = (status, EditData, personId, state) => {
  let history = EditData.statushistory
    ? JSON.parse(JSON.stringify(EditData.statushistory))
    : [];
  history = history?.map((val) => {
    let Pid = val.PersonID?.[0]._id;
    val.PersonID = Pid;
    delete val.period;
    return val;
  });
  let oldStatus = history?.[history?.length - 1]?.new_status ?? "";
  if (status?.label !== oldStatus) {
    history.push({
      PersonID: personId,
      old_status: oldStatus,
      status_reason: state?.statusReason,
      //"toperiod":getUtcTime(),
      //"fromperiod": history?.[history?.length - 1]?.fromperiod ?? 0,
      new_status: status?.label,
    });
  }
  return history;
};

export const getStatusHistoryList = (
  status,
  statushistory,
  personId,
  statusReason
) => {
  let history = statushistory ? JSON.parse(JSON.stringify(statushistory)) : [];
  history = history.map((val) => {
    let Pid = val.PersonID?.[0]?._id ?? 0;
    val.PersonID = Pid;
    delete val.period;
    return val;
  });
  let oldStatus = history?.[history?.length - 1]?.new_status ?? "";
  if (status.label !== oldStatus) {
    history.push({
      PersonID: personId,
      old_status: oldStatus,
      status_reason: statusReason,
      //"toperiod": getUtcTime(),
      //"fromperiod": history?.[history?.length - 1]?.fromperiod ?? 0,
      new_status: status.label,
    });
  }
  return history;
};
