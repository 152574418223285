import React from 'react';
import { TableComponent, ModelComponent } from '../../../../components';
import { Routes } from '../../../../router/routes';
import { WebDataRocksTable } from '../../../../components';
import CancelBookingPage from '../../../cancelBooking';
import { ConfirmationPage } from '../../../';
import '../index.css';
import { actions } from "primarycare-binder";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { SideMenu } from "qdm-component-library";
import { withRouter } from 'react-router-dom';
import { makeName } from '../../../../utils';

const visitData = ['firstVisits', 'otherVisits', 'walkIns', 'newPatients', 'todaysVisit'];

const Header = [
    { label: "Name" }, { label: "MRN" }, { label: "Age" },
    { label: "Mobile No" }, { label: "Appt Ref.No" }, { label: "Appt Date & Time" },
    { label: "Encounter No" }, { label: "Visit Type" }, { label: "Status" }, { label: "Actions" },
]
const Header1 = [
    { label: "Name & MRN" }, { label: "Ref.No" }, { label: "Date & Time" },
    { label: "Visit Type" }, { label: "Speciality & Clinic" }, { label: "Appointment Status" }, { label: "Actions" },
]

let ND = [
    { "uniqueName": "name", },
    { "uniqueName": "refNo", },
    { "uniqueName": "date" },
    { "uniqueName": "visitType" },
    { "uniqueName": "speciality" },
    { "uniqueName": "appointmentStatus" },
    { "uniqueName": "action" }
]
let FD = [
    { "uniqueName": "name", },
    { "uniqueName": "mrn", },
    { "uniqueName": "age" },
    { "uniqueName": "contact" },
    { "uniqueName": "visitType" },
    { "uniqueName": "date" },
    { "uniqueName": "refNo" },
    { "uniqueName": "appointmentStatus" },
    { "uniqueName": "action" },
]

const Appointments = (props) => {
    let write = props.write;
    let listingType = write?.indexOf("listingScreenF") > -1 ? 1 : write?.indexOf("listingScreenD") > -1 ? 2 : 3
    const dispatch = useDispatch();

    const REPORT = (data) => {
        return {
            dataSource: {
                dataSourceType: 'json',
                data: data
            },
            options: {
                grid: {
                    type: 'flat',
                    showTotals: "off",
                    showGrandTotals: "off",
                },
               
            },
            formats: [{
                "thousandsSeparator": "",
                "decimalPlaces": 0,
            }]
        }
    }

    const [state, setState] = React.useState({
        report: props?.report?.length ? REPORT(props?.report) : []
    })
    const [mainState, setMainState] = React.useState({ cancelDetails: null, cancelbookings: false, cancelbookingreason: {} })
    const [viewApp, setViewApp] = React.useState({
        open: false,
        appointmentId: null,
    })

    React.useEffect(() => {
        setState({
            ...state, report: props?.report?.length ? REPORT(props?.report) : []
        })
    }, [props?.report])
    const customizeCellFunction = (cellBuilder, cellData) => {
        if (cellData?.measure?.uniqueName === "contact") {
            cellBuilder.text=`
            <div class="labelStyle">
                ${(cellData?.label).replace(/"/g, "")}
            </div>`
        } else if (cellData?.measure?.uniqueName === "name") {
            if (cellData?.member?.caption) {
                let data = JSON.parse(cellData?.member.caption)
                cellBuilder.text = `
                    <div >
                        <p class="wd-profile-parent">
                        <span class="wd-profile-name">${data.name}</span><span class="${data.genderData ? 'wd-profile-sideLineage' : 'wd-profile-age'}">${data.age}</span>${data.genderData ? `<span class="wd-profile-gender">${data.genderData}</span>` : ''}
                        </p>
                        <p>
                            ${data?.mrn ? `<span class="wd-profile-mrn">${data?.mrn}</span>` : ''}<span class="wd-profile-dot" /><span class="wd-profile-contact">${data.contact}</span>
                        </p>
                    </div>`
            } else {
                cellBuilder.text = 'Name & MRN'
            }
        } else if (cellData?.measure?.uniqueName === "visitType" && cellData?.member?.caption) {
            cellBuilder.text = `
                <div >
                    <p class="wd-profile-parent">
                    <span class="wd-profile-name">OP</span><span>,${cellData?.member.caption}</span>
                    </p>
                </div>`
        } else if (cellData?.measure?.uniqueName === "date") {
            if (cellData?.member?.caption) {
                let data = JSON.parse(cellData?.member.caption)
                cellBuilder.text = `
                <div >
                    <p class="wd-profile-parent">
                        <span class="wd-profile-name">${data.date}</span>
                    </p>
                    <p>
                        <span class="wd-profile-mrn">${data.time}</span>
                    </p>
                </div>`
            } else {
                cellBuilder.text = 'Date & Time'
            }
        } else if (cellData?.measure?.uniqueName === "speciality") {
            if (cellData?.member?.caption) {
                let data = JSON.parse(cellData?.member.caption)
                cellBuilder.text = `
                <div >
                    <p class="wd-profile-parent">
                        <span class="wd-profile-name">${data.clinic}</span>
                    </p>
                    <p>
                        <span class="wd-profile-mrn">${data.speciality}</span>
                    </p>
                </div>`
            } else {
                cellBuilder.text = 'Speciality & Clinic'
            }
        } else if (cellData?.measure?.uniqueName === "action") {
            if (cellData?.member?.caption) {
                cellBuilder.text = `<div 
                style='display:flex;align-items:center;justify-content:center;cursor:pointer'
                >
                <svg 
                {...props}
                  xmlns="http://www.w3.org/2000/svg"
                  width=15px
                  height=15px
                  viewBox="0 0 14 10.18"
                >
                   
                  <path class="cls-7" d="M8,4C3.634,4,1.562,7.74,1.06,8.808a.66.66,0,0,0,0,.563C1.562,10.439,3.634,14.18,8,14.18c4.348,0,6.422-3.714,6.933-4.8a.686.686,0,0,0,0-.587C14.419,7.714,12.346,4,8,4ZM8,5.909A3.181,3.181,0,1,1,4.816,9.09,3.182,3.182,0,0,1,8,5.909ZM8,7.181A1.909,1.909,0,1,0,9.907,9.09,1.909,1.909,0,0,0,8,7.181Z" transform="translate(-0.997 -4)"/>
                  </svg>
            </div>`
            } else {
                cellBuilder.text = `Action`
            }
        } else if (cellData?.type !== "header") {
            cellBuilder.addClass("imgDiv");
            cellBuilder.text = `<div class='labelStyle'>
                ${cellData?.label}
            </div>`;
        }
    }
    const customizeCellFunctionFrontDesk = (cellBuilder, cellData) => {
        if (cellData?.measure?.uniqueName === "contact") {
            cellBuilder.text=`
            <div class="labelStyle">
                ${(cellData?.label).replace(/"/g, "")}
            </div>`
        } else if (cellData?.measure?.uniqueName === "action") {
            if (cellData?.member?.caption) {
                cellBuilder.text = `<div 
                style='display:flex;align-items:center;justify-content:center;cursor:pointer'
                >
                <svg 
                {...props}
                  xmlns="http://www.w3.org/2000/svg"
                  width=15px
                  height=15px
                  viewBox="0 0 14 10.18"
                >
                   
                  <path class="cls-7" d="M8,4C3.634,4,1.562,7.74,1.06,8.808a.66.66,0,0,0,0,.563C1.562,10.439,3.634,14.18,8,14.18c4.348,0,6.422-3.714,6.933-4.8a.686.686,0,0,0,0-.587C14.419,7.714,12.346,4,8,4ZM8,5.909A3.181,3.181,0,1,1,4.816,9.09,3.182,3.182,0,0,1,8,5.909ZM8,7.181A1.909,1.909,0,1,0,9.907,9.09,1.909,1.909,0,0,0,8,7.181Z" transform="translate(-0.997 -4)"/>
                  </svg>
            </div>`
            } else {
                cellBuilder.text = `Action`
            }
        } else if (cellData?.measure?.uniqueName === "appointmentStatus" && !cellData?.member?.caption) {
            cellBuilder.text = `Status`
        } else if (cellData?.measure?.uniqueName === "visitType" && !cellData?.member?.caption) {
            cellBuilder.text = `Visit Type`
        } else if (cellData?.measure?.uniqueName === "practitionerName" && !cellData?.member?.caption) {
            cellBuilder.text = `Practitioner`
        } else if (cellData?.measure?.uniqueName === "encounterNo" && !cellData?.member?.caption) {
            cellBuilder.text = `Encounter No.`
        } else if (cellData?.measure?.uniqueName === "modifyaction") {
            if (cellData?.member?.caption) {
                cellBuilder.text = `<div 
                style='display:flex;align-items:center;justify-content:center;cursor:pointer'
                >
                <svg 
                {...props}
                  xmlns="http://www.w3.org/2000/svg"
                  width=15px
                  height=15px
                  viewBox="0 0 12.666 14"
                >
                    <path xmlns="http://www.w3.org/2000/svg" class="cls-7" d="M9.5,4A1.5,1.5,0,0,0,8,5.5V15.833a1.5,1.5,0,0,0,1.5,1.5h3.181a1.167,1.167,0,0,1,.062-.245h0c.018-.075.04-.153.063-.234l.15-.522H9.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,9.5,5h3.833V7.833a1.5,1.5,0,0,0,1.5,1.5h2.833v1.277a2.208,2.208,0,0,1,1-.547V8.833a.5.5,0,0,0-.146-.353L14.187,4.146A.5.5,0,0,0,13.833,4Zm4.833,1.707,2.626,2.626H14.833a.5.5,0,0,1-.5-.5Zm4.833,4.96a1.568,1.568,0,0,0-.277.025,1.545,1.545,0,0,0-.814.445l-3.933,3.933a1,1,0,0,0-.253.428l-.523,1.827a.506.506,0,0,0-.033.2A.494.494,0,0,0,13.81,18a.508.508,0,0,0,.2-.033l1.827-.523a1,1,0,0,0,.428-.253L20.2,13.258a1.545,1.545,0,0,0,.445-.814,1.6,1.6,0,0,0,.025-.277,1.5,1.5,0,0,0-1.5-1.5Z" transform="translate(-8 -4)"/>
                  </svg>
            </div>`
            } else {
                cellBuilder.text = `Modify Action`
            }
        } else if (cellData?.measure?.uniqueName === "cancelaction") {
            if (cellData?.member?.caption) {
                cellBuilder.text = `<div 
                style='display:flex;align-items:center;justify-content:center;cursor:pointer'
                >
                <svg 
                {...props}
                xmlns="http://www.w3.org/2000/svg"
                width=15px
                height=15px
                viewBox="0 0 12.667 14"
                >
            <path xmlns="http://www.w3.org/2000/svg" class="cls-7" d="M9.5,4A1.5,1.5,0,0,0,8,5.5V15.833a1.5,1.5,0,0,0,1.5,1.5h4.378a4.34,4.34,0,0,1-.719-1H9.5a.5.5,0,0,1-.5-.5V5.5A.5.5,0,0,1,9.5,5h3.833V7.833a1.5,1.5,0,0,0,1.5,1.5h2.833v.723a4.309,4.309,0,0,1,1,.276v-1.5a.5.5,0,0,0-.146-.353L14.187,4.146A.5.5,0,0,0,13.833,4Zm4.833,1.707,2.626,2.626H14.833a.5.5,0,0,1-.5-.5ZM17,10.667a3.667,3.667,0,1,0,3.667,3.667A3.667,3.667,0,0,0,17,10.667ZM11.167,11a.5.5,0,1,0,0,1h2.186a4.353,4.353,0,0,1,.88-1Zm4.167,1.333a.332.332,0,0,1,.236.1L17,13.862l1.431-1.431a.333.333,0,1,1,.471.471l-1.431,1.431L18.9,15.764a.333.333,0,1,1-.471.471L17,14.8l-1.431,1.431a.333.333,0,1,1-.471-.471l1.431-1.431L15.1,12.9a.333.333,0,0,1,.236-.569Zm-4.167,1a.5.5,0,0,0,0,1h1.5a4.338,4.338,0,0,1,.12-1Z" transform="translate(-8 -4)"/>
          </svg>
            </div>`
            } else {
                cellBuilder.text = `Cancel Action`
            }
        } else if (cellData?.type !== "header") {
            cellBuilder.addClass("imgDiv");
            cellBuilder.text = `<div class='labelStyle'>
                ${cellData?.label}
            </div>`;
        }
    }

    const cellClick = (data) => {
        let val = JSON.parse(data.label)
        if (data.hierarchy.uniqueName === "action") {
            openView(null, val)
        }
        // else if (data.columnIndex === 9) {
        //     handleModify(null, val)
        // } else if (data.columnIndex === 10) {
        //     handlesidebarCancelbooking(null, val)
        // }
    }

    const fetchAppInfo = async (id) => {
        const data = await dispatch(actions.APPOINTMENT_READ({ appointmentId: id }));
        return data?.payload?.data || {};
    };

    const openView = (e, val, index) => {
        // debugger
        // return false
        if (visitData.indexOf(props.selectedTab.value) > -1) {
            props.history.push({
                pathname: Routes.patientVisitModify,
                state: {
                    role: "view",
                    appointmentId: val?.appNo ?? null,
                    patientId: val?.patientId,
                    walkin: val.visitType === "walkin",
                    personId: val?.personId,
                    appointment_Id: val?.appNo ?? null,
                    encounter_id: val.enId,
                    mobileNo: val.contact,
                },
            });
            return false
        }
        setViewApp({
            open: !viewApp.open,
            appointmentId: val?.appNo ?? null,
        });
    };

    const handleModify = async (e, val, index) => {
        // props.history.push(Routes.doctorListingWithIdCall+"123")
        // props.history.push(Routes.doctorListing)
        if (visitData.indexOf(props.selectedTab.value) > -1) {
            props.history.push({
                pathname: Routes.patientVisitModify,
                state: {
                    role: "modify",
                    appointmentId: val?.appNo ?? null,
                    patientId: val?.patientId,
                    walkin: val.visitType === "walkin",
                    personId: val?.personId,
                    appointment_Id: val?.appNo ?? null,
                    encounter_id: val.enId,
                    mobileNo: val.contact,
                },
            });
            return false
        }

        const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(val.appNo)));
        appInfo["HealthcareService"] = appInfo["healthservice"];
        delete appInfo["healthservice"];
        props.history.push({
            pathname: Routes.doctorListing,
            state: {
                cityName: 0,
                location: {
                    address: "Chennai, Tamil Nadu, India",
                    latitude: 13.0826802,
                    longitude: 80.2707184,
                    city: "Chennai",
                    location: "Chennai",
                },
                hospital: {
                    label: "",
                    value: appInfo?.orgid?.id || 0,
                },
                specialities: [],
                symptoms: [
                    {
                        label: "Fever",
                        type: "Symptom",
                        value: "SymptomsMapping/10000",
                    },
                ],
                selectedUser: {
                    //name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
                    name: makeName(appInfo?.Person?.[0]?.name?.[0]),
                    gender: appInfo?.Person?.[0]?.gender ?? "",
                    birthDay: appInfo?.Person?.[0]?.birthDay,
                },
                symptomsSearch: [],
                slotId: appInfo.slotID[0],
                modifyUser: true,
                newBook: false,
                appointMentInfo: appInfo,
                //unregister : { is_Unregister :  props.location.state.unregister}
            },
        });
    };

    const handlesidebarCancelbooking = async (e, val, index) => {

        if (visitData.indexOf(props.selectedTab.value) > -1) {
            props.history.push({
                pathname: Routes.patientVisitCancel,
                state: {
                    role: "cancel",
                    appointmentId: val?.appNo ?? null,
                    patientId: val?.patientId,
                    walkin: val.visitType === "walkin",
                    personId: val?.personId,
                    appointment_Id: val?.appNo ?? null,
                    encounter_id: val.enId,
                    mobileNo: val.contact,
                },
            });
            return false
        }

        setMainState({ ...mainState, cancelDetails: val, cancelbookings: !mainState.cancelbookings })
    }
    const handleSelectCancelbooking = (v) => {
        setMainState({
            ...mainState,
            cancelbookingreason: v,
        });
    };
    const handlecahngeCancelbooking = async (v, number) => {
        if (v === "back") {
            setMainState({ ...mainState, cancelbookings: 0 })
            return;
        }
        if (!v) {
            handlesidebarCancelbooking();
            // window.location.href = "/profile";
            // this.setState({
            //   cancelbookings: 0,
            // });
            return;
        }
        const mobile = number;
        if (v === 1) {
            await dispatch(actions.GENERATE_OTP({ mobileNo: mobile }));
        }
        setMainState({ ...mainState, cancelbookings: v })
    };
    return (
        <div>
            {/* && write?.length > 0 */}
            {(write?.length > 0) && <React.Fragment>
                {listingType === 1 ? <div id="WDRT-FD">
                    <WebDataRocksTable
                        report={state?.report}
                        customizeCellFunction={customizeCellFunctionFrontDesk}
                        cellClick={(data) => cellClick(data)}
                    /></div>
                    : <div id="WDRT-RD"><WebDataRocksTable
                        report={state?.report}
                        customizeCellFunction={customizeCellFunctionFrontDesk}
                    /></div>}
            </React.Fragment>}
            {/* {listingType === 1 ? <TableComponent
                Header={Header}
                dataList={props?.report ?? []}
                orderBy="name"
                orderByHeader="Name"
                modifyFunc={handleModify}
                viewFunc={openView}
                cancelFunc={handlesidebarCancelbooking}
                tableData={[
                    { type: ["TEXT"], name: "name" },
                    { type: ["TEXT"], name: "mrn" },
                    { type: ["TEXT"], name: "age" },
                    { type: ["TEXT"], name: "contact" },
                    { type: ["TEXT"], name: "appNo" },
                    { type: ["TEXT"], name: "date" },
                    { type: ["TEXT"], name: "enId" },
                    { type: ["TEXT"], name: "visitType" },
                    { type: ["TEXT"], name: "appointmentStatus" },
                    { type: ["MODIFY", "EYE", "CANCEL"], name: "actions" },
                ]}
            /> : <TableComponent
                Header={Header1}
                viewFunc={openView}
                dataList={props?.report ?? []}
                // dataList={[
                //     { name: "Name", mrn: "mrn", speciality: "Dermatology", clinic: "Jeremy Marshall", ward: "Ward 1", type: "OP", date: "24 Apr, 2022", time: "10:30 AM", age: 21, gender: "M", mobileno: "mobileno", apptRefNo: "apptRefNo", ApptDateTime: "ApptDateTime", encounterNo: "encounterNo", visitType: "visitType", status: "status" },
                //     { name: "Name312", mrn: "mrn", speciality: "Dermatology", clinic: "Jeremy Marshall", ward: "Ward 2", type: "OP", date: "24 Apr, 2022", time: "10:30 AM", age: 21, gender: "M", mobileno: "mobileno", apptRefNo: "apptRefNo", ApptDateTime: "ApptDateTime", encounterNo: "encounterNo", visitType: "visitType", status: "status" },
                //     { name: "Name21", mrn: "mrn", speciality: "Dermatology", clinic: "Jeremy Marshall", ward: "Ward 3", type: "OP", date: "24 Apr, 2022", time: "10:30 AM", age: 21, gender: "M", mobileno: "mobileno", apptRefNo: "apptRefNo", ApptDateTime: "ApptDateTime", encounterNo: "encounterNo", visitType: "visitType", status: "status" }
                // ]}
                tableData={[
                    {
                        type: ["PROFILE"], name: "name", subData: {
                            imgUrl: "", name: "name", age: "age", gender: "gender", mrn: "mrn", mobileno: "contact"
                        }
                    },
                    { type: ["TEXT"], name: "refNo" },
                    {
                        type: ["TWOLAYER"], name: "datTime", subData: {
                            top: "date", bottom: "time"
                        }
                    },
                    {
                        type: ["TWOLAYERWITHDOTTOP"], name: "visitType", subData: {
                            topOne: "type", topTwo: "visitType", bottom: "mrn"
                        }
                    },
                    {
                        type: ["TWOLAYERWITHDOTBOTTOM"], name: "specialityClinic", subData: {
                            bottomOne: "speciality", bottomTwo: "ward", top: "clinic"
                        }
                    },
                    { type: ["TEXT"], name: "appointmentStatus" },
                    { type: ["EYE"], name: "actions" },
                ]}
            />} */}
            <SideMenu
                open={mainState.cancelbookings}
                direction="right"
                width={435}
                color="default"
                onClose={() => handlesidebarCancelbooking()}
            >
                <CancelBookingPage
                    cancelbookingreason={mainState.cancelbookingreason}
                    appointmentId={mainState?.cancelDetails?.appNo ?? 0}
                    cancelbookings={mainState.cancelbookings === true ? 0 : mainState.cancelbookings}
                    handleSelectCancelbooking={handleSelectCancelbooking}
                    handlecahngeCancelbooking={handlecahngeCancelbooking}
                />
            </SideMenu>
            <ModelComponent
                parent_id={'patient_dashboard'}
                hiddencloseIcon={true}
                open={viewApp.open}
                onClose={openView}
                childrens={
                    <ConfirmationPage
                        parent_id={'patient_dashboard'}
                        appointmentId={viewApp.appointmentId}
                        appointmentDetails={true}
                        hidden={true}
                    />
                }
                width={530}
                backgroundColorNone
            />
        </div>
    )
}
export default withRouter(Appointments)