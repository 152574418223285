/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/12/202
 * @modify 12/12/202
 * @desc Providing the assemble registration from /src/context which is used in /src/App.js
 */

import React from "react";
import {
  PatientRegContext,
  NextOfKinContext,
  VisitDetails,
  ReferralDetails,
  FinancialFormContext,
  DataPayloadContext,
  QueueManagementContext,
  VisitFormContext,
  RoleContext
} from "./";
const AssembleReg = (props) => {
  let [patientData, setPatientData] = React.useState({
    identification: [{}],
    names: [{}],
    details: {},
    communication: {},
    contact: {},
    address: [],
  });
  let [nextOfKin, setNextOfKin] = React.useState({
    identification: [{}],
    names: [{}],
    details: {},
    contact: {},
    address: [],
    addformdetails: [],
    index: null,
  });

  let [visitData, setVisitDetails] = React.useState({
    encounter: {},
    practitioner: {},
    device: {},
    helthcare: {},
    accompained: {},
  });
  let [referralData, setReferralDetails] = React.useState({
    letter: {},
    from: {},
    to: {},
    details: {},
    note: {},
  });

  let [financialData, setFinancialData] = React.useState({
    payer: {},
    validity: {},
    membership: {},
    eligibility: {},
    financial: {},
    applicable: {},
    savedata: [],
    index: null,
  });

  let [additnaldata, setadditnaldata] = React.useState({
    additnaldata: {},
    birthdetails: {},
  });

  const [allData, setAllData] = React.useState([]); 
  const [data, setQueueData] = React.useState([]);
  const [onHoldData, setOnHoldData] = React.useState([]);
  const [previousData, setPreviousData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]);
  const [displayPreviousData, setDisplayPreviousData] = React.useState([]);

  let [visitform, setvisitform] = React.useState({
    encounterInfo: {},
    bookingdetails: {},
    pactitioner: {},
    deviceDetails: {},
    reasonDetails: {},
    setvisitform: () => null,
  });

  let [role, setData] = React.useState({
    role: null,
    setData: () => null,
  });
  return (
    <PatientRegContext.Provider value={{ ...patientData, setPatientData }}>
      <NextOfKinContext.Provider value={{ ...nextOfKin, setNextOfKin }}>
        <VisitDetails.Provider value={{ ...visitData, setVisitDetails }}>
          <ReferralDetails.Provider
            value={{ ...referralData, setReferralDetails }}
          >
            <FinancialFormContext.Provider
              value={{ ...financialData, setFinancialData }}
            >
              <DataPayloadContext.Provider
                value={{ ...additnaldata, setadditnaldata }}
              >
                <QueueManagementContext.Provider
                  value={{
                    data: [...data],
                    setData: setQueueData,
                    allData,
                    setAllData,
                    onHoldData,
                    setOnHoldData,
                    previousData,
                    setPreviousData,
                    displayData,
                    displayPreviousData,
                    setDisplayData,
                    setDisplayPreviousData,
                  }}
                >
                  <VisitFormContext.Provider
                    value={{ ...visitform, setvisitform }}
                  >
                    <RoleContext.Provider
                      value={{ ...role, setData }}
                    >
                      {props.children}
                    </RoleContext.Provider>
                  </VisitFormContext.Provider>
                </QueueManagementContext.Provider>
              </DataPayloadContext.Provider>
            </FinancialFormContext.Provider>
          </ReferralDetails.Provider>
        </VisitDetails.Provider>
      </NextOfKinContext.Provider>
    </PatientRegContext.Provider>
  );
};

export default AssembleReg;
