import React, { useState } from "react";
import { Div, Text, Col, Icons, Image } from "qdm-component-library";
import { CalendarIcon } from "../";
import { Popover, makeStyles } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getReccuranceDates, utcTOLocal } from "../../utils";

const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    // border:'1px solid #E0E0E0',
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
});

export const OrderStatus = (props) => {
  const classes = useStyles(props);

  const styles = {
    ordersCard: {
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 10,
      display: "flex",
      alignItems: "center",
    },
    ordersText: {
      fontSize: 12,
      color: "#323232",
    },
    dot: {
      width: 5,
      height: 5,
      borderRadius: "50%",
      backgroundColor: "#6B6B6B",
      margin: "0px 2px",
      display: "inline-block",
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [datas, setdata] = useState(null);

  const handleClick = (val, event) => {
    setdata(val)
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const data = props.data?.childRecurrent?.sort(function (a, b) {
    return a.apptDate - b.apptDate;
  });
  console.log("actualData",data);
  const getSelectedDate = (date,time,data) => {
    const actualDate = date?.slice(0,11);
    const actualTime = time?.split(",").pop("");
    if(data?.val?.recurrentData?.startsOn?.dateTime === time){
      const actualunixCode = moment(actualDate+actualTime, 'YYYY-MM-DD,hh:mm A').unix()
      const newReturndate = new Date(actualunixCode * 1000)
      return newReturndate
    }else{
      const newUnixCode = moment(date, 'YYYY-MM-DD,hh:mm A').unix()
      return new Date(newUnixCode * 1000)
    }
  }
  return (
    <Div style={{ display: "flex", overflowX: "auto", width: "100%" }}>
      {/* {props.data.childRecurrent?.length} */}
      {data?.map((val, i) => {
        return (
          <Col sm={12} md={5} lg={5} xl={5} inLineStyles={{ padding: 5 }}>
            <Div inLineStyles={styles.ordersCard}>
              <Div style={{ flex: 1 }}>
                <Div
                  style={{
                    marginBottom: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text className="pc_semibold" style={styles.ordersText}>
                    {/* {val?.startDate} */}
                    {utcTOLocal(val?.startDate,"DD MMM")} {(val?.recurrentData?.startsOn?.dateTime === val?.dateTime ? val?.dateTime : val?.startDate).split(",").pop("")}
                  </Text>
                  <Text
                    className="pc_regular"
                    style={{
                      ...styles.ordersText,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp;
                    <Div style={styles.dot} />
                    &nbsp;{val?.priority?.label}
                  </Text>
                  <Text
                    className="pc_regular"
                    style={{
                      ...styles.ordersText,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp;
                    <Div style={styles.dot} />
                    &nbsp;{val?.nature?.label}
                  </Text>
                </Div>
                <Div>
                  <Text
                    className="pc_regular"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12,
                      color: "#101010",
                    }}
                  >
                    <Image
                      src="images/icons8-time.svg"
                      alt="time"
                      style={{ width: 16, height: 16 }}
                    />
                    &nbsp;{"Order Pending"}
                    {val?.childRecurrent?.length > 0 ? (
                      <Text
                        className="pc_regular"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                          color: "#3673b9",
                          fontWeight: "bold",
                          marginLeft: 12,
                        }}
                      >
                        Parent Order
                      </Text>
                    ) : null}
                  </Text>
                </Div>
              </Div>
              {!props.signOff && (
                <Div style={{ display: "flex", alignItems: "center" }}>
                  <Icons
                    fontIcon={"pencil"}
                    style={{ marginRight: 10, cursor: "pointer" }}
                    onClick={
                      i !== 0
                        ? () => {
                          debugger;
                            props.editFun &&
                              props.editFun(
                                props?.IndexValue,
                                i,
                                val?.editData,
                                val
                              );
                            props.onEditChart &&
                              props.onEditChart(
                                val,
                                props.Israd
                                  ? "rad_order_form"
                                  : props.Istreatment
                                  ? "treatment_procedure_form"
                                  : "lab_order_form"
                              );
                          }
                        : () => {
                            props.editFun(
                              props.IndexValue,
                              null,
                              null,
                              props.data
                            );
                            // props.editFun(props.IndexValue, null, props.data.editData, props.data);
                            props.onEditChart(
                              props?.val,
                              props.Istreatment
                                ? props.Israd
                                  ? "rad_order_form"
                                  : "treatment_procedure_form"
                                : "lab_order_form"
                            );
                          }
                    }
                  />
                  <Text
                    onClick={(e) =>
                      handleClick(
                        {
                          IndexValue: props?.IndexValue,
                          i,
                          editData: val?.editData,
                          val,
                        },
                        e
                      )
                    }
                    style={{ cursor: "pointer", display: "flex" }}
                  >
                    <CalendarIcon
                      aria-describedby={id}
                      color={"#101010"}
                      width={"16"}
                      height={"16"}
                    />
                  </Text>
                </Div>
              )}
            </Div>
          </Col>
        );
      })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        <DatePicker
          // dateFormat="dd-MM-yyyy"
          // selected={this.state.dob.value}
          selected={getSelectedDate(datas?.val?.startDate,datas?.val?.dateTime,datas)}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          // showPopperArrow={false}
          // showYearDropdown
          inline
          onChange={props.Israd ? async (date) => {
            await props.updatefuncy && props.updatefuncy({
              editIndex: datas.IndexValue,
              child: {
                index: datas.i,
                parentOrderId:  datas?.val?.parentOrderId,
                IsChild: (!datas.editData) ? true : false,
                dates: date,
                editData: datas?.editData
              }
            })
            debugger;
            props.saveRadiology && props.saveRadiology([datas.val], datas.editData,
              date)
            handleClose()
          } : props.Istreatment ? async (date) => {
            await props.updatefuncy && props.updatefuncy({
              editIndex: datas.IndexValue,
              child: {
                index: datas.i,
                parentOrderId:  datas?.val?.parentOrderId,
                IsChild: (!datas.editData) ? true : false,
                dates: date,
                editData: datas?.editData
              }
            })
            props.saveTreatment && props.saveTreatment([datas.val], datas.editData,
              date)
            handleClose()
          } : async (date) => {
            debugger;
            await props.updatefuncy && props.updatefuncy({
              editIndex: datas.IndexValue,
              child: {
                index: datas.i,
                parentOrderId: datas?.val?.parentOrderId,
                IsChild: (!datas.editData) ? true : false,
                dates: date,
                editData: datas?.editData
              }
            })
            props.saveLabOrders && (props.saveLabOrders([datas.val], datas.editData,
              date));
            handleClose()
          }}
        />
      </Popover>
    </Div>
  );
};

OrderStatus.defaultProps = {
  data: " ",
  time: " ",
  id: "",
  status: " ",
};
