/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React, { useState } from "react";
import {
  Row,
  Col,
  Text,
  H6,
  Avatar,
  Div,
  Select,
  MonthCalendar,
  TapSelect,
  TextInput,
  TextArea,
  Button,
} from "qdm-component-library";
import {
  ShowList,
  CalendarIcon,
  AllergyIcon,
  SelectBox,
} from "../../../components";
import AddEditReactionForm  from "./addEditReaction";
import { Popover, makeStyles, Typography, TextField, InputAdornment } from "@material-ui/core";
import InfoStatus from "../infoStatus.js";
import AllergyNewIcon from "../../../assets/img/allergynew.svg";
import Calendar from "react-calendar";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { AlertProps, makeName, UIColor, utcTOLocal } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import { actions } from "primarycare-binder";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CustomDatePicker from "../../../components/customDatePicker";
const filter = createFilterOptions();

const onserOption = [
  { value: "Day", label: "Day" },
  { value: "Month", label: "Month" },
  { value: "Year", label: "Year" },
];

const useStyles = makeStyles((theme) => ({
  muiPopovers: {
    boxShadow: "0px 10px 75px #0000003D",
    borderRadius: 8,
  },
  selectBox:{
    width: "100%",
    height: "35px",
    marginTop: "5px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "7px",
      '& fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&:hover fieldset': {
        borderColor: UIColor.lineBorderFill.color,
      },
      '&.Mui-focused fieldset': {
        // borderColor: UIColor.lineBorderFill.color,
        border: `1px solid ${UIColor.lineBorderFill.color}`
      },
    },
    "& .MuiFormLabel-root.Mui-focused": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiFormLabel-root.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 9px) scale(1) !important",
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "35px",
      "& input": {
        padding: "0.5px 4px !important",
        color: UIColor.secondaryText.color
      },
    },
    "& .MuiAutocomplete-tag": {
      margin: "3px -4px 3px 8px",
      height: "25px",
      fontSize: "0.8rem"
    }
    
  }
}));

const statusTypeColor = {
  active: UIColor.success.color,
  inactive: UIColor.secondaryColor.color,
  resolved: UIColor.secondaryText.color,
  default: UIColor.primaryText.color,
};

const AllergyForm = (props) => {
  const dispatch = useDispatch();
  const allergiesData = useSelector((state) => state?.allergyMastersSlice);
  const encounterData = useSelector(
    (state) => state?.chiefCompilenceMastersSlice?.encounter_Details
  );
  let searchPractioner = useSelector(
    (states) => states?.personApiSlice?.searchPractioner
  );
  let personDetails = searchPractioner?.data?.[0];
  let allergys = props?.allergy;

  const styles = {
    header1: {
      borderRadius: "23px",
      // backgroundColor: "#f0f0f0",
      // border: "1px solid #e0e0e0",
      display: "flex",
      width: "100%",
    },
    header: {
      borderRadius: "23px",
      // backgroundColor: "#f0f0f0",
      // border: "1px solid #e0e0e0",
      display: "flex",
      textAlign: "right",
      justifyContent: "flex-end",
    },
    bullet: {
      width: 6.5,
      height: 6.5,
      borderRadius: "50%",
      // backgroundColor: "#38C20A",
    },
    userPic: {
      width: 34,
      height: 34,
      marginLeft: 12,
      fontSize: 14,
      // borderRadius: 4,
    },
    subText: {
      fontSize: 12,
      color: UIColor.secondaryText.color,
    },
    text: {
      fontSize: 14,
    },
    text_: {
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "140px",
    },
    headerText: {
      fontSize: 14,
      color: "#055CA7",
      fontWeight: 600,
      paddingLeft: "8px",
      textTransform: "capitalize",
    },
    flexEnd: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    flexStart: {
      display: "flex",
      //   justifyContent: "flex-start",
      alignItems: "center",
    },
    backIcon: {
      background: UIColor.lineBorderFill.color,
      padding: "9px 13px 9px 11px",
      borderRadius: "50px",
      display: "flex",
      marginRight: 14,
      cursor: "pointer",
    },
    headBorder: {
      // borderBottom: "1px solid #E0E0E0",
      padding: "6px 0px",
      background:"#fff",
      borderRadius:"10px"
    },
    labelStyle: {
      color: UIColor.tertiaryText.color,
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
    },
    text_border_Style: {
      borderRadius: 8,
      borderColor: UIColor.lineBorderFill.color,
      color: UIColor.secondaryText.color
    },
    colPadding: {
      padding: "6px",
    },
    tapSelectStyle: {
      padding: "4px 8px",
      borderColor: UIColor.lineBorderFill.color,
      margin: "4px 10px 6px 0px",
      borderRadius: "8px",
      minWidth: "auto",
      fontSize: "14px !important",
    },
    buttonTextStyle: {
      color: UIColor.primaryColor.color,
      fontWeight: 600,
      padding: "4px",
    },
    flexBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cancelBtn: {      
      padding: "8px",
      fontSize: 14,
      marginRight: 10,
      borderColor: UIColor.lineBorderFill.color,
      color: UIColor.primaryColor.color,
      borderRadius: 8,
    },
    filledBtn: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      padding: "8px",
      fontSize: 14,
      borderRadius: 8,
    },
    calenderIconstyles: {
      padding: "2px 7px",
      margin: "3px 0px 5px 0px",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      cursor: "pointer",
    },
    active: {
      margin: "0 15px 0 10px",
      // color: "#38C20A",
    },
    iconWrapper: {
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      marginLeft: "8px",
    }
  };

  const classes = useStyles();
  const [addEditReaction, setAddEditReaction] = React.useState({
    open: null,
    isEditState: null,
  });
  const [openStatus, setOpenstatus] = React.useState(null);
  const [statusType, setStatusType] = React.useState(true);

  const openPopover = (e, type, index) => {
    setAddEditReaction({
      ...addEditReaction,
      open: e?.currentTarget ?? null,
      isEditState: type ?? null,
      index: index ?? null,
    });
  };

  const reactionSave = (reactionData, type) => {
    if (type) {
      state.reactions[addEditReaction.index] = reactionData;
      setState({
        ...state,
      });
    } else {
      state["reactions"].push(reactionData);
      setState({
        ...state,
      });
    }
    openPopover(null);
  };

  const reactionDelete = (index) => {
    state["reactions"].splice(index, 1);
    setState({
      ...state,
    });
  };

  const handleStatusInactive = () => {
    setStatusType(false);
  };

  const handleStatusActive = () => {
    setStatusType(true);
  };

  const openStausInfo = (e) => {
    setOpenstatus(e?.currentTarget ?? null);
  };
  const handleStausClose = () => {
    setOpenstatus(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
	const [masters, setMasters] = React.useState([]);

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick1 = (event, type) => {
    setAnchorEl1(event.currentTarget);
  };
  const [state, setState] = React.useState(
    allergys.editIndex !== undefined && allergys.editIndex !== null
      ? allergys.list[allergys.editIndex]
      : {
        onserOption: {},
        onsetcalenderShow: true,
        lastOccurancecalenderShow: true,
        onset: {
          day: "",
          freq: "",
          currentDate: "",
          isCalenderEnabled: false,
          calenderIconEnabled: true,
        },
        lastOccurance: {
          day: "",
          freq: "",
          currentDate: "",
          isCalenderEnabled: false,
          calenderIconEnabled: true,
        },
      }
  );

  console.log("stateData", state);

  const handleAllergy = async (v) => {
		if (v?.length > 3) {
				let ccMaster = await dispatch(
					actions.ALLERGY_MASTERS_VALUE({ func: "onchange", input_text: v })
				);

				let ccMasters = ccMaster?.payload?.data;
				let datalist = allergys.list.map((val) => val.allergenCode.label);
				let masterData = ccMasters.filter(
					(val) => datalist.indexOf(val.label) === -1
				);

				setMasters(masterData);
		}
	};

  const handleInputChange = (selector, val) => {
    if(selector === "allergenCode"){
      setState({
        ...state,
        [selector]:{
          label: val?.label,
          value:val?.id??val?.value
        }
      })

    }else{
      setState({
        ...state,
        [selector]: val,
      });
    }
    
  };

  const handleClose = (get) => {
    if (get) {
      setAnchorEl(null);
      setAnchorEl1(null);
    }
  };

  const calenderDataClear = () => {
    state.onset["isCalenderEnabled"] = false;
    setState({
      ...state,
    });
  };

  const calenderDataClear1 = () => {
    state.lastOccurance["isCalenderEnabled"] = false;
    setState({
      ...state,
    });
  };

  const calenderChange = (val) => {
    state.onset["currentDate"] = moment(val).format("DD MMM, YYYY");
    state.onset["isCalenderEnabled"] = true;
    setState({
      ...state,
    });
    handleClose(true);
  };

  const calenderChange1 = (val) => {
    state.lastOccurance["currentDate"] = moment(val).format("DD MMM, YYYY");
    state.lastOccurance["isCalenderEnabled"] = true;
    setState({
      ...state,
    });
    handleClose(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  const goBack = () => {
    props.onCancel();
  };

  const [errorFields,setErrorFields] = useState(false)
  const handleUpdate = async () => {
    let data = {
      status_verification: state?.verify,
      name: state?.allergenCode?.value,
      type: state?.type,
      severity: state?.severity,
      reactions: state?.reactions,
      patient_id: encounterData?.data?.pID,
      encounter_id: encounterData?.data?.eId,
      clinicalStatus: state?.clinicalStatus,
      onset: state?.onset,
      lastOccurance: state?.lastOccurance,
      asserted: state?.asserted,
      note: state?.note,
      editId: state?.editId?._id,
      id: state?.editId?.id,
      relatedperson_id: state?.relatedperson_id,
      personDetails: personDetails,
      statushistory: state?.statushistory,
      statusreason: state?.statusreason,
    };

    // validation error
    if (
      !state?.allergenCode?.label ||
      !state?.type ||
      !state?.severity ||
      !state?.clinicalStatus ||
      (state?.asserted === "Others" && !state?.relatedperson_id)
    ) 
    {
      setErrorFields(true)
      props.alert.setSnack({
        ...props.alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }

    // let allergysData = props?.allergy;
    // await props.saveAllergy([state], allergysData?.editIndex, data);
    // props.onCancel()

    // return false;

    // screen loading true
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: true,
      message: "Updating...",
    });

    await dispatch(actions.SAVE_ALLERGY({ list: data }));

    // Get Allergy list
    // let AllergyData = await dispatch(
    //   actions.ENCOUNTER_BASED_A({ encounter_id: encounterData?.data?.eId })
    // );
    let allergys = props?.allergy;

    // let dataList = JSON.parse(JSON.stringify({
    //   ...{
    //     isNew: false,
    //     isModified: true,
    //   }, ...AllergyData?.payload?.data?.[allergys?.editIndex]
    // }))

    allergys.list[allergys?.editIndex] = {...state, isNew: false, isModified: true };
    allergys.setAllergys({
      ...allergys,
    });

    // screen loading true
    props.backDrop.setBackDrop({
      ...props.backDrop,
      open: false,
      message: "",
    });
    props.onCancel();
  };
  ;
  const { parent_id } = props;
  console.log("state",state);
  return (
    <Div
      id={`${parent_id}_allergyform_parent_div`}>
      {/* Header */}
      <Row
        id={`${parent_id}_allergyform_parent_row`}
        inLineStyles={styles.headBorder}>
        <Col
          id={`${parent_id}_allergyform_parent_col`}
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={10}
          style={{ display: "flex", paddingRight: "0px", borderRight: `2px solid ${UIColor.lineBorderFill.color}`, margin: "4px 0px"  }}
        >
          <div
            id={`${parent_id}_allergyform_NewIcon_div`}
            style={styles.header1}>
            <div
              id={`${parent_id}_allergyform_NewIcon_sub_div`}
              style={styles.flexStart}>
              {/* <Text inLineStyles={styles.backIcon} onClick={() => goBack()}>
                <LeftArrowIcon />
              </Text> */}
              <Div
                id={`${parent_id}_allergyform_NewIcon_div`}
                style={{
                  ...styles.iconWrapper,
                  backgroundColor: "#d2e4f3",
                }}
              >
                <img
                  id={`${parent_id}_allergyform_NewIcon_image`}
                  src={AllergyNewIcon} style={{ width: 14, height: 16 }} />
              </Div>
              <Text
                id={`${parent_id}_allergyform_${state?.allergenCode?.label}_text`}
                inLineStyles={styles.headerText}>
                {state?.allergenCode?.label}
              </Text>
            </div>
            <div
              id={`${parent_id}_allergyform_clinicalStatus_div`}
              style={{ flexGrow: 1 }}></div>
            <div
              id={`${parent_id}_allergyform_clinicalStatus_sub_div`}
              style={{ cursor: "pointer", ...styles.flexStart }}
              onClick={(e) => openStausInfo(e)}
            >
              <Div
                id={`${parent_id}_allergyform_toLocaleLowerCase_div`}
                style={{
                  backgroundColor:
                    statusTypeColor?.[
                    state?.clinicalStatus?.toLocaleLowerCase()
                    ] ?? statusTypeColor["default"],
                  ...styles.bullet,
                }}
              />
              <Text
                id={`${parent_id}_allergyform_${state?.clinicalStatus ?? " "}_text`}
                style={{
                  color:
                    statusTypeColor?.[
                    state?.clinicalStatus?.toLocaleLowerCase()
                    ] ?? statusTypeColor["default"],
                  ...styles.active,
                }}
              >
                {state?.clinicalStatus ?? " "}
              </Text>
            </div>
          </div>
        </Col>
        <Col
          id={`${parent_id}_allergyform_Created_col`}
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={2}
          style={{ textAlign: "right", justifyContent: "flex-end" }}
        >
          <div
            id={`${parent_id}_allergyform_Created_div`}
            style={styles.header}>
            <div
              id={`${parent_id}_allergyform_Created_text_div`}
            >
              <Text
                id={`${parent_id}_allergyform_Created_text`}
                inLineStyles={styles.subText}>
                {utcTOLocal(state?.createddate,"DD MMM, YYYY")}
                {/* {moment.unix(state?.createddate).format(
                  "DD MMM, YYYY"
                )}{" "} */}
                - Created
              </Text>
              <H6
                id={`${parent_id}_allergyform_${state?.practitioner_id?.[0]?.name?.text ?? " ".replaceAll(" ", "_")}_h6`}
                inLineStyles={styles.text_}>
                {
                  Array.isArray(state?.practitioner_id?.[0]?.name)
                    ? makeName(state?.practitioner_id?.[0]?.name?.[0] || {}) + " (You)"
                    : makeName(state?.practitioner_id?.[0]?.name || {}) + " (You)"}
              </H6>
            </div>
            <div
              id={`${parent_id}_allergyform_avatar_div`}
              style={{ marginTop: "5px" }}>
              <Avatar
                id={`${parent_id}_allergyform_avatar`}
                variant={"circle"}
                inLineStyles={styles.userPic}
                style={{ margin: "0px 8px" }}
              />
            </div>
          </div>
        </Col>
      </Row>

      {/* Main content */}
      <div
        id={`${parent_id}_allergyform_input_parent_div`}
        style={{ height: "calc(100vh - 296px)", overflow: "auto", background: "#fff", borderRadius: "10px", margin: "10px 0px" }}>
        <Row
          id={`${parent_id}_allergyform_input_parent_row`}
          style={{ padding: 7 }}>
          <Col
            id={`${parent_id}_allergyform_type_col`}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              parent_id={'allergyform'}
              // serachIcon
              top_title={"Type"}
              requireStart
              list={allergiesData?.type_master?.data ?? []}
              placeholder={"Search here..."}
              value={{ label: state?.type }}
              onchange={(e, value) => handleInputChange("type", value?.label)}
              error={(errorFields && !state?.type) ? true : false}
            />
          </Col>
          <Col
            id={`${parent_id}_allergyform_allergenCode_col`}
            xs={12}
            sm={12}
            md={6}
            lg={8}
            xl={8}
            inLineStyles={styles.colPadding}
          >
            {/* <SelectBox
              parent_id={'allergyform'}
              top_title={"Allergen Code & Description"}
              requireStart
              list={allergiesData?.allery_master?.data ?? []}
              placeholder={"Search here..."}
              value={state?.allergenCode}
              onchange={(e, value) =>
                handleInputChange("allergenCode", value)
              }
              error={(errorFields && !state?.allergenCode?.label) ? true : false}
            /> */}

            <Text
              id={`${parent_id}_allergyform_Severity_text`}
              inLineStyles={styles.labelStyle}>{"Allergen Code & Description "}
              <span style={{color: UIColor.error.color}}>*</span>
            </Text>

            <Autocomplete
              className={classes.selectBox}
              value={state?.allergenCode}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  handleInputChange("allergenCode", newValue)
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  handleInputChange("allergenCode", {
                    label: newValue,
                    value: newValue
                  })
                } else {
                  handleInputChange("allergenCode", newValue)
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const already = allergys.list.map((val) => val.allergenCode.label);
                const list = already.includes(params.inputValue);
                // Suggest the creation of a new value
                if (params.inputValue !== "" && !list) {
                  filtered.push({
                    label: `${params.inputValue}`,
                    value: `${params.inputValue}`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // disableClearable
              id="free-solo-with-text-demo"
              // options={allergiesData?.allery_master?.data ?? []}
              options={masters}
              onInputChange={(e) =>
                handleAllergy(e?.target?.value ?? "")
              }
                
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.label;
              }}

              renderOption={(option) => (
                <Typography
                  id={`${parent_id}_allergy_${option.label}_typography`}
                  style={{ width: "100%" }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              freeSolo
              popupIcon={
                <InputAdornment
                  id={`${parent_id}_select_InputAdornment`}
                  position="end">
                  <SearchIcon
                    id={`${parent_id}_select_SearchIcon`}
                    style={{ color: UIColor.secondaryText.color }} />
                </InputAdornment>
              }
              renderInput={(params) => (
                <TextField
                  // label={"Allergen Code & Description"}
                  id={`allergyform_allergy_type_textfield`}
                  {...params}
                  variant={"outlined"}
                  placeholder={"Search here..."}
                  size={"small"}
                  style={{ height: "35px"}}
                  error={(errorFields && !state?.allergenCode?.label) ? true : false}
                />
              )}

              />

          </Col>

          {state?.onset?.isCalenderEnabled === true ? (
            <Col
              id={`${parent_id}_allergyform_onset_calender_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Row
                id={`${parent_id}_allergyform_onset_calender_row`}
              >
                <Col
                  id={`${parent_id}_allergyform_onset_calender_sub_col`}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  inLineStyles={styles.colPadding}
                >
                  <Text
                    id={`${parent_id}_allergyform_onset_calender_text`}
                    inLineStyles={styles.labelStyle}>{"Onset"}</Text>
                  <TextInput
                    id={`${parent_id}_allergyform_onset_calender_textinput`}
                    label={""}
                    variant={"outlined"}
                    hoverColor={UIColor.lineBorderFill.color}
                    placeholder=""
                    value={state?.onset?.currentDate}
                    disabled
                    style={styles.text_border_Style}
                  />
                </Col>
                <Col
                  id={`${parent_id}_allergyform_onset_calender_avatar_col`}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  inLineStyles={styles.colPadding}
                >
                  <div
                    id={`${parent_id}_allergyform_onset_calender_avatar_div`}
                    style={{ margin: "22px 0px 0px 0px" }}>
                    <Avatar
                      id={`${parent_id}_allergyform_onset_calender_avatar`}
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => calenderDataClear()}
                    >
                      <CloseIcon
                        id={`${parent_id}_allergyform_onset_CloseIcon`}
                      />
                    </Avatar>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              id={`${parent_id}_allergyform_calender_select_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Text
                id={`${parent_id}_allergyform_calender_Onset_text`}
                inLineStyles={styles.labelStyle}>{"Onset"}</Text>
              <div
                id={`${parent_id}_allergyform_calender_Onset_div`}
                style={{ display: "flex" }}>
                <div
                  id={`${parent_id}_allergyform_calender_Onset_sub_div`}
                  style={{ width: 40, marginRight: 8 }}>
                  <TextInput
                    id={`${parent_id}_allergyform_calender_day_textinput`}
                    label={""}
                    variant={"standard"}
                    placeholder="05"
                    maxLength={2}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.onset?.day}
                    onChange={(event) => {
                      state.onset["day"] = event.target.value;
                      setState({
                        ...state,
                      });
                    }}
                    style={styles.text_border_Style}
                  />
                </div>
                <TapSelect
                  id={`${parent_id}_allergyform_calenderIconEnabled_tapSelect`}
                  label={""}
                  inLineStyles={styles.tapSelectStyle}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor={"#fff"}
                  textSize={"14px"}
                  options={onserOption}
                  defaultSelected={state?.onset?.freq}
                  value={state?.onset?.freq}
                  onChange={(value) => {
                    if(state?.onset?.freq){
                    state.onset["freq"] = value;
                    state.onset["calenderIconEnabled"] = value?.value
                      ? false
                      : true;
                    }
                    setState({
                      ...state,
                    });
                  }}
                />
                {state?.onset["calenderIconEnabled"] && (
                  <div
                    id={`${parent_id}_allergyform_calenderIcon_div`}
                    style={styles.calenderIconstyles} onClick={handleClick}>
                    <Text
                      id={`${parent_id}_allergyform_calenderIcon_text`}
                    >
                      <CalendarIcon
                        id={`${parent_id}_allergyform_calenderIcon`}
                        style={{ marginTop: 4 }} />
                    </Text>
                  </div>
                )}
              </div>
            </Col>
          )}
          {state?.lastOccurance?.isCalenderEnabled === true ? (
            <Col
              id={`${parent_id}_allergyform_lastOccurance_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Row
                id={`${parent_id}_allergyform_lastOccurance_row`}
              >
                <Col
                  id={`${parent_id}_allergyform_lastOccurance_sub_row`}
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  inLineStyles={styles.colPadding}
                >
                  <Text
                    id={`${parent_id}_allergyform_lastOccurance_text`}
                    inLineStyles={styles.labelStyle}>
                    {"Last Occurance"}
                  </Text>
                  <TextInput
                    id={`${parent_id}_allergyform_lastOccurance_textinput`}
                    label={""}
                    variant={"outlined"}
                    hoverColor={UIColor.lineBorderFill.color}
                    placeholder=""
                    value={state?.lastOccurance?.currentDate}
                    disabled
                    style={styles.text_border_Style}
                  />
                </Col>
                <Col
                  id={`${parent_id}_allergyform_calenderDataClear_col`}
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  inLineStyles={styles.colPadding}
                >
                  <div
                    id={`${parent_id}_allergyform_calenderDataClear_div`}
                    style={{ margin: "22px 0px 0px 0px" }}>
                    <Avatar
                      id={`${parent_id}_allergyform_calenderDataClear_avatar`}
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => calenderDataClear1()}
                    >
                      <CloseIcon
                        id={`${parent_id}_allergyform_calenderDataClear_CloseIcon`}
                      />
                    </Avatar>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col
              id={`${parent_id}_allergyform_Last_Occurance_col`}
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              inLineStyles={styles.colPadding}
            >
              <Text
                id={`${parent_id}_allergyform_Last_Occurance_text`}
                inLineStyles={styles.labelStyle}>{"Last Occurance"}</Text>
              <div
                id={`${parent_id}_allergyform_Last_Occurance_div`}
                style={{ display: "flex" }}>
                <div
                  id={`${parent_id}_allergyform_Last_Occurance_sub_div`}
                  style={{ width: 40, marginRight: 8 }}>
                  <TextInput
                    id={`${parent_id}_allergyform_Last_Occurance_textinput`}
                    label={""}
                    variant={"standard"}
                    placeholder="05"
                    maxLength={2}
                    hoverColor={UIColor.lineBorderFill.color}
                    value={state?.lastOccurance?.day}
                    onChange={(event) => {
                      state.lastOccurance["day"] = event.target.value;
                      setState({
                        ...state,
                      });
                    }}
                    style={styles.text_border_Style}
                  />
                </div>
                <TapSelect
                  id={`${parent_id}_allergyform_Last_Occurance_tapselect`}
                  label={""}
                  inLineStyles={styles.tapSelectStyle}
                  selectedBorderColor={UIColor.secondaryButtonColor.color}
                  selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                  selectedTextColor={"#fff"}
                  textSize={"14px"}
                  options={onserOption}
                  defaultSelected={state?.lastOccurance?.freq}
                  value={state?.lastOccurance?.freq}
                  onChange={(value) => {
                    state.lastOccurance["freq"] = value;
                    state.lastOccurance["calenderIconEnabled"] = value?.value
                      ? false
                      : true;
                    setState({
                      ...state,
                    });
                  }}
                />
                {state?.lastOccurance["calenderIconEnabled"] && (
                  <div
                    id={`${parent_id}_allergyform_CalendarIcon_div`}
                    style={styles.calenderIconstyles} onClick={handleClick1}>
                    <Text
                      id={`${parent_id}_allergyform_CalendarIcon_text`}
                    >
                      <CalendarIcon
                        id={`${parent_id}_allergyform_CalendarIcon`}
                        style={{ marginTop: 4 }} />
                    </Text>
                  </div>
                )}
              </div>
            </Col>
          )}

          <Col
            id={`${parent_id}_allergyform_Severity_col`}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_allergyform_Severity_text`}
              inLineStyles={styles.labelStyle}>{"Severity "}<span style={{color: UIColor.error.color}}>*</span></Text>
            <TapSelect
              id={`${parent_id}_allergyform_Severity_tapSelect`}
              label={""}
              inLineStyles={styles.tapSelectStyle}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor={"#fff"}
              textSize={"14px"}
              options={allergiesData?.Severity_masters?.data ?? []}
              value={state?.severity}
              defaultSelected={
                allergiesData?.Severity_masters?.data.filter(
                  (d) => d.label === state?.severity
                )?.[0]
              }
              onChange={(value) => handleInputChange("severity", value?.label)}
            />
            {errorFields && !state?.severity && (
            <div>
              <Text
                style={{ color: 'red', fontSize: 14 }}>
                {'Is required'}
              </Text>
            </div>
            )}
          </Col>
          <Col
            id={`${parent_id}_allergyform_Asserted_By_col`}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
            style={{ display: "flex" }}
          >
            <div
              id={`${parent_id}_allergyform_Asserted_By_div`}
            >
              <Text
                id={`${parent_id}_allergyform_Asserted_By_text`}
                inLineStyles={styles.labelStyle}>{"Asserted By"}</Text>
              <TapSelect
                id={`${parent_id}_allergyform_Asserted_By_tapselect`}
                style={{ width: "136px" }}
                label={""}
                inLineStyles={styles.tapSelectStyle}
                selectedBorderColor={UIColor.secondaryButtonColor.color}
                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                selectedTextColor={"#fff"}
                textSize={"14px"}
                // options={assertedOption}
                defaultSelected={
                  allergiesData?.assertby_master?.data.filter(
                    (d) => d.label === state?.asserted
                  )?.[0]
                }
                options={allergiesData?.assertby_master?.data ?? []}
                value={state?.asserted}
                onChange={(value) =>
                  handleInputChange("asserted", value?.label)
                }
              />
            </div>
            {state?.asserted === "Others" && (
              <div
                id={`${parent_id}_allergyform_RelationName_div`}
                style={{ width: "100%" }}>
                <SelectBox
                  id={`${parent_id}_allergyform_RelationName_selectbox`}
                  top_title={"RelationName"}
                  requireStart
                  list={allergiesData?.relationship_masters?.data ?? []}
                  placeholder={"Search here..."}
                  value={
                    allergiesData?.relationship_masters?.data.filter(
                      (d) => d.masterId === state?.relatedperson_id
                    )?.[0]
                  }
                  onchange={(e, value) =>
                    handleInputChange("relatedperson_id", value?.masterId)
                  }
                  error={(errorFields && !state?.relationName) ? true : false}
                />
              </div>
            )}
          </Col>
          <Col
            id={`${parent_id}_allergyform_clinicalStatus_col`}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <Text
              id={`${parent_id}_allergyform_clinicalStatus_text`}
              inLineStyles={styles.labelStyle}>{"Clinical Status "}<span style={{ color: UIColor.error.color }}>*</span></Text>
            <TapSelect
              id={`${parent_id}_allergyform_clinicalStatus_tapselect`}
              label={""}
              inLineStyles={styles.tapSelectStyle}
              selectedBorderColor={UIColor.secondaryButtonColor.color}
              selectedBackgroundColor={UIColor.secondaryButtonColor.color}
              selectedTextColor={"#fff"}
              textSize={"14px"}
              // options={clinicalStatusOptions}
              defaultSelected={
                allergiesData?.clinicalStatus_masters?.data.filter(
                  (d) => d.label === state?.clinicalStatus
                )?.[0]
              }
              options={allergiesData?.clinicalStatus_masters?.data ?? []}
              value={state?.clinicalStatus}
              name="Clinical Status"
              onChange={(value) =>
                handleInputChange("clinicalStatus", value?.label)
              }
            />
             {errorFields && !state?.clinicalStatus && (
            <div>
              <Text
                style={{ color: 'red', fontSize: 14 }}>
                {'Is required'}
              </Text>
            </div>
            )}
          </Col>
          <Col
            id={`${parent_id}_allergyform_verify_col`}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            inLineStyles={styles.colPadding}
          >
            <SelectBox
              id={`${parent_id}_allergyform_verify_selectbox`}
              top_title={"Verification Status"}
              list={allergiesData?.Verification_masters?.data ?? []}
              placeholder={"Search here..."}
              value={{ label: state?.verify }}
              onchange={(e, value) => handleInputChange("verify", value?.label)}
            />
          </Col>
          <Col
            id={`${parent_id}_allergyform_notes_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <TextArea
              id={`${parent_id}_allergyform_notes_textarea`}
              label={"Notes"}
              placeholder={""}
              labelStyle={styles.labelStyle}
              style={styles.borderStyle}
              hoverColor={UIColor.lineBorderFill.color}
              name="notes"
              maxLength=""
              value={state?.note}
              onChange={(event) =>
                handleInputChange("note", event.target.value)
              }
            />
          </Col>
        </Row>
        <Row
          id={`${parent_id}_allergyform_Reaction_row`}
          style={{ padding: 7 }}>
          <Col
            id={`${parent_id}_allergyform_Reaction_col`}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.colPadding}
          >
            <Div
              id={`${parent_id}_allergyform_Reaction_div`}
            >
              <H6
                id={`${parent_id}_allergyform_Reaction_h6`}
                className="pi-title" style={{color: UIColor.primaryText.color}}>{"Reaction"}</H6>
              <Text
                id={`${parent_id}_allergyform_Add_New_text`}
                onClick={(e) => openPopover(e)} className="pi-addbtn" style={{color: UIColor.secondaryButtonColor.color, fontSize: "12px !important"}}>
                {"+ Add New"}
              </Text>
            </Div>
          </Col>
          {state?.reactions?.map((val, i) => {
            return (
              <Col
                id={`${parent_id}_allergyform_${i}_ShowList_col`}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                inLineStyles={styles.colPadding}
              >
                <ShowList
                  parent_id={'allergyform' + i}
                  index={i + 1}
                  title={val?.description}
                  description={
                    `${val?.severity?.length > 0 ? val?.severity : " "}` +
                    `${val?.substance?.length > 0 ? ", " + val?.substance : " "
                    }` +
                    `${val?.exposureroute?.length > 0
                      ? ", " + val?.exposureroute
                      : " "
                    }` +
                    `${val?.note?.length > 0 ? ", " + val?.note : " "}`
                  }
                  ondelete={() => reactionDelete(i)}
                  onedit={(e) => openPopover(e, val, i)}
                />
              </Col>
            );
          })}
        </Row>
      </div>

      {/* footer  */}
      <Row
        id={`${parent_id}_allergyform_bottom_row`}
        inLineStyles={{ background: "#fff", borderRadius: "10px" }}>
        <Col
          id={`${parent_id}_allergyform_bottom_col`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ margin: "10px 0px" }}
        >
          <Div
            id={`${parent_id}_allergyform_bottom_div`}
            inLineStyles={styles.flexEnd}>
            <Button
              id={`${parent_id}_allergyform_Cancel_button`}
              onClick={() => goBack()}
              variant={"text"}
              inLineStyles={styles.cancelBtn}
            >
              Cancel
            </Button>
            <Button
              id={`${parent_id}_allergyform_Update_button`}
              variant={"outlined"}
              inLineStyles={styles.filledBtn}
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Div>
        </Col>
      </Row>

      {/* edit or add new reactions */}
      <AddEditReactionForm
        parent_id={'allergyform'}
        addEditReaction={addEditReaction}
        openPopover={openPopover}
        reactionSave={reactionSave}
      />

      {/* for status info */}
      <Popover
        open={Boolean(openStatus)}
        anchorEl={openStatus}
        onClose={handleStausClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: classes.muiPopovers }}
      >
        <InfoStatus
          parent_id={'allergyform'}
          infoStatusprops={openStatus}
          openStausInfo={openStausInfo}
          handleStatusInactive={handleStatusInactive}
          handleStatusActive={handleStatusActive}
          handleStatusChange={handleInputChange}
          currentStatus={state?.clinicalStatus}
          statusList={allergiesData?.clinicalStatus_masters?.data ?? []}
          statushistory={state?.statushistory}
        />
      </Popover>

      {/* calender UI */}
      <Popover
        id={Boolean(open) ? "simple-popover" : undefined}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Calendar
          id={`${parent_id}_allergyform_calender`}
          maxDate={new Date()}
          allowPartialRange
          onClickMonth={(value, event) => {
            handleClose(false);
          }}
          onClickYear={(value, event) => {
            handleClose(false);
          }}
          onClickWeekNumber={(value, event) => {
            handleClose(false);
          }}
          onChange={(value, event) => {
            calenderChange(value);
          }}
          className={"pc_regular"}
        /> */}
        <CustomDatePicker maxDate={new Date()} handleChange={(value) => calenderChange(value)} />
      </Popover>

      <Popover
        id={Boolean(open1) ? "simple-popover" : undefined}
        open={Boolean(open1)}
        anchorEl={anchorEl1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Calendar
          id={`${parent_id}_allergyform_calender`}
          maxDate={new Date()}
          allowPartialRange
          onClickMonth={(value, event) => {
            handleClose(false);
          }}
          onClickYear={(value, event) => {
            handleClose(false);
          }}
          onClickWeekNumber={(value, event) => {
            handleClose(false);
          }}
          onChange={(value, event) => {
            calenderChange1(value);
          }}
          className={"pc_regular"}
        /> */}
        <CustomDatePicker minDate={new Date()} handleChange={(value) => {calenderChange1(value);}} />
      </Popover>
    </Div >
  );
};

export default withAllContexts(AllergyForm);
