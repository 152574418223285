import React from "react";
import { UIColor } from "../../utils";

export const ExamineIcon = (props) => {
  let width = props.width ? props.width : "20";
  let height = props.height ? props.height : "18";
  let color = props.color ? props.color : UIColor.sidebarIconColor.color;
  return (
        <svg id="Examine_svg"xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 18"><path class="a" fill={color} d="M16.165,1.914a3.054,3.054,0,0,0-3.092,2.994,3.094,3.094,0,0,0,6.184,0A3.054,3.054,0,0,0,16.165,1.914Zm0,1.283A1.712,1.712,0,1,1,14.4,4.908,1.73,1.73,0,0,1,16.165,3.2ZM3.884,4.089a1.112,1.112,0,0,0-.942.513A2.5,2.5,0,0,0,1.478,8.448L5.43,13.915s0,.008,0,.012v.428a5.663,5.663,0,0,0,5.742,5.56,5.847,5.847,0,0,0,2.641-.629,4.945,4.945,0,0,0,2.352.592,4.793,4.793,0,0,0,4.859-4.7V10.355a1.635,1.635,0,0,0-1.65-1.6H12.955a1.635,1.635,0,0,0-1.649,1.6v4.817a4.6,4.6,0,0,0,1.325,3.217,4.556,4.556,0,0,1-1.459.241,4.339,4.339,0,0,1-4.417-4.277v-.428q0-.011,0-.022l3.71-5.527A2.494,2.494,0,0,0,9.309,4.722,1.107,1.107,0,0,0,8.3,4.089a1.07,1.07,0,1,0,0,2.138A1.127,1.127,0,0,0,9,5.987a1.17,1.17,0,0,1,.356,1.691L6.072,12.566,2.564,7.712a1.173,1.173,0,0,1,.52-1.818,1.122,1.122,0,0,0,.8.333,1.07,1.07,0,1,0,0-2.138Zm9.071,5.951h6.418a.311.311,0,0,1,.325.315v4.817a3.469,3.469,0,0,1-3.534,3.422,3.656,3.656,0,0,1-1.1-.169,5.462,5.462,0,0,0,1.847-4.071v-1.5a1.05,1.05,0,0,0,.383-1.192,1.114,1.114,0,0,0-2.092,0,1.05,1.05,0,0,0,.384,1.193v1.5a4.216,4.216,0,0,1-1.743,3.4,3.364,3.364,0,0,1-1.216-2.584V10.355A.31.31,0,0,1,12.955,10.04Z" transform="translate(-1.023 -1.914)"/></svg>
    );
};