import React from "react";
import { Div, Avatar, H6, Card, Text, Image } from 'qdm-component-library';
import { NoUndefinedVariablesRule } from "graphql";
import { UIColor } from "../../utils";

class PatientInfoCard extends React.Component {

    styles = {
        rowFlex: {
            display: 'flex',
            alignItems: 'center'
        },
        columnFlex: {
            display: 'flex',
            flexDirection: 'column'
        },
        h6: {
            fontSize: 14,
            color: UIColor.primaryColor.color,
            margin: 0
        },
        span: {
            fontSize: 12,
            color: UIColor.tertiaryText.color,
            margin: 0
        },
        cardStyle: {
            //boxShadow: '0px 10px 25px #0000000A',
            boxShadow: "none",
            borderRadius: 8,
            // padding: 10
        },
        avatar: {
            margin: 0,
            marginRight: 15,
            width: 48,
            height: 48,
            borderRadius: 8,
            background: "#F0F0FA"
        },
        img: {
            width: 16,
            height: 16,
            marginRight: 10
        }
    }

    render() {

        const { rowFlex, columnFlex, h6, span, cardStyle, avatar, img } = this.styles;
        const { parent_id, padding } = this.props;
        return <Card
            id={`${parent_id}_patientinfocard_parent_card`}
            style={{ 
                boxShadow: "none",
                borderRadius: 8,
                padding: padding ? 10 : 0
            }}>
            <Div
                id={`${parent_id}_patientinfocard_parent_div`}
                style={rowFlex}>
                {this.props.pic ?
                    <Avatar
                        id={`${parent_id}_patientinfocard_avatar_one`}
                        src={this.props.pic} variant="rounded" inLineStyles={avatar} /> :
                    <Avatar
                        id={`${parent_id}_${this.props.name?.[0]}_patientinfocard_avatar`}
                        variant="rounded" inLineStyles={avatar}>{this.props.name?.[0]}</Avatar>
                }
                <Div
                    id={`${parent_id}_patientinfocard_sub_div`}
                    style={columnFlex}>
                    <Text
                        id={`${parent_id}_patientinfocard_text`}
                        style={rowFlex}>
                        <H6
                            id={`${parent_id}_${this.props.name}_patientinfocard_name_h6`}
                            className="pc_semibold" style={h6}>{this.props.name}</H6>
                        <Text
                            id={`${parent_id}_patientinfocard_dot_text`}
                            className="pc_regular" style={{ ...span, margin: '0px 5px' }}>•</Text>
                        <Text
                            id={`${parent_id}_patientinfocard_gender_text`}
                            className="pc_regular" style={span}>{this.props.age + ", " + this.props.gender}</Text>
                    </Text>
                    <Div
                        id={`${parent_id}_patientinfocard_doc_div`}
                        style={{ ...rowFlex, marginTop: 7 }}>
                        <Div
                            id={`${parent_id}_patientinfocard_doc_sub_div`}
                            style={{ ...rowFlex, marginRight: 15 }}>
                            <Image
                                id={`${parent_id}_patientinfocard_identification_doc_image`}
                                style={img} src="images/icons8-identification-documents.svg" alt="id" />
                            <Text
                                id={`${parent_id}_${this.props.mrn_id}_patientinfocard_text`}
                                className="pc_regular" style={span}>{this.props.mrn_id}</Text>
                        </Div>
                        <Div
                            id={`${parent_id}_patientinfocard_group_div`}
                            style={rowFlex}>
                            <Image
                                id={`${parent_id}_patientinfocard_group_image`}
                                style={img} src="images/Group 90503.svg" alt="group" />
                            <Text
                                id={`${parent_id}_${this.props.pi_id}_patientinfocard_text`}
                                className="pc_regular" style={span}>{this.props.pi_id}</Text>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Card>
    }
}

PatientInfoCard.defaultProps = {
    pic: null,
    name: "",
    age: null,
    gender: null,
    mrn_id: null,
    pi_id: null
}

export default PatientInfoCard;