import { makeStyles, Typography, Grid, Button } from "@material-ui/core";
import React, { useState } from "react";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import headerIcons from "../../../assets/img/supportBoxes.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { carousalData } from "./carousalData";
import "./carousalSupport.css";

const useStyles = makeStyles((theme) => ({
  headerText: {
    fontFamily: "pc_medium !important",
    fontSize: "16px",
    fontWeight: 600,
  },
  textBody: {
    fontFamily: "pc_medium !important",
    fontSize: "16px",
    color: "#6F6F6F",
    margin: "5px 0px 25px",
  },
  lowerHeader: {
    fontFamily: "pc_medium !important",
    fontSize: "14px",
    color: "#4062D0",
  },
  lowerText: {
    fontFamily: "pc_regular !important",
    fontSize: "12px",
    color: "#B6B6B6",
    textAlign: "initial",
  },
  buttonAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    position: "absolute",
    bottom: "-80px",
    right: "200px",
    zIndex: "20",
  },
  numbers:{
    border: "1px solid #4366D8",
    borderRadius: "50%",
    color: "#4366D8",
    padding: "2px 5px",
    marginRight:"5px"
  }
}));

const renderArrow = (direction) => (onClickHandler, shouldBeEnabled, label) => {
  const styles = {
    position: "absolute",
    zIndex: 2,
    fontSize: "14px",
    textTransform: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    fontFamily: "pc_medium !important",
    color: "#0071F2",
    padding: "5px 20px",
    border: "1px solid #0071F2",
    "&:hover": {
      backgroundColor: "#0551a9",
    },
  };

  if (direction === "prev") {
    styles.bottom = 20;
    styles.right = 90;
    styles.color = "#000";
    styles.border = "1px solid #F0F0F0";
  } else {
    styles.bottom = 20;
    styles.right = 10;
  }

  const returnClick = () => {
    alert("Back to tour guide");
  };

  return (
    <Button
      type="button"
      onClick={shouldBeEnabled ? onClickHandler : returnClick}
      style={styles}
    >
      {direction === "prev" ? "Back" : shouldBeEnabled ? "Next" : "Done"}
    </Button>
  );
};

function CarousalSupport(props) {
  const classes = useStyles();
  //   const [selected,setSelected] = useState(0);

  //  const setSelectedPage = (index) => {
  //     setSelected(index)
  //   };

  return (
    <div style={{ margin: "10px 220px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.headerText} style={{ margin: "20px 0" }}>
          Ainqa Hospital Video Guided Tour
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <img src={headerIcons} />
      </div>
      <Carousel
        showStatus={false}
        animationHandler="fade"
        swipeable={false}
        renderThumbs={() => null}
        dynamicHeight={false}
        // onChange={setSelectedPage}
        renderArrowPrev={renderArrow("prev")}
        renderArrowNext={renderArrow("next")}
      >
        {carousalData.map((val) => (
          <div>
            <img alt="" src={val.image} />
            <div className="legend" style={{ marginBottom: "25px" }}>
              <Typography className={classes.headerText}>
                {val?.tiltle}
              </Typography>
              <Typography className={classes.textBody}>
                {val?.subTile}
              </Typography>
              <Grid container spacing={2}>
                {val.downHeader.map((value,index) => (
                  <Grid item xs={2}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.numbers}>
                        0{index+1}
                      </div>
                      <Typography className={classes.lowerHeader}>
                        {value.header}
                      </Typography>
                    </div>
                    <Typography className={classes.lowerText}>
                      {value.subHeader}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CarousalSupport;
