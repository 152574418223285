import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Addmember,
  Addmemberform,
  Addmemberotp,
  ModelComponent,
} from "../../components";
import ConfirmationPage from "../confirmBooking";
import CancelBookingPage from "../cancelBooking";
import Eye from "../../assets/img/eye.svg";
import CancelAppointment from "../../assets/img/cancel_appointment.svg";
import Modify from "../../assets/img/modify.svg";
import Calendar from "../../assets/img/calendar.svg";
import DotMenu from "../../assets/img/dot_menu.svg";
import Warning from "../../assets/img/warning.svg";
import "./style.css";
import {
  Container,
  Row,
  Col,
  Avatar,
  Card,
  Tooltip,
  TopHeader,
  Div,
  Icons,
  Divider,
  Text,
  ListItem,
  Button,
  SideMenu,
} from "qdm-component-library";

class AppointmentsListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
      collpaseOpen: 0,
      newmembersteps: 0,
      singleupload: [],
      searchMenber: true,
      memberForm: false,
      memberOtp: false,
      chooseSearch: null,
      open: false,
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
    };
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.APPOINTMENT_READ();
    this.props.READ_PERSON();
  };

  shouldComponentUpdate(props, state) {
    return true;
  }

  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
    });
  };

  nextSection = (member, signUpData) => {
    if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
      });
    } else if (member === "addmemberotp") {
      if (signUpData) {
        this.setState({
          ...this.state,
          signUpData,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
        });
      } else {
        this.setState({
          ...this.state,
          searchMenber: false,
          memberForm: false,
          memberOtp: true,
        });
      }
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
      });
      window.location.href = "/doctor_consultation";
    }
  };
  handleOpen = (val) => {
    const state = this.state;
    const values = state.collpaseOpen === val ? null : val;
    this.setState({
      collpaseOpen: values,
    });
  };

  handlenewmembersteps = (val) => {
    this.setState({
      newmembersteps: val,
    });
  };
  ImageUploads = (url) => {
    const state = this.state;
    state.singleupload.push({ url: url });
    this.setState({ ...state });
  };
  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };
  openView = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  handlesidebarCancelbooking = () => {
    this.setState({
      cancelbooking: !this.state.cancelbooking,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };
  handlecahngeCancelbooking = (v) => {
    if (!v) {
      this.handlesidebarCancelbooking();
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    this.setState({
      cancelbookings: v,
    });
  };
  render() {
    const { sidebar, cancelbooking, cancelbookingreason, cancelbookings } =
      this.state;

    return (
      <div>
        <Container
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#fff",
            height: "100vh",
            padding: "0px",
          }}
        >
          <SideMenu
            open={cancelbooking}
            direction="right"
            width={435}
            color="default"
            onClose={() => this.handlesidebarCancelbooking()}
          >
            <CancelBookingPage
              cancelbookingreason={cancelbookingreason}
              cancelbookings={cancelbookings}
              handleSelectCancelbooking={this.handleSelectCancelbooking}
              handlecahngeCancelbooking={this.handlecahngeCancelbooking}
            />
          </SideMenu>
          <SideMenu
            open={sidebar}
            direction="right"
            width={435}
            color="default"
            onClose={() => this.toggleSideBar()}
          >
            {this.state.searchMenber ? (
              <Addmember
                name={"Add Member"}
                nextSection={(member) => this.nextSection(member)}
                handelChangeSelect={(v) => this.handelChangeSelect(v)}
                chooseSearch={this.state.chooseSearch}
              />
            ) : this.state.memberForm ? (
              <Addmemberform
                addNewMember
                nextSection={(member, signUpInfo) =>
                  this.nextSection(member, signUpInfo)
                }
              />
            ) : this.state.memberOtp ? (
              <Addmemberotp
                nextSection={(member) => this.nextSection(member)}
                appointmentDetails={true}
              />
            ) : null}
          </SideMenu>
          <TopHeader
            position="static"
            inLineStyles={{ height: "64px", backgroundColor: "#0071f2" }}
          >
            <Row
              key={"0"}
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              spacing="2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Col
                key={"0"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="1"
                xs="12"
                md="1"
                sm="1"
              >
                <Avatar
                  src="https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png"
                  alt="Image"
                  style={{
                    backgroundColor: "transparent",
                    height: "auto",
                    width: "40px",
                  }}
                  variant="square"
                ></Avatar>
              </Col>
              <Col
                key={"1"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="6"
                xs="12"
                md="6"
                sm="6"
              >
                <Div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    paddingTop: "4px",
                  }}
                >
                  <Card
                    style={{ height: "50px", marginTop: "-1px", width: "40%" }}
                  >
                    <ListItem
                      style={{
                        height: "48px",
                        color: "#101010",
                        fontWeight: "600",
                        padding: "8px",
                      }}
                      profile={
                        <img
                          alt="img"
                          style={{ borderRadius: "10px", marginTop: "-8px" }}
                          src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                        />
                      }
                      action={
                        <Icons
                          inLineStyles={{ color: " #B6B6B6" }}
                          fontIcon="ellipsis-v"
                        ></Icons>
                      }
                      title={
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#101010",
                          }}
                        >
                          Sudharsan N
                        </span>
                      }
                      description={
                        <span
                          style={{
                            fontSize: "12px",
                            marginTop: "-4px",
                            color: "#B6B6B6",
                          }}
                        >
                          PID 1231313 | You
                        </span>
                      }
                    />
                  </Card>
                  <Divider
                    key={"1"}
                    id=""
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="vertical"
                    variant="middle"
                    style={{
                      height: "34px",
                      borderColor: "#ffffff",
                      backgroundColor: "#ffffff",
                    }}
                  ></Divider>
                  <Div inLineStyles={{ display: "flex", alignItems: "center" }}>
                    <div style={{ position: "relative" }}>
                      <Avatar
                        variant="rounded"
                        inLineStyles={{ height: "40px", width: "40px" }}
                        src={
                          "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                        }
                      />
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#FF9100",
                          position: "absolute",
                          top: "6px",
                          right: "6px",
                        }}
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <Avatar
                        variant="rounded"
                        style={{ height: "40px", width: "40px" }}
                        src={
                          "https://images.firstpost.com/wp-content/uploads/2017/06/Sundar_Pichai_New.jpg"
                        }
                      />
                      <span
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: "#FF9100",
                          position: "absolute",
                          top: "6px",
                          right: "6px",
                        }}
                      />
                    </div>
                    <Avatar
                      variant="rounded"
                      style={{
                        height: "40px",
                        width: "40px",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                        color: "#6F6F6F",
                        fontSize: "16px",
                      }}
                      letter="+"
                      onClick={() => this.toggleSideBar(true)}
                    />
                  </Div>
                </Div>
              </Col>
              <Col
                key={"2"}
                name="qdm-col-12"
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                textAlign="left"
                lg="1"
                xs="12"
                md="1"
                sm="1"
              >
                <Div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Avatar
                    variant="circle"
                    style={{ height: "38px", width: "38px" }}
                    src={
                      "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                    }
                  />

                  <Icons
                    key={"2"}
                    fontIcon="angle-down"
                    ariaHidden="true"
                    className=""
                    size="medium"
                    style={{ color: "#ffffff" }}
                  ></Icons>
                </Div>{" "}
              </Col>
            </Row>
          </TopHeader>
          <Row
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Col
              key={"0"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              inLineStyles={{
                padding: 0,
              }}
            >
              <Div
                inLineStyles={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 30px 11px 34px",
                  backgroundColor: "#F9F9F9",
                }}
              >
                <Div>
                  <ul
                    style={{
                      display: "inline-flex",
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                      position: "absolute",
                      bottom: 0,
                    }}
                  >
                    <li
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        borderBottom: "2px solid #0071F2",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#0071F2",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      Appointments
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#6F6F6F",
                        fontSize: "14px",
                      }}
                    >
                      Vitals
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#6F6F6F",
                        fontSize: "14px",
                      }}
                    >
                      Reports
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        textDecoration: "none",
                        padding: "0px 0px 14px",
                        letterSpacing: ".9",
                        color: "#6F6F6F",
                        fontSize: "14px",
                      }}
                    >
                      Bill Info
                    </li>
                  </ul>
                </Div>
                <Div>
                  <Button
                    name="Book New Appointment"
                    inLineStyles={{
                      fontSize: "14px",
                      borderRadius: "8px",
                      backgroundColor: "#0071F2",
                      border: "none",
                      padding: "10px 14px 10px 14px",
                      fontWeight: "600",
                      letterSpacing: "0.9",
                    }}
                    onClick={() =>
                      this.props.history.push("/doctor_consultation")
                    }
                  ></Button>
                </Div>
              </Div>
              <Row
                key={"0"}
                alignItems="stretch"
                direction="row"
                justify="flex-start"
                spacing="2"
                inLineStyles={{
                  margin: "14px !important",
                  border: "1px solid #F0F0F0",
                  borderRadius: "8px",
                }}
              >
                <Col
                  key={"0"}
                  name="qdm-col-12"
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  textAlign="left"
                  lg="4"
                  xs="12"
                  md="4"
                  sm="4"
                  style={{
                    borderRight: "1px solid #E0E0E0",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <ListItem
                    title={
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "#101010",
                        }}
                      >
                        UPCOMING
                      </span>
                    }
                    inLineStyles={{
                      marginTop: "3%",
                      marginBottom: "12px",
                      paddingLeft: 0,
                    }}
                    description={false}
                    profile={false}
                    action={
                      <div
                        style={{
                          display: "flex",
                          right: "-12px",
                          position: "absolute",
                          top: "-12px",
                          cursor: "pointer",
                        }}
                      >
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            marginRight: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="search"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            inLineStyles={{
                              color: "#B6B6B6",
                              fontSize: "12px",
                            }}
                          ></Icons>
                        </Div>
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="filter"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            style={{ color: "#B6B6B6", fontSize: "12px" }}
                          ></Icons>
                        </Div>
                      </div>
                    }
                  />
                  <Text
                    key={"0"}
                    name="Today"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      fontWeight: "600",

                      textTransform: "uppercase",
                    }}
                  ></Text>
                  <div
                    style={{
                      marginTop: "10px",
                      boxShadow: "none",
                      border: "1px solid #F0F0F0",
                      backgroundColor: "white",
                      height: 138,
                      padding: "15px 17px 18px 15px",
                      borderRadius: 8,
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: "16px" }}>
                      <img
                        alt="img"
                        src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                        style={{
                          marginRight: "16px",
                          width: "46px",
                          height: "42px",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#101010",
                              fontWeight: "600",
                            }}
                          >
                            Dr. Priya Dharshini
                          </span>
                          <br />
                          <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
                            General Physician
                          </span>
                        </div>
                        <div style={{ marginRight: "8px" }}>
                          <img alt="img" src={DotMenu} style={{}} />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "46px",
                          height: "42px",
                          backgroundColor: "#F9F9F9",
                          borderRadius: "50px",
                          padding: "10px",
                          textAlign: "center",
                          marginRight: "16px",
                        }}
                      >
                        <img alt="img" src={Calendar} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#101010",
                              fontWeight: "600",
                            }}
                          >
                            20 Apr 21 | 10:00 AM
                          </span>
                          <br />
                          <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
                            Direct Consultation
                          </span>
                        </div>
                        <div
                          style={{ display: "inherit", marginBottom: "8px" }}
                        >
                          <Tooltip title="View" placement="top">
                            <div
                              className="hover"
                              style={{
                                padding: "1px 6px",
                                borderRadius: "5px",
                                marginLeft: "6px",
                              }}
                              onClick={() => this.openView()}
                            >
                              <img alt={"Eye"} src={Eye} />
                            </div>
                          </Tooltip>
                          <div
                            style={{
                              width: "1px",
                              backgroundColor: "#E0E0E0",
                              marginLeft: "8px",
                              height: "12px",
                              marginTop: "6px",
                            }}
                          />
                          <Tooltip title="Modify Appointment" placement="top">
                            <div
                              className="hover"
                              style={{
                                padding: "1px 6px",
                                borderRadius: "5px",
                                marginLeft: "6px",
                              }}
                              onClick={() =>
                                this.props.history.push("/drlising")
                              }
                            >
                              <img alt={"Modify"} src={Modify} />
                            </div>
                          </Tooltip>
                          <div
                            className="hover"
                            style={{
                              width: "1px",
                              backgroundColor: "#E0E0E0",
                              marginLeft: "8px",
                              height: "12px",
                              marginTop: "6px",
                            }}
                          />
                          <Tooltip title="Cancel Appointment" placement="top">
                            <div
                              style={{
                                padding: "1px 6px",
                                borderRadius: "5px",
                                marginLeft: "6px",
                              }}
                              onClick={() => this.handlesidebarCancelbooking()}
                            >
                              <img
                                alt={"CancelAppointment"}
                                src={CancelAppointment}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  key={"1"}
                  name="qdm-col-12"
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  textAlign="left"
                  lg="4"
                  xs="12"
                  md="4"
                  sm="4"
                  style={{
                    borderRight: "1px solid #E0E0E0",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <ListItem
                    title={
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textTransform: "uppercase",
                        }}
                      >
                        Follow up reminder
                      </span>
                    }
                    description={false}
                    profile={false}
                    inLineStyles={{
                      marginTop: "3%",
                      marginBottom: "12px",
                      paddingLeft: 0,
                    }}
                    action={
                      <div
                        style={{
                          display: "flex",
                          right: "-12px",
                          position: "absolute",
                          top: "-12px",
                          cursor: "pointer",
                        }}
                      >
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            marginRight: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="search"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            inLineStyles={{
                              color: "#B6B6B6",
                              fontSize: "12px",
                            }}
                          ></Icons>
                        </Div>
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="filter"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            style={{ color: "#B6B6B6", fontSize: "12px" }}
                          ></Icons>
                        </Div>
                      </div>
                    }
                  />
                  <Text
                    key={"0"}
                    name="This week"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                    }}
                  ></Text>
                  <div
                    style={{
                      marginTop: "10px",
                      boxShadow: "none",
                      border: "1px solid #F0F0F0",
                      backgroundColor: "white",
                      height: 138,
                      padding: "15px 17px 18px 15px",
                      borderRadius: 8,
                    }}
                  >
                    <div style={{ display: "flex", marginBottom: "16px" }}>
                      <img
                        alt="img"
                        src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                        style={{
                          marginRight: "16px",
                          width: "46px",
                          height: "42px",
                          borderRadius: "50%",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#101010",
                              fontWeight: "600",
                            }}
                          >
                            Dr. Priya Dharshini
                          </span>
                          <br />
                          <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
                            General Physician
                          </span>
                        </div>
                        <div style={{ marginRight: "8px" }}>
                          <img alt="img" src={DotMenu} style={{}} />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "46px",
                          height: "42px",
                          backgroundColor: "#fff9f2",
                          borderRadius: "50px",
                          padding: "10px",
                          textAlign: "center",
                          marginRight: "16px",
                        }}
                      >
                        <img alt="img" src={Warning} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              fontSize: "14px",
                              color: "#F58B00",
                              fontWeight: "600",
                            }}
                          >
                            Follow up in 2 days
                          </span>
                          <br />
                          <span style={{ color: "#6F6F6F", fontSize: "12px" }}>
                            Direct Consultation
                          </span>
                        </div>
                        <div
                          style={{ display: "inherit", marginBottom: "8px" }}
                        >
                          <button
                            style={{
                              color: "#0071F2",
                              fontSize: "14px",
                              fontWeight: "600",
                              backgroundColor: "white",
                              border: "1px solid #0071F2",
                              padding: "6px 16px",
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  key={"2"}
                  name="qdm-col-12"
                  alignItems="stretch"
                  direction="row"
                  justify="flex-start"
                  textAlign="left"
                  lg="4"
                  xs="12"
                  md="4"
                  sm="4"
                  style={{
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <ListItem
                    title={
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          textTransform: "uppercase",
                        }}
                      >
                        PAST
                      </span>
                    }
                    inLineStyles={{ marginTop: "3%", marginBottom: "12px" }}
                    description={false}
                    profile={false}
                    action={
                      <div
                        style={{
                          display: "flex",
                          right: "-12px",
                          position: "absolute",
                          top: "-12px",
                          cursor: "pointer",
                        }}
                      >
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            marginRight: "8px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="search"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            inLineStyles={{
                              color: "#B6B6B6",
                              fontSize: "12px",
                            }}
                          ></Icons>
                        </Div>
                        <Div
                          style={{
                            border: "1px solid #E0E0E0",
                            padding: "4px 10px",
                            borderRadius: "4px",
                            color: "#B6B6B6",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Icons
                            key={"2"}
                            fontIcon="filter"
                            ariaHidden="true"
                            className=""
                            size="medium"
                            style={{ color: "#B6B6B6", fontSize: "12px" }}
                          ></Icons>
                        </Div>
                      </div>
                    }
                  />
                  <Text
                    key={"0"}
                    name="This Month"
                    style={{
                      color: "#6F6F6F",
                      fontSize: "14px",
                      fontWeight: "600",
                      padding: "0px 14px",
                      textTransform: "uppercase",
                    }}
                  ></Text>
                  <div style={{ height: "70vh" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        boxShadow: "none",
                        border: "1px solid #F0F0F0",
                        backgroundColor: "white",
                        height: 138,
                        padding: "15px 17px 18px 15px",
                        borderRadius: 8,
                      }}
                    >
                      <div style={{ display: "flex", marginBottom: "16px" }}>
                        <img
                          alt="img"
                          src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                          style={{
                            marginRight: "16px",
                            width: "46px",
                            height: "42px",
                            borderRadius: "50%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#101010",
                                fontWeight: "600",
                              }}
                            >
                              Dr. Priya Dharshini
                            </span>
                            <br />
                            <span
                              style={{ color: "#6F6F6F", fontSize: "12px" }}
                            >
                              General Physician
                            </span>
                          </div>
                          <div style={{ marginRight: "8px" }}>
                            <img alt="img" src={DotMenu} />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "46px",
                            height: "42px",
                            backgroundColor: "#F9F9F9",
                            borderRadius: "50px",
                            padding: "10px",
                            textAlign: "center",
                            marginRight: "16px",
                          }}
                        >
                          <img alt="img" src={Calendar} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            width: "100%",
                          }}
                        >
                          <div>
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#101010",
                                fontWeight: "600",
                              }}
                            >
                              20 Apr 21 | 10:00 AM
                            </span>
                            <br />
                            <span
                              style={{ color: "#6F6F6F", fontSize: "12px" }}
                            >
                              Direct Consultation
                            </span>
                          </div>
                          <div
                            style={{
                              display: "inherit",
                              marginBottom: "8px",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                color: "#258007",
                                fontSize: "10px",
                                fontWeight: "600",
                                backgroundColor: "#f0fcef",
                                border: "none",
                                padding: "5px 22px",
                                borderRadius: "8px",
                                position: "absolute",
                                top: "-32px",
                                letterSpacing: "1px",
                              }}
                            >
                              COMPLETED
                            </div>
                            <button
                              style={{
                                color: "#0071F2",
                                fontSize: "14px",
                                fontWeight: "600",
                                backgroundColor: "white",
                                border: "1px solid #0071F2",
                                padding: "6px 16px",
                                borderRadius: "8px",
                                cursor: "pointer",
                              }}
                            >
                              Book Again
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </Col>
              </Row>
            </Col>
          </Row>
          <ModelComponent
            hiddencloseIcon={true}
            open={this.state.open}
            onClose={this.openView}
            childrens={<ConfirmationPage appointmentDetails={true} 
            parent_id={'patient_dashboard'}
            />}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appointments: state?.appointmentReducer?.readAppointment,
  members: state?.personApiSlice?.readPerson,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(AppointmentsListingPage));
