
import React from "react";
import { UIColor } from "../../utils";

export const DocumentIcon = (props) => {
  let width = props.width ? props.width : "18";
  let height = props.height ? props.height : "17";
  let color = props.color ? props.color : UIColor.sidebarIconColor.color;
  return (
    <svg id="document_svg" xmlns="http://www.w3.org/2000/svg"  {...props} width={width} height={height} viewBox="0 0 18 17.1">
      <path
        class="a"
        fill={color}
        d="M6.025,5A2.027,2.027,0,0,0,4,7.025v13.05A2.027,2.027,0,0,0,6.025,22.1H7.969a2.917,2.917,0,0,1-.737-1.35H6.025a.676.676,0,0,1-.675-.675V7.025a.676.676,0,0,1,.675-.675H7.232A2.921,2.921,0,0,1,7.969,5Zm4.05,0A2.035,2.035,0,0,0,8.05,7.025v13.05A2.035,2.035,0,0,0,10.075,22.1h9.9A2.035,2.035,0,0,0,22,20.075v-9a.675.675,0,0,0-.2-.477L16.4,5.2a.675.675,0,0,0-.477-.2Zm0,1.35H15.25V9.725a2.035,2.035,0,0,0,2.025,2.025H20.65v8.325a.665.665,0,0,1-.675.675h-9.9a.665.665,0,0,1-.675-.675V7.025A.665.665,0,0,1,10.075,6.35ZM16.6,7.3l3.1,3.1H17.275a.665.665,0,0,1-.675-.675ZM12.325,14a.675.675,0,1,0,0,1.35h5.4a.675.675,0,1,0,0-1.35Zm0,3.15a.675.675,0,1,0,0,1.35h4.05a.675.675,0,1,0,0-1.35Z"
        transform="translate(-4 -5)" 
        />
    </svg>
  );
};