import React from "react";
import { Col, H6, Paper, Row, Div, Icons, Text } from 'qdm-component-library'
import "./style.css"
import { Typography, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import moment from "moment";

const styles = (theme) => ({
    listview: {
        background: "#f9f9f9",
        borderRadius: 6,
        padding: 20,
        backgroundImage: "url('/images/referral/Mask Group 46.svg')",
        backgroundRepeat: 'no-repeat',
    },
    title_list: {
        color: '#6f6f6f',
        fontSize: 13,
    }, title_bold: {
        fontSize: 12,
        marginLeft: 20,
    }, title_bold_chips: {
        padding: '6px',
        background: '#f0f0f0',
        borderRadius: '6px',
    }, underline: {
        width: '80%',
        height: '2px',
        // background: '#E0E0E0',
        marginTop: '7px',
        borderBottom: "1.7px dashed #E0E0E0"
    }
})
class ReferralBannerListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {

        const { ReferralData, classes, parent_id } = this.props;

        const referral_letter_data = [
            {
                name: "Referral Letter No:", data: ReferralData?.referralLetter?.[0]?.referralLetterNo,
                img: '/images/referral/Mask Group 22.svg'
            },
            { name: false },
            {
                img: '/images/referral/icons8-calendar.svg',
                name: "Referral Date", data: moment.unix(ReferralData?.referralLetter?.[0]?.referralDate).format("DD-MM-YYYY") ?? '#----'
            },
            {
                img: '/images/referral/icons8-calendar.svg',
                name: "Recived Date", data: moment.unix(ReferralData?.referralLetter?.[0]?.receivedDate).format("DD-MM-YYYY") ?? '#----'
            },
            {
                img: '/images/referral/icons8-identification-documents.svg',
                name: "Referral ID", data: ReferralData?._id ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 25.svg',
                name: "Status", data: ReferralData?.activestatus ? "Active" : "In-Active"
            },
        ]

        const referral_form_data = [
            {
                img: '/images/referral/Mask Group 36.svg',
                name: "Source", data: ReferralData?.referralFrom?.[0]?.from ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 33.svg',
                name: "Facility Type", data: ReferralData?.referralFrom?.[0]?.facilityType ?? '#----'
            },
            {
                img: '/images/referral/icons8-medical-doctor (1).svg',
                name: "Facility Name", data: ReferralData?.referralFrom?.[0]?.facilityName ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 26.svg',
                name: "Specialty", data: ReferralData?.referralFrom?.[0]?.specialty ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 30.svg',
                name: "Practitioner Type", data: ReferralData?.referralFrom?.[0]?.practitionerType ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 43.svg',
                name: "Practitioner", data: ReferralData?.referralFrom?.[0]?.practitioner ?? '#----'
            },
        ]

        const refered_to_data = [
            {
                img: '/images/referral/Mask Group 26.svg',
                name: "Specialty", data: ReferralData?.referralTo?.[0]?.specialty ?? '#----'
            },
            {
                img: '/images/referral/icons8-address.svg',
                name: "Location", data: ReferralData?.referralTo?.[0]?.location ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 30.svg',
                name: "Practitioner Type", data: ReferralData?.referralTo?.[0]?.practitionerType ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 43.svg',
                name: "Practitioner", data: ReferralData?.referralTo?.[0]?.practitioner ?? '#----'
            },
        ]

        const refered_details_data = [
            {
                img: '/images/referral/Mask Group 32.svg',
                name: "Intent Type", data: ReferralData?.referralDetails?.[0]?.intentType ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 40.svg',
                name: "For", data: ReferralData?.referralDetails?.[0]?.for ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 37.svg',
                name: "Priority", data: ReferralData?.priority ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 41.svg',
                name: "Request Reason", data: ReferralData?.reasonReference ?? '#----'
            },
            {
                img: '/images/referral/Mask Group 43.svg',
                name: "Service Reqested", data: ReferralData?.serviceRequested ?? "#----"
            },
        ]

        const refered_note_data = [
            {
                img: '/images/referral/Mask Group 35.svg',
                name: "Reason for Referral", data: ReferralData?.note?.[0]?.authorreference ?? '#----'
            },
        ]

        return <Paper
            id={`${parent_id}_view_details_parent_paper`}
            className="rv-pl-root" style={{ padding: 20 }}>
            <Row
                id={`${parent_id}_view_details_parent_row`}
            >
                <Col
                    id={`${parent_id}_view_details_reletter_col`}
                    md={4} lg={4} sm={4} xs={12} inLineStyles={{ padding: 0 }} className="pl-detail-root">
                    <Div
                        id={`${parent_id}_view_details_reletter_div`}
                        className={classes.listview}>
                        <H6
                            id={`${parent_id}_view_details_reletter_h6`}
                            className="en-title space-padding-bottom">REFERRAL LETTER</H6>
                        <Row
                            id={`${parent_id}_view_details_reletter_row`}
                        >  {referral_letter_data?.map((v, i) => {
                            if (v.name) {
                                return <Col
                                    id={`${parent_id}_${i}_view_details_reletter_img_col`}
                                    md={6} lg={6} sm={6} xs={12}
                                    inLineStyles={{ padding: 0, marginTop: 8 }} className="pl-detail-root">
                                    <Typography
                                        id={`${parent_id}_${i}_view_details_reletter_typography`}
                                        className={classes.title_list}>
                                        {/* <Icons
                                            inLineStyles={{ color: "#0071F2" }}
                                            fontIcon="user-o" color='#0071F2' /> */}
                                        <img
                                            id={`${parent_id}_${i}_view_details_reletter_img`}
                                            src={v.img} />
                                        &nbsp;&nbsp;
                                        {v.name}
                                    </Typography>
                                    <b
                                        id={`${parent_id}_${i}_view_details_reletter_b`}
                                        className={classes.title_bold} name={v?.data}>{v?.data}</b>
                                </Col>
                            } else {
                                return <Col
                                    id={`${parent_id}_${i}_view_details_reletter_space_col`}
                                    md={6} lg={6} sm={6} xs={12}
                                    inLineStyles={{ padding: 0, marginTop: 8 }} className="pl-detail-root">&nbsp;</Col>
                            }
                        })}
                        </Row>
                    </Div>
                </Col>
                <Col
                    id={`${parent_id}_view_details_relform_parent_col`}
                    md={8} lg={8} sm={8} xs={12} inLineStyles={{ padding: 0 }} className="pl-detail-root">
                    <Div
                        id={`${parent_id}_view_details_relform_parent_div`}
                        inLineStyles={{ padding: 20 }}>
                        <Div
                            id={`${parent_id}_view_details_relform_div`}
                            style={{ display: "flex" }}>
                            <H6
                                id={`${parent_id}_view_details_relform_h6`}
                                className="en-title space-padding-bottom">REFERRAL FORM</H6>
                            <Div
                                id={`${parent_id}_view_details_relform_space_div`}
                                className={classes.underline}>&nbsp;</Div>  </Div>
                        <Row
                            id={`${parent_id}_view_details_relform_row`}
                        >
                            {referral_form_data?.map((v, i) => {
                                if (v.name) {
                                    return <Col
                                        id={`${parent_id}_view_details_relform_col`}
                                        md={4} lg={4} sm={4} xs={12}
                                        inLineStyles={{ padding: 0, marginTop: 8 }} className="pl-detail-root">
                                        <Typography
                                            id={`${parent_id}_view_details_relform_typography`}
                                            className={classes.title_list}>
                                            <img
                                                id={`${parent_id}_view_details_relform_img`}
                                                src={v.img} />
                                            {/* <Icons
                                                inLineStyles={{ color: "#0071F2" }}
                                                fontIcon="user-o" color='#0071F2' /> */}
                                            &nbsp;&nbsp;
                                            {v.name}
                                        </Typography>
                                        <b
                                            id={`${parent_id}_view_details_relform_b`}
                                            className={classes.title_bold} name={v?.data}>{v?.data}</b>
                                    </Col>
                                } else {
                                    return <Col
                                        id={`${parent_id}_view_details_relform_space_col`}
                                        md={4} lg={4} sm={4} xs={12}
                                        inLineStyles={{ padding: 0, marginTop: 8 }} className="pl-detail-root">&nbsp;</Col>
                                }
                            })}
                        </Row>
                    </Div>
                    <Div
                        id={`${parent_id}_view_details_relto_parent_div`}
                        inLineStyles={{ padding: '0px 20px' }}>
                        <Div
                            id={`${parent_id}_view_details_relto_title_div`}
                            style={{ display: "flex" }}>
                            <H6
                                id={`${parent_id}_view_details_relto_title_h6`}
                                className="en-title space-padding-bottom">REFERRAL TO</H6>
                            <Div
                                id={`${parent_id}_view_details_relto_space_div`}
                                className={classes.underline}>&nbsp;</Div>  </Div>
                        <Row
                            id={`${parent_id}_view_details_relto_row`}
                        >
                            {refered_to_data?.map((v, i) => {
                                if (v.name) {
                                    return <Div
                                        id={`${parent_id}_${i}_view_details_relto_sub_div`}
                                        inLineStyles={{ padding: 0, marginTop: 8, marginRight: 20 }} className="pl-detail-root">
                                        <Typography
                                            id={`${parent_id}_${i}_view_details_relto_typography`}
                                            className={classes.title_list}>
                                            <img
                                                id={`${parent_id}_${i}_view_details_relto_img`}
                                                src={v.img} />
                                            {/* <Icons
                                                inLineStyles={{ color: "#0071F2" }}
                                                fontIcon="user-o" color='#0071F2' /> */}
                                            &nbsp;&nbsp;
                                            {v.name}
                                        </Typography>
                                        <b
                                            id={`${parent_id}_${i}_view_details_relto_b`}
                                            className={classes.title_bold} name={v?.data}>{v?.data}</b>
                                    </Div>
                                }
                            })}
                        </Row>
                    </Div>
                </Col>
                <Div
                    id={`${parent_id}_view_details_reldetails_parent_div`}
                    inLineStyles={{ padding: 20, width: "100%" }}>
                    <Div
                        id={`${parent_id}_view_details_reldetails_title_div`}
                        style={{ display: "flex" }}>
                        <H6
                            id={`${parent_id}_view_details_reldetails_title_h6`}
                            className="en-title space-padding-bottom">REFERRAL DETAILS</H6>
                        <Div
                            id={`${parent_id}_view_details_reldetails_space_div`}
                            className={classes.underline} style={{ width: "86%" }}>&nbsp;</Div>  </Div>
                    <Row
                        id={`${parent_id}_view_details_reldetails_row`}
                        style={{ display: "flex" }}>
                        {refered_details_data?.map((v, i) => {
                            if (v.name && !v.select) {
                                return <Div
                                    id={`${parent_id}_${i}_view_details_reldetails_sub_div`}
                                    inLineStyles={{ padding: 0, marginTop: 8, marginRight: 20 }} className="pl-detail-root">
                                    <Typography
                                        id={`${parent_id}_${i}_view_details_reldetails_typography`}
                                        className={classes.title_list}>
                                        <img
                                            id={`${parent_id}_${i}_view_details_reldetails_img`}
                                            src={v.img} />
                                        {/* <Icons
                                            inLineStyles={{ color: "#0071F2" }}
                                            fontIcon="user-o" color='#0071F2' /> */}
                                        &nbsp;&nbsp;
                                        {v.name}
                                    </Typography>
                                    <b
                                        id={`${parent_id}_${i}_view_details_reldetails_b`}
                                        className={classes.title_bold} name={v?.data}>{v?.data}</b>
                                </Div>
                            } else if (v.select) {
                                return <Div
                                    id={`${parent_id}_view_details_reldetails_div`}
                                    inLineStyles={{ padding: 0, marginTop: 8, marginRight: 20 }} className="pl-detail-root">
                                    <Typography
                                        id={`${parent_id}_view_details_reldetails_typography`}
                                        className={classes.title_list} style={{ marginBottom: 6 }}>
                                        <img
                                            id={`${parent_id}_view_details_reldetails_img`}
                                            src={v.img} />
                                        {/* <Icons
                                            inLineStyles={{ color: "#0071F2" }}
                                            fontIcon="user-o" color='#0071F2' /> */}
                                        &nbsp;&nbsp;
                                        {v.name}
                                    </Typography>
                                    {(v?.data?.length > 0) ? v.data.map((l, i) => {
                                        return <b
                                            id={`${parent_id}_${i}_view_details_reldetails_b`}
                                            className={`${classes.title_bold} ${classes.title_bold_chips}`} name={l?.display}>{l?.display}</b>
                                    }) : '#----'}
                                </Div>
                            }
                        })}
                    </Row>
                </Div>
                <Div
                    id={`${parent_id}_view_details_relnote_parent_div`}
                    inLineStyles={{ padding: 20, width: "100%" }}>
                    <Div
                        id={`${parent_id}_view_details_relnote_title_div`}
                        style={{ display: "flex" }}>
                        <H6
                            id={`${parent_id}_view_details_relnote_title_h6`}
                            className="en-title space-padding-bottom">REFERRAL NOTE</H6>
                        <Div
                            id={`${parent_id}_view_details_relnote_title_space_div`}
                            className={classes.underline} style={{ width: "86%" }}>&nbsp;</Div>  </Div>
                    <Row
                        id={`${parent_id}_view_details_relnote_row`}
                    >
                        {refered_note_data?.map((v, i) => {
                            if (v.name) {
                                return <Div
                                    id={`${parent_id}_${i}_view_details_relnote_div`}
                                    inLineStyles={{ padding: 0, marginTop: 8, marginRight: 20 }} className="pl-detail-root">
                                    <Typography
                                        id={`${parent_id}_${i}_view_details_relnote_typography`}
                                        className={classes.title_list}>
                                        <img
                                            id={`${parent_id}_${i}_view_details_relnote_img`}
                                            src={v.img} />
                                        {/* <Icons
                                            inLineStyles={{ color: "#0071F2" }}
                                            fontIcon="user-o" color='#0071F2' /> */}
                                        &nbsp;&nbsp;
                                        {v.name}
                                    </Typography>
                                    <b
                                        id={`${parent_id}_${i}_view_details_relnote_b`}
                                        className={classes.title_bold} name={v?.data}>{v?.data}</b>
                                </Div>
                            }
                        })}
                    </Row>
                </Div>
            </Row>
        </Paper>
    }
}


export default withStyles(styles)(withRouter(ReferralBannerListView));