import React, { useState } from 'react';
import CurrentVisits from './currentVisitStats';
import { makeStyles,Typography } from '@material-ui/core';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useHistory } from "react-router-dom";
import { Routes } from '../../../../router/routes';
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { SwitchTab } from '../../components';
import { LoadingComp, TopHeader } from '../../../../components';
import moment from 'moment';
import { checkWithCasbin } from '../../../../utils/permissonHandling';
import { getUtcTime } from '../../../../utils';

const useStyles = makeStyles(()=>({
    root:{
    },
    content:{
        padding:20,
        height:'calc(100vh - 72.4px)',
        overflow:'auto'
    },
    header:{
        fontSize:'16px',
        color:'#101010',
        fontFamily:'pc_semibold',
    },
    rowCenter:{
        display:'flex',
        alignItems:'center'
    },
    iconWrapper:{
        backgroundColor:'#F0F0F0',
        width:26,
        height:26,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginRight:12,
        borderRadius:'50%',
        cursor:'pointer'
    },
    tabWrapper:{
        display:'flex',
        alignItems:'center',
        marginTop:20,
        '& $wrapper':{
            '&:nth-child(odd)':{
                '& $countWrapper':{
                    borderColor:'#FF6384',
                    color:'#FF6384'
                }
            },
            '&:nth-child(even)':{
                '& $countWrapper':{
                    borderColor:'#4BC0C0',
                    color:'#4BC0C0'
                }
            },
        }
    },
    wrapper:{
        border:'1px solid #E0E0E0',
        borderRadius:22,
        padding:8,
        display:'flex',
        alignItems:'center',
        fontSize:14,
        fontFamily:'pc_regular',
        marginRight:10,
        cursor:'pointer'
    },
    countWrapper:{
        width:29,
        height:29,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        border:'1px dashed',
        borderRadius:'50%',
        padding:5,
        fontSize:14,
        fontFamily:'pc_semibold',
    },
    tabTitle:{
        fontSize:14,
        fontFamily:'pc_regular',
        color:'#101010',
        flex:1,
        marginRight:10,
        paddingLeft:8,
    }
}));

export const CurrentVisitStats = (props) =>{

    const classes = useStyles(props);
    const history = useHistory();
    const dispatch = useDispatch();

    const TAB = [
      {
        label: "Patient checked-in",
        value: "patientsCheckedIn",
        actionsName: "VISIT_CHECKEDIN",
        count: "0",
      },
      {
        label: "Triage done",
        value: "triageDone",
        actionsName: "VISIT_TRAIGE_DONE",
        count: "0",
      },
      {
        label: "Visit completed",
        value: "visitCompleted",
        actionsName: "VISIT_COMPLETED",
        count: "0",
      },
      {
        label: "Patients no shows",
        value: "patientsNoShows",
        actionsName: "VISIT_NO_SHOWS",
        count: "0",
      },
    ];

    const [state,setState] = React.useState({
        selectedTab:TAB[0],
        report:[]
    })

     const [permission, setPermission] = React.useState({});

    React.useEffect(() => {
        if(props?.location?.state?.index >= 0){
            getSelectedTab(TAB[props?.location?.state?.index]);
            onChangeState('selectedTab',TAB[props?.location?.state?.index]);
        }
      }, []);


        React.useEffect(() => {
          const initFun = async () => {
            let permission = await checkWithCasbin(["dashboard"]);
            setPermission(permission);
          };
          initFun();
        }, []);

         React.useEffect(() => {
           if (props?.location?.state?.index >= 0) {
             getSelectedTab(TAB[props?.location?.state?.index]);
             onChangeState("selectedTab", TAB[props?.location?.state?.index]);
           }
         }, [permission]);
    const navigateBack = () =>{
        history.push({
            pathname:Routes.dashboard
          })
    }

    const onChangeState = (key,value) =>{
        setState({
            ...state,[key]:value
        })
    }

    const [loader,setLoader] = useState(true)
    const getFrontDeskDetails = async (data) =>{
        setLoader(true)
        setState({ ...state, report: [] })
        let value;
        let payload = {
          orgID: props?.location?.state?.organizationID,
          orgIdInt: props?.location?.state?.organizationInt,
          practionerID: props?.location?.state?.practionerID,
          startDate: getUtcTime(moment().startOf("day")),
          endDate: getUtcTime(moment().endOf("day")),
        };

        switch(data.value){
            case 'patientsCheckedIn':
                value = await dispatch(actions.GET_PATIENTS_CHECKED_IN(payload));
                break;
            case 'triageDone':
                value = await dispatch(actions.GET_TRAIGE_DONE(payload));
                break;
            case 'visitCompleted':
                value = await dispatch(actions.GET_VISIT_COMPLETED(payload));
                break;
            case 'patientsNoShows':
                value = await dispatch(actions.GET_PATIENTS_NO_SHOWS(payload));
                break;
            default:
                value = await dispatch(actions.GET_PATIENTS_CHECKED_IN(payload));
                break;
        }
        setLoader(false)
        return value
    }
    const getNurseDetails = async (data) =>{
        setLoader(true)
        setState({ ...state, report: [] })
        let value;
        let payload = {
          orgID: props?.location?.state?.organizationID,
          orgIdInt: props?.location?.state?.organizationInt,
          practionerID: props?.location?.state?.practionerID,
          startDate: getUtcTime(moment().startOf("day")),
          endDate: getUtcTime(moment().endOf("day")),
        };

        switch(data.value){
            case 'patientsCheckedIn':
                value = await dispatch(actions.GET_PATIENTS_CHECKED_IN_NURSE(payload));
                break;
            case 'triageDone':
                value = await dispatch(actions.GET_TRAIGE_DONE_NURSE(payload));
                break;
            case 'visitCompleted':
                value = await dispatch(actions.GET_VISIT_COMPLETED_NURSE(payload));
                break;
            case 'patientsNoShows':
                value = await dispatch(actions.GET_PATIENTS_NO_SHOWS_NURSE(payload));
                break;
            default:
                value = await dispatch(actions.GET_PATIENTS_CHECKED_IN_NURSE(payload));
                break;
        }
        setLoader(false)
        return value
    }
    const getDoctorDetails = async (data) =>{
        setLoader(true)
        setState({ ...state, report: [] })
        let value;
        let payload = {
          orgID: props?.location?.state?.organizationID,
          orgIdInt: props?.location?.state?.organizationInt,
          practionerID: props?.location?.state?.practionerID,
          startDate: getUtcTime(moment().startOf("day")),
          endDate:  getUtcTime(moment().endOf("day")),
        };

        switch(data.value){
            case 'patientsCheckedIn':
                value = await dispatch(actions.GET_PATIENTS_CHECKED_IN_DOCTOR(payload));
                break;
            case 'triageDone':
                value = await dispatch(actions.GET_TRAIGE_DONE_DOCTOR(payload));
                break;
            case 'visitCompleted':
                value = await dispatch(actions.GET_VISIT_COMPLETED_DOCTOR(payload));
                break;
            case 'patientsNoShows':
                value = await dispatch(actions.GET_PATIENTS_NO_SHOWS_DOCTOR(payload));
                break;
            default:
                value = await dispatch(actions.GET_PATIENTS_CHECKED_IN_DOCTOR(payload));
                break;
        }
        setLoader(false)
        return value
    }

    const getSelectedTab = async (data) =>{
        let value;

        if (permission?.write?.indexOf("listingScreenN") > -1) {
          value = await getNurseDetails(data);
        } else if (permission?.write?.indexOf("listingScreenD") > -1) {
          value = await getDoctorDetails(data);
        } else if (permission?.write?.indexOf("listingScreenF") > -1) {
          value = await getFrontDeskDetails(data);
        }
        if (value) {
            setState({
                ...state,
                report:value?.payload?.data,
                selectedTab:data
            })
        }

    }
    const columnToShow = () => {
        let data = [
          { uniqueName: "name", caption: "Name" },
          { uniqueName: "mrn", caption: "Mrn" },
          { uniqueName: "orderDate", caption: "Order Date" },
          { uniqueName: "orderTime", caption: "Order Time" },
          { uniqueName: "speciality", caption: "Speciality" },
          { uniqueName: "ward", caption: "Ward" },
          { uniqueName: "age", caption: "Age" },
          { uniqueName: "contact", caption: "Contact" },
          { uniqueName: "type", caption: "Type" },
          { uniqueName: "encounterStatus", caption: "Encounter Status" },
          { uniqueName: "action", caption: "Action" },
        ];
      return data
      };
    return(
        <div className={classes.root}>
            <TopHeader
                parent_id={'appointment_listing'}
                noHome={true} middleComponent={<></>} 
            />
            <div className={classes.content}>
                <div className={classes.rowCenter}>
                    <div className={classes.iconWrapper} onClick={navigateBack}>
                        <NavigateBeforeIcon />
                    </div>
                    <Typography variant="body1" className={classes.header}>{'Current Visit Stats'}</Typography>
                </div>
                <SwitchTab 
                    orgID={props?.location?.state?.organizationID}
                    orgIDInt={props?.location?.state?.organizationInt}
                    practionerID={props?.location?.state?.practionerID}
                    roleId={props?.location?.state?.roleId}
                    options={TAB}
                    selectedTab={state.selectedTab}
                    getSelectedTab={getSelectedTab}
                />
                <LoadingComp loading={loader} style={{alignItems: "center", height:"65vh"}} >
                {state.report.length > 0 ? <div style={{paddingTop:12}}>
                    <CurrentVisits 
                        report={state.report}
                        permission={permission}
                        columnToShow={columnToShow()}
                    />
                </div> : <div><p style={{ textAlign: "center", marginTop: "11%" }}>No data found</p></div>}
                </LoadingComp>
            </div>
        </div>
    )
}