import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";
import { Avatar, Div, H6 } from "qdm-component-library";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { UIColor } from "../../../utils";
const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "unset !important",
    borderRadius: 24,
    backgroundColor: "#fff",
    // padding: 6,
    cursor: "pointer",
    overflow: "hidden",
    margin: "4px",
  },
});

export const SliderCom = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: true,
    arrows: true,
  };

  const styles = {
    title: {
      fontSize: 11,
      fontFamily: "pc_regular",
      fontWeight: 600,
      width: "-webkit-fill-available",
    },
    count: {
      color: "#9b9b9b",
      fontFamily: "pc_regular",
      width: "-webkit-fill-available",
      fontSize: 10,
    },
  };
  const [open, setopen] = React.useState(1);

  const classes = useStyles(props);
  const { data } = props;

  const handleOpen = (i) => {
    setopen(i);
  };
  return (
    <Slider {...settings} style={{ width: "100%", margin: "4px" }}>
      {data?.map((v, i) => {
        return (
          <>
            {/* {!v?.status && ( */}

            <div>
              <div
                className={classes.muiPopovers}
                onClick={(e) => props.onClickPractitoner(v)}
                style={{
                  border:
                    props.selectedForm === v._key
                      ? `1px solid ${UIColor.primaryColor.color}`
                      : `1px solid ${UIColor.lineBorderFill.color}`,
                }}
              >
                <Div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {!v?.status ? (
                    <DescriptionIcon
                      style={{ color: "#F58B00", padding: 2, margin: 4 }}
                    />
                  ) : (
                    <CheckCircleIcon
                      style={{ color: `${UIColor.success.color}`, padding: 2, margin: 4 }}
                    />
                  )}
                  <H6 inLineStyles={styles.title}>
                    {v?.name === " " ? <>Practitioner Name {i}</> : v?.name}
                    {props.isYou && <>(You) </>}
                  </H6>
                  &nbsp;
                  <H6 inLineStyles={styles.count}>{v?.date}</H6>
                </Div>
              </div>
            </div>
            {/* )} */}
          </>
        );
      })}
    </Slider>
  );
};
