export const CountryRace = [
    {
      "Country": "Abkhazia",
      "Demonyms": "Abkhazians"
    },
    {
      "Country": "Afghanistan",
      "Demonyms": "Afghans"
    },
    {
      "Country": "Åland",
      "Demonyms": "Ålanders"
    },
    {
      "Country": "Albania",
      "Demonyms": "Albanians"
    },
    {
      "Country": "Algeria",
      "Demonyms": "Algerians"
    },
    {
      "Country": "American Samoa",
      "Demonyms": "American Samoans"
    },
    {
      "Country": "Andorra",
      "Demonyms": "Andorrans"
    },
    {
      "Country": "Angola",
      "Demonyms": "Angolans"
    },
    {
      "Country": "Anguilla",
      "Demonyms": "Anguillans"
    },
    {
      "Country": "Antarctica[a]",
      "Demonyms": "Antarctic residents"
    },
    {
      "Country": "Antigua and Barbuda",
      "Demonyms": "AntiguansBarbudans"
    },
    {
      "Country": "Argentina",
      "Demonyms": "ArgentinesArgentinians"
    },
    {
      "Country": "Armenia",
      "Demonyms": "Armenians"
    },
    {
      "Country": "Aruba",
      "Demonyms": "Arubans"
    },
    {
      "Country": "Australia",
      "Demonyms": "Australians"
    },
    {
      "Country": "Austria",
      "Demonyms": "Austrians"
    },
    {
      "Country": "Azerbaijan",
      "Demonyms": "AzerbaijanisAzeris"
    },
    {
      "Country": "The Bahamas",
      "Demonyms": "Bahamians"
    },
    {
      "Country": "Bahrain",
      "Demonyms": "Bahrainis"
    },
    {
      "Country": "Bangladesh",
      "Demonyms": "Bangladeshis"
    },
    {
      "Country": "Barbados",
      "Demonyms": "Barbadians"
    },
    {
      "Country": "Belarus",
      "Demonyms": "Belarusians"
    },
    {
      "Country": "Belgium",
      "Demonyms": "Belgians"
    },
    {
      "Country": "Belize",
      "Demonyms": "Belizeans"
    },
    {
      "Country": "Benin",
      "Demonyms": "BenineseBeninois"
    },
    {
      "Country": "Bermuda",
      "Demonyms": "BermudiansBermudans"
    },
    {
      "Country": "Bhutan",
      "Demonyms": "Bhutanese"
    },
    {
      "Country": "Bolivia",
      "Demonyms": "Bolivians"
    },
    {
      "Country": "Bonaire",
      "Demonyms": "Bonaire Dutch"
    },
    {
      "Country": "Bosnia and Herzegovina",
      "Demonyms": "BosniansHerzegovinians"
    },
    {
      "Country": "Botswana",
      "Demonyms": "Batswana (singular Motswana)"
    },
    {
      "Country": "Bouvet Island",
      "Demonyms": "Bouvet Islanders"
    },
    {
      "Country": "Brazil",
      "Demonyms": "Brazilians"
    },
    {
      "Country": "British Indian Ocean Territory",
      "Demonyms": "British"
    },
    {
      "Country": "Brunei",
      "Demonyms": "Bruneians"
    },
    {
      "Country": "Bulgaria",
      "Demonyms": "Bulgarians"
    },
    {
      "Country": "Burkina Faso",
      "Demonyms": "BurkinabèBurkinabé"
    },
    {
      "Country": "Burma[b]",
      "Demonyms": "BurmeseBurman"
    },
    {
      "Country": "Burundi",
      "Demonyms": "BurundiansBarundi"
    },
    {
      "Country": "Cabo Verde[c]",
      "Demonyms": "Cabo Verdeans"
    },
    {
      "Country": "Cambodia",
      "Demonyms": "Cambodians"
    },
    {
      "Country": "Cameroon",
      "Demonyms": "Cameroonians"
    },
    {
      "Country": "Canada",
      "Demonyms": "Canadians"
    },
    {
      "Country": "Cayman Islands",
      "Demonyms": "Caymanians"
    },
    {
      "Country": "Central African Republic",
      "Demonyms": "Central Africans"
    },
    {
      "Country": "Chad",
      "Demonyms": "Chadians"
    },
    {
      "Country": "Chile",
      "Demonyms": "Chileans"
    },
    {
      "Country": "China",
      "Demonyms": "Chinese"
    },
    {
      "Country": "Christmas Island",
      "Demonyms": "Christmas Islanders"
    },
    {
      "Country": "Cocos (Keeling) Islands",
      "Demonyms": "Cocos Islanders"
    },
    {
      "Country": "Colombia",
      "Demonyms": "Colombians"
    },
    {
      "Country": "Comoros",
      "Demonyms": "ComoransComorians"
    },
    {
      "Country": "Democratic Republic of the Congo",
      "Demonyms": "Congolese"
    },
    {
      "Country": "Republic of the Congo",
      "Demonyms": "Congolese"
    },
    {
      "Country": "Cook Islands",
      "Demonyms": "Cook Islanders"
    },
    {
      "Country": "Costa Rica",
      "Demonyms": "Costa Ricans"
    },
    {
      "Country": "Croatia",
      "Demonyms": "CroatiansCroats"
    },
    {
      "Country": "Cuba",
      "Demonyms": "Cubans"
    },
    {
      "Country": "Curaçao",
      "Demonyms": "Curaçaoans"
    },
    {
      "Country": "Cyprus",
      "Demonyms": "Cypriots"
    },
    {
      "Country": "Czech Republic",
      "Demonyms": "Czechs"
    },
    {
      "Country": "Denmark",
      "Demonyms": "Danes"
    },
    {
      "Country": "Djibouti",
      "Demonyms": "Djiboutians"
    },
    {
      "Country": "Dominica",
      "Demonyms": "Dominicans[d]"
    },
    {
      "Country": "Dominican Republic",
      "Demonyms": "Dominicans[e]"
    },
    {
      "Country": "East Timor[f]",
      "Demonyms": "Timorese"
    },
    {
      "Country": "Ecuador",
      "Demonyms": "Ecuadorians"
    },
    {
      "Country": "Egypt",
      "Demonyms": "Egyptians"
    },
    {
      "Country": "El Salvador",
      "Demonyms": "SalvadoransSalvadoriansSalvadoreans"
    },
    {
      "Country": "England",
      "Demonyms": "EnglishEnglishmen and Englishwomen"
    },
    {
      "Country": "Equatorial Guinea",
      "Demonyms": "Equatorial GuineansEquatoguineans"
    },
    {
      "Country": "Eritrea",
      "Demonyms": "Eritreans"
    },
    {
      "Country": "Estonia",
      "Demonyms": "Estonians"
    },
    {
      "Country": "Eswatini[g]",
      "Demonyms": "Swazis"
    },
    {
      "Country": "Ethiopia",
      "Demonyms": "EthiopiansHabesha"
    },
    {
      "Country": "European Union[h]",
      "Demonyms": "Europeans[i]"
    },
    {
      "Country": "Falkland Islands",
      "Demonyms": "Falkland Islanders"
    },
    {
      "Country": "Faroe Islands",
      "Demonyms": "Faroese"
    },
    {
      "Country": "Fiji",
      "Demonyms": "Fijians"
    },
    {
      "Country": "Finland",
      "Demonyms": "Finns"
    },
    {
      "Country": "France",
      "Demonyms": "FrenchFrenchmen and Frenchwomen"
    },
    {
      "Country": "French Guiana",
      "Demonyms": "French Guianese"
    },
    {
      "Country": "French Polynesia",
      "Demonyms": "French Polynesians"
    },
    {
      "Country": "French Southern Territories",
      "Demonyms": "French"
    },
    {
      "Country": "Gabon",
      "Demonyms": "GaboneseGabonaise"
    },
    {
      "Country": "The Gambia",
      "Demonyms": "Gambians"
    },
    {
      "Country": "Georgia",
      "Demonyms": "Georgians"
    },
    {
      "Country": "Germany",
      "Demonyms": "Germans"
    },
    {
      "Country": "Ghana",
      "Demonyms": "Ghanaians"
    },
    {
      "Country": "Gibraltar",
      "Demonyms": "Gibraltarians"
    },
    {
      "Country": "Greece",
      "Demonyms": "GreeksHellenes"
    },
    {
      "Country": "Greenland",
      "Demonyms": "Greenlanders"
    },
    {
      "Country": "Grenada",
      "Demonyms": "Grenadians"
    },
    {
      "Country": "Guadeloupe",
      "Demonyms": "GuadeloupiansGuadeloupeans"
    },
    {
      "Country": "Guam",
      "Demonyms": "Guamanians"
    },
    {
      "Country": "Guatemala",
      "Demonyms": "Guatemalans"
    },
    {
      "Country": "Guernsey",
      "Demonyms": "Guernseyman and Guernseywoman"
    },
    {
      "Country": "Guinea",
      "Demonyms": "Guineans"
    },
    {
      "Country": "Guinea-Bissau",
      "Demonyms": "Bissau-Guineans"
    },
    {
      "Country": "Guyana",
      "Demonyms": "Guyanese"
    },
    {
      "Country": "Haiti",
      "Demonyms": "Haitians"
    },
    {
      "Country": "Heard Island and McDonald Islands",
      "Demonyms": "Heard IslandersMcDonald Islanders"
    },
    {
      "Country": "Honduras",
      "Demonyms": "Hondurans"
    },
    {
      "Country": "Hong Kong",
      "Demonyms": "HongkongersHong Kongese"
    },
    {
      "Country": "Hungary",
      "Demonyms": "HungariansMagyars"
    },
    {
      "Country": "Iceland",
      "Demonyms": "Icelanders"
    },
    {
      "Country": "India",
      "Demonyms": "Indians"
    },
    {
      "Country": "Indonesia",
      "Demonyms": "Indonesians"
    },
    {
      "Country": "Iran",
      "Demonyms": "IraniansPersians"
    },
    {
      "Country": "Iraq",
      "Demonyms": "Iraqis"
    },
    {
      "Country": "Ireland[j]",
      "Demonyms": "IrishIrishmen and Irishwomen"
    },
    {
      "Country": "Isle of Man",
      "Demonyms": "Manx"
    },
    {
      "Country": "Israel",
      "Demonyms": "Israelis"
    },
    {
      "Country": "Italy",
      "Demonyms": "Italians"
    },
    {
      "Country": "Ivory Coast[k]",
      "Demonyms": "Ivorians"
    },
    {
      "Country": "Jamaica",
      "Demonyms": "Jamaicans"
    },
    {
      "Country": "Jan Mayen",
      "Demonyms": "Jan Mayen residents"
    },
    {
      "Country": "Japan",
      "Demonyms": "Japanese"
    },
    {
      "Country": "Jersey",
      "Demonyms": "Jerseyman and JerseywomanJersianJèrriais"
    },
    {
      "Country": "Jordan",
      "Demonyms": "Jordanians"
    },
    {
      "Country": "Kazakhstan",
      "Demonyms": "KazakhstanisKazakhs"
    },
    {
      "Country": "Kenya",
      "Demonyms": "Kenyans"
    },
    {
      "Country": "Kiribati",
      "Demonyms": "I-Kiribati"
    },
    {
      "Country": "North Korea",
      "Demonyms": "KoreansNorth Koreans"
    },
    {
      "Country": "South Korea",
      "Demonyms": "KoreansSouth Koreans"
    },
    {
      "Country": "Kosovo",
      "Demonyms": "Kosovars"
    },
    {
      "Country": "Kuwait",
      "Demonyms": "Kuwaitis"
    },
    {
      "Country": "Kyrgyzstan",
      "Demonyms": "KyrgyzstanisKyrgyzKirgizKirghiz"
    },
    {
      "Country": "Laos",
      "Demonyms": "LaosLaotians"
    },
    {
      "Country": "Latvia",
      "Demonyms": "LatviansLetts"
    },
    {
      "Country": "Lebanon",
      "Demonyms": "Lebanese"
    },
    {
      "Country": "Lesotho",
      "Demonyms": "Basotho (singular Mosotho)"
    },
    {
      "Country": "Liberia",
      "Demonyms": "Liberians"
    },
    {
      "Country": "Libya",
      "Demonyms": "Libyans"
    },
    {
      "Country": "Liechtenstein",
      "Demonyms": "Liechtensteiners"
    },
    {
      "Country": "Lithuania",
      "Demonyms": "Lithuanians"
    },
    {
      "Country": "Luxembourg",
      "Demonyms": "Luxembourgers"
    },
    {
      "Country": "Macau",
      "Demonyms": "MacaneseChinese"
    },
    {
      "Country": "Madagascar",
      "Demonyms": "Malagasy"
    },
    {
      "Country": "Malawi",
      "Demonyms": "Malawians"
    },
    {
      "Country": "Malaysia",
      "Demonyms": "Malaysians"
    },
    {
      "Country": "Maldives",
      "Demonyms": "Maldivians"
    },
    {
      "Country": "Mali",
      "Demonyms": "Malians"
    },
    {
      "Country": "Malta",
      "Demonyms": "Maltese"
    },
    {
      "Country": "Marshall Islands",
      "Demonyms": "Marshallese"
    },
    {
      "Country": "Martinique",
      "Demonyms": "MartiniquaisMartiniquaises"
    },
    {
      "Country": "Mauritania",
      "Demonyms": "Mauritanians"
    },
    {
      "Country": "Mauritius",
      "Demonyms": "Mauritians"
    },
    {
      "Country": "Mayotte",
      "Demonyms": "Mahorans"
    },
    {
      "Country": "Mexico",
      "Demonyms": "Mexicans"
    },
    {
      "Country": "Micronesia",
      "Demonyms": "Micronesians"
    },
    {
      "Country": "Moldova",
      "Demonyms": "Moldovans"
    },
    {
      "Country": "Monaco",
      "Demonyms": "MonégasquesMonacans"
    },
    {
      "Country": "Mongolia",
      "Demonyms": "MongoliansMongols"
    },
    {
      "Country": "Montenegro",
      "Demonyms": "Montenegrins"
    },
    {
      "Country": "Montserrat",
      "Demonyms": "Montserratians"
    },
    {
      "Country": "Morocco",
      "Demonyms": "Moroccans"
    },
    {
      "Country": "Mozambique",
      "Demonyms": "Mozambicans"
    },
    {
      "Country": "Myanmar[l]",
      "Demonyms": "Myanmars"
    },
    {
      "Country": "Namibia",
      "Demonyms": "Namibians"
    },
    {
      "Country": "Nauru",
      "Demonyms": "Nauruans"
    },
    {
      "Country": "Nepal",
      "Demonyms": "NepalisNepalese"
    },
    {
      "Country": "Netherlands",
      "Demonyms": "DutchDutchmen and DutchwomenNetherlanders"
    },
    {
      "Country": "New Caledonia",
      "Demonyms": "New Caledonians"
    },
    {
      "Country": "New Zealand",
      "Demonyms": "New Zealanders"
    },
    {
      "Country": "Nicaragua",
      "Demonyms": "Nicaraguans"
    },
    {
      "Country": "Niger",
      "Demonyms": "Nigeriens"
    },
    {
      "Country": "Nigeria",
      "Demonyms": "Nigerians"
    },
    {
      "Country": "Niue",
      "Demonyms": "Niueans"
    },
    {
      "Country": "Norfolk Island",
      "Demonyms": "Norfolk Islanders"
    },
    {
      "Country": "North Macedonia",
      "Demonyms": "Macedonians"
    },
    {
      "Country": "Northern Ireland",
      "Demonyms": "Northern IrishNorthern Irishmen and Northern Irishwomen"
    },
    {
      "Country": "Northern Mariana Islands",
      "Demonyms": "Northern Marianans"
    },
    {
      "Country": "Norway",
      "Demonyms": "Norwegians"
    },
    {
      "Country": "Oman",
      "Demonyms": "Omanis"
    },
    {
      "Country": "Pakistan",
      "Demonyms": "Pakistanis"
    },
    {
      "Country": "Palau",
      "Demonyms": "Palauans"
    },
    {
      "Country": "Palestine",
      "Demonyms": "Palestinians"
    },
    {
      "Country": "Panama",
      "Demonyms": "Panamanians"
    },
    {
      "Country": "Papua New Guinea",
      "Demonyms": "Papua New GuineansPapuans"
    },
    {
      "Country": "Paraguay",
      "Demonyms": "Paraguayans"
    },
    {
      "Country": "Peru",
      "Demonyms": "Peruvians"
    },
    {
      "Country": "Philippines",
      "Demonyms": "FilipinosFilipinas"
    },
    {
      "Country": "Pitcairn Islands",
      "Demonyms": "Pitcairn Islanders"
    },
    {
      "Country": "Poland",
      "Demonyms": "Poles"
    },
    {
      "Country": "Portugal",
      "Demonyms": "Portuguese"
    },
    {
      "Country": "Puerto Rico",
      "Demonyms": "Puerto Ricans"
    },
    {
      "Country": "Qatar",
      "Demonyms": "Qataris"
    },
    {
      "Country": "Réunion",
      "Demonyms": "RéunioneseRéunionnais and Réunionnaises"
    },
    {
      "Country": "Romania",
      "Demonyms": "Romanians"
    },
    {
      "Country": "Russia[m]",
      "Demonyms": "Russians"
    },
    {
      "Country": "Rwanda",
      "Demonyms": "RwandansBanyarwanda"
    },
    {
      "Country": "Saba",
      "Demonyms": "Saba Dutch"
    },
    {
      "Country": "Saint Barthélemy",
      "Demonyms": "BarthélemoisBarthélemoises"
    },
    {
      "Country": "Saint Helena, Ascension and Tristan da Cunha",
      "Demonyms": "Saint Helenians"
    },
    {
      "Country": "Saint Kitts and Nevis",
      "Demonyms": "KittitiansNevisians"
    },
    {
      "Country": "Saint Lucia",
      "Demonyms": "Saint Lucians"
    },
    {
      "Country": "Saint Martin",
      "Demonyms": "Saint-MartinoisSaint-Martinoises"
    },
    {
      "Country": "Saint Pierre and Miquelon",
      "Demonyms": "Saint-PierraisSaint-PierraisesMiquelonnaisMiquelonnaises"
    },
    {
      "Country": "Saint Vincent and the Grenadines",
      "Demonyms": "Saint VincentiansVincentians"
    },
    {
      "Country": "Sahrawi",
      "Demonyms": "SahrawisWestern Saharans"
    },
    {
      "Country": "Samoa",
      "Demonyms": "Samoans"
    },
    {
      "Country": "San Marino",
      "Demonyms": "Sammarinese"
    },
    {
      "Country": "São Tomé and Príncipe",
      "Demonyms": "São Toméans"
    },
    {
      "Country": "Saudi Arabia",
      "Demonyms": "SaudisSaudi Arabians"
    },
    {
      "Country": "Scotland",
      "Demonyms": "ScotsScotsmen and Scotswomen"
    },
    {
      "Country": "Senegal",
      "Demonyms": "Senegalese"
    },
    {
      "Country": "Serbia",
      "Demonyms": "SerbsSerbians"
    },
    {
      "Country": "Seychelles",
      "Demonyms": "Seychellois/Seychelloises"
    },
    {
      "Country": "Sierra Leone",
      "Demonyms": "Sierra Leoneans"
    },
    {
      "Country": "Singapore",
      "Demonyms": "Singaporeans[4][5]"
    },
    {
      "Country": "Sint Eustatius",
      "Demonyms": "Statians"
    },
    {
      "Country": "Sint Maarten",
      "Demonyms": "Sint Maarteners"
    },
    {
      "Country": "Slovakia",
      "Demonyms": "SlovaksSlovakians"
    },
    {
      "Country": "Slovenia",
      "Demonyms": "SlovenesSlovenians"
    },
    {
      "Country": "Solomon Islands",
      "Demonyms": "Solomon Islanders"
    },
    {
      "Country": "Somalia",
      "Demonyms": "Somali[6]Somalians[7]"
    },
    {
      "Country": "Somaliland",
      "Demonyms": "Somalilanders"
    },
    {
      "Country": "South Africa",
      "Demonyms": "South Africans"
    },
    {
      "Country": "South Georgia and the South Sandwich Islands",
      "Demonyms": "South Georgia IslandersSouth Sandwich Islanders"
    },
    {
      "Country": "South Ossetia",
      "Demonyms": "South Ossetians"
    },
    {
      "Country": "South Sudan",
      "Demonyms": "South Sudanese"
    },
    {
      "Country": "Spain",
      "Demonyms": "Spaniards"
    },
    {
      "Country": "Sri Lanka",
      "Demonyms": "Sri Lankans"
    },
    {
      "Country": "Sudan",
      "Demonyms": "Sudanese"
    },
    {
      "Country": "Suriname",
      "Demonyms": "Surinamers"
    },
    {
      "Country": "Svalbard",
      "Demonyms": "Svalbard residents"
    },
    {
      "Country": "Sweden",
      "Demonyms": "Swedes"
    },
    {
      "Country": "Switzerland",
      "Demonyms": "Swiss"
    },
    {
      "Country": "Syria",
      "Demonyms": "Syrians"
    },
    {
      "Country": "Taiwan",
      "Demonyms": "TaiwaneseFormosans"
    },
    {
      "Country": "Tajikistan",
      "Demonyms": "TajikistanisTajiks"
    },
    {
      "Country": "Tanzania",
      "Demonyms": "Tanzanians"
    },
    {
      "Country": "Thailand",
      "Demonyms": "Thai"
    },
    {
      "Country": "Timor-Leste[n]",
      "Demonyms": "Timorese"
    },
    {
      "Country": "Togo",
      "Demonyms": "Togolese"
    },
    {
      "Country": "Tokelau",
      "Demonyms": "Tokelauans"
    },
    {
      "Country": "Tonga",
      "Demonyms": "Tongans"
    },
    {
      "Country": "Trinidad and Tobago",
      "Demonyms": "TrinidadiansTobagonians"
    },
    {
      "Country": "Tunisia",
      "Demonyms": "Tunisians"
    },
    {
      "Country": "Turkey",
      "Demonyms": "Turks"
    },
    {
      "Country": "Turkmenistan",
      "Demonyms": "Turkmens"
    },
    {
      "Country": "Turks and Caicos Islands",
      "Demonyms": "Turks and Caicos Islanders"
    },
    {
      "Country": "Tuvalu",
      "Demonyms": "Tuvaluans"
    },
    {
      "Country": "Uganda",
      "Demonyms": "Ugandans"
    },
    {
      "Country": "Ukraine",
      "Demonyms": "Ukrainians"
    },
    {
      "Country": "United Arab Emirates",
      "Demonyms": "EmiratisEmiriansEmiri"
    },
    {
      "Country": "United Kingdom of Great Britain and Northern Ireland",
      "Demonyms": "BritonsBritish people"
    },
    {
      "Country": "United States of America",
      "Demonyms": "Americans[o]"
    },
    {
      "Country": "Uruguay",
      "Demonyms": "Uruguayans"
    },
    {
      "Country": "Uzbekistan",
      "Demonyms": "UzbekistanisUzbeks"
    },
    {
      "Country": "Vanuatu",
      "Demonyms": "Ni-Vanuatu"
    },
    {
      "Country": "Vatican City",
      "Demonyms": "Vatican citizens"
    },
    {
      "Country": "Venezuela",
      "Demonyms": "Venezuelans"
    },
    {
      "Country": "Vietnam",
      "Demonyms": "Vietnamese"
    },
    {
      "Country": "British Virgin Islands",
      "Demonyms": "British Virgin Islanders"
    },
    {
      "Country": "United States Virgin Islands",
      "Demonyms": "U.S. Virgin Islanders"
    },
    {
      "Country": "Wales",
      "Demonyms": "WelshmenWelshwomen"
    },
    {
      "Country": "Wallis and Futuna",
      "Demonyms": "Wallis and Futuna IslandersWallisiansFutunans"
    },
    {
      "Country": "Western Sahara",
      "Demonyms": "SahrawisSahraouis"
    },
    {
      "Country": "Yemen",
      "Demonyms": "Yemenis"
    },
    {
      "Country": "Zambia",
      "Demonyms": "Zambians"
    },
    {
      "Country": "Zanzibar",
      "Demonyms": "Zanzibaris"
    },
    {
      "Country": "Zimbabwe",
      "Demonyms": "Zimbabweans"
    }
   ]