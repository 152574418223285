import React, { useState } from 'react';
import { Div, H6, Text, Divider, TextInput, Icons } from 'qdm-component-library';
import { makeStyles, Popover } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { CalendarIcon, StatusCard } from '../';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { UIColor } from "../../utils";

const statusTypeColor = {
    "active": UIColor.success.color,
    "completed": UIColor.success.color,
    "on hold": UIColor.secondaryColor.color,
    "cancel": UIColor.error.color,
}
const useStyles = makeStyles({
    dateWrapper: {
        border: `1px solid ${UIColor.lineBorderFill.color}`,
        borderRadius: 8,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        minHeight: 36,
        '&:hover': {
            borderColor: UIColor.primaryColor.color,
        }
    },
});

const statusColors = {
    Hold: { color: "#5B12BB", background: "#E3D7F3" },
    Draft: { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
    //Active: { color: UIColor.primaryColor.color, background: "#D4E7FD" },
    Active: { color: UIColor.success.color, background: "#fff" },
    Complete: { color: UIColor.success.color, background: "hsl(105, 62%, 90%)" },
    Cancel: { color: UIColor.erro, background: "#FFE1E0" },
    Discontinue: { color: "#d60000", background: UIColor.lineBorderFill.color },
    Continue: { color: UIColor.primaryColor.color, background: UIColor.primaryColor.color },
    "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
    // "Cancel": { color: UIColor.erro, background: "#FFE1E0" }
};

export const ChangeStatus = (props) => {
    const classes = useStyles(props);

    const styles = {
        lastSection: {
            display: "flex",
            marginTop: "4px",
        },
        lastSectionText: {
            fontSize: "12px",
            color: UIColor.secondaryText.color,
            marginLeft: "8px",
            paddingRight: "32px",
        },
        headerText: {
            color: UIColor.primaryColor.color,
            fontSize: 12,
            flex: 1,
            margin: 0
        },
        historySectionText: {
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
        },
        header: {
            padding: 10
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        saveText: {
            color: '#0071F2',
            fontSize: 12,
            cursor: 'pointer'
        },
        cancelText: {
            color: UIColor.secondaryText.color,
            fontSize: 12,
            marginRight: 10,
            cursor: 'pointer'
        },
        divider: {
            margin: 0
        }, iconSize: {
            width: 15,
            height: 15,
            cursor: "pointer",
        },
        title: {
            color: '#646464',
            fontSize: 12,
            margin: 0,
            marginBottom: 5
        },
        dateText: {
            fontSize: 12,
            color: '#323232',
            flex: 1
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color,
            height: 36,
            padding: '5px 10px'
        },
        column: {
            marginBottom: 10
        },
        collapse: {
            backgroundColor: '#EFEFEF',
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        statusHis: {
            fontSize: 12,
            color: UIColor.primaryColor.color,
            flex: 1
        },
        expand: {
            padding: 10
        },
        flexCenterBetween: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        span: {
            fontSize: 10,
            color: '#323232'
        },
        forwardIcon: {
            fontSize: "14px",
            color: UIColor.secondaryText.color,
        },
    };

    const [state, setState] = useState({
        status: props?.changeStatusData?.status ?? props?.changeStatusData ?? {},
        date: props?.changeStatusData?.date,
        reason: props?.changeStatusData?.reason,

        // status: props?.changeStatusData?.status ?? {},
        // date: props?.changeStatusData?.date ?? '',
        // reason: props?.changeStatusData?.reason ?? '',
    })

    const onChangeState = async (key, value) => {
        setState({ ...state, [key]: value })
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [showHistory, setShowHistory] = useState(false)

    const cancel = () => {
        props?.cancelButtonAction && props.cancelButtonAction();
    }

    const save = () => {
        props?.saveButtonAction && props.saveButtonAction(state)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const backStatus = (type) => {
        switch (type) {
            case 'Active':
                return UIColor.success.color
            case 'On Hold':
                return UIColor.secondaryColor.color
            case 'Cancel':
                return UIColor.error.color
            case 'Completed':
                return UIColor.success.color

        }
    }
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_chnagestatus_parent_div`}
        >
            <Div
                id={`${parent_id}_chnagestatus_parent_sub_div`}
                style={styles.header}>
                <Div
                    id={`${parent_id}_chnagestatus_title_div`}
                    style={styles.flexCenter}>
                    <H6
                        id={`${parent_id}_chnagestatus_${props?.title}_h6`}
                        className="pc_semibold" style={styles.headerText}>{props?.title}</H6>
                    <Div
                        id={`${parent_id}_chnagestatus_button_div`}
                        style={styles.flexCenter}>
                        {props?.cancelButton && <Text
                            id={`${parent_id}_chnagestatus_${props?.cancelButton}_text`}
                            className="pc_medium" onClick={() => cancel()} style={styles.cancelText}>{props?.cancelButton}</Text>}
                        {props?.saveButton && <Text
                            id={`${parent_id}_chnagestatus_${props?.saveButton}_text`}
                            className="pc_medium" onClick={save} style={styles.saveText}>{props?.saveButton}</Text>}
                    </Div>
                </Div>
            </Div>
            <Divider
                id={`${parent_id}_chnagestatus_bottom_divider`}
                style={styles.divider} />
            <Div
                id={`${parent_id}_chnagestatus_Change_to_div`}
                style={{ padding: 10 }}>
                <Div
                    id={`${parent_id}_chnagestatus_Change_to_sub_div`}
                    style={styles.column}>
                    <H6
                        id={`${parent_id}_chnagestatus_Change_to_h6`}
                        className={"pc_regular"} style={styles.title}>Change to</H6>
                    <Div
                        id={`${parent_id}_chnagestatus_card_div`}
                        style={styles.flexCenter}>
                        {
                            props?.statusOption && props?.statusOption?.map((_, i) => {
                                return (
                                    <StatusCard
                                        isSignOff={(!!props?.isSignOff)}
                                        parent_id={'change_status' + i}
                                        backgroundColor={_?.backgroundColor}
                                        color={statusColors?.[_?.label]}
                                        value={_}
                                        activebackgroundColor={backStatus(state?.status?.label)}
                                        activeColor='#fff'
                                        activeStatus={state?.status}
                                        changeStatus={(data) => onChangeState('status', data)}
                                    />
                                )
                            })
                        }
                    </Div>
                </Div>
                {(state?.status?.label) === 'On Hold' && <Div
                    id={`${parent_id}_chnagestatus_Till_Date-Time_div`}
                    style={styles.column}>
                    <H6
                        id={`${parent_id}_chnagestatus_Till_Date-Time_h6`}
                        className={"pc_regular"} style={styles.title}>Till Date & Time</H6>
                    <Div
                        id={`${parent_id}_chnagestatus_calender_div`}
                        className={classes.dateWrapper}>
                        <Text
                            id={`${parent_id}_chnagestatus_calender_date_text`}
                            className="pc_regular" style={styles.dateText}>{state?.date ? state?.date : ' '}</Text>
                        <Text
                            id={`${parent_id}_chnagestatus_calender_icon_text`}
                            style={{ cursor: 'pointer', display: 'flex' }} aria-describedby={id} onClick={(event) => handleClick(event?.currentTarget)}><CalendarIcon
                                id={`${parent_id}_chnagestatus_calender_icon`}
                                color={"#B6B6B6"} width={"16"} height={"16"} /></Text>
                    </Div>
                </Div>}
                <Div
                    id={`${parent_id}_chnagestatus_reason_div`}
                    style={styles.column}>
                    <H6
                        id={`${parent_id}_chnagestatus_reason_h6`}
                        className={"pc_regular"} style={styles.title}>Reason <span style={{ color: "red" }}>*</span></H6>
                    <TextInput
                        id={`${parent_id}_chnagestatus_reason_textinput`}
                        value={state?.reason}
                        onChange={(evt) => onChangeState('reason', evt.target.value)}
                        variant={"outlined"}
                        hoverColor={UIColor.primaryColor.color}
                        placeholder="Add reason"
                        style={styles.borderStyle}
                        isRequired
                    />
                </Div>
            </Div>
            <Div
                id={`${parent_id}_chnagestatus_Status_History_div`}
                style={styles.column}>
                <Div
                    id={`${parent_id}_chnagestatus_StatusHistory_sub_div`}
                    style={styles.collapse} onClick={() => setShowHistory(!showHistory)}>
                    <Text
                        id={`${parent_id}_chnagestatus_StatusHistory_text`}
                        className="pc_medium" style={styles.statusHis}>Status History</Text>
                    <Icons
                        id={`${parent_id}_chnagestatus_chevrony_up-down_Icons`}
                        fontIcon={showHistory ? "chevron-up" : "chevron-down"}
                        style={styles.iconSize}
                    />
                </Div>
                {showHistory ? (
                    <>{(props.statushistory?.length > 0) && props.statushistory.map((val, i) => {
                        const personId = typeof val?.PersonID === 'string' ? val?.PersonID : (val?.PersonID?.[0]?._id || "")
                        return (
                            <div
                                id={`${parent_id}_chnagestatus_${i}_person_div`}
                                style={{ padding: "6px 16px 0px" }}>
                                <div
                                    id={`${parent_id}_chnagestatus_${i}_person_sub_div`}
                                    style={{ display: "flex" }}>
                                    <Text
                                        id={`${parent_id}_chnagestatus_${i}_${personId?.split("/")[0] ?? " ".replaceAll(" ", "_")}_text`}
                                        style={{
                                            paddingRight: "35px",
                                            fontWeight: 600,
                                            fontSize: "14px",
                                        }}
                                    >
                                        {personId?.split("/")[0] ?? " "}
                                    </Text>
                                    <div
                                        id={`${parent_id}_chnagestatus_${i}_personid_div`}
                                        style={{ flexGrow: 1 }}></div>
                                    <Text
                                        id={`${parent_id}_chnagestatus_${i}_${personId?.split("/")[1] ?? " ".replaceAll(" ", "_")}_text`}
                                        inLineStyles={styles.historySectionText}>
                                        {personId?.split("/")[1] ?? " "}</Text>
                                </div>

                                <div
                                    id={`${parent_id}_chnagestatus_${i}_LowerCase_div`}
                                    style={styles.lastSection}>
                                    <Text
                                        id={`${parent_id}_chnagestatus_${i}_Active_text`}
                                        inLineStyles={{
                                            color: statusTypeColor[val?.old_status?.toLocaleLowerCase()] ?? UIColor.secondaryColor.color,
                                            ...styles.historySectionText,
                                        }}
                                    >
                                        {val?.old_status?.length > 0 ? val?.old_status : <span
                                            id={`${parent_id}_chnagestatus_${i}_Active_span`}
                                            style={{ color: statusTypeColor['active'] ?? UIColor.secondaryColor.color }}>Active</span>}
                                    </Text>
                                    {val?.new_status !== val?.old_status && <>
                                        <ArrowForwardIcon
                                            id={`${parent_id}_chnagestatus_${i}_ArrowForwardIcon`}
                                            style={styles.forwardIcon} />
                                        <Text
                                            id={`${parent_id}_chnagestatus_${i}_${val?.new_status}_text`}
                                            inLineStyles={{
                                                color: statusTypeColor[val?.new_status?.toLocaleLowerCase()] ?? UIColor.secondaryColor.color,
                                                ...styles.historySectionText,
                                            }}
                                        >
                                            {val?.new_status}
                                        </Text></>
                                    }
                                    <div
                                        id={`${parent_id}_chnagestatus_${i}_toperiod_div`}
                                        style={{ flexGrow: 1 }}></div>
                                    <Text
                                        id={`${parent_id}_chnagestatus_${i}_toperiod_text`}
                                        inLineStyles={{
                                            color: "#323232",
                                            fontWeight: 500,
                                            ...styles.historySectionText,
                                        }}
                                    >
                                        {val.toperiod ?
                                            moment.unix(val.toperiod).format("DD MMM h:mm a") : new Date()}
                                    </Text>
                                </div>
                                <Divider
                                    id={`${parent_id}_chnagestatus_${i}_divider`}
                                    style={{ margin: "14px 0px" }}></Divider>
                            </div>
                        )
                    })}</>
                ) : null}
            </Div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.muiPopovers }}
            >
                <DatePicker
                    id={`${parent_id}_chnagestatus_DatePicker`}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    // selected={this.state.dob.value}
                    selected={new Date()}
                    // showPopperArrow={false}
                    // showYearDropdown
                    inline
                    onChange={date => {
                        onChangeState('date', moment(date).format('DD-MM-YYYY,hh:mm A'));
                        handleClose()
                    }}
                // placeholderText="DD-MM-YYYY"
                // maxDate={new Date()}
                // className={"pc_regular"}
                />
            </Popover>
        </div >
    )
}

ChangeStatus.defaultProps = {
    title: 'Change Status',
    statusOption: [],
    changeStatusData: {},
    statusHistory: {},
    cancelButtonAction: null,
    saveButtonAction: null,
    saveButton: '',
    cancelButton: '',
    isSignOff: false,
}