import React, { useState } from "react";
import { Div, Divider, Text, H6, Dropdown } from "qdm-component-library";
import expandarrow from "../../assets/img/expandarrow.svg";
import { CalendarIcon } from "../";
import { TextField, makeStyles, Popover } from "@material-ui/core";
import { styles } from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { SelectBox } from "../common";
import { UIColor } from "../../utils";

const useStyles = makeStyles({
  textField: {
    fontSize: 12,
    fontFamily: "pc_medium",
    padding: 0,
    border: "none",
    outline: "none",
  },
  everyTextField: {
    width: 20,
    border: "none",
    borderBottom: "1px solid #989898",
    outline: "none",
    fontSize: 14,
    fontFamily: "pc_regular",
  },
});

export const Recurrent = (props) => {
  const classes = useStyles(props);

  const [state, setState] = useState({
    endsAfter: 0,
    anchorEl: null,
    index: null,
  });

  const [recurrentData, setRecurrentData] = useState(
    props?.recurrentData ?? {}
  );

  const handleRecurrentData = (parentKey, key, value) => {
    const newRecurrentData = Object.keys(recurrentData).length > 0 ? {...recurrentData} : {};
    newRecurrentData[parentKey][key] = value;
    if (key === "endsBy") {
      newRecurrentData[parentKey]["endsAfter"] = "";
    }
    if (key === "endsAfter") {
      newRecurrentData[parentKey]["endsBy"] = "";
    }
    setRecurrentData(newRecurrentData);
    setState({
      ...state,
      anchorEl: null,
      index: null,
    });
  };

  const handleClick = (index, event) => {
    setState({ ...state, index, anchorEl: event.currentTarget });
  };

  const saveRecurrentData = () => {
    props?.saveButtonAction && props.saveButtonAction(recurrentData);
  };

  const onChangeState = (key, value) => {
    if (value >= 0) {
      setState({ ...state, [key]: value, anchorEl: null });
    } else {
      setState({ ...state, anchorEl: null });
    }
  };
  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div
      id={`${parent_id}_recurrent_parent_div`}
      style={{ width: props?.width ?? "100%" }}>
      <Div
        id={`${parent_id}_recurrent_parent_sub_div`}
        style={styles.flexCenterJustify}>
        {props?.title && (
          <H6
            id={`${parent_id}_recurrent_${props?.title?.replaceAll(" ", "_")}_title_h6`}
            className="pc_semibold" style={styles.title}>
            {props?.title}
          </H6>
        )}
        <Div
          id={`${parent_id}_recurrent_title_div`}
        >
          {props?.deleteButton && (
            <Text
              id={`${parent_id}_recurrent_Delete_text`}
              style={styles.deleteButton}
              onClick={() => props?.deleteButtonAction()}
              className="pc_medium"
            >
              Delete
            </Text>
          )}
          {props?.saveButton && (
            <Text
              id={`${parent_id}_recurrent_Save_text`}
              className="pc_medium"
              onClick={() => saveRecurrentData()}
              style={styles.save}
            >
              Save
            </Text>
          )}
        </Div>
      </Div>
      <Div
        id={`${parent_id}_recurrent_date_format_div`}
        style={{ ...styles.flexRow, marginBottom: 15 }}>
        <Text
          id={`${parent_id}_recurrent_Every_text`}
          style={{ ...styles.span, marginRight: 10 }}
          className="pc_regular"
        >
          Every<Text
            id={`${parent_id}_recurrent_Every_star_text`}
            style={styles.star}>*</Text>
        </Text>
        <input
          id={`${parent_id}_recurrent_Every_input`}
          value={recurrentData?.every?.value}
          onChange={(e) =>
            handleRecurrentData("every", "value", e.target.value)
          }
          maxLength={2}
          type="text"
          style={{ width: 20, color: UIColor.secondaryText.color }}
          className={classes.everyTextField}
        />
        <Div
          id={`${parent_id}_recurrent_dropdwon_div`}
          style={{ minWidth: 100, marginLeft: 10 }}>
          {/* <Dropdown
            id={`${parent_id}_recurrent_calender_format_dropdwon`}
            key={"0"}
            elevation={"1"}
            fullWidth={true}
            dropDownTextSize={"14"}
            dropDownTextColor={"#000"}
            activeTextColor={"#000"}
            dropdownListMargin={"0"}
            dropdownListPadding={"8"}
            value={recurrentData?.every?.periodicity}
            onChange={(data) =>
              handleRecurrentData("every", "periodicity", data)
            }
            options={[
              { label: "Days", value: "Days" },
              { label: "Week", value: "Week" },
              { label: "Month", value: "Month" },
            ]}
            inLineStyles={{
              fontSize: "14px",
              fontWeight: 500,
            }}
            className={"pc_regular"}
          /> */}
          <SelectBox
            id={`${parent_id}_recurrent_calender_format_dropdwon`}
            disableClearable
            list={[
              { label: "Days", value: "Days" },
              { label: "Week", value: "Week" },
              { label: "Month", value: "Month" },
            ]}
            value={recurrentData?.every?.periodicity}
            onchange={(e, value) => handleRecurrentData("every", "periodicity", value)}
          />
        </Div>
      </Div>
      <Div
        id={`${parent_id}_recurrent_Starts_on_div`}
        style={styles.flexRow}>
        <Div
          id={`${parent_id}_recurrent_Starts_on_sub_div`}
          style={styles.flexRow}>
          <Text
            id={`${parent_id}_recurrent_Starts_on_text`}
            style={{ ...styles.span, marginRight: 10, minWidth: 60 }}
            className="pc_regular"
          >
            Starts on<Text
              id={`${parent_id}_recurrent_Starts_star_text`}
              style={styles.star}>*</Text>
          </Text>
          <Div
            id={`${parent_id}_recurrent_dateTime_div`}
            style={styles.startsOn}>
            <H6
              id={`${parent_id}_recurrent_dateTime_h6`}
              className="pc_medium"
              style={{ ...styles.span, minWidth: 100 }}
              name={false}
            >
              {moment(recurrentData?.startsOn?.dateTime).format("YYYY-MM-DD, hh:mm A")}
            </H6>
            <Text
              id={`${parent_id}_recurrent_dateTime_h6`}
              style={{ color: UIColor.secondaryText.color, margin: "0px 10px" }}>|</Text>
            <Text
              id={`${parent_id}_recurrent_calender_text`}
              style={{ cursor: "pointer", display: "flex" }}
              onClick={(e) => handleClick(0, e)}
              aria-describedby={id}
            >
              <CalendarIcon
                id={`${parent_id}_recurrent_calender_icon`}
                color={UIColor.primaryText.color} width={"16"} height={"16"} />
            </Text>
          </Div>
        </Div>
        <Div
          id={`${parent_id}_recurrent_Ends_after_div`}
          style={{ ...styles.flexRow, marginLeft: 10 }}>
          <Text
            id={`${parent_id}_recurrent_Ends_after_text`}
            style={{
              ...styles.span,
              marginRight: 10,
              minWidth: 90,
              cursor: "pointer",
            }}
            aria-describedby={id}
            onClick={(e) => handleClick(1, e)}
            className="pc_regular"
          >
            Ends after<Text
              id={`${parent_id}_recurrent_Ends_after_star_text`}
              style={styles.star}>*</Text>
            <img
              id={`${parent_id}_recurrent_Ends_after_image`}
              alt="img" src={expandarrow} style={{ marginLeft: 10 }} />
          </Text>
          {state.endsAfter === 0 && (
            <Div
              id={`${parent_id}_recurrent_occurence_div`}
              style={styles.occurence}>
              <input
                id={`${parent_id}_recurrent_occurence_input`}
                type="text"
                maxlength="2"
                value={recurrentData?.endsAfter?.endsAfter}
                onChange={(e) =>
                  handleRecurrentData("endsAfter", "endsAfter", e.target.value)
                }
                style={{ width: 20 }}
                className={classes.textField}
              />
              <Text
                id={`${parent_id}_recurrent_occurence_text`}
                style={styles.divider}>| occurence</Text>
            </Div>
          )}
          {state.endsAfter === 1 && (
            <Div
              id={`${parent_id}_recurrent_recurrentData_div`}
              style={styles.occurence}>
              <Text
                id={`${parent_id}_recurrent_${recurrentData?.endsAfter?.endsBy}`}
                className="pc_medium" style={styles.span} name={false}>
                {moment(recurrentData?.endsAfter?.endsBy).format("YYYY-MM-DD")} 
              </Text>
              <Text style={{ ...styles.divider, margin: "0px 10px" }}>|</Text>
              <Text
                id={`${parent_id}_recurrent_calendericon_text`}
                style={{ cursor: "pointer", display: "flex" }}
                onClick={(e) => handleClick(2, e)}
                aria-describedby={id}
              >
                <CalendarIcon
                  id={`${parent_id}_recurrent_calendericon`}
                  color={UIColor.primaryText.color} width={"16"} height={"16"} />
              </Text>
            </Div>
          )}
        </Div>
      </Div>
      <Popover
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={() => onChangeState("anchorEl", null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {state.index === 0 && (
          <Div
            id={`${parent_id}_recurrent_dateformat_div`}
          >
            <DatePicker
              id={`${parent_id}_recurrent_dateformat_datepicker`}
              timeInputLabel="Time:"
              dateFormat="yyyy/MM/dd h:mm aa"
              showTimeInput
              // selected={this.state.dob.value}
              selected={new Date()}
              // showPopperArrow={false}
              // showYearDropdown
              inline
              minDate={new Date()}
              minTime={new Date()}
              onChange={(date) => {
                // handleRecurrentData('startsOn','dateTime',moment(date).format('DD-MM-YYYY,hh:mm A'))
                handleRecurrentData(
                  "startsOn",
                  "dateTime",
                  date
                );
              }}
            // placeholderText="DD-MM-YYYY"
            // maxDate={new Date()}
            // className={"pc_regular"}
            />
          </Div>
        )}
        {state.index === 1 && (
          <Div
            id={`${parent_id}_recurrent_Ends_after_div`}
            style={styles.endsAfter}>
            <Div
              id={`${parent_id}_recurrent_Ends_after_sub_div`}
              style={{ ...styles.flexCenterJustify, cursor: "pointer" }}
              onClick={() => onChangeState("endsAfter", 0)}
            >
              <Text
                id={`${parent_id}_recurrent_Ends_after_text`}
                style={{ ...styles.span, marginRight: 10 }}
                className="pc_regular"
              >
                Ends after
              </Text>
              <Div
                id={`${parent_id}_recurrent_number_div`}
                style={styles.popoverOccurence}>
                <Text
                  id={`${parent_id}_recurrent_number_text`}
                  style={{ fontSize: 12 }} className="pc_regular">
                  01
                </Text>
                <Text
                  id={`${parent_id}_recurrent_occurence_text`}
                  style={{ ...styles.divider, fontSize: 12 }}
                  className="pc_regular"
                >
                  &nbsp;|&nbsp;occurence
                </Text>
              </Div>
            </Div>
            <Divider
              id={`${parent_id}_recurrent_occurence_divider`}
              style={{ margin: "5px 0px" }} />
            <Div
              id={`${parent_id}_recurrent_Ends_by_div`}
              style={{ ...styles.flexCenterJustify, cursor: "pointer" }}
              onClick={() => onChangeState("endsAfter", 1)}
            >
              <Text
                id={`${parent_id}_recurrent_Ends_by_text`}
                style={{ ...styles.span, marginRight: 10 }}
                className="pc_regular"
              >
                Ends by
              </Text>
              <Div
                id={`${parent_id}_recurrent_date_format_div`}
                style={styles.popoverOccurence}>
                <Text
                  id={`${parent_id}_recurrent_date_format_text`}
                  style={{ fontSize: 12 }} className="pc_regular">
                  DD-MM-YY
                </Text>
                <Text
                  id={`${parent_id}_recurrent_date_format_sidedivider_text`}
                  style={{ ...styles.divider, margin: "0px 5px" }}>|</Text>
                <CalendarIcon
                  id={`${parent_id}_recurrent_calender_icon`}
                  color={UIColor.primaryText.color} width={"14"} height={"14"} />
              </Div>
            </Div>
          </Div>
        )}
        {state.index === 2 && (
          <Div
            id={`${parent_id}_recurrent_DatePicker_div`}
          >
            <DatePicker
              id={`${parent_id}_recurrent_DatePicker`}
              dateFormat="dd-MM-yyyy"
              // selected={this.state.dob.value}
              selected={new Date()}
              // showPopperArrow={false}
              // showYearDropdown
              inline
              minDate={new Date()}
              minTime={new Date()}
              onChange={(date) => {
                handleRecurrentData(
                  "endsAfter",
                  "endsBy",
                  date
                );
                onChangeState("anchorEl", null);
              }}
            // placeholderText="DD-MM-YYYY"
            // maxDate={new Date()}
            // className={"pc_regular"}
            />
          </Div>
        )}
      </Popover>
    </div>
  );
};

Recurrent.defaultProps = {
  title: "Recurrent",
  deleteButton: "Delete",
  deleteButtonAction: null,
  saveButton: "Save",
  saveButtonAction: null,
  recurrentData: {},
  width: "100%",
};
