import React from "react";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import { UIColor } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 3000,
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    "& .MuiFab-root": {
      // backgroundColor: theme.palette.primary.main,
      backgroundColor: UIColor.secondaryColor.color,
      color: theme.palette.common.white,
    },
  },
}));

const ActionFab = (props) => {
  const classes = useStyles();
  const { parent_id } = props;
  return (
    <div
      style={{ bottom: props.bottom && "88px" }}
      id={`${parent_id}_parent_div`}
      className={classes.root}
    >
      <Fab
        id={`${parent_id}_parent_fab`}
        aria-label="add"
        onClick={props.handleAction}
      >
        {props.icon}
      </Fab>
    </div>
  );
};

ActionFab.propTypes = {
  icon: PropTypes.any,
  handleAction: PropTypes.func,
};

export default ActionFab;
