const JSONArr = {
	result: [
		{
			title: "Your Body Temperature",
			shotName: "Temp",
			value: "100.0",
			unit: "F",
			normal: false,
		},
		{
			title: "Your Body Weight",
			shotName: "Weight",
			value: "70",
			unit: "KG",
			normal: true,
		},
		{
			title: "Your Body Height",
			shotName: "Height",
			value: "155",
			unit: "CM",
			normal: true,
		},
		{
			title: "Your Cholesterol Level",
			shotName: "Cholesterol",
			value: "200",
			unit: "Range",
			normal: true,
		},
		{
			title: "Your Head Circumference",
			shotName: "Head",
			value: "10",
			unit: "Inches",
			normal: false,
		},
		{
			title: "Your Blood Pressure",
			shotName: "Pressure",
			value: "",
			unit: "SYS/DIA",
			normal: true,
		},
		{
			title: "Your Respiratory Rate",
			shotName: "Respiratory",
			value: "60",
			unit: "MIN",
			normal: true,
		},
		{
			title: "Your Blood Sugar Level",
			shotName: "Sugar",
			value: "100.0",
			unit: "",
			normal: true,
		},
		{
			title: "Your Oxygen Saturation",
			shotName: "Oxygen",
			value: "99",
			unit: "%",
			normal: true,
		},
		{
			title: "Your Waist Circumference",
			shotName: "Waist",
			value: "32",
			unit: "Inches",
			normal: true,
		},
		{
			title: "Your Pulse Rate",
			shotName: "Pulse",
			value: "80",
			unit: "P/Min",
			normal: true,
		},
	],
};
export default JSONArr;
