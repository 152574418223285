import React from "react";
import { Button, Div } from "qdm-component-library";
import {
  TopNavbar,
  PatientListView,
  EncounterInfo,
  Pactitioner,
  DeviceDetails,
  RefNote,
  RefDetails,
  RefTo,
  RefFrom,
  RefLetter,
  BookingDetails,
  ReasonDetails,
  TopHeader,
  LoadingComp,
} from "../../../components";
import { patientVisit, patientVisitTitle } from "../../../utils/routesList";
import { Routes } from "../../../router/routes";
import { withQueue, withAllContexts } from "../../../HOCs";
import { withRouter } from "react-router-dom";
import {
  AlertProps,
  checkError,
  fetchQueueData,
  makeQueueData,
  putOnHold,
  getUserInfo,
  uploadImage,
  fetchUserInfo,
  getUtcTime,
} from "../../../utils";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from "../../../contexts";
import moment from "moment";
import { checkWithCasbin } from "../../../utils/permissonHandling";
class PatientVisitDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: 1,
      allMasters: [],
      Persondata: [],
      choosemember: [],
      appointmentData: [],
      loading: true,
      btnName: false,
      reasonID: 0,
    };
    this.successfun = this.successfun.bind(this);
    this.setActive = this.setActive.bind(this);
    this.fetchQueueData = fetchQueueData.bind(this);
    this.putOnHold = putOnHold.bind(this);
    this.fetchUserInfo = fetchUserInfo.bind(this);
  }
  handleSubmit = async (UploadedFiles) => {
    const visitData = this.props?.visitData;
    let { isBack, encounterDetails } = this.props.history.location.state;
    const patientStatus = await this.props.GET_ENCOUNTER_STATUS();
    let extraJson = {};

    if (isBack) {
      extraJson = {
        _id: encounterDetails[0].properties.doc._id,
        id: encounterDetails[0].properties.doc.id,
      };
    }

    let participantdata = [
      {
        type: ["CodingMaster/12338"],
        period: "",
        Practitioner_id: this.state.appointmentData?.resourcecode?.id ?? 0,
        PractitionerRole_id:
          this.state.appointmentData?.resourcecode?.practitioner_role?.[0]
            ?.id ?? 0,
        RelatedPerson_id: [
          {
            relatedpersonid: "",
            relationship: "",
          },
        ],
        specialty: "",
      },
    ];
    if (
      this.state.appointmentData?.resourcetype?.toLowerCase() !== "practitioner"
    ) {
      participantdata = [];
    }
    const data = [
      {
        doc: {
          ...extraJson,
          files: UploadedFiles || [
            {
              fileid: "",
              filetype: "",
              objectid: "",
              fileName: "",
            },
          ],
          // encounterdate: getUtcTime(),
          identifier: [
            {
              user: "",
              system: "",
              value: visitData?.accompained?.id ?? "",
              period: [
                {
                  start: getUtcTime(),
                  end: 0,
                  id: 0,
                },
              ],
              assigner: "",
              // Type: [
              //   {
              //     text: "",
              //     coding: [
              //       {
              //         system: "",
              //         version: "",
              //         code: "",
              //         display: visitData?.accompained?.name ?? "",
              //         userSelected: true,
              //         id: 0,
              //         Type: visitData?.accompained?.id_type?.value ?? "",
              //       },
              //     ],
              //     id: 0,
              //     Type: visitData?.accompained?.id_type?.value ?? "",
              //   },
              // ],
              Type: visitData?.accompained?.id_type?.id ?? "",
              id: 0,
              files: [
                {
                  fileid: "",
                  filename: "",
                  objectid: "",
                  filetype: "",
                },
              ],
            },
          ],
          status: patientStatus.payload.data[0],
          id: 0,
          relationship: visitData?.accompained?.relationship?.value ?? "",
          mode: visitData?.encounter?.mode ?? "",
          start_type: visitData?.encounter?.start_type?.value,
          visit_type: visitData?.encounter?.visit_type?.value,
          health_of_service: [
            {
              category: visitData?.helthcare?.category?.value ?? "",
              name: visitData?.helthcare?.name?.value ?? "",
              type: visitData?.helthcare?.type?.value ?? "",
            },
          ],

          device_details: [
            {
              category: visitData?.device?.category?.value ?? "",
              type: visitData?.device?.type?.value ?? "",
              name: visitData?.device?.name?.value ?? "",
            },
          ],

          practitioner_details: [
            {
              speciality: visitData?.practitioner?.speciality?.coding_id ?? "",
              type: visitData?.practitioner?.type?.value ?? "",
              id_name: visitData?.practitioner?.id_name?.value ?? "",
              clinic: visitData?.practitioner?.clinic?.value ?? "",
              role: visitData?.practitioner?.role?.value ?? "",
              period: "",
            },
          ],
          statushistory: [
            {
              status: patientStatus.payload.data[0],
              period: [
                {
                  _id: "",
                  start: getUtcTime(),
                  end: getUtcTime(),
                  id: 0,
                },
              ],
              reasoncode: "",
            },
          ],
          encounterdate: getUtcTime(),
          followupDur: 0,
          followupUOM: "",
          eSignature: "",
          encounter_no: "",
          end_type: "",
          accompanied_person: [
            {
              id_type: visitData?.accompained?.id_type?.value ?? "",
              id: visitData?.accompained?.id ?? "",
              name: visitData?.accompained?.name ?? "",
              relationship: visitData?.accompained?.relationship?.value ?? "",
            },
          ],
          cancel_date: 0,
          revision_date: 0,
          reasonCode: visitData?.accompained?.Reason?.value ?? "",
          class: visitData?.encounter?.class?._id
            ? visitData?.encounter?.class?._id
            : visitData?.encounter?.class?.label
            ? visitData?.encounter?.class?.label
            : "",
          classhistory: [
            {
              class: "",
              period: "",
            },
          ],
          // type: [
          //   {
          //     display: visitData?.encounter?.type?.value ?? "",
          //     _id: visitData?.encounter?.type?._id ?? "",
          //   },
          // ],
          type: [visitData?.encounter?.type?._id ?? ""],
          serviceType: visitData?.encounter?.visit_type?.value ?? "",
          priority: "",
          participant: participantdata,
          appointmentId: [this.props.location?.state?.appointmentId],
          period: "",
          length: [
            {
              value: 0,
              comparator: "",
              unit: "",
              system: "",
              code: "",
            },
          ],
          reasonReference: visitData?.encounter?.visit_reason?._id ?? "",
          priorityreason: "",
          followupReqd: false,
          encounterenddate: getUtcTime(moment().endOf("day")),
          diagnosis: [
            {
              condition: "",
              use: "",
              rank: "",
            },
          ],
          account: [
            {
              accountid: "",
              ChangeSeqNum: 0,
            },
          ],
          // account_id: [],
          // hospitalization: [
          //   {
          //     preAdmissionIdentifier: "",
          //     origin: "",
          //     admitSource: "",
          //   },
          // ],
          hospitalization: [
            {
              preAdmissionIdentifier: "",
              origin: "",
              admitSource: "",
              reAdmission: "",
              dietPreference: [],
              specialCourtesy: [],
              specialArrangement: [],
              destination: "",
              dischargeDisposition: "",
            },
          ],
          // reAdmission: "",
          // dietPreference: [],
          // specialCourtesy: [],
          // specialArrangement: [],
          // destination: "",
          // dischargeDisposition: "",
          location: [
            {
              location_id: "",
              status: "",
              period: "",
              physicalType: "",
            },
          ],
          Organization_id: this?.props?.loggedUserInfo?.data?.orgId || 0,
          // "Encounter_id": 0,
          patient_id: this.props.location?.state?.patientId,
          episodeOfCare_id: 0,
          ServiceRequest_id: 0,
          participantHistory: [
            {
              participantType: "",
              practroleId: "",
              practitionerId: "",
              specialty: "",
              relatedPersonId: "",
              relationship: "",
              userId: "",
              startTime: 0,
              endTime: 0,
              reasonCode: "",
            },
          ],
          source: "",
          plannedStartDate: 0,
          plannedEndDate: 0,
          subject: "",
          basedOn: "",
          partOf: "",
        },
      },
    ];
    if (isBack) {
      data[0].doc.id =
        this.props.location?.state?.encounterDetails?.[0]?.properties?.doc?.id;
    }
    console.log(this.state.appointmentData, "appointmentData");
    const res = await this.props.ORGANIZATION_VISIT_REGISTRATION({
      payloaddata: data[0],
      enId: isBack ? encounterDetails[0]?.properties.doc._id : null,
      encounterId: this.state?.appointmentData?.encounter?.[0]
        ? this.state.appointmentData.encounter[0]?.slice(10, 15)
        : null,
    });

    // await this.props.ORGANIZATION_REFERRAL_REGISTRATION({ payloaddata: referral[0] })

    const status = checkError(this.props.oraganizationvisitRegister);
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    }
    if (res?.payload?.data?.validation_error || res.payload?.data?.error) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg:
          res?.payload?.data?.validation_error?.[0]?.Errormsg ||
          res.payload?.data?.id?.error,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      return;
    } else {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.success,
        msg: "Encounter number generated successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
      this.props.history.push({
        pathname: Routes.financialRegistration,
        state: {
          prevRoute: Routes.patientVisitDetails,
          encounterDetails: res?.payload?.data,
          from: this.props.location?.state?.from,
          visitData: JSON.parse(JSON.stringify(visitData)),
          patientId: this.props.location?.state?.patientId,
          from: this.props.location?.state?.from,
          appointmentId: this.props.location?.state?.appointmentId,
          Ispatient: this.props?.location?.state?.Ispatient,
          mobileNumber:
            this.props.location?.state?.mobileNumber ||
            this.props.location?.state?.patientData ||
            this.props.location?.state?.mobileNo,
        },
      });
    }
  };

  async successfun(files) {
    let UploadedFiles = [];
    this.setState({
      ...this.state,
      btnName: true,
    });
    console.log("btnState", this.state);
    UploadedFiles = await uploadImage(files);
    // ----------------------------------
    const visitform = this.props.visitData;

    if (!visitform.encounter?.visit_reason) {
      visitform.encounter["error"] = {
        visit_reason: true,
        ...visitform.encounter["error"],
      };
    }
    if (!visitform.encounter?.visit_type) {
      visitform.encounter["error"] = {
        visit_type: true,
        ...visitform.encounter["error"],
      };
    }
    // if (!visitform.helthcare?.type) {
    //   visitform.helthcare["error"] = {
    //     type: true,
    //     ...visitform.helthcare["error"],
    //   };
    // }
    // if (!visitform.helthcare?.category) {
    //   visitform.helthcare["error"] = {
    //     category: true,
    //     ...visitform.helthcare["error"],
    //   };
    // }
    // if (!visitform.helthcare?.name) {
    //   visitform.helthcare["error"] = {
    //     name: true,
    //     ...visitform.helthcare["error"],
    //   };
    // }
    if (
      !(
        visitform.practitioner?.speciality &&
        Object.keys(visitform.practitioner?.speciality)?.length > 0
      )
    ) {
      visitform.practitioner["error"] = {
        speciality: true,
        ...visitform.practitioner["error"],
      };
    }
    visitform.setVisitDetails({
      ...visitform,
      uploadFiles: files,
    });
    if (
      // visitform.helthcare?.name?.value &&
      // visitform.helthcare?.category?.value &&
      // visitform.helthcare?.type?.value &&
      visitform.encounter?.visit_type?.value &&
      visitform.encounter?.visit_reason?.value &&
      visitform.practitioner?.speciality
    ) {
      this.handleSubmit(UploadedFiles);
    } else {
      this.setState({
        ...this.state,
        btnName: false,
      });
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  }

  async nextBtnFun() {
    const visitform = this.props.visitData;

    if (!visitform.encounter?.visit_reason) {
      visitform.encounter["error"] = {
        visit_reason: true,
        ...visitform.encounter["error"],
      };
    }
    if (!visitform.encounter?.visit_type) {
      visitform.encounter["error"] = {
        visit_type: true,
        ...visitform.encounter["error"],
      };
    }
    if (!visitform.helthcare?.type) {
      visitform.helthcare["error"] = {
        type: true,
        ...visitform.helthcare["error"],
      };
    }
    if (!visitform.helthcare?.category) {
      visitform.helthcare["error"] = {
        category: true,
        ...visitform.helthcare["error"],
      };
    }
    if (!visitform.helthcare?.name) {
      visitform.helthcare["error"] = {
        name: true,
        ...visitform.helthcare["error"],
      };
    }
    visitform.setVisitDetails({
      ...visitform,
    });
    if (
      visitform.helthcare?.name?.value &&
      visitform.helthcare?.category?.value &&
      visitform.helthcare?.type?.value &&
      visitform.encounter?.visit_type?.value &&
      visitform.encounter?.visit_reason?.value
    ) {
      this.handleSubmit();
      // this.setState({ activeId: 2 })
    }
  }
  setActive(Id) {
    // this.setState({ activeId: Id })
  }

  styles = {
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
  };

  getallmasters = async () => {
    const data = await this.props.MASTER_SEARCH({
      type: [
        "Level Of Care",
        "Department",
        "VISITTYPE",
        "Occupation",
        "NAMEPREFIX",
        "NAMESUFFIX",
        "Patient Type",
        "Race",
        "Ethnicity",
        "Religion",
        "RELATIONSHIP",
        "MARITALSTATUS",
        "LANGUAGE",
        "Town",
        "District",
        "State",
        "Country",
        "ALTERNATEIDTYPE",
        "Blood Group",
        "SPECIALTY",
        "Document Type",
        "ENCOUNTERTYPE",
        "ENCOUNTERCLASS",
        "STARTTYPE",
        // "Encounter Type","Encounter Class","Start type",
        "Reason",
        "SERVICECATEGORY",
        "SERVICETYPE",
        "Service",
      ],
    });
    this.setState({
      allMasters: data?.payload?.data,
    });
  };

  appointmentread = async () => {
    const appointmentData = await this.props.APPOINTMENT_READ({
      appointmentId: this.props.location?.state?.appointmentId,
    });
    const data = await this.props.ORGANIZATION_FILTER_BY_PATIENT({
      patientid: this.props.location?.state?.patientId,
    });
    // this.props?.location?.state?.patientId
    this.setState({
      Persondata: data?.payload?.data,
      appointmentData: appointmentData?.payload?.data,
      reasonID: appointmentData?.payload?.data?.appointmentReason?.[0],
    });
    console.log(data);
    const payload = {
      userid: this.props?.location?.state?.personId,
    };
    if (this.props.GET_RELATED_PERSON) {
      const data = await this.props.GET_RELATED_PERSON(payload);
      if (data?.payload?.data) {
        this.setState({
          choosemember: [...data?.payload?.data],
        });
      }
    }
  };
  setIntialReasonData = (reason) => {
    debugger;
    let patientData = this.props.visitData;
    let obj = {};
    if (reason && Array.isArray(reason) && reason?.length > 0) {
      obj = {
        value: reason?.[0]?.title,
        _id: reason?.[0]?.value,
      };
    }
    patientData.encounter["visit_reason"] = obj;
    if (patientData.encounter.error) {
      patientData.encounter.error["visit_reason"] = false;
    }
    patientData.setVisitDetails({
      ...patientData,
    });
  };

  async componentDidMount() {
    if (this?.props?.location?.state?.appointmentReason) {
      this.setIntialReasonData(this?.props?.location?.state?.appointmentReason);
    }
    let { isBack } = this?.props?.location?.state;
    if (!isBack) {
      // this.props.visitData.setVisitDetails({
      //   encounter: {
      //     mode: "direct"
      //   },
      //   practitioner: {},
      //   device: {},
      //   helthcare: {},
      //   accompained: {},
      // })
    } else {
    }
    debugger;
    await this.appointmentread();
    await this.getallmasters();
    let data = await this.props.GET_ENCOUNTER_MASTER_TYPE();
    await this.constructEncounterTypeObjectData(data);
    if (
      (this.props.queueManagement?.data || []).length === 0 &&
      (this.props.queueManagement?.previousData || []).length === 0 &&
      (this.props.queueManagement?.onHoldData || []).length === 0
    ) {
      this.fetchQueueData();
    } else {
      const userData = await this.fetchUserInfo();
      const permissionA = await checkWithCasbin(["queueSideNav"]);
      const permissionData = permissionA.write.find(
        (d) =>
          d === "appointmentQueueDataN" ||
          d === "appointmentQueueDataD" ||
          d === "appointmentQueueDataF"
      );
      const [, displayData, onHoldData, completedData] = makeQueueData(
        permissionData,
        this.props.queueManagement.allData,
        userData,
        false
      );
      this.props.queueManagement.setDisplayData(displayData);
      this.props.queueManagement.setOnHoldData(onHoldData);
      this.props.queueManagement.setPreviousData(completedData);
    }
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  alertHold = () => {
    // this.context.setSnack({
    //   open: true,
    //   severity: AlertProps.severity.success,
    //   msg: "Put on holded",
    //   vertical: AlertProps.vertical.top,
    //   horizontal: AlertProps.horizontal.right,
    // });
  };

  goBackFn = () => {
    const { prevRoute } = this.props.location.state;
    if (prevRoute && prevRoute === Routes.assemblePatioentRegister) {
      this.props.history.push({
        // pathname: ((this.props.location?.state?.guest) || (this.props?.location?.state?.Ispatient)) ? Routes.confirmBooking : Routes.PatientDashBoard,
        pathname: prevRoute,
        state: {
          isBack: true,
          nextRoute: Routes.patientVisitDetails,
          prevRoute: Routes.patientVisitDetails,
          personId: this.props.location?.state?.personId,
          patientData: this.props.location?.state?.mobileNumber,
          Ispatient: true,
          appointmentId: this.props.location?.state?.appointmentId,
          patientId: this.props.location?.state?.patientId,
          patient_id: this.props.location?.state?.patient_id,
          patient_key: this.props.location?.state?.patient_key,
        },
      });
      return;
    }
    this.props.history.goBack();
  };
  getEncounterClass = async (value) => {
    return await this.props.GET_ENCOUNTER_MASTER_CLASS({
      encType: value?.label,
    });
  };
  getEncounterClass_ = async (value) => {
    let patientData = this.props.visitData;
    let obj = {};
    let data = await this.props.GET_ENCOUNTER_MASTER_CLASS({
      encType: value,
    });
    if (
      data?.payload?.data &&
      Array.isArray(data?.payload?.data) &&
      data?.payload?.data?.length > 0
    ) {
      data.payload.data.map((val) => {
        if (val.shortDesc === "Ambulatory") {
          obj = {
            value: val.shortDesc,
            _id: val.encClsType,
          };
          patientData.encounter["class"] = obj;
          if (patientData.encounter.error) {
            patientData.encounter.error["class"] = false;
          }
          patientData.setVisitDetails({
            ...patientData,
          });
        }
      });
    }
  };
  constructEncounterTypeObjectData = async (data) => {
    let patientData = this.props.visitData;
    let obj = {};
    if (
      data?.payload?.data &&
      Array.isArray(data?.payload?.data) &&
      data?.payload?.data?.length > 0
    ) {
      data.payload.data.map((val) => {
        if (val.shortDesc === "Outpatient") {
          obj = {
            value: val.shortDesc,
            _id: val.encType,
          };
          patientData.encounter["type"] = obj;
          if (patientData.encounter.error) {
            patientData.encounter.error["type"] = false;
          }
          patientData.setVisitDetails({
            ...patientData,
          });
        }
      });
    }
    if (obj?._id) {
      await this.getEncounterClass_(obj?._id);
    }
  };

  render() {
    const { activeId } = this.state;
    const walkin =
      this.props?.location?.state?.walkin ||
      this.props?.location?.state?.Ispatient;
    const {
      outlinedButton,
      // containedButton<
    } = this.styles;
    const { name } = getUserInfo();

    return (
      <Div
        id="Registervisit_visit_details_parent_div"
        className="light-background-color"
      >
        <TopHeader
          parent_id={"dashboard"}
          noHome={true}
          middleComponent={<></>}
        />
        <TopNavbar
          goBack={this.goBackFn}
          initialFiles={this.props.visitData?.uploadFiles || []}
          parent_id={"Registervisit_visit_details"}
          alertHold={this.alertHold}
          activeId={activeId}
          putOnHold={() =>
            this.putOnHold(
              "undefined",
              this.props.location?.state?.appointmentId
            )
          }
          newUser={this.props.location?.state?.newUser}
          navClick={this.setActive}
          successfun={this.successfun}
          navBarArr={patientVisit}
          title={patientVisitTitle}
          btn={this.state.btnName ? "Registering" : "Register & continue"}
          isAVC={this.props?.AVCMeeting?.RoomName}
        />
        <div
          style={{
            height: "calc(100vh - 128px)", // 128px = 72px + 56px // 72 - topheader , 56 - topnav
            overflow: "auto",
            display: this.state.loading ? "flex" : "block",
            alignItems: "center",
            justifyContent: this.state.loading ? "center" : "unset",
          }}
        >
          <LoadingComp loading={this.state.loading}>
            {this.state.Persondata?.length > 0 && (
              <Div
                id="Registervisit_visit_details_patientlist_div"
                className="rv-list-view"
                inLineStyles={{ padding: 10 }}
              >
                {this.state.Persondata?.[0] && (
                  <PatientListView
                    parent_id={"Registervisit_visit_details"}
                    Persondata={this.state.Persondata?.[0]}
                    personvisitedDataNames={
                      this.props?.location?.state
                        ?.personvisitedDataNames?.[0] ?? {}
                    }
                    details={this.props?.location?.state?.details ?? {}}
                    contact={this.props?.location?.state?.contact ?? {}}
                    address={this.props?.location?.state?.address?.[0] ?? {}}
                    walkin={walkin}
                  />
                )}
              </Div>
            )}

            {activeId === 1 && (
              <Div
                id="Registervisit_visit_details_id_one_parent_div"
                style={{
                  padding: "3px 15px",
                }}
              >
                <EncounterInfo
                  parent_id={"Registervisit_visit_details"}
                  Persondata={this.state.Persondata}
                  allMasters={this.state?.allMasters}
                  getEncounterClass={this.getEncounterClass}
                  encounterType={
                    this.props.getEncounterType?.data &&
                    Array.isArray(this.props.getEncounterType?.data)
                      ? this.props.getEncounterType?.data
                      : []
                  }
                  encounterClass={
                    this.props.getEncounterClass?.data &&
                    Array.isArray(this.props.getEncounterClass?.data)
                      ? this.props.getEncounterClass?.data
                      : []
                  }
                  appointmentData={this.state.appointmentData}
                  reasonID={this.state?.reasonID}
                  isEdit={false}
                  walkin
                />

                {walkin && (
                  <BookingDetails
                    parent_id={"Registervisit_visit_details"}
                    name={name}
                    personvisitedDataNames={
                      this.props?.location?.state
                        ?.personvisitedDataNames?.[0] ?? {}
                    }
                    Persondata={this.state.Persondata}
                    appointmentData={this.state.appointmentData}
                  />
                )}

                <Pactitioner
                  parent_id={"Registervisit_visit_details"}
                  Persondata={this.state.Persondata}
                  appointmentData={this.state.appointmentData}
                  walkin={walkin}
                  allMasters={this.state?.allMasters}
                  choosemember={this.state.choosemember}
                />

                <DeviceDetails
                  parent_id={"Registervisit_visit_details"}
                  walkin={walkin}
                  appointmentData={this.state.appointmentData}
                  allMasters={this.state?.allMasters}
                />
                {/* <ReasonDetails
                parent_id={"Registervisit_visit_details"}
                walkin={walkin}
                appointmentData={this.state.appointmentData}
                allMasters={this.state?.allMasters}
                label={"Reason for Revision"}
                // view={view}
              /> */}
                <br />
                {/* <Div className="p-continue-action">
                      <Button onClick={() => this.nextBtnFun()} inLineStyles={containedButton}>
                          Continue to Referral
                          </Button>
                  </Div> */}
              </Div>
            )}

            {activeId === 2 && (
              <Div
                id="Referral_Registration_referral_id_one_parent_div"
                style={{
                  padding: "3px 15px",
                }}
              >
                <RefLetter Persondata={this.state.Persondata} />
                <RefFrom
                  walkin={walkin}
                  choosemember={this.state.choosemember}
                  allMasters={this.state?.allMasters}
                />
                <RefTo
                  Persondata={this.state.Persondata?.Person?.[0]}
                  walkin={walkin}
                  appointmentData={this.state.appointmentData}
                  allMasters={this.state?.allMasters}
                />
                <RefDetails
                  walkin={walkin}
                  allMasters={this.state?.allMasters}
                />
                <RefNote />
                <Div
                  id="Referral_Registration_referral_go_back_div"
                  className="p-continue-action"
                >
                  <Button
                    id="Referral_Registration_referral_go_back_button"
                    inLineStyles={outlinedButton}
                    onClick={() =>
                      this.setState({
                        activeId: 1,
                      })
                    }
                  >
                    Go Back
                  </Button>
                </Div>
              </Div>
            )}
          </LoadingComp>
        </div>
      </Div>
    );
  }
}
PatientVisitDetails.contextType = AlertContext;
const mapStateToProps = (state) => ({
  oraganizationRegisterPatient:
    state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
  loggedUserInfo: state?.authSlice?.loggedUserInfo,
  getEncounterType: state?.searhcApiSlice?.getEncounterType,
  getEncounterClass: state?.searhcApiSlice?.getEncounterClass,
});
export default connect(
  mapStateToProps,
  actions
)(withRouter(withQueue(withAllContexts(PatientVisitDetails))));
