import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Verification from '../../assets/img/verification.svg';
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  Div,
  //   Icons,
  Text,
  Divider,
  // Card,
  H6,
  // ListItem,
  Avatar,
  //   Dropdown,
  TextArea,
  Button,
  OtpInput
} from 'qdm-component-library'
import { checkError, AlertProps, convertTime, getUserInfo, makeName, utcTOLocal, UIColor } from '../../utils'
import { AlertContext } from '../../contexts'
import { Selection } from '../../components';
const casbinjs = require("casbin.js");

const styles = (theme) => ({
  select: {
    marginTop:16,
    "& fieldset": {
      borderColor: "#E0E0E0",
      borderRadius:8
  },
  '& input':{
    height:19
  }
}
});

class CancelBookingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      resend: '',
      appointmentInfo: {},
      otp: 0,
      callcen: false
    }
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  async componentDidMount() {
    this.fetchInfo();
    this.props.REASON_FOR_APPT_CANCEL_MODIFY({type:'APPCANCELBYUSER'})
    
    const Arr = localStorage.getItem("role_data");
    const arr1 = JSON.parse(atob(Arr))[localStorage.getItem("role_name")].access;
    const permission = { "callcenter": arr1};
    const authorizer = new casbinjs.Authorizer("manual", permission);
    authorizer.setPermission(permission);
    const result = await authorizer.can("callcenter", "4");
    
    this.setState({ callcen: result });
  }

  async fetchInfo() {
    const data = await this.props.APPOINTMENT_READ({ appointmentId: this.props.appointmentId })
    this.changeState("appointmentInfo", data?.payload?.data || {});
  }

  handleOtpChange = (val) => {
    this.setState({
      otp: val
    })
  };

  shouldComponentUpdate(props, state) {
    return true
  }
  resendOTP = async () => {
    const res = await this.props.RESEND_OTP({
      mobileNo: this.state.appointmentInfo?.AppointmentNotification?.[0]?.mobile,
    })
    const status = checkError(this.props.resendOTP)
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true
      })
    } else {
      this.setState({
        resend: res?.payload?.data?.message
      })
    }
  }
  getPractionerName(val) {
    if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
      return makeName(val?.resourcecode?.name?.[0] ?? {});
    }
    return val?.resourcecode?.shortdesc;
  }
  render() {
    const { cancelbookings, cancelbookingreason, parent_id, classes } = this.props
    return (
      <div
        id={`${parent_id}_cancelbook_parent_div`}
      >
        <Container
          id={`${parent_id}_cancelbook_parent_container`}
          key={'0'}
          name='container'
          fluid={false}
          style={{
            padding: '20px'
          }}
        >
          <Text
            id={`${parent_id}_cancelbook_appointment_text`}
            key={'0'}
            className="pc_semibold"
            style={{ fontSize: 20, color: '#101010' }}
            name={'Cancel Appointment'}
          ></Text>

          <Divider
            id={`${parent_id}_cancelbook_bottom_divider`}
            key={'1'}
            textWidth={'50'}
            textColor={'#000'}
            borderColor={'#f0f0f0'}
            borderWidth={'1'}
            orientation={'horizontal'}
            variant={'middle'}
            style={{ marginLeft: 0, marginRight: 0 }}
          //   style={qdmstyles.BKv}
          ></Divider>
          {cancelbookings === 2 && (
            <Div
              id={`${parent_id}_cancelbook_verification_div`}
              key={'0'} style={{ textAlign: 'center' }}>
              <img alt={'Verification'} src={Verification} />
            </Div>
          )}

          {cancelbookings === 2 && (
            <Div
              id={`${parent_id}_cancelbook_your_visit_div`}
              key={'1'}
              className='qdm'
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '14px'
              }}
            >
              <Text
                id={`${parent_id}_cancelbook_your_visit_text`}
                key={'0'}
                name={"Your appointment has been cancelled !"}
                style={{
                  fontSize: '16px',
                  textAlign: 'center',
                  color: '#38C20A',
                  fontWeight: '600',
                  textTransform: ''
                }}
              ></Text>
            </Div>
          )}

          <Div
            id={`${parent_id}_cancelbook_side_div`}
            style={{
              marginTop: '10px',
              boxShadow: 'none',
              borderTop: cancelbookings === 2 && '1px solid #F0F0F0',
              borderBottom: '1px solid #F0F0F0',
              display: 'flex',
              paddingBottom: '14px',
              paddingTop: cancelbookings === 2 && '16px',
              justifyContent: 'space-around'
            }}
          >
            <Div
              id={`${parent_id}_cancelbook_side_sub_div`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                // borderRight: "1px solid #F0F0F0",
                paddingRight: '13px'
              }}
            >
              <Div
                id={`${parent_id}_cancelbook_image_div`}
              >
                <img
                  id={`${parent_id}_cancelbook_image`}
                  alt='img'
                  width='40px'
                  height='40px'
                  style={{ borderRadius: '50%', marginRight: '10px' }}
                  src='https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'
                />
              </Div>
              <Div
                id={`${parent_id}_cancelbook_appointmentinfo_div`}
              >
                <H6
                  id={`${parent_id}_cancelbook_appointmentinfo_h6`}
                  className="pc_regular"
                  style={{
                    fontSize: '14px',
                    color: '#101010'
                  }}
                >
                  {this.getPractionerName(this.state.appointmentInfo)}
                </H6>
                <Text
                  id={`${parent_id}_cancelbook_appointmentinfo_text`}
                  className="pc_regular"
                  style={{
                    fontSize: '10px',
                    letterSpacing: '.4px',
                    color: '#6f6f6f'
                  }}
                >
                  {this.state.appointmentInfo?.orgid?.name || ""}
                </Text>
              </Div>
            </Div>
            <Div
              id={`${parent_id}_cancelbook_description_div`}
              style={{ width: 1, height: 42, backgroundColor: '#E0E0E0' }} />
            <Div
              id={`${parent_id}_cancelbook_description_sub_div`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '6px'
              }}
            >
              <Div
                id={`${parent_id}_cancelbook_calendar_div`}
              >
                <Avatar
                  id={`${parent_id}_cancelbook_logo_image`}
                  key={'0'}
                  variant='circle'
                  letter='Logo'
                  src='https://d27zlipt1pllog.cloudfront.net/pub/static/version1608753130/frontend/Apollo/mobile/en_US/images/new-img/logo_2.png'
                  alt='Image'
                  style={{
                    backgroundColor: '#F9F9F9',
                    paddingTop: '0px',
                    width: '40px',
                    height: '40px',
                    margin: '0px',
                    marginRight: '10px'
                  }}
                >
                  <Avatar
                    id={`${parent_id}_cancelbook_calendar_image`}
                    src='/static/media/calendar.374dbd8e.svg'
                    alt='Image'
                    style={{
                      backgroundColor: 'transparent',
                      height: 'auto',
                      width: '16px'
                    }}
                    variant='square'
                  ></Avatar>
                  {/* <Icons
                    key={"2"}
                    fontIcon="calendar"
                    ariaHidden="true"
                    className=""
                    size="small"
                    style={{
                      color: "#B6B6B6",
                      fontSize: "12px",
                      padding: "12px",
                    }}
                  ></Icons> */}
                </Avatar>
              </Div>
              <Div>
                <H6
                  id={`${parent_id}_cancelbook_convert_h6`}
                  className="pc_regular"
                  style={{
                    fontSize: '14px',
                    color: '#101010'
                  }}
                >
                  {utcTOLocal(this.state.appointmentInfo?.Slots?.[0]?.start,"DD MMM YY | hh:mm a")}
                  {/* {convertTime(this.state.appointmentInfo?.Slots?.[0]?.start, "unix", "DD MMM YY | hh:mm a")} cancel drawer */}
                </H6>
                <Text
                  id={`${parent_id}_cancelbook_consultation_text`}
                  className="pc_regular"
                  style={{
                    fontSize: '10px',
                    letterSpacing: '.4px',
                    color: '#6f6f6f'
                  }}
                >
                  {this.state.appointmentInfo?.consultationMode}
                </Text>
              </Div>
            </Div>
          </Div>
          <Div
            id={`${parent_id}_cancelbook_subtitle_div`}
            style={{
              padding: '10px',
              border: '1px solid #E0E0E0',
              background: '#F9F9F9',
              margin: '16px 0px',
              borderRadius: '6px',
              // height: '12vh'
            }}
          >
            <Div
              id={`${parent_id}_cancelbook_subtitle_sub_div`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '9px'
              }}
            >
              <Text
                id={`${parent_id}_cancelbook_description_text`}
                className="pc_medium"
                style={{
                  fontSize: '12px',
                  color: '#6F6F6F'
                }}
              >
                DESCRIPTION
              </Text>
              <Text
                id={`${parent_id}_cancelbook_total_text`}
                className="pc_medium"
                style={{
                  fontSize: '12px',
                  color: '#6F6F6F'
                }}
              >
                TOTAL
              </Text>
            </Div>
            <Div
              id={`${parent_id}_cancelbook_cancellation_div`}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '6px',
                borderTop: '1px solid #F0F0F0'
              }}
            >
              <Text
                id={`${parent_id}_cancelbook_cancellation_text`}
                className="pc_regular"
                style={{
                  fontSize: '14px',
                  color: '#101010'
                }}
              >
                Cancellation charge ( Nil )
              </Text>
              <Text
                id={`${parent_id}_cancelbook_amount_text`}
                className="pc_regular"
                style={{
                  fontSize: '14px',
                  color: '#101010'
                }}
              >
                ₹ 0.00
              </Text>
            </Div>
          </Div>
          {/* <br /> */}
          <Text
            id={`${parent_id}_cancelbook_reason_text`}
            className="pc_regular"
            name={
              <span
                id={`${parent_id}_cancelbook_reason_span`}
                style={{ fontSize: '14px', color: '#6F6F6F' }}>
                Reason for cancellation
                <label
                  id={`${parent_id}_cancelbook_star_label`}
                  style={{ color: 'red', marginLeft: 4 }}>*</label>
              </span>
            }
            style={{ fontSize: '17px' }}
          />

          <Div
            id={`${parent_id}_cancelbook_select_div`}
            key={'2'} 
            className={classes.select}
          >
            <Selection 
              options={this.props?.reasonForApptCancelModify.data || []}
              optionLabel="title"
              value={cancelbookingreason}
              label={''}
              parent_id={parent_id}
              onChange={(e,value)=>this.props.handleSelectCancelbooking(value)}

            />
          </Div>
          {/* {cancelbookings === 1 && (
            <Div
              id={`${parent_id}_cancelbook_otp_div`}
              inLineStyles={{
                backgroundColor: '#F5FAFF',
                padding: 20,
                borderRadius: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '20px'
              }}
            >
              <Text
                id={`${parent_id}_cancelbook_otp_text`}
                className="pc_medium"
                inLineStyles={{
                  marginBottom: 24,
                  fontSize: 16,
                  color: '#101010'
                }}
              >
                OTP Verification
              </Text>
              <Text
                id={`${parent_id}_cancelbook_otp_sent_text`}
                className="pc_regular"
                inLineStyles={{
                  fontSize: 14,
                  textAlign: 'center',
                  color: '#6F6F6F'
                }}
              >
                Please enter the OTP sent to <br />
                <Text
                  id={`${parent_id}_cancelbook_mobile_text`}
                  className="pc_regular"
                  inLineStyles={{
                    color: '#101010',
                    fontSize: 14
                  }}
                >
                  {this.state.appointmentInfo?.AppointmentNotification?.[0]?.mobile}
                </Text>
              </Text>
              <Div
                id={`${parent_id}_cancelbook_mobile_otp_div`}
                inLineStyles={{ marginBottom: 24 }}>
                <OtpInput
                  id={`${parent_id}_cancelbook_mobile_otpinput`}
                  onChange={(e) => {
                    this.handleOtpChange(e);
                  }}
                  numberOfInputs={6}
                  variant='standard'
                  type='text'
                  focusTextColor='#0071F2'
                  focusBorderColor='#0071F2'
                  inLineStyles={{ backgroundColor: 'transparent' }}
                // style={ { display : "flex",justifyContent : "center",alignItems : "center",paddingLeft : "" } }
                />
              </Div>
              <Text
                id={`${parent_id}_cancelbook_recevie_otp_text`}
                className="pc_regular" inLineStyles={{ color: '#6F6F6F', fontSize: 12 }}>
                Didn't receive OTP ?{' '}
                <Text
                  id={`${parent_id}_cancelbook_resend_text`}
                  className="pc_medium"
                  inLineStyles={{
                    color: '#101010',
                    fontSize: 12
                  }}
                  onClick={() => this.resendOTP()}
                >
                  Resend OTP
                </Text>
              </Text>
              {this.state.resend && (
                <Text
                  id={`${parent_id}_cancelbook_resend_error_text`}
                  name={
                    <span
                      id={`${parent_id}_cancelbook_resend_error_span`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '12px',
                        marginBottom: '6px',
                        color: '#38C20A'
                      }}
                    >
                      {this.state.resend}
                    </span>
                  }
                ></Text>
              )}
            </Div>
          )} */}
          {cancelbookingreason?.value ===
            'Other reason ( enter your reason manually )' &&
            cancelbookings === 0 && (
              <TextArea
                id={`${parent_id}_cancelbook_reason_manually_textarea`}
                placeholder='Enter your reason manually'
                style={{
                  marginTop: '20px',
                  padding: 14,
                  resize: 'none',
                  height: '14vh'
                }}
              />
            )}
          <Button
            id={`${parent_id}_cancelbook_buttom`}
            key={'1'}
            type='button'
            disabled={this.props?.signIn?.loading || this.props?.cancelAppointment?.loading}
            className="pc_medium"
            variant='outlined'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              marginTop: '20px',
              fontSize: '14px',
              padding: '8px 16px',
              marginBottom: '10px',
              backgroundColor: UIColor?.primaryColor?.color,
              border: 'none',
              borderRadius: 8
            }}
            onClick={async () => {
              if (cancelbookings === 1) {
                if(!cancelbookingreason?.value){
                  this.context.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: 'Please select reason for cancellation',
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                    tone: true,
                  })
                  return
                } else {
                let mobile = this.state.appointmentInfo?.AppointmentNotification?.[0]?.mobile;
                if (!mobile) {
                  const userInfo = getUserInfo();
                  mobile = userInfo.mobile;
                }
                // const data = await this.props.SIGN_IN({
                //   mobileNo: mobile,
                //   otp: this.state.otp,
                // });
                // const status = checkError(data?.payload || {})
                // if (!status.isError) {
                  const appointmentData = JSON.parse(JSON.stringify(this.state.appointmentInfo));
                  appointmentData["cancelationReason"] = [cancelbookingreason?._id];
                  const cancelData = await this.props.APPOINTMENT_DELETE(appointmentData);
                  const cancelStatus = checkError(cancelData?.payload || {});
                  if (!cancelStatus.isError) {
                    this.props.handlecahngeCancelbooking(2);
                  } 
                  else {
                    this.changeState("resend", cancelStatus?.errMsg)
                  }
                // } else {
                //   this.changeState("resend", status.errMsg)
                // }
              }
              } else {
                if(!cancelbookingreason?.value){
                  this.context.setSnack({
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: 'Please select reason for cancellation',
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                    tone: true,
                  })
                  return
                }
                this.props.handlecahngeCancelbooking(
                  cancelbookings === 0 ? 1 : null,
                  this.state.appointmentInfo?.AppointmentNotification?.[0]?.mobile,
                )
              }
            }}
          >
            {
              this.props?.signIn?.loading || this.props?.cancelAppointment?.loading
                ? "Canceling..."
                : (
                  cancelbookings === 0
                    ? 'Send OTP & Cancel'
                    : cancelbookings === 1
                      ? 'Confirm & Cancel'
                      : cancelbookings === 2 && 'Back to Home'
                )
            }
          </Button>
          {/* {cancelbookings === 1 && (
            <Text
              id={`${parent_id}_cancelbook_back_text`}
              onClick={() => this.props.handlecahngeCancelbooking('back')}
              name={'Back'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: '#0071f2',
                textDecoration: 'underline',
                marginTop: '18px',
                cursor: 'pointer'
              }}
            ></Text>
          )} */}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  resendOTP: state?.authSlice?.resendOTP,
  sigin: state?.authSlice?.signIn,
  cancelAppointment: state?.appointmentReducer?.deleteAppointment,
  reasonForApptCancelModify: state?.appointmentReducer?.reasonForApptCancelModify,
})
CancelBookingPage.contextType = AlertContext

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(CancelBookingPage))
);
