import React from 'react';
import { Div, Avatar, Divider, Icons, H6, Text } from 'qdm-component-library';
import { makeStyles } from '@material-ui/core';
import { UIColor } from "../../utils";

const useStyles = makeStyles({
    billingStatus: {
        '&::before': {
            position: 'absolute',
            content: '" "',
            height: '100%',
            width: 0.5,
            backgroundColor: UIColor.lineBorderFill.color,
            left: 18,
            top: 0
        },
    },
    parentDiv: {
        position: 'relative',
        overflow: 'hidden',
        margin: 20
    },
    dot: {
        width: 8,
        height: 8,
        border: `2px solid ${UIColor.lineBorderFill.color}`,
        borderRadius: '50%',
        position: 'relative',
        left: '18px',
        zIndex: 1,
        backgroundColor: '#fff',
        transform: 'translateX(-50%)'
    }
});

export const BillingPending = (props) => {

    const classes = useStyles(props);

    const styles = {
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        flex: {
            display: 'flex',
            position: 'relative',
            zIndex: 1
        },
        header: {
            padding: 10
        },
        h6: {
            fontSize: 12,
            color: '101010',
            margin: 0
        },
        divider: {
            margin: 0,
            marginBottom: 5,
            height: 0.5
        },
        avatar: {
            width: 36,
            height: 36,
            borderRadius: 8,
            marginLeft: 0
        },
        span: {
            fontSize: 10,
            color: '#323232'
        },
        statusCard: {
            borderRadius: 8,
            margin: '5px 0px',
            padding: 10
        },
        location: {
            fontSize: 12,
            color: '#323232'
        }
    };

    const backgroundColorStatus = (type) => {
        switch (type) {
            case 'Billing Pending':
                return '#E5F0FE'
            case 'Sample Collected':
                return '#F7F7F7'
            default:
                return '#F7F7F7'
        }
    }

    const colorStatus = (type) => {
        switch (type) {
            case 'Billing Pending':
                return UIColor.primaryText.color
            case 'Sample Collected':
                return '#323232'
            default:
                return '#323232'
        }
    }
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_billing_pending_parent_div`}
        >
            <Div
                id={`${parent_id}_billing_pending_title_div`}
                style={styles.header}>
                <H6
                    id={`${parent_id}_billing_pending_${props?.title}_div`}
                    style={styles.h6} className="pc_semibold">{props?.title}</H6>
            </Div>
            <Divider
                id={`${parent_id}_billing_pending_divider`}
                style={styles.divider} />
            <div
                id={`${parent_id}_billing_pending_profile_div`}
                className={classes.parentDiv}>
                <div
                    id={`${parent_id}_billing_pending_profile_sub_div`}
                    className={classes.dot} />
                {
                    props?.billingPendingData?.length > 0 &&
                    props?.billingPendingData?.map((_, i) => {
                        return (
                            <div
                                id={`${parent_id}_billing_pending_${i}_div`}
                                className={classes.billingStatus} key={i}>
                                <Div
                                    id={`${parent_id}_billing_pending_${i}_sub_div`}
                                    style={styles.flex}>
                                    <Avatar
                                        id={`${parent_id}_billing_pending_${i}_profile_avatar`}
                                        variant="square"
                                        letter="Avatar"
                                        src={_?.profile_pic}
                                        alt="Image"
                                        style={styles.avatar}
                                    />
                                    <Div
                                        id={`${parent_id}_billing_pending_${i}_description_div`}
                                        style={{ marginLeft: 10, width: '100%', flex: 1 }}>
                                        <Text
                                            id={`${parent_id}_billing_pending_${i}_description_text`}
                                        >
                                            {_?.speciality && <Text
                                                id={`${parent_id}_billing_pending_${i}_speciality_text`}
                                                className="pc_regular" style={styles.span}>{_?.speciality}</Text>}
                                            {_?.date && <Text
                                                id={`${parent_id}_billing_pending_${i}_date_text`}
                                                className="pc_regular" style={styles.span}>&nbsp;•&nbsp;{_?.date}</Text>}
                                        </Text>
                                        {_?.name && <H6
                                            id={`${parent_id}_billing_pending_${i}_name_h6`}
                                            style={styles.h6} className="pc_semibold">{_?.name}</H6>}
                                        {
                                            _?.status &&
                                            <Div
                                                id={`${parent_id}_billing_pending_${i}_status_div`}
                                                style={{
                                                    ...styles.statusCard,
                                                    backgroundColor: backgroundColorStatus(_?.status?.status)
                                                }}>
                                                {_?.status?.status &&
                                                    <H6
                                                        id={`${parent_id}_billing_pending_${i}_status_h6`}
                                                        style={{
                                                            ...styles.h6,
                                                            color: colorStatus(_?.status?.status)
                                                        }}
                                                        className="pc_semibold">
                                                        {_?.status?.status}
                                                    </H6>
                                                }
                                                <Text
                                                    id={`${parent_id}_billing_pending_${i}_location_text`}
                                                >
                                                    {_?.status?.location &&
                                                        <Text
                                                            id={`${parent_id}_billing_pending_${i}_location_sub_text`}
                                                        >
                                                            <Icons
                                                                id={`${parent_id}_billing_pending_${i}_location_map-marker_icons`}
                                                                fontIcon="map-marker" style={{ color: colorStatus(_?.status?.status) }} />
                                                            {_?.status?.location && <Text
                                                                id={`${parent_id}_billing_pending_${i}_location_text`}
                                                                style={styles.location} className="pc_regular" >&nbsp;{_?.status?.location}</Text>}
                                                        </Text>
                                                    }
                                                    {_?.status?.locationCode && <Text
                                                        id={`${parent_id}_billing_pending_${i}_locationCode_text`}
                                                        className="pc_regular" style={styles.location}>&nbsp;•&nbsp;{_?.status?.locationCode}</Text>}
                                                </Text>
                                            </Div>
                                        }
                                    </Div>
                                </Div>
                            </div>
                        )
                    })
                }
                <div className={classes.dot} />
            </div>
        </div>
    )
}

BillingPending.defaultProps = {
    title: 'Workflow Status',
    billingPendingData: []
}