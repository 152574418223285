import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import EventIcon from '@material-ui/icons/Event';
import AccessTime from '@material-ui/icons/AccessTime';

class CustomInputDatePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <DatePicker
        popperPlacement={this.props?.popperPlacement}
        minDate={this.props?.minDate}
        maxDate={this.props?.maxDate}
        selected={this.props.selectedDate}
        timeFormat={this.props?.timeFormat }
        showTimeSelectOnly={this.props?.showTimeSelectOnly || false}
        showTimeSelect={this.props?.showTimeSelect || false}
        timeIntervals={this.props?.timeIntervals}
        // monthsShown={1}
        customInput={
          <div style={{ position: "relative" }}>
            {this.props.inputField}
            {this.props?.showTimeSelect && this.props?.showTimeSelectOnly ? 
              <AccessTime style={{ position: "absolute", top: this?.props?.customInput?.top ?? 32, right: this?.props?.customInput?.right ?? 10 }}/> :
              <EventIcon style={{ position: "absolute", top: this?.props?.customInput?.top ?? 33, right: this?.props?.customInput?.right ?? 10 }}/>}
          </div>
        }
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        placeholderText={this.props.placeholderText}
        // className={this.props.className}
        dateFormat="dd/MM/yyyy"
        // inline
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        calendarStartDay={1}
        onChange={(value) => {
          this.props.handleChange(value) ;
        }}
      />
    );
  }
}

export default CustomInputDatePicker;
