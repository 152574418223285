import React from 'react';
import { Div, H6, Divider } from 'qdm-component-library';
import { UIColor } from "../../utils";

export const AppointmentInfo = (props) => {

    const styles = {
        header: {
            padding: 10
        },
        h6: {
            fontSize: 12,
            color: UIColor.primaryText.color,
            margin: 0
        },
        body: {
            minHeight: 100
        },
        divider: {
            margin: 0
        }
    };
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_appointmentinfo_parent_div`}
        >
            <Div
                id={`${parent_id}_appointmentinfo_${props?.title}_div`}
                style={styles.header}>
                <H6
                    id={`${parent_id}_appointmentinfo_${props?.title}_h6`}
                    style={styles.h6} className="pc_semibold">{props?.title}</H6>
            </Div>
            <Divider
                id={`${parent_id}_appointmentinfo_bottom_divider`}
                style={styles.divider} />
            <Div
                id={`${parent_id}_appointmentinfo_bottom_div`}
                style={styles.body}>

            </Div>
        </div>
    )
}

AppointmentInfo.defaultProps = {
    title: 'Appointment Info'
}