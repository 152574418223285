import React, { useState } from "react";
import {
  Div,
  Card,
  Text,
  Modal,
  Divider,
  Row,
  Icons,
  H6,
  Col,
  Image
} from "qdm-component-library";
import { RequestorDetails, OrderStatus } from "../../../components";
import { Popover, makeStyles, Button, Typography } from "@material-ui/core";
import moment from "moment";
import { useSelector } from "react-redux";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { getOrderName, makeName, UIColor, utcTOLocal } from "../../../utils";
const statusColors = {
  "On-Hold": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  "Draft": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
  "Discontinue": { color: "#d60000", background: UIColor.lineBorderFill.color },
  "Continue": { color: UIColor.primaryColor.color, background: UIColor.primaryColor.color },
  //"Active": { color: UIColor.primaryColor.color, background: "#D4E7FD" },
  "Active": { color: UIColor.success.color, background: "#fff" },
  "Hold": { color: "#5B12BB", background: "#E3D7F3" },
  "Complete": { color: UIColor.success.color, background: "hsl(105, 62%, 90%)" },
  "Cancel": { color: UIColor.error.color, background: "#FFE1E0" },
  // "Cancel": { color: UIColor.error.color, background: "#FFE1E0" }
}

const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
});

export const LabOrderList = (props) => {
  const styles = {
    textColor: {
      fontFamily: "pc_regular",
      fontSize: 12,
      paddingLeft: 6,
      color: UIColor.tertiaryText.color
    },
    textColor1: {
      fontFamily: "pc_regular",
      fontSize: 12,
      color: UIColor.tertiaryText.color
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginRight: "16px"
    },
    editAllergyIcon: {
      padding: "6px 10px",
      background: UIColor.greyBackground.color,
      borderRadius: "50px",
      cursor: "pointer",
    },
    userIcon: {
      width: 25,
      height: 25,
      borderRadius: 50,
      backgroundColor: "#E2EEFB",
      marginRight: 15,
      padding: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalCard: {
      width: 250,
      padding: 20,
    },
    modalHeading: {
      fontSize: 16,
      color: UIColor.primaryText.color,
      textAlign: "center",
      marginBottom: 10,
    },
    modalSubTitle: {
      fontSize: 14,
      color: "#242424",
      textAlign: "center",
      marginBottom: 20,
    },
    outlinedButton: {
      borderColor: "#DEDEDE",
      color: UIColor.primaryText.color,
      backgroundColor: "#fff",
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_regular !important",
      display: "block",
    },
    containedButton: {
      backgroundColor: UIColor.error.color,
      borderColor: UIColor.error.color,
      borderRadius: 8,
      fontSize: 14,
      fontFamily: "pc_semibold !important",
      marginBottom: 10,
      color: "#fff",
    },
    modal: {
      backgroundColor: "#fff",
      boxShadow: "0px 4px 24px #00000029",
      border: `1px solid ${UIColor.lineBorderFill.color}`,
      borderRadius: 8,
      overflow: "hidden",
    },
    dot: {
      width: 5,
      height: 5,
      borderRadius: "50%",
      backgroundColor: "#6B6B6B",
      margin: "0px 2px",
      display: "inline-block",
    },
    wrapper: {
      width: 15,
      height: 15,
      borderRadius: "50%",
      backgroundColor: UIColor.lineBorderFill.color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 12,
    },
    statustextColor: {
      color: UIColor.success.color,
      fontSize: 12,
      fontWeight: "bold",
      paddingLeft: 6,
    },
  };
  console.log("listOrderData", props.val?.requestorDetails?.practitionerId?.prefix?.[0]?.[0]);
  const classes = useStyles(props);
  const encounter = useSelector((state) => state?.chiefCompilenceMastersSlice.encounter_Details);
  const loggedUserInfo = useSelector((state) => state?.authSlice?.loggedUserInfo);
  const orderStatus = useSelector((state) => state?.signOffSlice.getRecordStatus);
  const isFirstVisit = encounter.data.type.toLocaleLowerCase() === "first visit"
  const statusVal = orderStatus?.data?.find?.(d => d?.label === props?.status);
  const status = statusVal?.label || "";
  const requestorDetails = {
    name: props.patientgetData?.name ?? "",
    pidNumber: props.patientgetData?.pID ?? "",
    role: props.patientgetData?.speciality ?? "",
    hospital: props.patientgetData?.facilityName ?? "",
    profile_pic:
      "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
  };

  const [modal, setModal] = useState(false);
  const [infoText, setInfoText] = React.useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [show, hide] = useState(false);

  const handleModal = (val) => {
    setModal(val);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // let arr = [];
  // for (let i = 0; i < props?.recurrentData?.endsAfter?.endsAfter; i++) {
  //   arr.push(i);
  // }

  const { parent_id } = props;

  return (
    <>
      <Row
        id={`${parent_id}_list_parent_div`}
      // className={!props.signOff && "allergyListHover"}
      >
        <Col
          id={`${parent_id}_list_parent_col`}
          xs={12} sm={12} md={8} lg={8} xl={8}>
          {props?.treatment_order && (
            <H6
              id={`${parent_id}_list_title_h6`}
              inLineStyles={{
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                color: UIColor.secondaryButtonColor.color
              }}
            >
              <Text
                id={`${parent_id}_list_title_text`}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Div
                  id={`${parent_id}_list_${props?.treatment_order.replaceAll(" ", "_")}_div`}
                  inLineStyles={{ color: UIColor.secondaryButtonColor.color, fontSize: "14px", fontWeight: 500,fontFamily:"pc_regular"  }}>
                  {props?.treatment_order}  {props?.infoAlertText ?
                    <ErrorOutlineIcon onClick={(event) => setInfoText(event.currentTarget)} style={{ width: "17px", height: "14px", color: "#464242" }} />
                    : ''}
                  <Popover
                    open={(Boolean(infoText))}
                    anchorEl={infoText}
                    onClose={() => setInfoText(null)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <Typography style={{ padding: 7, background: "lightgrey", color: "black" }}> {props?.infoAlertText}</Typography>
                  </Popover>
                  {/* {props?.recurrentData?.endsAfter?.endsAfter > 0 ? ( */}
                  {(props?.data?.childRecurrent?.length > 0) && <span
                    id={`${parent_id}_list_icons_span`}
                  // onClick={() => hide(!show)}
                  >
                    <Icons
                      id={`${parent_id}_list_icons`}
                      fontIcon="exchange"
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                    {props?.data?.childRecurrent?.length ?? 0}
                    {/* {props?.recurrentData?.endsAfter?.endsAfter} */}
                  </span>}
                  {/* ) : null} */}
                </Div>
              </Text>
            </H6>
          )}
          {props?.priority && (
            <Text
              id={`${parent_id}_list_${props?.priority.replaceAll(" ", "_")}_text`}
              inLineStyles={styles.textColor1}>{props?.priority}</Text>
          )}
          {props?.nature && (
            <Text
              id={`${parent_id}_list_${props?.nature.replaceAll(" ", "_")}_text`}
              inLineStyles={styles.textColor}>
              {" - "}
              {props?.nature}
            </Text>
          )}
          {props?.dateTime && (
            <Text
              id={`${parent_id}_list_date-time_text`}
              inLineStyles={styles.textColor}>
              {" -  On "}
              {props?.dateTime}
            </Text>
          )}
          {status && (
            <Text
              id={`${parent_id}_list_${props?.status}_text`}
              onClick={(e) => {
                if (props?.val?.statushistory?.length > 1) {
                  props.handleOpenStatus && props.handleOpenStatus(e, props.val, props.IndexValue, "lab", statusVal)
                }
              }}
              inLineStyles={{
                ...styles.statusColor,
                color: statusColors[status]?.color,
                cursor: props?.val?.statushistory?.length > 1 ? "pointer" : "unset",
              }}
            >
              {" •"} {status}
            </Text>
          )}
          {/* {
            <Text inLineStyles={{ color: "#9b9b9b", margin: "0px 5px" }}>
              {" | "}
            </Text>
          } */}
          {/* {<Text inLineStyles={styles.textColor}>{"ID 385322"}</Text>}
          {<Text inLineStyles={styles.textColor}>{" - 24 May 12.30"}</Text>} */}
          {/* {<Text inLineStyles={styles.textColor}>{" - Order Pending "}</Text>} */}
        </Col>
        <Col
          id={`${parent_id}_list_On_col`}
          xs={12} sm={12} md={4} lg={4} xl={4}>
          <Div
            id={`${parent_id}_list_userInfoEdit_div`}
            // className={!props.signOff && "userInfoEdit"}
            className={"userInfoEdit"}
            style={{ right: open ? 10 : props.signOff ? -70 : -155 }}
          >
            <div
              id={`${parent_id}_list_userInfoEdit_sub_div`}
              style={styles.userInfo} className={"userHide"}>
              <Text
                id={`${parent_id}_list_${(JSON.stringify(props?.val?.createdAt))?.replaceAll(" ", "_") ?? ''}_text`}
                inLineStyles={styles.textColor} style={{ marginBottom: 8 }}>
                {"On "}
                {props?.val?.createdAt
                  ? utcTOLocal(props?.val?.createdAt, "DD MMM, YYYY")
                  : props.getData()}
              </Text>
              <Text
                id={`${parent_id}_list_${props?.info?.name.replaceAll(" ", "_")}_text`}
                inLineStyles={styles.textColor}>
                {/* {props?.val?.userName.includes(props?.info?.name)
                  ? props?.val?.userName + ' ' + "(You)"
                  : props?.val?.userName} */}
                {
                  getOrderName(props?.val, loggedUserInfo?.data?.completeName) ?? props?.val?.accessionNum ?? props?.val?.userName ?? (
                    props?.val?.requestorDetails?.practitionerId
                      ? makeName(props?.val?.requestorDetails?.practitionerId || {}) + " (You)"
                      : props?.val?.userName + " (You)"
                  )
                }
              </Text>
              {/* {((props.signOff) && (props?.data?.childRecurrent?.length > 0)) && <Text
                // aria-describedby={id}
                onClick={() => hide(!show)}
              // inLineStyles={
              //   open
              //     ? styles.userIcon
              //     : { ...styles.userIcon, backgroundColor: "transparent" }
              // }
              >
                {show ? <div style={{
                  borderRadius: '50%',
                  border: '1px solid #dadada',
                  width: '30px',
                  height: '30px',
                  padding: '2px',
                  marginLeft: '12px',
                  cursor: "pointer"
                }}><ExpandLessIcon /></div> : <div style={{
                  borderRadius: '50%',
                  border: '1px solid #dadada',
                  width: '30px',
                  height: '30px',
                  padding: '2px',
                  marginLeft: '12px',
                  cursor: "pointer"
                }}><ExpandMoreIcon /></div>}
              </Text>} */}

            </div>

            {((!props.signOff) && (props?.data?.childRecurrent?.length > 0)) && <Text
              // aria-describedby={id}
              onClick={() => hide(!show)}
            // inLineStyles={
            //   open
            //     ? styles.userIcon
            //     : { ...styles.userIcon, backgroundColor: "transparent" }
            // }
            >
              {show ? <div style={{
                borderRadius: '50%',
                border: '1px solid #dadada',
                width: '30px',
                height: '30px',
                padding: '2px',
                marginLeft: '12px',
                cursor: "pointer"
              }}><ExpandLessIcon /></div> : <div style={{
                borderRadius: '50%',
                border: '1px solid #dadada',
                width: '30px',
                height: '30px',
                padding: '2px',
                marginLeft: '12px',
                cursor: "pointer"
              }}><ExpandMoreIcon /></div>}
            </Text>}


            {((props.signOff) && (props?.data?.childRecurrent?.length > 0)) && <Text
              // aria-describedby={id}
              onClick={() => hide(!show)}
            // inLineStyles={
            //   open
            //     ? styles.userIcon
            //     : { ...styles.userIcon, backgroundColor: "transparent" }
            // }
            >
              {show ? <div style={{
                borderRadius: '50%',
                border: '1px solid #dadada',
                width: '30px',
                height: '30px',
                padding: '2px',
                marginLeft: '12px',
                cursor: "pointer"
              }}><ExpandLessIcon /></div> : <div style={{
                borderRadius: '50%',
                border: '1px solid #dadada',
                width: '30px',
                height: '30px',
                padding: '2px',
                marginLeft: '12px',
                cursor: "pointer"
              }}><ExpandMoreIcon /></div>}
            </Text>}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!props.signOff &&

                <Text
                  id={`${parent_id}_list_delete_text`}
                  inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                  onClick={() => handleModal(true)}
                >
                  {/* <Icons
                id={`${parent_id}_list_delete_icons`}
                fontIcon="trash" style={{ color: "red" }} /> */}
                  <Image
                    src={'images/icons8-trash_.svg'}
                    alt="delete_icon"
                  // style={{ width: 100, height: 35 }}
                  />
                </Text>}


              {((!props.signOff) && (!props?.data?.childRecurrent?.length > 0)) &&
                <Text
                  id={`${parent_id}_list_edit_text`}
                  inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                  onClick={() => {
                    props.editFun(props.IndexValue, null, null, props.data);
                    props.onEditChart(props?.data?.[0], 'lab_order_form');
                  }}>
                  {/* <Icons
                  id={`${parent_id}_list_edit_icons`}
                  fontIcon={"pencil"}
                  onClick={() => {
                    props.editFun(props.IndexValue, null, null, props.data);
                    props.onEditChart(props?.data?.[0], 'lab_order_form');
                  }}
                /> */}
                  <Image
                    src={'images/icons8-edit_.svg'}
                    alt="delete_icon"
                  // style={{ width: 100, height: 35 }}
                  />
                </Text>}
            </div>

            <div
              id={`${parent_id}_list_delete_edit_div`}
              style={{ display: "flex", alignItems: "center", marginLeft: 64 }}
            >
              {/* <Text
                // aria-describedby={id}
                onClick={() => hide(!show)}
                // inLineStyles={
                //   open
                //     ? styles.userIcon
                //     : { ...styles.userIcon, backgroundColor: "transparent" }
                // }
              >
                {show ? "▾" : "▴"}
              </Text> */}
              {/* {!props.signOff && (
                <>
                  <Div
                    id={`${parent_id}_list_delete_div`}
                    style={{
                      height: 20,
                      width: 1,
                      backgroundColor: "rgb(224, 224, 224)",
                    }}
                  />
                  <Text
                    id={`${parent_id}_list_delete_text`}
                    inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 10 }}
                    onClick={() => handleModal(true)}
                  >
                    <Icons
                      id={`${parent_id}_list_delete_icons`}
                      fontIcon="trash" style={{ color: "red" }} />
                  </Text>
                  <Text
                    id={`${parent_id}_list_edit_text`}
                    inLineStyles={{ ...styles.editAllergyIcon, marginLeft: 5 }}
                    onClick={() => {
                      props.editFun(props.IndexValue, null, null, props.data);
                      // props.editFun(props.IndexValue, null, props.data.editData, props.data);
                      props.onEditChart(props?.val, "lab_order_form");
                    }}
                  // onClick={() => handleEdit()}
                  >
                    <Icons
                      id={`${parent_id}_list_edit_icons`}
                      fontIcon={"pencil"} />
                  </Text>
                </>
              )} */}
            </div>
            <Modal
              id={`${parent_id}_list_parent_modal`}
              open={modal}
              onClose={() => handleModal(false)}
              width={250}
              inLineStyles={styles.modal}
            >
              <Card
                id={`${parent_id}_list_parent_card`}
                style={styles.modalCard}>
                <H6
                  id={`${parent_id}_list_remove_this_h6`}
                  className="pc_semibold" style={styles.modalHeading}>
                  Are you sure want to remove this?
                </H6>
                <H6
                  id={`${parent_id}_list_the_order_h6`}
                  className="pc_regular" style={styles.modalSubTitle}>
                  Some content about the Lab order
                </H6>
                <Button
                  id={`${parent_id}_list_Yes_remove_button`}
                  variant="contained"
                  fullWidth
                  onClick={async () => {
                    handleModal(false);
                    await props.deleteList(props.IndexValue, props.val);
                  }}
                  style={styles.containedButton}
                >
                  Yes remove
                </Button>
                <Button
                  id={`${parent_id}_list_No_button`}
                  variant="outlined"
                  fullWidth
                  onClick={() => handleModal(false)}
                  style={styles.outlinedButton}
                >
                  No
                </Button>
              </Card>
            </Modal>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              classes={{ paper: classes.muiPopovers }}
            >
              <RequestorDetails
                parent_id={'lab_orderlist'}
                requestorDetails={requestorDetails} />
            </Popover>
          </Div>
        </Col>
      </Row>
      {show && (
        <>
          <Divider
            id={`${parent_id}_list_bottom_divider`}
            style={{ margin: "5px 0px" }} />
          <div
            id={`${parent_id}_list_orderstatus_div`}
            style={{ overflowX: "auto", display: "flex", width: props.signOff ? "100%" : "65vw" }}>
            {/* {props?.recurrentData?.every?.value &&
              arr.map((v, i) => {
                return ( */}
            <OrderStatus
              recurrentDatas={props?.recurrentData}
              parent_id={'lab_orderlist'}
              date={props?.recurrentData.startsOn.dateTime}
              // time={"222"}
              data={props.data}
              IndexValue={props.IndexValue}
              editFun={props.editFun}
              onEditChart={props.onEditChart}
              Cedit={props.Cedit}
              updatefuncy={props.updatefuncy}
              id={"ID 03421"}
              status={"Order Pending"}
              signOff={props.signOff}
              saveLabOrders={props.saveLabOrders}
            />
            {/* );
              })} */}
          </div>
        </>
      )}
    </>
  );
};