import React from "react";
import { H6, Row } from "qdm-component-library";
import PatientStatusCard from "./patientStatusCard";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { getUserInfo, getUtcTime, permission } from "../../../utils";
const casbinjs = require("casbin.js");

function PatientStatus(props) {
  const DATA = [
    {
      title: "Assessment Pending",
      subtitle: "Need your authendication",
      count: "0",
      color: "#36A2EB",
      backgroundColor: "#F1FAFA",
      binderName: "PATIENTSTATUSASSESSMENTPENDING",
      selectorName: "patientStatusAssessmentPendin",
    },
    {
      title: "Assessment Completed",
      subtitle: "Patient waiting for doctor",
      count: "0",
      color: "#FF6384",
      backgroundColor: "#FFF3F5",
      binderName: "PATIENTSTATUSASSESSMENTCOMPLETED",
      selectorName: "patientStatusAssessmentComplete",
    },
    {
      title: "Consultation In-progress",
      subtitle: "Doctor consults the patient",
      count: "0",
      color: "#4BC0C0",
      backgroundColor: "#F1FAFA",
      binderName: "CONSULTATION_INPROGRESS",
      selectorName: "consultation_in_progress",
    },
    {
      title: "Consultation Completed",
      subtitle: "Patients needs your review",
      count: "0",
      color: "#FF9F40",
      backgroundColor: "#FFF7F0",
      binderName: "CONSULTATION_COMPLETED",
      selectorName: "consultation_completed",
    },
  ];

  const { parent_id } = props;

  const dispatch = useDispatch();

  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );

  React.useEffect(() => {
    getData();
  }, [props?.Permission]);

  const authorizer = new casbinjs.Authorizer("manual", permission);
  authorizer.setPermission(permission);

  const fetchUserInfo = async (email) => {
    if (!loggedUserInfo?.data?.orgId) {
      if (!email) {
        const info = getUserInfo();
        email = info.email;
      }
      const userData = await dispatch(
        actions.SEARCH_PRACTIONER({
          email,
        })
      );

      const info = {
        orgId: userData?.payload?.data?.[0]?.org_id,
        name: {
          given: userData?.payload?.data?.[0]?.name?.[0]?.given,
          family: userData?.payload?.data?.[0]?.name?.[0]?.family,
          text: userData?.payload?.data?.[0]?.name?.[0]?.text,
        },
        personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
        org_id: userData?.payload?.data?.[0]?.org_id,
      };

      await dispatch(actions.LOGGED_USER_INFO(info));
      return info;
    }
    return loggedUserInfo?.data;
  };

  const getData = async () => {
    const userData = await fetchUserInfo();
    const list = props?.Permission?.write ?? [];
    let roleId = list.indexOf("PatientStatusD") > -1 ? 1 : list.indexOf("PatientStatusN") > -1 ? 2 : 3
    let payload = {
      orgId: userData?.org_id,
      startDate: getUtcTime(moment().startOf("day")),
      endDate:getUtcTime(moment().endOf("day")),
      roleId: roleId,
      loginId: userData?.personId,
    };

    const promArr = DATA.filter((d) => !!d.binderName).map((d) => {
      return dispatch(actions[d.binderName](payload));
    });
    let data = await Promise.all(promArr);
   
  };

  // const getData = async () => {
  //   const userData = await fetchUserInfo();
  //   ;
  //   let payload = {
  //     orgId: userData?.orgId || 0,
  //     startDate: moment().startOf("day").unix(),
  //     endDate: moment().endOf("day").unix(),
  //     roleId: userData?.role,
  //     loginId: userData?.personId,
  //   };
  //   ;
  //   let data = await dispatch(actions.PATIENTSTATUSINFO({payload}));
  //   let data_ = data.payload.data;
  //   const assPendingCount = data_?.CheckedIn;
  //   const assCompletedCount = data_?.Triage;
  //   alert(assPendingCount)
  //   alert(assCompletedCount)
  //   ;
  // };

  return (
    <div
      id={`${parent_id}_layout_pendingtask_parent_div`}
      style={{ height: "100%", padding: "10px 0px", paddingLeft: "10px" }}
    >
      <H6
        id={`${parent_id}_layout_pendingtask_h6`}
        className="pc_medium"
        style={{ marginBottom: 10, fontSize: 14 }}
      >
        Patient Status{" "}
      </H6>
      <Row id={`${parent_id}_layout_pendingtask_row`}>
        {DATA &&
          DATA?.map((task, i) => {
            return (
              <div
                id={`${parent_id + "_layout"}_${i}_pendingtask_div`}
                style={{ minWidth: "100%" }}
              >
                <PatientStatusCard
                  parent_id={`dashboard_layout_${i}`}
                  title={task?.title}
                  subTitle={task?.subtitle}
                  count={task?.count}
                  color={task?.color}
                  backgroundColor={task?.backgroundColor}
                  selectorName={task?.selectorName}
                />
              </div>
            );
          })}
      </Row>
    </div>
  );
}

export default PatientStatus;
