import React from "react";
import {
  makeStyles,
  TextField,
  Typography,
  Avatar,
  Grid,
  ListItem,
  List,
  Collapse,
} from "@material-ui/core";
import { Div, Button, Text, H6 } from "qdm-component-library";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { ActionItems } from "../actionItems";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DescriptionIcon from "@material-ui/icons/Description";
import { UIColor } from "../../../utils";

const useStyles = makeStyles({
  root: {
    padding: 10,
    width: "700px",
  },
  main_div: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    margin: "0px 14px",
  },
  main_div_snd: {
    display: "flex",
    justifyContent: "space-between",
    margin: "14px",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    alignItems: "center",
    paddingBottom: 18,
  },
  title: {
    fontSize: "14px !important",
    color: UIColor.secondaryText.color,
    fontWeight: "600",
    width: "max-content",
  },
  width: {
    width: "100%",
  },
  leftavatar: {
    cursor: "pointer",
    padding: "11px",
    background: UIColor.primaryColor.color,
    marginTop: "20px",
    width: "30px",
    height: "30px",
    marginLeft: 10,
  },
  leftavatar_scnd: {
    cursor: "pointer",
    padding: "11px",
    background: UIColor.greyBackground.color,
    color: UIColor.secondaryText.color,
    marginTop: "20px",
    width: "30px",
    height: "30px",
    marginLeft: 10,
  },
  autocomplete: {
    marginTop: 6,
    "& .MuiInputBase-input": {
      color: UIColor.secondaryText.color,
      fontSize: "14px !important",
      fontFamily: "pc_regular!important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: UIColor.lineBorderFill.color,
        borderRadius: 8,
      },
      "&:hover fieldset": {
        borderColor: UIColor.lineBorderFill.color,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 1,
        borderColor: UIColor.lineBorderFill.color,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      transform: "none !important",
    },
  },
  avatar: {
    cursor: "pointer",
    padding: "11px",
    background: UIColor.primaryColor.color,
    marginTop: "4px",
    width: "30px",
    height: "30px",
    marginLeft: 10,
  },
  border: {
    borderRight: `1px solid ${UIColor.lineBorderFill.color}`,
  },
  doc: {
    "& img": {
      objectFit: "none",
    },
  },
  isactive: {
    color: UIColor.primaryColor.color,
    background: `${UIColor.primaryColor.color}26`,
  },
  span: {
    fontSize: "11px !important",
    color: UIColor.secondaryText.color,
  },
  text: {
    fontSize: "14px !important",
    color: UIColor.primaryColor.color,
    marginLeft: "0px !important",
  },
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    cursor: "pointer",
  },
  cardView: {
    // backgroundColor: '#F0F0F0',
    // border: '1px solid #E0E0E0',
    borderRadius: 21,
    padding: "8px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  avatar__: {
    width: 34,
    height: 34,
    borderRadius: "10%",
    margin: 0,
    marginLeft: 5,
    marginRight: 10,
  },
});

export const DoccModel = (props) => {
  const classes = useStyles();

  const [openhead, setopen] = React.useState(false);

  const [filter, setfilter] = React.useState(true);

  const color = ["#d63d5d", "#a417d0"];

  const background = ["#f4748f8a", "#d674f499"];

  const {
    handleInputChange,
    parent_id,
    stateDoc,
    handleSelectList,
    handleSelectListchild,
    data,
  } = props;

  React.useEffect(() => {
    if(stateDoc.parentIndex === null){
    handleSelectList(0, "parentIndex");
    }
  }, []);

  React.useEffect(() => {
    if (stateDoc.parentIndex === 0 && data?.[0]?.child?.length > 0) {
      handleSelectListchild( data?.[0]?.child?.[0]?.user, "userdetails", 0, "childIndex");
    }
  }, [stateDoc.parentIndex,data?.[0]?.child?.[0]?.title]);

  const handlehead = (name) => {
    setopen(!openhead);
    if (name === "done") {
      props.saveDoc();
    }
  };

  const handlefilter = () => {
    setfilter(!filter);
  };
  console.log("stateDocModel", stateDoc);
  console.log("dataToModal", data);
  console.log("props?.cat",props?.category);
  return (
    <div className={classes.root}>
      <Div className={classes.main_div}>
        <Typography
          variant="h6"
          style={{
            fontSize: 16,
            color: UIColor.primaryText.color,
          }}
        >
          Document
        </Typography>
        <Button
          variant="text"
          style={{
            color: !openhead
              ? UIColor.primaryColor.color
              : UIColor.tertiaryText.color,
            fontWeight: 600,
            fontSize: 13,
          }}
          onClick={() => !openhead && handlehead()}
        >
          + Add
        </Button>
      </Div>
      {openhead && (
        <Div className={classes.main_div_snd}>
          <Div className={classes.width}>
            <Typography className={classes.title}>Select Category</Typography>
            <Autocomplete
              id={`${parent_id}_docc_autocomplete`}
              className={classes.autocomplete}
              size="small"
              options={props.category ?? []}
              disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Category"
                  style={{ width: "98%", fontSize: 14 }}
                />
              )}
              renderOption={(option) => (
                <Typography
                  className="pc_regular"
                  style={{
                    width: "98%",
                    fontSize: 14,
                    color: UIColor.primaryText.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              classes={{ popper: "muiPopperDropdown" }}
              value={stateDoc.selectCatagory}
              onChange={(e, newValue) =>
                handleInputChange(newValue, "selectCatagory")
              }
            />
          </Div>
          <Div className={classes.width}>
            <Typography className={classes.title}>Select Document</Typography>
            <Autocomplete
              id={`${parent_id}_docc_autocomplete`}
              className={classes.autocomplete}
              size="small"
              options={props.document ?? []}
              disableClearable
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Document"
                  style={{ width: "98%", fontSize: 14 }}
                />
              )}
              renderOption={(option) => (
                <Typography
                  className="pc_regular"
                  style={{
                    width: "98%",
                    fontSize: 14,
                    color: UIColor.primaryText.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              classes={{ popper: "muiPopperDropdown" }}
              value={stateDoc.selectDoccument}
              onChange={(e, newValue) =>
                handleInputChange(newValue, "selectDoccument")
              }
            />
          </Div>
          <Avatar
            className={classes.leftavatar}
            onClick={() => handlehead("done")}
          >
            <DoneIcon />
          </Avatar>
          <Avatar
            className={classes.leftavatar_scnd}
            onClick={() => handlehead()}
          >
            <CloseIcon />
          </Avatar>
        </Div>
      )}
      <Div className={classes.main_div_snd}>
        <Autocomplete
          className={classes.autocomplete}
          size="small"
          options={props.category ?? []}
          disableClearable
          style={{ width: "100%" }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Category"
              style={{ width: "98%", fontSize: 14 }}
            />
          )}
          renderOption={(option) => (
            <Typography
              className="pc_regular"
              style={{
                width: "98%",
                fontSize: 14,
                color: UIColor.primaryText.color,
              }}
              noWrap
            >
              {option.label}
            </Typography>
          )}
          classes={{ popper: "muiPopperDropdown" }}
          value={stateDoc.selectFilterCategory}
          onChange={(e, newValue) =>
            handleInputChange(newValue, "selectFilterCategory")
          }
        />
        {filter && !props.loading ? (
          <>
            <Autocomplete
              className={classes.autocomplete}
              size="small"
              options={props.speciality ?? []}
              disableClearable
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Specialty"
                  style={{ width: "98%", fontSize: 14 }}
                />
              )}
              renderOption={(option) => (
                <Typography
                  className="pc_regular"
                  style={{
                    width: "98%",
                    fontSize: 14,
                    color: UIColor.primaryText.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              classes={{ popper: "muiPopperDropdown" }}
              value={stateDoc.selectSpeciality}
              onChange={(e, newValue) =>
                handleInputChange(newValue, "selectSpeciality")
              }
            />

            <Autocomplete
              className={classes.autocomplete}
              size="small"
              options={props.encounter ?? []}
              disableClearable
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Encounter"
                  style={{ width: "98%", fontSize: 14 }}
                />
              )}
              renderOption={(option) => (
                <Typography
                  className="pc_regular"
                  style={{
                    width: "98%",
                    fontSize: 14,
                    color: UIColor.primaryText.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              classes={{ popper: "muiPopperDropdown" }}
              value={stateDoc.selectEncoumter}
              onChange={(e, newValue) =>
                handleInputChange(newValue, "selectEncoumter")
              }
            />

            <Autocomplete
              className={classes.autocomplete}
              size="small"
              options={props.practitioner ?? []}
              disableClearable
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Practitioner"
                  style={{ width: "98%", fontSize: 14 }}
                />
              )}
              renderOption={(option) => (
                <Typography
                  className="pc_regular"
                  style={{
                    width: "98%",
                    fontSize: 14,
                    color: UIColor.primaryText.color,
                  }}
                  noWrap
                >
                  {option.label}
                </Typography>
              )}
              classes={{ popper: "muiPopperDropdown" }}
              value={stateDoc.selectPractitioner}
              onChange={(e, newValue) =>
                handleInputChange(newValue, "selectPractitioner")
              }
            />
          </>
        ) : null}
        <Avatar
          src="images/icons8-funnel@2x.png"
          className={classes.avatar}
          style={{
            background: filter
              ? UIColor.primaryColor.color
              : UIColor.greyBackground.color,
            color: !filter && "#000",
          }}
          onClick={() => handlefilter()}
        />
      </Div>
      <Grid container spacing={2} style={{ height: "236px" }}>
        <Grid item xs={4} className={classes.border}>
          {data?.map((val, index) => {
            return (
              <List
                // style={{ padding: 0 }}
                style={{
                  borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
                  paddingBottom: 8,
                }}
              >
                <ListItem
                  button
                  onClick={() => handleSelectList(index, "parentIndex")}
                >
                  {index === stateDoc.parentIndex ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowRightIcon />
                  )}
                  <Typography style={{ fontSize: 15, fontWeight: 600 }}>
                    {val?.title}
                  </Typography>
                  <Avatar
                    style={{
                      background: background[index],
                      color: color[index],
                      fontSize: 14,
                      width: 24,
                      height: 24,
                      marginLeft: 6,
                    }}
                  >
                    {val?.count}
                  </Avatar>
                </ListItem>
                <Collapse
                  in={index === stateDoc.parentIndex}
                  timeout="auto"
                  unmountOnExit
                >
                  {val?.child.map((sub, i) => {
                    return (
                      <List
                        component="div"
                        disablePadding
                        onClick={() =>
                          handleSelectListchild(
                            sub?.user,
                            "userdetails",
                            i,
                            "childIndex"
                          )
                        }
                        style={{ padding: 0 }}
                      >
                        <ListItem
                          button
                          // className={((stateDoc.childIndex === i) && (stateDoc.parentIndex === index)) && classes.isactive}
                          style={{
                            padding: "6px 16px",
                            color:
                              stateDoc.childIndex === i &&
                              stateDoc.parentIndex === index &&
                              UIColor.primaryColor.color,
                            background:
                              stateDoc.childIndex === i &&
                              stateDoc.parentIndex === index &&
                              `${UIColor.primaryColor.color}26`,
                          }}
                        >
                          <DescriptionIcon
                            className={classes.doc}
                            style={{
                              color:
                                stateDoc.childIndex === i &&
                                stateDoc.parentIndex === index
                                  ? UIColor.primaryColor.color
                                  : UIColor.primaryText.color,
                            }}
                          />
                          <Typography style={{ fontSize: 13 }}>
                            {sub?.title}
                          </Typography>
                        </ListItem>
                      </List>
                    );
                  })}
                </Collapse>
              </List>
            );
          })}
        </Grid>

        <Grid item xs={8}>
          {stateDoc?.userdetails?.length > 0 ? (
            <Div style={{ display: "flex", flexWrap: "wrap" }}>
              {stateDoc?.userdetails?.map((v) => {
                return (
                  <Div
                    onClick={() => props.handleEdit(v)}
                    className={classes.cardView}
                  >
                    <Div>
                      <Avatar
                        variant="square"
                        letter="Avatar"
                        src={
                          "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg"
                        }
                        alt="Image"
                        className={classes.avatar__}
                      />
                    </Div>
                    <Div className={classes.flexEnd}>
                      <Text>
                        <Text className={classes.span}>{v?.title}</Text>
                        <Text className={classes.span}>
                          &nbsp;{"•"}&nbsp;{v?.date}
                        </Text>
                      </Text>
                      <H6 className={classes.text}>{v?.name}</H6>
                    </Div>
                  </Div>
                );
              })}
            </Div>
          ) : (
            <ActionItems text="No Document Selected" showTitle />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
