import React from "react";
import {
  Div,
  Button,
  H6,
  TapSelect,
  TextInput,
} from "qdm-component-library";
import { DetailCard } from "./card";
import { Chip, Grid, TextField } from "@material-ui/core";
import { SelectBox } from "../../../components";
import PractitionerCard from "./profileCard";
import PatientDeatils from "./patientDeatils";
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { getImgUrl } from "../../../utils/helperFunctions";
import { AlertProps, UIColor, makeName, getUtcTime } from "../../../utils";
import { useSelector } from "react-redux";
import moment from "moment";
import { withAllContexts } from "../../../HOCs";
//import FollowupReferral from "./followupReferral";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { Routes } from "../../../router/routes";
import { useHistory } from "react-router-dom";
import CustomInputDatePicker from "../../../components/customDatePicker/inputDatePicker";
const useStyles = makeStyles((theme) => ({
  datePadding: {
    "& .MuiOutlinedInput-input": {
      padding: "9.5px 14px",
      width: "275px",
    },
  },
  root: {
    "& .MuiGrid-spacing-xs-2": {
      "& .MuiGrid-item": {
        padding: "5px",
      },
    },
  },
  chip: {
    backgroundColor: "#fff",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    minWidth: "auto",
    marginRight: "7px",
    padding: "4px",
  },
  selectedchip: {
    backgroundColor: "#0271F2",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    minWidth: "auto",
    marginRight: "7px",
    padding: "4px",
    color: "#fff",
  },
}));

// const practitionerData = [
//   {
//     profile_pic:
//       "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
//     title: "Partrick Stewart",
//     subTitle: "Patient Assigned",
//     count: "01",
//     id: 1,
//     appointmentCount: "01",
//   },
//   {
//     profile_pic:
//       "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
//     title: "Randy Sullivan",
//     subTitle: "Patient Assigned",
//     count: "00",
//     id: 2,
//     appointmentCount: "00",
//   },
//   {
//     profile_pic:
//       "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
//     title: "Nicolas Haffman",
//     subTitle: "Patient Assigned",
//     count: "01",
//     id: 3,
//     appointmentCount: "02",
//   },
// ];
function AssignReassign(props) {
  const classes = useStyles();
  const history = useHistory();
  const { data: useData } = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );
  const Practitioner = useSelector(
    (state) => state?.personApiSlice?.searchPractioner.data?.[0]
  );
  const {
    parent_id,
    practitionerRole,
    practitionerType,
    encounter_id,
    dispositionData,
    followupUOMData,
    reasonData,
  } = props;

  const dispatch = useDispatch();
  const styles = {
    colPadding: {
      padding: 7,
    },

    usYVQ: {
      marginBottom: "13px",
      boxShadow: "none",
      border: "1px solid #d5d5d5",
      height: "40px",
    },
    wrapper: {
      padding: "0px 20px 20px 20px",
      overflow: "auto",
    },
    book_app_title_: {
      fontSize: 12,
      textAlign: "left",
      letterSpacing: "0px",
      color: "#0071F2",
      fontWeight: 400,
      display: "flex",
      justifyContent: "center",
      paddingTop: "8px",
    },
    title_: {
      fontSize: 12,
      textAlign: "left",
      //font: "normal normal normal 12px/18px Poppins",
      letterSpacing: "0px",
      color: "#6F6F6F",
      opacity: 1,
      fontWeight: 400,
    },
    title: {
      fontSize: 16,
      color: UIColor.primaryText.color,
      font: "normal normal 600 14px/12px Poppins",
      fontWeight: "bold",
      margin: "20px 0px",
      marginBottom: 5,
    },
    tapSelectStyle: {
      padding: "4px 8px",
      //borderColor: "#E0E0E0",
      // margin: "4px 10px 6px 0px",
      borderRadius: "8px",
      minWidth: "auto",
      margin: "0px 6px 6px 10px",
    },
    content_title: {
      fontSize: 12,
      color: UIColor.primaryText.color,
      fontWeight: "bold",
      margin: 0,
      marginBottom: 5,
      font: "normal normal 600 12px/20px Poppins",
    },
    h6: {
      fontSize: 12,
      color: "#36A2EB",
      margin: 0,
      textTransform: "uppercase",
    },
    h5: {
      fontSize: 12,
      color: "#6F6F6F",
      display: "block",
      margin: 0,
      marginBottom: 10,
    },
    blackSpan: {
      color: UIColor.primaryText.color,
      fontSize: 10,
    },
    span: {
      color: "#6F6F6F",
      fontSize: 10,
    },
    content: {
      marginBottom: 20,
    },
    specialityWrap: {
      border: "0.5px solid #E0E0E0",
      borderRadius: 8,
      padding: 5,
      display: "flex",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    icons: {
      marginRight: 10,
      color: "#36A2EB",
    },
    docImage: {
      height: "100%",
      minHeight: "260px",
      borderRadius: "0px 0px 8px 8px",
      boxShadow: "0px 1px 10px #00000029",
      overflow: "hidden",
    },
    dashBorder: {
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    text: {
      fontSize: "0.75rem",
      fontFamily: "Roboto",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
    },
    appointmentDeatailsDot: {
      left: "325px",
      width: "482px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    patientDeatailsDot: {
      left: "325px",
      width: "195px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    visitDetailDot: {
      //top: "187px",
      left: "325px",
      width: "536px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    toPractitionerDot: {
      //top: "187px",
      marginLeft: "10px",
      left: "325px",
      width: "836px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    encounterCloseTimeDot: {
      //top: "187px",
      marginLeft: "10px",
      left: "325px",
      width: "300px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    dispositionDot: {
      //top: "187px",
      marginLeft: "10px",
      left: "325px",
      width: "370px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    followDot: {
      //top: "187px",
      marginLeft: "10px",
      left: "325px",
      width: "715px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    fromPractitionerDot: {
      //top: "187px",
      marginLeft: "10px",
      left: "325px",
      width: "820px",
      height: "0px",
      border: "1px dashed #E0E0E0",
      opacity: 1,
    },
    subHeader: {
      marginLeft: 0,
      fontSize: "12px",
      fontWeight: 600,
      display: "inline-block",
      color: UIColor.primaryText.color,
      fontFamily: "'pc_semibold' !important",
    },
    header: {
      margin: "14px 0px",
      fontSize: "14px",
      fontWeight: 600,
      display: "inline-block",
      color: UIColor.primaryText.color,
      fontFamily: "'pc_semibold' !important",
    },
    cancelButton: {
      width: "87px",
      height: "36px",
      border: `1px solid ${UIColor.greyBackground.color}`,
      borderRadius: "8px",
      opacity: 1,
      color: "#323232",
      background: UIColor.greyBackground.color,
      marginRight: "10px",
    },
  };

  const [state, setState] = React.useState({
    Practitioner_id: null,
    PractitionerRole_id: null,
    pract_id: null,
    pract_role_id: null,
    practitionerData_: [],
    // dateTime: moment(new Date()).format("DD MMM,YYYY hh:mm A"),
    dateTime: null,
    dispositionType: { value: "", label: "" },
    followup: false,
    followup_number: null,
    followupType: { value: "", label: "" },
    reason: { value: "", label: "" },
    referralOutRequired: false,
    disable: false,
  });

  const [practitionerType_, setPractitionerType] = React.useState(null);
  const [practitionerRole_, setPractitionerRole] = React.useState(null);

  const handleselectChange = (name, val) => {
    if (name === "practitionerType") {
      setPractitionerType(val);
    } else if (name === "practitionerRole") {
      setPractitionerRole(val);
    }
  };
  const handleselectChange_ = (name, val) => {
    setState({
      ...state,
      [name]: val,
    });
  };
  const changeState = (key, value) => {
    if (key === "dateTime") {
      setState({
        ...state,
        [key]: moment(value).format("DD MMM,YYYY hh:mm A"),
      });
    } else {
      setState({
        ...state,
        [key]: value,
      });
    }
  };
  const handleInputChange = (name, e, limit) => {
    debugger
    if(name === "followup_number"){
      if(e === "0"){
        setState({
          ...state,
          "followup_number" : "",
        });
        return;
      }
    }
    if(limit){
        if (limit < e.length) {
            return;
        }
    }
    setState({
      ...state,
      [name]: e,
    });
  };
  const tabHandle = (name, val) => {
    setState({
      ...state,
      [name]: val,
    });
  };

  const handleChange = (name, event) => {
    if (event.target.checked) {
      setState({
        ...state,
        [name]: true,
      });
    } else {
      setState({
        ...state,
        [name]: false,
      });
    }
  };

  const handleClick = (v) => {
    if (state.Practitioner_id !== v?.Practitioner_id) {
      setState({
        ...state,
        Practitioner_id: v?.Practitioner_id,
        PractitionerRole_id: v?.PractitionerRole_id,
        pract_id: v?.pract_id,
        pract_role_id: v?.pract_role_id,
        name: v?.name
      });
    } else {
      setState({
        ...state,
        Practitioner_id: null,
        PractitionerRole_id: null,
        pract_id: null,
        pract_role_id: null,
        name: null
      });
    }
  };

  const confirm = async () => {
    if (
      (state?.Practitioner_id && state?.PractitionerRole_id) ||
      props?.type === "close"
    ) {
      if (props?.type === "assign") {
        let resData = {
          _key: encounter_id,
          type: ["CodingMaster/12338"],
          period: "",
          Practitioner_id: state.Practitioner_id,
          PractitionerRole_id: state.PractitionerRole_id,

          participantType: "CodingMaster/12338",
          practroleId: props?.assignData?.practroleId ?? "",
          practitionerId: props?.assignData?.practitionerId ?? "",
          specialty: props?.assignData?.specialtyId ?? "",
          userId: useData?.user_id,
          startTime: moment().unix(),
          endTime: 0,
          reasonCode: "",
        };
        const data_ = await dispatch(
          actions.ASSIGN_PRACTITIONER_UPDATE({
            data: resData,
          })
        );
        const data__ = await dispatch(
          actions.UPSERT_APPOINTMENT({
            appID: props?.appID ?? "", resourceCode: state?.pract_id ?? "", practId: state.Practitioner_id ?? ""
          })
        );

        if (data_?.payload?.data?.Code === 201 && data__?.payload?.data?.Code === 201) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Practitioner assigned successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          //window.location.reload(false)
          props.onModalClose();
        } else {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      } else if (props?.type === "reassign") {
        //CONSTRUCT PARTICIPANT HISTORY ARRAY
        let participantHistoryArray = JSON.parse(
          JSON.stringify(props?.assignData?.participantHistory)
        );

        if (participantHistoryArray.length > 0) {
          participantHistoryArray[
            props?.assignData?.participantHistory.length - 1
          ].endTime = moment().unix();
        }
        let obj = {
          participantType: "CodingMaster/12338",
          practroleId: state?.pract_role_id ?? "",
          practitionerId: state?.pract_id ?? "",
          specialty: props?.assignData?.specialtyId ?? "",
          relatedPersonId: "",
          relationship: "",
          userId: useData?.user_id,
          startTime: moment().unix(),
          endTime: 0,
          reasonCode: "",
        };
        participantHistoryArray.push(obj);

        let resData_ = {
          _key: encounter_id,
          type: ["CodingMaster/12338"],
          period: "",
          Practitioner_id: state.Practitioner_id,
          PractitionerRole_id: state.PractitionerRole_id,

          //PARTICIPANT HISTORY
          participantHistory: participantHistoryArray ?? [],
          "practitioner_details": [
            {
              "id_name": state?.name ?? ""
            }
          ]
        };
        const data_ = await dispatch(
          actions.REASSIGN_PRACTITIONER_UPDATE({
            data: resData_,
          })
        );

        const data__ = await dispatch(
          actions.UPSERT_APPOINTMENT({
            appID: props?.appID ?? "", resourceCode: state?.pract_id ?? "", practId: state.Practitioner_id ?? ""
          })
        );
        debugger
        if (data_?.payload?.data?.Code === 201 && data__?.payload?.data?.Code === 201) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Practitioner re-assigned successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
          //window.location.reload(false)
          props.onModalClose();
        } else {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      } else if (props?.type === "close") {
        //CONSTRUCT PARTICIPANT HISTORY ARRAY
        let participantHistoryArray = JSON.parse(
          JSON.stringify(props?.assignData?.participantHistory)
        );

        if (participantHistoryArray.length > 0) {
          participantHistoryArray[
            props?.assignData?.participantHistory.length - 1
          ].end = moment().unix();
        }
        let obj = {
          id: 0,
          start: moment().unix(),
          end: 0,
        };
        participantHistoryArray.push(obj);
        //REQUEST DATA
        let resData = {
          _key: encounter_id,
          "hospitalization": [
            {
            "preAdmissionIdentifier": "",
            "origin": "",
            "admitSource": "",
            "reAdmission": "",
            "dietPreference": [],
            "specialCourtesy": [],
            "specialArrangement": [],
            "destination": "",
            "dischargeDisposition": state.dispositionType?.value
            }
          ],
          // dischargeDisposition: state.dispositionType?.value,
          followupDur: state.followup_number,
          followupUOM: state.followupType?.value,
          reasonCode: state.reason?.value,
          followupReqd: state.followup,
          encounterenddate: state.dateTime ? moment(state.dateTime).format("DD MMM,YYYY hh:mm A") : moment(new Date()).format("DD MMM,YYYY hh:mm A"),
          referralOutRequired: state.referralOutRequired,
          status: "finished",
          statushistory: props?.assignData?.statushistory ?? [],
          period: participantHistoryArray ?? [],
          period_ : [
            {
            id: 0,
            start: moment().unix(),
            end: 0,
          }
        ]
        };
        if (state?.dispositionType.value.length > 0) {
          const data_ = await dispatch(
            actions.CLOSE_CONSULTATION_UPDATE({
              data: resData,
            })
          );
          //props.fetQueData();
          if (state?.followup && !state?.disable) {
            if (
              state?.followup_number &&
              state?.followupType?.label &&
              state?.reason?.label
            ) {
              await dispatch(
                actions.SET_FOLLOWUP_SERVICE({
                  data: {
                    date: moment().unix(),
                    status: "pending",
                    encounterId: props?.assignData?.encounterId ?? "",
                    practitionerId: Practitioner?.practitioner?.[0]?._id
                      ? Practitioner?.practitioner?.[0]?._id
                      : "",
                    orgId: Practitioner?.OrgID
                      ? JSON.stringify(Practitioner?.OrgID)
                      : "",
                    patientId: props?.assignData?.patientId ?? "",
                    editId:
                      props.assignData?.Followupdetails?.length > 0
                        ? props.assignData?.Followupdetails[0]._id
                        : "",
                    followup_number: state?.followup_number
                      ? parseInt(state?.followup_number)
                      : 0,
                    followup_type: state?.followupType?.value ?? "",
                    followup_reason: state?.reason?.value ?? "",
                    appointmentId:
                      JSON.stringify(props?.assignData?.appointmentId) ?? "",
                  },
                })
              );
            } else {
              if (!state?.followup_number) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please enter followup after",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
              } else if (!state?.followupType?.label) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please select followup type",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
              } else if (!state?.reason?.value) {
                props.alert.setSnack({
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please select reason",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
              }
            }
          }

          if (data_?.payload?.data?.Code === 201) {
            props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.success,
              msg: "Close Consultation successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
            props.onModalClose();
          } else {
            props.alert.setSnack({
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.right,
              tone: true,
            });
          }
        } else {
          setErr(true);
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please fill all the mandatory fields",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
      }
    } else {
      setErr(true);
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all the mandatory fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };
  const [err, setErr] = React.useState(false);

  const getPractitionerList = async () => {
    const data_ = await dispatch(
      actions.ASSIGN_PRACTITIONER_LIST({
        type: practitionerType_?.value,
        role: practitionerRole_?.value,
        type_: props?.type ?? "",
        start: moment().startOf("day").unix(),
        end: moment().endOf("day").unix(),
      })
    );

    setState({ ...state, practitionerData_: data_?.payload?.data ?? [] });
  };

  React.useEffect(() => {
    if (practitionerType_?.value && practitionerRole_?.value) {
      getPractitionerList();
    } else {
      setState({ ...state, practitionerData_: [] });
    }
  }, [practitionerType_, practitionerRole_]);

  React.useEffect(() => {
    if (props.assignData?.Followupdetails?.length > 0) {
      let followUpDetails = props?.assignData?.Followupdetails?.[0];
      setState({
        ...state,
        followup: true,
        followup_number: followUpDetails?.followupdurval,
        followupType: {
          value: followUpDetails?.followupduruom?._id,
          label: followUpDetails?.followupduruom?.display,
        },
        reason: {
          value: followUpDetails?.followupreason?._id,
          label: followUpDetails?.followupreason?.display,
        },
        disable: true,
      });
    }
  }, []);

  const fetchAppInfo = async (id) => {
    const data = await dispatch(
      actions.APPOINTMENT_READ({ appointmentId: id })
    );
    return data?.payload?.data || data?.AppointmentNotification || {};
  };

  const followUpInDays_ = () => {
    if (state?.followup) {
      let followUpCount = state?.followup_number;
      let followupType = state?.followupType;
      if (followupType.label === "Days") {
        return moment().startOf("day").add(followUpCount, "days");
      } else if (followupType.label === "Weeks") {
        return moment().startOf("day").add(followUpCount, "weeks");
      } else if (followupType.label === "Months") {
        return moment().startOf("day").add(followUpCount, "months");
      }
    } else {
      return moment();
    }
  };

  const followUPUpdate = async () => {
    if (state?.followup && !state?.disable) {
      if (
        state?.followup_number &&
        state?.followupType?.label &&
        state?.reason?.label
      ) {
        await dispatch(
          actions.SET_FOLLOWUP_SERVICE({
            data: {
              date: moment().unix(),
              status: "pending",
              encounterId: props?.assignData?.encounterId ?? "",
              practitionerId: Practitioner?.practitioner?.[0]?._id
                ? Practitioner?.practitioner?.[0]?._id
                : "",
              orgId: Practitioner?.OrgID
                ? JSON.stringify(Practitioner?.OrgID)
                : "",
              patientId: props?.assignData?.patientId ?? "",
              editId:
                props.assignData?.Followupdetails?.length > 0
                  ? props.assignData?.Followupdetails[0]._id
                  : "",
              followup_number: state?.followup_number
                ? parseInt(state?.followup_number)
                : 0,
              followup_type: state?.followupType?.value ?? "",
              followup_reason: state?.reason?.value ?? "",
              appointmentId:
                JSON.stringify(props?.assignData?.appointmentId) ?? "",
            },
          })
        );
        return true;
      } else {
        if (!state?.followup_number) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please enter followup after",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else if (!state?.followupType?.label) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please select followup type",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        } else if (!state?.reason?.value) {
          props.alert.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: "Please select reason",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.right,
            tone: true,
          });
        }
        return false;
      }
    }
  };
  const bookAppointment = async () => {
    debugger
    if (state?.followup) {
      let followUP;
      if (props.assignData?.Followupdetails?.length === 0) {
        followUP = await followUPUpdate();
      } else {
        followUP = true;
      }
      if (followUP) {
        const followUpInDays = followUpInDays_();
        const mobileNumber = props?.assignData?.patientMobileNumber ?? null;
        const persondata = await dispatch(
          actions.READ_PERSON({ phone: mobileNumber })
        );
        const appId = props?.assignData?.appointmentId
          ? props?.assignData?.appointmentId
          : undefined;
        const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(appId)));
        appInfo["HealthcareService"] = appInfo["healthservice"];
        appInfo["Slots"] = [
          {
            start: followUpInDays.unix(),
          },
        ];
        delete appInfo["healthservice"];

        let mobNoo = "";
        let emaill = "";
        if(Array.isArray(appInfo?.Person?.[0]?.telecom)  && appInfo?.Person?.[0]?.telecom.length > 0) {
          appInfo.Person[0].telecom.map(val => {
            if(val?.system === "Email"){
              emaill = val?.value;
            } else if(val?.system === "Phone"){
              mobNoo = val?.value;
            }
          })
        }
        debugger
        history.push({
          pathname: Routes.doctorListing,
          state: {
            mobNoo: mobNoo,
            emaill: emaill,
            appointmentId: appId,
            patientId: props?.assignData?.patientId,
            userId: persondata?.payload?.data?.id,
            cityName: 0,
            location: {
              address: "Chennai, Tamil Nadu, India",
              latitude: 13.0826802,
              longitude: 80.2707184,
              city: "Chennai",
              location: "Chennai",
            },
            hospital: {
              label: "",
              value: appInfo?.orgid?.id || 0,
            },
            doctors: [],
            specialities: Array.isArray(appInfo?.SpecialtyID) ? appInfo?.SpecialtyID : [],
            symptoms: [
              {
                label: "Fever",
                type: "Symptom",
                value: "SymptomsMapping/10000",
              },
            ],
            selectedUser: {
              //name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
              name: makeName(appInfo?.Person?.[0]?.name?.[0]),
              gender: appInfo?.Person?.[0]?.gender ?? "",
              birthDay: appInfo?.Person?.[0]?.birthDay,
            },
            symptomsSearch: [],
            modifyUser: true,
            newBook: true,
            appointMentInfo: appInfo,
            fromCloseConsultation: true,
          },
        });
      }
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill Select Followup",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  const CheckingRole = (role) => {
    switch (role) {
      case 1:
        return true;
      case 2:
        if (props.assignData?.Followupdetails?.length > 0) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };
  const dateSet = props?.assignData?.encounterCloseTime?.filter(
    (val) => val?.status?.shortdesc === "Consultation over"
  )?.[0]?.period?.[0];
  return (
    <div
      id={`${parent_id}_assign_practitioner_parent_div`}
      style={styles.wrapper}
      className={classes.root}
    >
      <Div id={`${parent_id}_contact_point_title_label_div`}>
        <H6
          id={`${parent_id}_contact_point_title_label_h6`}
          style={styles.header}
        >
          {props.title}
        </H6>
        {/* <H6>{JSON.stringify(useData)}</H6> */}
      </Div>

      <Grid
        container
        spacing={2}
        id={`${parent_id}_assign_practitioner_header_grid`}
      >
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Div
                id={`${parent_id}_contact_point_title_label_div`}
                style={{ display: "flex" }}
              >
                <H6
                  id={`${parent_id}_contact_point_title_label_h6`}
                  style={styles.subHeader}
                >
                  VISIT DETAILS
                </H6>
                <hr style={styles.visitDetailDot} />
              </Div>
            </Grid>
            <Grid
              item
              xs={4}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              <DetailCard
                title="Visit Type"
                subTitle={props?.assignData?.visitType ?? ""}
                icon="images/Mask Group 45.svg"
              />
            </Grid>
            <Grid
              item
              xs={4}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              <DetailCard
                title="Visit Date & Time"
                subTitle={props?.assignData?.encounterDate ?? ""}
                icon="images/icons8-calendar_1.svg"
              />
            </Grid>
            <Grid
              item
              xs={4}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              {props?.assignData?.reasonCode && (
                <DetailCard
                  title={
                    props?.title === "Close Consultation"
                      ? "Reason for visit"
                      : "Reason"
                  }
                  subTitle={props?.assignData?.reasonCode ?? ""}
                  icon="images/Mask Group 35.svg"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Div
                id={`${parent_id}_contact_point_title_label_div`}
                style={{ display: "flex" }}
              >
                <H6
                  id={`${parent_id}_contact_point_title_label_h6`}
                  style={styles.subHeader}
                >
                  APPOINTMENT DETAILS
                </H6>
                <hr style={styles.appointmentDeatailsDot} />
              </Div>
            </Grid>
            <Grid
              item
              xs={4}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              <DetailCard
                title="Appointment Date & Time"
                subTitle={props?.assignData?.appointmentDateTime ?? ""}
                icon="images/icons8-calendar_1.svg"
              />
            </Grid>
            <Grid
              item
              xs={4}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              <DetailCard
                title="Consultation Mode"
                subTitle={props?.assignData?.consultationMode ?? ""}
                icon="images/Mask Group 45.svg"
              />
            </Grid>
            <Grid
              item
              xs={4}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              {props?.assignData?.appointmentReason && (
                <DetailCard
                  title="Reason"
                  subTitle={props?.assignData?.appointmentReason ?? ""}
                  icon="images/Mask Group 35.svg"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Div
                id={`${parent_id}_contact_point_title_label_div`}
                style={{ display: "flex" }}
              >
                <H6
                  id={`${parent_id}_contact_point_title_label_h6`}
                  style={styles.subHeader}
                >
                  PATIENT DETAILS
                </H6>
                <hr style={styles.patientDeatailsDot} />
              </Div>
            </Grid>
            <Grid item xs={12}>
              <PatientDeatils
                parent_id={"PatientDeatils_"}
                title={"PatientDeatils___ "}
                patientPhoto={
                  getImgUrl(props.assignData?.patientPhoto?.fileid) ?? ""
                  //getImgUrl(10101)
                }
                name={props.assignData?.patientName ?? ""}
                age={
                  props.assignData?.patientAge
                    ? `${
                        +props.assignData?.patientAge > 1
                          ? `${props.assignData?.patientAge} Yrs`
                          : `${props.assignData?.patientAge} Yr`
                      }`
                    : ""
                }
                gender={props.assignData?.patientGender ?? ""}
                patientId={props.assignData?.patientMRN ?? ""}
                mobileNumber={props.assignData?.patientMobileNumber ?? ""}
                specialityName={props.assignData?.specialityName ?? ""}
                clinic={props.assignData?.clinic ?? "No data"}
                showPractitioner={props.showPractitioner}
                practitioner={props?.assignData?.practitioner ?? ""}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* FROM PRACTITIONER */}
        {props.type === "reassign" && props.type !== "close" && (
          <>
            <Grid item xs={12}>
              <Div
                id={`${parent_id}_contact_point_title_label_div`}
                style={{ display: "flex" }}
              >
                <H6
                  id={`${parent_id}_contact_point_title_label_h6`}
                  style={styles.subHeader}
                >
                  FROM PRACTITIONER
                </H6>
                <hr style={styles.fromPractitionerDot} />
              </Div>
            </Grid>
            <Grid
              item
              xs={5}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DetailCard
                    title="Practitioner Type"
                    subTitle={props?.assignData?.practitionerType ?? ""}
                    icon="images/Mask Group 45.svg"
                  />
                </Grid>
                <Grid item xs={6}>
                  <DetailCard
                    title="Practitioner Role"
                    subTitle={props?.assignData?.practitionerRole ?? ""}
                    icon="images/icons8-medical-doctor.svg"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={7}
              id={`${parent_id}_assign_practitioner_header_item_grid`}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <DetailCard
                    title="Practitioner"
                    subTitle={props?.assignData?.practitioner ?? ""}
                    icon="images/Mask Group 42.svg"
                  />
                </Grid>
                <Grid item xs={4}>
                  <DetailCard
                    title="Assigned By"
                    subTitle={useData?.name?.text ?? ""}
                    icon="images/Mask Group 22.svg"
                  />
                </Grid>
                <Grid item xs={4}>
                  <DetailCard
                    title="Assigned Date & Time"
                    subTitle={moment().format("DD MMM,YYYY hh:mm A")}
                    icon="images/icons8-calendar_1.svg"
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {/* TO PRACTITIONER */}
        {props.type !== "close" && (
          <>
            <Div
              id={`${parent_id}_contact_point_title_label_div`}
              style={{ display: "flex", padding: "8px" }}
            >
              <H6
                id={`${parent_id}_contact_point_title_label_h6`}
                style={styles.subHeader}
              >
                TO PRACTITIONER
              </H6>
              <hr style={styles.toPractitionerDot} />
            </Div>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectBox
                    // style={{ background: "white", borderRadius: "7px", width: "400px" }}
                    parent_id={"reason"}
                    top_title={"Practitioner Type"}
                    list={practitionerType ?? []}
                    placeholder={"Select Type"}
                    value={practitionerType_}
                    requireStart
                    onchange={(e, value) =>
                      handleselectChange("practitionerType", value)
                    }
                    error={err && !practitionerType_ ? true : false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <SelectBox
                    // style={{ background: "white", borderRadius: "7px", width: "400px" }}
                    parent_id={"reason"}
                    top_title={"Practitioner Role"}
                    list={practitionerRole ?? []}
                    requireStart
                    placeholder={"Select Role..."}
                    value={practitionerRole_}
                    onchange={(e, value) =>
                      handleselectChange("practitionerRole", value)
                    }
                    error={err && !practitionerRole_ ? true : false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {/* SELECT PRACTITIONER */}
        <Grid item xs={12}>
          {/* {props.type === "assign" && ( */}
          <>
            {props.type !== "close" && (
              <>
                <Div
                  id={`${parent_id}_contact_point_title_label_div`}
                  style={styles.text}
                >
                  Select Practitioner
                </Div>

                {state.practitionerData_.length > 0 ? (
                  <Grid container spacing={2}>
                    {state.practitionerData_?.map((val, i) => {
                      return (
                        <Grid item xs={4}>
                          <div
                            style={{
                              minWidth: "305px",
                              padding: "10px",
                              paddingLeft: 0,
                            }}
                          >
                            <PractitionerCard
                              parent_id={"_" + i}
                              url={getImgUrl(val?.photo?.fileid)}
                              title={val?.name}
                              //subTitle={val?.subTitle}
                              appointmentCount={val?.appointmentCount}
                              selected={
                                state.Practitioner_id === val.Practitioner_id
                              }
                              value={val}
                              index={i}
                              handleClick={handleClick}
                            />
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Div id={`${parent_id}_search_speciality_data_found_div`}>
                    <H6
                      style={{
                        margin: 10,
                        textAlign: "left",
                        color: "rgb(155 153 153)",
                        fontSize: 14,
                        fontWeight: "600",
                      }}
                    >
                      No Data Found
                    </H6>
                  </Div>
                )}
              </>
            )}
          </>

          {/* ENCOUNTER CLOSE TIME */}
          {props.type === "close" && (
            <>
              <Grid
                container
                spacing={2}
                id={`${parent_id}_assign_practitioner_header_grid`}
              >
                <Grid item xs={6}>
                  <Div
                    id={`${parent_id}_contact_point_title_label_div`}
                    style={{ display: "flex", padding: "8px 8px 8px 0px" }}
                  >
                    <H6
                      id={`${parent_id}_contact_point_title_label_h6`}
                      style={styles.subHeader}
                    >
                      ENCOUNTER CLOSE TIME
                    </H6>
                    <hr style={styles.encounterCloseTimeDot} />
                  </Div>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div>
                          <H6 className="pc_medium" style={styles.title_}>
                            Data & Time{" "}
                            <span style={{ color: UIColor.error.color }}>
                              *
                            </span>
                          </H6>
                        </div>
                        {/* <DateTimepicker
                          key={"6"}
                          type={"date"}
                          placeholder={"please select"}
                          style={styles.usYVQ}
                          value={state.dateTime}
                          onChange={(e) =>
                            changeState("dateTime", e.target.value)
                          }
                        ></DateTimepicker> */}
                        {/* <TextField
                          id="datetime-local"
                          label=""
                          type="datetime-local"
                          variant="outlined"
                          defaultValue={state.dateTime}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: new Date(dateSet?.start * 1000)
                              .toISOString()
                              .slice(0, 16),
                          }}
                          className={classes.datePadding}
                          onChange={(e) =>
                            changeState("dateTime", e.target.value)
                          }
                          error={err && !state.dateTime ? true : false}
                        /> */}
                        <CustomInputDatePicker
                          customInput={
                            {right:6,
                            top:8}
                          }
                          popperPlacement={"top-start"}
                          placeholderText="dd / mm / yyyy"
                          disabled={true}
                          showTimeSelect
                          // showTimeSelectOnly
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          // minDate={dateSet ? new Date(dateSet?.start * 1000).toISOString().slice(0, 16) : null }
                          maxDate={new Date()}
                          selectedDate={state.dateTime ? new Date(state.dateTime) : null}
                          handleChange={(value) =>
                            // alert(moment(value))
                            changeState("dateTime", value)
                            // updateState(val.stateName, moment(value).format("HH: mm"))
                          }
                          inputField={
                            <TextInput
                              disabled={true}
                              readOnly={true}
                              id={`${parent_id}_laborder_calender_textfield`}
                              // className={classes.autocomplete}
                              type="text"
                              // style={{ width: 170 }}
                              // autoComplete={"off"}
                              value={state.dateTime ? moment(new Date(state.dateTime)).format("DD-MM-YYYY HH:mm") : moment(new Date()).format("DD-MM-YYYY HH:mm") }
                              placeholder={"Start Date"}
                              
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Div
                    id={`${parent_id}_contact_point_title_label_div`}
                    style={{ display: "flex", padding: "8px 8px 8px 0px" }}
                  >
                    <H6
                      id={`${parent_id}_contact_point_title_label_h6`}
                      style={styles.subHeader}
                    >
                      DISPOSITION
                    </H6>
                    <hr style={styles.dispositionDot} />
                  </Div>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div>
                          <H6 className="pc_medium" style={styles.title_}>
                            Disposition Type{" "}
                            <span style={{ color: UIColor.error.color }}>
                              *
                            </span>
                          </H6>
                        </div>
                        <SelectBox
                          // style={{ background: "white", borderRadius: "7px", width: "400px" }}
                          parent_id={"reason"}
                          top_title={""}
                          list={dispositionData ?? []}
                          placeholder={"Select Type"}
                          value={state.dispositionType}
                          onchange={(e, value) =>
                            handleselectChange_("dispositionType", value)
                          }
                          error={
                            err && state.dispositionType.value.length < 1
                              ? true
                              : false
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Div
                    id={`${parent_id}_contact_point_title_label_div`}
                    style={{ display: "flex", padding: "8px 8px 8px 0px" }}
                  >
                    <H6
                      id={`${parent_id}_contact_point_title_label_h6`}
                      style={styles.subHeader}
                    >
                      FOLLOW-UP AND REFERRAL DETAILS
                    </H6>
                    <hr style={styles.followDot} />
                  </Div>

                  <Grid container spacing={2}>
                    {CheckingRole(props.Role) && (
                      <>
                        <Grid item xs={2}>
                          <div>
                            <H6 className="pc_medium" style={styles.title_}>
                              Follow-up ?
                            </H6>
                          </div>
                          <Checkbox
                            checked={state.followup}
                            onChange={(e) => handleChange("followup", e)}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            disabled={state?.disable}
                          />
                        </Grid>
                        <Grid item xs={4} style={{ marginLeft: "-60px" }}>
                          <div>
                            <H6 className="pc_medium" style={styles.title_}>
                              Follow-up After
                            </H6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              textAlign: "center",
                              alignItems: "center",
                              marginTop: 6,
                            }}
                          >
                            <TextField
                              type='number'
                              style={{
                                width: 20,
                                marginLeft: "10px",
                                marginTop: "-10px",
                              }}
                              value={state?.followup_number}
                              disabled={state.disable}
                              onChange={(e) =>
                                handleInputChange(
                                  "followup_number",
                                  e.target.value,
                                  2
                                )
                              }
                              id="standard-basic"
                              placeholder={"02"}
                              inputProps={{
                                maxLength: 2,
                              }}
                            />
                            {state?.disable ? (
                              <span style={{ marginLeft: "20px" }}>
                                {followupUOMData?.map((val) => {
                                  return (
                                    <Chip
                                      className={
                                        state?.followupType?.label ===
                                        val?.label
                                          ? classes?.selectedchip
                                          : classes?.chip
                                      }
                                      label={val?.label}
                                    />
                                  );
                                })}
                              </span>
                            ) : (
                              <TapSelect
                                label={""}
                                inLineStyles={styles.tapSelectStyle}

                                selectedBorderColor={UIColor.secondaryButtonColor.color} 
                                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                                selectedTextColor="#fff"
                                borderColor={UIColor.differentiationBackground.color}
                                backgroundColor={UIColor.differentiationBackground.color}
                                textColor={UIColor.secondaryText.color}
                                textSize={"small"}
                                options={followupUOMData}
                                value={state?.followupType}
                                defaultSelected={state?.followupType}
                                onChange={(value) =>
                                  tabHandle("followupType", value)
                                }
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={props?.title === "Close Consultation" ? 4 : 6}
                          style={{ marginLeft: "-30px" }}
                        >
                          <div>
                            <H6 className="pc_medium" style={styles.title_}>
                              Reason
                            </H6>
                          </div>
                          <SelectBox
                            // style={{ background: "white", borderRadius: "7px", width: "400px" }}
                            parent_id={"reason"}
                            top_title={""}
                            list={reasonData ?? []}
                            placeholder={"Select"}
                            value={state.reason}
                            disabled={state?.disable}
                            onchange={(e, value) =>
                              handleselectChange_("reason", value)
                            }
                          />
                        </Grid>
                        {props?.title === "Close Consultation" && (
                          <Grid
                            item
                            xs={2}
                            style={{ marginLeft: "10px", marginTop: "21px" }}
                          >
                            <div
                              style={{
                                background: "rgb(235 244 255)",
                                borderRadius: "8px",
                                width: "182px",
                                height: "36px",
                                cursor: "pointer",
                              }}
                              onClick={() => bookAppointment()}
                            >
                              <H6
                                className="pc_medium"
                                style={styles.book_app_title_}
                              >
                                Book Appointment
                              </H6>
                            </div>
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid item xs={2}>
                      <div>
                        <H6 className="pc_medium" style={styles.title_}>
                          Referral Out required?
                        </H6>
                      </div>
                      <Checkbox
                        checked={state.referralOutRequired}
                        onChange={(e) => handleChange("referralOutRequired", e)}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    </Grid>

                    {state.referralOutRequired && (
                      <Grid item xs={4}>
                        <Button
                          id="patient_detail_signoff_button"
                          inLineStyles={{
                            backgroundColor: UIColor.secondaryButtonColor.color,
                            borderColor: UIColor.secondaryButtonColor.color,
                            borderRadius: "8px",
                          }}
                        >
                          {"Register Referral"}
                        </Button>
                        {/* <div
                          style={{
                            fontSize: "16px",
                            color: "#0071F2",
                            width: "150px",
                            height: "30px",
                            background: "#deebfa",
                            padding: "3px 16px",
                            borderRadius: "5px",
                            marginTop: "28px",
                          }}
                        >
                          Register Referral
                        </div> */}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* Footer */}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Div id="patient_detail_hold-sign_button_div">
                <Button
                  id="patient_detail_put_hold_button"
                  inLineStyles={styles.cancelButton}
                  onClick={() => props.onModalClose()}
                >
                  {"Cancel"}
                </Button>
                <Button
                  id="patient_detail_signoff_button"
                  onClick={() => confirm()}
                  inLineStyles={{
                    backgroundColor: UIColor.secondaryButtonColor.color,
                    borderColor: UIColor.secondaryButtonColor.color,
                    borderRadius: "8px",
                  }}
                >
                  {"Confirm"}
                </Button>
              </Div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

AssignReassign.defaultProps = {
  title: "",
};

export default withAllContexts(AssignReassign);