import React from "react";
import { Div, H6, Col, Row, Text, Avatar, Paper, Image, Icons, Modal } from 'qdm-component-library'
import "./style.css"
import moment from "moment";
import ReferralBannerListView from "./referralViewDetails";

class ReferralListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openRef: false
        }
    };

    getAge = (date) => {
        return (
            Math.floor(
                moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
            ) + "Yrs"
        );
    };
    handleopenRef = () => {
        this.setState({
            openRef: !this.state.openRef
        })
    }
    render() {
        const { ReferralData, imgUrl,
            letter = (
                (ReferralData?.name?.[0])), noright, parent_id
        } = this.props;
        return <Paper
            id={`${parent_id}_referabanner_parent_paper`}
            className="rv-pl-root">
            <Row
                id={`${parent_id}_referabanner_parent_row`}
            >
                <Col
                    id={`${parent_id}_referabanner_parent_col`}
                    md={12} lg={12} sm={12} xs={12} inLineStyles={{ padding: 0 }} className="pl-detail-root">
                    <Div
                        id={`${parent_id}_referabanner_parent_div`}
                        className="flexCenter">
                        {(imgUrl || letter) && <Avatar
                            id={`${parent_id}_referabanner_letter_image`}
                            style={{ padding: 10 }} className="pl-details-avatar" src={imgUrl} letter={letter} variant="rounded" />}
                        &nbsp;&nbsp;&nbsp;
                        <Div
                            id={`${parent_id}_referabanner_description_div`}
                            className="pl-details-names">
                            <H6
                                id={`${parent_id}_referabanner_referral_id_h6`}
                            >
                                {(ReferralData?._id)}
                                &nbsp;&nbsp;•&nbsp;&nbsp;
                                {ReferralData?.referralFrom?.[0]?.facilityType}</H6>

                            <Div
                                id={`${parent_id}_referabanner_document_div`}
                                className="flexCenter">
                                <Div
                                    id={`${parent_id}_referabanner_document_sub_div`}
                                    className="pl-secondary-text flexCenter">
                                    <Div
                                        id={`${parent_id}_referabanner_iden_document_div`}
                                        className="p1-secondary-img">
                                        <Image
                                            id={`${parent_id}_referabanner_document_image`}
                                            className="pl-secondary-text-icons" src="images/icons8-identification-documents.svg" alt="id" />
                                    </Div>
                                    <Div
                                        id={`${parent_id}_referabanner_specialty_div`}
                                    ><Text
                                        id={`${parent_id}_referabanner_specialty_text`}
                                    >{ReferralData?.referralTo?.[0]?.specialty}</Text></Div>
                                </Div>
                                <Div
                                    id={`${parent_id}_referabanner_group_div`}
                                    className="pl-secondary-text flexCenter" >
                                    <Div
                                        id={`${parent_id}_referabanner_group1_div`}
                                        className="p1-secondary-img">
                                        <Image
                                            id={`${parent_id}_referabanner_group_image`}
                                            className="pl-secondary-text-icons" src="images/Group 90503.svg" alt="group" />
                                    </Div>
                                    <Text
                                        id={`${parent_id}_referabanner_practitioner_text`}
                                    >{ReferralData?.referralFrom?.[0]?.practitioner}</Text>
                                </Div>
                                <Div
                                    id={`${parent_id}_referabanner_openref_div`}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '8px',
                                        width: '30px',
                                        height: '30px',
                                        border: '1px solid #E0E0E0',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: "pointer"
                                    }}
                                    onClick={() => this.handleopenRef()}
                                >
                                    <Icons
                                        id={`${parent_id}_referabanner_eye_icons`}
                                        inLineStyles={{ color: "black", fontSize: '20px ​!importan' }}
                                        fontIcon={"eye"}
                                        ariaHidden={"true"}
                                        size={"large"} />
                                </Div>
                            </Div>

                        </Div>

                    </Div>
                </Col>
            </Row>
            <Modal
                id={`${parent_id}_referabanner_modal`}
                inLineStyles={{ borderRadius: 16 }}
                open={this.state.openRef}
                onClose={this.handleopenRef}
                width={1000}
            >
                <ReferralBannerListView
                    parent_id={'doctor_listing_referral'}
                    ReferralData={ReferralData} />
            </Modal>
        </Paper>
    }
}


export default ReferralListView;