import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { Routes } from "../../router/routes";
import {
  Container,
  Div,
  Icons,
  SideMenu,
  Text,
  Image,
  Avatar,
  Divider,
  Button,
  H6,
} from "qdm-component-library";
import CancelBookingPage from "../../screens/cancelBooking";
import { Routes } from "../../router/routes";
import { convertTime, makeName } from "../../utils";

// import { Gustuserconfirm } from "../../assets/img/gustuserconfirm.js";
class ConfirmBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelbooking: false,
      cancelbookingreason: {},
      cancelbookings: 0,
      appointmentInfo: {},
    };
  }
  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {
    this.fetchInfo();
  }

  async fetchInfo() {
    const data = await this.props.APPOINTMENT_READ({ appointmentId: this.props.appointmentId })
    this.changeState("appointmentInfo", data?.payload?.data || {});
  }

  shouldComponentUpdate(props, state) {
    return true;
  }
  styles = {
    container: {
      background: "#FFFFFF",
      boxShadow: "0px 4px 8px #0000001A",
      padding: 30,
      maxWidth: "570px",
      borderRadius: 16
    },
    tickSvg: {
      width: 32,
      height: 32,
      margin: "auto auto 10px",
    },
    appointmentBooking: {
      color: "#38C20A",
      fontSize: 18,
      marginBottom: 10,
      textAlign: "center",
    },
    confirmation: {
      color: "#6F6F6F",
      fontSize: 12,
      textAlign: "center",
    },
    wrapper: {
      border: "2px dashed rgb(239 239 239)",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      margin: "20px 0px",
    },
    h6: {
      color: "#101010",
      fontSize: 14,
      margin: 0,
    },
    span: {
      color: "#6F6F6F",
      fontSize: 12,
    },
    barCode: {
      width: 100,
      height: 100,
      border: "2px dashed rgb(239 239 239)",
      padding: 5,
      marginBottom: 10,
      borderRadius: 8,
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      padding: 10,
    },
    flexRow: {
      display: "flex",
      alignItems: "center",
    },
    divider: {
      margin: "10px",
      opacity: 0.5,
    },
    iconSpan: {
      color: "#0071F2",
      marginLeft: "10px",
      fontSize: 12,
    },
    outlinedButton: {
      borderColor: "#0071F2",
      color: "#0071F2",
      backgroundColor: "#fff",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
      marginRight: 10,
    },
    containedButton: {
      backgroundColor: "#0071F2",
      borderColor: "#0071F2",
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontSize: 12,
    },
    clickHere: {
      margin: 0,
      marginLeft: 10,
      color: "#0071F2",
      cursor: "pointer",
      fontSize: 12,
    },
    avatar: {
      width: 40,
      height: 40,
    },
    gustuser: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #38C20A",
      width: "80%",
      padding: "6px 8px",
      backgroundColor: "#F0FCEF",
      borderRadius: "8px",
      fontSize: 12,
      color: "#258007",
      marginTop: "4%",
    },
  };
  handlesidebarCancelbooking = () => {
    this.setState({
      cancelbooking: !this.state.cancelbooking,
    });
  };
  handleSelectCancelbooking = (v) => {
    this.setState({
      cancelbookingreason: v,
    });
  };
  handlecahngeCancelbooking = async (v, number) => {
    if (v === "back") {
      this.setState({
        cancelbookings: 0,
      });
      return;
    }
    if (!v) {
      this.handlesidebarCancelbooking();
      window.location.href = "/profile";
      // this.setState({
      //   cancelbookings: 0,
      // });
      return;
    }
    const mobile = number;
    if (v === 1) {
      await this.props.GENERATE_OTP({ mobileNo: mobile });
    }
    this.setState({
      cancelbookings: v,
    });
  };

  getPractionerName(val) {
    if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
      return makeName(val?.resourcecode?.name?.[0] ?? {});
    }
    return val?.resourcecode?.shortdesc;
  }

  render() {
    const {
      container,
      tickSvg,
      appointmentBooking,
      confirmation,
      wrapper,
      h6,
      span,
      barCode,
      flexColumn,
      flexRow,
      divider,
      iconSpan,
      containedButton,
      clickHere,
      avatar,
      // gustuser,
    } = this.styles;
    const { cancelbooking, cancelbookingreason, cancelbookings } = this.state;
    return (
      <Container name="container" fluid={false} inLineStyles={container}>
        <Div>
          <Div style={tickSvg}>
            <Image
              src="images/icons8-instagram-verification-badge.svg"
              alt="tick"
            />
          </Div>
          <H6 className="pc_semibold" style={appointmentBooking}>
            Your visit has been registered123 !
          </H6>
          <H6 className="pc_regular" style={confirmation}>
            Confirmation has been sent to your {this.state?.appointmentInfo?.AppointmentNotification?.[0]?.mobile}{" "}
            {this.state.appointmentInfo?.AppointmentNotification?.[0]?.patientEmail && "&"}<br />
            {this.state.appointmentInfo?.AppointmentNotification?.[0]?.patientEmail}
          </H6>
          {/* <H6 className="pc_regular" style={gustuser}>
            <span style={{ marginRight: "2%" }}>
              <Gustuserconfirm />
            </span>{" "}
            You're a registered user now, you can log in using
            <br /> your mobile number (9894541371).
          </H6> */}
          <Div style={wrapper}>
            <Div style={{ ...flexColumn, alignItems: "center" }}>
              <Div style={barCode}>
                <Image
                  alt="Image"
                  // src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=${this.state.appointmentInfo?.id}`}
                />
              </Div>
              <H6 className="pc_medium" style={h6}>{
                // convertTime(this.state.appointmentInfo?.start || 0, "DD/MM/YYYY HH:mm:ss", "DD MMM YYYY | hh:mm a")
                this.state?.appointmentInfo?.resourcetype?.toLowerCase() === "practitioner"
                ? convertTime(this.state.appointmentInfo?.start || 0, "unix", "DD MMM YYYY | hh:mm a")
                : `${convertTime(this.state.appointmentInfo?.start || 0, "unix", "DD MMM YYYY")} | ${convertTime(this.state.appointmentInfo?.start || 0, "unix", "hh:mm a")} - ${convertTime(this.state.appointmentInfo?.end || 0, "unix", "hh:mm a")}`
              }</H6>
              <H6 className="pc_regular" style={{ ...span, margin: '5px 0px' }}>AP-{this.state.appointmentInfo?.id || "---"}</H6>
              {/* Data For Price */}
              <Text className="pc_regular" style={{ ...span, color: '#FF4D4A' }}>{" "}</Text>
            </Div>
            <Div
              style={{
                borderLeft: "1px dashed #E0E0E0",
                flex: 1,
              }}
            >
              <Div style={{ display: "flex", padding: "5px 10px" }}>
                <Avatar
                  variant="circle"
                  letter="Avatar"
                  src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  alt="Image"
                  style={avatar}
                />
                <Div style={flexColumn}>
                  <H6 className="pc_medium" style={h6}>{this.getPractionerName(this.state.appointmentInfo)}</H6>
                  <Text className="pc_regular" style={{ ...span, marginBottom: '4px' }}>
                    {this.state.appointmentInfo?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.specialty?.find(spec => spec?.Type?.toLowerCase() === "speciality")?.text || ' '}
                  </Text>
                  <Text className="pc_regular" style={{ ...span, color: '#101010' }}>
                    {this.state.appointmentInfo?.Practioner?.[0]?.roles?.[0]?.healthcareservices?.[0]?.name || ''}
                  </Text>
                </Div>
              </Div>
              <Divider style={divider} />
              <Div style={{ display: "flex", padding: "5px 10px" }}>
                <Avatar
                  variant="circle"
                  letter="Avatar"
                  src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                  alt="Image"
                  style={avatar}
                />
                <Div style={flexColumn}>
                  <H6 className="pc_medium" style={h6}>{makeName(this.state.appointmentInfo?.Person?.[0]?.name?.[0])}</H6>
                  <Text className="pc_regular" style={{ ...span, marginBottom: '4px' }}>{convertTime(this.state.appointmentInfo?.Person?.[0]?.birthDay, "DD/MM/YYYY", "years", "diff")} Yrs | {this.state.appointmentInfo?.Person?.[0]?.gender}</Text>
                  <Text className="pc_regular" style={{ ...span, color: '#101010' }}>{this.state.appointmentInfo?.AppointmentNotification?.[0]?.mobile}</Text>
                </Div>
              </Div>
            </Div>
          </Div>
          <Div style={{ ...flexRow, justifyContent: "space-between" }}>
            <Div style={{ ...flexRow, cursor: "pointer" }}>
              <Icons
                fontIcon="download"
                ariaHidden="true"
                size="small"
                style={{ color: "#0071F2" }}
              />
              <Text className="pc_regular" style={iconSpan}>
                Appointment Summary
              </Text>
            </Div>
            <Div style={{ ...flexRow, cursor: "pointer" }}>
              <Icons
                fontIcon="download"
                ariaHidden="true"
                size="small"
                style={{ color: "#0071F2" }}
              />
              <Text className="pc_regular" style={iconSpan}>
                Invoice
              </Text>
            </Div>
            <Div style={{ ...flexRow, cursor: "pointer" }}>
              <Icons
                fontIcon="print"
                ariaHidden="true"
                size="small"
                style={{ color: "#0071F2" }}
              />
              <Text className="pc_regular" style={iconSpan}>
                Print
              </Text>
            </Div>
            <Div style={{ ...flexRow, cursor: "pointer" }}>
              <Icons
                fontIcon="share"
                ariaHidden="true"
                size="small"
                style={{ color: "#0071F2" }}
              />
              <Text className="pc_regular" style={iconSpan}>
                Share
              </Text>
            </Div>
          </Div>
          {!this.props.hidden && (
            <Div
              style={{
                ...flexRow,
                justifyContent: "center",
                margin: "30px 0px",
              }}
            >
              <Button
                className="pc_medium"
                onClick={() =>
                  this.props.history.push(Routes.appointmentsListingPage)
                }
                inLineStyles={containedButton}
              >
                Back to Home
              </Button>
            </Div>
          )}
          {/* {!this.props.hidden && (
            <Div>
              <H6
                className="pc_regular"
                style={{ ...span, ...flexRow, justifyContent: "center" }}
              >
                Want to cancel appointment ?
                <H6
                  style={clickHere}
                  onClick={() => this.handlesidebarCancelbooking()}
                >
                  Click here
                </H6>
              </H6>
            </Div>
          )} */}
        </Div>
        <SideMenu
          open={cancelbooking}
          direction="right"
          width={435}
          color="default"
          onClose={() => this.handlesidebarCancelbooking()}
        >
          <CancelBookingPage
            cancelbookingreason={cancelbookingreason}
            appointmentId={this.props.appointmentId}
            cancelbookings={cancelbookings}
            handleSelectCancelbooking={this.handleSelectCancelbooking}
            handlecahngeCancelbooking={this.handlecahngeCancelbooking}
          />
        </SideMenu>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  appointmentInfo: state?.appointmentReducer?.readAppointment
});

export default connect(mapStateToProps, actions)(withRouter((ConfirmBook)));