import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import qdmstyles from "./styles.json";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { AlertProps, convertTime } from "../../utils";
import {
  Container,
  H6,
  Divider,
  Text,
  Div,
  TextInput,
  Button,
  Checkbox,
  Avatar,
  Dropdown,
  MobileNumberWithCode,
} from "qdm-component-library";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import { AlertContext } from "../../contexts";

const styles = (theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  body: {
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  TapSelect: {
    "& div": {
      "& span": {
        fontSize: 13,
        fontWeight: "600",
        letterSpacing: "0.9px",
      },
    },
  },
});
class AppointmentPageDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMembers: false,
      error: null,
      expanded1: false,
      expanded2: false,
      addmemberlist: null,
      type: "text",
      limit: 20,
      listMmbr: [],
    };
  }
  handleExpandClick = (val) => {
    console.log(val + ' vimport clsx from "clsx";');
    val === 1
      ? this.setState({
        expanded1: !this.state.expanded1,
      })
      : this.setState({
        expanded2: !this.state.expanded2,
      });
  };
  changeState = (key, val) => {
    let value = val;
    if (key === "Aadhar Number") {
      if (
        value &&
        value.length < this.state.limit &&
        value.replaceAll("-", "")?.length % 4 === 0
      ) {
        value += "-";
      }
    }

    this.setState({
      [key]: value,
    });
  };

  onKeyDown = (key, e) => {
    if (key === "Aadhar Number") {
      let value = e.target.value;
      if (e.keyCode === 8) {
        if (value[value.length - 1] === "-") {
          value = value.substring(0, value.length - 2);
          e.preventDefault();
        } else if (value[value.length - 2] === "-") {
          value = value.substring(0, value.length - 1);
          e.preventDefault();
        }
        this.setState({
          [key]: value,
        });
      } else if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault();
      }
    }
  };

  async componentDidMount() {
    await this.props.GET_COUNTRY_CODE_LIST()
   }

  shouldComponentUpdate(props, state) {
    return true;
  }
  senOtpMMr = async () => {
    debugger

    // let res__ = mobileNumberValidation(this.state.mobileNumber.mobileNo, this.state.mobileNumber.countryData.callingCodes);
		// 	debugger
		// 	if (res__ === "Invalid Country code") {
		// 		this.props.alert.setSnack({
		// 			open: true,
		// 			severity: AlertProps.severity.error,
		// 			msg: res__,
		// 			vertical: AlertProps.vertical.top,
		// 			horizontal: AlertProps.horizontal.right,
		// 		});
		// 		return
		// 	} else if (res__ === false) {
		// 		this.props.alert.setSnack({
		// 			open: true,
		// 			severity: AlertProps.severity.error,
		// 			msg: "Please enter valid mobile number",
		// 			vertical: AlertProps.vertical.top,
		// 			horizontal: AlertProps.horizontal.right,
		// 		});
		// 		return
		// 	}

    const SearchUserData = await this.props.GENERATE_OTP({
      mobileNo: this.state.selectData?.telecom?.find(
        (tel) => (tel.system === "Phone" && tel.use === "Mobile")
      )?.value,
    });
    if (SearchUserData.payload.data.error) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: SearchUserData.payload.data.errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      return;
    } else {
      const countryCode = `${this.state[this.props?.chooseSearch?.value]?.countryData
        ?.callingCodes ?? "00"
        }`;
      const phoneNumber = this.state.selectData?.telecom
        ?.find((tel) => (tel.system === "Phone" && tel.use === "Mobile"))
        ?.value?.split(countryCode);
      // this.props.serchMMrDatafuncydata(SearchUserData)
      const signUpData = {
        mobileNo: {
          mobileNo: phoneNumber[1] || phoneNumber[0],
          countryData: {
            callingCodes: countryCode,
          },
        },
        selectData: this.state?.selectData,
        id: this.state?.selectData.Id,
        SearchUserData,
        firstName: this.state?.selectData?.name.text || this.state?.selectData?.name.given,
        lastName: this.state?.selectData?.name.family,
        dob: this.state?.selectData?.birthDay,
        gender: this.state?.selectData?.gender,
        emailId: this.state?.selectData?.telecom?.find(
          (tel) => (tel.system === "Email" && tel.use === "Work")
        )?.value,
        middleName: "",
      };
      this.props.nextSection("addmemberotp", "addMember", signUpData);
    }
  };
  searchMember = async () => {
    let searchType = this.props?.chooseSearch?.value;
    let value = this.state?.[searchType];
    let checkErr = this.checkErr(searchType, value);
    ;
    if (searchType && value && checkErr) {
      const SearchUserData = await this.props.SEARCH_PERSON({
        phone: this.state[this.props?.chooseSearch?.value]?.mobileNo,
        // phone: '8056895305',
        use: "Mobile",
      });

      if (SearchUserData?.payload?.data) {
        this.setState({
          ...this.state,
          showMembers: true,
          error: null,
          listMmbr: SearchUserData?.payload?.data,
        });
      }
      this.setState({
        ...this.state,
        showMembers: true,
        error: null,
      });
    } else {
      this.setState({
        ...this.state,
        showMembers: false,
        error: this.errorMessage(searchType, value),
      });
    }
  };

  checkErr = (stateName, val) => {
    if (stateName === "PID Number") {
      return val?.length === 20 ? true : false;
    } else if (stateName === "Aadhar Number") {
      return val?.length === 14 ? true : false;
    } else if (stateName === "Mobile Number") {
      return val?.countryData?.callingCodes && val?.mobileNo?.length === 10
        ? true
        : false;
    } else {
      return true;
    }
  };

  errorMessage = (searchType, value) => {
    if (searchType === "PID Number") {
      return "Please enter valid PID Number";
    } else if (searchType === "Aadhar Number") {
      return "Please enter valid Aadhar Number";
    } else if (searchType === "Mobile Number") {
      if (!value?.countryData?.callingCodes) {
        return "Please select country code";
      } else if (value?.mobileNo?.length !== 10) {
        return "Please enter valid Mobile Number";
      }
    } else {
      return "Please fill all the mandatory fields";
    }
  };

  handleCheked = (v, l) => {
    this.setState({
      ...this.state,
      addmemberlist: l,
      selectData: v,
    });
  };

  handleChange = (e) => {
    let type, limit;
    if (e.value === "PID Number") {
      type = "text";
      limit = 20;
    } else if (e.value === "Aadhar Number") {
      type = "text";
      limit = 14;
    }
    this.props.handelChangeSelect && this.props.handelChangeSelect(e);
    this.setState({
      ...this.state,
      type,
      limit,
      [e.value]: "",
    });
  };

  onChangeStateName = () => {
    return (
      (this.props.chooseSearch?.value === "PID Number" && "PID Number") ||
      (this.props.chooseSearch?.value === "Aadhar Number" && "Aadhar Number") ||
      (this.props.chooseSearch?.value === "Emirate ID" && "Emirate ID") ||
      (this.props.chooseSearch?.value === "SSN Number" && "SSN Number")
    );
  };

  render() {
    const { classes } = this.props;
    const { addmemberlist, parent_id } = this.state;
    return (
      <div
        id={`${parent_id}_addmember_parent_div`}
      >
        <Container
          id={`${parent_id}_addmember_parent_container`}
          key={"0"}
          name={"container"}
          fluid={true}
          style={qdmstyles.rpP}
        >
          <H6
            id={`${parent_id}_addmember_name_h6`}
            key={"0"} name={this.props.name} style={qdmstyles.egZK}></H6>

          <Divider
            id={`${parent_id}_addmember_bottom_divider`}
            key={"1"}
            textWidth={"50"}
            textColor={"#000"}
            borderColor={"lightgray"}
            borderWidth={"1"}
            orientation={"horizontal"}
            variant={"middle"}
            style={qdmstyles.BKv}
          ></Divider>

          {/* <Collapse
            key={"2"}
            title={"Existing Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.aGaSE}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}

          <Card
            id={`${parent_id}_addmember_parent_card`}
            style={{
              overflow: "visible",
              boxShadow: "none",
              border: "2px solid #e0e0e0",
              borderRadius: 8,
            }}
          >
            <CardActions
              id={`${parent_id}_addmember_existig_cardactions`}
              onClick={() => this.handleExpandClick(1)}
              disableSpacing
              style={{
                padding: 0,
                paddingLeft: 20,
                paddingBottom: 0,
                cursor: "pointer",
              }}
            >
              <Text
                id={`${parent_id}_addmember_existig_label_text`}
                key={"0"}
                name="Existing Member"
                style={{ fontSize: "16px", fontWeight: "800" }}
                className={"pc_semibold"}
              />

              <IconButton
                id={`${parent_id}_addmember_existig_iconbutton`}
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expanded1,
                })}
                aria-expanded={this.state.expanded1}
                aria-label="show more"
              >
                <ExpandMoreIcon
                  id={`${parent_id}_addmember_existig_expandmore`}
                />
              </IconButton>
            </CardActions>
            <Collapse
              id={`${parent_id}_addmember_existig_collapse`}
              className={classes.body}
              in={this.state.expanded1}
              timeout="auto"
              unmountOnExit
            >
              <Text
                id={`${parent_id}_addmember_existig_registered_text`}
                key={"0"}
                name={"If the member already registered with Apollo hospital."}
                style={qdmstyles.MOH}
                className="pc_regular"
              ></Text>

              <Text
                id={`${parent_id}_addmember_existig_chooseid_text`}
                key={"1"}
                className="pc_regular"
                name={
                  <span
                    id={`${parent_id}_addmember_existig_chooseid_span`}
                  >
                    Choose ID and enter criteria{" "}
                    <span
                      id={`${parent_id}_addmember_existig_chooseid_error_span`}
                      style={{ color: "red" }}>*</span>
                  </span>
                }
                style={qdmstyles.iJEO}
              ></Text>

              <Div
                id={`${parent_id}_addmember_existig_choose_here_div`}
                key={"2"} className={"qdm"} style={qdmstyles.DazVI}>
                <div style={{ width: "66%" }}>
                  <Dropdown
                    id={`${parent_id}_addmember_existig_choose_here_dropdown`}
                    inLineStyles={{
                      height: "42px",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                    name="Choose ID here"
                    onChange={(e) => this.handleChange(e)}
                    placeholder="Choose ID here"
                    options={[
                      {
                        value: "Mobile Number",
                        label: "Mobile Number",
                      },
                      {
                        value: "PID Number",
                        label: "PID Number",
                      },
                      {
                        value: "Aadhar Number",
                        label: "Aadhar Number",
                      },
                      // {
                      //   value: 'Emirate ID',
                      //   label: 'Emirate ID'
                      // },
                      // {
                      //   value: 'SSN Number',
                      //   label: 'SSN Number'
                      // }
                    ]}
                  />
                </div>
                &nbsp;
                {this.props.chooseSearch?.value === "Mobile Number" ? (
                  <MobileNumberWithCode
                    id={`${parent_id}_addmember_existig_mobilenumberwithcode`}
                    key={"2"}
                    type="number"
                    label="Mobile No"
                    borderColor="#E0E0E0"
                    borderRadius="6"
                    maxLength={30}
                    // id="qdm-euu-0877"
                    name=""
                    width=""
                    // dropdownData={[
                    //   {
                    //     name: "India",
                    //     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
                    //     altSpellings: "IND",
                    //     callingCodes: "91",
                    //   },
                    // ]}
                    dropdownData={this?.props?.CountryCode?.data}
                    disabled={false}
                    size=""
                    marginAll="0"
                    marginLR="0"
                    marginTB="0"
                    borderWidth="0"
                    textWidth="0"
                    style={{
                      height: "42px",
                      borderRadius: "5px",
                      width: "110%",
                    }}
                    onChange={(e) => this.changeState("Mobile Number", e)}
                  />
                ) : (
                  <TextInput
                    id={`${parent_id}_addmember_existig_chooseid_textinput`}
                    key={"0"}
                    type={this.state.type}
                    // onInput={e=>e>10 && }
                    variant={"outlined"}
                    value={this.state[this.props?.chooseSearch?.value]}
                    onChange={(e) =>
                      this.changeState(this.onChangeStateName(), e.target.value)
                    }
                    onKeyDown={(e) =>
                      this.onKeyDown(this.onChangeStateName(), e)
                    }
                    helperTextType={"error"}
                    autoFocus={false}
                    autoComplete={"off"}
                    maxLength={this.state.limit}
                    search={false}
                    borderColor={"#E0E0E0"}
                    elevation={"0"}
                    placeholder={
                      (this.props.chooseSearch?.value === "PID Number" &&
                        "PID44444") ||
                      (this.props.chooseSearch?.value === "Aadhar Number" &&
                        "4444-4444-4444") ||
                      (this.props.chooseSearch?.value === "Emirate ID" &&
                        "989-2345-2345-1") ||
                      (this.props.chooseSearch?.value === "SSN Number" &&
                        "123-12-1234")
                    }
                    size={"medium"}
                    style={qdmstyles.qsyIC}
                    name={""}
                    label={""}
                  ></TextInput>
                )}
              </Div>
              {this.state.error && (
                <div
                  id={`${parent_id}_addmember_existig_chooseid_error_div`}
                >
                  {" "}
                  <Text
                    id={`${parent_id}_addmember_existig_chooseid_error_text`}
                    style={{ color: "red", fontSize: 12 }}>
                    {this.state.error}
                  </Text>
                </div>
              )}
              <Button
                id={`${parent_id}_addmember_existig_search_buttom`}
                key={"1"}
                type={"button"}
                variant={"text"}
                name={"Search"}
                inLineStyles={{
                  display: "flex",
                  margin: "auto",
                  marginTop: "16px !important",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
                style={qdmstyles.tSDI}
                onClick={() => this.searchMember()}
              ></Button>
              {this.state?.showMembers && (
                <>
                  {this.state?.listMmbr?.map((v, key) => {
                    return (
                      <Div
                        id={`${parent_id}_${key}_addmember_listmember_div`}
                        key={"3"}
                        className={"qdm"}
                        style={
                          addmemberlist !== key
                            ? qdmstyles.htwc
                            : qdmstyles.htwc1
                        }
                        onClick={(e) => this.handleCheked(v, key)}
                      >
                        <Checkbox
                          id={`${parent_id}_${key}_addmember_listmember_checkbox`}
                          key={"0"}
                          size={"medium"}
                          color={"primary"}
                          name={""}
                          disabled={this.props?.memberIds?.includes(v?.Id)}
                          checked={addmemberlist === key ? true : false}
                          style={{ marginRight: 0 }}
                        ></Checkbox>
                        <Avatar
                          id={`${parent_id}_${key}_addmember_listmember_image`}
                          key={"1"}
                          variant={"circle"}
                          letter={"Avatar"}
                          src={"https://i.pravatar.cc/150?img=65"}
                          alt={"Image"}
                          inLineStyles={{
                            padding: 0,
                            height: "auto",
                            width: 42,
                          }}
                        ></Avatar>
                        <Div
                          id={`${parent_id}_${key}_addmember_listmember_number_div`}
                          key={"2"}>
                          <Div
                            id={`${parent_id}_${key}_addmember_listmember_number_sub_div`}
                            key={"0"}>
                            <H6
                              id={`${parent_id}_${key}_addmember_listmember_h6`}
                              key={"0"}
                              name={(v?.name?.text || v?.name?.given) ?? " "}
                              style={qdmstyles.cccb_ef_d_db_efbfbd}
                            ></H6>

                            <Text
                              id={`${parent_id}_${key}_addmember_convert_text`}
                              key={"1"}
                              name={`PID ---- ,${v?.telecom?.find((tel) => (tel.system === "Phone" && tel.use === "Mobile"))
                                ?.value
                                }, ${convertTime(
                                  v?.birthDay,
                                  "DD/MM/YYYY",
                                  "years",
                                  "diff"
                                )} Yrs, ${v?.gender}`}
                              // name={'PID 24***74 , +91 98******14 , 31 Yrs, Male'}
                              style={qdmstyles.ed_c_f_be_ebccac}
                            ></Text>
                          </Div>
                        </Div>
                        <Button
                          id={`${parent_id}_${key}_addmember_added_button`}
                          key={"1"}
                          type={"button"}
                          variant={"outlined"}
                          name={
                            this.props?.memberIds?.includes(v?.Id)
                              ? "Added"
                              : ""
                          }
                          style={qdmstyles.RrEm}
                        ></Button>
                      </Div>
                    );
                  })}

                  {addmemberlist !== null && (
                    <Button
                      id={`${parent_id}_addmember_send_otp_button`}
                      key={"1"}
                      type={"button"}
                      variant={"text"}
                      name={"Send OTP & Add Member"}
                      style={qdmstyles.Hdva1}
                      onClick={() => {
                        if (
                          this.props?.memberIds?.includes(
                            this.state.selectData.Id
                          )
                        ) {
                          this.context.setSnack({
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: "Member Already Added To Your Profile",
                            vertical: AlertProps.vertical.top,
                            horizontal: AlertProps.horizontal.center,
                            tone: true,
                          });
                        } else {
                          addmemberlist !== null
                            ? this.senOtpMMr()
                            : this.props.nextSection(
                              "addmemberotp",
                              "addMember"
                            );
                        }
                      }}
                    ></Button>
                  )}
                </>
              )}
            </Collapse>
          </Card>

          {/* </Collapse> */}

          {/* <Collapse
            key={"3"}
            title={"New Member"}
            subtitle={""}
            body={"Collapse Body"}
            iconPosition={"left"}
            iconHeight={"20"}
            iconWidth={"20"}
            url={""}
            style={qdmstyles.JgF}
            openIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
            }
            closeIcon={
              <svg
                width="26px"
                class="MuiSvgIcon-root jss107"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>
              </svg>
            }
          > */}
          <Card
            id={`${parent_id}_addmember_newmember_card`}
            style={{
              marginTop: "6%",
              boxShadow: "none",
              border: "2px solid #e0e0e0",
              borderRadius: 8,
            }}
          >
            <CardActions
              id={`${parent_id}_addmember_newmember_cardactions`}
              onClick={() => this.handleExpandClick(2)}
              disableSpacing
              style={{
                paddingLeft: 20,
                paddingBottom: 0,
                cursor: "pointer",
                paddingTop: 0,
              }}
            >
              <Text
                id={`${parent_id}_addmember_newmember_text`}
                key={"0"}
                name="New Member"
                style={{ fontSize: "16px", fontWeight: "800" }}
                className={"pc_semibold"}
              />

              <IconButton
                id={`${parent_id}_addmember_newmember_show_iconbutton`}
                className={clsx(classes.expand, {
                  [classes.expandOpen]: this.state.expanded2,
                })}
                aria-expanded={this.state.expanded2}
                aria-label="show more"
              >
                <ExpandMoreIcon
                  id={`${parent_id}_addmember_newmember_expandmoreicon`}
                />
              </IconButton>
            </CardActions>
            <Collapse
              id={`${parent_id}_addmember_newmember_collapse`}
              className={classes.body}
              in={this.state.expanded2}
              timeout="auto"
              unmountOnExit
            >
              <Text
                id={`${parent_id}_addmember_newmember_registered_text`}
                key={"0"}
                name={
                  "If the member is not registered with Apollo hospital before. Please proceed with Add New Member."
                }
                style={qdmstyles.oZszx}
                className={"pc_regular"}
              ></Text>

              <Button
                id={`${parent_id}_addmember_newmember_form_button`}
                key={"1"}
                type={"button"}
                variant={"text"}
                name={this.props.name}
                style={qdmstyles.Hdva}
                onClick={() => this.props.nextSection("addmemberform")}
                className={"pc_medium"}
              ></Button>
            </Collapse>
          </Card>
          {/* </Collapse> */}
        </Container>
      </div>
    );
  }
}
AppointmentPageDrawer.contextType = AlertContext;

const mapStateToProps = (state) => ({
  readPerson: state?.personApiSlice?.readPerson,
  CountryCode: state?.authSlice?.country_code_list
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(AppointmentPageDrawer))
);

// export default connect(
//   mapStateToProps,
//   actions
// )(withRouter(AppointmentPageDrawer));
