import React from 'react';
import { H6, Image } from 'qdm-component-library';
import { Grid } from '@material-ui/core';
import { UIColor } from '../../../utils';

export const DetailCard = (props) => {
    const { parent_id } = props

    const styles = {
        title: {
            fontSize: 12,
            textAlign: "left",
            //font: "normal normal normal 12px/18px Poppins",
            letterSpacing: "0px",
            color: UIColor.tertiaryText.color,
            opacity: 1,
            fontWeight: 400
        },
        subtitle: {
            fontSize: 14,
            textAlign: "left",
            //font: "normal normal normal 14px/21px Poppins",
            letterSpacing: "-0.34px",
            color: UIColor.primaryText.color,
            opacity: 1
        },
        wrapper: {
            //padding: '0px 20px 20px 20px',
            //overflow: 'auto'
            padding: "4px"
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
    };

    return (
        <div id={`${parent_id}_doctor_profile_parent_div`} style={styles.wrapper}>
            <Grid container spacing={2} id={`${parent_id}_assign_practitioner_header_grid`}>
                <Grid item xs={2} id={`${parent_id}_assign_practitioner_header_item_grid`}>
                    {/* {props?.icon && 
                        props?.icon
                    } */}
                    {props?.icon && <Image style={{ width: 16, height: 16 }} src={props?.icon} alt={'image'} />}
                </Grid>
                <Grid item xs={10} id={`${parent_id}_assign_practitioner_header_item_grid`}>
                    <H6 style={styles.title}>
                        {props?.title && 
                            props?.title
                        }
                    </H6>
                    <H6 style={styles.subtitle}>
                        {props?.subTitle && 
                            props?.subTitle
                        }
                    </H6>
                </Grid>
            </Grid>
        </div>
    )
}

DetailCard.defaultProps = {
    icon: "",
    title: "",
    subTitle: ""
}