import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles,Tooltip } from "@material-ui/core";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
    Div,
    Icons,
    Text,
    Row,
    Button,
    Col,
    H6,
    Modal,
    DialogContent,
    Image
} from "qdm-component-library";
import CashPayment from './cashPayment';
import CreditCardPayment from './creditCardPayment';
import ChequePayment from './chequePayment';
import CollectionSummary from './collectionSummary';
import { PatientCareTypeCard, PatientStatusCard, TopNavbar } from "../../components";
import { TableList } from '../../screens';
import { collectionTitle } from "../../utils/routesList";
import { Routes } from "../../router/routes";
import { withQueue } from "../../HOCs";
import { PatientInfoCard } from '../../components'
const materialStyles = (theme) => ({
    hoverDiv: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EBF4FE',
        border: '1px solid #EBF4FE',
        overflow: 'hidden',
        cursor: 'pointer',
        marginBottom: 10,
        '&:hover': {
            border: '1px solid #0071F2'
        },
    },
    iconColor: {
        color: '#0071F2'
    },
    addIcon: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed #0071F2',
        overflow: 'hidden',
        cursor: 'pointer',
        position: 'absolute',
        backgroundColor: '#fff',
        zIndex: 1,
        // transition: 'all 0.2s',
        '& div:first-child': {
            transform: 'rotate(0deg)',
            transition: 'transform 0.3s',
        },
        '&:hover': {
            width: 170,
            borderRadius: 30,
            justifyContent: 'space-around',
            '& div:first-child': {
                transform: 'rotate(45deg)',
                transition: 'transform 0.3s',
            },
            '& div': {
                display: 'flex'
            },
        },
        '& div:not(:first-child)': {
            display: 'none'
        }
    },
    givePaddingBottom: {
        paddingBottom: '60px'
    },
    popOverStyle: {
        cursor: 'pointer'
    },
    tooltip: {
        backgroundColor: "#000",
      },
      arrow: {
        color: "#000",
      }
});


class Collection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            open: false,
            modal: false,
            selectOption:{
                pic: "images/Group 90507.svg",
                name: null,
                care_type: "TPA, NEXTCARE",
                title_name: "AINQA-Ex"
            }
        };
        this.successfun = this.successfun.bind(this)
    }

    styles = {
        rowFlex: {
            display: 'flex',
            alignItems: 'center'
        },
        columnFlex: {
            display: 'flex',
            flexDirection: 'column'
        },
        h6: {
            fontSize: 14,
            color: '#000000',
            margin: 0
        },
        span: {
            fontSize: 12,
            color: '#6F6F6F',
            margin: 0
        },
        rectWrap: {
            padding: '2px 7px',
            backgroundColor: '#F0F0F0',
            borderRadius: 5,
            minWidth: 55
        },
        underline: {
            textDecoration: 'underline',
            color: '#006EEB',
            cursor: 'pointer'
        },
        columnStyle: {
            padding: 10,
            backgroundColor: '#fff',
            borderRadius: 5,
        },
        empty: {
            margin: 0,
            color: '#6F6F6F',
            opacity: '0.5',
            fontSize: 16,
        },
        divider: {
            margin: '5px 0px',
            border: '0.5px dashed #EEEEEE',
            borderRadius: 10
        },
        outlinedButton:{
            borderColor:'#0071F2',
            color:'#0071F2',
            backgroundColor:'#fff',
            borderRadius:8,
            padding:'8px 15px',
            minWidth:100,
            fontSize:14,
            fontFamily:'pc_medium !important'
        },
        containedButton:{
            backgroundColor:'#0071F2',
            borderColor:'#0071F2',
            borderRadius:8,
            padding:'8px 15px',
            minWidth:100,
            fontSize:14,
            fontFamily:'pc_medium !important'
        },
        borderStyle:{
            borderColor:'#E0E0E0',
            borderRadius:4,
            height:25,
            width:100
        }
    }

    componentDidMount() { }

    shouldComponentUpdate(props, state) {
        return true;
    }

    billNumber = (val) => {
        return (
            <Div className="pc_regular" style={this.styles.underline}>{val}</Div>
        )
    }

    changeState = (key, val) => {
        this.setState({
            ...this.state, [key]: val
        })
    }

    handleClose = (e) => {
        this.setState({
            ...this.state, open: false
        })
        e.stopPropagation();
    }
    successfun() {
        const { nextRoute } = this.props.location?.state ?? {};
        const nr = nextRoute ? nextRoute : Routes.PatientDashBoard
        this.props.history.push({
            pathname: nr,
            state: { nextRoute: Routes.PatientDashBoard }
        })
    }

    iconData=[
        {
            url:'images/icons8-cash-in-hand.svg',
            title:'Cash'
        },
        {
            url:'images/icons8-credit-card.svg',
            title:'Card'
        },
        {
            url:'images/icons8-paycheque.svg',
            title:'Cheque'
        },
    ]

    tableHeader = ["S.No", "Bill Type", "Bill No.", "Date", "Gross Amt.", "Discount", "Net Payable", "Patient payable" ,"Paid", "Outstanding", "Settle Now"];

    
    tableData = [
        {
            billType: 'Consultation',
            billNo: this.billNumber('123456'),
            date: '07/05/1993',
            grossAmt: '980.00',
            discount: '60.00',
            netPayable: '920.00',
            patientPayable: '488.00',
            paid: '200.00',
            outstanding: '288.00',
            settleNow: '288.00'
        },
        {
            billType: 'Lab Service',
            billNo: this.billNumber('123456'),
            date: '07/05/1993',
            grossAmt: '980.00',
            discount: '60.00',
            netPayable: '920.00',
            patientPayable: '488.00',
            paid: '200.00',
            outstanding: '288.00',
            settleNow: '288.00'
        },
    ]
    
    tableDataList=["billType", "billNo", "date", "grossAmt", "discount", "netPayable", "patientPayable", "paid", "outstanding", "settleNow"];
    
    billingStatus=['Total Net Amount','','','','','','','488.00','200.00','288.00','488.00']
    
    modalTableHeader = ["S.No", "Bill Type", "Qty", "Unit", "Rate", "Discount", "Net Payable", "Patient payable" ,"Paid", "Outstanding", "Paid Now"];

    modalTableData = [
        {
            billType: 'Patient Card',
            qty:'1.00',
            unit: 'No',
            rate: '200.00',
            discount: '-',
            netPayable: '920.00',
            patientPayable: '488.00',
            paid: '200.00',
            outstanding: '288.00',
            paidNow: '288.00'
        },
        {
            billType: 'Patient Card',
            qty:'1.00',
            unit: 'No',
            rate: '200.00',
            discount: '-',
            netPayable: '920.00',
            patientPayable: '488.00',
            paid: '200.00',
            outstanding: '288.00',
            paidNow: '288.00'
        },
        {
            billType: 'Patient Card',
            qty:'1.00',
            unit: 'No',
            rate: '200.00',
            discount: '-',
            netPayable: '920.00',
            patientPayable: '488.00',
            paid: '200.00',
            outstanding: '288.00',
            paidNow: '288.00'
        },
    ]

    modalTableDataList=["billType", "qty", "unit", "rate", "discount", "netPayable", "patientPayable", "paid", "outstanding", "paidNow"];

    handleTableClick = (val) =>{
        if(val==='billNo'){
            this.changeState('modal', true)
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <TopNavbar successfun={this.successfun} sucessBtnName={"Finalize Collection"} navBarArr={[]} title={collectionTitle} noUpload={true} />
                <Row inLineStyles={{ backgroundImage: 'linear-gradient(to right, #F7ECF4, #DAEAF8)', padding: '5px 10px' }}>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
                        <PatientInfoCard  pic={"https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"} name={"Chris Richards Chavez"} age={"26 Yrs"} gender={"Male"} mrn_id={"MRN037894"} pi_id={"PI 46056235"} />
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
                        <PatientStatusCard  pic={"images/Group 90506.svg"} titles={["EN-65892", "OP", "Followup", "Cardiology"]} date={"24 Mar,2021"} languages={"Tamil, English"} />
                    </Col>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12} inLineStyles={{ padding: 5 }}>
                        <PatientCareTypeCard 
                            careList={[
                                        {
                                            pic: "images/Group 90507.svg",
                                            name: null,
                                            care_type: "TPA, NEXTCARE",
                                            title_name: "AINQA-Ex"
                                        },
                                        {
                                            pic: "images/Group 90507.svg",
                                            name: null,
                                            care_type: "TPA, NEXTCARE",
                                            title_name: "AINQA-Ex"
                                        },
                                        {
                                            pic: "images/Group 90507.svg",
                                            name: null,
                                            care_type: "JPA, NEXTCARE",
                                            title_name: "AINQA-Ex"
                                        }
                                    ]}
                                    selected={this.state.selectOption}
                                    onChange={(data)=>this.changeState('selectOption',data)}
                        />
                    </Col>
                </Row>
                <Row inLineStyles={{ padding: '10px 20px', backgroundColor: '#F7F7F7' }}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{...this.styles.columnStyle,padding:0}}>
                        <Div style={{ ...this.styles.rowFlex, padding: 10 }}>
                            <H6 className="pc_semibold" style={this.styles.h6}>Billing Outstanding As On 27 May,2021 10:32</H6>
                            <Div style={{ ...this.styles.rectWrap, marginLeft: 10 }}>
                                <H6 className="pc_semibold" style={this.styles.h6}>In INR</H6>
                            </Div>
                        </Div>
                        <Div>
                            <TableList
                                tableHeader={this.tableHeader}
                                tableData={this.tableData}
                                isSerialNo={true}
                                // isCheckBox={true}
                                tableDataList={this.tableDataList}
                                loading={false}
                                isempty={false}
                                onClickTableCell={(val)=>this.handleTableClick(val)}
                                // onChangeTable={(val,e)=>this.changeState(val,e.target.value)}
                                billingStatus={this.billingStatus}
                            />
                        </Div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} style={{ ...this.styles.columnStyle, marginTop: 10 }}>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={9} xl={9}>
                                <Div style={{ display: 'flex' }}>
                                    <Div className={classes.givePaddingBottom}>
                                        {
                                            this.iconData?.map((l, i) => {
                                                return (
                                                    <Div style={this.styles.rowFlex}>
                                                         <Tooltip title={l?.title} arrow placement="right" classes={classes}>
                                                             <div>
                                                                <Div className={classes.hoverDiv} onClick={() => this.changeState('selected', i + 1)}>
                                                                    <Image src={l?.url} alt={`img-${i}`} style={{width:30,height:30}} />
                                                                </Div>
                                                            </div>
                                                        </Tooltip>
                                                        {
                                                            this.state.selected === i + 1 &&
                                                            <Div>
                                                                <ArrowRightIcon className={classes.iconColor} />
                                                            </Div>
                                                        }
                                                    </Div>
                                                )
                                            })
                                        }
                                        <Div className={classes.addIcon}>
                                            <Div style={{ display: 'flex' }}>
                                                <Image src={'images/icons8-plus-math.svg'} alt={`plus`} style={{width:20,height:20}} />
                                            </Div>
                                            <Tooltip title={'Paypal'} arrow placement="bottom" classes={classes}>
                                                    <div style={{marginRight:10}}>
                                                        <Image src={'images/icons8-cash-in-hand.svg'} alt={`paypal`} style={{width:30,height:30}} />
                                                    </div>
                                            </Tooltip>
                                            <Tooltip title={'Wallet'} arrow placement="bottom" classes={classes}>
                                                    <div style={{marginRight:10}}>
                                                        <Image src={'images/icons8-cash-in-hand.svg'} alt={`wallet`} style={{width:30,height:30}} />
                                                    </div>
                                            </Tooltip>
                                        </Div>
                                    </Div>
                                    <Div style={{ ...this.styles.columnFlex, flex: 1, padding: 20,paddingTop:5 }}>
                                        {   this.state.selected === null &&
                                            <Div style={{ ...this.styles.rowFlex, justifyContent: 'center', height: '100%', width: '100%' }}>
                                                <H6 className="pc_regular" style={this.styles.empty}>Choose Payment Mode</H6>
                                            </Div>
                                        }
                                        {   this.state.selected === 1 &&
                                            <CashPayment />
                                        }
                                        {   this.state.selected === 2 &&
                                            <CreditCardPayment />
                                        }
                                        {   this.state.selected === 3 &&
                                            <ChequePayment />
                                        }
                                        {   this.state.selected > 0 &&
                                            <Div style={{ ...this.styles.rowFlex, justifyContent: 'flex-end', marginTop: 20 }}>
                                                <Button className="nextKing-btn" inLineStyles={this.styles.outlinedButton}>Cancel</Button>
                                                <Button className="nextKing-btn" inLineStyles={this.styles.containedButton}>Save</Button>
                                            </Div>
                                        }
                                    </Div>
                                </Div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={3} xl={3} style={{ backgroundColor: '#F5FAFF', padding: 0 }}>
                                <CollectionSummary selected={this.state.selected} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal open={this.state.modal} onClose={() => this.changeState('modal', false)} width={820} inLineStyles={{borderRadius:8}}>
                    <DialogContent inLineStyles={{ padding: 0 }}>
                        <Div>
                            <Div style={{ ...this.styles.rowFlex, padding: 10 }}>
                                <H6 className="pc_semibold" style={{ ...this.styles.h6, flex: 1 }}>Charge Info (Bill No:12345,Dated On 27 May,2021)</H6>
                                <Div style={{ ...this.styles.rowFlex, color: '#0071F2' }}>
                                    <Div style={{ ...this.styles.rowFlex, marginRight: 10 }}>
                                        <Icons fontIcon="download" inLineStyles={{ marginRight: 10 }} />
                                        <Text className="pc_regular" style={{ ...this.styles.span, color: 'inherit' }}>Download</Text>
                                    </Div>
                                    <Div style={this.styles.rowFlex}>
                                        <Icons fontIcon="print" inLineStyles={{ marginRight: 10 }} />
                                        <Text className="pc_regular" style={{ ...this.styles.span, color: 'inherit' }}>Print</Text>
                                    </Div>
                                </Div>
                            </Div>
                            <TableList
                                tableHeader={this.modalTableHeader}
                                tableData={this.modalTableData}
                                isSerialNo={true}
                                // isCheckBox={true}
                                tableDataList={this.modalTableDataList}
                                loading={false}
                                isempty={false}
                            />
                        </Div>
                    </DialogContent>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default withStyles(materialStyles)(connect(mapStateToProps, actions)(withRouter(withQueue(Collection))));