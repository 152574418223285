import React from "react";
import CommonTable from "./table";
import TableModel from "./tableModel";
import { Grid } from "@material-ui/core";
// import DailyDashboard from "./dailyDashboard";
import { useSelector } from "react-redux";

const tableHeader = [
    { label: "Day End Process", rowSpan: 2 },
    { label: "Total Appointment Status", colSpan: 2, align: "center" },
    { label: "Total Encounters Status", colSpan: 2, align: "center" },
    { label: "status", rowSpan: 2 },
    { label: "Reason of failure", rowSpan: 2 },
    { label: "Action", rowSpan: 2 }
]

const tableHeader1 = [
    { label: "No-show" },
    { label: "process Failed" },
    { label: "Closed" },
    { label: "process Failed" }
]

const tableData = [
    { label: "date", type: "TEXT" }, { label: "AppNoshows", type: "TEXT" }, { label: "AppFailed", type: "TEXT" }, { label: "EncClosed", type: "TEXT" },
    { label: "EncFailed", type: "TEXT" }, { label: "curStatus", type: "TEXT" }, { label: "errorlog", type: "TEXT" }, { label: "action", type: "VIEWLOG" },
]

const Data = []

function DayEndProcessDashboard({ viewLogDaily = () => null }) {
    const dayList = useSelector((state) => state?.dayEndProcessSlice?.getDayEndProcess);
    const [data, setData] = React.useState(null)
    const viewLog = (data) => {
        // setData(data)
        viewLogDaily(data)
    }

    return <React.Fragment>
        <Grid container>
            <Grid md="12" style={{ padding: "22px 20px 0px" }}>
                <CommonTable
                    Header={tableHeader}
                    Header1={tableHeader1}
                    tableData={tableData}
                    dataList={dayList?.data ?? []}
                    viewLog={viewLog}
                    pagination={true}
                />
                {/* {JSON.stringify(dayList)} */}
            </Grid>
        </Grid>

        {data && <TableModel data={data} viewLogDaily={viewLogDaily} closeFun={() => setData(null)} />}
    </React.Fragment>
}


export default DayEndProcessDashboard;