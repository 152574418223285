import React from "react";
import { actions } from "primarycare-binder";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Queue, QueueCard } from "../";
import {
  Tab,
  Tabpanel,
  SideMenu,
  Div,
  Row,
  Col,
  TextInput,
  Modal,
} from "qdm-component-library";
import SearchIcon from "@material-ui/icons/Search";
import AssignReassign from "../../screens/dashboard/assignReassign";
import { UIColor } from "../../utils";

class QueueDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkName: "",
    };
  }

  componentDidMount() {}

  shouldComponentUpdate(props, state) {
    return true;
  }
  onHoldData = () => {
    if (this.state.checkName) {
      var arr = [];
      const data = this.props?.onHoldData?.filter((v) => {
        if (
          v.name
            ?.toLocaleLowerCase()
            .includes(this.state.checkName?.toLocaleLowerCase())
        ) {
          return arr.push(v);
        }
      });
      return arr;
    }
  };
  filterData = () => {
    var arr = [];
    const data = this.props?.pendingData?.filter((v) => {
      if (
        v.name
          ?.toLocaleLowerCase()
          .includes(this.state.checkName?.toLocaleLowerCase()) && v.showBringtoTop === true
      ) {
        return arr.push(v);
      }
    });
    return arr;
  };
  pastData = () => {
    var arr = [];
    const data = this.props?.pastData?.filter((v) => {
      if (
        v.name
          ?.toLocaleLowerCase()
          .includes(this.state.checkName?.toLocaleLowerCase())
      ) {
        return arr.push(v);
      }
    });
    return arr;
  };

	orderList = (a) => {
		const data = a.sort(
			(a, b) => parseFloat(b.personId) - parseFloat(a.personId)
		);
		return data;
	};

	render() {
		const {
			previous,
			attend,
			next,
			pendingData,
			open,
			onClose,
			onHoldData,
			pastData,
			permission,
		} = this.props;
		return (
			<div>
				<SideMenu
					id="sidemenu_parent_sidemenu"
					// onDoubleClick={e=>console.log(e)}
					// data={drawerdata}
					open={open}
					inLineStyles={{ zIndex: 99999999, padding: 0 }}
					direction="right"
					width={900}
					color="default"
					onClose={onClose}
					// open={true}
				>
					<Row id="sidemenu_parent_row">
						<Col
							id="sidemenu_parent_col"
							xl={5}
							lg={5}
							md={5}
							sm={12}
							xs={12}
							inLineStyles={{
								padding: "20px 15px",
								backgroundColor: UIColor.differentiationBackground.color,
								height: "100vh",
								overflow: "auto",
							}}
						>
							<div id="sidemenu_parent_queue_div">
								<Queue
									markHighPriority={
										!(permission?.write?.indexOf("markHighPriorityQ") > -1)
									}
									assignReassignPractitioner={
										!(
											permission?.write?.indexOf(
												"assignReassignPractitionerQ"
											) > -1
										)
									}
									parent_id={"dashboard"}
									previous={previous}
									attend={attend}
									next={next}
								/>
							</div>
						</Col>
						<Col
							id="sidemenu_tab_col"
							xl={7}
							lg={7}
							md={7}
							sm={12}
							xs={12}
							inLineStyles={{ padding: 20, height: "100vh", overflow: "auto" }}
						>
							<Tab
								id="sidemenu_parent_tab"
								activetabs={0}
								variant="contained"
								borderColor={UIColor.differentiationBackground.color}
								size="small"
								activeColor={UIColor.secondaryButtonColor.color}
								labelStyle={{
									fontFamily: "pc_medium !important",
									fontSize: 14,
									border: "none",
									padding: "6px 8px"
								}}
								backgroundColor={UIColor.lineBorderFill.color}
								onChange={(e) => {
									if (typeof e === "number") {
										this.setState({
											...this.state,
											checkName: "",
										});
									}
								}}
							>
								<Tabpanel
									title=""
									id="sidemenu_tab_tabpanel"
									label="Pending"
									number={`${this.filterData().length}`}
									inLineStyles={{
										border:"none"
									}}
								>
									<Div id="sidemenu_search_input_div">
										<Div
											id="sidemenu_search_input_sub_div"
											style={{ display: "flex", position: "relative" }}
										>
											<TextInput
												id="sidemenu_search_input_textinput"
												value={this.state.checkName}
												onChange={(evt) => {
													this.setState({
														...this.state,
														checkName: evt.target.value,
													});
												}}
												style={{
													margin: "20px 0px 15px",
													borderRadius: 8,
													borderColor: UIColor.lineBorderFill.color,
												}}
												// search
												variant={"outlined"}
												// id={"inputId"}
												label=""
												placeholder="Search"
												hoverColor="#0071F2"
											/>
											<SearchIcon
												id="sidemenu_search_searchicon"
												style={{
													position: "absolute",
													top: 29,
													right: 20,
													color: UIColor.lineBorderFill.color,
												}}
											/>
										</Div>
										<div
											style={{
												height: "calc(100vh - 10.1rem)",
												overflow: "auto",
											}}
										>
											{(
												this.filterData() || [
													...this.orderList(this.props.pendingData),
												]
											).map((l, i) => {
												return (
													<QueueCard
														parent_id={"dashboard_queuecard_pending" + i}
														id={l.token}
														markHighPriority={
															!(
																permission?.write?.indexOf("markHighPriorityQ") >
																-1
															)
														}
														assignReassignPractitioner={
															!(
																permission?.write?.indexOf(
																	"assignReassignPractitionerQ"
																) > -1
															)
														}
														type="All"
														showBtn={l?.showBringtoTop}
														name={l?.name}
														time={l?.start}
														token={l?.token}
														role={l?.role}
														profile_pic={l?.profile_pic}
														isEmergency={l?.isEmergency}
														totalData={l}
														assignButtonAction={() => {
															let assigned =
																l?.info?.encounter?.[0]?.participant?.length > 0
																	? true
																	: false;
															this.props.assignPractitioner(
																assigned ? "reassign" : "assign",
																l
															);
														}}
														assignButtonIcon={
															l?.info?.encounter?.[0]?.participant?.length > 0
																? "images/icons8-repeat.svg"
																: "images/icons8-change-user.svg"
														}
														assigned={
															l?.info?.encounter?.[0]?.participant?.length > 0
																? true
																: false
														}
														role_={l.type}
													/>
												);
											})}
										</div>
									</Div>
								</Tabpanel>
								<Tabpanel
									title=""
									id="sidemenu_parent_tabpanel"
									label="On Hold"
									number={`${onHoldData.length}`}
								>
									<Div id="sidemenu_sub_div">
										<Div
											id="sidemenu_search_div"
											style={{ display: "flex", position: "relative" }}
										>
											<TextInput
												id="sidemenu_search_text"
												size="medium"
												value={this.state.checkName}
												onChange={(evt) => {
													this.setState({
														...this.state,
														checkName: evt.target.value,
													});
												}}
												// search={
												//   true
												// }
												// InputProps={{
												//   endAdornment: <SearchIcon />
												// }}
												style={{
													margin: "20px 0px 15px",
													borderRadius: 8,
													borderColor: "#E0E0E0",
												}}
												// search
												variant={"outlined"}
												// id={"inputId"}
												label=""
												placeholder="Search"
												hoverColor="#0071F2"
											/>
											<SearchIcon
												id="sidemenu_search_searchicon"
												style={{
													position: "absolute",
													top: 29,
													right: 20,
													color: "#e0e0e0",
												}}
											/>
										</Div>
										{(
											(this.onHoldData()?.length > 0 && this.onHoldData()) || [
												...this.orderList(this.props.onHoldData),
											]
										).map((l, i) => (
											<QueueCard
												parent_id={"dashboard_queuecard_hold" + i}
												isHold
												id={i}
												name={l?.name}
												time={l?.start}
											    totalData={l}
												markHighPriority={!(permission?.write?.indexOf("markHighPriorityQ") > -1)}
												assignReassignPractitioner={!(permission?.write?.indexOf("assignReassignPractitionerQ") > -1)}
												token={l?.token}
												role={l?.role}
												profile_pic={l?.profile_pic}
												isEmergency={l?.isEmergency}
												assignButtonAction={() => {
														let assigned =
															l?.info?.encounter?.[0]?.participant?.length > 0
															? true
															: false;
														this.props.assignPractitioner(
															assigned ? "reassign" : "assign",
															l
														);
														}}
														assignButtonIcon={
														l?.info?.encounter?.[0]?.participant?.length > 0
															? "images/icons8-repeat.svg"
															: "images/icons8-change-user.svg"
														}
														assigned={
														l?.info?.encounter?.[0]?.participant?.length > 0
															? true
															: false
														}
														role_={l.type}
											/>
										))}
									</Div>
								</Tabpanel>
								<Tabpanel
									title=""
									id="sidemenu_parent_tabpanel"
									label="Completed"
									number={`${pastData.length}`}
								>
									<Div id="sidemenu_parent_div">
										<Div
											id="sidemenu_sub_div"
											style={{ display: "flex", position: "relative" }}
										>
											<TextInput
												id="sidemenu_search_textinput"
												value={this.state.checkName}
												onChange={(evt) => {
													this.setState({
														...this.state,
														checkName: evt.target.value,
													});
												}}
												style={{
													margin: "20px 0px 15px",
													borderRadius: 8,
													borderColor: "#E0E0E0",
												}}
												// search
												variant={"outlined"}
												// id={"inputId"}
												label=""
												placeholder="Search"
												hoverColor="#0071F2"
											/>
											<SearchIcon
												id="sidemenu_search_searchicon"
												style={{
													position: "absolute",
													top: 29,
													right: 20,
													color: "#e0e0e0",
												}}
											/>
										</Div>
										{(
											this.pastData() || [
												...this.orderList(this.props.pastData),
											]
										).map((l, i) => (
											<QueueCard
												parent_id={"dashboard_queuecard_past" + i}
												id={i}
												val={l}
												name={l?.name}
												token={l?.token}
												// permission={permission}
												nocancelVisit={
													!(permission?.write?.indexOf("cancelVisit") > -1)
												}
												nomodifyVisit={
													!(permission?.write?.indexOf("modifyVisit") > -1)
												}
												noviewVisit={
													!(permission?.write?.indexOf("viewVisit") > -1)
												}
												markHighPriority
												// markHighPriority={
												// 	!(permission?.write?.indexOf("markHighPriorityQ") > -1)
												// }
												// assignReassignPractitioner={
												// 	!(
												// 		permission?.write?.indexOf(
												// 			"assignReassignPractitionerQ"
												// 		) > -1
												// 	)
												// }
												NureCloseConsultation={(permission?.write?.indexOf("closeConsultationN") > -1)}
												DoctorCloseConsultation={(permission?.write?.indexOf("closeConsultationD") > -1)}
												assignReassignPractitioner
												role={l?.role}
												completedView
												time={l?.start}
												profile_pic={l?.profile_pic}
												isEmergency={l?.isEmergency}
												closeButtonAction={() => {
													
													this.props.closeConsultation("close", l);
												  }}
											/>
										))}
									</Div>
								</Tabpanel>
							</Tab>
						</Col>
					</Row>
				</SideMenu>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(withRouter(QueueDrawer));
