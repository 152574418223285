import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Routes } from "./../../router/routes";
import {
  Container,
  Row,
  Col,
  // Avatar,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
  H6,
  TextInput
} from "qdm-component-library";
import { AlertContext } from '../../contexts'
import { checkError, AlertProps, fetchQueueData, UIColor } from '../../utils'
import { withAllContexts } from "../../HOCs";
// import Logo from "../../assets/img/Logo_W.svg";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      error:"",
    };
    this.fetchQueueData = fetchQueueData.bind(this)
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {}

  shouldComponentUpdate(props, state) {
    return true;
  }

  styles = {
    labelStyle: {
      color: '#6F6F6F',
      fontSize: '12px',
      marginBottom: '6px',
      fontFamily: `pc_regular !important`
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: '#E0E0E0',
      marginBottom: 10
    }
  }

  handleRecover = async () => {
    if (this.state.userId) {
      this.setState({
        error: ''
      })

      const payloadlogin = { "username": this.state.userId }
      await this.props.FORGOT_PASSWORD(payloadlogin)

      const status = checkError(this.props.forgotPassword)
      if (status.isError) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: status.errMsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        })
        this.setState({
          error: status.errMsg
        })
      } else {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: "An email has been sent for restting your password.",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        })
        // await this.fetchQueueData();
        // localStorage.setItem('token', res?.payload?.data?.access_token)
        // this.props.history.push({
        //   pathname: '/dashboard',
        // })
      }

    } else {
      const errorMessage = 'Please enter the Registered Email ID !'
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: errorMessage,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      })
      this.setState({
        error: errorMessage
      })
    }
  }

  render() {
    return (
      <div>
        <Container
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#e8e8e8",
            height: "100vh",
            padding: "0px",
          }}
        >
          <Row
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ backgroundColor: UIColor.primaryColor.color, height: "" }}
          >
            <Col
              key={"0"}
              name="qdm-col-12"
              alignItems="center"
              direction="row"
              justify="space-between"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              style={{
                height: "55px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <Avatar
                key={"0"}
                variant="rounded"
                letter="Logo"
                src={Logo}
                alt="Logo"
                style={{
                  borderColor: "#ffffff",
                  backgroundColor: "#ffffff",
                  padding: "8px",
                }}
              /> */}
               <svg xmlns="http://www.w3.org/2000/svg" width="90" height="29.647" viewBox="0 0 90 29.647">
                <g id="AINQA_Logo_High_Res_SVG" data-name="AINQA_Logo High Res SVG" transform="translate(-21.7 -14.098)">
                  <path id="Path_92901" data-name="Path 92901" d="M50.283,28.93A14.592,14.592,0,0,1,35.94,43.745,14.585,14.585,0,0,1,21.7,28.9,14.594,14.594,0,0,1,36.086,14.1C43.9,14.159,50.283,20.8,50.283,28.93Zm-3.019,7.333c-.044-.09-.058-.136-.073-.166l-.174-.271a12.1,12.1,0,0,0-3.368-3.4,12.768,12.768,0,0,0-10.06-2.274,13.714,13.714,0,0,0-8.623,5.707,3.252,3.252,0,0,0-.232.407c2.642,4.02,6.257,6.309,10.974,6.4C40.658,42.782,44.476,40.478,47.263,36.262Zm-23.081-1.1c3.571-6.67,7.128-13.28,10.684-19.92a11.716,11.716,0,0,0-4.761,1.34A13.509,13.509,0,0,0,24.7,21.7,14.061,14.061,0,0,0,24.182,35.163Zm23.662-.105a14.033,14.033,0,0,0-.436-13.19,13.546,13.546,0,0,0-5.69-5.375,12.356,12.356,0,0,0-4.457-1.28C40.8,21.868,44.316,28.448,47.844,35.058ZM36.071,15.138c-1.6,2.981-3.165,5.9-4.689,8.838a13.2,13.2,0,0,1,4.674-.873,12.487,12.487,0,0,1,4.674.9.116.116,0,0,0,.029-.06C39.207,21.025,37.653,18.119,36.071,15.138Zm-9.16,17.12a13.921,13.921,0,0,1,18.291.105.334.334,0,0,0-.029-.151c-.494-.918-.973-1.837-1.466-2.74a.678.678,0,0,0-.29-.286,14.076,14.076,0,0,0-8.231-2.063,15.044,15.044,0,0,0-6.474,2.048.892.892,0,0,0-.363.376c-.435.828-.886,1.656-1.336,2.5A1.388,1.388,0,0,0,26.911,32.257ZM29.379,27.6c.015.015.029.045.044.06.087-.03.16-.075.247-.105.74-.3,1.481-.647,2.236-.9a12.863,12.863,0,0,1,5.5-.587,15.58,15.58,0,0,1,5.1,1.506c.058.03.116.03.232.075-.421-.8-.813-1.521-1.19-2.228a12.825,12.825,0,0,0-10.989,0C30.163,26.129,29.771,26.867,29.379,27.6Z" transform="translate(0 0)" fill="#fff"/>
                  <path id="Path_92902" data-name="Path 92902" d="M461.558,78.663v1.365a1.982,1.982,0,0,1-.276.029c-1.771,0-3.527.015-5.3,0a6.528,6.528,0,0,1-2.41-.465,4.686,4.686,0,0,1-2.845-3,5.709,5.709,0,0,1,.581-5.139,5.221,5.221,0,0,1,5.182-2.468,5.129,5.129,0,0,1,4.573,3.644,5.565,5.565,0,0,1-.363,4.457,4.2,4.2,0,0,1-1.1,1.365.941.941,0,0,0-.131.116.407.407,0,0,1-.014.087c.232,0,.45.015.682.015h1.423ZM455.9,70.432a5,5,0,0,0-.653.073,4.043,4.043,0,0,0-3.194,3.208,4.085,4.085,0,0,0,1.7,4.166,3.823,3.823,0,0,0,5.56-1.365,4.2,4.2,0,0,0-.856-5.037A3.742,3.742,0,0,0,455.9,70.432Z" transform="translate(-362.429 -46.398)" fill="#fff"/>
                  <path id="Path_92903" data-name="Path 92903" d="M379.365,70.5h1.5c.073.261.1,10.074.029,10.858-.116,0-.232.015-.348.015l-3.615-3.745-3.615-3.745a.562.562,0,0,0-.073.421v7.055H371.7V70.5h.421c2.381,2.453,4.776,4.921,7.229,7.461.044-.421.015-.769.029-1.1V71.574C379.365,71.211,379.365,70.863,379.365,70.5Z" transform="translate(-296.532 -47.722)" fill="#fff"/>
                  <path id="Path_92904" data-name="Path 92904" d="M548.52,81.358h-1.771c-.3-.653-.639-1.336-.958-2H540.1c-.3.653-.624,1.321-.944,2.018H537.4c1.786-3.644,3.557-7.244,5.342-10.858a1.1,1.1,0,0,1,.218-.015h.2C544.949,74.086,546.72,77.686,548.52,81.358ZM540.8,77.976h4.34c-.726-1.539-1.423-3.048-2.119-4.544h-.087C542.234,74.942,541.523,76.437,540.8,77.976Z" transform="translate(-436.82 -47.722)" fill="#fff"/>
                  <path id="Path_92905" data-name="Path 92905" d="M260.705,81.358h-1.771c-.319-.668-.624-1.336-.944-2H252.3c-.3.653-.624,1.321-.944,2H249.6c1.786-3.644,3.557-7.244,5.342-10.858h.435Q258.012,75.9,260.705,81.358Zm-5.531-7.984c-.073.145-.116.2-.145.276-.668,1.423-1.336,2.845-2,4.282,0,.015,0,.015.015.029s.015.015.015.029c.668.058,3.963.029,4.253-.044C256.6,76.437,255.9,74.942,255.174,73.374Z" transform="translate(-193.041 -47.723)" fill="#fff"/>
                  <path id="Path_92906" data-name="Path 92906" d="M342.381,81.358H340.9V70.5h1.466C342.439,70.747,342.468,80.937,342.381,81.358Z" transform="translate(-270.658 -47.723)" fill="#fff"/>
                </g>
              </svg>

              <Div
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Icons
                  key={"0"}
                  fontIcon="phone"
                  ariaHidden="true"
                  size="small"
                  className=""
                  style={{ color: "#fff", margin: "10px", fontSize: 16 }}
                />

                <Text
                  className="pc_regular"
                  key={"1"}
                  name="+91 9821315141 , +91 9551482612"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: 16
                  }}
                />

                <Divider
                  key={"2"}
                  id=""
                  className=""
                  inLineStyles="[object Object]"
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="vertical"
                  variant="middle"
                  style={{ height: "24px", marginTop: "6px" }}
                />

                <Button
                  className="pc_medium"
                  key={"3"}
                  type="button"
                  variant="text"
                  name="Support"
                  style={{
                    borderColor: UIColor.secondaryButtonColor.color,
                    borderRadius: "8px",
                    color: UIColor.secondaryButtonColor.color,
                    height: "35px",
                    lineHeight: 0,
                    fontSize: 14,
                    minWidth: 88,
                    backgroundColor: "#fff",
                    fontWeight: 500
                  }}
                />
              </Div>
            </Col>
          </Row>

          <Col
            key={"1"}
            name="qdm-col-12"
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            textAlign="left"
            lg="12"
            xs="12"
            md="12"
            sm="12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 55px)",
              backgroundColor: "#F0F7FF",
            }}
          >
            <BackgroundImage
              key={"0"}
              url="images/login_bg.svg"
              alt="Mask-Group-71-2x"
              backgroundPosition="0% 0%"
              backgroundPositionX="left"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="contain"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 55px)",
              }}
            >
              <Paper
                key={"0"}
                children="Paper"
                variant="elevation"
                elevation="1"
                style={{
                  width: "350px",
                  // height: "400px",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  padding: "32px",
                  boxShadow: '0px 8px 69px #0000001A',
                  display: "",
                }}
              >
                <H6
                  key={"0"}
                  name="Forgot Password?"
                  style={{
                    fontSize: "24px",
                    fontWeight: "500",
                    color: UIColor.primaryText.color,
                  }}
                  inLineStyles={{ fontFamily: "pc_semibold !important" }}
                />

                <H6
                  className="pc_regular"
                  key={"1"}
                  name="Enter your email ID to receive your password reset instruction"
                  style={{
                    fontSize: "12px",
                    color: UIColor.secondaryText.color,
                    textAlign: "left",
                    marginBottom: "30px",
                    marginTop: "10px",
                  }}
                />

                <TextInput isRequired={true} label="Email ID"
                  placeholder=''
                  labelStyle={this.styles.labelStyle} style={this.styles.borderStyle} hoverColor={UIColor.secondaryText.color}
                  value={this.state.userId}
                  onChange={(e) => this.changeState('userId', e.target.value)}
                />

                {this.state.error && (
                  <H6
                    name={this.state.error}
                    style={{
                      fontSize: '12px',
                      color: 'red',
                      textAlign: 'left'
                    }}
                  />
                )}

                <Button
                  className="pc_medium"
                  key={"3"}
                  onClick={() => {
                      this.handleRecover();
                    //   this.props.history.push(Routes.resetPassword)
                    }}
                  disabled={this.props.forgotPassword?.loading}
                  type="button"
                  variant="outlined"
                  name={"Recover"}
                  style={{
                    margin: "",
                    marginTop: "25px",
                    width: "100%",
                    backgroundColor: UIColor.primaryColor.color,
                    borderColor: UIColor.primaryColor.color,
                    borderRadius: "8px",
                    fontSize: "14px",
                    height: "40px",
                  }}
                />
                <Button
                  className="pc_medium"
                  key={"3"}
                  onClick={() => this.props.history.push('/')}
                  disabled={this.props.forgotPassword?.loading}
                  type="button"
                  variant="outlined"
                  name={"Back to Sign In"}
                  style={{
                    margin: "",
                    marginTop: "15px",
                    width: "100%",
                    backgroundColor: "#fff",
                    borderColor:  UIColor.primaryColor.color,
                    borderRadius: "8px",
                    fontSize: "14px",
                    height: "40px",
                    color: UIColor.primaryColor.color
                  }}
                />

              </Paper>
            </BackgroundImage>
          </Col>
        </Container>
      </div>
    );
  }
}
ForgotPassword.contextType = AlertContext

const mapStateToProps = (state) => ({
    forgotPassword: state?.authSlice?.forgotPassword,
    loggedUserInfo: state?.authSlice?.loggedUserInfo,
});
export default connect(mapStateToProps, actions)(withRouter(withAllContexts(ForgotPassword)));