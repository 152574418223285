import moment from "moment";

export const createVitalsJson = (props, searchPractioner, encounter) => {
    // const { patientid, orgid, encounterid } = props;
    let personDetails = searchPractioner?.data?.[0];
    // let searchPract = personDetails?.practitioner?.[0];
    return {
        // "orgentitytype": "string",
        "orgid": personDetails?.OrgID?.[0],
        "patientid": encounter?.data?.pID,
        "confirmedby": personDetails?._id,
        "encountertype": "op",
        "encounterid": encounter?.data?.eId,

    }
}