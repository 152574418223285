import React from 'react';
import { makeStyles,Typography } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import moment from "moment";
import { UIColor } from '../../../utils';
const useStyles = makeStyles(()=>({
    tabWrapper:{
        display:'flex',
        alignItems:'center',
        marginTop:10,
        '& $wrapper':{
            '&:nth-child(odd)':{
                '& $countWrapper':{
                    borderColor:'#FF6384',
                    color:'#FF6384'
                }
            },
            '&:nth-child(even)':{
                '& $countWrapper':{
                    borderColor:'#4BC0C0',
                    color:'#4BC0C0'
                }
            },
        }
    },
    wrapper:{
        border:'1px solid #E0E0E0',
        borderRadius:22,
        padding:8,
        display:'flex',
        alignItems:'center',
        fontSize:14,
        fontFamily:'pc_regular',
        marginRight:10,
        cursor:'pointer'
    },
    countWrapper:{
        width:29,
        height:29,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        border:'1px dashed',
        borderRadius:'50%',
        padding:5,
        fontSize:14,
        fontFamily:'pc_semibold',
    },
    tabTitle:{
        fontSize:14,
        fontFamily:'pc_regular',
        color:'#101010',
        flex:1,
        marginRight:10,
        paddingLeft:8,
    }
}));

const SwitchTab = (props) =>{
const dispatch = useDispatch();
    const [state, setState] = React.useState(props.options);
    const classes = useStyles(props);

    
  React.useEffect(() => {
    if (props?.options.length > 0) {
        props.options.map((v,i)=>{
          getData(v?.actionsName,i);
        })
      
    }
  }, []);

  
  const getData = async (actionsName,i) => {
   
    let payload = {
      orgId: props.orgID || "",
      startDate: moment().startOf("day").unix(),
      endDate: moment().endOf("day").unix(),
      roleId: props.roleId,
      loginId: props.practionerID,
      orgIdInt: props.orgIDInt || 0,
    };

        if(actionsName){
        let data = await dispatch(actions[actionsName](payload));
        if (actionsName === "APPOINTMENTS"){
            state[i].count = data.payload.data.totalAPP;
            // state[5].count = data.payload.data.checkin;

        } else {
          state[i].count = data.payload.data.count;
        }
        setState([...state])
        // setState((oldArray) => [
        //   ...oldArray,
        //   {
        //     loading: false,
        //     total: null,
        //     count: data.payload.data.count,
        //   },
        // ]);
        // setState();
        
}
   
  };
    return(
        <div className={classes.tabWrapper}>
        {
            state?.map((_,i)=>{
                return(
                    <div 
                        className={classes.wrapper}
                        key={_?.value}
                        style={{
                            backgroundColor:props?.selectedTab?.value === _?.value ? UIColor.secondaryButtonColor.color : '#fff',
                        }}
                        onClick={()=>props?.getSelectedTab(_)}
                    >
                        <Typography 
                            variant="body1"
                            className={classes.tabTitle}
                            style={{color:props?.selectedTab?.value === _?.value ? '#fff' : '#000',}}
                        >{_?.label}</Typography>
                        <div 
                            className={classes.countWrapper}
                            style={{
                                color:props?.selectedTab?.value === _?.value && '#fff',
                                borderColor:props?.selectedTab?.value === _?.value && '#fff',
                            }}
                        >
                           {_?.count}
                        </div>
                    </div>
                )
            })
        }
    </div>
    )
}

SwitchTab.defaultProps={
    options:[], // [{label:'Patient checked-in',value:'patientsCheckedIn',count:'20'},]
    selectedTab:{},
    getSelectedTab:null

}

export default SwitchTab;