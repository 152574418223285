import React from "react";
import { Div, Select, Col, Row, Paper, H6, TextInput, Divider, Checkbox, Button } from 'qdm-component-library'
import "./style.css";
import { withAllContexts } from "../../HOCs";
import { UIColor } from "../../utils";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";
import moment from "moment";

// const data = [{ value: "data" }]

function CardDetails(props) {

    const styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        selectBorderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        },
        inputBorderStyle: {
            borderRadius: 8,
            border: '1px solid #E0E0E0',
        },
        outlinedButton: {
            borderColor: UIColor.primaryColor.color,
            color: UIColor.primaryColor.color,
            backgroundColor: '#fff',
            borderRadius: 8,
            padding: '8px 15px',
            minWidth: 100,
            fontFamily: 'pc_medium !important',
            fontSize: 14
        },
        containedButton: {
            backgroundColor: UIColor.primaryColor.color,
            borderColor: UIColor.primaryColor.color,
            borderRadius: 8,
            padding: '8px 15px',
            minWidth: 100,
            fontFamily: 'pc_medium !important',
            fontSize: 14
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        }
    }

    const { payer, validity, membership, financial, eligibility } = props.financialData

    const setDetails = (name, value, saveto, limit) => {

        if (limit) {
            if (limit < value.length) {
                return false
            }
        }
        let patientData = props.financialData
        if (patientData[saveto].error) {
            patientData[saveto].error[name] = false;
        }
        patientData[saveto][name] = value;
        patientData.setFinancialData({
            ...patientData,
        })
    }

    const FlterOptions = (type) => {
        const { allMasters } = props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
            };
            lista.push(datatemp);
        }
        return lista
    }

    const { labelStyle, selectBorderStyle, inputBorderStyle, containedButton, outlinedButton } = styles;
    const { parent_id } = props

    return < Div
        id={`${parent_id}_parent_div`}
        className="pv-root" style={{
            margin: '3px 15px'
        }
        }>
        <Paper
            id={`${parent_id}_parent_paper`}
            className="pl-root">
            <Row
                id={`${parent_id}_payer_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_payer_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_payer_title_div`}
                    >
                        <H6
                            id={`${parent_id}_payer_label_div`}
                            className="en-title space-padding-bottom">PAYER DETAILS</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_payer_sub_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_payer_priority_div`}
                            md={1} lg={1} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_payer_priority_select`}
                                options={
                                    [
                                        { value: "1" },
                                        { value: "2" },
                                        { value: "3" },
                                        { value: "4" },
                                        { value: "5" },
                                        { value: "6" },
                                    ]
                                }
                                placeholder='Priority'
                                value={payer.priority?.value}
                                onChangeValue={e => setDetails("priority", e, "payer")}
                                label="Priority" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_payer_Reciver_Type_div`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_payer_Reciver_Type_select`}
                                options={[
                                    { value: "TPA" },
                                    { value: "Insurance" },
                                    { value: "Corporate" },
                                    // { value: "Self Pay" }
                                ]}
                                // isRequired
                                showArrow
                                // helperText={(payer?.error && payer?.error['reciver_type']) ? 'Is required' : ''}
                                error={(payer?.error && payer?.error['reciver_type']) ? true : false}
                                value={payer.reciver_type?.value}
                                onChangeValue={e => setDetails("reciver_type", e, "payer")} label="Receiver Type"
                                labelStyle={labelStyle}
                                style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_payer_Reciver_code_div`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_payer_Reciver_code_select`}
                                options={[
                                    { value: "PERKESO/SOCSO" },
                                    { value: "Zakat Selangor" },
                                    { value: "Zakat MAIWP" },
                                    { value: "Jabatan Perkhidmatan Awam (JPA)" },
                                    { value: "Jabatan Hal Ehwal Veteran (JHEV)" },
                                    { value: "Jabatan Kebajikan Masyarakat (JKM)" },
                                    { value: "Sihat Selangor (Pejabat Setiausaha Negeri Selangor)" },
                                    ]}
                                // isRequired
                                // helperText={(payer?.error && payer?.error['reciver_code']) ? 'Is required' : ''}
                                error={(payer?.error && payer?.error['reciver_code']) ? true : false}
                                value={payer.reciver_code?.value}
                                onChangeValue={e => setDetails("reciver_code", e, "payer")}
                                label="Receiver Code" showArrow
                                labelStyle={labelStyle}
                                style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_payer_Guarantor_type_div`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_payer_Guarantor_type_select`}
                                options={[
                                    { value: "Union Insurance Company" },
                                    { value: "United Insurance Company" },
                                    { value: "National General Insurance Company" },
                                    { value: "Orient Insurance" },
                                    { value: "Noor Takeful" },
                                ]}
                                placeholder='Guarantor Type'
                                disabled={payer.reciver_type?.value === 'TPA' ? false : true}
                                value={payer?.guarantor_type?.value} onChangeValue={e => setDetails("guarantor_type", e, "payer")}
                                showArrow label="Guarantor type" labelStyle={labelStyle} style={{ ...inputBorderStyle }} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_payer_Guarantor_id_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_payer_Guarantor_id_select`}
                                options={[
                                    { value: "EXE-Executive" },
                                    { value: "SUP-Supervisor" },
                                    { value: "VIP" },
                                ]}
                                placeholder='Guarantor ID'
                                showArrow disabled={payer.reciver_type?.value === 'TPA' ? false : true}
                                value={payer?.guarantor_code?.value} onChangeValue={e => setDetails("guarantor_code", e, "payer")}
                                label="Guarantor Id" labelStyle={labelStyle} style={{ ...inputBorderStyle }} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                    <Row
                        id={`${parent_id}_payer_sub_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_payer_Membership_ID_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left space15-padding-top space-padding-bottom">
                            <TextInput
                                autoFocus={true}
                                id={`${parent_id}_payer_Membership_ID_textinput`}
                                placeholder='Membership ID' value={payer.membershipId ? payer.membershipId : ""} onChange={e => setDetails("membershipId", e.target.value, "payer", 30)} label="Membership ID" labelStyle={labelStyle} style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_payer_Policy_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left space15-padding-top space-padding-bottom">
                            <Select
                                id={`${parent_id}_payer_Policy_select`}
                                // disabled
                                options={[
                                    { value: "EXE-Executive" },
                                    { value: "SUP-Supervisor" },
                                    { value: "VIP" },
                                ]}
                                placeholder='Policy'
                                showArrow
                                value={payer?.policy?.value}
                                isRequired
                                // helperText={(payer?.error && payer?.error['policy']) ? 'Is required' : ''}
                                error={(payer?.error && payer?.error['policy']) ? true : false}
                                onChangeValue={e => setDetails("policy", e, "payer")}
                                label="Policy" labelStyle={labelStyle}
                                style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_payer_Plan_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left space15-padding-top space-padding-bottom">
                            <Select
                                id={`${parent_id}_payer_Plan_select`}
                                options={[
                                    { value: "GN-General" },
                                    { value: "GC-Comprehensive" },
                                    { value: "Gold Plan" },
                                    { value: "Gold(+) Plan" },
                                    { value: "EN-Enhanced" },
                                ]} value={payer.plan?.value}
                                // helperText={(payer?.error && payer?.error['plan']) ? 'Is required' : ''}
                                isRequired
                                error={(payer?.error && payer?.error['plan']) ? true : false}
                                placeholder='Plan'
                                onChangeValue={e => setDetails("plan", e, "payer")} label="Plan"
                                showArrow labelStyle={labelStyle}
                                style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider
                id={`${parent_id}_payer_bottom_divider`}
                style={{ margin: '10px 0px', borderColor: 'rgba(0, 0, 0, 0.08)', backgroundColor: 'rgba(0, 0, 0, 0.08)' }} />
            <Row
                id={`${parent_id}_validity_row`}
                className="en-actions-root space-padding-bottom">
                <Col
                    id={`${parent_id}_validity_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_validity_title_div`}
                    >
                        <H6
                            id={`${parent_id}_validity_label_h6`}
                            className="en-title space-padding-bottom">VALIDITY DETAILS</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_Policy_Start_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_Policy_Start_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            {/* <TextInput
                                id={`${parent_id}_Policy_Start_textinput`}
                                value={validity.policy_start ? validity.policy_start : ''} 
                                max={ "9999-12-31" } 
                                onChange={e => setDetails("policy_start", e.target.value, "validity")} 
                                label="Policy Start" 
                                type="date" 
                                dateIcon="images/icons8-calendar-grey.svg" 
                                labelStyle={labelStyle} 
                                style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} /> */}
                                <CustomInputDatePicker
                                    disabled = {false}
                                    placeholderText = "dd / mm / yyyy" 
                                    maxDate = {new Date("9999-12-31")}
                                    handleChange = {(value) => setDetails("policy_start", value ? moment(value).format("YYYY-MM-DD") : null, "validity")}
                                    selectedDate = {validity.policy_start ? new Date(validity.policy_start) : ''}
                                    inputField = {
                                        <TextInput
                                            id={`${parent_id}_Policy_Start_textinput`}
                                            label = "Policy Start"
                                            placeholder = "dd / mm / yyyy"
                                            dateIcon="images/icons8-calendar-grey.svg" 
                                            labelStyle={labelStyle} 
                                            style={inputBorderStyle} 
                                            hoverColor={UIColor.primaryColor.color}
                                            value={validity.policy_start ? moment(new Date(validity.policy_start)).format("MM/DD/YYYY") : ''}
                                        />
                                    }
                                />
                        </Col>
                        <Col
                            id={`${parent_id}_Policy_Expiry_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                             {/* <TextInput
                                id={`${parent_id}_Policy_Expiry_textinput`}
                                value={validity.policy_expiry ? validity.policy_expiry : ''} 
                                onChange={e => setDetails("policy_expiry", e.target.value, "validity")} 
                                label="Policy Expiry" 
                                type="date" 
                                max={ "9999-12-31" } 
                                dateIcon="images/icons8-calendar-grey.svg" 
                                labelStyle={labelStyle} 
                                style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />  */}

                                <CustomInputDatePicker
                                    disabled = {false}
                                    placeholderText = "dd / mm / yyyy" 
                                    maxDate = {new Date("9999-12-31")}
                                    handleChange = {(value) => setDetails("policy_expiry",value ? moment(value).format("YYYY-MM-DD") : null , "validity")}
                                    selectedDate = {validity.policy_expiry ? new Date(validity.policy_expiry) : ''}
                                    inputField = {
                                        <TextInput
                                            id={`${parent_id}_Policy_Expiry_textinput`}
                                            label = "Policy Expiry"
                                            placeholder = "dd / mm / yyyy"
                                            dateIcon="images/icons8-calendar-grey.svg" 
                                            labelStyle={labelStyle} 
                                            style={inputBorderStyle} 
                                            hoverColor={UIColor.primaryColor.color}
                                            value={validity.policy_expiry ? moment(new Date(validity.policy_expiry)).format("MM/DD/YYYY") : ''}
                                        />
                                    }
                                />
                        </Col>
                        <Col
                            id={`${parent_id}_Service_Start_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            {/* <TextInput
                                id={`${parent_id}_Service_Start_textinput`}
                                value={validity.service_start ? validity.service_start : ''} 
                                onChange={e => setDetails("service_start", e.target.value, "validity")} 
                                label="Service Start" 
                                type="date" 
                                max={ "9999-12-31" } 
                                dateIcon="images/icons8-calendar-grey.svg" 
                                labelStyle={labelStyle} 
                                style={inputBorderStyle} 
                                hoverColor={UIColor.primaryColor.color} /> */}
                                <CustomInputDatePicker
                                    disabled = {false}
                                    placeholderText = "dd / mm / yyyy" 
                                    maxDate = {new Date("9999-12-31")}
                                    handleChange = {(value) => setDetails("service_start", value ? moment(value).format("YYYY-MM-DD") : null, "validity")}
                                    selectedDate = {validity.service_start ? new Date(validity.service_start) : ''}
                                    inputField = {
                                        <TextInput
                                            id={`${parent_id}_Service_Start_textinput`}
                                            label = "Service Start"
                                            placeholder = "dd / mm / yyyy"
                                            dateIcon="images/icons8-calendar-grey.svg" 
                                            labelStyle={labelStyle} 
                                            style={inputBorderStyle} 
                                            hoverColor={UIColor.primaryColor.color}
                                            value={validity.service_start ? moment(new Date(validity.service_start)).format("MM/DD/YYYY") : ''}
                                        />
                                    }
                                />
                        </Col>
                        <Col
                            id={`${parent_id}_Service_end_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            {/* <TextInput
                                id={`${parent_id}_Service_end_textinput`}
                                value={validity.service_end ? validity.service_end : ''} 
                                onChange={e => setDetails("service_end", e.target.value, "validity")} 
                                label="Service End" 
                                type="date" 
                                max={ "9999-12-31" } 
                                dateIcon="images/icons8-calendar-grey.svg" 
                                labelStyle={labelStyle} 
                                style={inputBorderStyle} 
                                hoverColor={UIColor.primaryColor.color} /> */}

                                <CustomInputDatePicker
                                    disabled = {false}
                                    placeholderText = "dd / mm / yyyy" 
                                    maxDate = {new Date("9999-12-31")}
                                    handleChange = {(value) => setDetails("service_end", value ? moment(value).format("YYYY-MM-DD") : null , "validity")}
                                    selectedDate = {validity.service_end ? new Date(validity.service_end) : ''}
                                    inputField = {
                                        <TextInput
                                            id={`${parent_id}_Service_end_textinput`}
                                            label = "Service End"
                                            placeholder = "dd / mm / yyyy"
                                            dateIcon="images/icons8-calendar-grey.svg" 
                                            labelStyle={labelStyle} 
                                            style={inputBorderStyle} 
                                            hoverColor={UIColor.primaryColor.color}
                                            value={validity.service_end ? moment(new Date(validity.service_end)).format("MM/DD/YYYY") : ''}
                                        />
                                    }
                                />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider
                id={`${parent_id}_bottom_divider`}
                style={{ margin: '10px 0px', borderColor: 'rgba(0, 0, 0, 0.08)', backgroundColor: 'rgba(0, 0, 0, 0.08)' }} />
            <Row
                id={`${parent_id}_membership_parent_row`}
                className="en-actions-root space-padding-bottom">
                <Col
                    id={`${parent_id}_membership_parent_col`}
                    md={6} lg={6} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_membership_title_div`}
                    >
                        <H6
                            id={`${parent_id}_membership_label_h6`}
                            className="en-title space-padding-bottom">MEMBERSHIP DETAILS</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_membership_sub_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_membership_Membership_Type_col`}
                            md={4} lg={4} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_membership_Membership_Type_select`}
                                options={[
                                    { value: "Dependent" },
                                    { value: "self" },
                                ]}
                                placeholder='Membership type'
                                value={membership.type?.value} onChangeValue={e => setDetails("type", e, "membership")} label="Membership Type" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_membership_Member_Relationship_col`}
                            md={4} lg={4} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_membership_Member_Relationship_select`}
                                placeholder='Member relationship'
                                options={FlterOptions('RELATIONSHIP')} value={membership.relationship?.value}
                                onChangeValue={e => setDetails("relationship", e, "membership")} label="Member Relationship" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_membership_Prime_Member_ID_col`}
                            md={4} lg={4} sm={12} xs={12} className="no-padding-left">
                            <TextInput
                                id={`${parent_id}_membership_Prime_Member_ID_textinput`}
                                value={membership.member_id ? membership.member_id : ""}
                                placeholder='Prime member id'
                                type="number"
                                onChange={e => setDetails("member_id", e.target.value, "membership", 20)} label="Prime Member ID" labelStyle={labelStyle} style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
                <Col
                    id={`${parent_id}_membership_Eligibility_parent_col`}
                    md={6} lg={6} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_membership_Eligibility_title_div`}
                    >
                        <H6
                            id={`${parent_id}_membership_Eligibility_label_h6`}
                            className="en-title space-padding-bottom">ELIGIBILITY DETAILS</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_membership_Eligibility_label_h6`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_membership_Eligibility_Verification_col`}
                            md={4} lg={4} sm={12} xs={12} className="no-padding-left">
                            <H6
                                id={`${parent_id}_membership_Eligibility_Verification_label_h6`}
                                className="default-size pc_regular">Verification Required</H6>
                            <Checkbox
                                id={`${parent_id}_membership_Eligibility_Verification_checkbox`}
                                name='' checked={eligibility.isverify} onChange={e => setDetails("isverify", !(eligibility.isverify), "eligibility")} color={UIColor.secondaryButtonColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_membership_Eligibility_Sponsor_ID_col`}
                            md={4} lg={4} sm={12} xs={12} className="no-padding-left">
                            <TextInput
                                id={`${parent_id}_membership_Eligibility_Sponsor_ID_textinput`}
                                type="number"
                                placeholder='Sponsor id' value={eligibility.sponsor_id ? eligibility.sponsor_id : ""}
                                onChange={e => setDetails("sponsor_id", e.target.value, "eligibility",60)} label="Sponsor ID" labelStyle={labelStyle} style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_membership_Eligibility_Sponsor_name_col`}
                            md={4} lg={4} sm={12} xs={12} className="no-padding-left">
                            <TextInput
                                id={`${parent_id}_membership_Eligibility_Sponsor_name_textinput`}
                                placeholder='Sponsor name' value={eligibility.sponsor_name ? eligibility.sponsor_name : ""}
                                onChange={e => setDetails("sponsor_name", e.target.value, "eligibility", 60)} label="Sponsor Name" labelStyle={labelStyle} style={inputBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider
                id={`${parent_id}_membership_bottom_divider`}
                style={{ margin: '10px 0px', borderColor: 'rgba(0, 0, 0, 0.08)', backgroundColor: 'rgba(0, 0, 0, 0.08)' }} />
            <Row
                id={`${parent_id}_Financial_parent_row`}
                className="en-actions-root">
                <Col
                    id={`${parent_id}_Financial_parent_col`}
                    md={12} lg={12} sm={12} xs={12} className="no-padding-left">
                    <Div
                        id={`${parent_id}_Financial_title_div`}
                    >
                        <H6
                            id={`${parent_id}_Financial_label_h6`}
                            className="en-title space-padding-bottom">FINANCIAL CLASS</H6>
                    </Div>
                    <Row
                        id={`${parent_id}_Financial_sub_row`}
                        className="en-actions-root">
                        <Col
                            id={`${parent_id}_Financial_Class_Type_col`}
                            md={2} lg={2} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_Financial_Class_Type_select`}
                                options={[
                                    { value: "Subsidy class" },
                                ]} value={financial.class_type?.value}
                                placeholder='Financial class type'
                                onChangeValue={e => setDetails("class_type", e, "financial")} label="Financial Class Type" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                        <Col
                            id={`${parent_id}_Financial_Class_value_col`}
                            md={3} lg={3} sm={12} xs={12} className="no-padding-left">
                            <Select
                                id={`${parent_id}_Financial_Class_value_select`}
                                options={[
                                    { value: "STU-Student" },
                                    { value: "BL-Blood donor" },
                                    { value: "CPEN-Pensioner(Cancel)" },
                                    { value: "SPEN-Pensioner(State)" },
                                ]} value={financial.class_value?.value}
                                placeholder='Financial class value'
                                onChangeValue={e => setDetails("class_value", e, "financial")} label="Financial Class Value" showArrow labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor={UIColor.primaryColor.color} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Div
                id={`${parent_id}_Financial_submit_div`}
                className="p-continue-action">
                <Button
                    id={`${parent_id}_Financial_cancel_buttom`}
                    style={{ ...outlinedButton, marginRight: "15px" }}
                    onClick={() => props.onCancel()} className="pc_medium">Cancel</Button>
                <Button
                    id={`${parent_id}_Financial_save_buttom`}
                    inLineStyles={containedButton} onClick={() => props.onSave()}>Save</Button>
            </Div>
        </Paper>
    </Div >
}

export default withAllContexts(CardDetails);
