import moment from "moment";
import { getUtcTime,getUnixTime } from ".";
import { makeName } from "./helperFunctions";

console.log(makeName, "makeName");

export const CC_Json_Generator = (
	props,
	list,
	searchPractioner,
	personDetails,
	encounter_data
) => {
	const { pId } = props?.location?.state;
	let data = list?.map((val) => {
		let clicnicalstatushistory = val.clicnicalstatushistory
			? val.clicnicalstatushistory
			: [];
		clicnicalstatushistory = clicnicalstatushistory.map((val) => {
			val.period = [
				{
					start: val.period[0].start,
					end: val.period[0].end,
				},
			];
			delete val.Person;
			delete val.createdAt;
			delete val.personName;
			return val;
		});
		if (
			val.clinicalStatus &&
			clicnicalstatushistory[clicnicalstatushistory.length - 1]?.new_status !==
				val.clinicalStatus?.label
		) {
			clicnicalstatushistory.push({
				PersonID: personDetails._id,
				new_status: val?.clinicalStatus?.label,
				old_status:
					clicnicalstatushistory[clicnicalstatushistory.length - 1]
						?.new_status ?? "",
				period: [
					{
						start: getUtcTime(),
						end:  getUtcTime(moment().endOf("day")),
					},
				],
			});
		}
		return {
			editId: val?.editData?._id ?? null,
			identifier: [],
			clinicalStatus: val?.clinicalStatus?.label ?? "",
			verificationstatus: val?.status_verification?.label ?? "",
			category: "",
			severity: val.severity?.label,
			bodysite: val.bodySite?.label,
			assertedby: val.assertedBy?.label,

			patient_id: pId,
			encounter_id: encounter_data?.data?.eId,
			// encounter_id: encounter_id,

			onsetdatetime: val.onset.day
				? parseInt(val.onset.day)
				: val.onset.currentDate
				? getUtcTime(val.onset.currentDate)
				: "",
			onsetAge: "",
			onsetperiod: val.onset.day ? val.onset.freq.value : "",
			onsetrange: "",
			onsetstring: "",

			abatementdatetime: val.abatement.day
				? parseInt(val.abatement.day)
				: val.abatement.currentDate
				? getUtcTime(val.abatement.currentDate)
				: "",
			abatementage: "",
			abatementperiod: val.abatement.day ? val.abatement.freq.value : "",
			abatementrange: "",
			abatementstring: "",

			recordeddate: getUtcTime(),

			clicnicalstatushistory: clicnicalstatushistory,

			practitioner_id: searchPractioner._id,
			practitionerrole_id: searchPractioner?.PractitionerRole[0]?._id,

			relatedperson_id: val?.relationName?.value,

			note: val.notes,
			patient_complaint: val?.complaint,
			code: val?.name?.value,
			modifying_factor: val?.modifying_factor,
		};
	});
	return data;
};

export const DIAGNOSIS_Json_Generator = (
	list,
	searchPractioner,
	personDetails,
	encounter_data
) => {
	let data = list?.map((val) => {
		return {
			editId: val?.editData ?? null,
			identifier: [],

			Organization_id: personDetails.OrgID,

			patient_id: encounter_data?.data?.pID,
			practitioner_id: searchPractioner?._id,
			practitionerrole_id: searchPractioner?.practitioner_role[0],

			encounter_id: encounter_data?.data?.eId,

			onsetperiod: val.onset?.day
				? val.onset.day + " " + val.onset?.freq?.label
				: val.onset?.currentDate,

			abatementdatetime: val.abatement?.day
				? val.abatement?.day + " " + val.abatement?.freq?.label
				: val.abatement?.currentDate,

			abatementperiod: val.abatement?.day ? val.abatement?.currentDate : "",
			diagcode: val.diagnosis?.value ? val.diagnosis?.value : "",

			use: val.use?.label ? val.use?.label : "",
			rank: val.rank?.label ? val.rank?.label : "",
			codeset: val?.codeSet ? val?.codeSet : "",
			workrelated: val?.workRelated ? val?.workRelated : true,
			confidential: val?.confidential ? val?.confidential : true,
			Notifieable: val?.notifiable ? val?.notifiable : true,
			VerificationSTatus: val.verification?.label
				? val.verification?.label
				: "",
			clinicalstatus: val.clinicalStatus?.label
				? val.clinicalStatus?.label
				: "",

			recordeddate: getUtcTime(),
			manifestation: val.manifestation?.label ? val.manifestation?.label : "",
			EvidenceReference: val?.evidenceReference ? val?.evidenceReference : "",
			grade: [
				{
					code: val.gradeCode?.label ? val.gradeCode?.label : "",
					Type: val.gradeType?.label ? val.gradeType?.label : "",
					AssesmentRef: val.assesmentReference?.label
						? val.assesmentReference?.label
						: "",
				},
			],
			Notes: val?.notes ? val?.notes : "",
		};
	});
	return data;
};

export const Treatment_Json_Generator = (
	list,
	encounter_data,
	personDetails,
	searchPractioner
) => {
	let data = list?.map((val) => {
		var statushistoryData = [];
		const CurrentcalStatus = val.statusData?.reason;
		let statushistory = val?.statushistory ? val?.statushistory : [];
		var statushistoryData = statushistory;

		if (!statushistory?.length > 0) {
			statushistoryData.push({
				PersonID: personDetails?.name?.[0]?.given
					? personDetails?.name?.[0]?.given +
					  " " +
					  personDetails?.name?.[0]?.family +
					  "/" +
					  personDetails?.name?.[0]?.use
					: personDetails.telecom.value + "/" + personDetails?.name?.[0]?.use,
				new_status: val?.statusData?.status?.label ?? "Draft",
				old_status: "Draft",
				status_reason: CurrentcalStatus,
				fromperiod:
					statushistory?.length > 0
						? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
						: getUtcTime(),
				toperiod: val.date ? getUtcTime(val.date): getUtcTime(),
				// statushistory: statushistory,
			});
		}

		statushistory = statushistory.map((val) => {
			val.fromperiod = getUtcTime(val.date);
			val.toperiod = getUtcTime(val.date);
			return val;
		});
		if (
			CurrentcalStatus &&
			statushistory[statushistory.length - 1]?.new_status !== CurrentcalStatus
		) {
			statushistoryData.push({
				PersonID: personDetails?.name?.[0]?.given
					? personDetails?.name?.[0]?.given +
					  " " +
					  personDetails?.name?.[0]?.family +
					  "/" +
					  personDetails?.name?.[0]?.use
					: personDetails.telecom.value + "/" + personDetails?.name?.[0]?.use,
				new_status: val?.statusData?.status?.label ?? "",
				old_status: statushistory[statushistory.length - 1]?.new_status ?? "",
				status_reason: CurrentcalStatus,
				fromperiod:
					statushistory?.length > 0
						? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
						:getUtcTime(),
				toperiod: val.date ? getUtcTime(val.date) :getUtcTime(),
				// statushistory: statushistory,
			});
		}

		const diagnosisData = val?.selectDiagnosis?.map((v) => {
			if (v?.label) {
				return {
					codeStandard: v?.label,
					code: v?.value,
				};
			}
		});
		const recurrentListData = val?.recurrentList?.map((v) => {
			if (v?.date || v?.dataTime) {
				return {
					date: getUtcTime(v?.date || v?.dataTime),
					status: v?.status,
				};
			}
		});
		const DataperformerDetails = val?.performerDetails?.map((v) => {
			return {
				organization: v?.organization?.label ?? "",
				actionType: v?.status?.label ?? "",
				practType: v?.performedBy?.label ?? "",
				practRole: v?.practitionerRole?.label ?? "",
				practId: v?.practitionerId,
				locationRoleType: v?.location?.label ?? "",
				locationCode: v?.locationCode ?? "",
			};
		});
		return {
			statushistory: statushistoryData,
			statusreason: val.statusData?.reason,
			Organization_id: JSON.stringify(personDetails.OrgID),
			editId: val?.editData ?? null,
			patient_id: encounter_data?.data?.pID,
			encounter_Id: encounter_data?.data?.eId,
			every_date: val?.recurrentData?.every?.value
				? JSON.parse(val?.recurrentData?.every?.value)
				: 0,
			every_days: val?.recurrentData?.every?.periodicity?.value ?? "",
			starts_on: val?.recurrentData?.startsOn?.dateTime
				? getUtcTime(val?.recurrentData?.startsOn?.dateTime)
				: 0,
			starts_on_days: moment(val?.recurrentData?.startsOn?.dateTime) ?? "",
			startes_on_occurance_date: val?.recurrentData?.endsAfter?.endsBy
				? getUtcTime(val?.recurrentData?.endsAfter?.endsBy)
				: 0,
			startes_on_occurance: val?.recurrentData?.endsAfter?.endsAfter
				? JSON.parse(val?.recurrentData?.endsAfter?.endsAfter)
				: 0,
			OrderType: val?.orderType?.label ?? "",
			OrderNature: val?.nature?.label ?? "",
			orderMode: val?.mode?.label ?? "",
			priority: val?.priority?.label ?? "",
			orderDateTime: val?.startDate ?  getUnixTime(val?.startDate) : getUtcTime(val?.dateTime) ?? 0,
			occuranceStartDate: val.startDate ? getUnixTime(val?.startDate) : 0,
			occuranceEndDate: val.endDate ? getUnixTime(val?.endDate) : 0,
			orderCatalog: (val?.orderCatalog?._id ? val?.orderCatalog?._id : val?.orderCatalog?.value) ?? "",
			orderQty: val.qty ? parseInt(val?.qty) : 1,
			Requestor: [
				{
					organization: val?.requestorDetails?.organization?.label ?? "",
					practRole: val?.requestorDetails?.practitionerRole?._id ?? "",
					practId: val?.requestorDetails?.orderBy?.value ?? "",
				},
			],
			indications: val?.indication?.label ?? "",
			reasonCode: val?.indication?.value ?? "",
			diagnosis: [...(diagnosisData || [])],
			performer: [...(DataperformerDetails || [])],
			notes: val?.notes ?? "",
			patientInstruction: [
				{
					code: val?.patientInstruction?.label,
					description: val?.patientInstruction?.content,
				},
			],
			preparatoryInstruction: [
				{
					code: val?.preparatoryInstruction?.label,
					description: val?.preparatoryInstruction?.content,
				},
			],
			departmentalInstruction: [
				{
					code: val?.departmentalInstruction?.label,
					description: val?.departmentalInstruction?.content,
				},
			],
			attribute: {
				view: val?.attributes?.view?.label ?? "",
				bedSide: val?.attributes?.bedSide ?? false,
				contrast: val?.attributes?.contrast ?? "",
			},
			recurrentList: [...(recurrentListData || [])],
			changeStatus: {
				changeToStatus: val?.statusData?.status?.label ?? "",
				dateTime: getUtcTime(val?.date) ?? 0,
				reason: val?.reason,
			},
			accessionNum: makeName(personDetails?.name?.[0]),
			parentOrderId: val?.parentOrderId,
		};
	});
	return data;
};

export const Rad_Json_Generator = (
	list,
	encounter_data,
	personDetails,
	searchPractioner
) => {
	let data = list?.map((val) => {
		var statushistoryData = [];
		const CurrentcalStatus = val.statusData?.reason;
		let statushistory = val?.statushistory ? val?.statushistory : [];
		var statushistoryData = statushistory;

		if (!statushistory?.length > 0) {
			statushistoryData.push({
				PersonID: personDetails?.name?.[0]?.given
					? personDetails?.name?.[0]?.given +
					  " " +
					  personDetails?.name?.[0]?.family +
					  "/" +
					  personDetails?.name?.[0]?.use
					: personDetails.telecom.value + "/" + personDetails?.name?.[0]?.use,
				new_status: val?.statusData?.status?.label ?? "Draft",
				old_status: "Draft",
				status_reason: CurrentcalStatus,
				fromperiod:
					statushistory?.length > 0
						? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
						: getUtcTime(),
				toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
				// statushistory: statushistory,
			});
		}

		statushistory = statushistory.map((val) => {
			val.fromperiod = getUtcTime(val.date);
			val.toperiod = getUtcTime(val.date);
			return val;
		});
		if (
			CurrentcalStatus &&
			statushistory[statushistory.length - 1]?.new_status !== CurrentcalStatus
		) {
			statushistoryData.push({
				PersonID: personDetails?.name?.[0]?.given
					? personDetails?.name?.[0]?.given +
					  " " +
					  personDetails?.name?.[0]?.family +
					  "/" +
					  personDetails?.name?.[0]?.use
					: personDetails.telecom.value + "/" + personDetails?.name?.[0]?.use,
				new_status: val?.statusData?.status?.label,
				old_status: statushistory[statushistory.length - 1]?.new_status ?? "",
				status_reason: CurrentcalStatus,
				fromperiod:
					statushistory?.length > 0
						? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
						:getUtcTime(),
				toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
				// statushistory: statushistory,
			});
		}

		const diagnosisData = val?.selectDiagnosis?.map((v) => {
			if (v?.label) {
				return {
					codeStandard: v?.label,
					code: v?.value,
				};
			}
		});
		const recurrentListData = val?.recurrentList?.map((v) => {
			if (v?.date || v?.dataTime) {
				return {
					date: getUtcTime(v?.date || v?.dataTime),
					status: v?.status,
				};
			}
		});
		const DataperformerDetails = val?.performerDetails?.map((v) => {
			return {
				organization: v?.organization?.label ?? "",
				actionType: v?.status?.label ?? "",
				practType: v?.performedBy?.label ?? "",
				practRole: v?.practitionerRole?.label ?? "",
				practId: v?.practitionerId,
				locationRoleType: v?.location?.label ?? "",
				locationCode: v?.locationCode ?? "",
			};
		});
		return {
			statushistory: statushistoryData,
			statusreason: val.statusData?.reason,
			Organization_id: JSON.stringify(personDetails.OrgID),
			editId: val?.editData ?? null,
			patient_id: encounter_data?.data?.pID,
			encounter_Id: encounter_data?.data?.eId,
			every_date: val?.recurrentData?.every?.value
				? JSON.parse(val?.recurrentData?.every?.value)
				: 0,
			every_days: val?.recurrentData?.every?.periodicity?.value ?? "",
			starts_on: val?.recurrentData?.startsOn?.dateTime
				? getUtcTime(val?.recurrentData?.startsOn?.dateTime)
				: 0,
			starts_on_days: moment(val?.recurrentData?.startsOn?.dateTime) ?? "",
			startes_on_occurance_date: val?.recurrentData?.endsAfter?.endsBy
				? getUtcTime(val?.recurrentData?.endsAfter?.endsBy)
				: 0,
			startes_on_occurance: val?.recurrentData?.endsAfter?.endsAfter
				? JSON.parse(val?.recurrentData?.endsAfter?.endsAfter)
				: 0,
			OrderType: val?.orderType?.label ?? "",
			OrderNature: val?.nature?.label ?? "",
			orderMode: val?.mode?.label ?? "",
			priority: val?.priority?.label ?? "",
			orderDateTime:val?.startDate ?  getUnixTime(val?.startDate) : getUtcTime(val?.dateTime) ?? 0,
			occuranceStartDate: val.startDate ? getUnixTime(val?.startDate) : 0,
			occuranceEndDate: val.endDate ? getUnixTime(val?.endDate) : 0,
			orderCatalog: (val?.orderCatalog?._id ? val?.orderCatalog?._id : val?.orderCatalog?.value) ?? "",
			orderQty: val.qty ? parseInt(val?.qty) : 1,
			Requestor: [
				{
					organization: val?.requestorDetails?.organization?.label ?? "",
					practRole: val?.requestorDetails?.practitionerRole?._id ?? "",
					practId: val?.requestorDetails?.orderBy?.value ?? "",
				},
			],
			indications: val?.indication?.label ?? "",
			reasonCode: val?.indication?.value ?? "",
			diagnosis: [...(diagnosisData || [])],
			performer: [...(DataperformerDetails || [])],
			notes: val?.notes ?? "",
			patientInstruction: [
				{
					code: val?.patientInstruction?.label,
					description: val?.patientInstruction?.content,
				},
			],
			preparatoryInstruction: [
				{
					code: val?.preparatoryInstruction?.label,
					description: val?.preparatoryInstruction?.content,
				},
			],
			departmentalInstruction: [
				{
					code: val?.departmentalInstruction?.label,
					description: val?.departmentalInstruction?.content,
				},
			],
			attribute: {
				view: val?.attributes?.view?.label ?? "",
				bedSide: val?.attributes?.bedSide ?? false,
				contrast: val?.attributes?.contrast ?? "",
			},
			recurrentList: [...(recurrentListData || [])],
			changeStatus: {
				changeToStatus: val?.statusData?.status?.label ?? "",
				dateTime: getUtcTime(val?.date) ?? 0,
				reason: val?.reason,
			},
			accessionNum: makeName(personDetails?.name?.[0]),
			parentOrderId: val?.parentOrderId,
		};
	});
	return data;
};

export const LabOrder_Json_Generator = (
	list,
	encounter_data,
	personDetails,
	searchPractioner
) => {
	let data = list?.map((val) => {
		var statushistoryData = [];
		const CurrentcalStatus = val.statusData?.reason;
		let statushistory = val?.statushistory ? val?.statushistory : [];
		var statushistoryData = statushistory;
		if (!statushistory?.length > 0) {
			statushistoryData.push({
				PersonID: personDetails?.name?.[0]?.given
					? personDetails?.name?.[0]?.given +
					  " " +
					  personDetails?.name?.[0]?.family +
					  "/" +
					  personDetails?.name?.[0]?.use
					: personDetails.telecom.value + "/" + personDetails?.name?.[0]?.use,
				new_status: val?.statusData?.status?.label ?? "Draft",
				old_status: "Draft",
				status_reason: CurrentcalStatus,
				fromperiod:
					statushistory?.length > 0
						? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
						: getUtcTime(),
				toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
				// statushistory: statushistory,
			});
		}
		statushistory = statushistory.map((val) => {
			val.fromperiod = getUtcTime(val.date);
			val.toperiod = getUtcTime(val.date)
			return val;
		});
		if (
			CurrentcalStatus &&
			statushistory[statushistory.length - 1]?.new_status !== CurrentcalStatus
		) {
			statushistoryData.push({
				PersonID: personDetails?.name?.[0]?.given
					? personDetails?.name?.[0]?.given +
					  " " +
					  personDetails?.name?.[0]?.family +
					  "/" +
					  personDetails?.name?.[0]?.use
					: personDetails.telecom.value + "/" + personDetails?.name?.[0]?.use,
				new_status: val?.statusData?.status?.label,
				old_status: statushistory[statushistory.length - 1]?.new_status ?? "",
				status_reason: CurrentcalStatus,
				fromperiod:
					statushistory?.length > 0
						? getUtcTime(statushistory[statushistory.length - 1].fromperiod)
						:getUtcTime(),
				toperiod: val.date ? getUtcTime(val.date) : getUtcTime(),
				// statushistory: statushistory,
			});
		}

		const diagnosisData = val?.selectDiagnosis?.map((v) => {
			if (v?.label) {
				return {
					codeStandard: v?.label,
					code: v?.value,
				};
			}
		});
		const recurrentListData = val?.recurrentList?.map((v) => {
			if (v?.date || v?.dataTime) {
				return {
					date: getUtcTime(v?.date || v?.dataTime),
					status: v?.status,
				};
			}
		});
		const DataperformerDetails = val?.performerDetails?.map((v) => {
			return {
				organization: v?.organization?.label ?? "",
				actionType: v?.status?.label ?? "",
				practType: v?.performedBy?.label ?? "",
				practRole: v?.practitionerRole?.label ?? "",
				practId: v?.practitionerId,
				locationRoleType: v?.location?.label ?? "",
				locationCode: v?.locationCode ?? "",
			};
		});
		return {
			statushistory: statushistoryData,
			statusreason: val.statusData?.reason,
			Organization_id: JSON.stringify(personDetails.OrgID),
			editId: val?.editData ?? null,
			patient_id: encounter_data?.data?.pID,
			encounter_Id: encounter_data?.data?.eId,
			every_date: val?.recurrentData?.every?.value
				? JSON.parse(val?.recurrentData?.every?.value)
				: 0,
			every_days: val?.recurrentData?.every?.periodicity?.value ?? "",
			starts_on: val?.recurrentData?.startsOn?.dateTime
				? getUtcTime(val?.recurrentData?.startsOn?.dateTime)
				: 0,
			starts_on_days: moment(val?.recurrentData?.startsOn?.dateTime) ?? "",
			startes_on_occurance_date: val?.recurrentData?.endsAfter?.endsBy
				? getUtcTime(val?.recurrentData?.endsAfter?.endsBy)
				: 0,
			startes_on_occurance: val?.recurrentData?.endsAfter?.endsAfter
				? JSON.parse(val?.recurrentData?.endsAfter?.endsAfter)
				: 0,
			OrderType: val?.orderType?.label ?? "",
			OrderNature: val?.nature?.label ?? "",
			orderMode: val?.mode?.label ?? "",
			priority: val?.priority?.label ?? "",
			// orderDateTime: getUtcTime(val?.startDate || val?.dateTime) ?? 0,
			orderDateTime: val?.startDate ? moment((val?.startDate).replace(",", " "), 'YYYY-MM-DD hh:mm A').unix() : moment((val?.dateTime), 'YYYY-MM-DD hh:mm A').unix(),
			occuranceStartDate: val.startDate ? getUtcTime(val?.startDate) : 0,
			occuranceEndDate: val.endDate ? getUnixTime(val?.endDate) : 0,
			orderCatalog: val?.orderCatalog?._id ?? "",
			orderQty: val.qty ? parseInt(val?.qty) : 1,
			Requestor: [
				{
					organization: val?.requestorDetails?.organization?.label ?? "",
					practRole: val?.requestorDetails?.practitionerRole?._id ?? "",
					practId: val?.requestorDetails?.orderBy?.value ?? "",
				},
			],
			indications: val?.indication?.label ?? "",
			reasonCode: val?.indication?.value ?? "",
			diagnosis: [...(diagnosisData || [])],
			performer: [...(DataperformerDetails || [])],
			notes: val?.notes ?? "",
			patientInstruction: [
				{
					code: val?.patientInstruction?.label,
					description: val?.patientInstruction?.content,
				},
			],
			preparatoryInstruction: [
				{
					code: val?.preparatoryInstruction?.label,
					description: val?.preparatoryInstruction?.content,
				},
			],
			departmentalInstruction: [
				{
					code: val?.departmentalInstruction?.label,
					description: val?.departmentalInstruction?.content,
				},
			],
			specimenRelated: [
				{
					reqd: true,
					type: val?.specimenRelated?.specimenType?.label,
					volume: val?.specimenRelated?.volume,
					volumeUOM: val?.specimenRelated?.volumeUom?.label,
					collectionMethod: val?.specimenRelated?.collectionMethod?.label,
					container: "",
					bodySite: val?.specimenRelated?.bodySite?.label,
					FastingPeriod: val?.specimenRelated?.fasting,
					FastingPeriodUOM: val?.specimenRelated?.fastingUom?.label,
				},
			],
			attribute: {
				view: val?.attributes?.view?.label ?? "",
				bedSide: val?.attributes?.bedSide ?? false,
				contrast: val?.attributes?.contrast ?? "",
			},
			recurrentList: [...(recurrentListData || [])],
			changeStatus: {
				changeToStatus: val?.statusData?.status?.label ?? "",
				dateTime: getUtcTime(val?.date) ?? 0,
				reason: val?.reason,
			},
			accessionNum: makeName(personDetails?.name?.[0]),
			// childRecurrent: LabOrder_Json_Generator1(
			//   val?.childRecurrent,
			//   encounter_data,
			//   personDetails,
			//   loginUser
			// ),
			parentOrderId: val?.parentOrderId,
		};
	});
	return data;
};
