import React from "react";
import {
  H6,
  Div,
  Row,
  Col,
  Text,
  TextInput,
  TapSelect,
} from "qdm-component-library";
import { Line } from "react-chartjs-2";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 14,
    minWidth: 120,
    "& select": {
      padding: 8,
      fontSize: 14,
      "& option": {
        fontSize: 14,
      },
    },
  },
}));
const data = {
  labels: ["10 Jan", "11 Feb", "01 Mar", "28 Mar", "08 May", "07 Jun"],
  datasets: [
    {
      label: "Temperature",
      data: [8, 20, 25, 40, 12],
      fill: false,
      borderColor: "#F29233",
    },
  ],
};
const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.3,
    },
  },
};
const styles = {
  title: {
    margin: 0,
    fontSize: 16,
    color: "#101010",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 0,
    color: "#6F6F6F",
  },
  inputFiledStyle: {
    width: 38,
    padding: 8,
    border: "none",
    borderBottom: "2px solid #E0E0E0",
    borderRadius: 0,
    textAlign: "center",
  },
  tapSelectStyle: {
    padding: "2px 12px",
    borderColor: "#E0E0E0",
    margin: "4px 10px 6px 0px",
    borderRadius: "50px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
};
const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};
const VitalSigns = [
  { value: "Temperature", label: "Temperature" },
  { value: "Spo2", label: "Spo2" },
  { value: "Blood Pressure", label: "Blood Pressure" },
  { value: "Pulse rate", label: "Pulse rate" },
  { value: "Respiration Rate", label: "Respiration Rate" },
];
const ViewTrend = (props) => {
  const classes = useStyles();
  const { parent_id } = props
  return (
    <Div
      id={`${parent_id}_view_parent_div`}
      style={{ width: "844px", padding: "12px 24px" }}>
      <p
        id={`${parent_id}_view_Trends_title_p`}
        className={"pc_semibold"} style={styles.title}>
        View Trends
      </p>
      <Row
        id={`${parent_id}_view_parent_row`}
      >
        <Col
          id={`${parent_id}_view_past_visits_col`}
          xl={3} lg={3} md={3} sm={3} xs={3} style={{ padding: 0 }}>
          <p
            id={`${parent_id}_view_past_visits_p`}
            style={styles.subtitle}>Past visits for</p>
          <Row
            id={`${parent_id}_view_past_visits_row`}
            style={{ display: "flex", alignItems: "flex-end" }}>
            <Col
              id={`${parent_id}_view_past_visits_col`}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              inLineStyles={{ padding: 0 }}
            >
              <TextInput
                id={`${parent_id}_view_number_textinput`}
                label={""}
                size={"size"}
                type={"number"}
                placeholder={""}
                style={styles.inputFiledStyle}
                hoverColor="#E0E0E0"
                maxLength={"2"}
                onInput={maxLengthCheck}
              />
            </Col>
            <Col
              id={`${parent_id}_view_option_col`}
              xl={10} lg={10} md={10} sm={10} xs={10}>
              <FormControl
                id={`${parent_id}_view_option_FormControl`}
                variant="outlined" className={classes.formControl}>
                <Select
                  id={`${parent_id}_view_days_month_Select`}
                  native>
                  <option
                    id={`${parent_id}_view_None_option`}
                    value="">None</option>
                  <option
                    id={`${parent_id}_view_Days_option`}
                    value={"days"}>Days</option>
                  <option
                    id={`${parent_id}_view_Months_option`}
                    value={"months"}>Months</option>
                </Select>
              </FormControl>
            </Col>
          </Row>
        </Col>
        <Col
          id={`${parent_id}_view_Compare_col`}
          xl={9} lg={9} md={9} sm={9} xs={9}>
          <p
            id={`${parent_id}_view_Compare_p`}
            style={styles.subtitle}>To Compare</p>
          <TapSelect
            id={`${parent_id}_view_Compare_TapSelect`}
            label={""}
            inLineStyles={styles.tapSelectStyle}
            selectedBorderColor={"#0271F2"}
            selectedBackgroundColor={"#0271F2"}
            selectedTextColor={"#fff"}
            textSize={"small"}
            options={VitalSigns}
            className={"pc_medium"}
            defaultSelected={{ value: "Temperature", label: "Temperature" }}
          />
        </Col>
      </Row>
      <Line
        id={`${parent_id}_view_Compare_line`}
        style={{ paddingTop: "20px" }}
        width={500}
        height={200}
        data={data}
        options={options}
      />
      <div
        id={`${parent_id}_view_${data.datasets[0].label}_div`}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 16,
          fontSize: 12,
        }}
        className={"pc_regular"}
      >
        <div
          id={`${parent_id}_view_${data.datasets[0].label}_sub_div`}
          style={{
            width: 10,
            height: 10,
            borderRadius: 50,
            backgroundColor: "#F29233",

            marginRight: 8,
          }}
        />{" "}
        {data.datasets[0].label}
      </div>
    </Div>
  );
};

ViewTrend.defaultProps = {
  data: {},
  label: " ",
};

export default ViewTrend;
