import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import {
    Container,
    Row,
    Col,
    Div,
    Icons,
    Text,
    Divider,
    Button,
    BackgroundImage,
    Paper,
} from "qdm-component-library";
import { Grid, Typography } from "@material-ui/core"
import { AlertContext } from '../../contexts'
import { checkError, AlertProps, fetchQueueData } from '../../utils'
import { withAllContexts } from "../../HOCs";
// import Logo from "../../assets/img/Logo_W.svg";

class ExpiryPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            error: "",
            btnDisabled: true,
            newPassword: "",
            confirmPassword: "",
        };
        this.fetchQueueData = fetchQueueData.bind(this)
    }

    changeState = (key, value) => {
        this.setState({
            [key]: value,
        });
    };

    componentDidMount() { 
        this.props.backDrop.setBackDrop({
            ...this.props.backDrop,
            open: false,
            message: "loading...",
        });
    }

    shouldComponentUpdate(props, state) {
        return true;
    }

    styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0',
            marginBottom: 10
        }
    }

    handleRecover = async () => {

    }

    render() {
        let { btnDisabled, newPassword, confirmPassword } = this.state
        return (
            <div>
                <Container
                    key={"0"}
                    name="container"
                    fluid={true}
                    style={{
                        backgroundColor: "#e8e8e8",
                        height: "100vh",
                        padding: "0px",
                    }}
                >
                    <Row
                        key={"0"}
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        spacing="2"
                        style={{ backgroundColor: "#ffffff", height: "" }}
                    >
                        <Col
                            key={"0"}
                            name="qdm-col-12"
                            alignItems="center"
                            direction="row"
                            justify="space-between"
                            textAlign="left"
                            lg="12"
                            xs="12"
                            md="12"
                            sm="12"
                            style={{
                                height: "55px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {/* <Avatar
                key={"0"}
                variant="rounded"
                letter="Logo"
                src={Logo}
                alt="Logo"
                style={{
                  borderColor: "#ffffff",
                  backgroundColor: "#ffffff",
                  padding: "8px",
                }}
              /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="97.387" height="46" viewBox="0 0 97.387 46">
                                <defs>
                                    <pattern id="pattern" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 1024 948">
                                    </pattern>
                                </defs>
                                <g id="Group_3793" data-name="Group 3793" transform="translate(-36 -25)">
                                    <g id="Group_2087" data-name="Group 2087">
                                        <g id="Group_1501" data-name="Group 1501" transform="translate(2)">
                                            <rect id="logo_2" width="48" height="46" transform="translate(34 25)" opacity="0" fill="url(#pattern)" />
                                            <g id="AINQA_Logo-01" transform="translate(9.301 14)">
                                                <path id="Path_2769" d="M41.829,18H41.7a16,16,0,1,0,.129,0Zm0,1.157c1.735,3.149,3.534,6.361,5.2,9.51l-.064.064a14.377,14.377,0,0,0-5.2-.964,14.633,14.633,0,0,0-5.2.964C38.294,25.518,39.965,22.369,41.829,19.157Zm7.261,13.43a19.538,19.538,0,0,0-5.719-1.671,14.29,14.29,0,0,0-6.169.643c-.835.257-1.671.643-2.506.964-.129.064-.193.064-.257.129l-.064-.064a17.139,17.139,0,0,1,1.414-2.313,15.019,15.019,0,0,1,12.337,0c.386.707.835,1.542,1.285,2.378C49.218,32.651,49.154,32.651,49.09,32.586Zm.9,1.735a.7.7,0,0,1,.321.257c.578.964,1.092,1.992,1.671,2.956a.236.236,0,0,1,.064.193A16.491,16.491,0,0,0,41.829,34a16,16,0,0,0-10.281,3.663c.064-.064.064-.129.193-.193.514-.9.964-1.8,1.478-2.7.064-.193.257-.257.386-.386A16.779,16.779,0,0,1,40.865,32.2,14.807,14.807,0,0,1,49.989,34.321Zm-20.948-8.1a15.835,15.835,0,0,1,6.1-5.526,14.073,14.073,0,0,1,5.333-1.478c-3.984,7.2-7.968,14.329-12.016,21.59A14.929,14.929,0,0,1,29.041,26.225Zm12.4,22.747a14.733,14.733,0,0,1-12.337-6.94,3.686,3.686,0,0,0,.257-.45A15.06,15.06,0,0,1,39,35.414,14.754,14.754,0,0,1,50.31,37.855a13.475,13.475,0,0,1,3.727,3.663l.193.257a.5.5,0,0,0,.064.193C51.21,46.53,46.969,49.036,41.443,48.972Zm1.671-29.687a15.285,15.285,0,0,1,5.012,1.349,14.931,14.931,0,0,1,6.361,5.783A14.645,14.645,0,0,1,55,40.683C51.082,33.55,47.1,26.418,43.114,19.285Z" transform="translate(0)" fill="blue" />
                                                <path id="Path_2770" d="M145.054,42.9h-.707v-.129c.064-.064.129-.064.193-.129a4.075,4.075,0,0,0,1.221-1.542,6.469,6.469,0,0,0,.386-5.012,5.787,5.787,0,0,0-5.141-4.112,5.946,5.946,0,0,0-5.783,2.763,6.44,6.44,0,0,0-.643,5.719,5.25,5.25,0,0,0,3.149,3.341,8.029,8.029,0,0,0,2.7.514H146.4c.064,0,.193-.064.257-.064V42.709Zm-1.092-2.12a4.321,4.321,0,0,1-5.976,1.092,4.538,4.538,0,0,1,1.671-8.225c.257,0,.514-.064.707-.064a4.649,4.649,0,0,1,2.892,1.157,4.515,4.515,0,0,1,.964,5.59A3.684,3.684,0,0,1,143.962,40.782Z" transform="translate(-38.776 -4.982)" fill="blue" />
                                                <path id="Path_87256" data-name="Path 87256" d="M124.681,44.445H124.3c-1.349-1.414-2.7-2.763-4.048-4.177s-2.7-2.763-4.048-4.177a.381.381,0,0,0-.064.45v7.9H114.4V32.3h.45c2.7,2.7,5.333,5.526,8.1,8.353.064-.45,0-.835.064-1.221V32.364h1.542C124.681,32.557,124.745,43.545,124.681,44.445Z" transform="translate(-31.704 -5.111)" fill="blue" />
                                                <path id="Path_2772" d="M162.626,32.3h-.45c-1.992,4.048-3.984,8.032-5.976,12.145h1.992c.386-.771.707-1.542,1.092-2.249h6.361a24.276,24.276,0,0,0,1.092,2.249h1.992C166.674,40.332,164.682,36.284,162.626,32.3Zm-2.57,8.353c.835-1.735,1.671-3.406,2.378-5.141h.129c.771,1.735,1.606,3.406,2.378,5.141Z" transform="translate(-46.645 -5.111)" fill="blue" />
                                                <path id="Path_2773" d="M89.79,32.3h-.514c-1.992,4.048-3.984,8.032-5.976,12.145h1.992c.386-.771.707-1.542,1.092-2.249h6.361c.386.771.707,1.542,1.092,2.249H95.83C93.774,40.4,91.782,36.348,89.79,32.3Zm-2.57,8.418h0v-.129c.771-1.671,1.542-3.213,2.249-4.819a.887.887,0,0,0,.193-.257c.771,1.735,1.606,3.47,2.313,5.141A37.977,37.977,0,0,1,87.22,40.718Z" transform="translate(-20.588 -5.111)" fill="blue" />
                                                <path id="Path_2774" d="M108.171,44.509H106.5V32.3h1.671C108.235,32.557,108.235,43.995,108.171,44.509Z" transform="translate(-28.88 -5.111)" fill="blue" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>

                            <Div
                                key={"1"}
                                inLineStyles="[object Object]"
                                className="qdm"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <Icons
                                    key={"0"}
                                    fontIcon="phone"
                                    ariaHidden="true"
                                    size="small"
                                    className=""
                                    style={{ color: "#6F6F6F", margin: "10px" }}
                                />

                                <Text
                                    className="pc_regular"
                                    key={"1"}
                                    name="+91 9821315141 , +91 9551482612"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: '#6F6F6F'
                                    }}
                                />

                                <Divider
                                    key={"2"}
                                    id=""
                                    className=""
                                    inLineStyles="[object Object]"
                                    label=""
                                    borderColor=""
                                    textColor=""
                                    orientation="vertical"
                                    variant="middle"
                                    style={{ height: "24px", marginTop: "6px" }}
                                />

                                <Button
                                    className="pc_medium"
                                    key={"3"}
                                    type="button"
                                    variant="text"
                                    name="Support"
                                    style={{
                                        borderColor: "#0071F2",
                                        borderRadius: "8px",
                                        color: "#0071F2",
                                        height: "35px",
                                        lineHeight: 0,
                                        fontSize: 14,
                                        minWidth: 88,
                                    }}
                                />
                            </Div>
                        </Col>
                    </Row>

                    <Col
                        key={"1"}
                        name="qdm-col-12"
                        alignItems="stretch"
                        direction="row"
                        justify="flex-start"
                        textAlign="left"
                        lg="12"
                        xs="12"
                        md="12"
                        sm="12"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "calc(100vh - 55px)",
                            backgroundColor: "#F0F7FF",
                        }}
                    >
                        <BackgroundImage
                            key={"0"}
                            url="images/login_bg.svg"
                            alt="Mask-Group-71-2x"
                            backgroundPosition="0% 0%"
                            backgroundPositionX="left"
                            backgroundPositionY="top"
                            backgroundRepeat="no-repeat"
                            backgroundRepeatX="no-repeat"
                            backgroundRepeatY="no-repeat"
                            backgroundAttachment="scroll"
                            backgroundSize="contain"
                            backgroundOrigin="padding-box"
                            inLineStyles="[object Object]"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "calc(100vh - 55px)",
                            }}
                        >
                            <Paper
                                key={"0"}
                                children="Paper"
                                variant="elevation"
                                elevation="1"
                                style={{
                                    width: "62%",
                                    textAlign: 'center',
                                    // height: "400px",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "16px",
                                    padding: "32px",
                                    boxShadow: '0px 8px 69px #0000001A',
                                    display: "inline",
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    style={{ display: "inline" }}
                                >


                                    <>
                                        <Grid item xs={12} style={{ marginBottom: 25 }}>
                                            <BlockOutlinedIcon htmlColor="#f5bca0" style={{ marginBottom: 20, width: 100, height: 100 }}
                                            //  classes={{ root: classes.svg_icon }} 
                                            />
                                            <Typography
                                                variant="h2"
                                                component="h2"
                                                style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                                            >
                                                Link Expired !
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                style={{ marginTop: 4, marginBottom: 30, color: "rgba(146, 151, 165, 1)", fontSize: "1rem" }}
                                            >
                                                This link is expired since it is not used for more than 24 hours. <br /> Please contact out support team for further help.
                                            </Typography>
                                        </Grid>

                                        {/* ------------------------------  */}
                                        <Grid container item xs={12} justify="center">
                                            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        id="sendCodeButton"
                                                        // disabled={loading}
                                                        onClick={() => this.props.history.push("/")}
                                                        // className={classes.sendCodeButton}
                                                        style={{ marginTop: 0, width: "90%" }}
                                                    >
                                                        Back to Sign In

                                                    </Button>
                                                </Grid>



                                            </Grid>
                                        </Grid>
                                    </>
                                </Grid>
                            </Paper>
                        </BackgroundImage>
                    </Col>
                </Container>
            </div >
        );
    }
}
ExpiryPassword.contextType = AlertContext

const mapStateToProps = (state) => ({
    forgotPassword: state?.authSlice?.forgotPassword,
    loggedUserInfo: state?.authSlice?.loggedUserInfo,
});
export default connect(mapStateToProps, actions)(withRouter(withAllContexts(ExpiryPassword)));