import React from "react";
import { Div, H6, Col, Row, Text, Avatar, Image } from "qdm-component-library";
import "./style.css";
import moment from "moment";
import { utcTOLocal } from "../../../utils";

class ShowList extends React.Component {
	render() {
		const { imgUrl, letter, data, ondelete, onedit, parent_id, isDelete, isEdit } = this.props;
		return (
			<Row
				id={`${parent_id}_addresslist_parent_row`}
				className="sl-root"
				style={{ marginBottom: "18px !important" }}
			>
				<Col
					id={`${parent_id}_addresslist_parent_col`}
					md={11}
					lg={11}
					sm={6}
					xs={12}
					className="sl-detail-root"
				>
					<Div id={`${parent_id}_addresslist_sub_div`} className="sl-count">
						<Text
							id={`${parent_id}_addresslist_text`}
							className="pc_medium"
							style={{
								color: "#101010",
							}}
						>
							{this.props?.index}
						</Text>
					</Div>
					<Div
						id={`${parent_id}_addresslist_sub_parent_div`}
						className={imgUrl || letter ? "sl-details" : "sl-detail"}
						style={{ display: "flex" }}
					>
						{(imgUrl || letter) && (
							<Avatar
								id={`${parent_id}_addresslist_letter_avatar`}
								className="sl-details-avatar"
								src={imgUrl}
								letter={letter}
							/>
						)}
						<Div
							id={`${parent_id}_addresslist_name_div`}
							className="sl-details-names"
						>
							<H6 id={`${parent_id}_addresslist_reciver_h6`}>
								{data?.payer?.reciver_type?.value ?? ""}&nbsp;
								{data?.payer?.reciver_code?.value ?? ""}
							</H6>
							<Text
								id={`${parent_id}_addresslist_sponsor_text`}
								style={{
									color: "#6F6F6F",
									fontSize: 12,
								}}
							>
								{data?.payer?.reciver_type?.value ?? ""}{" "}
								{data?.eligibility?.sponsor_id ?? ""}
							</Text>
						</Div>

						<Div
							id={`${parent_id}_addresslist_date_format_div`}
							style={{
								width: "40%",
								marginLeft: "30px",
								fontSize: "20px",
							}}
						>
							<Text
								id={`${parent_id}_addresslist_membership_text`}
								style={{
									color: "#6F6F6F",
									fontSize: 12,
								}}
							>
								{data?.payer?.membershipId ?? " "}
							</Text>
							&nbsp;
							<Text
								id={`${parent_id}_addresslist_plan_text`}
								style={{
									color: "#6F6F6F",
									fontSize: 12,
								}}
							>
								{data?.payer?.plan?.value ?? " "}
							</Text>
							&nbsp;
							{data?.validity?.policy_start ? (
								<Text
									id={`${parent_id}_addresslist_date_time_text`}
									style={{
										color: "#6F6F6F",
										fontSize: 12,
									}}
								>
									{/* {moment(data?.validity?.policy_start).format(
										"MMMM Do YYYY, h:mm a"
									)} */}
									{utcTOLocal(data?.validity?.policy_start,"MMMM Do YYYY, h:mm a")}
								</Text>
							) : null}
							&nbsp;
							<Text
								id={`${parent_id}_addresslist_text`}
								style={{
									color: "#6F6F6F",
									fontSize: 12,
								}}
							>
								{data?.membership?.type?.value ?? " "}
							</Text>
						</Div>
					</Div>
				</Col>
				<Col
					id={`${parent_id}_addresslist_image_div`}
					md={1}
					lg={1}
					sm={6}
					xs={12}
					className="sl-action-root"
				>
					<Div
						id={`${parent_id}_addresslist_edit_delete_div`}
						style={{ display: "flex", alignItems: "center" }}
					>
						{isEdit && (
							<Image
								id={`${parent_id}_addresslist_edit_image`}
								onClick={(e) => onedit(data, this.props.index)}
								style={{ cursor: "pointer" }}
								src="images/icons8-edit1.svg"
								alt="edit"
							/>
						)}
						{isDelete && (
							<Image
								id={`${parent_id}_addresslist_trash_image`}
								onClick={() => ondelete(data, this.props.index)}
								style={{ cursor: "pointer", marginLeft: 15 }}
								src="images/icons8-trash1.svg"
								alt="delete"
							/>
						)}
					</Div>
				</Col>
			</Row>
		);
	}
}

export default ShowList;
