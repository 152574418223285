import React from "react";
import { Div, Avatar, H6, Text, Image } from "qdm-component-library";
import { withStyles } from "@material-ui/core";
import { UIColor } from "../../utils";

const materialStyles = (theme) => ({
  avatarCls: {
    "& img": {
      width: "25px !important",
      height: "25px !important",
      objectFit: "contain !important",
    },
  },
});

class SelectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
    };
  }
  styles = {
    rowFlex: {
      display: "flex",
      alignItems: "center",
    },
    columnFlex: {
      display: "flex",
      flexDirection: "column",
    },
    h6: {
      fontSize: 14,
      color: UIColor.primaryColor.color,
      margin: 0,
    },
    h61: {
      fontSize: 14,
      color: UIColor.secondaryButtonColor.color,
      margin: 0,
    },
    span: {
      fontSize: 12,
      color: UIColor.tertiaryText.color,
      margin: 0,
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA",
    },
    img: {
      width: 16,
      height: 16,
      marginRight: 10,
    },
  };

  render() {
    const { classes, showUpArrow, parent_id } = this.props;
    return (
      <React.Fragment>
        <Div
          id={`${parent_id}_selectcard_parent_div`}
          style={{ ...this.styles.rowFlex, flex: 1 }}>
          <Div
            id={`${parent_id}_selectcard_parent_sub_div`}
            style={{ ...this.styles.rowFlex, flex: 1 }}>
            {this.props?.pic ? (
              <Avatar
                id={`${parent_id}_selectcard_${this.props?.name?.[0]}_avatar`}
                src={this.props?.pic}
                variant="rounded"
                className={classes.avatarCls}
                inLineStyles={this.styles.avatar}
              />
            ) : (
              <Avatar
                id={`${parent_id}_selectcard_${this.props?.name?.[0]}_avatar`}
                variant="rounded"
                inLineStyles={this.styles.avatar}
                className={classes.avatarCls}
              >
                {this.props?.name?.[0]}
              </Avatar>
            )}
            <Div
              id={`${parent_id}_selectcard_title_div`}
              style={{ flex: 1 }}>
              <Div
                id={`${parent_id}_selectcard_title_sub_div`}
                style={{ ...this.styles.rowFlex, flex: 1 }}>
                <Div
                  id={`${parent_id}_selectcard_title_name_sub_div`}
                  style={{ flex: 1 }}>
                  {this.props?.title_name && (
                    <Text
                      id={`${parent_id}_selectcard_${this.props?.title_name}_text`}
                      style={this.styles.rowFlex}>
                      <H6
                        id={`${parent_id}_selectcard_${this.props?.title_name}_h6`.replaceAll(" ","_")}
                        className="pc_semibold" style={this.styles.h6}>
                        {this.props?.title_name}
                      </H6>
                      &nbsp;
                      {this.props.noOfIteams === 1 ? '' : (!this.props.moreopen) ? null : (
                        <H6
                          id={`${parent_id}_selectcard_${this.props.noOfIteams}_h6`.replaceAll(" ","_")}
                          className="pc_semibold" style={this.styles.h61}>
                          {this.props.noOfIteams ? (
                            `& ${this.props.noOfIteams - 1}+ more`
                          ) : (
                            <div />
                          )}
                        </H6>
                      )}
                    </Text>
                  )}
                  {this.props?.care_type && (
                    <Div
                      id={`${parent_id}_selectcard_${this.props?.care_type}_div`}
                      style={{ ...this.styles.rowFlex, marginTop: 7 }}>
                      <Div
                        id={`${parent_id}_selectcard_${this.props?.care_type}_sub_div`}
                        style={{ ...this.styles.rowFlex, marginRight: 10 }}>
                        {/* <Icons fontIcon="address-card-o" /> */}
                        <Image
                          id={`${parent_id}_selectcard_${this.props?.care_type}_image`}
                          style={this.styles.img}
                          src="images/icons8-privacy-policy.svg"
                          alt="id"
                        />
                        <Text
                          id={`${parent_id}_selectcard_${this.props?.care_type}_text`}
                          className="pc_regular" style={this.styles.span}>
                          {this.props?.care_type}
                        </Text>
                      </Div>
                    </Div>
                  )}
                </Div>
                {this.props?.showUpArrow && (
                  <Div
                    id={`${parent_id}_selectcard_uparrow_div`}
                    style={{ ...this.styles.rowFlex, width: 24, height: 24 }}
                  >
                    <Image
                      id={`${parent_id}_selectcard_uparrow_image`}
                      src="images/Group 3873.svg" alt="upArrow" />
                  </Div>
                )}
              </Div>
              {this.props.showDivider && (
                <Div
                  id={`${parent_id}_selectcard_showdivider_div`}
                  style={{ borderTop: `1px dashed ${UIColor.lineBorderFill.color}`, marginTop: 10 }}
                />
              )}
            </Div>
          </Div>
        </Div>
      </React.Fragment>
    );
  }
}

SelectCard.defaultProps = {
  pic: "",
  title_name: "",
  care_type: "",
  showUpArrow: false,
  showDivider: false,
};

export default withStyles(materialStyles)(SelectCard);
