import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import qdmstyles from './styles2.json'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import {
  Container,
  H6,
  Divider,
  Div,
  Avatar,
  Text,
  Icons,
  Card,
  OtpInput,
  Button,
  TapSelect,
  TermsAndConditions
} from 'qdm-component-library'
import { checkError, AlertProps, getMobileNumber, upsertPerson, UIColor } from '../../utils'
import { AlertContext } from '../../contexts'

class AppointmentPageDrawerOtpSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      otp: '',
      genderValue: '',
      firstNameValue: '',
      resend: ''
    }
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleSubmit = async () => {
    const { otp } = this.state
    const addmmber = this.props
    if (addmmber) {
      const checkmmr = await this.props.SIGN_IN({
        mobileNo: getMobileNumber(this.props.signUpData?.mobileNo),
        otp
      })
      if (checkmmr.payload.data.error === "invalid_grant") {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: checkmmr.payload.data.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true
        })
        return false
      } else {
        let userId = isNaN(Number(localStorage.getItem("idval")))
          ? 0
          : Number(localStorage.getItem("idval"));
        const relatedInput = {
          phone: getMobileNumber(this.props.signUpData?.mobileNo),
          dob: this.props.signUpData?.dob,
          firstName: this.props.signUpData?.firstName,
          lastName: this.props.signUpData?.lastName,
          gender: this.props.signUpData?.gender,
          email: this.props.signUpData?.emailId || '',
          relationType: this.state.genderValue,
          relationPersonId: [userId],
          personId: this.props.signUpData?.id,
        };

        await this.props.ADD_RELATED_PERSON(relatedInput)
        // if(addmmrss){
        //   console.log('p')
        // }
        this.props.nextSection('member_succss', this.props.signUpData)
      }

      return false
    }

    await this.props.SIGN_IN({
      mobileNo: `+${this.props.signUpData?.mobileNo?.countryData
        ?.callingCodes ?? '00'}${this.props.signUpData?.mobileNo.mobileNo}`,
      otp
    })
    const status = checkError(this.props.signIn);
    if (!status.isError) {
      const {
        mobileNo,
        firstName,
        lastName,
        dob,
        gender,
        emailId,
      } = this.props?.signUpData;
      const guestBookerAdd = {
        phone: getMobileNumber(mobileNo),
        dob,
        firstName,
        lastName,
        gender,
        email: emailId,
      };
      if (this.props?.signIn?.data?.access_token) {
        localStorage.removeItem('guestInfo')
        localStorage.setItem('token', this.props?.signIn?.data?.access_token)
      }
      const { error, data } = await upsertPerson(this.props.READ_PERSON, this.props.UPSERT_PERSON, guestBookerAdd)
      if (error && data.includes("User Already Exists ")) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Member Already Exists. Please try Sign Up using existing member",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true
        })
      } else if (error) {
        this.context.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: data,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true
        })
        this.setState({
          error: status.errMsg
        })
      } else {
        const personId = data.payload?.data?.id || 0
        localStorage.setItem('idval', personId)
        this.props.nextSection('movenextscreen')
      }
    } else {
      this.setState({
        error: status.errMsg
      })
    }
  }

  getAge = date => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, 'DD/MM/YYYY'), 'years', true)
      ) + 'Yrs'
    )
  }
  resendOTP = async () => {
    const res = await this.props.RESEND_OTP({
      mobileNo: `+${this.props.signUpData?.mobileNo?.countryData
        ?.callingCodes ?? '00'}${this.props.signUpData?.mobileNo.mobileNo}`
    })
    const status = checkError(this.props.resendOTP)
    if (status.isError) {
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: status.errMsg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true
      })
    } else {
      this.setState({
        resend: res?.payload?.data?.message
      })
    }
  }
  componentDidMount() { }

  shouldComponentUpdate(props, state) {
    return true
  }

  render() {
    const { appointmentDetails, addmmber, parent_id } = this.props
    return (
      <div
        id={`${parent_id}_addmember_otp_parent_div`}
      >
        <Container
          id={`${parent_id}_addmember_otp_parent_container`}
          key={'0'} style={{ marginTop: '6%' }}>
          <Div
            id={`${parent_id}_addmember_otp_newmember_div`}
            style={addmmber ? qdmstyles.de_da__c_cd : {}}>
            <Text
              id={`${parent_id}_addmember_otp_newmember_text`}
              key={'0'}
              name={appointmentDetails ? 'Add Member' : 'Sign Up as New Member'}
              style={!addmmber ? qdmstyles.dNMp : qdmstyles.dNMp1}
            ></Text>
            {addmmber && (
              <Div
                id={`${parent_id}_addmember_otp_back_div`}
                key={'1'}>
                <Button
                  id={`${parent_id}_addmember_otp_back_buttom`}
                  key={'0'}
                  name={'Back'}
                  style={qdmstyles.b__ae_ba_fcfdfc}
                  onClick={() => this.props.nextSection('back')}
                ></Button>
              </Div>
            )}
          </Div>
          <Divider
            id={`${parent_id}_addmember_otp_back_bottom_divider`}
            key={'1'}
            style={{ marginLeft: 0, marginRight: 0 }}
          ></Divider>

          <Div
            id={`${parent_id}_addmember_otp_mobile_div`}
            key={'2'} className={'qdm'} style={qdmstyles.vGaal}>
            <Div
              id={`${parent_id}_addmember_otp_mobile_sub_div`}
              key={'0'} className={'qdm'} style={qdmstyles.nilbd}>
              <Avatar
                id={`${parent_id}_addmember_otp_image`}
                key={'0'}
                variant={'circle'}
                letter={'Avatar'}
                src={'https://i.pravatar.cc/150?img=65'}
                alt={'Image'}
                // style={qdmstyles.ddd_dc_c_ee_dbb}
                style={{
                  margin: '0px',
                  marginRight: '12px',
                  height: '40px',
                  width: '40px'
                }}
              ></Avatar>

              <Div
                id={`${parent_id}_addmember_otp_signup_div`}
                key={'1'}>
                <H6
                  id={`${parent_id}_addmember_otp_signup_h6`}
                  key={'0'}
                  name={`${this.props.signUpData?.firstName} ${this.props?.signUpData?.middleName} ${this.props.signUpData?.lastName}`}
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: '#101010'
                  }}
                />

                <Text
                  id={`${parent_id}_addmember_otp_signup_text`}
                  key={'1'}
                  name={`${this.getAge(this.props.signUpData?.dob)} ${this.props.signUpData?.gender
                    }, +${this.props.signUpData?.mobileNo?.countryData
                      ?.callingCodes ?? '00'}${this.props.signUpData?.mobileNo
                        ?.mobileNo ?? 'XXXXXXXXX'}, ${this.props.signUpData?.emailId
                    }`}
                  style={qdmstyles.dlAv}
                ></Text>
              </Div>
            </Div>

            {!addmmber && (
              <Icons
                id={`${parent_id}_addmember_otp_pencil_icon`}
                key={'1'}
                fontIcon={'pencil'}
                ariaHidden={'true'}
                size={'large'}
                style={qdmstyles.fecea_ab__d_deed}
                onClick={() =>
                  this.props.nextSection('addmemberform', this.props.signUpData)
                }
              ></Icons>
            )}
          </Div>
          {addmmber && (
            <>
              <div
                id={`${parent_id}_addmember_otp_relationship_div`}
                style={qdmstyles.dda_e_dc_a_afcccbd}>
                What's your relationship with {this.props.signUpData?.firstName} ?{' '}
                <span
                  id={`${parent_id}_addmember_otp_relationship_start_span`}
                  style={{ color: 'red' }}>*</span>
              </div>
              <TapSelect
                id={`${parent_id}_addmember_otp_relationship_tapselect`}
                key={'4'}
                fullWidth={false}
                label={false}
                selectedBorderColor='#0071F2'
                selectedBackgroundColor='#0071F2'
                selectedTextColor='#fff'
                textSize='16'
                iconHeight='20'
                iconWidth='20'
                textColor='#000'
                options={[
                  { value: 'Father', label: 'Father' },
                  { value: 'Mother', label: 'Mother' },
                  { value: 'Brother', label: 'Brother' },
                  { value: 'Sister', label: 'Sister' },
                  { value: 'Others', label: 'Others' }
                ]}
                value={{
                  value: this.state.genderValue,
                  label: this.state.genderValue
                }}
                onChange={e => this.changeState('genderValue', e.value)}
                style={{ marginTop: '', paddingTop: '' }}
                defaultSelected={{
                  value: this.state.genderValue,
                  label: this.state.genderValue
                }}
                inLineStyles={{
                  borderColor: '#E0E0E0',
                  fontSize: '14px',
                  fontFamily: "'pc_regular' !important"
                }}
              // style={qdmstyles.SREcn}
              ></TapSelect>

              {/* <TextInput
                key={'0'}
                type={'text'}
                name={'text'}
                variant={'outlined'}
                helperTextType={'error'}
                label={
                  <div style={qdmstyles.dda_e_dc_a_afcccbd1}>
                    Mail Id (optional)
                  </div>
                }
                autoFocus={false}
                autoComplete={'off'}
                search={false}
                borderColor={'#E0E0E0'}
                elevation={'0'}
                placeholder={''}
                size={'medium'}
                value={this.state.firstNameValue}
                onChange={e =>
                  this.changeState('firstNameValue', e.target.value)
                }
                error={this.state?.error?.firstNameValue}
              ></TextInput> */}
              <br />
              <TermsAndConditions
                id={`${parent_id}_addmember_otp_send_appointment_terms`}
                value={true}
                checkboxTickColor={"#fff"}
                activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
                checkboxBorderColor={UIColor.lineBorderFill.color}
                checkboxBackgroundColor={UIColor.differentiationBackground.color}
                activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
                disabled
                inLineStyles={{
                  fontSize: '1px',
                  marginTop: '-10px',
                  marginBottom: '28px'
                }}
                lable={
                  <span
                    id={`${parent_id}_addmember_otp_send_appointment_span`}
                    style={{ fontSize: '12px', color: '#6F6F6F' }}>
                    Send appointment update and notification to this mail id
                  </span>
                }
              />
            </>
          )}
          <Div
            id={`${parent_id}_addmember_otp_verification_div`}
            key={'3'} className={'qdm'} style={qdmstyles.adff___ada_bfbec}>
            <Card
              id={`${parent_id}_addmember_otp_verification_card`}
              key={'0'} children={'Card'} style={qdmstyles.eca__f_f_fabe}>
              <Text
                id={`${parent_id}_addmember_otp_verification_text`}
                key={'0'}
                name={'OTP Verification'}
                style={qdmstyles.ffac_b__d_fc}
              ></Text>
              <Divider
                id={`${parent_id}_addmember_otp_verification_bottom_divider`}
                style={{ backgroundColor: '#F0F0F0' }}></Divider>

              <Text
                id={`${parent_id}_addmember_otp_verification_otpsend_text`}
                key={'1'}
                name={'Please enter the OTP sent to'}
                style={qdmstyles.ee_eea_c_a_eaffe}
              ></Text>

              <H6
                id={`${parent_id}_addmember_otp_mobileno_h6`}
                key={'2'}
                name={this.props.signUpData?.mobileNo?.mobileNo ?? '99******66'}
                style={qdmstyles.cbfd_a_f_d_ecaf}
              ></H6>

              <Div
                id={`${parent_id}_addmember_otp_div`}
                key={'3'} className={'qdm'} style={qdmstyles.fabbd_b___dfb}>
                <OtpInput
                  id={`${parent_id}_addmember_otp_otpinput`}
                  numberOfInputs={6}
                  key={'0'}
                  style={{ backgroundColor: '#f5faff' }}
                  value={this.state.otp}
                  onChange={e => this.changeState('otp', e)}
                ></OtpInput>
              </Div>

              <Text
                id={`${parent_id}_addmember_otp_didn't_text`}
                key={'4'}
                name={
                  <span
                    id={`${parent_id}_addmember_otp_didn't_span`}
                  >
                    Didn't receive OTP ?{' '}
                    <span
                      id={`${parent_id}_addmember_otp_resend_span`}
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() => this.resendOTP()}
                    >
                      Resend OTP
                    </span>
                  </span>
                }
                style={qdmstyles.bfa_d_b_d_bbfd}
              ></Text>
              {this.state.resend && (
                <Text
                  id={`${parent_id}_addmember_otp_resend_text`}
                  name={
                    <span style={qdmstyles.bfa_d_b_d_bbfd1}>
                      {this.state.resend}
                    </span>
                  }
                ></Text>
              )}
            </Card>
            <Button
              id={`${parent_id}_addmember_otp_Confirm_Sign_Up'_buttom`}
              key={'5'}
              type={'button'}
              variant={'outlined'}
              name={
                appointmentDetails
                  ? 'Confirm & Add Member'
                  : 'Confirm & Sign Up'
              }
              style={qdmstyles.eab_cb_ba_e_eedc}
              onClick={this.handleSubmit}
            // onClick={() => this.props.nextSection("movenextscreen")}
            ></Button>
          </Div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  registerUsers: state?.authSlice?.registerUsers,
  signIn: state?.authSlice?.signIn,
  resendOTP: state?.authSlice?.resendOTP
})
AppointmentPageDrawerOtpSection.contextType = AlertContext

export default connect(
  mapStateToProps,
  actions
)(withRouter(AppointmentPageDrawerOtpSection))