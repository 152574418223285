import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Text,
  TextInput,
  TapSelect,
  Div,
  Icons,
} from "qdm-component-library";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Calendar from "react-calendar";
import { Popover } from "@material-ui/core";
import { withAllContexts } from "../../../HOCs";
import { AlertProps, UIColor } from "../../../utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createVitalsJson } from "../createJson";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import {
  Vitals,
  predialysis,
  intradialysis,
  postdialysis,
} from "../vitalsJson";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useContext } from "react";
import { DialogContext } from "../../../contexts";
import Aadddialysistype, { Adddialysistype } from "./adddialysistype";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
function jsonconverter(data = []) {
  debugger;
  function removeSpaces(str = "") {
    return str.replace(/\s/g, "");
  }
  let result = {};
  data.map((v) => {
    result[removeSpaces(v?.vitalscode)] = {
      obsinternalid: v?._id,
      obscode: removeSpaces(v?.vitalscode),
      obsvalue: "",
      shortdesc: v?.shortdesc,
      isabnormal: true,
      abnormalmsg: '"Min"',
      type: v?.type,
      obsuom: v?.units_allowed?.[0]?._id,
      time: Date.now(),
      dropdownvalues: v?.dropdownvalues,
    };
  });
  return result;
}

const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
};
export const Dialysis = (props) => {
  console.log("Dialysis", props);
  const dispatch = useDispatch();
  const dialog = useContext(DialogContext);
  const selector = useSelector((state) => state.VitalsApiSlice);
  const encounter = useSelector(
    (state) => state?.chiefCompilenceMastersSlice.encounter_Details
  );
  const [dialysisData, setDialysisData] = useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [dailogComp, setDailogComp] = useState(<></>);
  var predialysisJSON = jsonconverter(predialysis?.result ?? []);
  var intradialysisJSON = jsonconverter(
    intradialysis?.result ? intradialysis?.result : []
  );
  var postdialysisJSON = jsonconverter(
    postdialysis?.result ? postdialysis?.result : []
  );

  async function insertDialysisWithEncouterid(dialysisDatafromsave) {
    debugger;
    let data = props?.dialysisData;
    if (
      Array.isArray(data?.component) &&
      data.component[0].predialysis &&
      data.component[0].postdialysis
    ) {
      data.component[0].predialysis = dialysisDatafromsave.predialysis;
      data.component[0].postdialysis = dialysisDatafromsave.postdialysis;
      data.component[0].intradialysis = dialysisDatafromsave.intradialysis;
    } else {
      data = {
        orgid: props?.orgdetails?.org_ID,
        patientid: props?.patientgetData?.Patient?.[0]?._id,
        confirmedby: props?.patientgetData?.Patient?.[0]?.personInfo?.[0]?._id,
        encountertype: props?.patientgetData?.type,
        encounterid: props?.patientgetData?._id,
        component: [
          {
            predialysis: dialysisDatafromsave?.predialysis ?? [],
            postdialysis: dialysisDatafromsave?.postdialysis ?? [],
            intradialysis: dialysisDatafromsave?.intradialysis ?? [],
          },
        ],
      };
    }

    let dataforupsert = [
      {
        db_name: process.env.REACT_APP_DB,
        entity: "CA_DialysisVitalsSigns",
        filter: { encounterid: props?.patientgetData?._id },
        doc: { ...data },
      },
    ];
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_ARRANGO_URL}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataforupsert,
    };
    axios(config)
      .then((res) => {
        debugger;
        console.log(res.data);
        props.getDialysisData();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
  useEffect(() => {
    let data = props?.dialysisData?.component?.[0];
    console.log(props?.dialysisData, data);
    setDialysisData({
      predialysis: data?.predialysis ?? [],
      intradialysis: data?.intradialysis ?? [],
      postdialysis: data?.postdialysis ?? [],
    });
  }, [props?.dialysisData]);

  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem("dialysis"));
    // if (Object.keys(data ?? {})?.length > 0) {
    //   setDialysisData(data);
    // }
  }, []);

  const handleDialog = (json, statekey, edit = false, editstate) => {
    setOpen(true);
    setDailogComp(
      <Aadddialysistype
        data={json}
        handlesave={handlesave}
        statekey={statekey}
        handlecancel={handleClose}
        edit={edit}
        editstate={editstate}
        handleEdit={handleEdit}
      />
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlesave = (statekey, value) => {
    try {
      value = { ...value, id: { id: uuidv4() } };
      let data = JSON.parse(JSON.stringify(dialysisData));
      data[statekey].push(value);
      insertDialysisWithEncouterid(data);
      // setDialysisData({
      //   ...dialysisData,
      //   [statekey]: data[statekey],
      // });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = (statekey, value) => {
    try {
      let data = JSON.parse(JSON.stringify(dialysisData));
      let index = data[statekey]?.findIndex((v) => {
        return v?.id?.id === value?.id?.id;
      });
      data[statekey][index] = value;
      insertDialysisWithEncouterid(data);

      // setDialysisData({
      //   ...dialysisData,
      //   [statekey]: data[statekey],
      // });
      //localStorage.setItem("dialysis", JSON.stringify(data));
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Row inLineStyles={styles.vitailsCard}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <Text
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                color: UIColor.primaryColor.color,
                margin: "5px 5px 0px 5px",
              }}
            >
              {"Pre dialysis"}
            </Text>
            <IconButton
              onClick={() => handleDialog(predialysisJSON, "predialysis")}
            >
              <AddCircleIcon />
            </IconButton>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "0px 8px 10 8px" }}
          >
            {loading ? (
              <Div
                id={`vitals_CircularProgress_div`}
                style={{ display: "flex", height: "100px" }}
              >
                <CircularProgress
                  id={`vitals_CircularProgress`}
                  style={{ margin: "auto" }}
                  size={23}
                />
              </Div>
            ) : (
              <div style={{ width: "100%" }}>
                {dialysisData.predialysis?.length > 0 ? (
                  dialysisData.predialysis.map((e, i) => {
                    return (
                      <div key={e?.id} style={{ width: "100%" }}>
                        {Object.keys(e ?? {}).length > 0 ? (
                          <div style={{ margin: "10px 5px" }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  whiteSpace: "nowrap", // Prevents text from wrapping
                                  overflow: "hidden", // Hides any overflow content
                                  textOverflow: "ellipsis", // Displays ellipsis for overflow
                                }}
                              >
                                {Object.keys(e ?? {})
                                  .slice(0, 10)
                                  .map((v) => {
                                    return (
                                      <span key={v}>
                                        <strong>{v}</strong>-
                                        <p style={{ display: "inline" }}>
                                          {
                                            dialysisData?.predialysis?.[i]?.[v]
                                              ?.obsvalue
                                          }
                                          ,
                                        </p>
                                      </span>
                                    );
                                  })}
                              </Typography>
                              <span style={{ marginRight: "30px" }}>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleDialog(
                                      predialysisJSON,
                                      "predialysis",
                                      true,
                                      e
                                    )
                                  }
                                >
                                  <Div
                                    id={"_body_pencil_div"}
                                    className="showWhenHover"
                                  >
                                    <Icons
                                      id={`_body_pencil_edit_icons`}
                                      fontIcon="pencil"
                                    />
                                  </Div>
                                </IconButton>
                              </span>
                            </div>
                            <Divider
                              orientation="horizontal"
                              variant="fullWidth"
                            />
                          </div>
                        ) : (
                          <Typography
                            variant="caption"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: "5px",
                              display: "block",
                            }}
                          >
                            No data available
                          </Typography>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "5px",
                      display: "block",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row inLineStyles={styles.vitailsCard}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <Text
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                color: UIColor.primaryColor.color,
                margin: "5px 5px 0px 5px",
              }}
            >
              {"Intra Dialysis"}
            </Text>
            <IconButton
              onClick={() => handleDialog(intradialysisJSON, "intradialysis")}
            >
              <AddCircleIcon />
            </IconButton>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "0px 8px 10 8px" }}
          >
            {loading ? (
              <Div
                id={`vitals_CircularProgress_div`}
                style={{ display: "flex", height: "100px" }}
              >
                <CircularProgress
                  id={`vitals_CircularProgress`}
                  style={{ margin: "auto" }}
                  size={23}
                />
              </Div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {dialysisData.intradialysis?.length > 0 ? (
                  dialysisData.intradialysis.map((e, i) => {
                    return (
                      <div key={e?.id} style={{ width: "100%" }}>
                        {Object.keys(e ?? {}).length > 0 ? (
                          <div style={{ margin: "10px 5px" }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  whiteSpace: "nowrap", // Prevents text from wrapping
                                  overflow: "hidden", // Hides any overflow content
                                  textOverflow: "ellipsis", // Displays ellipsis for overflow
                                }}
                              >
                                {Object.keys(e ?? {}).map((v) => {
                                  return (
                                    <span key={v}>
                                      <strong>{v}</strong>-
                                      <p style={{ display: "inline" }}>
                                        {
                                          dialysisData?.intradialysis?.[i]?.[v]
                                            ?.obsvalue
                                        }
                                        ,
                                      </p>
                                    </span>
                                  );
                                })}
                              </Typography>
                              <span style={{ marginRight: "30px" }}>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleDialog(
                                      intradialysisJSON,
                                      "intradialysis",
                                      true,
                                      e
                                    )
                                  }
                                >
                                  <Div
                                    id={"_body_pencil_div"}
                                    className="showWhenHover"
                                  >
                                    <Icons
                                      id={`_body_pencil_edit_icons`}
                                      fontIcon="pencil"
                                    />
                                  </Div>
                                </IconButton>
                              </span>
                            </div>
                            <Divider
                              orientation="horizontal"
                              variant="fullWidth"
                            />
                          </div>
                        ) : (
                          <Typography
                            variant="caption"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: "5px",
                            }}
                          >
                            No data available
                          </Typography>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "5px",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
            )}
          </Col>
        </Row>
        <Row inLineStyles={styles.vitailsCard}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={styles.vitalsHead}
          >
            <Text
              inLineStyles={{
                fontWeight: 600,
                fontSize: "14px",
                color: UIColor.primaryColor.color,
                margin: "5px 5px 0px 5px",
              }}
            >
              {"Post Dialysis"}
            </Text>
            <IconButton
              onClick={() => handleDialog(postdialysisJSON, "postdialysis")}
            >
              <AddCircleIcon />
            </IconButton>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            inLineStyles={{ padding: "0px 8px 10 8px" }}
          >
            {loading ? (
              <Div
                id={`vitals_CircularProgress_div`}
                style={{ display: "flex", height: "100px" }}
              >
                <CircularProgress
                  id={`vitals_CircularProgress`}
                  style={{ margin: "auto" }}
                  size={23}
                />
              </Div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {dialysisData.postdialysis?.length > 0 ? (
                  dialysisData.postdialysis.map((e, i) => {
                    return (
                      <div key={e?.id} style={{ width: "100%" }}>
                        {Object.keys(e ?? {}).length > 0 ? (
                          <div style={{ margin: "10px 5px" }}>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  whiteSpace: "nowrap", // Prevents text from wrapping
                                  overflow: "hidden", // Hides any overflow content
                                  textOverflow: "ellipsis", // Displays ellipsis for overflow
                                }}
                              >
                                {Object.keys(e ?? {}).map((v) => {
                                  return (
                                    <span key={v}>
                                      <strong>{v}</strong>-
                                      <p style={{ display: "inline" }}>
                                        {
                                          dialysisData?.postdialysis?.[i]?.[v]
                                            ?.obsvalue
                                        }
                                        ,
                                      </p>
                                    </span>
                                  );
                                })}
                              </Typography>
                              <span style={{ marginRight: "30px" }}>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleDialog(
                                      postdialysisJSON,
                                      "postdialysis",
                                      true,
                                      e
                                    )
                                  }
                                >
                                  <Div
                                    id={"_body_pencil_div"}
                                    className="showWhenHover"
                                  >
                                    <Icons
                                      id={`_body_pencil_edit_icons`}
                                      fontIcon="pencil"
                                    />
                                  </Div>
                                </IconButton>
                              </span>
                            </div>
                            <Divider
                              orientation="horizontal"
                              variant="fullWidth"
                            />
                          </div>
                        ) : (
                          <Typography
                            variant="caption"
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: "5px",
                            }}
                          >
                            No data available
                          </Typography>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "5px",
                      display: "block",
                    }}
                  >
                    No data available
                  </Typography>
                )}
              </div>
            )}
          </Col>
        </Row>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        {dailogComp}
      </Dialog>
    </>
  );
};

const data = {
  orgid: "Organization/10135",
  patientid: "Patient/11109",
  confirmedby: "Person/11346",
  encountertype: "op",
  encounterid: "Encounter/12007",
  component: [
    {
      predialysis: {
        PULSE: {
          obsinternalid: "VitalsCodes/10000",
          obscode: "PULSE",
          obsvalue: "121",
          isabnormal: true,
          abnormalmsg: '"Min"',
          obsuom: "VitalsMeasureCodes/10000",
          time: "2023-08-18T13:19:37.533Z",
        },
        TEMP: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "TEMP",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        BP: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "BP",
          obsvalue: "100",
          isabnormal: false,
          abnormalmsg: '"mmHg"',
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        TVA: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "TVA",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        CCA: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "CCA",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        PCS: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "PCS",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        SSN: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "SSN",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        LastVisitDate: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Last Visit Date",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        "Dialysis Date": {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Dialysis Date",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        HDMachineNo: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "HD Machine No",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        TargetDryWeight: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Target Dry Weight",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        TargetUF: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Target UF",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        PreHDWeight: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Pre HD Weight",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        PrevPostHDWeight: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Prev Post HD Weight",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        IDWG: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "IDWG",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Shift: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Shift",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Frequency: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Frequency",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Duration: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Duration",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Qb: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Qb",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Qd: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Qd",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        AVF: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "AVF",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        DialyzerType: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Dialyzer Type",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        SurfaceArea: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Surface Area",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        DialysateCa2: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Dialysate Ca2+",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        NoOfReuse: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "No of reuse",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        TYPE: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "TYPE",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Loading: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Loading",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        Maintenance: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Maintenance",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
      },
      postdialysis: {
        EndTime: {
          obsinternalid: "VitalsCodes/10000",
          obscode: "End Time",
          obsvalue: "121",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10000",
          time: "2023-08-18T13:19:37.533Z",
        },
        EndSN: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "End SN",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        BP: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "BP",
          obsvalue: "100",
          isabnormal: false,
          abnormalmsg: '"mmHg"',
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        TEMP: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "TEMP",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        PULSE: {
          obsinternalid: "VitalsCodes/10000",
          obscode: "PULSE",
          obsvalue: "121",
          isabnormal: true,
          abnormalmsg: '"Min"',
          obsuom: "VitalsMeasureCodes/10000",
          time: "2023-08-18T13:19:37.533Z",
        },
        PostHdweight: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "Post Hd weight",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        TotalWeightLoss: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "Total Weight Loss",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        TotalUF: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "Total UF",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        KT: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Kt/V",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
      },
      intradialysis: {
        PULSE: {
          obsinternalid: "VitalsCodes/10000",
          obscode: "PULSE",
          obsvalue: "121",
          isabnormal: true,
          abnormalmsg: '"Min"',
          obsuom: "VitalsMeasureCodes/10000",
          time: "2023-08-18T13:19:37.533Z",
        },
        TEMP: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "TEMP",
          obsvalue: "35",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        BP: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "BP",
          obsvalue: "100",
          isabnormal: false,
          abnormalmsg: '"mmHg"',
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        Heparin: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "Heparin",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        Qb: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Qb",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: '"Celcius"',
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        AP: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "AP",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        VP: {
          obsinternalid: "VitalsCodes/10001",
          obscode: "VP",
          obsvalue: "test",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10004",
          time: "2023-08-18T13:19:37.533Z",
        },
        Conductivity: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "Conductivity",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
        AccumulatedUFV: {
          obsinternalid: "VitalsCodes/10003",
          obscode: "AccumulatedUFV",
          obsvalue: "",
          isabnormal: true,
          abnormalmsg: "",
          obsuom: "VitalsMeasureCodes/10002",
          time: "2023-08-18T13:19:37.533Z",
        },
      },
    },
  ],
  createdby: "",
  createddate: 1692364777,
  updatedby: "",
  updatedate: 1692364777,
  activestatus: true,
};
