import React, { useLayoutEffect, useState } from "react";
import Pic from "../../../assets/img/bg_avc.svg";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { TicketComp } from "../../../components";
import { LoginComp } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import { actions } from "primarycare-binder";
import { Link } from "react-router-dom";
import moment from "moment";
import { DialogContext } from '../../../contexts';
import { WarningDialog  } from './warning';
import LoginBG from '../../../assets/img/AVC/loginBg.svg';

// const data = {
// 	date: "06 Aug 2021 | 09:45 am",
// 	app_id: "AP-123456",
// 	doctor: {
// 		name: "Dr. Priya dharshini N",
// 		type: "General Physician",
// 		org_name: "Apollo Hospital - Mount Road"
// 	},
// 	patient: {
// 		name: "Sudharsan N",
// 		age: "24",
// 		gander: "Male",
// 		id: "MRN451245",
// 		mobile: "+91 9988774411"
// 	}
// }

const useStyles = makeStyles((theme) => ({
	body: {
		backgroundImage: `url(${Pic})`,
		height: "100vh",
		flexGrow: 1,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		position: "relative",
	},
	loginCard: {
		border: "1px solid #DCE9F5",
		borderRadius: 16,
		background: "#FAFCFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 14px 26px #4881B81F",
		position: "absolute",
		top: "50%",
		width: "100%",
		transform: "translateY(-50%)",
	},
	div: {
		height: "82vh",
		padding: "41px 64px",
		display: "flex",
		margin: "auto",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 16,
	},
	warning:{
		'& .MuiDialog-paper':{
			borderRadius:16
		},
		'& .MuiDialogActions-root':{
			padding:0
		},
		'& .MuiDialogContent-root':{
			padding:0
		},
	},
	loginBg:{
		backgroundImage:`url(${LoginBG})`,
		backgroundSize:'contain',
		backgroundRepeat:'no-repeat'
	}
}));

export function AVCLoginPage(props) {
	const dispatch = useDispatch();
	const { loading, data } = useSelector(
		(state) => state?.avcMeetApiSlice?.avc_info
	);
 
	const [state, setData] = useState(null);

	const dialogContext = React.useContext(DialogContext);

	useLayoutEffect(() => {
		// AVC_LOGIN
		let id = props?.match?.params?.id;
		// if (data?.dataJson?.avc?.id) {
		if (id) {
			let data = dispatch(actions.AVC_LOGIN({ id: id }));
			// setData(data.payload.data ?? null);
		}
		// } else {
		// 	props.history.push(Routes.signIn)
		// }
	}, []);

	const loginAVC = (passcode, type) => {
  
		sessionStorage.setItem(
			"avc_token",
			JSON.stringify({ id: props?.match?.params?.id })
		);
		if(data?.dataJson?.avc?.start_time && moment() >= moment(data?.dataJson?.avc?.start_time.trim())){			
			props.history.push({
				pathname: Routes.avcLanding,
				state: { id: props?.match?.params?.id },
			});
		}
		else{
			dialogContext.setDialog({
				...dialogContext,
				open:true,
				positiveBtnDontShow:true,
				negativeBtnDontShow:true,
				className:classes.warning,
				body:(
					<WarningDialog
						date={
							data?.dataJson?.avc?.start_time ? moment(data?.dataJson?.avc?.start_time?.trim()).format('DD MMM,yyyy hh:mm A')
							: ''
						}
						onCancel={onCancel}
						proceed={proceed}
					/>
				)
			})
		}
	};

	const onCancel = () =>{
		dialogContext.setDialog({
			open:false,
		})
	} 

	const proceed = () =>{
		onCancel();
		props.history.push({
			pathname: Routes.avcLanding,
			state: { id: props?.match?.params?.id },
		});
	}

	const classes = useStyles();
	return (
		<React.Fragment className={classes.root_1}>
			<Container maxWidth={"md"}>
				<div className={classes.body}>
					{loading ? (
						" "
					) : (
						<Grid container className={classes.loginCard}>
							<Grid item xs={12} sm={6} className={`${classes.div} ${classes.loginBg}`}>
								<TicketComp data={data} />
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className={classes.div}
								style={{ backgroundColor: "white" }}
							>
								<LoginComp data={data} loginfun={loginAVC} />
							</Grid>
						</Grid>
					)}
				</div>
			</Container>
		</React.Fragment>
	);
}

export default withRouter(AVCLoginPage);
