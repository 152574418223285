import { Grid, makeStyles,Button } from "@material-ui/core";
import React from "react";
import JSONArr from "./vitalsJson";
import { VitalComp } from "./vitalsComp";
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F2F4FC",
	},
	actions:{
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "20px",
	},
	ButtonAlign: {
		fontSize: "12px",
		textTransform: "none",
		borderRadius: "8px",
		fontFamily: "pc_regular !important",
		padding: "5px 20px",
		backgroundColor: "#fff",
		color: "#000",
		marginLeft: "10px",
		border: "1px solid #E0E0E0",
		"&:hover": {
		  backgroundColor: "#0551a9",
		},
	  },
}));

export function VitalAvcPage(props) {
	const classes = useStyles();

	const updateVitals = () =>{
		props?.updateVitals && props.updateVitals()
	}

	return (
		<div style={{ padding: 20 }}>
			<Grid container spacing={3}>
				{JSONArr.result.map((val, i) => {
					return <VitalComp data={val} />;
				})}
			</Grid>
			<div className={classes.actions}>
				<Button
				className={classes.ButtonAlign}
				style={{
					backgroundColor: "#F0F0F0",
					color:"#B6B6B6"
				}}
				// disabled={fileUpload.length < 1 ? true : false}
				onClick={updateVitals}
				>
					Update Vitals and Confirm
				</Button>
				<Button
				className={classes.ButtonAlign}
				>
				Skip
				</Button>
			</div>
		</div>
	);
}
