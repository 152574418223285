import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import {
  Div,
  Row,
  Select,
  Col,
  H6,
  TextInput,
} from "qdm-component-library";

class CreditCardPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        currency:'',
        amountReceived:'',
        rate:'',
        total:'',
        nameInCard:'',
        cardNo:'',
        cvv:'',
        invoiceNo:'',
        issuedBy:'',
        apprNo:'',
        date:''
    };
  }

  componentDidMount() {}

   changeState = (key,val) =>{
      this.setState({
          ...this.state,[key]:val
      })
  }

  
  styles={
    h6:{
        fontSize:16,
        color:'#000000',
        margin:0
    },
    labelStyle:{
      color:'#6F6F6F',
      fontSize:'12px',
      marginBottom:'6px',
      fontFamily:`pc_regular !important`
  },
  selectBorderStyle:{
      borderRadius:8,
      borderColor:'#E0E0E0'
  },
  inputBorderStyle:{
      borderRadius:8,
      border:'1px solid #E0E0E0',
  },
}



  render() {
    const { labelStyle,inputBorderStyle,selectBorderStyle,h6 } = this.styles;

    return (   
        <Div style={{flex:1}}>
            <H6 className="pc_semibold" style={h6}>Credit Card Payment</H6>
            <Row>
                <Col xl={2} lg={2} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <Select label="Currency" placeholder="select..." showArrow  labelStyle={labelStyle} inLineStyles={selectBorderStyle} hoverColor="#0071F2" />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Amount Received"
                        placeholder=""
                        value={this.state.amountReceived}
                        onChange={evt =>this.changeState('amountReceived',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
                <Col xl={2} lg={2} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                     <TextInput 
                        label="Rate"
                        placeholder=""
                        value={this.state.rate}
                        onChange={evt =>this.changeState('rate',evt.target.value)}
                        labelStyle={labelStyle}
                        style={{...inputBorderStyle,background:'#F0F0F0'}}
                        hoverColor="#0071F2"
                        disabled
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Total"
                        placeholder=""
                        value={this.state.total}
                        onChange={evt =>this.changeState('total',evt.target.value)}
                        labelStyle={labelStyle}
                        style={{...inputBorderStyle,background:'#F0F0F0'}}
                        hoverColor="#0071F2"
                        disabled
                    />
                </Col>
                <Col xl={5} lg={5} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Name in Card"
                        placeholder=""
                        value={this.state.nameInCard}
                        onChange={evt =>this.changeState('nameInCard',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                        />
                </Col>
                <Col xl={5} lg={5} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                     <TextInput 
                        label="Card No."
                        placeholder=""
                        value={this.state.cardNo}
                        onChange={evt =>this.changeState('cardNo',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                        />
                </Col>
                <Col xl={2} lg={2} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                     <TextInput 
                        label="CVV"
                        placeholder=""
                        value={this.state.cvv}
                        onChange={evt =>this.changeState('cvv',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Invoice No."
                        placeholder=""
                        value={this.state.invoiceNo}
                        onChange={evt =>this.changeState('invoiceNo',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Issued By"
                        placeholder=""
                        value={this.state.issuedBy}
                        onChange={evt =>this.changeState('issuedBy',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                     <TextInput 
                        label="Appr No."
                        placeholder=""
                        value={this.state.apprNo}
                        onChange={evt =>this.changeState('apprNo',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12} style={{ padding:5,marginTop:5 }}>
                    <TextInput 
                        label="Date"
                        placeholder=""
                        type="date"
                        dateIcon="images/icons8-calendar-grey.svg"
                        value={this.state.date}
                        onChange={evt =>this.changeState('date',evt.target.value)}
                        labelStyle={labelStyle}
                        style={inputBorderStyle}
                        hoverColor="#0071F2"
                    />
                </Col>
            </Row>
        </Div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(CreditCardPayment);
