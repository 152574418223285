import React from 'react';
import { UIColor } from "../../utils";
export const EcgIcon = (props) => {
  let width = props.width ? props.width : "80";
  let height = props.height ? props.height : "80";
  let pulseColor = props.pulseColor ? props.pulseColor : "#ec6a49"; // Default pulse color
  let monitorColor = props.color ? props.color : UIColor.primaryColor.color;


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 80 80"
      preserveAspectRatio="xMidYMid meet" // Center the content within the viewBox
    >
      {/* Monitor Frame */}
      <rect x="0" y="0" width="100%" height="100%" fill={monitorColor} />
      <rect x="8" y="8" width="64" height="64" fill="white" />

      {/* ECG Pulse Waveform */}
      <path
        d="M16,40 L28,20 L40,40 L52,60 L64,40"
        fill="none"
        stroke={pulseColor}
        strokeWidth="2"
      />
    </svg>
  );
};
