import React, { useEffect } from "react";
// import Eye from "../../../assets/img/eye.svg";
// import CancelAppointment from "../../../assets/img/cancel_appointment.svg";
// import Modify from "../../../assets/img/modify.svg";
import Calendar from "../../../assets/img/calendar.svg";
import warning from "../../../assets/img/warning.svg";
import DotMenu from "../../../assets/img/dot_menu.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { Button, Avatar } from "@material-ui/core";
import { H6 } from "qdm-component-library";
import "./style.css";
import { Routes } from "../../../router/routes";
import { withRouter } from "react-router-dom";
import { convertTime, getImgUrl, makeName, TimeStampToMnthandDays, utcTOLocal } from "../../../utils";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch,useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { Eye, Modify, CancelAppointment } from "../../svg";
import { QueueManagementContext } from '../../../contexts';
import RemoveIcon from '@material-ui/icons/Remove';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { UIColor } from "../../../utils";


const useStyles = makeStyles((theme) => ({
  hover: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: UIColor.primaryColor.color,
      "& svg path": {
        fill: "#fff",
      },
    },
  },
  vedioConsult: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    marginRight: "10px",
    flex: 1,
    padding: "15px",
    // marginBottom: "8px",
    boxShadow: "none",
    borderTop: "1px solid #F0F0F0",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 8px 8px"
  },
  icon: {
		fontSize: 16,
		marginRight: "4px"
	},
	boxStatus: {
		top: "-8%",
		position: "absolute",
		right: "4%",
		display: "flex",
		alignItems: "center",
    fontSize: "12px !important",

	},
	removeIcon:{
		backgroundColor: "#6F6F6F",
		borderRadius: "50px", 
		color: "#fff", 
		fontSize: 12,
		marginRight: "4px"
	}
}));

function ViewCard(props) {
  const classes = useStyles();
  //const location =useLocation();

  const dispatch = useDispatch();

  const {
    displayData,
  } = React.useContext(QueueManagementContext);

    
  const {
    openView = (id) => null,
    handlesidebarCancelbooking = (id) => null,
    ProfileView = (id , val) => null,
    showBtn = false,
    isShowLabel = false,
    isFollowUp = false,
    val, nomodify, nocancel,
    past = false, noBookNow
  } = props;
  const id = val?.id || 0;
  let imgId = val?.resourcecode?.photo?.[0]?.fileid || "";
  let speciality =
    val?.Speciality?.[0]?.coding?.[0]?.display || " ";
  const status = val?.status;
  const comment = val?.comment;
  let name = val?.resourcecode?.shortdesc;
  if ((val?.resourcetype || "")?.toLowerCase() === "practitioner") {
    name = makeName(val?.resourcecode?.name?.[0] ?? {});
  }
  if (isFollowUp) {
    name = makeName(val?.practitionerid?.[0]?.name?.[0] ?? {});
    speciality = ""
    imgId = val?.practitionerid?.[0]?.photo?.[0]?.fileid || "";
  }
  const consultation = val?.consultationMode || "";
  const dateOfAppointment = utcTOLocal(val?.start, "DD MMM YY | hh:mm a"); 
  // convertTime(
  //   val?.start,
  //   "unix",
  //   "DD MMM YY | hh:mm a"
  // );

  const follow_up_month_days = TimeStampToMnthandDays(val)

  const fetchAppInfo = async (id) => {
    const data = await dispatch(
      actions.APPOINTMENT_READ({ appointmentId: id })
    );
    return data?.payload?.data || data?.AppointmentNotification || {};
  };


  const modificationFun = async () => {

    // props.history.push(Routes.doctorListingWithIdCall+"123")
    // props.history.push(Routes.doctorListing)

    const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(val.id)));
    appInfo["HealthcareService"] = appInfo["healthservice"];
    delete appInfo["healthservice"];
    props.history.push({
      pathname: Routes.doctorListing,
      state: {
        cityName: 0,
        location: {
          address: "Chennai, Tamil Nadu, India",
          latitude: 13.0826802,
          longitude: 80.2707184,
          city: "Chennai",
          location: "Chennai",
        },
        hospital: {
          label: "",
          value: appInfo?.orgid?.id || 0,
        },
        specialities: [],
        symptoms: [
          {
            label: "Fever",
            type: "Symptom",
            value: "SymptomsMapping/10000",
          },
        ],
        selectedUser: {
          //name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
          name: makeName(appInfo?.Person?.[0]?.name?.[0]),
          gender: appInfo?.Person?.[0]?.gender ?? "",
          birthDay: appInfo?.Person?.[0]?.birthDay,
          fileid: appInfo?.Person?.[0]?.photo?.[0]?.fileid,
        },
        symptomsSearch: [],
        slotId: appInfo.slotID[0],
        modifyUser: true,
        newBook: false,
        appointMentInfo: appInfo,
        //unregister : { is_Unregister :  props.location.state.unregister}
      },
    });
  };
  const BookNow = async () => {
    const appId = val?.appointmentid ? JSON.parse(val?.appointmentid) : undefined;
    const appInfo = JSON.parse(JSON.stringify(await fetchAppInfo(appId || val?.id)));
    const followUpId = isFollowUp ? val?._id : null;
    appInfo["HealthcareService"] = appInfo["healthservice"];
    delete appInfo["healthservice"];
    props.history.push({
      pathname: Routes.doctorListing,
      state: {
        appointmentId: appId,
        patientId: props.location.state.patientId || val?.patientid?.[0]?.id,
        userId: props.location.state.userId,
        cityName: 0,
        location: {
          address: "Chennai, Tamil Nadu, India",
          latitude: 13.0826802,
          longitude: 80.2707184,
          city: "Chennai",
          location: "Chennai",
        },
        hospital: {
          label: "",
          value: appInfo?.orgid?.id || 0,
        },
        specialities: [],
        symptoms: [
          {
            label: "Fever",
            type: "Symptom",
            value: "SymptomsMapping/10000",
          },
        ],
        selectedUser: {
          //name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
          name: makeName(appInfo?.Person?.[0]?.name?.[0]),
          gender: appInfo?.Person?.[0]?.gender ?? "",
          birthDay: appInfo?.Person?.[0]?.birthDay,
          fileid: appInfo?.Person?.[0]?.photo?.[0]?.fileid,
        },
        symptomsSearch: [],
        modifyUser: true,
        newBook: true,
        appointMentInfo: appInfo,
        isFollowUp,
        followUpId
      },
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const idopen = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { parent_id, visitCard } = props;
  return (
    <div
      id={`${parent_id}_viewcard_parent_div`}
      style={{
        marginBottom: "8%",
        boxShadow: "none",
        border: "1px solid #F0F0F0",
        backgroundColor: "#FFFFFF",
        minHeight: 133,
        // padding: "15px",
        borderRadius: 8,
        position: "relative"
      }}
    >
      {props.canceled && (
        <H6
        className={`${classes.boxStatus} pc_medium`}
        id={`${props.val._key}dashboard-CardList-${props.title}-H6`}
        style={{
          // backgroundColor: "#fff",
          color: status ? "#6F6F6F" : comment === "completed" ?  "#3BB213" :  "#FF4D4A",
        }}
      >
        {status ? <><RemoveIcon className={classes.removeIcon} /> IN COMPLETE</> : comment === "completed" 	? <><CheckIcon  className={classes.icon} /> COMPLETED</> : <><CancelIcon  className={classes.icon} /> CANCELLED </>}
      </H6>
      )}
      {isShowLabel && (
        <H6
          className={`${classes.boxStatus} pc_medium`}
          id={`${props.val._key}dashboard-CardList-${props.title}-H6`}
          style={{
            // backgroundColor: "#fff",
            color: status ? "#6F6F6F" : comment === "completed" ?  "#3BB213" :  "#FF4D4A",
          }}
      >
        {status ? <><RemoveIcon className={classes.removeIcon} /> IN COMPLETE</> : comment === "completed" 	? <><CheckIcon  className={classes.icon} /> COMPLETED</> : <><CancelIcon  className={classes.icon} /> CANCELLED </>}
      </H6>
      )}
      <Popover
        id={idopen}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          id={`${parent_id}_viewcard_label_typography`}
          className={clsx("viewDocText", "pc_regular")}
          style={{
            padding: 8,
            fontSize: 14,
            cursor: "pointer",
            color: "#101010",
          }}
          onClick={() => props.history.push(
            {
              pathname: Routes.viewreport,
              state: {
                appNo: props.val.appno,
                created: props.val.createddate,
                report_docs: props.val.report_docs,
                data: props.val,
            }
          })}
        >
          View Uploaded Report
        </Typography>
      </Popover>
      <div style={{ display: "flex", marginBottom: "16px", padding: "15px 15px 0px" }}>
        <div
          id={`${parent_id}_viewcard_image_div`}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          {/* <img
            id={`${parent_id}_viewcard_image`}
            alt="img"
            src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
            style={{
              width: "100%",
              height: "auto",
            }}
          /> */}
          <Avatar
            src={getImgUrl(imgId) || undefined}
            id={`${parent_id}_viewcard_image`}
          >{(name ? name : "")?.replaceAll("Mrs", "")?.replaceAll("Mr", "")?.replaceAll(".", "")[0]}</Avatar>
        </div>
        <div
          id={`${parent_id}_viewcard_speciality_div`}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "16px",
            flex: 1,
          }}
        >
          <div id={`${parent_id}_viewcard_speciality_sub_div`}>
            <H6
              id={`${parent_id}_viewcard_speciality_span`}
              style={{
                fontSize: "16px",
                color: "#101010",
                letterSpacing: 0,
                lineHeight: 1,
                margin: "0px 0px 8px",
              }}
              className="pc_medium"
            >
              {name ? name : " "}
            </H6>
            <H6
              id={`${parent_id}_viewcard_speciality_sub_span`}
              className="pc_regular"
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                letterSpacing: 0,
                lineHeight: 1,
              }}
            >
              {speciality}
            </H6>
          </div>

          {!isFollowUp && <div
            style={{ marginRight: "8px" }}
            id={`${parent_id}_viewcard_dotmenu_div`}
          >
            {props.val.report_docs?.length > 0 && (
              <img
                id={`${parent_id}_viewcard_dotmenu_image`}
                alt="img"
                src={DotMenu}
                style={{ cursor: "pointer" }}
                onClick={handleClick}
              />
            )}
          </div>}
        </div>
      </div>
      <div
        id={`${parent_id}_viewcard_sub_parent_div`}
        style={{ display: "flex", padding: "0px 15px 15px" }}
      >
        <div
          id={`${parent_id}_viewcard_sub_parent_image_div`}
          style={{
            width: "40px",
            height: "40px",
            backgroundColor: isFollowUp ? "#F4F7FC" : "#F9F9F9",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "16px",
          }}
        >
          {!isFollowUp ? (
            <img
              id={`${parent_id}_viewcard_calendar_image`}
              alt="img"
              src={Calendar}
            />
          ) : (
            <img
              id={`${parent_id}_viewcard_warning_image`}
              alt="img"
              src={warning}
            />
          )}
        </div>
        <div
          id={`${parent_id}_viewcard_appointment_div`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
            flex: 1,
          }}
        >
          <div id={`${parent_id}_viewcard_followup_div`}>
            <H6
              id={`${parent_id}_viewcard_followup_span`}
              className="pc_medium"
              style={{
                fontSize: "14px",
                color: isFollowUp ? "#2A60BC" : "#101010",
                lineHeight: 1,
                letterSpacing: 0,
                margin: "0px 0px 8px",
              }}
            >
              {isFollowUp ? `After ${follow_up_month_days}` : dateOfAppointment}
            </H6>
            <H6
              id={`${parent_id}_viewcard_consultation_span`}
              className="pc_regular"
              style={{
                color: "#6F6F6F",
                fontSize: "12px",
                letterSpacing: 0,
                lineHeight: 1,
                margin: 0,
              }}
            >
              {Array.isArray(consultation) ? consultation?.join() :  consultation }
            </H6>
          </div>

          <div
            style={{ display: "inherit" }}
            id={`${parent_id}_viewcard_view_div`}
          >
            {!showBtn &&
              // (props.visitCard ? (
              //   ""
              // ) :
               (
                <React.Fragment>
                  <Tooltip
                    id={`${parent_id}_viewcard_view_title_tooltip`}
                    title={visitCard ? "View Visit" : "View"}
                    arrow
                    placement="top"
                  >
                    <div
                      id={`${parent_id}_viewcard_view_hover_div`}
                      data-tip="View"
                      className={classes.hover}
                      style={{
                        padding: "1px 6px",
                        borderRadius: "5px",
                        marginLeft: "3px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        visitCard
                          ? handlesidebarCancelbooking(val?.id, "view")
                          : openView(id)
                      }
                    >
                      {/* <img
                        id={`${parent_id}_viewcard_view_eye_img`}
                        alt={"Eye"} src={Eye} /> */}
                      <Eye id={`${parent_id}_viewcard_view_eye_img`} />
                    </div>
                  </Tooltip>

                  <div
                    id={`${parent_id}_viewcard_title_Modify_Appointment_div`}
                    style={{
                      display: (past || nomodify) ? "none" : "unset",
                      width: "1px",
                      backgroundColor: "#E0E0E0",
                      marginLeft: "3px",
                      height: "12px",
                      marginTop: "6px",
                    }}
                  />
                  {(past || nomodify) ? null : (
                    <Tooltip
                      id={`${parent_id}_viewcard_title_Modify_Appointment_tooltip`}
                      title={visitCard ? "Revise Visit" : "Modify Appointment"}
                      arrow
                      placement="top"
                    >
                      <div
                        id={`${parent_id}_viewcard_title_Modify_div`}
                        className={classes.hover}
                        style={{
                          padding: "1px 6px",
                          borderRadius: "5px",
                          marginLeft: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          visitCard
                            ? handlesidebarCancelbooking(val?.id, "modify")
                            : modificationFun()
                        }
                      >
                        {/* <img
                          id={`${parent_id}_viewcard_title_Modify_img`}
                          alt={"Modify"} src={Modify} /> */}
                        <Modify id={`${parent_id}_viewcard_title_Modify_img`} />
                      </div>
                    </Tooltip>
                  )}

                  <div
                    id={`${parent_id}_viewcard_title_Cancel_Appointment_div`}
                    className="hover"
                    style={{
                      display: (past || nocancel) ? "none" : "unset",
                      width: "1px",
                      backgroundColor: "#E0E0E0",
                      marginLeft: "3px",
                      height: "12px",
                      marginTop: "6px",
                    }}
                  />

                  {(past || nocancel) ? null : (
                    <Tooltip
                      id={`${parent_id}_viewcard_title_Cancel_Appointment_tooltip`}
                      title={visitCard ? "Cancel Visit" : "Cancel Appointment"}
                      arrow
                      placement="top"
                    >
                      <div
                        id={`${parent_id}_viewcard_title_Cancel_Appointment_sub_div`}
                        className={classes.hover}
                        style={{
                          padding: "1px 6px",
                          borderRadius: "5px",
                          marginLeft: "3px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handlesidebarCancelbooking(val?.id, "cancel")
                        }
                      >
                        {/* <img
                          id={`${parent_id}_viewcard_title_Cancel_Appointment_img`}
                          alt={"CancelAppointment"} src={CancelAppointment} /> */}
                        <CancelAppointment
                          id={`${parent_id}_viewcard_title_Cancel_Appointment_img`}
                        />
                      </div>
                    </Tooltip>
                  )}
                </React.Fragment>
              )}

            {showBtn && !props.canceled && (
              <React.Fragment>
                <div
                  style={{ position: "relative", minWidth: 96 }}
                  id={`${parent_id}_viewcard_comment_div`}
                >
                  
                  {!noBookNow && 
                  <>
                  <Button
                    id={`${parent_id}_viewcard_book_noe_button`}
                    variant="outlined"
                    color="primary"
                    disabled={!!props.isDiabled}
                    className="pd-book-btn"
                    onClick={() => BookNow()}
                    style={{
                      color: "#01205C",
											borderColor: "#DFE5EB",
                      borderRadius: 8,
                      padding: "6px 21px",
                      backgroundColor: "#E5E8EE",
											textTransform: "capitalize"
                    }}
                  >
                    Book Again
                  </Button>
                  <Tooltip
                  id={`${parent_id}_viewcard_view_title_tooltip`}
                  title={visitCard ? "View Visit" : "View"}
                  arrow
                  placement="top"
                >
                  <div
                    id={`${parent_id}_viewcard_view_hover_div`}
                    data-tip="View"
                    className={classes.hover}
                    style={{
                      padding: "1px 6px",
                      borderRadius: "5px",
                      marginLeft: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      ProfileView(val?.id , val)
                    }
                  >
                    {/* <img
                      id={`${parent_id}_viewcard_view_eye_img`}
                      alt={"Eye"} src={Eye} /> */}
                    <Eye id={`${parent_id}_viewcard_view_eye_img`} />
                  </div>
                </Tooltip>
                </>}
                  
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      {consultation === "Video Consultation" && props.upcoming &&
        <div className={classes.vedioConsult}>
          <H6
            className="pc_regular"
            style={{
              fontSize: 12,
              margin: "5px 0px",
              color: "#F58B00",
            }}
          >
            Passcode : {val?.avc?.passCode ?? "*******"}
          </H6>
          <Button
            variant="outlined"
            color="primary"
            className="pd-book-btn"
            onClick={() => 
              {
                props.history.push({
                  pathname: Routes.videoCall,
                  state: {					
                    appointmentId:val?._key,
                    name:props?.loggedUserInfo?.data?.name?.text,
                    role:props?.loggedUserInfo?.data?.role,
                    data:displayData?.[0]
                  },
                });
              }
              }
            style={{
              color: "#0071F2",
              borderColor: "#0071F2",
              borderRadius: 8,
              padding: '4px 15px'
            }}
          >
            Join Now
          </Button>
        </div>
      }
    </div>
  );
}

export default withRouter(ViewCard);
