import React from 'react';
import Appointments from './appointmentStats';
import { makeStyles, Typography } from '@material-ui/core';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useHistory } from "react-router-dom";
import { Routes } from '../../../../router/routes';
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { SwitchTab } from '../../components';
import { TopHeader,LoadingComp } from '../../../../components';
import moment from 'moment';
import { checkWithCasbin } from '../../../../utils/permissonHandling';
import { getUtcTime,UIColor } from '../../../../utils'; 

const useStyles = makeStyles(() => ({
    root: {
    },
    content:{
        padding: 20,
        height:'calc(100vh - 72.4px)',
        overflow:'auto',
        backgroundColor: UIColor.differentiationBackground.color,
    },
    header: {
        fontSize: '16px',
        color: '#101010',
        fontFamily: 'pc_semibold',
    },
    rowCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    iconWrapper: {
        backgroundColor: UIColor.lineBorderFill.color,
        // backgroundColor: '#F0F0F0',
        width: 26,
        height: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 12,
        borderRadius: '50%',
        cursor: 'pointer'
    },
    tabWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        '& $wrapper': {
            '&:nth-child(odd)': {
                '& $countWrapper': {
                    borderColor: '#FF6384',
                    color: '#FF6384'
                }
            },
            '&:nth-child(even)': {
                '& $countWrapper': {
                    borderColor: '#4BC0C0',
                    color: '#4BC0C0'
                }
            },
        }
    },
    wrapper: {
        border: '1px solid #E0E0E0',
        borderRadius: 22,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontFamily: 'pc_regular',
        marginRight: 10,
        cursor: 'pointer'
    },
    countWrapper: {
        width: 29,
        height: 29,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px dashed',
        borderRadius: '50%',
        padding: 5,
        fontSize: 14,
        fontFamily: 'pc_semibold',
    },
    tabTitle: {
        fontSize: 14,
        fontFamily: 'pc_regular',
        color: '#101010',
        flex: 1,
        marginRight: 10,
        paddingLeft: 8,
    }
}));

export const AppointmentStats = (props) => {

    const classes = useStyles(props);
    const history = useHistory();
    const dispatch = useDispatch();


    const TAB = [
        { label: 'Appointments', value: 'appointments', actionsName: "APPOINTMENTS", count: '0' },
        { label: "First Visits", value: 'firstVisits', actionsName: "FIRST_VISIT_COUNT", count: '0' },
        { label: "Other Visits", value: 'otherVisits', actionsName: "OTHER_VISIT_COUNT", count: '0' },
        { label: "Walk-Ins", value: 'walkIns', actionsName: "WALK_INS_COUNT", count: '0' },
        { label: "New Patients", value: 'newPatients', actionsName: "NEW_PATIENT_COUNT", count: '0' },
        { label: "Today's Visit", value: 'todaysVisit', actionsName: "TODAY_VISIT", count: '0' },
    ];

    const [state, setState] = React.useState({
        selectedTab: TAB[0],
        report: []
    })

    const [permission, setPermission] = React.useState({});
    const [loader,setLoader] = React.useState(true)
    React.useEffect(() => {
        if (props?.location?.state?.index >= 0) {
            getSelectedTab(TAB[props?.location?.state?.index]);
            onChangeState('selectedTab', TAB[props?.location?.state?.index])
        }
    }, []);


    React.useEffect(() => {
        const initFun = async () => {
            let permission = await checkWithCasbin(["dashboard"]);
            setPermission(permission);
        };
        initFun();
    }, []);

    React.useEffect(() => {
        if (props?.location?.state?.index >= 0) {
            getSelectedTab(TAB[props?.location?.state?.index]);
            onChangeState("selectedTab", TAB[props?.location?.state?.index]);
        }
    }, [permission]);


    const navigateBack = () => {
        history.push({
            pathname: Routes.dashboard
        })
    }

    const onChangeState = (key, value) => {
        setState({
            ...state, [key]: value
        })
    }

    const getNurseDetails = async (data) => {
        setLoader(true)
        setState({ ...state, report: [] })
        let value;
        let payload = {
            orgID: props?.location?.state?.organizationID,
            practionerID: props?.location?.state?.practionerID,
            // startDate: 1641925800,
            startDate: getUtcTime(moment().startOf("day")),
            // endDate: 1642012199,
            endDate: getUtcTime(moment().endOf("day")),
            orgIDInt: props?.location?.state?.organizationInt,
        };

        switch (data.value) {
            case 'appointments':
                value = await dispatch(actions.GET_APPOINTMENTS_NURSE(payload));
                break;
            case 'todaysVisit':
                value = await dispatch(actions.GET_TODAY_VISITS_NURSE(payload));
                break;
            case 'firstVisits':
                value = await dispatch(actions.GET_FIRST_VISITS_NURSE(payload));
                break;
            case 'otherVisits':
                value = await dispatch(actions.GET_OTHER_VISITS_NURSE(payload));
                break;
            case 'walkIns':
                value = await dispatch(actions.GET_WALK_INS_NURSE(payload));
                break;
            case 'newPatients':
                value = await dispatch(actions.GET_NEW_PATIENTS_NURSE(payload));
                break;
            default:
                value = await dispatch(actions.GET_APPOINTMENTS_NURSE(payload));
                break;
        }
        setLoader(false)
        return value
    }

    const getDoctorDetails = async (data) => {
        setLoader(true)
        setState({ ...state, report: [] })
        let value;
        let payload = {
            orgID: props?.location?.state?.organizationID,
            practionerID: props?.location?.state?.practionerID,
            startDate: getUtcTime(moment().startOf("day")),
            endDate: getUtcTime(moment().endOf("day")),
            orgIDInt: props?.location?.state?.organizationInt,
        };

        switch (data.value) {
            case 'appointments':
                value = await dispatch(actions.GET_APPOINTMENTS_DOCTOR(payload));
                break;
            case 'todaysVisit':
                value = await dispatch(actions.GET_TODAY_VISITS_DOCTOR(payload));
                break;
            case 'firstVisits':
                value = await dispatch(actions.GET_FIRST_VISITS_DOCTOR(payload));
                break;
            case 'otherVisits':
                value = await dispatch(actions.GET_OTHER_VISITS_DOCTOR(payload));
                break;
            case 'walkIns':
                value = await dispatch(actions.GET_WALK_INS_DOCTOR(payload));
                break;
            case 'newPatients':
                value = await dispatch(actions.GET_NEW_PATIENTS_DOCTOR(payload));
                break;
            default:
                value = await dispatch(actions.GET_APPOINTMENTS_DOCTOR(payload));
                break;
        }
        setLoader(false)
        return value
    }

    const getFrontDeskDetails = async (data) => {
        setLoader(true)
        setState({ ...state, report: [] })
        let value;
        let payload = {
            orgID: props?.location?.state?.organizationID,
            practionerID: props?.location?.state?.practionerID,
            startDate: getUtcTime(moment().startOf("day")),
            endDate: getUtcTime(moment().endOf("day")),
            // startDate: 1641925800,
            // endDate: 1642012199,
            orgIDInt: props?.location?.state?.organizationInt,
        };

        switch (data.value) {
            case 'appointments':
                value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'todaysVisit':
                value = await dispatch(actions.GET_TODAY_VISITS(payload));
                break;
            case 'firstVisits':
                value = await dispatch(actions.GET_FIRST_VISITS(payload));
                break;
            case 'otherVisits':
                value = await dispatch(actions.GET_OTHER_VISITS(payload));
                break;
            case 'walkIns':
                value = await dispatch(actions.GET_WALK_INS(payload));
                break;
            case 'newPatients':
                value = await dispatch(actions.GET_NEW_PATIENTS(payload));
                break;
            default:
                value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
        }
        setLoader(false)
        return value
    }

    const getSelectedTab = async (data) => {
        let value;
        if (permission?.write?.indexOf("listingScreenN") > -1) {
            value = await getNurseDetails(data);
        } else if (permission?.write?.indexOf("listingScreenD") > -1) {
            value = await getDoctorDetails(data);
        } else if (permission?.write?.indexOf("listingScreenF") > -1) {
            value = await getFrontDeskDetails(data);
        }
        if (value) {
            setState({
                ...state,
                report: value?.payload?.data,
                selectedTab: data
            })
        }

    }

    return (
        <div className={classes.root}>
            <TopHeader
                parent_id={'appointment_listing'}
                noHome={true} middleComponent={<></>}
            />
            <div className={classes.content}>
                <div className={classes.rowCenter}>
                    <div className={classes.iconWrapper} onClick={navigateBack}>
                        <NavigateBeforeIcon />
                    </div>
                    <Typography variant="body1" className={classes.header}>{'Appointment Stats'}</Typography>
                </div>

                <SwitchTab
                    orgID={props?.location?.state?.organizationID}
                    orgIDInt={props?.location?.state?.organizationInt}
                    practionerID={props?.location?.state?.practionerID}
                    roleId={props?.location?.state?.roleId}
                    options={TAB}
                    selectedTab={state.selectedTab}
                    getSelectedTab={getSelectedTab}
                />
                <LoadingComp loading={loader} style={{alignItems: "center", height:"65vh"}} >
                <div style={{ paddingTop: 12 }}>
                    {state.report.length > 0 ? <Appointments
                        write={permission?.write}
                        selectedTab={state.selectedTab}
                        report={state.report}
                    /> : <div><p style={{ textAlign: "center", marginTop: "11%" }}>No data found</p></div>}
                </div>
                </LoadingComp>
            </div>
        </div>
    )
}