export const avcList = {
	// roles: [
	// 	{
	// 		name: "Front Office",
	// 		vist: true,
	// 		active: true,
	// 		child: [
	// 			{ name: "Virtual waiting room", active: true },
	// 			{ name: "Receptionist", active: false },
	// 			{ name: "Visit Registration", active: false },
	// 			{ name: "Mr. Ramesh (Receptionist)", active: false },
	// 		],
	// 		steps: [
	// 			{ name: "Welcome", active: true, vist: false },
	// 			{ name: "Consent Acceptance", active: false, vist: false },
	// 			// { name: "Documents upload", active: false, vist: false },
	// 			{ name: "Waiting Lobby", active: false, vist: false },
	// 		],
	// 	},
	// 	{
	// 		name: "Nurse Station",
	// 		vist: false,
	// 		active: false,
	// 		child: [
	// 			{ name: "Virtual waiting room", active: true },
	// 			{ name: "Ms. geetha", active: false },
	// 			{ name: "Visit Registration", active: false },
	// 			{ name: "Mr. Ramesh (Receptionist)", active: false },
	// 		],
	// 		steps: [
	// 			{ name: "Welcome", active: true, vist: false },
	// 			// { name: "Vital Details update", active: false, vist: false },
	// 			{ name: "Waiting Lobby", active: false, vist: false },
	// 		],
	// 	},
	// 	{
	// 		name: "Doctor Consultaion",
	// 		vist: false,
	// 		active: false,
	// 		child: [
	// 			{ name: "Virtual waiting room", active: true },
	// 			{ name: "Dr. Ramesh (Receptionist)", active: false },
	// 			{ name: "Visit Registration", active: false },
	// 			{ name: "Mr. Ramesh (Receptionist)", active: false },
	// 		],
	// 		steps: [
	// 			{ name: "Waiting Lobby", active: true, vist: false },
	// 			{ name: "Doctor Consultation", active: false, vist: false },
	// 			{ name: "Consultation Completed", active: false, vist: false },
	// 		],
	// 	},
	// ],
};
