import React, { useState } from 'react';
import { Div, H6, Text, Divider, TextInput, Icons, TextArea } from 'qdm-component-library';
import { makeStyles, Popover } from '@material-ui/core';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { CalendarIcon } from '../../../../components';
import StatusCard from './statusCard';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { UIColor } from '../../../../utils';
// const statusTypeColor = {
//     "active": "#38c20a",
//     "completed": "#38c20a",
//     "on hold": "#f58b00",
//     "cancel": "#ff4d4a"
// }
const useStyles = makeStyles({
    dateWrapper: {
        border: `1px solid ${UIColor.lineBorderFill.color}`,
        borderRadius: 8,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        minHeight: 36,
        '&:hover': {
            borderColor: UIColor.primaryColor.color
        }
    },
});

const ShowList = {
    "Active": ["On-Hold", "Discontinue", "Continue"],
    "On-Hold": ["Discontinue", "Continue"],
    "Continue": ["On-Hold", "Discontinue"],
    "Discontinue": [],
}
const statusColors = {
    "Draft": { color: "rgb(245, 139, 0)", background: "rgb(245, 139, 0)" },
    "On-Hold": { color: "white", background: "rgb(245, 139, 0)" },
    "Discontinue": { color: "black", background: UIColor.lineBorderFill.color },
    "Continue": { color: "white", background: UIColor.primaryColor.color },
    //"Active": { color: UIColor.success.color, background: "hsl(105, 62%, 90%)" },
    "Active": { color: UIColor.success.color, background: "#fff" },
    // "Cancel": { color: "#FF4D4A", background: "#FFE1E0" }
}

const ChangeStatus = (props) => {
    const classes = useStyles(props);
    const styles = {
        historyHeader: {
            backgroundColor: UIColor.greyBackground.color,
            padding: "12px 16px 6px 16px",
            display: "flex",
            cursor: "pointer",
        },
        lastSection: {
            display: "flex",
            marginTop: "4px",
        },
        forwardIcon: {
            fontSize: "14px",
            color: UIColor.secondaryText.color,
        },
        lastSectionText: {
            fontSize: "12px",
            color: UIColor.secondaryText.color,
            marginLeft: "8px",
            paddingRight: "32px",
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color,
        },
        root: {
            minWidth: 400,
            borderRadius: 10
        },
        headerText: {
            color: UIColor.primaryText.color,
            fontSize: 12,
            flex: 1,
            margin: 0
        },
        header: {
            padding: 10
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        saveText: {
            color: UIColor.primaryColor.color,
            fontSize: 12,
            cursor: 'pointer'
        },
        cancelText: {
            color: UIColor.secondaryText.color,
            fontSize: 12,
            marginRight: 10,
            cursor: 'pointer'
        },
        divider: {
            margin: 0
        },
        title: {
            color: UIColor.primaryText.color,
            fontSize: 12,
            margin: 0,
            marginBottom: 5
        },
        dateText: {
            fontSize: 12,
            color: UIColor.secondaryText.color,
            flex: 1
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color,
            height: 36,
            padding: '5px 10px'
        },
        column: {
            marginBottom: 10
        },
        collapse: {
            backgroundColor: UIColor.greyBackground.color,
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        statusHis: {
            fontSize: 12,
            color: UIColor.primaryText.color,
            flex: 1
        },
        expand: {
            padding: 10
        },
        flexCenterBetween: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        span: {
            fontSize: 10,
            color: UIColor.secondaryText.color
        },
    };
    const [showHistory, setShowHistory] = useState(false)
    const [state, setState] = useState({
        reason: props?.statusReason,
        statusType: props?.changeStatusData,
        date: props?.statusperiod
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const onChangeState = (key, value) => {
        setState({ ...state, [key]: value })
    }

    const handleClick = (event) => {
        setAnchorEl(event);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const cancel = () => {
        props?.cancelButtonAction && props.cancelButtonAction();
    }

    const save = () => {
        props?.saveButtonAction && props.saveButtonAction(state.statusType, state.reason, state.date)
    }
    let selectedList = ShowList[props?.changeStatusData?.label];
    selectedList = selectedList ? selectedList : [];
    let StatusList = props?.statusOption.filter(val => selectedList.indexOf(val.label) > -1)
    const { parent_id } = props;
    return (
        <div
            id={`${parent_id}_change_status_parent_div`}
            style={styles.root}>

            <Div
                id={`${parent_id}_change_status_header_div`}
                style={styles.header}>
                <Div
                    id={`${parent_id}_change_status_${props?.title.replaceAll(" ", "_")}_div`}
                    style={styles.flexCenter}>
                    <H6
                        id={`${parent_id}_change_status_${props?.title.replaceAll(" ", "_")}_h6`}
                        className="pc_semibold" style={styles.headerText}>{props?.title}</H6>
                    {(props?.changeStatusData?.label !== "Discontinue") && <Div style={styles.flexCenter}>
                        {props?.cancelButton && <Text
                            id={`${parent_id}_change_status_${props?.cancelButton.replaceAll(" ", "_")}_text`}
                            className="pc_medium" onClick={() => cancel()} style={styles.cancelText}>{props?.cancelButton}</Text>}
                        {props?.saveButton && <Text
                            id={`${parent_id}_change_status_${props?.saveButton.replaceAll(" ", "_")}_text`}
                            className="pc_medium" onClick={save} style={styles.saveText}>{props?.saveButton}</Text>}
                    </Div>}
                </Div>
            </Div>
            <Divider
                id={`${parent_id}_change_status_divider`}
                style={styles.divider} />
            {(props?.changeStatusData?.label !== "Discontinue") && <React.Fragment>
                <Div
                    id={`${parent_id}_change_status_Change_to_title_div`}
                    style={{ padding: 10 }}>
                    <Div
                        id={`${parent_id}_change_status_Change_to_title_sub_div`}
                        style={styles.column}>
                        <H6
                            id={`${parent_id}_change_status_Change_to_h6`}
                            className={"pc_regular"} style={styles.title}>Change to</H6>
                        <Div
                            id={`${parent_id}_change_status_StatusCard_div`}
                            style={styles.flexCenter}>
                            {
                                StatusList && StatusList?.map((_, i) => {
                                    return (
                                        <StatusCard
                                            parent_id={'changestatus' + i}
                                            backgroundColor={statusColors[_?.label]?.background}
                                            color={statusColors[_?.label]?.color}
                                            value={_}
                                            activebackgroundColor={"green"}
                                            activebackgroundColor={"green"}
                                            activeStatus={state?.statusType}
                                            changeStatus={(data) => onChangeState('statusType', data)}
                                        />
                                    )
                                })
                            }
                        </Div>
                    </Div>
                    {(state?.statusType?.label) === "On-Hold" && <Div
                        id={`${parent_id}_change_status_Till Date-Time_div`}
                        style={styles.column}>
                        <H6
                            id={`${parent_id}_change_status_Till Date-Time_h6`}
                            className={"pc_regular"} style={styles.title}>Till Date & Time</H6>
                        <Div
                            id={`${parent_id}_change_status_Till_Date-Time_h6`}
                            className={classes.dateWrapper}>
                            <Text
                                id={`${parent_id}_change_status_Till_Date-Time_text`}
                                className="pc_regular" style={styles.dateText}>{state?.date ? state?.date : ' '}</Text>
                            <Text
                                id={`${parent_id}_change_status_calednder_text`}
                                style={{ cursor: 'pointer', display: 'flex' }} aria-describedby={id} onClick={(event) => handleClick(event?.currentTarget)}><CalendarIcon
                                    id={`${parent_id}_change_status_calednder_icon`}
                                    color={UIColor.tertiaryText.color} width={"16"} height={"16"} /></Text>
                        </Div>
                    </Div>}
                    <Div
                        id={`${parent_id}_change_status_Reason_div`}
                        style={styles.column}>
                        <H6
                            id={`${parent_id}_change_status_add_Reason_h6`}
                            className={"pc_regular"} style={styles.title}>Reason</H6>
                        <TextArea
                            id={`${parent_id}_change_status_Reason_textarea`}
                            value={state?.reason}
                            onChange={(evt) => onChangeState('reason', evt.target.value)}
                            variant={"outlined"}
                            hoverColor={UIColor.lineBorderFill.color}
                            placeholder="Add reason"
                            style={{ ...styles.borderStyle, height: '70px' }}
                        />
                    </Div>
                </Div>
            </React.Fragment>}
            <Div
                id={`${parent_id}_change_status_Show_Histor_div`}
                style={styles.column}>
                <div
                    id={`${parent_id}_change_status_Show_Histor_sub_div`}
                    style={styles.historyHeader} onClick={() => { setShowHistory(!showHistory) }}>
                    <Text
                        id={`${parent_id}_change_status_Show_Histor_text`}
                        style={{ fontSize: "12px", fontWeight: 500 }}>Show History</Text>
                    <div
                        id={`${parent_id}_change_status_Show_div`}
                        style={{ flexGrow: 1 }}></div>
                    <Icons
                        id={`${parent_id}_change_status_chevron-up-down_icons`}
                        fontIcon={showHistory ? "chevron-up" : "chevron-down"}
                        style={styles.iconSize} />
                </div>
                <div>
                    {showHistory ? (
                        <React.Fragment>
                            {
                                props.statusHistory?.map((val, i) => {
                                    return <div
                                        id={`${parent_id}_change_status_${i}_Practitioner_div`}
                                        style={{ padding: "6px 16px 0px" }}>
                                        <div
                                            id={`${parent_id}_change_status_${i}_Practitioner_sub_div`}
                                            style={{ display: "flex" }}>
                                            <Text
                                                id={`${parent_id}_change_status_${i}_${val?.PersonID?.[0]?.name?.text ?? "".replaceAll(" ", "_")}_Practitioner_sub_div`}
                                                style={{
                                                    paddingRight: "35px",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {val?.PersonID?.[0]?.name?.text ?? ""}
                                            </Text>
                                            <div
                                                id={`${parent_id}_change_status_${i}_Practitioner_div`}
                                                style={{ flexGrow: 1 }}></div>
                                            <Text
                                                id={`${parent_id}_change_status_${i}_Practitioner_text`}
                                                inLineStyles={styles.historySectionText}>Practitioner</Text>
                                        </div>

                                        <div
                                            id={`${parent_id}_change_status_${i}_oldsatatus_div`}
                                            style={styles.lastSection}>
                                            {val?.old_status && <Text
                                                id={`${parent_id}_change_status_${i}_${val?.old_status}_text`}
                                                inLineStyles={{
                                                    color: UIColor.secondaryColor.color,
                                                    ...styles.historySectionText,
                                                }}
                                            >
                                                {val?.old_status}
                                            </Text>}
                                            {val?.old_status && <ArrowForwardIcon style={styles.forwardIcon} />}
                                            <Text
                                                id={`${parent_id}_change_status_${i}_${val?.new_status}_text`}
                                                inLineStyles={{
                                                    color: UIColor.success.color,
                                                    ...styles.historySectionText,
                                                }}
                                            >
                                                {val?.new_status}
                                            </Text>
                                            <div
                                                id={`${parent_id}_change_status_${i}_date_div`}
                                                style={{ flexGrow: 1 }}></div>
                                            <Text
                                                id={`${parent_id}_change_status_${i}_${val?.toperiod}_text`}
                                                inLineStyles={{
                                                    color: UIColor.secondaryText.color,
                                                    fontWeight: 500,
                                                    ...styles.historySectionText,
                                                }}
                                            >
                                                {val?.toperiod ? moment.unix(val?.toperiod).format("DD, MMM YYYY") : ""}
                                            </Text>
                                        </div>
                                        <Divider
                                            id={`${parent_id}_change_status_${i}_divider`}
                                            style={{ margin: "8px 0px" }}></Divider>
                                    </div>
                                })
                            }
                        </React.Fragment>
                    ) : null}
                </div>

            </Div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.muiPopovers }}
            >
                <DatePicker
                    id={`${parent_id}_change_status_DatePicker`}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    // selected={this.state.dob.value}
                    selected={state.date ? new Date(moment(state.date, "DD-MM-YYYY,hh:mm A")) : new Date()}
                    inline
                    onChange={date => {
                        onChangeState('date', moment(date).format('DD-MM-YYYY,hh:mm A'));
                        handleClose()
                    }}
                // placeholderText="DD-MM-YYYY"
                // maxDate={new Date()}
                // className={"pc_regular"}
                />
            </Popover>
        </div >
    )
}

export default ChangeStatus;

ChangeStatus.defaultProps = {
    title: 'Change Status',
    statusOption: [],
    changeStatusData: {},
    statusHistory: {},
    cancelButtonAction: null,
    saveButtonAction: null,
    saveButton: '',
    cancelButton: ''
}