import React from "react";
import { Button, Div, Row, H6, Avatar } from "qdm-component-library";
import "./dashboard.css";
import { Routes } from "../../router/routes";
import { withRouter } from "react-router-dom";
import RefferalImg from "../../assets/img/icons8-Referral.svg"
import { ReferralDetails } from "../../contexts";

function RefferalConfirm(props) {
    const [btnName,setBtnName] = React.useState({
        refferalBtn : false,
        bookApp : false
    })
    const referralData = React.useContext(ReferralDetails);
    const bookingAppointment = () => {
        setBtnName({
            ...btnName,
            bookApp : true
        })
        let userId = props.location?.state?.userId
        props.history.push({
            pathname: Routes.doctorConsultation,
            state: {
                userId: userId,
                mobileNumber: props.location?.state?.mobileNumber,
                appointmentId: props.location?.state?.appointmentId,
                patientId: props.location?.state?.patientId || props?.primaryMember?.allData?.Patientname?.[0]?.id,
                walkin: props.location?.state?.guest,
                mobileNo: props.location?.state?.mobileNumber,
            }
        })
    }
    const refferalBooking = () => {
        setBtnName({
            ...btnName,
            refferalBtn : true
        })
        referralData.setReferralDetails({
            letter: {},
            from: {},
            to: {},
            details: {},
            note: {},
        })
        let userId = props.location?.state?.userId
        props.history.push({
            pathname: Routes.patientReferral,
            state: {
                userId: userId,
                mobileNumber: props.location?.state?.mobileNumber,
                appointmentId: props.location?.state?.appointmentId,
                patientId: props.location?.state?.patientId || props?.primaryMember?.allData?.Patientname?.[0]?.id,
                walkin: props.location?.state?.guest,
                primaryMember: props.primaryMember,
                mobileNo: props.location?.state?.mobileNumber,
            }
        })
    }
    const { parent_id } = props;
    return (
        <Row
            id={`${parent_id}_refferalconfirm_parent_row`}
            inLineStyles={{ display: 'flex', height: "100vh" }}>
            <Div
                id={`${parent_id}_refferalconfirm_parent_div`}
                style={{
                    margin: "auto 0px auto 0px", padding: "62px",
                    textAlign: "center"
                }}>
                <Div
                    id={`${parent_id}_refferalconfirm_img_div`}
                    className="ref-confirm-avr-parent"> <Avatar
                        id={`${parent_id}_refferalconfirm_image`}
                        className="ref-confirm-avr" src={RefferalImg} /> </Div>
                <H6
                    id={`${parent_id}_refferalconfirm_appointment_booking_h6`}
                    variant="h6" style={{ fontFamily: "'pc_medium' !important", fontSize: "18px" }}>Would you like to add any referral to this appointment booking?</H6>
                <Div
                    id={`${parent_id}_refferalconfirm_addrefferal_div`}
                > <Button
                    id={`${parent_id}_refferalconfirm_addrefferal_buttom`}
                    style={{ fontFamily: "'pc_medium' !important" }} onClick={() => refferalBooking()} className="ref-confirm-btn-bgcolor ref-confirm-btn" disabled={btnName.refferalBtn} >{btnName.refferalBtn ? "Adding referrak" : "Yes, add Referral"}</Button></Div>
                <Div
                    id={`${parent_id}_refferalconfirm_No_proceed_div`}
                > <Button
                    id={`${parent_id}_refferalconfirm_No_proceed_buttom`}
                    style={{ fontFamily: "'pc_medium' !important" }} onClick={() => bookingAppointment()} className="ref-confirm-btn" disabled={btnName.bookApp}>{btnName.bookApp ? "Booking" : "No, proceed with booking"}</Button></Div>
            </Div>
        </Row>
    )
}

export default withRouter(RefferalConfirm);