import React from 'react';
import { PendingTaskReport } from './pendingTasks';
import { makeStyles,Typography } from '@material-ui/core';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useHistory } from "react-router-dom";
import { Routes } from '../../../../router/routes';
import { useDispatch } from "react-redux";
import { actions } from "primarycare-binder";
import { SwitchTab } from '../../components';
import { TopHeader } from '../../../../components';
import moment from 'moment';

const useStyles = makeStyles(()=>({
    root:{
    },
    content:{
        padding:20,
        height:'calc(100vh - 72.4px)',
        overflow:'auto'
    },
    header:{
        fontSize:'16px',
        color:'#101010',
        fontFamily:'pc_semibold',
    },
    rowCenter:{
        display:'flex',
        alignItems:'center'
    },
    iconWrapper:{
        backgroundColor:'#F0F0F0',
        width:26,
        height:26,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginRight:12,
        borderRadius:'50%',
        cursor:'pointer'
    },
    tabWrapper:{
        display:'flex',
        alignItems:'center',
        marginTop:20,
        '& $wrapper':{
            '&:nth-child(odd)':{
                '& $countWrapper':{
                    borderColor:'#FF6384',
                    color:'#FF6384'
                }
            },
            '&:nth-child(even)':{
                '& $countWrapper':{
                    borderColor:'#4BC0C0',
                    color:'#4BC0C0'
                }
            },
        }
    },
    wrapper:{
        border:'1px solid #E0E0E0',
        borderRadius:22,
        padding:8,
        display:'flex',
        alignItems:'center',
        fontSize:14,
        fontFamily:'pc_regular',
        marginRight:10,
        cursor:'pointer'
    },
    countWrapper:{
        width:29,
        height:29,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        border:'1px dashed',
        borderRadius:'50%',
        padding:5,
        fontSize:14,
        fontFamily:'pc_semibold',
    },
    tabTitle:{
        fontSize:14,
        fontFamily:'pc_regular',
        color:'#101010',
        flex:1,
        marginRight:10,
        paddingLeft:8,
    }
}));

export const PendingTasks = (props) =>{

    const classes = useStyles(props);
    const history = useHistory();
    const dispatch = useDispatch();


    const [state,setState] = React.useState({
        selectedTab:{},
        report:[],
    })

    React.useEffect(() => {
      if(props?.location?.state?.data){
          setState({
              ...state,
              selectedTab : props?.location?.state?.data?.index >= 0 ? props?.location?.state?.data?.tabData[props?.location?.state?.data?.index] : props?.location?.state?.data?.tabData[0]
            })
            getSelectedTab(props?.location?.state?.data?.tabData[props?.location?.state?.data?.index ?? 0]);
        }
      }, []);

    const navigateBack = () =>{
        history.push({
            pathname:Routes.dashboard
          })
    }

    const getNurseDetails = async (data) =>{
        let value;
        let payload = {
            orgID:props?.location?.state?.organizationID,
            practionerID:props?.location?.state?.practionerID,
            startDate:moment().startOf("day").unix(),
            endDate:moment().endOf("day").unix(),
        }

        switch(data.label){
            case 'Bill Collection':
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'Confirmed Referrals':
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'Follow-Up Appointments':
                    value = await dispatch(actions.FOLLOWUP_APPOINTMENTS(payload));
                break;
            case 'Appointment Referrals':
                    value = await dispatch(actions.APPOINTMENT_REFERRALS(payload));
                break;
            default:
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
        }
        return value
    }

    const getDoctorDetails = async (data) =>{
        let value;
        let payload = {
            startDate:moment().startOf("day").unix(),
            endDate:moment().endOf("day").unix(),
        }

        switch(data.label){
            case 'Bill Collection':
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'Confirmed Referrals':
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'Follow-Up Appointments':
                    value = await dispatch(actions.FOLLOWUP_APPOINTMENTS(payload));
                break;
            case 'Appointment Referrals':
                    value = await dispatch(actions.APPOINTMENT_REFERRALS(payload));
                break;
            default:
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
        }
        return value
    }

    const getFrontDeskDetails = async (data) =>{
        let value;
        let payload = {
            startDate:moment().startOf("day").unix(),
            endDate:moment().endOf("day").unix(),
        }

        switch(data.label){
            case 'Bill Collection':
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'Confirmed Referrals':
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
            case 'Follow-Up Appointments':
                    value = await dispatch(actions.FOLLOWUP_APPOINTMENTS(payload));
                break;
            case 'Appointment Referrals':
                    value = await dispatch(actions.APPOINTMENT_REFERRALS(payload));
                break;
            default:
                // value = await dispatch(actions.GET_APPOINTMENTS(payload));
                break;
        }
        return value
    }

    const getSelectedTab = async (data) =>{
        let value;

        if(localStorage.getItem('role_name') === 'nurse'){
            value = await getNurseDetails(data)
        }
        else if(localStorage.getItem('role_name') === 'doctor'){
            value = await getDoctorDetails(data)
        }
        else if(localStorage.getItem('role_name') === 'frontdesk'){
            value = await getFrontDeskDetails(data)
        }
        
        setState({
            ...state,
            report:value?.payload?.data,
            selectedTab:data
        })

    }

    return(
        <div className={classes.root}>
            <TopHeader
                parent_id={'Pending_Tasks_listing'}
                noHome={true} middleComponent={<></>} 
            />
            <div className={classes.content}>
                <div className={classes.rowCenter}>
                    <div className={classes.iconWrapper} onClick={navigateBack}>
                        <NavigateBeforeIcon />
                    </div>
                    <Typography variant="body1" className={classes.header}>{'Pending Tasks'}</Typography>
                </div>
                <SwitchTab 
                    options={props?.location?.state?.data?.tabData}
                    selectedTab={state.selectedTab}
                    getSelectedTab={getSelectedTab}
                />
                <div style={{paddingTop:12}}>
                    <PendingTaskReport 
                        report={state.report}
                    />
                </div>
            </div>
        </div>
    )
}