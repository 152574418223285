import React from "react";
import { Div, Paper, Checkbox, H6, Col, Row, Select, Text } from 'qdm-component-library'
import "./style.css"
import { withAllContexts } from "../../HOCs";
// import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UIColor } from "../../utils";
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
    chips: {
        "& .MuiChip-root":{
            backgroundColor: UIColor.lineBorderFill.color,
            borderRadius: 8,
        }
    }
});

class PatientCommunication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fixedOptions: []
        }
    }
    styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0'
        }
    }

    setDetails = (name, value) => {
        let { patientData } = this.props
        patientData.communication[name] = value;
        patientData.setPatientData({
            ...patientData,
        })
    }



    setDetailslanguage = (name, value) => {
        let { patientData } = this.props
        if (patientData.communication?.clanguage?.length > 0) {
            const seen = new Set();
            const filteredArr = value.filter(el => {
                const duplicate = seen.has(el.value);
                seen.add(el.value);
                return !duplicate;
            });

            patientData.communication[name] = filteredArr
            patientData.setPatientData({
                ...patientData,
            })
            return
        }

        patientData.communication[name] = value
        patientData.setPatientData({
            ...patientData,
        })

    }
    FlterOptions = (type) => {
        const { allMasters } = this.props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
                "label": data[index]?.coding?.display,
                "_id": data[index]?.coding?._id,
            };
            lista.push(datatemp);
        }
        return lista
    }

    render() {
        let { communication,statusDetails } = this.props.patientData
        const { labelStyle, borderStyle } = this.styles;
        const { parent_id } = this.props;
        const { classes } = this.props;

        return <Div
            id={`${parent_id}_communication_parent_div`}
            className="pi-root">
            <Paper
                id={`${parent_id}_communication_parent_paper`}
                className="pi-paper">
                <Div
                    id={`${parent_id}_communication_label_div`}
                >
                    <H6
                        id={`${parent_id}_communication_label_communication_h6`}
                        className="pi-title">COMMUNICATION</H6>
                </Div>
                <Row
                    id={`${parent_id}_communication_parent_row`}
                    className="pi-actions-root" style={{paddingTop: "0px"}}>
                    <Col
                        id={`${parent_id}_communication_language_col`}
                        md={6} lg={6} sm={6} xs={12} className="no-padding-left">
                        <Text
                            id={`${parent_id}_communication_language_label_text`}
                            style={{
                                color: '#6F6F6F',
                                fontSize: '12px',
                                marginBottom: '6px',
                                fontFamily: 'pc_regular'
                            }}>Communication Languages
                        </Text>
                        <Autocomplete
                            id={`${parent_id}_communication_language_autocomplete`}
                            multiple
                            fullWidth
                            size='small'
                            getOptionLabel={(option) => option.label}
                            value={communication?.clanguage ?? []}
                            onChange={(e, v) => this.setDetailslanguage("clanguage", v)}
                            options={this.FlterOptions('LANGUAGE')}
                            className={classes.chips}
                            disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                            renderInput={(params) => (
                                <TextField
                                    id={`${parent_id}_communication_select_label_textfield`}
                                    style={{ height: "20px" }}
                                    {...params} label={false}
                                    variant="outlined" placeholder="select..." size='small' />
                            )}
                        />
                    </Col>
                    <Col
                        id={`${parent_id}_communication_preferred_col`}
                        md={3} lg={3} sm={6} xs={12} className="no-padding-left">
                        <Select
                            id={`${parent_id}_communication_preferred_select`}
                            options={communication?.clanguage ?? []}
                            disabled={!communication?.clanguage || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased")}
                            value={communication?.planguage?.value ?? null}
                            onChangeValue={(e) => this.setDetails("planguage", e)}
                            showArrow label="Preferred Language"
                            placeholder="select..." labelStyle={labelStyle} inLineStyles={borderStyle} hoverColor={UIColor.primaryColor.color} />

                    </Col>
                    <Col
                        id={`${parent_id}_communication_interpreter_col`}
                        md={3} lg={3} sm={6} xs={12} className="no-padding-left">
                        <H6
                            id={`${parent_id}_communication_interpreter_label_h6`}
                            className="default-size pc_regular">Interpreter Required</H6>
                        <Checkbox
                            style={{marginTop: "2%"}}
                            id={`${parent_id}_communication_interpreter_checkbox`}
                            disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                            name='' checked={communication?.isinterpreter} onChange={(e) => this.setDetails("isinterpreter", !(communication?.isinterpreter))} color={UIColor.secondaryButtonColor.color} />
                    </Col>
                </Row>
            </Paper>
        </Div>

    }
}
export default withStyles(styles)(withAllContexts(PatientCommunication));

// export default withAllContexts(PatientCommunication);