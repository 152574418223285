import React from 'react'
import { actions } from 'primarycare-binder'
import { connect } from 'react-redux'
import qdmstyles from './styles1.json'
import { withRouter } from 'react-router-dom'
import { AlertContext } from '../../contexts'
import {
  Container,
  Div,
  H6,
  Divider,
  Upload,
  TextInput,
  TapSelect,
  Text,
  // DateTimepicker,
  MobileNumberWithCode,
  Button,
  TermsAndConditions,
  MonthCalendar,
  Popover,
  Icons
} from 'qdm-component-library';
import { checkError, AlertProps, ValidateEmail, getMobileNumber, getUserInfo, upsertPerson, UIColor, mobileNumberValidation } from "../../utils";
import moment from "moment"

class AppointmentDrawerSigningPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      middleName: '',
      gender: '',
      dob: {
        val: moment().format('DD-MM-YYYY'),
        milli: moment().valueOf()
      },
      relation: {},
      emailId: '',
      mobileNo: {},
      opencalendar: false,
      loadingReadPerson: false
    }
  }

  changeState = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  componentDidMount = async () => {
    await this.props.GET_COUNTRY_CODE_LIST()
    if (this.props.signUpData) {
      const data = this.props.signUpData
      await this.setState({
        mobileNo: this.props.signUpData.mobileNo,
        firstName: data?.firstName,
        lastName: data?.lastName,
        middleName: data?.middleName,
        gender: data?.gender,
        dob: {
          val: this.props.signUpData.dob,
          milli: moment().valueOf()
        },
        emailId: data?.emailId
      })
    }
  }

  shouldComponentUpdate(props, state) {
    return true
  }

  handleSignUp = async () => {
    if (
      // this.state?.mobileNo?.mobileNo?.length > 9 &&
      // this.state?.mobileNo?.countryData?.callingCodes &&
      this.state?.firstName &&
      this.state?.gender &&
      // this.state?.lastName &&
      (this.state.emailId ? ValidateEmail(this.state?.emailId) : true)
    ) {
      let res__ = mobileNumberValidation(this.state?.mobileNo?.mobileNo, this.state?.mobileNo?.countryData?.callingCodes);
			debugger
			if (res__ === "Invalid Country code") {
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: res__,
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
				return
			} else if (res__ === false) {
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please enter valid mobile number",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
				return
			}

      const {
        firstName,
        lastName,
        middleName,
        dob,
        gender,
        emailId,
        mobileNo
      } = this.state
      if (!this.props.addNewMember) {
        await this.props.REGISTER_USERS({
          mobileNo: `+${this.state.mobileNo?.countryData?.callingCodes ??
            '00'}${this.state.mobileNo.mobileNo}`,
          firstName,
          lastName,
          emailId
        })
        const status = checkError(this.props.registerUsers)
        if (status.isError) {
          this.setState({
            error: status.errorMessage
          })
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: status.errMsg,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true
          })
        } else {
          const signUpData = {
            mobileNo,
            firstName,
            lastName,
            middleName,
            dob: dob.val,
            gender,
            emailId
          }
          this.props.nextSection('addmemberotp', signUpData)
        }
      } else {
        const guestBookerAdd = {
          phone: getMobileNumber(this.state.mobileNo),
          dob: dob.val,
          firstName,
          lastName,
          gender,
          email: this.state.emailId,
        }
        const {
          data: res,
          error,
        } = await upsertPerson(this.props.READ_PERSON, this.props.UPSERT_PERSON, guestBookerAdd);
        if (error) {
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true
          })
        } else {
          let personId = isNaN(Number(localStorage.getItem("idval")))
            ? 0
            : Number(localStorage.getItem("idval"));
          if (!personId) {
            const userInfo = getUserInfo();
            let mobileNo = userInfo.mobile;
            const persondata = await this.props.READ_PERSON({
              phone: mobileNo,
            });
            personId = persondata?.payload?.data?.id;
          }
          const relatedPersonInput = {
            ...guestBookerAdd,
            relationType: this.state.relation || "",
            relationPersonId: [personId || 0],
            personId: res.payload?.data?.id || 0,
          };
          await this.props.ADD_RELATED_PERSON(relatedPersonInput);
          this.context.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Member Added Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true
          })
          if (this.props.changeSideBar) {
            this.props.changeSideBar();
          }
          // const signUpData = {
          //   mobileNo,
          //   firstName,
          //   lastName,
          //   middleName,
          //   dob: dob.val,
          //   gender,
          //   emailId
          // }
          // this.props.nextSection('addmemberotp', signUpData)
          // alert('User Added')
        }
      }
    } else {
      this.setState({
        ...this.state,
        error: {
          mobileNo: this.state?.mobileNo?.mobileNo?.length > 9 ? false : true,
          callingCodes: this.state?.mobileNo?.countryData?.callingCodes
            ? false
            : true,
          firstName: this.state?.firstName?.length > 0 ? false : true,
          gender: this.state?.gender ? false : true,
          lastName: false,
          emailId: this.state?.emailId ? ValidateEmail(this.state?.emailId) ? false : true : false
        }
      })
      this.context.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: 'Please fill all the mandatory fields',
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true
      })
    }
  }
  handlepapover = e => {
    if (this.state.opencalendar) {
      this.handlepapoverclose()
    } else {
      this.setState({ opencalendar: e.currentTarget })
    }
  }
  handlepapoverclose = () => {
    this.setState({ opencalendar: null })
  }
  render() {
    const { addNewMember, parent_id } = this.props
    return (
      <div
        id={`${parent_id}_addmember_form_parent_div`}
      >
        <Container
          id={`${parent_id}_addmember_form_parent_container`}
          key={'0'}
          name={'container'}
          fluid={true}
          style={qdmstyles.Bc}
        >
          <Div
            id={`${parent_id}_addmember_form_title_div`}
            key={'0'} className={'qdm'} style={qdmstyles.de_da__c_cd}>
            <Div
              id={`${parent_id}_addmember_form_signup_div`}
              key={'0'}>
              <H6
                id={`${parent_id}_addmember_form_signup_h6`}
                key={'0'}
                name={addNewMember ? 'Add Member' : 'Sign Up as New Member'}
                style={qdmstyles.a_c_c_aed_fbeabbf}
              ></H6>
            </Div>

            <Div
              id={`${parent_id}_addmember_form_back_div`}
              key={'1'}>
              <Button
                id={`${parent_id}_addmember_form_back_buttom`}
                key={'0'}
                name={'Back'}
                style={qdmstyles.b__ae_ba_fcfdfc}
                onClick={() => this.props.nextSection('back')}
              ></Button>
            </Div>
          </Div>

          <Divider
            id={`${parent_id}_addmember_form_bottom_divider`}
            key={'1'}></Divider>

          <Div
            id={`${parent_id}_addmember_form_upload_div`}
            style={{
              background: '#E0E0E0',
              width: '80px',
              height: '80px',
              borderRadius: '50%',
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Upload
              id={`${parent_id}_addmember_form_image_upload`}
              key={'2'}
              label={'Img'}
              variant={'classic'}
              showIcon={false}
              labelSize={'small'}
              labelColor={'#000'}
              iconColor={'#000'}
              iconSize={'16'}
              inLineStyles={qdmstyles.zRf}
            ></Upload>
            <div
              id={`${parent_id}_addmember_form_icon_div`}
              style={{
                position: 'absolute',
                bottom: '-1px',
                right: '11px',
                padding: '3px 7px',
                background: '#fff',
                borderRadius: '50%',
                width: '28px',
                height: '28px'
              }}
            >
              <Icons
                id={`${parent_id}_addmember_form_camera_icons`}
                key={'0'}
                fontIcon='camera'
                size='small'
                style={{ backgroundColor: '#fff', color: '#6F6F6F' }}
              ></Icons>
            </div>
          </Div>

          <Div
            id={`${parent_id}_addmember_form_names_div`}
            key={'3'} className={'qdm'} style={qdmstyles.aa_cbc__a_eddc}>
            <TextInput
              id={`${parent_id}_addmember_form_first_label_textinput`}
              key={'0'}
              type={'text'}
              name={'text'}
              variant={'outlined'}
              helperTextType={'error'}
              maxLength={20}
              label={
                <div
                  id={`${parent_id}_addmember_form_first_label_div`}
                  style={{ fontSize: '13px', marginBottom: '-5px' }}>
                  First name <span
                    id={`${parent_id}_addmember_form_first_star_span`}
                    style={{ color: 'red' }}>*</span>
                </div>
              }
              autoFocus={false}
              autoComplete={'off'}
              search={false}
              borderColor={'#9b9b9b'}
              elevation={'0'}
              placeholder={'Sudharsan'}
              size={'medium'}
              value={this.state.firstName}
              onChange={e => this.changeState('firstName', e.target.value)}
              style={qdmstyles.vQU}
              error={this.state?.error?.firstName}
            ></TextInput>

            <TextInput
              id={`${parent_id}_addmember_form_middle_label_textinput`}
              key={'1'}
              type={'text'}
              name={'text'}
              variant={'outlined'}
              maxLength={20}
              helperTextType={'error'}
              label={
                <div
                  id={`${parent_id}_addmember_form_middle_label_div`}
                  style={{ fontSize: '13px', marginBottom: '-5px' }}>
                  Middle name
                </div>
              }
              autoFocus={false}
              autoComplete={'off'}
              search={false}
              borderColor={'#9b9b9b'}
              elevation={'0'}
              placeholder={'Narasimhan'}
              size={'medium'}
              value={this.state.middleName}
              onChange={e => this.changeState('middleName', e.target.value)}
              style={qdmstyles.BAIIv}
            ></TextInput>

            <TextInput
              id={`${parent_id}_addmember_form_last_label_textinput`}
              key={'2'}
              type={'text'}
              name={'text'}
              maxLength={20}
              variant={'outlined'}
              helperTextType={'error'}
              label={
                <div
                  id={`${parent_id}_addmember_form_last_label_div`}
                  style={{ fontSize: '13px', marginBottom: '-5px' }}>
                  Last name
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </div>
              }
              autoFocus={false}
              autoComplete={'off'}
              search={false}
              borderColor={'#9b9b9b'}
              elevation={'0'}
              placeholder={'Pv'}
              size={'medium'}
              value={this.state.lastName}
              onChange={e => this.changeState('lastName', e.target.value)}
              style={qdmstyles.pyYgb}
              error={this.state?.error?.lastName}
            ></TextInput>
          </Div>
          <div
            id={`${parent_id}_addmember_form_gender_label_div`}
            style={qdmstyles.dda_e_dc_a_afcccbd}>
            Gender <span
              id={`${parent_id}_addmember_form_gender_start_span`}
              style={{ color: 'red' }}>*</span>
          </div>
          <TapSelect
            id={`${parent_id}_addmember_form_gender_tapselect`}
            key={'4'}
            fullWidth={false}
            label={false}
            selectedBorderColor='#0071F2'
            selectedBackgroundColor='#0071F2'
            selectedTextColor='#fff'
            textSize='16'
            iconHeight='20'
            iconWidth='20'
            textColor='#000'
            options={[
              { value: 'Male', label: 'Male' },
              { value: 'Female', label: 'Female' },
              { value: 'Others', label: 'Others' }
            ]}
            value={{ value: this.state.gender, label: this.state.gender }}
            onChange={e => this.changeState('gender', e.value)}
            style={{ marginTop: '', paddingTop: '' }}
            defaultSelected={{
              value: this.state.gender,
              label: this.state.gender
            }}
            inLineStyles={{
              borderColor: '#E0E0E0',
              fontSize: '14px',
              fontFamily: "'pc_regular' !important"
            }}
          // style={qdmstyles.SREcn}
          ></TapSelect>
          {this.state.error?.gender && (
            <div
              id={`${parent_id}_addmember_form_pleaseselect_label_div`}
            >
              <Text
                id={`${parent_id}_addmember_form_pleaseselect_label_text`}
                style={{ color: 'red', fontSize: 14 }}>
                {'Please select the gender'}
              </Text>
            </div>
          )}
          {/* <Text
            key={'5'}
            name={
              <div style={{ fontSize: '13px',marginBottom:"-6px" }}>
                DOB <span style={{ color: 'red' }}>*</span>
              </div>
            }
            style={qdmstyles.dda_e_dc_a_afcccbd}
          ></Text>

          <DateTimepicker
            key={'6'}
            type={'date'}
            placeholder={'please select'}
            style={qdmstyles.usYVQ}
            value={this.state.dob}
            onChangedate={e => this.changeState('dob', e.target.value)}
          ></DateTimepicker> */}
          <Text
            id={`${parent_id}_addmember_form_dob_label_text`}
            style={{ fontSize: '12px', color: '#6f6f6f' }}>
            DOB <span style={{ color: 'red' }}>*</span>
          </Text>
          <Div
            id={`${parent_id}_addmember_form_calender_div`}
            style={{
              border: '1px solid #E0E0E0',
              padding: '10px',
              height: '40px',
              width: '100%',
              fontSize: '14px',
              borderRadius: '4px',
              background: 'transparent',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              marginBottom: '8px'
            }}
          >
            <Text
              id={`${parent_id}_addmember_form_calender_val_text`}
              style={{ color: '#000' }}>{this.state.dob.val}</Text>
            <img
              id={`${parent_id}_addmember_form_calender_image`}
              alt='loding'
              src='/images/icons8-calendar.svg'
              onClick={e => this.handlepapover(e)}
              style={{
                backgroundColor: '',
                color: '',
                cursor: 'pointer',
                borderLeft: '1px solid #E0E0E0',
                paddingLeft: '10px'
              }}
            />
            <Popover
              style={{
                transform: 'unset',
                position: 'absolute',
                width: '320px',
                top: '30%',
                zIndex: '10'
              }}
              open={this.state.opencalendar}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              onClose={() => this.handlepapoverclose()}
            >
              <MonthCalendar
                id={`${parent_id}_addmember_form_calender_mothcalender`}
                style={{ color: '#000' }}
                onChange={e => {
                  const milli = e.getTime()
                  const oldVal = this.state.dob.milli
                  const oldDate = moment(oldVal).format('DD')
                  const newDate = moment(milli).format('DD')
                  const date = {
                    val: moment(e).format('DD-MM-YYYY'),
                    milli
                  }
                  this.changeState('dob', date)
                  if (oldDate !== newDate || milli === oldVal) {
                    this.handlepapoverclose()
                  }
                }}
              />
            </Popover>
          </Div>
          <Text
            id={`${parent_id}_addmember_form_mobile_number_text`}
            key={'7'}
            name={
              <span
                id={`${parent_id}_addmember_form_mobile_number_span`}
                style={{ fontSize: '13px' }}>
                Enter your Mobile number <span
                  id={`${parent_id}_addmember_form_mobile_star_span`}
                  style={{ color: 'red' }}>*</span>
              </span>
            }
            style={qdmstyles.dda_e_dc_a_afcccbd}
          ></Text>

          <MobileNumberWithCode
            id={`${parent_id}_addmember_form_mobilenumberwithcode`}
            key={'8'}
            type={'number'}
            label={'Mobile Number'}
            borderColor={'#CACACA'}
            maxLength={30}
            borderRadius={'6'}
            disabled={false}
            size={'medium'}
            style={qdmstyles.a__ef__bad}
            value={this.state.mobileNo.mobileNo}
            defaultValue={this.state.mobileNo.mobileNo}
            dropdown={this.state.mobileNo.countryData}
            // dropdownData={[
            //   {
            //     name: 'India',
            //     flag:
            //       'https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg',
            //     altSpellings: 'IND',
            //     callingCodes: '91'
            //   }
            // ]}
            dropdownData={this?.props?.CountryCode?.data}
            onChange={e => this.changeState('mobileNo', e)}
            error={
              this.state.error?.mobileNo || this.state.error?.countryData
                ? true
                : false
            }
          ></MobileNumberWithCode>
          {(this.state.error?.mobileNo || this.state.error?.callingCodes) && (
            <>
              {this.state.error?.mobileNo && (
                <Text
                  id={`${parent_id}_addmember_form_enter_the_mobile_text`}
                  style={{ color: 'red', fontSize: 14 }}>
                  {'Please enter the mobile number'}
                </Text>
              )}
              {this.state.error?.callingCodes &&
                !this.state.error?.mobileNo && (
                  <Text
                    id={`${parent_id}_addmember_form_countrycode_text`}
                    style={{ color: 'red', fontSize: 14 }}>
                    {'Please select the country code'}
                  </Text>
                )}
            </>
          )}
          <TermsAndConditions
            id={`${parent_id}_addmember_form_send_appointmnet_terms`}
            value={true}
            style={{ fontSize: '1px' }}
            checkboxTickColor={"#fff"}
            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
            checkboxBorderColor={UIColor.lineBorderFill.color}
            checkboxBackgroundColor={UIColor.differentiationBackground.color}
            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
            disabled
            lable={
              <span
                id={`${parent_id}_addmember_form_send_appointmnet_span`}
                style={{ fontSize: '12px' }}>
                Send appointment update and notification to this Mobile number
              </span>
            }
          />
          <Text
            id={`${parent_id}_addmember_form_mail_id_text`}
            key={'13'}
            // name={'Mail Id (optional)'}
            name={
              <span
                id={`${parent_id}_addmember_form_mail_id_span`}
                style={{ fontSize: '13px' }}>
                Mail Id
                {/* <span style={{ color: 'red' }}>*</span> */}
              </span>
            }
            style={qdmstyles.dda_e_dc_a_afcccbd}
          ></Text>
          <TextInput
            id={`${parent_id}_addmember_form_mail_id_textinput`}
            key={'9'}
            type={'text'}
            name={''}
            variant={'outlined'}
            helperTextType={'error'}
            label={''}
            autoFocus={false}
            autoComplete={'off'}
            search={false}
            borderColor={'#9b9b9b'}
            elevation={'0'}
            placeholder={'example@gmail.com'}
            size={'medium'}
            style={qdmstyles.ed_db_d_f_e}
            value={this.state.emailId}
            onChange={e => this.changeState('emailId', e.target.value)}
            error={this.state?.error?.emailId}
          ></TextInput>
          {this.state.emailId && this.state.error?.emailId && (
            <div>
              <Text
                id={`${parent_id}_addmember_form_mail_id_error_text`}
                style={{ color: 'red', fontSize: 14 }}>
                {'Please Enter valid Email Id'}
              </Text>
            </div>
          )}
          <TermsAndConditions
            id={`${parent_id}_addmember_form_appointment_mail_id_terms`}
            inLineStyles={{
              fontFamily: "'pc_light' !important",
              maginBottom: '10px'
            }}
            checkboxTickColor={"#fff"}
            activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
            checkboxBorderColor={UIColor.lineBorderFill.color}
            checkboxBackgroundColor={UIColor.differentiationBackground.color}
            activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
            lable={
              <span
                id={`${parent_id}_addmember_form_appointment_mail_id_span`}
                style={{ fontSize: '12px' }}>
                Send appointment update and notification to this mail id
              </span>
            }
          />
          {addNewMember && (
            <>
              <br />{' '}
              <Text
                id={`${parent_id}_addmember_form_relationship_text`}
                key={'10'}
                name={
                  <span
                    id={`${parent_id}_addmember_form_relationship_span`}
                  >
                    What's your relationship with Sudharsan Narasimhan ?{' '}
                    <span
                      id={`${parent_id}_addmember_form_relationship_star_span`}
                      style={{ color: 'red' }}>*</span>
                  </span>
                }
                style={qdmstyles.efc_d__a_eea}
              ></Text>
              <TapSelect
                id={`${parent_id}_addmember_form_relationship_tapselect`}
                key={'11'}
                label={false}
                fullWidth={false}
                selectedBorderColor='#1890ff'
                selectedBackgroundColor='#1890ff'
                selectedTextColor='#fff'
                textSize='16'
                iconHeight='20'
                iconWidth='20'
                textColor='#000'
                title={false}
                name={false}
                inLineStyles={{
                  borderColor: '#E0E0E0',
                  fontSize: '14px',
                  fontFamily: "'pc_regular' !important"
                }}
                options={[
                  { value: 'Father', label: 'Father' },
                  { value: 'Mother', label: 'Mother' },
                  { value: 'Brother', label: 'Brother' },
                  { value: 'Sister', label: 'Sister' },
                  { value: 'Others', label: 'Others' }
                ]}
                value={this.state.relation}
                onChange={e => this.changeState('relation', e.value)}
                iconPosition={'left'}
              ></TapSelect>{' '}
            </>
          )}

          <Button
            id={`${parent_id}_addmember_form_Send_OTP_Sign_Up_buttom`}
            key={'12'}
            type={'button'}
            disabled={this.props?.registerUsers?.loading}
            variant={'outlined'}
            name={
              this.props?.registerUsers?.loading
                ? 'Signing Up...'
                : addNewMember
                  ? (
                    this.props?.addMember?.loading || this.props?.readPerson?.loading
                      ? 'Adding...'
                      : 'Add Member'
                  )
                  : (this.state.loadingReadPerson)
                    ? 'Signing Up...'
                    : 'Send OTP & Sign Up'
            }
            style={qdmstyles.ee_ce_fe_aa_ecdaf}
            onClick={this.handleSignUp}
          ></Button>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  registerUsers: state?.authSlice?.registerUsers,
  addMember: state?.personApiSlice?.upsertPerson,
  readPerson: state?.personApiSlice?.readPerson,
  CountryCode: state?.authSlice?.country_code_list
})

AppointmentDrawerSigningPage.contextType = AlertContext

export default connect(
  mapStateToProps,
  actions
)(withRouter(AppointmentDrawerSigningPage))
