import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Avatar,
  Div,
  Icons,
  Text,
  Divider,
  Button,
  BackgroundImage,
  Paper,
  H6,
  Tab,
  Tabpanel,
  TextInput,
  TapSelect,
  Link,
  Popover,
  MonthCalendar,
  SideMenu,
} from "qdm-component-library";
import {
  Addmember,
  Addmemberform,
  Addmemberotp,
  // AppointmentBooked,
} from "../../components";
import { Routes } from "../../router/routes";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      name: "",
      age: "2021-05-20",
      gender: "",
      opencalendar: null,
      sidebar: false,
      searchMenber: true,
      memberForm: false,
      memberOtp: false,
      chooseSearch: null,
    };
  }

  handleContinue = async () => {
    await this.props.GUEST_INFO?.(this.state);
    this.props.history.push(Routes.doctorConsultation);
  };

  handelChangeSelect = (v) => {
    this.setState({
      chooseSearch: v,
    });
  };
  changeState = (key, value) => {
    console.log(value);
    this.setState({
      [key]: value,
    });
  };

  componentDidMount() {}

  shouldComponentUpdate(props, state) {
    return true;
  }
  handlepapover = (e) => {
    if (this.state.opencalendar) {
      this.handlepapoverclose();
    } else {
      this.setState({ opencalendar: e.currentTarget });
    }
  };
  handlepapoverclose = () => {
    this.setState({ opencalendar: null });
  };
  toggleSideBar = (boolean) => {
    this.setState({
      ...this.state,
      sidebar: boolean,
      memberForm: false,
      searchMenber: true,
    });
  };
  nextSection = (member) => {
    if (member === "addmemberform") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: true,
        memberOtp: false,
      });
    } else if (member === "addmemberotp") {
      this.setState({
        ...this.state,
        searchMenber: false,
        memberForm: false,
        memberOtp: true,
      });
    } else if (member === "back") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
      });
    } else if (member === "movenextscreen") {
      this.setState({
        ...this.state,
        searchMenber: true,
        memberForm: false,
        memberOtp: false,
        sidebar: false,
      });
      window.location.href = "/appointments_listing_page";
    }
  };
  render() {
    return (
      <div>
        <Container
          key={"0"}
          name="container"
          fluid={true}
          style={{
            backgroundColor: "#e8e8e8",
            height: "100vh",
            padding: "0px",
          }}
        >
          <Row
            key={"0"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{ backgroundColor: "#ffffff", height: "" }}
          >
            <Col
              key={"0"}
              name="qdm-col-12"
              alignItems="stretch"
              direction="row"
              justify="flex-start"
              textAlign="left"
              lg="12"
              xs="12"
              md="12"
              sm="12"
              style={{
                height: "55px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Avatar
                key={"0"}
                variant="rounded"
                letter="Avatar"
                src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c5/Apollo_Hospitals_Logo.svg/800px-Apollo_Hospitals_Logo.svg.png"
                alt="Image"
                style={{ backgroundColor: "#ffffff", padding: "8px" }}
              ></Avatar>

              <Div
                key={"1"}
                inLineStyles="[object Object]"
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Icons
                  key={"0"}
                  fontIcon="phone"
                  ariaHidden="true"
                  className=""
                  size="small"
                  style={{ backgroundColor: "", color: "" }}
                ></Icons>

                <Text
                  key={"1"}
                  name="+91 9821315141 , +91 9551482612"
                  style={{ marginLeft: "8px", marginRight: "" }}
                ></Text>

                <Divider
                  key={"2"}
                  id=""
                  className=""
                  label=""
                  borderColor=""
                  textColor=""
                  orientation="vertical"
                  variant="middle"
                  style={{ height: "24px" }}
                ></Divider>

                <Button
                  key={"3"}
                  type="button"
                  variant="text"
                  name="Support"
                  style={{
                    borderColor: "#0071F2",
                    borderRadius: "8px",
                    color: "#0071F2",
                    height: "36px",
                    lineHeight: 0,
                  }}
                ></Button>
              </Div>
            </Col>
          </Row>

          <Row
            key={"1"}
            alignItems="stretch"
            direction="row"
            justify="flex-start"
            spacing="2"
            style={{
              height: "",
              backgroundColor: "#F0F7FF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // paddingBottom: '24px',
              // paddingTop: '24px'
            }}
          >
            <BackgroundImage
              key={"0"}
              url="images/login_bg.svg"
              alt="Mask-Group-71-2x"
              backgroundPosition="0% 0%"
              backgroundPositionX="center"
              backgroundPositionY="top"
              backgroundRepeat="no-repeat"
              backgroundRepeatX="no-repeat"
              backgroundRepeatY="no-repeat"
              backgroundAttachment="scroll"
              backgroundSize="contain"
              backgroundOrigin="padding-box"
              inLineStyles="[object Object]"
              style={{
                height: "",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Paper
                key={"0"}
                children="Paper"
                variant="elevation"
                elevation="1"
                style={{
                  width: "350px",
                  height: "",
                  backgroundColor: "#ffffff",
                  padding: "24px 32px 24px 32px",
                  borderRadius: "16px",
                  marginBottom: "24px",
                  marginTop: "24px",
                }}
              >
                <H6
                  key={"0"}
                  name="Welcome !"
                  style={{ color: "#101010" }}
                  inLineStyles={{
                    fontFamily: "'pc_semibold' !important",
                    fontSize: "24px",
                  }}
                ></H6>

                <Text
                  name="Share your basic info for personalized experience."
                  style={{ fontSize: "11px", color: "#6F6F6F" }}
                ></Text>

                <H6
                  key={"2"}
                  name="Booking for?"
                  style={{
                    fontSize: "12px",
                    color: "#6F6F6F",
                    marginTop: "16px",
                  }}
                ></H6>

                <Tab
                  key={"3"}
                  align="center"
                  activetabs={0}
                  id=""
                  name=""
                  inLineStyles="[object Object]"
                  className=""
                  label=""
                  inLineTabTitleStyles="[object Object]"
                  fullWidth={true}
                  size="small"
                  backgroundColor=""
                  elevation="0"
                  border={false}
                  variant="bordered"
                  activeColor={"#0071f2"}
                  style={{
                    marginTop: "2px",
                    padding: "0px",
                    borderRadius: "8px",
                  }}
                  onChange={(e) => this.changeState("type", e)}
                >
                  <Tabpanel
                    key={"0"}
                    label="Myself"
                    title="Myself"
                    color="#101010"
                    subTitle=""
                    style={{
                      height: "",
                      padding: "0px",
                      paddingLeft: "",
                      paddingTop: "10px",
                    }}
                    inLineStyles={{
                      marginLeft: "-6px",
                      borderRadius: "8px",
                      fontFamily: "'pc_medium' !important",
                      color: "#101010",
                    }}
                  >
                    <TextInput
                      key={"0"}
                      placeholder=""
                      value={this.state.name}
                      onChange={(e) => this.changeState("name", e.target.value)}
                      type="text"
                      name="text"
                      variant="outlined"
                      helperTextType="error"
                      label={
                        <span style={{ fontSize: "12px", color: "#6f6f6f" }}>
                          Your Name <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      autoFocus={false}
                      autoComplete="off"
                      search={false}
                      borderColor="#E0E0E0"
                      padding="10"
                      margin="0"
                      elevation="0"
                      style={{ marginBottom: "16px" }}
                    ></TextInput>
                    <Text style={{ fontSize: "12px", color: "#6f6f6f" }}>
                      DOB <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Div
                      style={{
                        border: "1px solid #E0E0E0",
                        padding: "10px",
                        height: "40px",
                        width: "100%",
                        fontSize: "14px",
                        borderRadius: "4px",
                        background: "transparent",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        marginBottom: "26px",
                      }}
                    >
                      <Text>03 Month</Text>
                      <img
                        alt="loding"
                        src="/images/icons8-calendar.svg"
                        onClick={(e) => this.handlepapover(e)}
                        style={{
                          backgroundColor: "",
                          color: "",
                          cursor: "pointer",
                          borderLeft: "1px solid #E0E0E0",
                          paddingLeft: "10px",
                        }}
                      />
                      {/* <Icons
                        key={'0'}
                        fontIcon='calendar'
                        ariaHidden='true'
                        className=''
                        size='small'
                        style={{
                          backgroundColor: '',
                          color: '',
                          cursor: 'pointer',
                          borderLeft: '1px solid #E0E0E0',
                          paddingLeft: '10px'
                        }}
                        onClick={e => this.handlepapover(e)}
                      ></Icons> */}
                      <Popover
                        style={{
                          transform: "unset",
                          position: "absolute",
                          width: "320px",
                          top: "30%",
                          zIndex: "10",
                        }}
                        open={this.state.opencalendar}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        onClose={() => this.handlepapoverclose()}
                      >
                        <MonthCalendar />
                      </Popover>
                    </Div>

                    <TapSelect
                      key={"2"}
                      type="single"
                      fullWidth={false}
                      selectedBorderColor="#0071f2"
                      selectedBackgroundColor="#0071f2"
                      selectedTextColor="#fff"
                      textSize="16"
                      iconHeight="20"
                      iconWidth="20"
                      textColor="#101010"
                      label={
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#6f6f6f",
                            position: "absolute",
                            marginBottom: "4px",
                            marginTop: "-10px",
                          }}
                        >
                          Gender <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      options={genderOptions}
                      style={{ marginTop: "", paddingTop: "" }}
                      defaultSelected={this.state.gender}
                      onChange={(e) => this.changeState("gender", e)}
                      inLineStyles={{
                        padding: "8px 0px 8px 0px",
                        borderColor: "#E0E0E0",
                        fontSize: "14px",
                        fontFamily: "'pc_regular' !important",
                      }}
                    ></TapSelect>
                  </Tabpanel>

                  <Tabpanel
                    key={"1"}
                    label="Others"
                    title="Others"
                    color={"#0071f2"}
                    style={{
                      paddingTop: "10px",
                      marginLeft: "-6px",
                      borderRadius: "8px",
                      fontFamily: "'pc_medium' !important",
                      color: "#101010",
                    }}
                  >
                    <TextInput
                      key={"0"}
                      style={{ marginBottom: "16px" }}
                      value={this.state.name}
                      onChange={(e) => this.changeState("name", e.target.value)}
                      placeholder=""
                      type="text"
                      name="text"
                      variant="outlined"
                      helperTextType="error"
                      label={
                        <span style={{ fontSize: "12px", color: "#6f6f6f" }}>
                          Patient Name <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      autoFocus={false}
                      autoComplete="off"
                      search={false}
                      borderColor="#E0E0E0"
                      padding="10"
                      margin="0"
                      elevation="0"
                    ></TextInput>

                    <Text style={{ fontSize: "12px", color: "#6f6f6f" }}>
                      DOB <span style={{ color: "red" }}>*</span>
                    </Text>
                    <Div
                      style={{
                        border: "1px solid #E0E0E0",
                        padding: "10px",
                        height: "40px",
                        width: "100%",
                        fontSize: "14px",
                        borderRadius: "4px",
                        background: "transparent",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        marginBottom: "26px",
                      }}
                    >
                      <Text>03 Month</Text>
                      <img
                        alt="loding"
                        src="/images/icons8-calendar.svg"
                        onClick={(e) => this.handlepapover(e)}
                        style={{
                          backgroundColor: "",
                          color: "",
                          cursor: "pointer",
                          borderLeft: "1px solid #E0E0E0",
                          paddingLeft: "10px",
                        }}
                      />
                      {/* <Icons
                        key={'0'}
                        fontIcon='calendar'
                        ariaHidden='true'
                        className=''
                        size='small'
                        style={{
                          backgroundColor: '',
                          color: '',
                          cursor: 'pointer',
                          borderLeft: '1px solid #E0E0E0',
                          paddingLeft: '10px'
                        }}
                        onClick={e => this.handlepapover(e)}
                      ></Icons> */}
                      <Popover
                        style={{
                          transform: "unset",
                          position: "absolute",
                          width: "320px",
                          zIndex: "10",
                          top: "30%",
                        }}
                        open={this.state.opencalendar}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        onClose={() => this.handlepapoverclose()}
                      >
                        <MonthCalendar />
                      </Popover>
                    </Div>

                    <TapSelect
                      key={"2"}
                      type="single"
                      fullWidth={false}
                      selectedBorderColor="#0071f2"
                      selectedBackgroundColor="#0071f2"
                      selectedTextColor="#fff"
                      textSize="16"
                      iconHeight="20"
                      iconWidth="20"
                      textColor="#000"
                      label={
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#6f6f6f",
                            position: "absolute",
                            marginBottom: "4px",
                            marginTop: "-10px",
                          }}
                        >
                          Gender <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      options={genderOptions}
                      defaultSelected={this.state.gender}
                      onChange={(e) => this.changeState("gender", e)}
                      style={{ marginTop: "", paddingTop: "" }}
                      inLineStyles={{
                        padding: "8px 0px 8px 0px",
                        borderColor: "#E0E0E0",
                        fontSize: "14px",
                        fontFamily: "'pc_regular' !important",
                      }}
                    ></TapSelect>
                  </Tabpanel>
                </Tab>

                <Button
                  key={"4"}
                  onClick={this.handleContinue}
                  type="button"
                  variant="outlined"
                  name="Continue as Guest"
                  style={{
                    width: "100%",
                    marginTop: "16px",
                    borderRadius: "8px",
                    backgroundColor: "#0071F2",
                    borderColor: "#0071F2",
                    fontSize: "14px",
                    fontWeight: "600",
                    height: "40px",
                    letterSpacing: ".9px",
                  }}
                ></Button>

                <Div
                  key={"5"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "16px",
                  }}
                >
                  <Divider
                    key={"0"}
                    id=""
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  ></Divider>

                  <Text
                    key={"1"}
                    name="Or"
                    style={{
                      color: "#6F6F6F",
                      width: "28px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      backgroundColor: "#ffffff",
                    }}
                  ></Text>

                  <Divider
                    key={"2"}
                    id=""
                    className=""
                    label=""
                    borderColor=""
                    textColor=""
                    orientation="horizontal"
                    variant="fullWidth"
                    style={{ width: "130px" }}
                  ></Divider>
                </Div>

                <Button
                  key={"6"}
                  onClick={() => {
                    window.location.href = "/signin";
                  }}
                  type="button"
                  variant="text"
                  name="Sign In"
                  style={{
                    borderColor: "#0071F2",
                    borderRadius: "8px",
                    color: "#0071F2",
                    marginTop: "16px",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "600",
                    height: "40px",
                    letterSpacing: ".9px",
                  }}
                ></Button>

                <Div
                  key={"7"}
                  inLineStyles="[object Object]"
                  className="qdm"
                  style={{
                    marginTop: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    key={"0"}
                    name="Don't have an account?"
                    color="#6f6f6f"
                    style={{ color: "#6f6f6f", fontSize: "12px" }}
                  ></Text>

                  <Link
                    onClick={() => this.toggleSideBar(true)}
                    key={"1"}
                    underline="hide"
                    color="primary"
                    name="Sign Up"
                    style={{
                      color: "#0071F2",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    inLineStyles={{ textDecoration: "unset" }}
                  ></Link>
                  <SideMenu
                    open={this.state.sidebar}
                    direction="right"
                    width={435}
                    color="default"
                    onClose={() => this.toggleSideBar(false)}
                  >
                    {this.state.searchMenber ? (
                      <Addmember
                        name={"Sign Up"}
                        nextSection={(member) => this.nextSection(member)}
                        handelChangeSelect={(v) => this.handelChangeSelect(v)}
                        chooseSearch={this.state.chooseSearch}
                      />
                    ) : this.state.memberForm ? (
                      <Addmemberform
                        nextSection={(member) => this.nextSection(member)}
                      />
                    ) : this.state.memberOtp ? (
                      <Addmemberotp
                        nextSection={(member) => this.nextSection(member)}
                      />
                    ) : null}
                  </SideMenu>
                </Div>
              </Paper>
            </BackgroundImage>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  generateOTP: state?.authSlice?.generateOTP,
});

export default connect(mapStateToProps, actions)(withRouter(SignUp));
