/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 
 * @modify date 
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from "react";
import { BorderLinearProgress } from "qdm-component-library";
import { NetworkCall } from "./networkcall";
import { LocalStorageKeys, NetWorkCallMethods, semverGreaterThan, refreshCacheAndReload } from "./utils";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidMount(){
    this.checkForLatestBuild();
  }

  checkForLatestBuild = () => {
    console.log(`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`);
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    ).then((_) => {
      const isVersion = semverGreaterThan(_.data.version, localStorage.getItem(LocalStorageKeys.version));
      localStorage.setItem(LocalStorageKeys.version, _.data.version)
      if (isVersion) {
        refreshCacheAndReload();
      }
    }).catch(err => {
      console.log('err:', err);
    })
  }

  render() {

    let {
      loading
    } = this.props;

    return (
      <>       
        {loading && (
          <BorderLinearProgress/>
        )}
        {this.props.children}
      </>
    );
  }
}
 
export default AppAuth;
