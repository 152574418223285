import React from 'react';
import { WebDataRocksTable } from '../../../../components';
import '../index.css';

export const WorkList = (props) =>{
   
    const REPORT = (data) => {
        return{
            dataSource:{
                dataSourceType:'json',
                data:data
            },
            options:{
                grid:{
                    type:'flat',
                    showTotals: "off",
                    showGrandTotals: "off",
                },
            },
            slice: {
                columns: props?.columnToShow
            },
            formats: [{
                "thousandsSeparator": "",
                "decimalPlaces": 0,
            }]
        }
    }

    const [state,setState] = React.useState({
        report:props?.report?.length ? REPORT(props?.report) :  []
    })

    React.useEffect(()=>{
        setState({
            ...state,report:props?.report?.length ? REPORT(props?.report) :  []
        })
    },[props?.report])


    const customizeCellFunction = (cellBuilder, cellData) =>{
        if (cellData?.measure?.uniqueName === "contact" || cellData?.measure?.uniqueName === "mobileNo") {
            cellBuilder.text=`
            <div class="labelStyle">
                ${(cellData?.label).replace(/"/g, "")}
            </div>`
        } else if(cellData?.measure?.uniqueName === "action"){
            if(cellData?.member?.caption === "AssignPractitioner"){                
                cellBuilder.text=`
                <div style="cursor:pointer;">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="width:18px;height:17.789px"
                        viewBox="0 0 18 17.789"
                    >
                        <path id="icons8-change-user_1_" data-name="icons8-change-user (1)" d="M12.658,4.918A10.3,10.3,0,0,1,20.6,8.654V6.124a.7.7,0,1,1,1.4,0v4.2a.7.7,0,0,1-.7.7H17.1a.7.7,0,1,1,0-1.4h2.493a8.915,8.915,0,0,0-14.308.6.7.7,0,0,1-1.16-.787A10.312,10.312,0,0,1,12.658,4.918Zm0,4.358A3.2,3.2,0,0,1,15.087,10.3a3.419,3.419,0,0,1,0,4.424,3.2,3.2,0,0,1-2.428,1.029,3.241,3.241,0,0,1,0-6.482Zm0,1.4a1.84,1.84,0,0,0,0,3.679,1.714,1.714,0,0,0,1.381-.558,2.02,2.02,0,0,0,0-2.562A1.714,1.714,0,0,0,12.658,10.677ZM16.2,16.633a1.582,1.582,0,0,1,1.625,1.523V19.6a2.75,2.75,0,0,1-1.592,2.287,7.279,7.279,0,0,1-3.574.824,7.283,7.283,0,0,1-3.575-.824A2.751,2.751,0,0,1,7.491,19.6v-1.44a1.582,1.582,0,0,1,1.625-1.523Zm0,1.4H9.116c-.175,0-.224.094-.224.121V19.6c0,.3.233.7.882,1.068a6.01,6.01,0,0,0,2.885.641,6,6,0,0,0,2.883-.641c.649-.368.882-.773.882-1.068v-1.44C16.424,18.128,16.374,18.035,16.2,18.035Z" 
                            transform="translate(-4 -4.918)"
                            fill="#101010"
                        />
                    </svg>
                </div>
                `
            }
            else if(cellData?.member?.caption === "ReassignPractitioner"){                
                cellBuilder.text=`
                <div style="cursor:pointer;">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="width:16px;height:17.067px"
                        viewBox="0 0 16 17.067"
                >
                    <path id="icons8-repeat" d="M17.836,3.984a.632.632,0,0,0-.585.4.646.646,0,0,0,.145.7L18.844,6.55H7.316A2.335,2.335,0,0,0,5,8.9V15.78L6.263,14.5V8.9A1.06,1.06,0,0,1,7.316,7.83H18.844L17.4,9.3a.645.645,0,0,0,0,.9.626.626,0,0,0,.893,0l2.526-2.559a.646.646,0,0,0,0-.9L18.289,4.178a.628.628,0,0,0-.453-.194ZM21,9.262l-1.263,1.279v5.6a1.06,1.06,0,0,1-1.053,1.066H7.156L8.6,15.746a.646.646,0,0,0,.141-.708.627.627,0,0,0-1.034-.2L5.185,17.4a.646.646,0,0,0,0,.9l2.526,2.559a.626.626,0,0,0,.893,0,.646.646,0,0,0,0-.9L7.156,18.492H18.684A2.335,2.335,0,0,0,21,16.146Z"
                        transform="translate(-5 -3.984)"
                        fill="#101010"
                    />
                    </svg>
                </div>
                `
            }
            else if(cellData?.member?.caption === "ClosePractitioner"){                
                cellBuilder.text=`
                <div style="cursor:pointer;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path id="icons8-sign-out" d="M12,4a8,8,0,1,0,6.27,12.96.6.6,0,1,0-.941-.745,6.8,6.8,0,1,1,0-8.43.6.6,0,1,0,.941-.745A7.986,7.986,0,0,0,12,4Zm4.994,4.994a.6.6,0,0,0-.418,1.03L17.951,11.4,9.8,11.392a.6.6,0,1,0,0,1.2l8.153.007-1.377,1.377a.6.6,0,1,0,.849.849l2.4-2.4a.6.6,0,0,0,0-.848l-2.4-2.4A.6.6,0,0,0,16.994,8.994Z" transform="translate(-4 -4)"/>
              </svg>
                </div>
                `
            }
        }       
    }

    const cellClick = (data) =>{
        if(data?.measure?.uniqueName === "action" && data?.member?.caption){
            props?.iconClick && props.iconClick(props?.report[data["rowIndex"] - 1])
        }
    }

    return(
        <div>
            <WebDataRocksTable
                report={state?.report}
                customizeCellFunction={customizeCellFunction}
                cellClick={(data)=>cellClick(data)}
            /> 
        </div>
    )
}