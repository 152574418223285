import React, { useState } from 'react';
import { Div, H6, Icons, Text, Select, Row, Col } from 'qdm-component-library';
import { TextField, makeStyles } from '@material-ui/core'
import { withAllContexts } from './../../../../HOCs';
import { AlertProps, UIColor } from './../../../../utils';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiInputBase-formControl': {
            height: 40,
            backgroundColor: UIColor.lineBorderFill.color,
            borderRadius: 8,
        },
        '& input': {
            fontFamily: 'pc_regular',
            fontSize: 14,
            color: UIColor.secondaryText.color,
            '&::placeholder': {
                fontSize: 14,
                fontFamily: 'pc_regular !important',
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    }
});

const RequestorDetailsForm = (props) => {
	const LabOrderApiSlice = useSelector((state) => state.LabOrderApiSlice);
	const dispatch = useDispatch();
    const practioner_role_options = useSelector((state) => state.LabOrderApiSlice?.practioner_role?.data || []);
    const { stateList = {}, requestorDetails, parent_id } = props;

    const classes = useStyles(props);

    const styles = {
        flexCenterJustify: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        title: {
            color: UIColor.primaryText.color,
            fontSize: 12,
            margin: 0,
            fontWeight: 600
        },
        wrapper: {
            border: `1px solid ${UIColor.lineBorderFill.color}`,
            borderRadius: 8,
            padding: 10,
            backgroundColor: UIColor.greyBackground.color
        },
        actionText: {
            fontSize: 12,
            backgroundColor: UIColor.primaryColor.color,
            color: "#fff",
            margin: 0,
            cursor: 'pointer',
            borderColor: UIColor.primaryColor.color,
            padding: "2px 8px",
            borderRadius: "5px"
        },
        specimenText: {
            color: UIColor.tertiaryText.color,
            fontSize: 10
        },
        flexRow: {
            display: 'flex',
            alignItems: 'center'
        },
        edit: {
            color: UIColor.primaryColor.color,
            cursor: 'pointer'
        },
        labelStyle: {
            color: UIColor.tertiaryText.color,
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        errorborderStyle:{
            borderRadius: 8,
            borderColor: UIColor.error.color,
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color
        },
        colPadding: {
            padding: "7px"
        },
        star: {
            color: UIColor.error.color,
            marginLeft: "5px",
            fontSize: 12,
            fontFamily: `pc_regular !important`
        }
    };

    const [show, hide] = useState(requestorDetails.organization && requestorDetails.practitionerRole && requestorDetails.orderBy ? false : true);

    const [state, setState] = useState({
        organization: props?.requestorDetails?.organization,
        practitionerRole: props?.requestorDetails?.practitionerRole,
        orderBy: props?.requestorDetails?.orderBy,
        practitionerId: props?.requestorDetails?.orderBy?.value,

    })

    const onChangeState = (key, value) => {
        if (key === "organization" && value?.id && value?.id !== state?.organization?.id) {
			dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_ROLE({
					id: value?.id,
				})
			);
		}
		if (key === "practitionerRole") {
			dispatch(
				actions.ORDER_TYPE_REQ_DETAILS_LAB({
					id: value?.id,
					_id: value?._id,
					orgId: state?.organization?.id,
				})
			);
		}
        setState({ ...state, [key]: value })
    }

    const addRequestorDetails = () => {
        hide(!show);
    }

    const [isErr,setErr] = useState(false)
    const save = () => {
        if (!state.organization || !state.practitionerRole || !state.orderBy) {
            setErr(true)
            props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all the mandatory fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            })
            return false
        }
        hide(true);
        props?.actionButton && props.actionButton(state)
    }

    React.useEffect(() => {
        const {
            organization,
            practitionerRole,
            orderBy,
        } = props?.requestorDetails || {};
        setState({
            organization: organization,
            practitionerRole: practitionerRole,
            orderBy: orderBy,
            practitionerId: orderBy?.value,
    
        });
        if (organization && practitionerRole && orderBy) {
            hide(!show);
        }
    }, [props?.requestorDetails])

    return (
        <Div
            id={`${parent_id}_rquestor_form_parent_div`}
            style={styles.wrapper}>
            <Div
                id={`${parent_id}_rquestor_form_parent_sub_div`}
                style={styles.flexCenterJustify}>
                <Div
                    id={`${parent_id}_rquestor_form_title_div`}
                    style={{ width: '100%' }}>
                    <Div
                        id={`${parent_id}_rquestor_form_title_sub_div`}
                        style={styles.flexCenterJustify}>
                        <H6
                            id={`${parent_id}_rquestor_form_${props?.title.replaceAll(" ", "_")}_h6`}
                            className="pc_regular" style={styles.title}>{props?.title}</H6>
                        {(!show && !state?.organization?.value) && <H6
                            id={`${parent_id}_rquestor_form_${props?.action.replaceAll(" ", "_")}_h6`}
                            className="pc_regular" style={styles.actionText} onClick={addRequestorDetails}>{props?.action}</H6>}
                        {show && <H6
                            id={`${parent_id}_rquestor_form_Save_h6`}
                            className="pc_regular" style={styles.actionText} onClick={save}>Save</H6>}
                    </Div>
                    {(!show && state?.organization?.value) &&
                        <Text
                            id={`${parent_id}_rquestor_form_show_text`}
                        >
                            {state?.organization?.value && <Text
                                id={`${parent_id}_rquestor_form_${state?.organization?.label.replaceAll(" ", "_")}_text`}
                                className="pc_medium" style={styles.specimenText}>{state?.organization?.label}</Text>}

                            {state?.practitionerRole?.value && <Text
                                id={`${parent_id}_rquestor_form_${state?.practitionerRole?.label.replaceAll(" ", "_")}_text`}
                                className="pc_medium" style={styles.specimenText}>&nbsp;•&nbsp;{state?.practitionerRole?.label}</Text>}

                            {state?.orderBy?.value && <Text
                                id={`${parent_id}_rquestor_form_${state?.orderBy?.label.replaceAll(" ", "_")}_text`}
                                className="pc_medium" style={styles.specimenText}>&nbsp;•&nbsp;{state?.orderBy?.value}</Text>}

                            {state?.orderBy?.value && <Text
                                id={`${parent_id}_rquestor_form_${state?.orderBy?.label.replaceAll(" ", "_")}_text`}
                                className="pc_medium" style={styles.specimenText}>&nbsp;•&nbsp;{state?.orderBy?.label}</Text>}
                        </Text>
                    }
                    {
                        show &&
                        <Row>
                            <Col
                                id={`${parent_id}_rquestor_form_Organization_col`}
                                xs={12} sm={12} md={3} lg={3} xl={3} inLineStyles={styles.colPadding}>
                                <Select
                                    id={`${parent_id}_rquestor_form_Organization_select`}
                                    getOptionLabel="label"
                                    showArrow
                                    label={"Organization"}
                                    isRequired
                                    placeholder={"Select Organization"}
                                    labelStyle={styles.labelStyle}
                                    inLineStyles={isErr && !state?.organization?.label ? styles.errorborderStyle : styles.borderStyle}
                                    hoverColor={UIColor.lineBorderFill.color}
                                    value={state?.organization?.label}
                                    onChangeValue={(e) => onChangeState("organization", e)}
                                    options={stateList.Organization_masters?.data ?? []}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_rquestor_form_practitionerRole_col`}
                                xs={12} sm={12} md={3} lg={3} xl={3} inLineStyles={styles.colPadding}>
                                <Select
                                    id={`${parent_id}_rquestor_form_practitionerRole_select`}
                                    getOptionLabel="label"
                                    showArrow
                                    label={"Practitioner Role"}
                                    isRequired
                                    placeholder={"Select Practitioner Role"}
                                    labelStyle={styles.labelStyle}
                                    inLineStyles={isErr && !state?.practitionerRole?.label ? styles.errorborderStyle : styles.borderStyle}
                                    hoverColor={UIColor.lineBorderFill.color}
                                    value={state?.practitionerRole?.label}
                                    onChangeValue={(e) => onChangeState("practitionerRole", e)}
                                    options={practioner_role_options ?? []}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_rquestor_form_orderBy_col`}
                                xs={12} sm={12} md={3} lg={3} xl={3} inLineStyles={styles.colPadding}>
                                <Select
                                    id={`${parent_id}_rquestor_form_orderBy_select`}
                                    getOptionLabel="label"
                                    showArrow
                                    label={"Order By"}
                                    isRequired
                                    placeholder={"Select Order By"}
                                    labelStyle={styles.labelStyle}
                                    inLineStyles={isErr && !state?.orderBy?.label ? styles.errorborderStyle : styles.borderStyle}
                                    hoverColor={UIColor.lineBorderFill.color}
                                    value={state?.orderBy?.label}
                                    onChangeValue={(e) => onChangeState("orderBy", e)}
                                    options={
										LabOrderApiSlice?.order_type_req_details?.data?.length > 0 ?
											LabOrderApiSlice?.order_type_req_details?.data
											: []
									}
                                />
                            </Col>
                            <Col
                                id={`${parent_id}_rquestor_form_Practitioner_ID_col`}
                                xs={12} sm={12} md={3} lg={3} xl={3} inLineStyles={styles.colPadding}>
                                <Div
                                    id={`${parent_id}_rquestor_form_Practitioner_ID_div`}
                                    className="pc_regular"
                                    style={styles.labelStyle}>Practitioner ID<Text
                                        id={`${parent_id}_rquestor_form_Practitioner_ID_star_div`}
                                        style={styles.star}>*</Text></Div>
                                <TextField
                                    id={`${parent_id}_rquestor_form_Practitioner_ID_textfield`}
                                    className={classes.autocomplete}
                                    value={state?.orderBy?.practitioner_id}
                                    onChange={(e) => onChangeState('practitionerId', e.target.value)}
                                    // placeholder={"Start Date"}
                                    InputProps={{
                                        classes: { input: classes.input },
                                    }}
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Col>
                        </Row>
                    }
                </Div>
                {
                    (!show && state?.organization?.value) &&
                    <Icons
                        id={`${parent_id}_rquestor_form_edit_icons`}
                        fontIcon={"pencil"} onClick={addRequestorDetails} style={styles.edit} />
                }
            </Div>
        </Div>
    )
}

export default withAllContexts(RequestorDetailsForm);

RequestorDetailsForm.defaultProps = {
    title: '',
    action: '',
    actionButton: null,
    specimenData: {},
    onChangeState: null
}
