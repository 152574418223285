import {
	Grid,
	makeStyles,
	Button,
	Typography,
	CircularProgress,
	Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import landingPic from "../../../assets/img/avcLandingBg.svg";

const useStyles = makeStyles((theme) => ({
	textBody: {
		fontFamily: "pc_medium !important",
		fontSize: "16px",
		color: "#2AAD88",
		padding: "10px",
	},
	buttonAlign: {
		fontSize: "14px",
		textTransform: "none",
		borderRadius: "8px",
		padding:"10px 16px",
		backgroundColor: "#0071F2",
		fontFamily: "pc_medium !important",
		color: "#fff",
		marginRight: "10px",
		border: "none",
		"&:hover": {
			backgroundColor: "#0551a9",
		},
	},
	rootCircle: {
		position: "relative",
	},
	bottom: {
		color:"#f6f6f6"
	},
	top: {
		color: "#FF6384",
		animationDuration: "550ms",
		position: "absolute",
		left: 0,
	},
	circle: {
		strokeLinecap: "round",
	},
	btnDiv: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "20px 0",
	},
}));

const userInfoData = localStorage.getItem("role_name");
function CircularProgressWithLabel(props) {
	const classes = useStyles();

	return (
		<Box position="relative" display="inline-flex" marginTop="6px">
			<div className={classes.rootCircle}>
				<CircularProgress
					variant="determinate"
					className={classes.bottom}
					thickness={4}
					size={45}
					value={100}
				/>
				<CircularProgress
					variant="determinate"
					disableShrink
					className={classes.top}
					classes={{
						circle: classes.circle,
					}}
					size={45}
					thickness={3}
					{...props}
					value={(props.value / 15) * 100}
				/>
			</div>

			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
				marginBottom="4px"
			>
				<Typography
					variant="caption"
					component="div"
					style={{color:"#FF6384",fontSize:"12px",fontFamily: "pc_medium !important"}}
				>{`${Math.round(props.value)}`}</Typography>
			</Box>
		</Box>
	);
}

CircularProgressWithLabel.propTypes = {
	value: PropTypes.number.isRequired,
};

export function LandingAVC(props) {
	const classes = useStyles();
	const [progress, setProgress] = React.useState(15);

	const handleMeeting = () => {
		// alert("Meeting is initiated");
		props?.proceed && props.proceed()
	};

	React.useEffect(() => {
		const timer = setInterval(() => {
		  setProgress((prevProgress) => (prevProgress <= 0 ? handleMeeting() : prevProgress - 1));
		}, 1000);
		return () => {
		  clearInterval(timer);
		};
	  }, []);

	return (
		<div>
			<img alt="" src={landingPic} width="100%" />
			<div style={{ textAlign: "center", width: "70%", margin: "auto" }}>
				<Typography className={classes.textBody}>
					{props?.name}
				</Typography>
				<Typography
					className={classes.textBody}
					style={{ color: "#B6B6B6", fontSize: "12px" }}
				>
					{props?.title}
				</Typography>
				<Typography className={classes.textBody} style={{ color: "#101010" }}>
					{props?.desc}
				</Typography>
				<div className={classes.btnDiv}>
					<Button className={classes.buttonAlign} onClick={handleMeeting}>
						Lets Proceed
					</Button>
					<CircularProgressWithLabel value={progress} />
				</div>
			</div>
		</div>
	);
}
