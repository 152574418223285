import React from "react";
import { Avatar, Card } from "qdm-component-library";
import { Typography, withStyles } from "@material-ui/core";
import { UIColor } from "../../utils";
import { encounterData } from "./dummyData";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";

const materialStyles = (theme) => ({
  avatarCls: {
    "& img": {
      width: "30px !important",
      height: "30px !important",
      objectFit: "contain !important",
    },
  },
  headerFonts: {
    fontSize: 14,
    color: UIColor.primaryColor.color,
    margin: 0,
    fontWeight: 600,
    fontFamily: "pc_semibold !important",
  },
  dot: {
    padding: "2px",
    backgroundColor: UIColor.tertiaryText.color,
    borderRadius: "50%",
  },
  alignItemsSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
  },
  subText: {
    fontSize: 12,
    color: UIColor.tertiaryText.color,
    fontFamily: "pc_regular !important",
  },
  circle: {
    border: `1px solid ${UIColor.tertiaryText.color}`,
    borderRadius: "50%",
    width: "21px",
    height: "21px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  arrow: {
    fontSize: "15px",
    color: UIColor.tertiaryText.color,
    cursor: "pointer",
  },
});

class EncounterCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encounterDataList: [],
      counter: 0,
    };
  }

  styles = {
    cardStyle: {
      boxShadow: "none",
      borderRadius: 8,
      marginLeft: "5px",
    },
    avatar: {
      margin: 0,
      marginRight: 15,
      width: 48,
      height: 48,
      borderRadius: 8,
      backgroundColor: "#F0F0FA",
    },
  };

  constructData = (encounters) => {
    let list = [];
    const compressedData = encounters.map((val, i) => {
      return list.push({
        encounterName: val?._key ? `EN - ${val?._key}` : "",
        type: val?.type?.[0]?.code ?? "",
        speciality: val?.practitioner_details?.[0]?.speciality?.display ?? "-",
        encounterStart: val?.encounterdate ?? "",
        encounterEnd: val?.encounterenddate ?? "",
        location: val?.location?.[0]?.physicalType ?? "",
        doctorName: val?.practitioner_details?.[0]?.id_name,
      });
    });
    return list;
  };

  componentDidMount() {
    const encounters = this?.props?.encounterDetails;
    const constructData = this.constructData(encounters);
    this.setState({ ...this.state, encounterDataList: constructData });
  }

  onIncrement = () => {
    this.props.onIncrement();
  };

  onDecrement = () => {
    this.props.onDecrement();
  };

  makeDate = (date) => {
    return moment.unix(date).format("DD MMM YY HH:mm");
  };

  makeName = (name) => {};

  render() {
    const { classes, parent_id, padding } = this.props;
    const { avatar, cardStyle } = this.styles;
    return (
      <div>
        <Card id={`${parent_id}_patientstatus_parent_card`} style={cardStyle}>
          <div
            id={`${parent_id}_patientstatus_parent_div`}
            className={classes.alignItemsSpaceBetween}
            style={{ alignItems: "start", marginRight: "10px" }}
          >
            <div className={classes.alignItems}>
              <Avatar
                id={`${parent_id}_patientstatus_${this.props.name?.[0]}_avatar`}
                src="images/Group 90506.svg"
                variant="rounded"
                className={classes.avatarCls}
                inLineStyles={avatar}
              />
              <div>
                <div className={classes.alignItemsSpaceBetween}>
                  <div className={classes.alignItems} style={{ gap: "8px" }}>
                    <Typography className={classes.headerFonts}>
                      {
                        this.state.encounterDataList?.[this.props?.counter]
                          ?.encounterName
                      }
                    </Typography>
                    <div className={classes.dot}></div>
                    <Typography className={classes.headerFonts}>
                      {
                        this.state.encounterDataList?.[this.props?.counter]
                          ?.type
                      }
                    </Typography>
                    <div className={classes.dot}></div>
                    <Typography className={classes.headerFonts}>
                      {
                        this.state.encounterDataList?.[this.props?.counter]
                          ?.speciality
                      }
                    </Typography>
                  </div>
                </div>
                <div
                  className={classes.alignItems}
                  style={{ marginTop: "10px" }}
                >
                  <Typography className={classes.subText}>
                    On{" "}
                    {this.makeDate(
                      this.state.encounterDataList?.[this.props?.counter]
                        ?.encounterStart
                    )}{" "}
                    -{" "}
                    {this.makeDate(
                      this.state.encounterDataList?.[this.props?.counter]
                        ?.encounterEnd
                    )}
                  </Typography>
                  <div
                    className={classes.dot}
                    style={{ margin: "0px 6px" }}
                  ></div>
                  <div>
                    <Typography className={classes.subText}>
                      {
                        this.state.encounterDataList?.[this.props?.counter]
                          ?.doctorName
                      }
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.alignItems} style={{ gap: "6px" }}>
              <div className={classes.circle} onClick={this.onDecrement}>
                <ChevronLeftIcon className={classes.arrow} />
              </div>
              <div className={classes.circle} onClick={this.onIncrement}>
                <ChevronRightIcon className={classes.arrow} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

EncounterCard.defaultProps = {
  pic: null,
  name: "",
  titles: [],
  date: null,
  languages: null,
};

export default withStyles(materialStyles)(EncounterCard);
