import React from "react";
import { H6, Div } from "qdm-component-library";
import { Bar } from "react-chartjs-2";

const BarChart = (props) => {
  const styles = {
    rowCenter: {
      display: "flex",
      alignItems: "center",
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: 10,
    },
    h6: {
      fontSize: 12,
      color: "#101010",
      margin: 0,
      flex: 1,
    },
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 12,
    },
  };

  const options = {
    plugins: {
      responsive: true,
      legend: {
        display: false,
      },
      type: "bar",
      scales: {
        y: {
          beginAtZero: true,
        },
        layout: {
          padding: 10,
        },
      },
    },
  };
  const { parent_id } = props;
  return (
    <Div id={`${parent_id}_barchart_parent_div`}>
      {props?.label ? (
        <H6
          id={`${parent_id}_barchart_label_h6`}
          className="pc_medium"
          style={{ marginBottom: 35, fontSize: 14 }}
        >
          {props?.label}
        </H6>
      ) : null}

        {/* <div style={{position:"relative", height:"274px",width:"400px"}}> */}
      <Bar
        id={`${parent_id}_barchart_bar`}
        data={props?.data}
        height={125}
        width={480}
        options={options}
      />
      {/* </div> */}
      <Div
        id={`${parent_id}_barchart_sub_div`}
        style={{
          ...styles.rowCenter,
          justifyContent: "space-between",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        {props?.data &&
          props?.data?.datasets?.map((l, i) => {
            ;
            return (
              <Div
                id={`${parent_id}_${i}_barchart_map_div`}
                style={styles.rowCenter}
              >
                {/* <Div
                  id={`${parent_id}_${i}_barchart_sublevel_label_div`}
                  style={{
                    ...styles.dot,
                    backgroundColor: l?.backgroundColor[0] ?? "red",
                  }}
                /> */}

                <H6
                  id={`${parent_id}_${i}_barchart_label_h6`}
                  className="pc_regular"
                  style={styles.h6}
                >
                  {l?.label ?? " "}
                </H6>
              </Div>
            );
          })}
      </Div>
    </Div>
  );
};

BarChart.defaultProps = {
  data: {},
  label: " ",
};

export default BarChart;
