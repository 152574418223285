import React, { useState } from "react";
import { Div, Row, Col, Text, Icons, H6, Avatar } from "qdm-component-library";
import {
  AllergyIcon,
  AppointmentInfo,
  BillingDetails,
  BillingPending,
  ChangeStatus,
  RequestorDetails,
  UnBilled,
} from "../";
import { Popover, makeStyles } from "@material-ui/core";
import { UIColor } from "../../utils";

const useStyles = makeStyles({
  muiPopovers: {
    boxShadow: "0px 4px 24px #00000029",
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 8,
    backgroundColor: "#fff",
    minWidth: 240,
    overflow: "visible",
  },
  billedPopover: {
    boxShadow: "0px 4px 24px #00000029",
    borderRadius: 8,
    minWidth: 240,
  },
});

const styles = {
  cardView: {
    // backgroundColor: "#F0F0F0",
    // border: "1px solid #E0E0E0",
    borderRadius: 21,
    padding: "8px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontSize: 12,
    color: UIColor.secondaryText.color,
  },
  text1: {
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "140px",
    textAlign: "right"
  },

  headerText: {
    fontSize: 14,
    color: "#055CA7",
    fontWeight: 600,
    paddingLeft: "8px",
    textTransform: "capitalize",
  },
  iconSize: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 26,
    height: 26,
    borderRadius: "50%",
    backgroundColor: "#d2e4f3",
    marginRight: 5,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    margin: "0px 8px",
    display: "inline-block",
  },
  userIcon: {
    width: 25,
    height: 25,
    borderRadius: 50,
    backgroundColor: "#E2EEFB",
    marginLeft: 10,
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  verticalDivider: {
    height: 25,
    backgroundColor: UIColor.tertiaryText.color,
    marginLeft: 10,
    width: 0.8,
  },
  avatar: {
    width: 34,
    height: 34,
    borderRadius: "50%",
    margin: 0,
    marginLeft: 5,
  },
  span: {
    fontSize: 10,
    color: "#323232",
  },
  span1: {
    fontSize: 10,
    color: UIColor.secondaryText.color,
  },
  colPadding: {
    padding: 5,
  },
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  headerText: {
    fontSize: 16,
    fontWeight: 500,
    color: UIColor.secondaryButtonColor.color,
    paddingLeft: 8,
  },
};

export const FormHeader = (props) => {
  const ShowLabel = (val) => {
    return (
      <Div
        id={`${parent_id}_formheader_div`}
        style={{
          ...styles.flexCenter,
          marginRight: 10,
          cursor: val?.onClick ? "pointer" : "auto",
        }}
        onClick={(e) => val?.onClick && val.onClick(e)}
      >
        {val?.icon && (
          <Div id={`${parent_id}_formheader_icon_div`} style={styles.iconSize}>
            {val?.icon}
          </Div>
        )}
        {val?.text && (
          <Text
            id={`${parent_id}_formheader_${val?.text}_text`}
            style={styles.headerText}
            className="pc_semibold"
          >
            {val?.text}
          </Text>
        )}
      </Div>
    );
  };

  const classes = useStyles(props);

  const [state, setState] = useState({
    statusData: {
      status: {},
      date: "",
      reason: "",
    },
  });

  React.useEffect(() => {
    state.statusData = props.statusDataList;
    setState({ ...state });
  }, [props.statusDataList]);

  const onChangeState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const status = [
    {
      label: "On Hold",
      value: "On Hold",
      color: UIColor.secondaryColor.color,
      backgroundColor: "#e6ab9c",
    },
    {
      label: "Complete",
      value: "Complete",
      color: UIColor.success.color,
      backgroundColor: "#86ad79",
    },
    {
      label: "Cancel",
      value: "Cancel",
      color: UIColor.error.color,
      backgroundColor: "#FFEDED",
    },
    // { label: 'Continue', value: 'Continue', color: '#F58B00', backgroundColor: '#FFF1E0' },
    // { label: 'Discontinue', value: 'Discontinue', color: '#FF4D4A', backgroundColor: '#FFEDED' },
  ];

  const backStatus = (type) => {
    switch (type) {
      case "Active":
        return UIColor.success.color;
      case "On Hold":
        return UIColor.secondaryColor.color;
      case "Cancel":
        return UIColor.error.color;
      case "Complete":
        return UIColor.success.color;
    }
  };
  const billingPendingData = [
    {
      speciality: "Pharmacist",
      date: "24 Mar 12:03 PM",
      name: "Dr.Roger Garrett",
      profile_pic:
        "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
      status: {
        status: "Billing Pending",
        location: "Counter 1",
        locationCode: "Location Code",
      },
    },
    {
      speciality: "Pharmacist",
      date: "24 Mar 11:30 PM",
      name: "Johnny Stewart",
      profile_pic:
        "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
      status: {
        status: "Sample Collected",
        location: "Emergency Unit 1",
        locationCode: "CD065456",
      },
    },
  ];

  const requestorDetails = {
    name: props.patientgetData?.name ?? "",
    pidNumber: props.patientgetData?.mrn ?? "",
    role: props.patientgetData?.speciality ?? "",
    hospital: props.patientgetData?.facilityName ?? "",
    profile_pic:
      "https://thumbs.dreamstime.com/b/portrait-charming-imposing-kid-private-school-boy-feel-glad-ready-work-worker-want-be-cool-professional-look-hear-wear-blazer-152393107.jpg",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(null);

  const colorStatus = (type) => {
    switch (type) {
      case "Active":
        return UIColor.success.color;
      case "On Hold":
        return UIColor.secondaryColor.color;
      case "Cancel":
        return UIColor.error.color;
      case "Complete":
        return UIColor.success.color;
      case "Continue":
        return UIColor.secondaryColor.color;
      case "Discontinue":
        return UIColor.error.color;
      default:
        return UIColor.secondaryText.color;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (index, event) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { parent_id } = props;
  return (
    <div id={`${parent_id}_formheader_parent_div`}>
      <Row id={`${parent_id}_formheader_parent_row`}>
        <Col
          id={`${parent_id}_formheader_parent_col`}
          inLineStyles={styles.colPadding}
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
        >
          <Div
            id={`${parent_id}_formheader_parent_sub_div`}
            style={styles.cardView}
          >
            <Div
              id={`${parent_id}_formheader_title_sub_div`}
              style={{ ...styles.flexCenter, flex: 1 }}
            >
              {props?.title && (
                <Text
                  id={`${parent_id}_formheader_${props?.title.replaceAll(
                    " ",
                    "_"
                  )}_text`}
                  inLineStyles={styles.headerText}
                >
                  {props?.title}
                </Text>
              )}
              {props?.cyclosporine && (
                <ShowLabel
                  id={`${parent_id}_formheader_cyclosporine_showlabel`}
                  icon={
                    <AllergyIcon
                      id={`${parent_id}_formheader_cyclosporine_allergyicon`}
                      width={14}
                      height={14}
                      color={UIColor.secondaryButtonColor.color}
                    />
                  }
                  text={
                    props.stateData?.orderCatalog?.label ||
                    props.stateData?.selectCatagory?.label
                  }
                />
              )}
              {props?.Docc && (
                <ShowLabel
                  id={`${parent_id}_formheader_cyclosporine_showlabel`}
                  icon={<img src={props.headUrl} />}
                  text={
                    <span style={{ color: UIColor.secondaryButtonColor.color }}>
                      {props.stateData?.OldselectCatagory?.label}
                      <span
                        style={{
                          border: `1px solid ${UIColor.secondaryButtonColor.color}`,
                          margin: "0px 6px",
                          padding: "2px",
                          borderRadius: "4px",
                          fontSize: 11,
                        }}
                      >
                        {props?.stateData?.saveMode}
                      </span>
                    </span>
                  }
                />
              )}
              {props?.recurrent && (
                <ShowLabel
                  id={`${parent_id}_formheader_recurrent_showlabel`}
                  icon={
                    <AllergyIcon
                      id={`${parent_id}_formheader_recurrent_allergyicon`}
                      width={14}
                      height={14}
                      color={UIColor.secondaryButtonColor.color}
                    />
                  }
                  text="Recurrent"
                />
              )}
              {props?.unbilled && (
                <ShowLabel
                  id={`${parent_id}_formheader_unbilled_showlabel`}
                  icon={
                    <AllergyIcon
                      id={`${parent_id}_formheader_unbilled_allergyicon`}
                      width={14}
                      height={14}
                      color={UIColor.secondaryButtonColor.color}
                    />
                  }
                  text="Unbilled"
                  onClick={(e) => handleClick(3, e)}
                />
              )}
              {props?.billingPending && (
                <ShowLabel
                  id={`${parent_id}_formheader_billingPending_showlabel`}
                  icon={
                    <AllergyIcon
                      id={`${parent_id}_formheader_billingPending_allergyicon`}
                      width={14}
                      height={14}
                      color={UIColor.secondaryButtonColor.color}
                    />
                  }
                  text="Billing Pending"
                  onClick={(e) => handleClick(4, e)}
                />
              )}
            </Div>
            {/* {JSON.stringify(state?.statusData)} */}
            <Div
              id={`${parent_id}_formheader_status_div`}
              style={styles.flexCenter}
            >
              {props?.status && (
                <Text
                  id={`${parent_id}_formheader_status_text`}
                  inLineStyles={{ cursor: "pointer" }}
                >
                  {props?.statusData && (
                    <Text
                      id={`${parent_id}_formheader_${
                        state?.statusData?.status?.label ?? props?.statusData
                      }_text`}
                      style={styles.flexCenter}
                      className="pc_medium"
                      aria-describedby={id}
                      onClick={(e) => handleClick(0, e)}
                      style={{
                        fontSize: 12,
                        color: colorStatus(
                          state?.statusData?.status?.label ?? props?.statusData
                        ),
                      }}
                    >
                      <text
                        id={`${parent_id}_formheader_statusdata_text`}
                        style={{
                          ...styles.dot,
                          backgroundColor: backStatus(
                            state?.statusData?.status?.label ??
                              props?.statusData
                          ),
                        }}
                      />
                      {state?.statusData?.status?.label || props?.statusData}
                    </Text>
                  )}
                </Text>
              )}
              {(props?.userGroup || props?.user) && (
                <Div style={styles.verticalDivider} />
              )}
              {props?.userGroup && (
                <Text
                  id={`${parent_id}_formheader_userGroup_text`}
                  aria-describedby={id}
                  onClick={(e) => handleClick(1, e)}
                  inLineStyles={
                    index === 1 && open
                      ? styles.userIcon
                      : { ...styles.userIcon, backgroundColor: "transparent" }
                  }
                >
                  <Icons
                    id={`${parent_id}_formheader_userGroup_icons`}
                    fontIcon="group"
                    style={{
                      color: index === 1 && open ? UIColor.primaryColor.color : "#000",
                    }}
                  />
                </Text>
              )}
              {props?.user && (
                <Text
                  id={`${parent_id}_formheader_user_text`}
                  aria-describedby={id}
                  onClick={(e) => handleClick(2, e)}
                  inLineStyles={
                    index === 2 && open
                      ? styles.userIcon
                      : { ...styles.userIcon, backgroundColor: "transparent" }
                  }
                >
                  <Icons
                    id={`${parent_id}_formheader_user_icons`}
                    fontIcon="user-o"
                    style={{
                      color: index === 2 && open ? UIColor.primaryColor.color : "#000",
                    }}
                  />
                </Text>
              )}
            </Div>
          </Div>
        </Col>
        <Col
          id={`${parent_id}_formheader_patientdetails_col`}
          inLineStyles={styles.colPadding}
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
        >
          <Div
            id={`${parent_id}_formheader_patientdetails_date_div`}
            style={{
              ...styles.cardView,
              justifyContent: "flex-end",
              padding: 2,
            }}
          >
            <Div
              id={`${parent_id}_formheader_patientdetails_date_sub_div`}
              style={styles.flexEnd}
            >
              <Text id={`${parent_id}_formheader_patientdetails_date_text`}>
                {props?.patientDetails?.date && (
                  <Text
                    id={`${parent_id}_formheader_${props?.patientDetails?.date.replaceAll(
                      " ",
                      "_"
                    )}_text`}
                    className="pc_regular"
                    style={styles.span}
                  >
                    {props?.patientDetails?.date}
                  </Text>
                )}
                {props?.patientDetails?.status && (
                  <Text
                    id={`${parent_id}_formheader_${props?.patientDetails?.status}_text`}
                    className="pc_regular"
                    style={styles.span1}
                  >
                    &nbsp;{"•"}&nbsp;{props?.patientDetails?.status}
                  </Text>
                )}
              </Text>
              {props?.patientDetails?.name && (
                <H6
                  id={`${parent_id}_formheader_${
                    props?.patientDetails?.name +
                      props?.patientDetails?.isYou &&
                    "(You)".replaceAll(" ", "_")
                  }_text`}
                  className="pc_medium"
                  style={styles.text1}
                >
                  {props?.patientDetails?.name}
                </H6>
              )}
            </Div>
            {props?.patientDetails?.profile_pic && (
              <Avatar
                id={`${parent_id}_formheader_profile_pic_avatar`}
                variant="circle"
                letter="Avatar"
                src={props?.patientDetails?.profile_pic}
                alt="Image"
                style={styles.avatar}
              />
            )}
          </Div>
        </Col>
      </Row>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: index === 3 ? classes.billedPopover : classes.muiPopovers,
        }}
      >
        {index === 0 && (
          <ChangeStatus
            parent_id={"form_header"}
            cancelButton="Cancel"
            cancelButtonAction={handleClose}
            saveButton="Save"
            saveButtonAction={(data) => {
              onChangeState("statusData", data);
              props.onChangeState("statusData", data);
              handleClose();
            }}
            statusOption={status}
            changeStatusData={state?.statusData}
            statushistory={props.statushistory}
            // statusHistory={{
            //     name: props.patientgetData?.name ?? "",
            //     isYou: true,
            //     role: 'Practitioner',
            //     oldStatus: props.stateData?.statusType,
            //     oldStatus: 'Active',
            //     currentStatus: state?.statusData?.status?.label ?? 'Active',
            //     date: props.stateData?.createdAt
            // }}
          />
        )}
        {index === 1 && <AppointmentInfo parent_id={"form_header"} />}
        {index === 2 && (
          <RequestorDetails
            parent_id={"form_header"}
            performerDetailsData={requestorDetails}
            requestorDetails={requestorDetails}
            performerDetails={props?.performerDetails}
          />
        )}
        {index === 3 && (
          <UnBilled
            parent_id={"form_header"}
            subTitle="Need to create billing"
            button="Create"
            buttonAction={(e) => handleClick(5, e)}
          />
        )}
        {index === 4 && (
          <BillingPending
            parent_id={"form_header"}
            billingPendingData={billingPendingData}
          />
        )}
        {index === 5 && (
          <BillingDetails
            parent_id={"form_header"}
            name="Billed"
            payment="Cash"
            amount="Rs.1300"
            status="Unpaid"
            date="24 May 02.30 PM"
            pharmacyName="Pharmacy 1"
            locationCode="Location Code"
          />
        )}
      </Popover>
    </div>
  );
};

FormHeader.defaultProps = {
  cyclosporine: false,
  recurrent: false,
  unbilled: false,
  billingPending: false,
  status: false,
  statusData: "",
  userGroup: false,
  user: false,
  patientDetails: {},
  performerDetails: false,
};
