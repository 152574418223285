import React from "react";
import { Div, Select, Col, Row, Paper, H6 } from "qdm-component-library";
import "./style.css";
import { withQueue, withAllContexts } from "../../HOCs";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from "../../contexts";
import { withRouter } from "react-router-dom";
import { makeName, UIColor } from "../../utils";

function RefFrom(props) {
	const styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		selectBorderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
		},
		errorselectBorderStyle: {
			borderRadius: 8,
		},
		inputBorderStyle: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
			background: "#F0F0F0",
		},
	};
	const [facility_name, setfacility_name] = React.useState([]);
	const [practitioner_name, setpractitioner_name] = React.useState([]);
	const [getspeciality, setsetspeciality] = React.useState([]);
	const [practitioner_role, setpractitioner_role] = React.useState([]);

	const { from } = props.referralData;
	const setDetails = async (name, value) => {
		let patientData = props.referralData;
		if (name === "facility_type") {
			delete patientData.from.facility_name;
			delete patientData.from.specialty;
			delete patientData.from.practitioner_type;
			delete patientData.from.practitioner;
			if (props.GET_FACILITY_NAME) {
				const facility_name = await props.GET_FACILITY_NAME({
					name: value?.codingId,
				});
				const facility_name_Data = facility_name?.payload?.data?.map((v) => {
					return { value: v?.name, label: v?.OrgID, _id: v?._id };
				});
				setfacility_name(facility_name_Data);
			}
		}
		if (name === "facility_name") {
			delete patientData.from.specialty;
			delete patientData.from.practitioner_type;
			delete patientData.from.practitioner;
			if (props.GET_SPECIALITY_DATA) {
				// value?.label
				const speciality = await props.GET_SPECIALITY_DATA({
					id: value?.label,
				});
				const speciality_Data = speciality?.payload?.data?.map((v) => {
					return {
						value: v?.display,
						label: v?.id,
						codeableId: v?._id,
						codingId: v?._id,
					};
				});
				setsetspeciality(speciality_Data);
			}
		}
		if (name === "specialty") {
			delete patientData.from.practitioner_type;
			delete patientData.from.practitioner;
			if(props?.GET_PRACTITIONER_TYPE){
                const practitioner_type = await props.GET_PRACTITIONER_TYPE({ OrgID: value?.codeableId });
                debugger
                setpractitioner_role(practitioner_type?.payload?.data ?? [])
            }
        } else if(name === 'practitioner_type'){
            delete from.practitioner;
            if(props?.GET_PRACTITIONER_NAME){
                const practitioner_name_Data = await props.GET_PRACTITIONER_NAME({ facilityId : from.facility_name?._id, specialityID: from.specialty?.codingId, practitionerType: value?.label});
                debugger
                setpractitioner_name(practitioner_name_Data?.payload?.data ?? [])
        }

			// if (props.GET_PRACTITIONER) {
			// 	const practitioner_name = await props.GET_PRACTITIONER({
			// 		OrgID: value?.codeableId,
			// 	});
			// 	const practitioner_name_Data = practitioner_name?.payload?.data?.map(
			// 		(v) => {
			// 			return { value: makeName(v?.name?.[0]) };
			// 		}
			// 	);
			// 	const practitioner_role_Data = practitioner_name?.payload?.data?.map(
			// 		(v) => {
			// 			return { value: v?.practitioner_type };
			// 		}
			// 	);
			// 	setpractitioner_name(practitioner_name_Data);
			// 	setpractitioner_role(practitioner_role_Data);
			// }
		}
		patientData.from[name] = value;
		if (patientData.from.error) {
			patientData.from.error[name] = false;
		}
		patientData.setReferralDetails({
			...patientData,
		});
	};
	// const FlterOptions = (type) => {
	//     const { allMasters } = props;
	//     var lista = []
	//     const data = allMasters?.[type]
	//     for (let index in data) {
	//         var datatemp = {
	//             "value": data[index]?.coding?.display,
	//         };
	//         lista.push(datatemp);
	//     }
	//     return lista
	// }
	// const choosemember = () => {
	//     const { choosemember } = props
	//     var arr = []
	//     const data = choosemember?.filter(v => {
	//         v.name.filter(l => {
	//             return arr.push({ value: l?.given })
	//         })
	//     })
	//     console.log(data)
	//     return arr.length > 0 ? arr : []
	// }
	const {
		labelStyle,
		selectBorderStyle, errorselectBorderStyle
		// inputBorderStyle
	} = styles;
	// const { walkin } = props
	const { parent_id } = props;

	return (
		<Div id={`${parent_id}_refFrom_parent_div`} className="pv-root">
			<Paper id={`${parent_id}_refFrom_parent_paper`} className="pl-root">
				<Row id={`${parent_id}_refFrom_parent_row`} className="en-actions-root">
					<Col
						id={`${parent_id}_refFrom_parent_col`}
						md={12}
						lg={12}
						sm={12}
						xs={12}
						className="no-padding-left"
					>
						<Div id={`${parent_id}_refFrom_title_div`}>
							<H6
								id={`${parent_id}_refFrom_label_h6`}
								className="en-title space-padding-bottom"
							>
								REFERRED FROM
							</H6>
						</Div>
						<Row id={`${parent_id}_refFrom_row`} className="en-actions-root">
							<Col
								id={`${parent_id}_refFrom_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_refFrom_from_select`}
									placeholder="select..."
									search
									options={[
										{ value: "Logged in Facility" },
										{ value: "enterprise" },
										{ value: "partner" },
										{ value: "external" },
									]}
									isRequired
									// helperText={
									// 	from?.error && from?.error["from"] ? "Is required" : ""
									// }
									error={from?.error && from?.error["from"] ? true : false}
									value={from.from?.value ?? null}
									onChangeValue={(e) => setDetails("from", e)}
									label="From"
									labelStyle={labelStyle}
									inLineStyles={from?.error && from?.error["from"] ? errorselectBorderStyle : selectBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
							<Col
								id={`${parent_id}_refFrom_partner_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_refFrom_partner_select`}
									isRequired
									// helperText={
									// 	from?.error && from?.error["facility_type"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										from?.error && from?.error["facility_type"] ? true : false
									}
									search
									placeholder={
										from.from?.value === "partner" ||
											from.from?.value === "external"
											? "Organization"
											: "Facility type"
									}
									options={props.facility_type}
									value={from.facility_type?.value}
									onChangeValue={(e) => setDetails("facility_type", e)}
									label={
										from.from?.value === "partner" ||
											from.from?.value === "external"
											? "Organization"
											: "Facility Type"
									}
									labelStyle={labelStyle}
									inLineStyles={from?.error && from?.error["facility_type"] ? errorselectBorderStyle : selectBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
							<Col
								id={`${parent_id}_refFrom_facility_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_refFrom_facility_select`}
									isRequired
									// helperText={
									// 	from?.error && from?.error["facility_name"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										from?.error && from?.error["facility_name"] ? true : false
									}
									disabled={!props.referralData?.from?.facility_type}
									search
									placeholder="Facility name"
									options={facility_name}
									value={from.facility_name?.value}
									onChangeValue={(e) => setDetails("facility_name", e)}
									label="Facility Name"
									labelStyle={labelStyle}
									inLineStyles={from?.error && from?.error["facility_name"] ? errorselectBorderStyle : selectBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
							<Col
								id={`${parent_id}_refFrom_specialty_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_refFrom_specialty_select`}
									// helperText={
									// 	from?.error && from?.error["specialty"] ? "Is required" : ""
									// }
									error={from?.error && from?.error["specialty"] ? true : false}
									// options={FlterOptions('SPECIALTY')}
									options={getspeciality}
									disabled={!props.referralData?.from?.facility_name}
									placeholder="select..."
									value={from.specialty?.value}
									onChangeValue={(e) => setDetails("specialty", e)}
									label="Specialty"
									showArrow
									labelStyle={labelStyle}
									inLineStyles={
										// from?.error && from?.error["specialty"] ? errorselectBorderStyle :
									 selectBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
							<Col
								id={`${parent_id}_refFrom_practioner_type_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_refFrom_practioner_type_select`}
									options={practitioner_role}
									disabled={!props.referralData?.from?.specialty}
									// options={[
									//     { label: "PR001", value: "Physician " },
									//     { label: "PR002", value: "Surgeon" },
									//     { label: "PR003", value: "Consultant" },
									//     { label: "PR004", value: "Assistant" },
									//     { label: "PR005", value: "Paediatrician" },
									//     { label: "PR006", value: "Nephrologist" },
									//     { label: "PR007", value: "Neurologist" },
									//     { label: "PR008", value: "Cardiologist" },
									//     { label: "PR009", value: "Medical Administrator" },
									//     { label: "PR010", value: "Midwife" },
									//     { label: "PR011", value: "Matron" },
									//     { label: "PR012", value: "Radiologist" },
									//     { label: "PR013", value: "Nursing Officer" },
									//     { label: "PR014", value: "Student Nurse" },
									//     { label: "PR015", value: "Intern" },
									//     { label: "PR016", value: "Dermatologist" },
									//     { label: "PR017", value: "Anaesthetist" },
									//     { label: "PR018", value: "LAB Technician" },
									//     { label: "PR019", value: "Orthopaedician" },
									//     { label: "PR020", value: "Urologist" },
									// ]}
									placeholder="select..."
									// helperText={
									// 	from?.error && from?.error["practitioner_type"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										// from?.error && from?.error["practitioner_type"]
										// 	? true
										// 	: 
											false
									}
									value={from.practitioner_type?.value}
									onChangeValue={(e) => setDetails("practitioner_type", e)}
									label="Practitioner Type"
									showArrow
									labelStyle={labelStyle}
									inLineStyles={
										// from?.error && from?.error["practitioner_type"] ? errorselectBorderStyle : 
										selectBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
							<Col
								id={`${parent_id}_refFrom_practioner_col`}
								md={2}
								lg={2}
								sm={12}
								xs={12}
								className="no-padding-left"
							>
								<Select
									id={`${parent_id}_refFrom_practioner_select`}
									options={practitioner_name}
									disabled={!props.referralData?.from?.specialty}
									// options={[
									//     { label: "PT001", value: "Doctor" },
									//     { label: "PT002", value: "Nurse" },
									//     { label: "PT003", value: "Pharmacist" },
									//     { label: "PT004", value: "Technician" },
									//     { label: "PT005", value: "Researcher" },
									//     { label: "PT006", value: "Teacher" },
									//     { label: "PT007", value: "ICT professional" },
									// ]}
									placeholder="select..."
									// helperText={
									// 	from?.error && from?.error["practitioner"]
									// 		? "Is required"
									// 		: ""
									// }
									error={
										// from?.error && from?.error["practitioner"] ? true : 
										false
									}
									value={from.practitioner?.value}
									onChangeValue={(e) => setDetails("practitioner", e)}
									label="Practitioner"
									showArrow
									labelStyle={labelStyle}
									inLineStyles={
										// from?.error && from?.error["practitioner"] ? errorselectBorderStyle : 
										selectBorderStyle}
									hoverColor={UIColor.primaryColor.color}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Paper>
		</Div>
	);
}

RefFrom.contextType = AlertContext;
const mapStateToProps = (state) => ({
	oraganizationRegisterPatient:
		state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
});
export default connect(
	mapStateToProps,
	actions
)(withRouter(withQueue(withAllContexts(RefFrom))));
