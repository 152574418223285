import React from "react";
import CommonTable from "./table";
import { Grid, Dialog, Typography, Paper } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from "react-redux";

const tableHeader = [
    { label: "Name" },
    { label: "MRN" },
    { label: "Age" },
    { label: "Appointment Ref No" },
    { label: "Appt Date Time" },
    { label: "Encounter No" },
    { label: "Visit Type" },
    { label: "Reason of Failure" }
]


const tableData = [
    { label: "name", type: "TEXT" }, 
    { label: "MRN", type: "TEXT" }, 
    { label: "age", type: "TEXT" }, 
    { label: "appointmentId", type: "TEXT" },
    { label: "aptdate", type: "TEXT" },
    { label: "id", type: "TEXT" }, 
    { label: "visit_type", type: "TEXT" }, 
    { label: "curStatus", type: "TEXT" }, 
]

// const Data = [
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
//     { "date": "10-01", "tasnoshow": 12, "tasfailed": 12, "tesclosed": 12, "tesFailed": 12, "status": 12, "reasonForFailed": 12 },
// ]

function DailyDashboard(props) {

    const [data, setData] = React.useState(null)
    const dayList = useSelector((state) => state?.dayEndProcessSlice?.dayBasedPractitionerList);
    React.useEffect(() => {
        setData(props.data)
    }, [props.data])

    return <Grid container md="12">
        <Grid md="12" style={{ padding: "22px 20px 20px" }}>
            <CommonTable
                Header={tableHeader}
                tableData={tableData}
                dataList={dayList?.data ?? []}
                // isSelect
            />
            {/* {JSON.stringify(dayList?.data)} */}
        </Grid>
    </Grid>
}

export default DailyDashboard;