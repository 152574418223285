/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date 2020-12-01
 * @modify date 2021-04-27
 * @desc Material UI MuIAlert and Snackbar is used to show the temporary message Component
 */
import React from "react";
import { Row, Col, Text, Div, Select, TapSelect, TextInput, Button } from 'qdm-component-library';
import { Popover } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { SelectBox } from "../../../components";
import { useSelector } from 'react-redux'
import { UIColor } from "../../../utils";
import { withAllContexts } from "../../../HOCs";
import { AlertProps } from "../../../utils";

const severityOption = [
    { value: "Severe", label: "Severe" }, { value: "Moderate", label: "Moderate" }, { value: "Mild", label: "Mild" }
]

const useStyles = makeStyles((theme) => ({
    muiPopovers: {
        boxShadow: '0px 10px 75px #0000003D',
        borderRadius: 8
    }
}));

const AddEditReactionForm = (props) => {
    const allergiesData = useSelector((state) => state?.allergyMastersSlice);
    const classes = useStyles();
    const styles = {
        userPic: {
            width: 34,
            height: 34,
            marginLeft: 12,
            fontSize: 14,
            borderRadius: 4
        },
        subText: {
            fontSize: 12,
            color: UIColor.secondaryText.color
        },
        text: {
            fontSize: 14
        },
        headerText: {
            fontSize: 16
        },
        flexEnd: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
        },
        flexStart: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        backIcon: {
            background: UIColor.lineBorderFill.color,
            padding: "9px 13px 9px 11px",
            borderRadius: "50px",
            display: "flex",
            marginRight: 14,
            cursor: "pointer"
        },
        headBorder: {
            borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
            padding: "6px 0px"
        },
        labelStyle: {
            color: UIColor.tertiaryText.color,
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: UIColor.lineBorderFill.color,
            color: UIColor.secondaryText.color
        },
        colPadding: {
            padding: "7px"
        },
        tapSelectStyle: {
            padding: "4px 8px",
            borderColor: UIColor.lineBorderFill.color,
            margin: "4px 10px 6px 0px",
            borderRadius: "8px",
            minWidth: "auto",
            fontSize: "14px",
        },
        buttonTextStyle: {
            color: UIColor.primaryColor.color,
            fontWeight: 600,
            padding: "4px"
        },
        flexBetween: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        cancelBtn: {
            padding: "4px 10px",
            fontSize: 14,
            marginRight: 10,
            borderColor: UIColor.lineBorderFill.color,
            color: UIColor.primaryColor.color,
            borderRadius: 8
        },
        filledBtn: {
            backgroundColor: UIColor.primaryColor.color,
            borderColor: UIColor.primaryColor.color,
            padding: "4px 10px",
            fontSize: 14,
            borderRadius: 8
        },
        filledBtnDisable: {
            backgroundColor: "#aeaeae",
            borderColor: "#aeaeae",
            padding: "4px 10px",
            fontSize: 14,
            borderRadius: 8
        }
    }
    const openPopover = (e) => {
        props.openPopover(e?.currentTarget ?? null)
        setState({})
    }

    const [state, setState] = React.useState({});

    React.useLayoutEffect(() => {
        if (props.addEditReaction.isEditState) {
            setState(props.addEditReaction.isEditState);
        }
    }, [props.addEditReaction])

    const handleInputChange = (selector, val) => {
        setState({
            ...state,
            [selector]: val
        })
    };

    const [isErr,setIsErr] = React.useState(false)
    const handleSave = () => {
        if(state?.description?.length > 0 && state?.severity?.length > 0){
            props.reactionSave(state, props?.addEditReaction?.isEditState ? true : false);
            setState({})
        }else{
            setIsErr(true)
            props.alert.setSnack({
                ...props.alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please fill all the mandatory fields",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
        }

    }

    const { parent_id } = props;
    return (
        <>
            {/* edit or add new reactions */}
            <Popover
                anchorEl={props.addEditReaction.open}
                open={Boolean(props.addEditReaction.open)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                classes={{ paper: classes.muiPopovers }}
            >
                <Div
                    id={`${parent_id}_addedit_parent_div`}
                    inLineStyles={{ padding: 7 }}>
                    <Row
                        id={`${parent_id}_addedit_parent_row`}
                        style={{ padding: 7 }}>
                        <Col
                            id={`${parent_id}_addedit_description_col`}
                            xs={12} sm={12} md={4} lg={4} xl={4} inLineStyles={styles.colPadding}>
                            <SelectBox
                                parent_id={'addedit'}
                                requireStart
                                top_title={"Reaction Code & Description"}
                                list={allergiesData?.reaction_master?.data ?? []}
                                placeholder={"Search here..."}
                                value={allergiesData?.reaction_master?.data.filter(d => d.label === state?.description)?.[0]}
                                onchange={(e, value) => handleInputChange("description", value?.label)}
                                error={(isErr && !state?.description) ? true : false}
                            />
                        </Col>
                        <Col
                            id={`${parent_id}_addedit_severity_col`}
                            xs={12} sm={12} md={4} lg={4} xl={4} inLineStyles={styles.colPadding}>
                            <Text inLineStyles={styles.labelStyle} >{"Severity"}<span style={{color: UIColor.error.color}}>*</span></Text>
                            <TapSelect
                                id={`${parent_id}_addedit_severity_tapselect`}
                                label={""}
                                inLineStyles={styles.tapSelectStyle}
                                selectedBorderColor={UIColor.secondaryButtonColor.color}
                                selectedBackgroundColor={UIColor.secondaryButtonColor.color}
                                selectedTextColor={"#fff"}
                                textSize={"14px"}
                                options={severityOption}
                                value={state?.severity}
                                defaultSelected={{ value: state?.severity }}
                                onChange={(value) => handleInputChange("severity", value?.value)}
                            />
                            {isErr && !state?.severity && (
                                    <Text
                                        style={{ color: 'red', fontSize: 12 }}>
                                        {'Is required'}
                                    </Text>
                            )}
                        </Col>
                        <Col
                            id={`${parent_id}_addedit_substance_col`}
                            xs={12} sm={12} md={4} lg={4} xl={4} inLineStyles={styles.colPadding}>
                            <SelectBox
                                parent_id={'addedit'}
                                top_title={"Substance Code"}
                                list={allergiesData?.substance_code_master?.data ?? []}
                                placeholder={"Search here..."}
                                value={allergiesData?.substance_code_master?.data.filter(d => d.label === state?.substance)?.[0]}
                                onchange={(e, value) => handleInputChange("substance", value?.label)}
                            />
                        </Col>
                        <Col
                            id={`${parent_id}_addedit_exposureroute_col`}
                            xs={12} sm={12} md={4} lg={4} xl={4} inLineStyles={styles.colPadding}>
                            <SelectBox
                                parent_id={'addedit'}
                                top_title={"Route"}
                                list={allergiesData?.reaction_route_master_a?.data ?? []}
                                placeholder={"Search here..."}
                                value={allergiesData?.reaction_route_master_a?.data.filter(d => d.label === state?.exposureroute)?.[0]}
                                onchange={(e, value) => handleInputChange("exposureroute", value?.label)}
                            />
                        </Col>
                        <Col
                            id={`${parent_id}_addedit_note_col`}
                            xs={12} sm={12} md={8} lg={8} xl={8} inLineStyles={styles.colPadding}>
                            <TextInput
                                id={`${parent_id}_addedit_note_textinput`}
                                label={"Note"}
                                placeholder="Add note"
                                labelStyle={styles.labelStyle}
                                style={styles.borderStyle}
                                hoverColor={UIColor.lineBorderFill.color}
                                name="notes"
                                value={state?.note}
                                onChange={(event) => handleInputChange("note", event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row
                        id={`${parent_id}_addedit_buttons_row`}
                    >
                        <Col
                            id={`${parent_id}_addedit_buttons_col`}
                            xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                            <Div
                                id={`${parent_id}_addedit_buttons_div`}
                                inLineStyles={styles.flexEnd}>
                                <Button
                                    id={`${parent_id}_addedit_Cancel_buttons`}
                                    onClick={() => openPopover(null)} variant={"text"} inLineStyles={styles.cancelBtn} >Cancel</Button>
                                {/* {(state?.description?.length > 0 && state?.severity?.length > 0) ?
                                    <Button
                                        id={`${parent_id}_addedit_${props?.addEditReaction?.isEditState ? "Update" : "Save"}_buttons`}
                                        variant={"outlined"} inLineStyles={styles.filledBtn} onClick={handleSave} >{props?.addEditReaction?.isEditState ? "Update" : "Save"}</Button> 
                                        :
                                    <Button
                                        id={`${parent_id}_addedit_${props?.addEditReaction?.isEditState ? "Update" : "Save"}_buttons`}
                                        variant={"outlined"} inLineStyles={styles.filledBtnDisable}>{props?.addEditReaction?.isEditState ? "Update" : "Save"}</Button>
                                } */}
                                   <Button
                                        id={`${parent_id}_addedit_${props?.addEditReaction?.isEditState ? "Update" : "Save"}_buttons`}
                                        variant={"outlined"} inLineStyles={styles.filledBtn} onClick={handleSave} >{props?.addEditReaction?.isEditState ? "Update" : "Save"}</Button> 
                            </Div>
                        </Col>
                    </Row>
                </Div>
            </Popover>
        </>
    );
};

export default withAllContexts(AddEditReactionForm);