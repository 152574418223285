import React, { useEffect } from "react";
import { H6, Card, Div } from "qdm-component-library";
import BarChart from "./barChart";
import DoughnutChart from "./doughnutChart";
import PolarChart from "./polarChart";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import barIcon from "../../../assets/img/icons8-bar-chart.svg";
import polarIcon from "../../../assets/img/icons8-chart.svg";
import doughnutIcon from "../../../assets/img/icons8-doughnut-chart.svg";

import barIcon_black from "../../../assets/img/3 Black.svg";
import polarIcon_black from "../../../assets/img/1 Black.svg";
import doughnutIcon_black from "../../../assets/img/2 Black.svg";
import barIcon_blue from "../../../assets/img/3 Blue.svg";
import polarIcon_blue from "../../../assets/img/1 Blue'.svg";
import doughnutIcon_blue from "../../../assets/img/2 Blue.svg";
import { actions } from "primarycare-binder";
import { getUserInfo, getUtcTime, permission } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setDefaultLocale } from "react-datepicker";
import moment from "moment";

const casbinjs = require("casbin.js");
//import BarChart from "./barChart";

const PatientWaitingTime = (props) => {
  const styles = {
    card: {
      boxShadow: "0px 10px 25px #0000000A",
      border: "1px solid #E0E0E0",
      borderRadius: 8,
      padding: 12,
      height: "304px",
    },
  };
  // const data = {
  //   labels: ["Patient Count"],
  //   datasets: [
  //     {
  //       label: "Pending Collection",
  //       data: [65, 80],
  //       barPercentage: 0.7,
  //       backgroundColor: ["#FF9F40", "#FF9F40"],
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "Collected",
  //       data: [55, 40],
  //       barPercentage: 0.7,
  //       backgroundColor: ["#4BC0C0", "#4BC0C0"],
  //       borderWidth: 1,
  //     },
  //     {
  //       label: "Pending Bills",
  //       data: [45, 50],
  //       barPercentage: 0.7,
  //       backgroundColor: ["#FF6384", "#FF6384"],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  const [alignment, setAlignment] = React.useState("1");
  const [DATA, setData] = React.useState([0, 0, 0])
  const dispatch = useDispatch();
  const loggedUserInfo = useSelector(
    (state) => state?.authSlice?.loggedUserInfo
  );


  const data = {
    labels: ["Less than 15 mins", "More than 15 mins", "More than 30 mins"],
    datasets: [
      {
        data: DATA,
        backgroundColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
        ],
        cutout: 60,
      },
    ],
  };
  const polardata = {
    labels: ["Less than 15 mins", "More than 15 mins", "More than 30 mins"],
    datasets: [
      {
        //label: "My First Dataset",
        data: DATA,
        backgroundColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
        ],
      },
    ],
  };
  const barData = {
    labels: ["Less than 15 mins", "More than 15 mins", "More than 30 mins"],
    datasets: [
      {
        data: DATA,
        backgroundColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
        ],
        cutout: 60,
      },
    ],
  };



  const getData = async () => {
    const userData = await fetchUserInfo();
    //const orgID = await dispatch(actions.GET_____ORGANIZATION({ id: userData?.orgId || 0 }))
    const list = props?.Permission?.write ?? [];
    let roleId = list.indexOf("patientwaitingtimeD") > -1 ? 1 : list.indexOf("patientwaitingtimeN") > -1 ? 2 : 3
    let payload = {
      //orgId: userData?.orgId || 0,
      orgId: userData?.org_id,
      roleId: roleId,
      loginId: userData?.practionerId,
      startDate: getUtcTime(moment().startOf("day")),
      endDate: getUtcTime(moment().endOf("day")),
    };
   
    let data = await dispatch(actions.PATIENT_WAITING_TIME(payload));
    if (Array.isArray(data?.payload?.data)) {
      setData(data?.payload?.data)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const authorizer = new casbinjs.Authorizer("manual", permission);
  authorizer.setPermission(permission);

  const fetchUserInfo = async (email) => {
    if (!loggedUserInfo?.data?.orgId) {
      if (!email) {
        const info = getUserInfo();
        email = info.email;
      }
      const userData = await dispatch(
        actions.SEARCH_PRACTIONER({
          email,
        })
      );
     
      const info = {
        orgId: userData?.payload?.data?.[0]?.OrgID?.[0],
        name: {
          given: userData?.payload?.data?.[0]?.name?.[0]?.given,
          family: userData?.payload?.data?.[0]?.name?.[0]?.family,
          text: userData?.payload?.data?.[0]?.name?.[0]?.text,
        },
        personId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        practionerId: userData?.payload?.data?.[0]?.practitioner?.[0]?.id,
        role: userData?.payload?.data?.[0]?.practitioner?.[0]?.PractitionerRole[0]?.code[0]?.coding?.[0]?.display?.toLowerCase(),
        org_id: userData?.payload?.data?.[0]?.org_id,
      };

      await dispatch(actions.LOGGED_USER_INFO(info));
      return info;
    }
    return loggedUserInfo?.data;
  };

  const handleAlignment = (event, newAlignment) => {
    ;
    let e = event.target.value;
    console.log("newAlignment  ", newAlignment);
    console.log("alignment  ", alignment);
    if (newAlignment !== null) {
      if (alignment !== newAlignment) {
        setAlignment(newAlignment);
      }
    }
  };
  return (
    <Card style={styles.card}>
      <Div style={{ display: "flex", justifyContent: "space-between" }}>
        <H6
          className="pc_medium"
          style={{ margin: 0, marginBottom: 10, fontSize: 14 }}
        >
          Patient Waiting Time
        </H6>
        {/* <H6
          className="pc_medium"
          style={{ margin: 0, marginBottom: 10, fontSize: 14 }}
        >
          Patient Waiting Time
        </H6> */}

        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="chart"
        >
          <ToggleButton value="1" aria-label="DoughnutChart">
            <img src={alignment === "1" ? doughnutIcon_blue : doughnutIcon_black} alt="doughnutIcon" />
          </ToggleButton>
          <ToggleButton value="2" aria-label="DoughnutChart">
            <img src={alignment === "2" ? polarIcon_blue : polarIcon_black} alt="doughnutIcon" />
          </ToggleButton>
          <ToggleButton value="3" aria-label="DoughnutChart">
            <img src={alignment === "3" ? barIcon_blue : barIcon_black} alt="doughnutIcon" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Div>
      {/* <BarChart data={data} label="" /> */}
      {alignment === "1" && <DoughnutChart data={data} type={"Patient Waiting Time"} label="" />}
      {alignment === "2" && <PolarChart data={polardata} label="" />}
      {alignment === "3" && <BarChart data={barData} label="" />}
    </Card>
  );
};

export default PatientWaitingTime;
