import React from 'react';
import { WebDataRocksTable } from '../../../../components';
import '../index.css';
export const PendingTaskReport = (props) =>{
   
    const REPORT = (data) => {
        return{
            dataSource:{
                dataSourceType:'json',
                data:data
            },
            options:{
                grid:{
                    type:'flat',
                    showTotals: "off",
                    showGrandTotals: "off",
                },
            },
        }
    }

    const [state,setState] = React.useState({
        report:props?.report?.length ? REPORT(props?.report) :  []
    })

    React.useEffect(()=>{
        setState({
            ...state,report:props?.report?.length ? REPORT(props?.report) :  []
        })
    },[props?.report])


    const customizeCellFunction = (cellBuilder, cellData) =>{
        if (cellData?.measure?.uniqueName === "contact") {
            cellBuilder.text=`
            <div class="labelStyle">
                ${(cellData?.label).replace(/"/g, "")}
            </div>`
        } else if(
            (
                cellData?.measure?.uniqueName === "View Profile" ||
                cellData?.measure?.uniqueName === "Book Appointment" 
                ) && 
                cellData?.label === "true"
            ){
            cellBuilder.text=`
                            <div class='viewDiv'>
                               ${cellData?.measure?.uniqueName}
                            </div>
                        `
        }
        else if(cellData?.measure?.uniqueName === "img" && cellData?.type !== "header"){
                cellBuilder.addClass("imgDiv");
                cellBuilder.text=`
                <div class='centerDiv'>
                    <div class='imgWrapper'>
                        <img src=${cellData?.member?.caption} class='img' />
                    </div>
                </div>
            `
        }
        else if(cellData?.type !== "header"){
            cellBuilder.addClass("imgDiv");
            cellBuilder.text=`<div class='labelStyle'>
                ${cellData?.label}
            </div>`;

        }
    }


    const cellClick = (data) =>{
    }

    return(
        <div>
            <WebDataRocksTable
                report={state?.report}
                customizeCellFunction={customizeCellFunction}
                cellClick={(data)=>cellClick(data)}
            /> 
        </div>
    )
}