import React from "react";
import { Row, Col, Div } from "qdm-component-library";
import {
	TopNavbar,
	PatientListView,
	EncounterInfo,
	Pactitioner,
	DeviceDetails,
	ReasonDetails,
	BookingDetails,
	PatientStatusCard,
	TopHeader,
	LoadingComp
} from "../../../components";
import { patientModifyTitle } from "../../../utils/routesList";
import { Routes } from "../../../router/routes";
import { withQueue, withAllContexts } from "../../../HOCs";
import { withRouter } from "react-router-dom";
import {
	AlertProps,
	checkError,
	fetchQueueData,
	makeQueueData,
	putOnHold,
	getUserInfo,
	uploadImage,
	fetchUserInfo,
	utcTOLocal,
} from "../../../utils";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { AlertContext } from "../../../contexts";
import moment from "moment";
import { checkWithCasbin } from "../../../utils/permissonHandling";
class PatientVisitModify extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeId: 1,
			allMasters: [],
			Persondata: [],
			choosemember: [],
			appointmentData: [],
			appointmentvisit: [],
			encount: {},
			loader: true
		};
		this.successfun = this.successfun.bind(this);
		this.setActive = this.setActive.bind(this);
		this.fetchQueueData = fetchQueueData.bind(this);
		this.putOnHold = putOnHold.bind(this);
		this.fetchUserInfo = fetchUserInfo.bind(this);
	}

	constructIndentifier = (data) => {
		// debugger;
		let identifier = [];
		if(Array.isArray(data) && data?.length  > 0){
			data.map((val) => {
				let obj = {
					user: val?.user ?? "",
					system: val?.system ?? "",
					value: val?.value ?? "",
					period: this.constructPeriod(val?.period ?? []),
					// period: val?.period ?? [],
					assigner: val?.assigner ?? "",
					Type: val?.Type ?? "",
					id: val?.id ?? 0,
					files: val?.files ?? []
				}
				identifier.push(obj);
			})
		}
		return identifier;	
	}
	constructPeriod = (data) => {
		let period = [];
		if(Array.isArray(data) && data?.length  > 0){
			data.map((val) => {
				let obj = {
					start: val?.start ?? 0,
					end: val?.end ?? 0,
					id: val?.id ?? 0
					// _id: val?._id ?? 0
				};
				period.push(obj)
			})
		}
		return period;
	}

	handleSubmit = async () => {
		const visitData = this.props.visitData.accompained;
		const Json = JSON.parse(JSON.stringify(this.state.encount));
		Json["accompanied_person"] = [
			{
				id_type: visitData?.id_type?.value ?? "",
				id: visitData?.id ?? "",
				name: visitData?.name ?? "",
				relationship: visitData?.relationship?.value ?? "",
			},
		];
		Json["revision_date"] = moment().unix();
		Json["cancel_date"] = 0;
		Json["reasonCode"] = visitData?.Reason?.value ?? "";
		Json["reasonReference"] = Json["reasonReference"]["_id"] ?? "";
		Json["identifier"] = this.constructIndentifier(Json["identifier"]);
		delete Json._key;
		delete Json.Encounter_id;
		delete Json.diagnosis;
		if (!Json.statushistory?.period?.length > 0) {
			Json["statushistory"] = [
				{
					status: "",
					period: [
						{
							_id: "",
							start: 0,
							end: 0,
							id: 0,
						},
					],
					reasoncode: "",
				},
			];
		}
		if (Json?.practitioner_details?.[0]?.speciality) {
			Json["practitioner_details"][0]["speciality"] =
				Json.practitioner_details[0].speciality._id;
		}
		if (Json?.reasonReference?._id) {
			Json.reasonReference = Json.reasonReference._id;
		}
		// Json['reasonReference'] = this.props.visitData?.encounter?.visit_reason?.value ?? '';
		// Json['practitioner_details'] = [
		//   {
		//     speciality: this.props.visitData?.practitioner?.speciality?.value ?? "",
		//     type: this.props.visitData?.practitioner?.type?.value ?? "",
		//     id_name: this.props.visitData?.practitioner?.id_name?.value ?? "",
		//     clinic: this.props.visitData?.practitioner?.clinic?.value ?? "",
		//   },
		// ]
		delete Json._rev;
		const data1 = [
			{
				doc: {
					...Json,
				},
			},
		];

		const res = await this.props.ORGANIZATION_VISIT_REGISTRATION({
			payloaddata: data1[0],
			enId: this.state.encount._id,
		});

		const status = checkError(this.props.oraganizationvisitRegister);
		if (status.isError) {
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: status.errMsg,
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			return;
		}
		if (res?.payload?.data?.validation_error || res.payload?.data?.error) {
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg:
					res?.payload?.data?.validation_error?.[0]?.Errormsg ||
					res.payload?.data?.id?.error,
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			return;
		} else {
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.success,
				msg: "Your visit has been modified !",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
			// this.props.history.goBack()
			this.props.history.push({
				pathname: Routes.confirmBooking,
				state: {
					visit: true,
					personId: this.props.location?.state?.personId,
					mobileNo: this.props.location?.state?.mobileNo,
					appointmentId: this.props.location?.state?.appointmentId,
					patientId: this.props.location?.state?.patientId,
					modfieded: true,
					reason: this.props.visitData.accompained?.Reason?.value ?? "",
				},
			});
		}
	};

	async successfun(files) {
		let UploadedFiles = [];
		UploadedFiles = await uploadImage(files);
		// ----------------------------------
		const visitform = this.props.visitData;
		console.log("visitform",visitform);
		if (!visitform.accompained?.Reason?.value) {
			visitform.accompained["error"] = {
				Reason: true,
				...visitform.accompained["error"],
			};
		}
		// if (!visitform.helthcare?.type?.value) {
		// 	visitform.helthcare["error"] = {
		// 	  type: true,
		// 	  ...visitform.helthcare["error"],
		// 	};
		//   }
		//   if (!visitform.helthcare?.category?.value) {
		// 	visitform.helthcare["error"] = {
		// 	  category: true,
		// 	  ...visitform.helthcare["error"],
		// 	};
		//   }
		//   if (!visitform.helthcare?.name?.value) {
		// 	visitform.helthcare["error"] = {
		// 		name: true,
		// 		...visitform.helthcare["error"],
		// 	};
		// }
		if (!visitform.practitioner?.speciality?.value) {
			visitform.practitioner["error"] = {
				speciality: true,
				...visitform.practitioner["error"],
			};
		}
		visitform.setVisitDetails({
			...visitform,
		});
		if (visitform.accompained?.Reason?.value
			// && visitform.helthcare?.type?.value
			// && visitform.helthcare?.category?.value
			// && visitform.helthcare?.name?.value
			&& visitform.practitioner?.speciality?.value) {
			this.handleSubmit(UploadedFiles);
		}else{
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: "Please fill all the mandatory fields",
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.right,
			});
		}
	} 

	async nextBtnFun() {
		const visitform = this.props.visitData;

		if (!visitform.encounter?.visit_reason) {
			visitform.encounter["error"] = {
				visit_reason: true,
				...visitform.encounter["error"],
			};
		}
		if (!visitform.encounter?.visit_type) {
			visitform.encounter["error"] = {
				visit_type: true,
				...visitform.encounter["error"],
			};
		}
		if (!visitform.helthcare?.type) {
			visitform.helthcare["error"] = {
				type: true,
				...visitform.helthcare["error"],
			};
		}
		if (!visitform.helthcare?.category) {
			visitform.helthcare["error"] = {
				category: true,
				...visitform.helthcare["error"],
			};
		}
		if (!visitform.helthcare?.name) {
			visitform.helthcare["error"] = {
				name: true,
				...visitform.helthcare["error"],
			};
		}
		visitform.setVisitDetails({
			...visitform,
		});
		if (
			visitform.helthcare?.name?.value &&
			visitform.helthcare?.category?.value &&
			visitform.helthcare?.type?.value &&
			visitform.encounter?.visit_type?.value &&
			visitform.encounter?.visit_reason?.value
		) {
			this.handleSubmit();
			// this.setState({ activeId: 2 })
		}
	}
	setActive(Id) {
		// this.setState({ activeId: Id })
	}

	styles = {
		outlinedButton: {
			borderColor: "#0071F2",
			color: "#0071F2",
			backgroundColor: "#fff",
			borderRadius: 8,
			padding: "8px 15px",
			minWidth: 100,
			fontFamily: "pc_medium !important",
			fontSize: 14,
		},
		containedButton: {
			backgroundColor: "#0071F2",
			borderColor: "#0071F2",
			borderRadius: 8,
			padding: "8px 15px",
			minWidth: 100,
			fontFamily: "pc_medium !important",
			fontSize: 14,
		},
	};

	getallmasters = async () => {
		const data = await this.props.MASTER_SEARCH({
			type: [
				"Level Of Care",
				"Department",
				"VISITTYPE",
				"Occupation",
				"NAMEPREFIX",
				"NAMESUFFIX",
				"Patient Type",
				"Race",
				"Ethnicity",
				"Religion",
				"RELATIONSHIP",
				"MARITALSTATUS",
				"LANGUAGE",
				"Town",
				"District",
				"State",
				"Country",
				"ALTERNATEIDTYPE",
				"Blood Group",
				"SPECIALTY",
				"Document Type",
				"ENCOUNTERTYPE",
				"ENCOUNTERCLASS",
				"STARTTYPE",
				// "Encounter Type","Encounter Class","Start type",
				"Reason",
				"SERVICECATEGORY",
				"SERVICETYPE",
				"Service",
			],
		});
		this.setState({
			allMasters: data?.payload?.data,
		});
	};

	appointmentread = async () => {
		const appointmentData_encounter =
			await this.props.APPOINTMENT_ID_GET_ENCOUNTE_DETAILS({
				appointmentId: this.props.location?.state?.appointmentId,
			});
		const dat = appointmentData_encounter.payload?.data?.[0];

		const appointmentData = await this.props.APPOINTMENT_READ({
			appointmentId: this.props.location?.state?.appointmentId,
		});
		const data = await this.props.ORGANIZATION_FILTER_BY_PATIENT({
			patientid: dat?.patient_id?.[0]?.id,
		});
		// this.props?.location?.state?.patientId
		// const appointment = this.props.GET_VISIT_APPOINTMENT({ id: appointmentData?.payload?.data[0]?._id })
		// const appointmentId_en = this.props.GET_APPOINTMENT_ENCOUNTER({ appointmentId: this.props.location?.state?.appointment_Id })
		const datas = await this.props.VISIT_READ({
			encounter_id: dat?._id,
		});

		if (datas.payload.data?.length > 0) {
			const setState = datas.payload.data?.[0];
			const visitData = this.props?.visitData;

			visitData.encounter.mode = setState.mode ? setState.mode : "";
			visitData.encounter.type = {
				value: setState?.type?.[0]?.display,
				label: setState?.type?.[0]?._id,
			};
			visitData.encounter.class = {
				value: setState?.class?.display,
				label: setState?.class?._id,
			};
			visitData.encounter.visit_type = {
				value: setState?.visit_type,
				label: setState?.visit_type,
			};
			visitData.encounter.start_type = {
				value: setState?.start_type, // mis
				label: setState?.start_type,
			};

			visitData.encounter.visit_reason = {
				value: setState?.reasonReference?.display,
				label: setState?.reasonReference?.display,
				_id: setState?.reasonReference?._id,
			};

			visitData.accompained.Reason = {
				value: setState?.reasonCode,
				label: setState?.reasonCode,
			};

			visitData.practitioner.type = {
				value: setState?.practitioner_details?.[0]?.type,
				label: setState?.practitioner_details?.[0]?.type,
			};
			visitData.practitioner.id_name = {
				value: setState?.practitioner_details?.[0]?.id_name,
				label: setState?.practitioner_details?.[0]?.id_name,
			};
			visitData.practitioner.speciality = {
				value:
					setState?.practitioner_details?.[0]?.speciality?.display,
				label:
					setState?.practitioner_details?.[0]?.speciality?.display,
				codeable_id: setState?.practitioner_details?.[0]?.speciality?._id,
				coding_id: setState?.practitioner_details?.[0]?.speciality?._id,
			};
			visitData.practitioner.clinic = {
				value: setState?.practitioner_details?.[0]?.clinic,
				label: setState?.practitioner_details?.[0]?.clinic,
			};
			visitData.practitioner.role = {
				value: setState?.practitioner_details?.[0]?.role, // mis
				label: setState?.practitioner_details?.[0]?.role,
			};
			// visitData.practitioner.speciality = {
			//   value: setState?.practitioner_details?.[0]?.speciality,    // mis
			//   label: setState?.practitioner_details?.[0]?.speciality,
			// }

			// mis
			console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%", setState)
			
			visitData.helthcare.category = {
				value: setState?.health_of_service?.[0]?.category,
				label: setState?.health_of_service?.[0]?.category,
			};
			visitData.helthcare.name = {
				value: setState?.health_of_service?.[0]?.name,
				label: setState?.health_of_service?.[0]?.name,
			};
			visitData.helthcare.type = {
				value: setState?.health_of_service?.[0]?.type,
				label: setState?.health_of_service?.[0]?.type,
			};

			visitData.accompained.id_type = {
				value: setState?.accompanied_person?.[0]?.id_type,
				label: setState?.accompanied_person?.[0]?.id_type,
			};

			visitData.accompained.id = setState?.accompanied_person?.[0]?.id ?? "";

			visitData.accompained.name =
				setState?.accompanied_person?.[0]?.name ?? "";

			visitData.accompained.relationship = {
				value: setState?.accompanied_person?.[0]?.relationship,
				label: setState?.accompanied_person?.[0]?.relationship,
			};

			visitData.setVisitDetails({
				...visitData,
			});
		}
		this.setState({
			Persondata: data?.payload?.data,
			appointmentData: appointmentData.payload.data,
			// appointmentvisit: appointment,
			encount: datas.payload.data?.[0],
		});
		console.log(data);
		const payload = {
			userid: this.props?.location?.state?.personId,
		};
		if (this.props.GET_RELATED_PERSON) {
			const data = await this.props.GET_RELATED_PERSON(payload);
			if (data?.payload?.data) {
				this.setState({
					choosemember: [...data?.payload?.data],
				});
			}
		}
	};
	async componentDidMount() {
		await this.appointmentread();
		this.getallmasters();
		if (
			(this.props.queueManagement?.data || []).length === 0 &&
			(this.props.queueManagement?.previousData || []).length === 0 &&
			(this.props.queueManagement?.onHoldData || []).length === 0
		) {
			await this.fetchQueueData();
		} else {
			const userData = await this.fetchUserInfo();
			const permissionA = await checkWithCasbin(["queueSideNav"]);
      		const permissionData = permissionA.write.find(d => d === "appointmentQueueDataN" || d === "appointmentQueueDataD" || d === "appointmentQueueDataF");
			const [, displayData, onHoldData, completedData] = makeQueueData(
				permissionData,
				this.props.queueManagement.allData,
				userData,
				false
			);
			this.props.queueManagement.setDisplayData(displayData);
			this.props.queueManagement.setOnHoldData(onHoldData);
			this.props.queueManagement.setPreviousData(completedData);
		}
		this.setState({
			...this.state,
			loader:false
		})
	}

	alertHold = () => {
		// this.context.setSnack({
		// 	open: true,
		// 	severity: AlertProps.severity.success,
		// 	msg: "Put on holded",
		// 	vertical: AlertProps.vertical.top,
		// 	horizontal: AlertProps.horizontal.right,
		// });
	};

	render() {
		const { activeId, encount } = this.state;
		const walkin =
			this.props?.location?.state?.walkin ||
			this.props?.location?.state?.Ispatient;
		const view = this.props?.location?.state?.role === "view";
		const { name } = getUserInfo();

		return (
			<Div
				id="Registervisit_visit_details_parent_div"
				className="light-background-color"
			>
				<TopHeader
					parent_id={'dashboard'}
					noHome={true} middleComponent={<></>} 
				/> 
				<TopNavbar
					view={view}
					newUser
					parent_id={"Registervisit_visit_details"}
					alertHold={this.alertHold}
					activeId={activeId}
					putOnHold={() =>
						this.putOnHold(
							"undefined",
							this.props.location?.state?.appointmentId
						)
					}
					navClick={this.setActive}
					successfun={this.successfun}
					//navBarArr={patientVisit}
					title={view ? "View Visit" : patientModifyTitle}
					viewYes={view}
					btn={view ? "View Visit" : "Revise Visit"}
				/>
				<div style={{
					height:'calc(100vh - 128px)', // 128px = 72px + 56px // 72 - topheader , 56 - topnav
					overflow:'auto',
					display: this.state.loader ? "flex" : "block",
					alignItems: "center",
					justifyContent:this.state.loader ? "center" : "unset"
					}}
				>
				<LoadingComp loading={this.state.loader}>
				<Div
					id="Registervisit_visit_details_patientlist_div"
					className="rv-list-view"
					inLineStyles={{ padding: 10 }}
				>
					{encount?._id && this.state.Persondata?.length > 0 ? (
						<Row id="financial_patientlist_row">
							{" "}
							<Col
								id="financial_patientlist_col"
								lg={6}
								md={6}
								inLineStyles={{ padding: 5 }}
							>
								<PatientListView
									parent_id={"Registervisit_visit_details"}
									Persondata={this.state.Persondata?.[0]}
									noright={true}
								/>
							</Col>
							<Col
								id="financial_patientstatus_col"
								lg={6}
								md={6}
								inLineStyles={{ padding: 5 }}
							>
								<PatientStatusCard
									parent_id={"Financial_Registration"}
									EncounterDetails={encount}
									pic={
										"https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
									}
									titles={[
										`${encount._id ?? ""}`,
										`${encount.type?.[0]?.display ?? ""}`,
										`${encount.visit_type ?? ""}`,
										`${
											// encount.practitioner_details?.[0]?.speciality?.coding?.[0]
											// 	?.display ?? ""
											encount.practitioner_details?.[0]?.speciality?.display ?? ""
										 }`,
									]}
									date={utcTOLocal(encount.encounterdate,"MMMM Do YYYY, h:mm a")}
									languages={this.state.Persondata?.[0]?.communicationLanguage ?
										 this.state.Persondata?.[0]?.communicationLanguage?.map((val,i)=> val?.display +
										((this.state.Persondata?.[0]?.communicationLanguage.length - 1) === i ? "" : ",")) : []} 
									padding
								/>
							</Col>
						</Row>
					) : (
						<>
							{" "}
							{this.state.Persondata?.length > 0 && (
								<PatientListView
									parent_id={"Registervisit_visit_details"}
									Persondata={this.state.Persondata?.[0]}
									noright={true}
								/>
							)}
						</>
					)}
				</Div>
				{activeId === 1 && (
					<Div
						id="Registervisit_visit_details_id_one_parent_div"
						style={{
							padding: "3px 15px",
						}}
					>
						<EncounterInfo
							parent_id={"Registervisit_visit_details"}
							Persondata={this.state.Persondata}
							allMasters={this.state?.allMasters}
							appointmentData={this.state.appointmentData}
							isEdit={true}
							encount={this.state.encount}
							walkin={walkin}
							type={"modify"}
							view={view}
						/>

						{walkin && (
							<BookingDetails
								parent_id={"Registervisit_visit_details"}
								name={name}
								personvisitedDataNames={
									this.props?.location?.state?.personvisitedDataNames?.[0] ?? {}
								}
								Persondata={this.state.Persondata}
								appointmentData={this.state.appointmentData}
							/>
						)}

						<Pactitioner
							parent_id={"Registervisit_visit_details"}
							Persondata={this.state.Persondata}
							// appointmentData={this.state.appointmentData}
							walkin={walkin}
							allMasters={this.state?.allMasters}
							// choosemember={this.state.choosemember}
							type={"modify"}
							view={view}
						/>

						<DeviceDetails
							parent_id={"Registervisit_visit_details"}
							walkin={walkin}
							appointmentData={this.state.appointmentData}
							allMasters={this.state?.allMasters}
							type={"modify"}
							view={view}
						/>
						{!view && (
							<ReasonDetails
								parent_id={"Registervisit_visit_details"}
								walkin={walkin}
								appointmentData={this.state.appointmentData}
								allMasters={this.state?.allMasters}
								label={"Reason for Revision"}
								view={view}
							/>
						)}
					</Div>
				)}
				</LoadingComp>
				</div>
			</Div>
		);
	}
}
PatientVisitModify.contextType = AlertContext;
const mapStateToProps = (state) => ({
	oraganizationRegisterPatient:
		state?.OrganizationRegisterPatientApiSlice?.oraganizationRegisterPatient,
	loggedUserInfo: state?.authSlice?.loggedUserInfo,
	visitGetAppointment: state?.VisitSlice?.visitGetAppointment,
});
export default connect(
	mapStateToProps,
	actions
)(withRouter(withQueue(withAllContexts(PatientVisitModify))));
