import React from "react";
import { Div, Paper, Select, TextInput, Upload, H6, Col, Row, Text, Icons, Image } from 'qdm-component-library'
import "./style.css"
import { withAllContexts } from "../../HOCs";
import { uploadImage } from '../../utils'
import { AlertProps } from "../../utils";
import moment from "moment";
import { UIColor } from "../../utils";
import CustomInputDatePicker from "../customDatePicker/inputDatePicker";

class PatientIdentification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limited: false
        }
    }
    styles = {
        labelStyle: {
            color: '#6F6F6F',
            fontSize: '12px',
            marginBottom: '6px',
            fontFamily: `pc_regular !important`,
        },
        borderStyle: {
            borderRadius: 8,
            borderColor: '#E0E0E0',
        },
        errorborderStyle:{
            borderRadius: 8,
            borderColor: 'red',
        },
        borderStyleDisable: {
            borderRadius: 8,
            border: "1px solid #E0E0E0",
            background: "#F0F0F0",
            // paddingBottom: 10,
        },
        denseBottom: {
            paddingBottom: 10,
        }
    }

    setData = (name, value, index, limit) => {
        if (limit) {
            if (limit < value.length) {
                return false
            }
        }
        let patientData = this.props[this.props.saveto]
        if (name === 'id_type') {
            patientData.identification[index]['id_number'] = '';
        }
        patientData.identification[index][name] = value;
        if (patientData.identification[index].error) {
            patientData.identification[index].error[name] = false;
        }
        patientData[this.props.setTo]({
            ...patientData,
        })
    }
    setDataUpload = async (name, value, index) => {
        var UploadedFiles = [];
        let patientData = this.props[this.props.saveto]
        UploadedFiles = await uploadImage([value])
        const dat = UploadedFiles?.map(v => {
            return {
                fileid: v.fileid,
                filetype: v.filetype,
                objectid: v.objectid,
                filename: v.fileName
            }
        })
        patientData.identification[index][name] = dat[0];
        if (patientData.identification[index].error) {
            patientData.identification[index].error[name] = false;
        }
        patientData[this.props.setTo]({
            ...patientData,
        })
    }
    newRowAdd = () => {
        let patientData = this.props[this.props.saveto]
        patientData.identification.push({})
        patientData[this.props.setTo]({
            ...patientData,
        })
    }

    getSize = (size) => {
        let fileSize = size / 1024 / 1024;
        if (fileSize < 1) {
            return `${parseInt(size / 1024) + 1} KB`
        } else {
            return `${parseInt(fileSize)} MB`
        }

    }
    handleDelete = (i) => {
        let patientData = this.props[this.props.saveto]
        if (patientData.identification.length <= 1) {
            this.props.alert.setSnack({
                open: true,
                severity: AlertProps.severity.error,
                msg: "Atleast one identification should be entered",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
            });
        } else {
            patientData.identification.splice(i, 1);
            patientData[this.props.setTo]({
                ...patientData,
            })
        }
    }
    FlterOptions = (type) => {
        const { allMasters } = this.props;
        var lista = []
        const data = allMasters?.[type]
        for (let index in data) {
            var datatemp = {
                "value": data[index]?.coding?.display,
                "code": data[index]?.coding?.code,
                "dis": data[index]?.Type,
                "_id": data[index]?.coding?._id,
            };
            lista.push(datatemp);
        }
        return lista
    }
    handleLimit = () => {
        this.setState({
            limited: !this.state.limited
        })
    }
    render() {
        const { identification,statusDetails } = this.props[this.props.saveto];
        const { labelStyle, borderStyle, errorborderStyle, borderStyleDisable, denseBottom } = this.styles;
        const { parent_id } = this.props;

        return <Div
            id={`${parent_id}_patientidentification_parent_div`}
            className="pi-root">
            <Paper
                id={`${parent_id}_patientidentification_parent_paper`}
                className="pi-paper">
                <Div
                    id={`${parent_id}_patientidentification_header_label_div`}
                >
                    <H6
                        id={`${parent_id}_patientidentification_label_h6`}
                        className="pi-title">{this.props.saveto === 'nextOfKin' ? 'NEXT OF KIN IDENTIFICATION' : 'PATIENT IDENTIFICATION'}</H6>
                    <Text
                        id={`${parent_id}_patientidentification_addnew_label_text`}
                        onClick={() => this.newRowAdd()} className="pi-addbtn">+ Add New
                    </Text>

                </Div>
                {identification?.slice(0, (this.state.limited ? (identification.length) : 1))?.map((val, i) => <Row
                    id={`${parent_id}_${i}_patientidentification_map_row`}
                >
                    <Col
                        id={`${parent_id}_${i}_patientidentification_ip_type_col`}
                        md={2} lg={2} sm={6} xs={12} className="no-padding-left" inLineStyles={{ padding: 10 }}>
                        <Select
                            id={`${parent_id}_${i}_patientidentification_id_type_select`}
                            value={val?.id_type?.value ?? null} onChangeValue={e => this.setData("id_type", e, i)}
                            isRequired={true}
                            // helperText={(val?.error && val?.error['id_type']) ? 'Is required' : ''}
                            disabled = {(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                            error={(val?.error && val?.error['id_type']) ? true : false}
                            options={this.FlterOptions('ALTERNATEIDTYPE')} label="ID Type" placeholder="select..." showArrow labelStyle={labelStyle} inLineStyles={(val?.error && val?.error['id_type'])  ? errorborderStyle : borderStyle} hoverColor={UIColor.primaryColor.color}
                        />

                    </Col>
                    <Col
                        id={`${parent_id}_${i}_patientidentification_id_number_col`}
                        md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                        <TextInput
                            id={`${parent_id}_${i}_patientidentification_id_number_textinput`}
                            // helperText={(val?.error && val?.error['id_number']) ? 'Is required' : ''}
                            error={(val?.error && val?.error['id_number']) ? true : false}
                            value={val.id_number ? val.id_number : ""} 
                            onChange={(e) => {
                                let pattern = /^[a-z0-9]+$/i
                                let result = pattern.test(e.target.value)
                                if(result)
                                {
                                    this.setData("id_number", e.target.value, i, 20)
                                } else if(e.target.value === ""){
                                    this.setData("id_number", "", i, 20)
                                }
                                
                            }}
                            label="ID Number"
                            isRequired={true}
                            disabled={!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased")} 
                            // isRequired={val?.id_type ? true : false}
                            placeholder="Id number"
                            labelStyle={labelStyle}
                            style={!val?.id_type?.value ? borderStyleDisable : (val?.error && val?.error['id_number']) ?  errorborderStyle : borderStyle }
                            hoverColor={UIColor.primaryColor.color}
                        />

                    </Col>
                    <Col
                        id={`${parent_id}_${i}_patientidentification_expirydate_col`}
                        md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 6 }}>
                         {/* <TextInput
                            id={`${parent_id}_${i}_patientidentification_expirydate_calender_textinput`}
                            max={moment().startOf("day").add(100, "years").format("YYYY-MM-DD")}
                            min={moment().startOf("day").format("YYYY-MM-DD")}
                            // onKeyPress={function (event) {
                            //     event.preventDefault();
                            //     return
                            // }} 
                            helperText={'Invalid Expiry Date'}
                            disabled={(!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"))}
                            error={val.expiry_date && ((moment(val.expiry_date, "YYYY-MM-DD").diff(moment().startOf("day"), 'years') > 100) || (moment().startOf("day").unix() > moment(val.expiry_date, "YYYY-MM-DD").unix()))}
                            value={val.expiry_date ? val.expiry_date : ""}
                            onChange={(e) => this.setData("expiry_date", e.target.value, i)}
                            label="Expiry Date"
                            type="date"
                            dateIcon="images/icons8-calendar-grey.svg"
                            labelStyle={labelStyle} 
                            style={ !val?.id_type?.value ? borderStyleDisable : borderStyle }
                            hoverColor={UIColor.primaryColor.color}
                        />  */}
                        <CustomInputDatePicker 
                            disabled= {(!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"))}
                            placeholderText="dd/mm/yyyy"
                            // minDate={moment().startOf("day").format("YYYY-MM-DD")}
                            // maxDate={moment().startOf("day").add(100, "years").format("YYYY-MM-DD")}
                            minDate={new Date()}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear()+100))}
                            handleChange={(value) => this.setData("expiry_date", value, i)}
                            selectedDate={val.expiry_date ? new Date(val.expiry_date) : ""}
                            // className={denseBottom}
                            inputField={
                                <TextInput 
                                    id={`${parent_id}_${i}_patientidentification_expirydate_calender_textinput`}
                                    label="Expiry Date"
                                    isRequired={false}
                                    placeholder="dd/mm/yyyy"
                                    dateIcon="images/icons8-calendar-grey.svg"
                                    labelStyle={labelStyle} 
                                    value={val.expiry_date ? moment(new Date(val.expiry_date)).format("DD/MM/YYYY") : ""}
                                    style={ !val?.id_type?.value ? borderStyleDisable : (val?.error && val?.error?.expiry_date) ? errorborderStyle : borderStyle }
                                    hoverColor={UIColor.primaryColor.color}
                                    helperText={'Invalid Expiry Date'}
                                    error={(val?.error && val?.error?.expiry_date) ? true : false}
                                    // error={true}
                                    disabled={(!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"))}
                                />}
                        />

                    </Col>

                    {this.props.saveto !== "nextOfKin" && <Col
                        id={`${parent_id}_${i}_patientidentification_id_source_col`}
                        md={2} lg={2} sm={6} xs={12} inLineStyles={{ padding: 10 }}>
                        <Select
                            id={`${parent_id}_${i}_patientidentification_id_source_select`}
                            value={val?.id_source?.value ?? null}
                            // helperText={(val?.error && val?.error['id_source']) ? 'Is required' : ''}
                            error={(val?.error && val?.error['id_source']) ? true : false}
                            onChangeValue={(e) => this.setData("id_source", e, i)}
                            options={this.FlterOptions('RELATIONSHIP')}
                            showArrow
                            isRequired={true}
                            disabled={(!val?.id_type?.value || (statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased"))}
                            //  isRequired={val?.id_type ? true : false}
                            label="ID Source"
                            placeholder="select..."
                            labelStyle={labelStyle}
                            inLineStyles={(val?.error && val?.error['id_source']) ? errorborderStyle : borderStyle}
                            hoverColor={UIColor.primaryColor.color} />
                    </Col>}
                    <Col
                        id={`${parent_id}_${i}_patientidentification_sub_parent_col`}
                        md={4} lg={4} sm={12} xs={12} className="pi-upload-root" 
                        inLineStyles={{ padding: 10, alignItems:!val.doc ?  null :"baseline" }}>
                        {!val.doc ? <Div
                            id={`${parent_id}_${i}_patientidentification_document_div`}
                            style={{ display: "flex",alignItems:"end",marginBottom: "1%" }}>
                            <Upload
                                id={`${parent_id}_${i}_patientidentification_document_upload`}
                                label="Upload Document"
                                className="pi-upload-btn"
                                handleChange={(e, value) => this.setDataUpload("doc", value, i)}
                                labelColor={UIColor.secondaryButtonColor.color}
                                iconColor={UIColor.secondaryButtonColor.color}
                                iconSize={14}
                                imgUrl="images/uploadBlue.svg"
                                labelStyle={{ fontSize: 14, fontFamily: 'pc_medium !important' }}
                                disabled={(statusDetails?.status1 === "Inactive" || statusDetails?.status === "Deceased") ? true : false}
                            />
                            <Div
                                id={`${parent_id}_${i}_patientidentification_document_upload_div`}
                                style={{
                                    width: 15,
                                    cursor: 'pointer'
                                }}>
                                <Image
                                    id={`${parent_id}_${i}_patientidentification_delete_image`}
                                    src="images/icons8-trash1.svg" alt="delete" onClick={() => this.handleDelete(i)}
                                />
                            </Div></Div> : 
                            <Div
                                id={`${parent_id}_${i}_patientidentification_upload_doc_div`}
                                className="pi-upload-doc" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{display: "flex"}}>
                            <Image
                                id={`${parent_id}_${i}_patientidentification_upload_file_image`}
                                className="pi-upload-doc-img" src={'images/icons8-file.svg'} alt="file"
                            />

                            <Text
                                id={`${parent_id}_${i}_patientidentification_upload_doc_name_text`}
                                className="pi-upload-doc-name" >
                                    {(val?.doc?.filename?.length > 15 
                                        ? `${val?.doc?.filename?.slice(0, 15)}...`
                                        : val?.doc?.filename) ?? ""}
                            </Text>
                            </div>
                            <div style={{display: "flex"}}>
                            &nbsp;{/* <Text className="pi-upload-doc-size">{this.getSize(val?.doc?.size)}</Text> */}
                            <Icons
                                id={`${parent_id}_${i}_patientidentification_upload_doc_close_icons`}
                                key={"1"}
                                className="pi-upload-doc-close"
                                fontIcon={"close"}
                                ariaHidden={"true"}
                                style={{
                                    marginLeft: "2px",
                                    marginRight: "4px",
                                }}
                                onClick={() => this.setData("doc", null, i)}
                                size={"small"}
                            ></Icons>
                            </div>
                           
                        </Div>
                        }
                        {!val.doc ? null :
                        <Div
                                id={`${parent_id}_${i}_patientidentification_trash_div`}
                                style={{
                                    width: 15,
                                    cursor: 'pointer',
                                    marginLeft:"10px"
                                }}>
                                <Image
                                    id={`${parent_id}_${i}_patientidentification_trash_image`}
                                    src="images/icons8-trash1.svg" alt="delete" onClick={() => this.handleDelete(i)} />
                        </Div>
                        }   

                        {(identification?.length > 1 && i === 0) &&
                            <Text
                                id={`${parent_id}_${i}_patientidentification_show_showless_label_text`}
                                className="pc_medium" style={{
                                    color: '#F58B00',
                                    marginLeft: 15,
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    marginTop: 20
                                }}
                                onClick={() => this.handleLimit()}>{this.state.limited ? 'Show Less' : `Show +${identification?.length}`}
                            </Text>}
                    </Col>
                </Row>)}
            </Paper>
        </Div>

    }
}


export default withAllContexts(PatientIdentification);