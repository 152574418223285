import React from "react";
import { Checkbox, Container, FormControlLabel, Grid, Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { Routes } from "../../router/routes";
import {
	TextInput,
	Select,
	MobileNumberWithCode,
	H6,
	Div,
	Text,
	SideMenu,
} from "qdm-component-library";
import SearcMemberList from "./serachMemberList";
import { withAllContexts } from "../../HOCs";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import CancelBookingPage from "../../screens/cancelBooking";
import { getUserInfo, _calculateAge, convertTime, getImgUrl, makeName, mobileNumberValidation, AlertProps, utcTOLocal } from "../../utils";
import { ModelComponent } from "../../components";
import { ConfirmationPage } from "../../screens";
import { UIColor } from "../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { searchCall } from "./billSettlementFunctions";
import { checkWithCasbin } from "../../utils/permissonHandling";

const styles = (theme) => ({
	root: {
		overflow: "auto",
		zIndex: 99999999,
		backgroundColor: UIColor.differentiationBackground.color
	},
	search: {
		background: "#0071f2",
		boxShadow: "none",
		color: "#fff",
	},
	clear: {
		marginTop: "10px",
		width: "100%",
		borderRadius: 8,
		color: "#6F6F6F",
		fontSize: 13,
		border: "1px solid #e0e0e0",
		boxShadow: "none",
		background: "#fff",

		height: "38px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: 600,
		cursor: "pointer",
	},
	title: {
		padding: "16px 0px 16px",
		fontWeight: 600,
		fontSize: 20,
		fontFamily: "pc_semibold !important",
		margin: "inherit !important",
	},
	mbl: {
		"& .bcDEQH": {
			background: "#fff",
		},
	},
	searchOptions: {
		height: "50px",
		borderTop: "1px solid #f1f1f1",
		borderBottom: "1px solid #f1f1f1",
		color: "#0071f2",
		display: "flex",
		// margin: "20px 0px",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "14px",
		fontWeight: 600,
		cursor: "pointer",
	},
	pd_0: {
		padding: "4px 8px !important",
	},
	dis: {
		color: "#101010",
		fontSize: 15,
		fontFamily: "pc_medium !important",
	},
	err: {
		textAlign: "center",
		color: "red",
		fontSize: "14px",
		marginTop: "4px",
	},
});

class SearchPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allMasters: [],
			pid_number: "",
			identification: "",
			id_number: "",
			patientName: "",
			mobileNumber: {},
			appointmentName: "",
			searchoptions: true,
			data: [],
			cancelbooking: false,
			cancelbookingreason: {},
			cancelbookings: 0,
			otpnumber: "",
			open: false,
			appointmentId: 0,
			button: false,
			dateRange:[new Date(new Date().setHours(0, 0, 0, 0)),new Date(new Date().setHours(23, 59, 59, 999))],
			isBilled: true,
		};
	}
	componentDidMount() {
		this.getallmasters();
	}

	onChangeDate = (range) => {
		debugger;
		this.setState({...this.state,dateRange:range})
	}

	getCountryCodelist = async() => {
    let code = await this.props.GET_COUNTRY_CODE_LIST()
    // let value = {
    //   countryData: code?.payload?.data?.filter(val => val?.callingCodes === '91')?.[0],
    //   mobileNo: "",
    // };

    // this.setState({
    //   ...this.state,
    //   mobileNumber: value,
    // })

  }

	getallmasters = async () => {
		await this.getCountryCodelist();
		const data = await this.props.MASTER_SEARCH({
			type: ["ALTERNATEIDTYPE"],
		});
		this.setState({
			allMasters: data?.payload?.data,
		});
	};

	FlterOptions = (type) => {
		const { allMasters } = this.state;
		var lista = [];
		const data = allMasters?.[type];
		for (let index in data) {
			var datatemp = {
				value: data[index]?.coding?.display,
				code: data[index]?.coding?.code,
				dis: data[index]?.Type,
			};
			lista.push(datatemp);
		}
		return lista;
	};

	styles = {
		labelStyle: {
			color: "#6F6F6F",
			fontSize: "12px",
			marginBottom: "6px",
			fontFamily: `pc_regular !important`,
		},
		borderStyle: {
			borderRadius: 8,
			borderColor: "#E0E0E0",
			background: "#fff",
		},
		borderStyleDisable: {
			borderRadius: 8,
			border: "1px solid #E0E0E0",
			background: "#F0F0F0",
		},
	};

	setData = (n, v) => {
		const state = this.state;
		if (n === "identification") {
			state.id_number = "";
		}
		this.setState({
			...state,
			[n]: v,
		});
	};

	handleList = () => {
		const state = this.state;
		this.setState({
			searchoptions: !state.searchoptions,
		});
	};

	getAge = (date) => {
		return (
			Math.floor(
				moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
			) + " Yrs"
		);
	};

	SearchUserData = async () => {
		var SearchUserData;
		var lista = [];
		this.setState({
			...this.state,
			button: true,
		});
		debugger;
		const start = Math.floor(this.state.dateRange[0]/1000);
		const endDAte = new Date((this.state.dateRange[1].setHours(23, 59, 59, 999)));
		const end = Math.floor(endDAte/1000);
		const name = this.state.patientName;
		const unbilled = this.state.isBilled;
		const mbl = this?.state?.mobileNumber?.mobileNo;
		const mrn = this.state.pid_number;
		const alternateId = this?.state?.identification?.code;
		const alternateNo = this?.state?.id_number;
		if(this?.props?.isCashier){
			if(!this.state.dateRange[1]){
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please provide the date range",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
			}else{
				if (this.state.patientName && this.state.dateRange[1]){
					const searchUserData =  await this.props.SEARCH_VIA_NAME({name,start,end,unbilled});
					let data = searchUserData?.payload?.data;
					const newData = searchCall(data);
					lista = newData
				}
				if(this.state.pid_number && this.state.dateRange[1]){
					const searchUserData =  await this.props.SEARCH_VIA_PID({mrn,start,end,unbilled});
					let data = searchUserData?.payload?.data;
					const newData = searchCall(data);
					lista = newData
				}
				if(this.state.mobileNumber?.mobileNo && this.state.dateRange[1]){
					let res__ = mobileNumberValidation(this.state?.mobileNumber?.mobileNo, this.state?.mobileNumber?.countryData?.callingCodes);
					if (res__ === "Invalid Country code") {
						this.props.alert.setSnack({
							open: true,
							severity: AlertProps.severity.error,
							msg: res__,
							vertical: AlertProps.vertical.top,
							horizontal: AlertProps.horizontal.right,
						});
						return
					} else if (res__ === false) {
						this.props.alert.setSnack({
							open: true,
							severity: AlertProps.severity.error,
							msg: "Please enter valid mobile number",
							vertical: AlertProps.vertical.top,
							horizontal: AlertProps.horizontal.right,
						});
						return
					}
					const searchUserData =  await this.props.SEARCH_VIA_MOBILE({mbl,start,end,unbilled});
					let data = searchUserData?.payload?.data;
					const newData = searchCall(data);
					lista = newData
				}
				if(this.state.id_number && this.state.identification?.code && this.state.dateRange[1]){
					const searchUserData =  await this.props.SEARCH_VIA_MULTIPLE_ID({alternateId,alternateNo,start,end,unbilled});
					let data = searchUserData?.payload?.data;
					const newData = searchCall(data);
					lista = newData
				}
				if(this.state.dateRange[1] && (this.state?.patientName?.length === 0) && (this.state?.pid_number?.length === 0) && (this.state.mobileNumber?.mobileNo?.length === 0) && (this.state.identification?.code?.length === 0)){
					debugger;
					const searchUserData =  await this.props.ENCOUNTER_SEARCH({start,end,unbilled});
					let data = searchUserData?.payload?.data;
					const newData = searchCall(data);
					lista = newData
				}
				this.setState({
					data: lista,
				});
			}
		}else{
			if (this.state.mobileNumber?.mobileNo) {
				let res__ = mobileNumberValidation(this.state?.mobileNumber?.mobileNo, this.state?.mobileNumber?.countryData?.callingCodes);
				debugger
				if (res__ === "Invalid Country code") {
					this.props.alert.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: res__,
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.right,
					});
					return
				} else if (res__ === false) {
					this.props.alert.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: "Please enter valid mobile number",
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.right,
					});
					return
				}
				SearchUserData = await this.props.SEARCH_PERSON({
					phone:
						"+" +
						this.state.mobileNumber.countryData.callingCodes +
						this.state.mobileNumber.mobileNo,
					use: "Mobile",
				});
				let data = SearchUserData?.payload?.data;
				const valuedata = data?.map((v) => {
					// if (v?.patient?.[0]?.alias) {
					return lista.push({
						idnumber: "",
						idsourcecode: "",
						email:
							v.telecom?.find?.((tel) => tel.system === "Email")?.value ?? "",
						name: makeName(v?.name) ?? "",
						id: v?.patient?.[0]?.alias,
						dis: `${(v?.gender + ", " ?? "") +
							(v.birthDay ? this.getAge(v.birthDay) : "")
							}
							${v.telecom?.[0].value
								? " | " + v.telecom?.[0].value
								: ""
							}`,
						userId: v?.Id,
						appointmentName: "",
						mobileNumber:
							v?.telecom?.find?.((tel) => tel.system === "Phone")?.value ?? "",
						patientId: v?.patient?.[0]?.id,
						img: getImgUrl(v?.photo?.[0]?.fileid) || getImgUrl(v?.patient?.[0]?.photo?.[0]?.fileid) || "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
					});
					// }
				});
			}
			if (this.state.patientName) {
				SearchUserData = await this.props.GET_PATIENT_NAME({
					patientName: this.state.patientName.toUpperCase() || "",
				});
				let data = SearchUserData?.payload?.data;
				const valuedata = data?.map((v) => {
					// if (v?.telecom[0]?.value) {
						return lista.push({
							idnumber: "",
							idsourcecode: "",
							email:
								v.telecom?.find?.((tel) => tel.system === "Email")?.value ?? "",
							name: makeName(v?.nameObj?.[0] || {}) || "",
							id: v?.patientDetail?.[0]?.alias ?? "",
							dis: `${(v?.patientDetail?.[0]?.gender + ", " ?? "") +
								(v?.patientDetail?.[0]?.birthDate
									? this.getAge(v?.patientDetail?.[0]?.birthDate)
									: "")
								}
								${v.telecom?.[0].value
									? " | " + v.telecom?.[0].value
									: ""
								}`,
							userId: v?.personId,
							appointmentName: "",
							mobileNumber:
								v?.telecom?.find?.((tel) => tel.system === "Phone")?.value || v.telecom?.find?.((tel) => tel.system === "Email")?.value || "",
							patientId: v?.patientDetail?.[0]?.id,
							img: getImgUrl(v?.photo?.[0]?.fileid) || getImgUrl(v?.patientDetail?.[0]?.photo?.[0]?.fileid) || "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
						});
					// }
				});
			}
			if (this.state.appointmentName) {
				SearchUserData = await this.props.GET_PATIENT_APPOINTMENT({
					appointmentId: this.state.appointmentName,
				});

				let data = SearchUserData?.payload?.data;
				const valuedata = data?.map((v) => {
					return lista.push({
						idnumber: "",
						idsourcecode: "",
						appointmentName: v.appnumber ?? "",
						email:
							v?.Person.telecom?.find?.((tel) => tel.system === "Email")?.value ??
							"",
						name: makeName(v?.Person?.name[0]) ?? "",
						id: v?.patient?.[0]?.alias,
						dis: `${(v?.Person?.gender + ", " ?? "") +
							(v?.Person?.birthDay ? this.getAge(v?.Person?.birthDay) : "")
							}
							${v.Person?.telecom?.[0].value
								? " | " + v?.Person?.telecom?.[0].value
								: ""
							}`,
						userId: v?.Person?.Id,
						mobileNumber:
							// "+91" + 
							v.Person?.telecom?.find?.((tel) => tel.system === "Phone")
								?.value ||
							// "+91" +
							v?.patient?.telecom?.find?.(
								(tel) => tel.system === "Phone" || tel.use === "Phone"
							)?.value,
						patientId: v?.patient?.[0]?.id,
						img: getImgUrl(v?.Person?.photo?.[0]?.fileid) || getImgUrl(v?.patient?.[0]?.photo?.[0]?.fileid) || "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
						date: v?.start
							? convertTime(v?.start, "unix", "DD MMM YYYY | hh:mm a")
							: "",
						status: v?.status,
						appStatus: v?.appStatus ?? null,
						AppointmentID: v.AppointmentID,
					});
				});
			}
			if (this.state.pid_number) {
				SearchUserData = await this.props.READ_PATIENT({
					mrnnumber: this.state.pid_number,
				});
				let data = SearchUserData?.payload?.data;
				const valuedata = data?.map((v) => {
					if (v?.telecom[0]?.value) {
						return lista.push({
							idnumber: "",
							idsourcecode: "",
							email:
								v.telecom?.find?.((tel) => tel.system === "Email")?.value ?? "",
							name: makeName(v?.name?.[0]) ?? "",
							// id: ((v?._id)),
							id: this.state.pid_number ?? "",
							dis: `${(v?.gender + ", " ?? "") +
								(v.birthDay ? this.getAge(v.birthDay) : "")
								}
							${v.telecom?.[0].value
									? " | " + v.telecom?.[0].value
									: ""
								}`,
							userId: null,
							appointmentName: "",
							mobileNumber:
								v?.telecom?.find?.((tel) => tel.system === "Phone")?.value ?? "",
							patientId: v?.Id ?? 0,
							img: getImgUrl(v?.photo?.[0]?.fileid) || getImgUrl(v?.Patient?.photo?.[0]?.fileid) || "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
						});
					}
				});
			}
			if (this.state.id_number && this.state.identification?.code) {
				SearchUserData = await this.props.GET_PATIENT_TYPEID({
					idnumber: this.state.id_number,
					idsourcecode: this.state.identification?.code,
				});
				let data = SearchUserData?.payload?.data;
				const valuedata = data?.map((v) => {
					if (v?.telecom[0]?.value) {
						return lista.push({
							idnumber: this.state.id_number ?? "",
							idsourcecode: this.state.identification?.code ?? "",
							email:
								v.telecom?.find?.((tel) => tel.system === "Email")?.value ?? "",
							name: makeName(v?.name?.[0]) ?? "",
							id: v?.alias ?? "",
							dis: `${(v?.gender + ", " ?? "") +
								(v.birthDay ? this.getAge(v.birthDay) : "")
								}
							${v.telecom?.[0].value
									? " | " + v.telecom?.[0].value
									: ""
								}`,
							userId: v?.personID,
							appointmentName: "",
							mobileNumber:
							// v?.telecom?.find?.((tel) => tel.system === "Phone")?.value ?? "",
							v?.telecom?.find?.((tel) => tel.system)?.value ?? "",
							patientId: v?.id ?? 0,
							img: getImgUrl(v?.personInfo?.[0]?.photo?.[0]?.fileid) || getImgUrl(v?.photo?.[0]?.fileid) || "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
						});
					}
				});
			}
			const mappedData = () => {
				const state = this.state;
				if (lista?.length > 0) {
					const val = lista?.filter((v) => {
						if (
							(v?.id ? v?.id?.includes(state?.pid_number ?? "") : true) &&
							v?.name?.toUpperCase()?.includes(state?.patientName?.toUpperCase() ?? "") &&
							v?.mobileNumber?.includes(state?.mobileNumber?.mobileNo ?? "") &&
							v?.appointmentName?.includes(state?.appointmentName ?? "") &&
							v?.idnumber?.includes(state?.id_number ?? "") &&
							v?.idsourcecode?.includes(state?.identification?.code ?? "")
						) {
							return v;
						}	
					});
					return val;
				}
			};
			this.setState({
				data: mappedData(),
			});
			// idnumber: this.state.id_number,
			// idsourcecode: this.state.identification?.code,
		}	
	};

	Search = async () => {
		debugger
		this.setState({
			searchClick: true,
		});
		await this.SearchUserData();
		this.setState({
			searchoptions: this.state?.data?.length > 0 ? false : true,
			err: true,
			button: false,
		});
	};

	Clear = () => {
		this.setState({
			pid_number: "",
			identification: "",
			id_number: "",
			patientName: "",
			mobileNumber: {},
			appointmentName: "",
			searchoptions: true,
			err: false,
			data: [],
		});
	};

	handleMove = () => {
		this.props.history.push({
			pathname: Routes.assemblePatioentRegister,
			state: {
				newUser: true,
				Ispatient: true,
				idedit: false,
			},
		});
	};

	handleMoveList = (v) => {
		debugger;
		if(this?.props?.isCashier){
			this.props.history.push({
				pathname: Routes.billingDetails,
				state: {
					data: v,
				},
			});
		}else{
			this.props.history.push({
				pathname: Routes.PatientDashBoard,
				state: {
					userId: v?.userId,
					mobileNumber: v?.mobileNumber,
					patientId: v?.patientId,
					// appointmentId
				},
			});
		}
	};

	//   handleModify = (v) => {
	//     alert("Modify");
	//   };

	fetchAppInfo = async (id) => {
		const data = await this.props.APPOINTMENT_READ({ appointmentId: id });
		return data?.payload?.data || {};
	};

	handleModify = async (val) => {
		// props.history.push(Routes.doctorListingWithIdCall+"123")
		// props.history.push(Routes.doctorListing)
		const appInfo = JSON.parse(
			JSON.stringify(await this.fetchAppInfo(val.AppointmentID))
		);
		appInfo["HealthcareService"] = appInfo["healthservice"];
		delete appInfo["healthservice"];
		this.props.history.push({
			pathname: Routes.doctorListing,
			state: {
				userId: val.userId,
				mobileNo: val.mobileNumber,
				patientId: val.patientId,
				location: {
					address: "Chennai, Tamil Nadu, India",
					latitude: 13.0826802,
					longitude: 80.2707184,
					city: "Chennai",
					location: "Chennai",
				},
				hospital: {
					label: "",
					value: appInfo?.HealthcareService?.[0]?.Organization?.[0]?.id || 0,
				},
				specialities: [],
				symptoms: [
					{
						label: "Fever",
						type: "Symptom",
						value: "SymptomsMapping/10000",
					},
				],
				selectedUser: {
					//name: appInfo?.Person?.[0]?.name?.[0]?.text ?? "",
					name: makeName(appInfo?.Person?.[0]?.name?.[0]),
					gender: appInfo?.Person?.[0]?.gender ?? "",
					birthDay: appInfo?.Person?.[0]?.birthDay,
				},
				symptomsSearch: [],
				modifyUser: true,
				newBook: false,
				appointMentInfo: appInfo,
			},
		});
	};

	openView = (v) => {
		this.setState({
			open: !this.state.open,
			appointmentId: v.AppointmentID,
		});
	};

	handleCancel = (v) => {
		if (v !== "cancel") {
			this.setState({
				cancelbooking: !this.state.cancelbooking,
				appointmentId: v.AppointmentID,
			});
		} else {
			this.setState({
				cancelbooking: !this.state.cancelbooking,
				appointmentId: 0,
			});
		}
	};
	handleSelectCancelbooking = (v) => {
		this.setState({
			cancelbookingreason: v,
		});
	};
	handlecahngeCancelbooking = async (v, number) => {
		if (v === "back") {
			this.setState({
				cancelbookings: 0,
			});
			return;
		}
		if (!v) {
			this.handleCancel("cancel");
			window.location.href = "/dashboard";
			return;
		}
		let mobile = number;
		if (!number) {
			const userInfo = getUserInfo();
			mobile = userInfo.mobile;
		}
		if (v === 1) {
			await this.props.GENERATE_OTP({ mobileNo: mobile });
		}
		this.setState({
			cancelbookings: v,
			otpnumber: number,
		});
	};
	render() {
		const { classes, parent_id, noNewPatient } = this.props;
		const { labelStyle, borderStyle, borderStyleDisable } = this.styles;
		const {
			data,
			err,
			pid_number,
			identification,
			id_number,
			patientName,
			mobileNumber,
			appointmentName,
			searchoptions,
			cancelbookingreason,
			cancelbooking,
			cancelbookings
		} = this.state;
		const role_name = localStorage.getItem("role_name") === "biller and cashier" ? true : false;
		const [startDate,endDate] = this.state.dateRange;
		console.log("this.state",this.state)
		return (
			<Div className={classes.root}>
				<Container id={`${parent_id}_parent_container`} style={{ padding: 10 }}>
					<Div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<H6 className={classes.title}> Search Patient</H6>
						<CloseIcon
							style={{ fontSize: 20, cursor: "pointer" }}
							onClick={() => this.props.handleActionClose()}
						/>
					</Div>
						{searchoptions &&
					<Grid id={`${parent_id}_parent_grid`} spacing={2} container>
						<Grid item xs={12} className={classes.pd_0}>
							<TextInput
								maxLength={20}
								id={`${parent_id}_pid_number_textinput`}
								value={pid_number ?? ""}
								onChange={(e) => this.setData("pid_number", e.target.value, 20)}
								label="PID Number"
								placeholder={false}
								labelStyle={labelStyle}
								style={borderStyle}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.pd_0}>
							<Select
								search={true}
								id={`${parent_id}_identification_select`}
								value={identification?.value ?? null}
								onChangeValue={(e) => this.setData("identification", e)}
								options={this.FlterOptions("ALTERNATEIDTYPE")}
								label="Identification"
								// showArrow
								labelStyle={labelStyle}
								placeholder={false}
								inLineStyles={borderStyle}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.pd_0}>
							<TextInput
								maxLength={20}
								id={`${parent_id}_id_number_textinput`}
								value={id_number ?? ""}
								onChange={(e) => this.setData("id_number", e.target.value, 20)}
								label="ID Number"
								placeholder={false}
								labelStyle={labelStyle}
								disabled={!identification?.value}
								style={
									!identification?.value ? borderStyleDisable : borderStyle
								}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Grid>
						<Grid item xs={12} className={classes.pd_0}>
							<TextInput
								id={`${parent_id}_patientName_textinput`}
								value={patientName ?? ""}
								onChange={(e) =>
									this.setData("patientName", e.target.value, 20)
								}
								label="Patient Name"
								placeholder={false}
								labelStyle={labelStyle}
								style={borderStyle}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Grid>
						<Grid item xs={12} className={classes.pd_0}>
							<Text
								id={`${parent_id}_mobilenumberwithcode_title`}
								className="pc_regular"
								name={
									<span
										style={{ color: "#6F6F6F", fontSize: 12 }}
										id={`${parent_id}_mobilenumberwithcode_title_span`}
									>
										Mobile
									</span>
								}
							></Text>
							<MobileNumberWithCode
								id={`${parent_id}_mobilenumberwithcode`}
								type="number"
								label="Mobile No"
								borderColor="#E0E0E0"
								borderRadius="6"
								backgroundColor="#fff"
								maxLength={30}
								dropdown={mobileNumber?.countryData}
								value={""}
								// dropdown={{
								//     name: "India",
								//     flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
								//     altSpellings: "IND",
								//     callingCodes: "91",
								// }}
								className={classes.mbl}
								dropdownData={this?.props?.CountryCode?.data}
								disabled={false}
								style={{
									height: "42px",
									background: "#fff",
								}}
								placeholder={false}
								onChange={(e) => this.setData("mobileNumber", e)}
							/>
						</Grid>
						{this?.props?.isCashier === false &&
						<Grid item xs={12} className={classes.pd_0}>
							<TextInput
								maxLength={20}
								id={`${parent_id}_appointmentName_textinput`}
								value={appointmentName ?? ""}
								onChange={(e) =>
									this.setData("appointmentName", e.target.value, 20)
								}
								label="Appointment Number"
								placeholder={false}
								labelStyle={labelStyle}
								style={borderStyle}
								hoverColor={UIColor.primaryColor.color}
							/>
						</Grid>}
						{this?.props?.isCashier && 
							<>
							<Grid item xs={12} className={classes.pd_0}>
								<DatePicker
									// minDate={props?.minDate}
									maxDate={new Date()}
									// selected={new Date()}
									selectsRange
									startDate={startDate}
      								endDate={endDate}
									onChange={(value) => {
										this.setState({...this.state,dateRange:value})
									}}
									isClearable={false}
									customInput={
										<div style={{ position: "relative" }}>
											<TextInput
												// id={`${props.parentId}_dob_date_input`}
												value={(!startDate ? "" : utcTOLocal(startDate,"DD MMM, YYYY")) + (!startDate ? "" : " to ") + (!endDate ? "" : utcTOLocal(endDate,"DD MMM, YYYY"))}//send format in DD-MM-YYYY
												label={"Encounter Date Range"} 
												dateIcon="/images/icons8-calendar.svg" 
												placeholder={""}
												labelStyle={labelStyle}
												style={borderStyle}
												borderColor= {UIColor.lineBorderFill.color}
												// isRequired={props?.isRequired}
												// onKeyDown={props?.handleBack}
											/>
											<img
												src="images/icons8-calendar-grey.svg"
												style={{ position: "absolute", top: 35, right: 10 }}
											/>
										</div>
									}
								/>
							</Grid>
							<Grid item xs={12} className={classes.pd_0}>
								<FormControlLabel
									control={ <Checkbox
										checked={this.state.isBilled}
										color="primary"
										size="small"
										onChange={(e) => this.setState({...this.state,isBilled:e.target.checked})}
									/>}
									label={<Typography style={{...labelStyle,marginBottom:"0px"}} >Unbilled Encounter Only?</Typography>}
								/>
							</Grid></>
						}
						<Grid item xs={6}>
							<Button
								id={`${parent_id}_search_button`}
								className={classes.clear}
								variant="outlined"
								onClick={() => this.Search()}
								style={{
									backgroundColor: UIColor.secondaryButtonColor.color,
									color: "#fff",
									textTransform: "capitalize",
									fontWeight: 600,
									fontSize: 14,
								}}
							>
								{this.state.button ? "Loading..." : "Search"}
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								id={`${parent_id}_clearAll_button`}
								className={classes.clear}
								variant="outlined"
								onClick={() => this.Clear()}
								style={{
									textTransform: "capitalize",
								}}
							>
								Clear All
							</Button>
						</Grid>
					</Grid>
					}
					{err && !data?.length > 0 && (
						<Div className={classes.err}>No Result Found</Div>
					)}
				</Container>
				{data?.length > 0 && (
					<Div
						className={classes.searchOptions}
						onClick={() => this.handleList()}
					>
						Search Options
						{searchoptions ? "▾" : "▴"}
					</Div>
				)}

				<Container id={`${parent_id}_parent_container`} style={{ padding: 10 }}>
					{(data?.length > 0) &&
						data.map((v, i) => {
							return (
								<SearcMemberList
									parent_id={parent_id + i}
									handleModify={() => this.handleModify(v)}
									handleCancel={() => this.handleCancel(v)}
									handleMoveList={() => this.handleMoveList(v)}
									openView={() => this.openView(v)}
									name={v?.name}
									letter={v.letter}
									gendar={v?.dis?.split("\n")[0]}
									pid={v.id || "---"}
									email={v.email || ""}
									mobile={v.mobileNumber || "---"}
									imgUrl={v.img}
									appointmentName={v.appointmentName}
									date={v?.date}
									status={v?.status}
									appStatus={v?.appStatus}
								/>
							);
						})}
						
					{(!data?.length > 0 && !(noNewPatient)) && (
						<Div style={{ textAlign: "center" }}>
							<Div
								style={{
									display: "flex",
									alignItems: "center",
									margin: "15px 0px 6px",
								}}
							>
								<Div
									style={{ flex: 1, borderBottom: "1.9px dashed #E0E0E0" }}
								></Div>
								<H6
									style={{
										padding: "0px 10px",
										fontSize: 14,
										fontFamily: "pc_medium !important",
									}}
								>
									Or
								</H6>
								<Div
									style={{ flex: 1, borderBottom: "1.9px dashed #E0E0E0" }}
								></Div>
							</Div>
							<Text className={classes.dis}>
								If you're unable to find patient
							</Text>
							<Button
								className="pc_medium"
								key={"3"}
								onClick={() => this.handleMove()}
								variant={"outline"}
								name={"Register New Patient"}
								className={classes.clear}
								style={{
									borderColor: UIColor.secondaryButtonColor.color,
									color: UIColor.secondaryButtonColor.color,
									textTransform: "capitalize",
								}}
							>
								Register New Patient
							</Button>
						</Div>
					)}
				</Container>
				<SideMenu
					id="patient_dashboard_cancel_sidemenu"
					open={cancelbooking}
					direction="right"
					width={435}
					color="default"
					onClose={() => this.handleCancel("cancel")}
				>
					<CancelBookingPage
						parent_id={"patient_dashboard"}
						cancelbookingreason={cancelbookingreason}
						cancelbookings={cancelbookings}
						appointmentId={this.state.appointmentId}
						handleSelectCancelbooking={this.handleSelectCancelbooking}
						handlecahngeCancelbooking={this.handlecahngeCancelbooking}
					/>
				</SideMenu>

				<ModelComponent
					parent_id={"patient_dashboard"}
					hiddencloseIcon={true}
					open={this.state.open}
					onClose={this.openView}
					childrens={
						<ConfirmationPage
							appoinmentBooking
							parent_id={"patient_dashboard"}
							appointmentId={this.state.appointmentId}
							appointmentDetails={true}
							hidden={true}
						/>
						// <ConfirmBook
						//     appointmentId={this.state.appointmentId}
						//     appointmentDetails={true}
						//     hidden={true}
						// />
					}
					width={530}
					backgroundColorNone
				/>
			</Div>
		);
	}
}

// export default withRouter(SearchPage);
const mapStateToProps = (state) => ({
	readPerson: state?.personApiSlice?.readPerson,
	CountryCode: state?.authSlice?.country_code_list
});
export default withStyles(styles)(
	connect(mapStateToProps, actions)(withAllContexts(withRouter(SearchPage)))
);
